import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'underscore';

@Component({
  selector: 'app-visit-history-list',
  templateUrl: './visit-history-list.component.html',
  styleUrls: ['./visit-history-list.component.scss']
})

export class VisitHistoryListComponent implements OnInit {
  @Input('historylist') historylist: any;
  historylistData: any;
  filterOptions: string[];
  selectedFilterValue: any;
  optionList: string[];
  constructor() {

  }

  ngOnInit() {

  }

  ngOnChanges() {
    this.selectedFilterValue = 'All visits';
    if (this.historylist) {
      this.historylistData = this.historylist;
      this.filterOptions = [this.selectedFilterValue].concat(_.chain(this.historylist).map(c=>c['status']).uniq().value());
    } else {
      this.historylistData = [];
    }
  }

  onChange() {
    if (this.selectedFilterValue && this.selectedFilterValue != 'All visits') {
      this.historylistData = this.historylist.filter(c => c.status == this.selectedFilterValue);
    } else {
      this.historylistData = this.historylist;
    }
  }

  dnconstruct(name) {
    if (!name) return '';
    let splitdta = name.split(/[ ,]+/);
    if (splitdta[1]) {
      return splitdta[0][0] + splitdta[1][0];
    } else {
      return '';
    }
  }

}
