import { Component, OnInit, Input, TemplateRef, Output, EventEmitter, ViewEncapsulation, SimpleChanges, OnChanges, ViewChild, AfterContentInit, OnDestroy } from '@angular/core';
// ChangeDetectorRef, DoCheck, KeyValueDiffers,

import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { TaskService } from '../../_services/task/task.service';
import * as moment from 'moment';
import PhoneNumber from 'awesome-phonenumber';
// import {IOption} from 'ng-select';
import { NgForm } from '@angular/forms';
import { SharedService } from '../../_services/shared.service';
import { PatientsService } from '../../_services/patients/patients.service';
import { DataHelperService } from '../../_services/auth/data-helper.service';
import { NavparamsService } from '../../_services/navparams/navparams.service';
import { Observable, Subject } from 'rxjs/Rx';
import { filter } from 'rxjs/operators';
import libphonenumber from 'google-libphonenumber';
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
import * as _ from 'lodash';
import { SharedPopupService } from '../../_services/popup.service';


class DemographicsData {
  lastname: String;
  firstname: String;
  middlename: String;
  dob: any;
  gender: String;
  address1: any;
  address2: any;
  city: String;
  state: String;
  zipcode: String;
  homephone: any;
  phone: any;
  workphone: any;
  cellphone: any;
  email: any;
}

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: [
    './patient-details.component.scss',
    '../../../scss/vendors/bs-datepicker/bs-datepicker.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})

export class PatientDetailsComponent implements OnInit, OnDestroy, OnChanges {

  // protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input('Show') show: boolean;
  @Input('casetype') casetype: any;
  @Input('taskData') taskData: any;
  @Input('fromModule') fromModule: string;
  @Output() submit = new EventEmitter<any>();
  @Output() patientData: EventEmitter<any> = new EventEmitter<any>();

  public state_array_item: Array<{ id: string, text: string }>;
  public payer_array_item: any[] = [];
  public payer_array_selected_ids: any = [];
  public demographicsData: any;
  public dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public zipCodeFive = [/\d/, /\d/, /\d/, /\d/, /\d/];
  public zipCodeNine = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public phoneMask = ['(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  @ViewChild('f', { static: false }) myform: NgForm;
  popUpfrom: any;
  parentIndexValue: any;
  popUpinfo: any;
  popUpinfoselectedIndex: any;
  popupTitle: string;
  taskName: any;
  taskReason: any;
  taskInstructions: any;
  // casetype:any;
  task_info: any;
  practice_id: any;
  stateActiveItem: any;
  selectedStateCode: any;

  patientDemographData: any;
  demograph_formdata: any;
  casename: any;
  selectedcasetype: any;
  casedescription: any;
  testpush: any;
  caseindex: any;
  addPolicyIndicator: any;
  messageInfo: any;
  demograph_lastname_isempty: any;
  isNewPatient: any;
  // caseTypeArray:any;
  caseTypeArray_Add: any;

  patient: any;
  dataUpdates: any;
  doberror: boolean;
  validateNewCase = false;
  activities: any[];
  differ: any;

  page: number;
  currentPage: number;
  searchValue: String;
  defaultLimit: number;
  patientList: any;
  setPatient: any;
  fieldEdit: boolean;
  is_guarantor_different: boolean;
  insurance_practice: string;
  insuranceTabIndex: any;
  getPayersFlag = true;
  timeout: any;
  searchFlag: boolean;
  isCollapse: any;
  iconCollapse = 'icon-arrow-down';
  homeError: boolean;
  workError: boolean;
  cellError: boolean;
  accident_state_array: any;
  patient_state_array: any;
  accident_state_name: any;
  private insurance_state_array: any[][];
  allowPastdate: boolean;
  country:any;
  // , public ref: ChangeDetectorRef,
  // , private differs: KeyValueDiffers
  constructor(public modalService: BsModalService, public SharedPopupService: SharedPopupService, public taskService: TaskService, private sharedService: SharedService, public patientService: PatientsService, private DataHelper: DataHelperService, private navparams: NavparamsService) {
    // this.casetype="Self-Pay";
    this.fieldEdit = true;
    this.allowPastdate = true;
    // this.differ = differs.find({}).create();
    this.accident_state_array = [];
    this.patient_state_array = [];
    this.accident_state_name = [];
    this.insurance_state_array = [];
    this.state_array_item = [];
    this.payer_array_item = [];
    this.payer_array_item[0] = [];
    this.payer_array_item[1] = [];
    this.payer_array_item[2] = [];
    this.insuranceTabIndex = 0;
    this.demograph_formdata = [];
    this.testpush = [];
    this.isNewPatient = '';
    this.activities = [];
    // this.caseTypeArray = ["Self-Pay","Commercial","Medicare","Medicaid","Workers Comp","Auto Accident","Lien"];
    this.caseTypeArray_Add = ['Commercial', 'Medicare', 'Medicaid', 'Workers Comp', 'Auto Accident', 'Lien'];
    // this.loadData();
    this.dataUpdates = {};
    this.insurance_practice = '';
    this.sharedService.getGlobalData('case_types').subscribe(result => {
      // console.log(this.sharedService.globalData['case_types']);
      this.caseTypeArray_Add = this.sharedService.globalData['case_types'];
    });
    // this.patient = JSON.parse(JSON.stringify(this.taskData ? this.taskData.patient:{}));
    // console.log(this.sharedService.visit_statuses)
    this.defaultLimit = 100;
    this.patientList = [];
    this.searchFlag = false;
    this.stateActiveItem = '';
    this.isCollapse = {};
    this.isCollapse['accidentCase'] = false;
    // this.insurancecompanyOnBlur();
  }

  ngOnInit() {
    //  this.taskService.initialStateList();
    this.country = localStorage.getItem('Region');
  }
  ngAfterContentInit() {
    this.insurancecompanyOnBlur();
  }

  // ngDoCheck() {
  //   if (this.fromModule != 'task') {
  //     this.insurance_practice = this.DataHelper.getItem('practice_id');
  //     let changes = this.differ.diff(this.taskData);
  //     if (changes) {
  //       // changes.forEachChangedItem(r => console.log('changed ', r.currentValue));
  //       this.loadData();
  //     }
  //   } else {
  //     this.insurance_practice = this.patient.practice_id;
  //   }
  // }

  zipCodeMask(): any {
    return {
      mask: (value) => {
        if (value.length <= 5) {
          return this.zipCodeFive;
        } else {
          return this.zipCodeNine;
        }
      },
      guide: false
    };
  }

  ssnMask(): any {
    let x = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    return {
      mask: (value) => {
        return x;
      },
      guide: false
    };
  }
  toggleCollapse(target): void {
    if (this.isCollapse[target] != undefined) {
      this.isCollapse[target] = !this.isCollapse[target];
    } else {
      this.isCollapse[target] = true;
    }
  }
  isCollapsed(target) {
    if (this.isCollapse[target] != undefined) {
      return this.isCollapse[target];
    } else {
      this.isCollapse[target] = true;
      return this.isCollapse[target];
    }
  }
  collapsed(event: any): void {
    //console.log(event);
  }
  expanded(event: any): void {
    //console.log(event);
  }


  loadData() {
    this.accident_state_array = [];
    this.patient_state_array = [];
    this.accident_state_name = [];
    this.insurance_state_array = [];
    this.stateActiveItem = '';
    this['validatePatientDOB'] = true;
    this['validateAccidentDate'] = true;
    this['validatePrimary'] = true;
    this['validateSecondary'] = true;
    this['validateTertiary'] = true;



    // this.ref.detectChanges();
    // this.taskData=JSON.stringify(this.taskData);
    // console.log(this.taskData.patient);
    if (this.taskData && this.taskData.patient) {
      this.patient = JSON.parse(JSON.stringify(this.taskData.patient));
      this.patient.homephone = this.patient.homephone ? this.patient.homephone : '';
      if (this.patient.dob && moment(this.patient.dob).isValid()) {
        if(this.country=='US'){
          this.patient.dob = moment(this.patient.dob, ['YYYY-MM-DD', 'MM/DD/YYYY']).format('MM/DD/YYYY');
        }else{
          this.patient.dob = moment(this.patient.dob, ['YYYY-MM-DD', 'DD/MM/YYYY']).format('DD/MM/YYYY');
        }
      } else {
        this.patient.dob = '01/01/1991';
      }
      //console.log("patient dob",this.patient.dob)
      if (this.patient.address) {
        if (this.patient.address.zip) {
          this.zipCodeMask();
        }
        if (this.patient.address.state) {
          // let PSTATE = this.patient.address.state;

          // this.getState(this.taskData.practice_id, PSTATE);
        } else {
          this.getState("empty", '', null, null);
        }
      } else {
        this.patient.address = {
          'street_line_1': '',
          'street_line_2': '',
          'city': '',
          'state': '',
          'zip': ''
        }
      }

      if (this.patient.address && this.patient.address.state || this.patient.cases && this.patient.cases.length > 0) {
        this.initialStateNameLoad();
      }
      // for (let number_type of ['phone', 'homephone', 'workphone', 'cellphone']) {
      //   if (this.patient[number_type] && this.patient[number_type] != '') {
      //     let pn = new PhoneNumber(this.patient[number_type], 'US');
      //     this.patient[number_type] = (pn.isValid() ? pn.getNumber('significant') : ''); //patient_data[number_type].replace(/[-() ]/gi,''));
      //   }
      // }
      if (this.patient.cases && this.patient.cases.length > 0) {
        // console.log('case data', this.patient.cases);
        for (let i in this.patient.cases) {
          if (this.patient.cases[i].isactive == true) {
            this.caseindex = [i];
          } else {
            this.caseindex = 0;
          }

        }

        if (this.taskData.current_case_type_index != undefined) {
          this.caseindex = this.taskData.current_case_type_index;
          this.casetype = this.patient.cases[this.caseindex].case_type;
        } else {
          for (let i in this.patient.cases) {
            if (this.patient.cases[i].isactive == true) {
              this.caseindex = [i];
            } else {
              this.caseindex = 0;
            }
            if (this.patient.cases[i].insurances) {
              for (let j in this.patient.cases[i].insurances) {
                let insurance = this.patient.cases[i].insurances[j];
                this.payer_array_item[j] = [];
                if (insurance.insurance_payer_id) {
                  this.payer_array_selected_ids[j] = insurance.insurance_payer_id;
                  this.payer_array_item[j].push({
                    id: insurance.insurance_payer_id,
                    text: insurance.name,
                    payer_id: insurance.carrier_id
                  });
                }
                insurance.patient_is_subscriber = ['18', ''].includes(insurance.subscriber.relationship);
              }
            }
          }
          // this.caseindex = 0;
          this.casetype = this.patient.cases[this.caseindex].case_type;
        }
      } else {
        // console.log('adding self pay case');
        this.caseindex = 0;
        this.casetype = 'Self-Pay';
        this.patient.cases = [
          {
            'case_name': 'self',
            'case_type': 'Self-Pay',
            'description': 'Self-Pay',
            'isactive': true,
            'guarantor': {
              'last_name': '',
              'first_name': '',
              'middle_name': '',
              'relationship': '',
              'address': {
                'street_line_1': '',
                'street_line_2': '',
                'city': '',
                'state': '',
                'zip': ''
              },
              'notes': ''
            }
          }
        ];
      }
      this.addPolicyIndicator = (this.casetype != 'Self-Pay' && this.patient.cases[this.caseindex].insurances.length < 3) ? true : false;
      if (this.taskData.current_case_type && this.taskData.current_case_type.insurances && this.taskData.current_case_type.insurances[0]) {
        let selectedInsCompany = []; // this.taskData.current_case_type.insurances[0].name;
        this.taskData.current_case_type.insurances.forEach(element => {
          selectedInsCompany.push(element.name);
        });
        this.getPayersFlag = false;
        // this.getPayers(selectedInsCompany, 0);
      }
    } else {
      this.getState("empty", '', null, null);
      // this.getPayers('');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.taskData.currentValue != '' && changes.taskData.currentValue != undefined) {
      this.taskData = changes.taskData.currentValue;
      if (this.fromModule == 'task') {
        this.insurance_practice = this.taskData.practice_id;
        this.loadData();
        this.fieldEdit = true;
        this.is_guarantor_different = false;
        if (this.patient.cases[this.caseindex].guarantor && this.patient.cases[this.caseindex].guarantor.last_name != '') {
          this.is_guarantor_different = true;
        }
      }
      if (this.fromModule == 'patient') {
        // console.log('patient');
        this.fieldEdit = false;
        this.insurance_practice = this.DataHelper.getItem('practice_id');
        this.loadData();
        this.is_guarantor_different = false;
        if (this.patient.cases[this.caseindex].guarantor && this.patient.cases[this.caseindex].guarantor.last_name != '') {
          this.is_guarantor_different = true;
        }
      }
    }
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'taskData': {
            // this.loadData();
          }
        }
      }
    }
    if (this.fromModule == 'task') {

    }

    if (!changes['taskData'].isFirstChange()) {

      this.formResetterFun();
    }






    // if (this.fromModule != 'task') {
    //   console.log("patient");
    //       this.insurance_practice = this.DataHelper.getItem('practice_id');
    //       // let changes = this.differ.diff(this.taskData);
    //       // if (changes) {
    //         // changes.forEachChangedItem(r => console.log('changed ', r.currentValue));
    //         // this.loadData();
    //       // }
    //     } else {
    //       // this.insurance_practice = this.patient.practice_id;
    //     }
  }

  openModal(template: TemplateRef<any>, from, infoData?, selectedIndex?, parentIndex?) {
    this.popUpfrom = from;
    this.parentIndexValue = parentIndex;
    this.popUpinfo = infoData;
    this.popUpinfoselectedIndex = selectedIndex;
    if (from == 'newReason') {
      this.popupTitle = 'Add new Case';
    } else if (from == 'editCase') {
      this.popupTitle = 'Edit Case';
      this.taskName = 'asdf';
    } else if (from == 'editReason') {
      this.popupTitle = 'Edit Reason';
      this.taskReason = infoData.code_title;
      this.taskInstructions = infoData.code_description;
    }
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static'
    });
  }

  modalClose() {
    this.taskReason = '';
    this.taskInstructions = '';
    this.validateNewCase = false;

    this.casename = '';
    this.selectedcasetype = '';
    this.casedescription = '';

    this.SharedPopupService.dismiss();
  }

  /*initialStateList() {
    this.taskService.listStateNames().subscribe(result => {
      if (result.status == 'success') {
        this.taskService.stateList = [];
        result.results.forEach(element => {
          this.taskService.stateList.push({'state_code' : element.state_code});
        });
      }
    });
  }*/

  patientState(): Promise<any> {
    return Promise.resolve((() => {
      if (this.patient.address && this.patient.address.state) {
        let PSTATE = this.patient.address.state;
        this.getState("patient", PSTATE, null, null);
      }
      return 'one';
    })());
  }

  accidentState(): Promise<any> {
    return Promise.resolve((() => {
      if (this.patient.cases && this.patient.cases.length > 0) {
        this.patient.cases.forEach((element, i) => {
          if (element.accident_state) {
            this.getState('accident', element.accident_state, i, null);
          }
        });
      }
      return 'two';
    })());
  }

  insuranceState(): Promise<any> {
    return Promise.resolve((() => {
      if (this.patient.cases && this.patient.cases.length > 0) {

        this.patient.cases.forEach((element, i) => {
          if (element.insurances) {
            element.insurances.forEach((element, j) => {
              if (element.subscriber.address) {
                if (this.insurance_state_array[i] == undefined) {
                  this.insurance_state_array[i] = [];
                }
                this.insurance_state_array[i][j] = [];
                if (element.subscriber.address.state != '') {
                  this.getState('insurance', element.subscriber.address.state, i, j);
                }
              }
            });
          } else if (element.guarantor) {
            if (element.guarantor.address) {
              if (this.insurance_state_array[i] == undefined) {
                this.insurance_state_array[i] = [];
              }
              if (element.guarantor.address.state != '') {
                this.getState('guarantor', element.guarantor.address.state, i, null);
              }
            }
          }
        });

      }
      return 'three';
    })());
  }
  // functionFour(): Promise<any> {
  //   return Promise.resolve((() => {
  //     return 'four';
  //   })());
  // }
  initialStateNameLoad() {
    this.patientState().then(data1 => {
      //console.log(data1);
      this.accidentState().then(data2 => {
        //console.log(data2);
        this.insuranceState().then(data3 => {
          //console.log(data3);
          // this.functionFour();
        })
      });
    });
  }

  // ngOnDestroy() {
  // this.stateActiveItem = '';
  // }


  getState(type, searchKey, index, insurance_index) {
    this.taskService.initialStateList().then((data) => {
      let filtered_data = [];
      let searchResult = [];
      let stateval = this.taskService.stateList;
      filtered_data = this.taskService.filterByValue(stateval, searchKey);
      for (let i in filtered_data) {
        if (parseInt(i) < 5) {
          searchResult[i] = {
            id: filtered_data[i].state_code,
            text: filtered_data[i].state_code
          };
        }
        if (type == 'patient') {
          this.patient_state_array = searchResult;
          this.stateActiveItem = filtered_data[i].state_code;
        }
        if (type == 'accident' && index) {
          this.accident_state_array[index] = searchResult;
        }
        if (type == 'insurance' && index) {
          this.insurance_state_array[index][insurance_index] = searchResult;
        }
        if (type == 'guarantor') {
          this.insurance_state_array[index] = searchResult;
        }
        // edit
        // if (this.patientDemographData && this.patientDemographData.address) {
        //   if (filtered_data[i].state_code == this.patientDemographData.address.patient_state) {
        //     this.stateActiveItem = filtered_data[i].state_code;
        //   }
        // }
      }
    });
  }
  selectedAccidentState(value, index) {
    this.patient.cases[index].accident_state = value;
  }

  getPayers(searchKey, insIndex) {
    this.insuranceTabIndex = insIndex;
    //console.log('searchKey', searchKey, this.insurance_practice);
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
      this.taskService.listpayers(searchKey, this.insurance_practice).subscribe(result => {
        //console.log('resultresultresult', result, this.caseindex);
        if (result.status == 'success') {
          for (let i in result.results) {
            this.payer_array_item[i] = [];
            if (this.getPayersFlag) {
              //console.log('insuranceTabIndex', this.insuranceTabIndex);
              this.payer_array_item[this.insuranceTabIndex].push({
                id: result.results[i]._id,
                text: result.results[i].payer_name,
                payer_id: result.results[i].payer_id,
                _id: result.results[i].payer_name + '^' + result.results[i]._id
              });
            } else {
              //console.log('initial loop');
              this.payer_array_item[i].push({
                id: result.results[i]._id,
                text: result.results[i].payer_name,
                payer_id: result.results[i].payer_id,
                _id: result.results[i].payer_name + '^' + result.results[i]._id
              });
            }
          }
          //console.log('payer_array_itempayer_array_item', this.payer_array_item);
        }
      });
    }, 400);
  }

  insurancecompanyOnSelect(i, selected_value) {

    //console.log('Insurance selected', this.insuranceTabIndex, selected_value);
    //console.log(this.payer_array_item[i]);
    const selected = this.payer_array_item[i].find(x => x.id == selected_value);
    if (selected) {
      //console.log('selected', selected);
      this.patient.cases[this.caseindex].insurances[i].insurance_payer_id = selected_value;
      this.patient.cases[this.caseindex].insurances[i].name = selected.text;
      this.patient.cases[this.caseindex].insurances[i].id = selected.payer_id;
    } else {
      //console.log('not selected', i, selected_value);
      this.patient.cases[this.caseindex].insurances[i].name = 'Not set';
      this.patient.cases[this.caseindex].insurances[i].id = '';
    }

    // let x = selected.split('^');
    // this.patient.cases[this.caseindex].insurances[i].name = x[0];
    // this.patient.cases[this.caseindex].insurances[i].insurance_payer_id = x[1].trim();
  }

  insurancecompanyOnBlur() {
    // console.log("fsafkjsaf")
    // console.log('insurance lost focus', i, this.patient.cases, this.patient.cases[i].insurances);
    // repopulate the search with the original selected value
    for (let i in this.patient.cases) {
      if (this.patient.cases[i].insurances) {
        for (let j in this.patient.cases[i].insurances) {
          let insurance = this.patient.cases[i].insurances[j];
          this.payer_array_item[j] = [];
          if (insurance.insurance_payer_id) {
            this.payer_array_selected_ids[j] = insurance.insurance_payer_id;
            this.payer_array_item[j].push({
              id: insurance.insurance_payer_id,
              text: insurance.name,
              payer_id: insurance.carrier_id
            });
          }
        }
      }
    }
  }

  // tabChange(tabIndex) {
  //   this.insuranceTabIndex = (tabIndex.target.innerText == 'Primary') ? 0 : (tabIndex.target.innerText == 'Secondary') ? 1 : (tabIndex.target.innerText == 'Tertiary') ? 2 : 0 ;
  //   console.log('//////////////', this.insuranceTabIndex);
  // }
  stateSelectedValue(stateCode) {
    this.formValid(this.myform);
    this.selectedStateCode = stateCode;
  }

  stateSelectedValueInsurance(stateCode, caseindex, index) {
    this.formValid(this.myform);
    this.selectedStateCode = stateCode;

    if (caseindex == 0) {
      this.patient.cases[caseindex].guarantor.address.state = stateCode;
    } else {
      this.patient.cases[caseindex].insurances[index].subscriber.address.state = stateCode;
    }

  }

  convertDateFormat(str) {
    return moment(str).format('YYYY-MM-DD');
  }

  addpolicy() {
    if (this.patient.cases[this.caseindex].insurances.length > 0 && this.patient.cases[this.caseindex].insurances.length < 3) {
      if (this.insurance_state_array[this.caseindex][this.insurance_state_array[this.caseindex].length] == undefined) {
        this.insurance_state_array[this.caseindex][this.insurance_state_array[this.caseindex].length] = [];
      }
      let sequenceNo = this.patient.cases[this.caseindex].insurances.length + 1;
      if (sequenceNo == 3) { this.addPolicyIndicator = false; }
      this.patient.cases[this.caseindex].insurances.push({

        'sequence': sequenceNo,
        'responsibility_sequence': sequenceNo.toString(),
        'insurance_payer_id': '',
        'address': {
          'street_line_1': '',
          'street_line_2': '',
          'city': '',
          'state': '',
          'zip': ''
        },
        'claim_filing_indicator': '',
        'id': '',
        'name': '',
        'prior_authorization_number': '',
        'referral_number': '',
        'secondary_id': '',
        'secondary_id_type': '',
        'subscriber': {
          'address': {
            'street_line_1': '',
            'street_line_2': '',
            'city': '',
            'state': '',
            'zip': ''
          },
          'dob': '',
          'first_name': '',
          'gender': '',
          'group_id': '',
          'group_name': '',
          'id': '',
          'insurance_type_code': '',
          'last_name': '',
          'middle_name': '',
          'phone': '',
          'relationship': '18', // default to self
          'ssn': ''
        },
        'patient_is_subscriber': true // default to self
      });
    } else {
      this.addPolicyIndicator = false;
    }
  }

  selectedCase() {
    this.casetype = this.patient.cases[this.caseindex].case_type;
    // this.patient.cases[this.caseindex].isactive=false;
    // if(this.patient.cases[this.caseindex].accident_state) {
    //   this.accident_state_name[this.caseindex] = this.patient.cases[this.caseindex].accident_state;
    // }
    // console.log(".........................", this.accident_state_name[this.caseindex], this.accident_state_name)
    
    // for (let i in this.patient.cases) {
    //   if (i == this.caseindex) {
    //     this.patient.cases[i].isactive = true;
    //   } else {
    //     this.patient.cases[i].isactive = false;
    //   }
    // }


    if (this.casetype != 'Self-Pay' && this.patient.cases[this.caseindex].insurances.length < 3) {
      this.addPolicyIndicator = true;
    } else {
      this.addPolicyIndicator = false;
    }
  }

  onConfirm() {
    if (this.isNewPatient == 'newPatient') {
      delete this.patient._id;
    } else {
      setTimeout(() => {
        for (let i in this.patient.cases) {
            if (i == this.caseindex) {
              this.patient.cases[i].isactive = true;
            } else {
              this.patient.cases[i].isactive = false;
            }
          }
      }, 500);
      if (this.patient.cases[this.caseindex].isactive == true) {
        this.casetype = this.patient.cases[this.caseindex].isactive = false;
      } else {
        this.casetype = this.patient.cases[this.caseindex].isactive = true;
      }
    }
    this.modalClose();
  }

  onCancel() {
    this.modalClose();
  }

  editCaseData() {
    if (this.patient.cases[this.caseindex].case_name != '' && this.patient.cases[this.caseindex].case_name.trim()) {
      this.validateNewCase = false;

      if (this.patient.cases[this.caseindex].case_type != 'Self-Pay' && this.patient.cases[this.caseindex].insurances.length < 3) {
        this.addPolicyIndicator = true;
      } else {
        this.addPolicyIndicator = false;
      }
      this.modalClose();
      this.sharedService.pushToaster('success', 'updated', 'Case updated successfully');
    } else {
      this.validateNewCase = true;
    }

  }

  ConfirmPopup(confirmpopup: TemplateRef<any>, type) {
    if (type == '') {
      if (this.patient.cases[this.caseindex].isactive == true) {
        this.messageInfo = 'Are you sure want to Deactivate case ?';
      } else {
        this.messageInfo = 'Are you sure want to Activate case ?';
      }
    } else {
      this.messageInfo = 'Are you sure want to create new patient ?';
      this.isNewPatient = type;
    }
    if (type == 'editPatient') {
      this.fieldEdit = true;
      this.page = 0;
      this.currentPage = 0;
      this.searchValue = '';
      this.patientList = [];
      this.setPatient = [];
      this.popupTitle = 'Edit patient confirmation';
    }
    if (type != 'editPatient') {
      this.SharedPopupService.show(confirmpopup, {
        animated: true,
        backdrop: 'static'
      });
    } else {
      this.SharedPopupService.show(confirmpopup, {
        animated: true,
        backdrop: 'static',
        class: 'modal-lg'
      });
    }
  }

  getPatientsList(from) {
    if (from != 'pagination') {
      this.page = 0;
      this.currentPage = 1;
      if (this.searchValue.length > 2) {
        this.patientsData();
      } else {
        this.setPatient = [];
        this.patientList = [];
        // this.ngUnsubscribe.next();
      }

    } else {
      this.patientsData();
    }
  }

  patientsData() {
    this.searchFlag = false;
    let patientData = {
      searchKey: this.searchValue.trim(),
      page: this.page,
      limit: this.defaultLimit,
      practice_id: this.patient.practice_id
    };
    // console.log(patientData);
    // this.ngUnsubscribe.next();
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
      this.patientService.allPatients(patientData).subscribe(data => {
        if (data.status == 'success') {
          if (data.data.totalData && data.data.totalCount) {
            this.searchFlag = true;
            let dataParse = data.data;
            this.patientList = dataParse.totalData;
          } else {
            this.searchFlag = true;
            this.patientList = [];
          }
        } else {
          this.searchFlag = true;
          this.patientList = [];
        }
      });
    }, 400);
  }

  editPatientAction(reason: string, obj?: any) {
    if (reason == 'editPatient') {
      this.fieldEdit = false;
      this.isNewPatient = '';
    }
    if (reason == 'switchPatient') {
      this.fieldEdit = false;
      this.patient = this.setPatient;
      this.isNewPatient = '';
    }
    if (reason == 'newPatient') {
      this.myform.form.reset();
      this.fieldEdit = false;
      this.patient = {
        'last_name': '',
        'first_name': '',
        'middle_name': '',
        'dob': '01/01/1991',
        'gender': '',
        'address': {
          'street_line_1': '',
          'street_line_2': '',
          'city': '',
          'state': '',
          'zip': ''
        },
        'homephone': '',
        'workphone': '',
        'cellphone': '',
        'email': '',
        'cases': [
          {
            'case_name': 'self',
            'case_type': 'Self-Pay',
            'description': 'Self-Pay',
            'isactive': true,
            'guarantor': {
              'last_name': '',
              'first_name': '',
              'middle_name': '',
              'relationship': '',
              'address': {
                'street_line_1': '',
                'street_line_2': '',
                'city': '',
                'state': '',
                'zip': ''
              },
              'notes': ''
            }
          }
        ]
      };
      this.isNewPatient = 'newPatient';
      this.caseindex = 0;
    }
    this.formValid(this.myform)
    this.SharedPopupService.dismiss();
  }

  savecases() {
    this.validateNewCase = true;
    if (this.casename != undefined && this.casename.trim() && this.selectedcasetype) {
      if (!this.patient.cases) {
        this.patient.cases = [];
      }
      this.patient.cases.push({
        'case_name': this.casename,
        'case_type': this.selectedcasetype,
        'description': this.casedescription,
        'isactive': false,
        'insurances': [
          {
            'sequence': 1,
            'responsibility_sequence': '1',
            'insurance_payer_id': '',
            'claim_filing_indicator': '',
            'id': '',
            'name': '',
            'prior_authorization_number': '',
            'referral_number': '',
            'secondary_id': '',
            'secondary_id_type': '',
            'subscriber': {
              'address': {
                'street_line_1': '',
                'street_line_2': '',
                'city': '',
                'state': '',
                'zip': ''
              },
              'dob': '',
              'first_name': '',
              'gender': '',
              'group_id': '',
              'group_name': '',
              'id': '',
              'insurance_type_code': '',
              'last_name': '',
              'middle_name': '',
              'phone': '',
              'relationship': '18',
              'ssn': ''
            },
            'patient_is_subscriber': true
          }
        ]
      });
      this.validateNewCase = false;
      this.modalClose();
      this.casename = '';
      this.selectedcasetype = '';
      this.casedescription = '';
      this.sharedService.pushToaster('success', 'Success', 'Case added successfully');
    } else {
      this.validateNewCase = true;
      this.casename = undefined;
    }
    this.accident_state_array[this.patient.cases.length - 1] = [];
    if (this.insurance_state_array[this.patient.cases.length - 1] == undefined) {
      this.insurance_state_array[this.patient.cases.length - 1] = [];
      if (this.insurance_state_array[this.patient.cases.length - 1][0] == undefined) {
        this.insurance_state_array[this.patient.cases.length - 1][0] = [];
      }
    }

  }

  findChanges(taskinfo, patientInfo) {
    //console.log(taskinfo, patientInfo);
    let taskData = JSON.parse(JSON.stringify(taskinfo)); // _.cloneDeep(taskinfo);
    //  let chargeData = JSON.parse(JSON.stringify(chargeinfo)); // _.cloneDeep(chargeinfo);
    let patientData = JSON.parse(JSON.stringify(patientInfo)); // _.cloneDeep(patientInfo);
    // check whether patient is new or already existing
    if (patientData._id) {
      //console.log('patient already existing');
      this.dataUpdates['new_patient_created'] = false;
    } else {
      //console.log('new patient');
      this.dataUpdates['new_patient_created'] = true;
    }

    // check whether patient info updated
    let patient_info;
    if (this.fromModule == 'patient') {
      patient_info = JSON.parse(JSON.stringify(taskData));
    } else {
      patient_info = JSON.parse(JSON.stringify(taskData.charge_info[0].patient)); // Object.assign({},taskData.charge_info[0].patient);
    }
    let mod_patient_info;
    mod_patient_info = JSON.parse(JSON.stringify(patientData)); // Object.assign({},patientData);
    delete patient_info.cases;
    delete mod_patient_info.cases;

    let ini_patient_info = JSON.stringify(patient_info);
    let modified_patient_info = JSON.stringify(mod_patient_info);
    if (ini_patient_info === modified_patient_info) {
      this.dataUpdates['patient_demograpgy_updated'] = false;
      //console.log('patient info is same');
    } else {
      this.dataUpdates['patient_demograpgy_updated'] = true;
      //console.log('patient info is updated');
    }

    // check whether cases info updated
    let ini_case_info;
    if (this.fromModule == 'patient') {
      ini_case_info = JSON.stringify(taskData.cases);
    } else {
      ini_case_info = JSON.stringify(taskData.charge_info[0].patient.cases);
    }
    let modified_case_info = JSON.stringify(patientData.cases);
    if (ini_case_info === modified_case_info) {
      this.dataUpdates['cases_updated'] = false;
      //console.log('cases info same');
    } else {
      let casesLength1 = 0;
      let casesLength2 = 0;

      if (this.fromModule == 'patient') {
        if (taskData.cases) {
          casesLength1 = taskData.cases.length;
        }
      } else {
        casesLength1 = taskData.charge_info[0].patient.cases.length;
      }
      casesLength2 = patientData.cases.length;
      if (casesLength1 == casesLength2) {
        this.dataUpdates['cases_added'] = false;
        this.dataUpdates['cases_updated'] = true;
        //console.log('cases info updated');
      } else {
        this.dataUpdates['cases_added'] = true;
        //console.log('new cases added');
      }
    }

    return this.dataUpdates;
  }

  formValid(form) {
    //console.log("formvalid function");
    if ((form.value.address1 && form.value.address1 != '') || (form.value.address2 && form.value.address2 != '')
      || (form.value.city1 && form.value.city1 != '') || (form.value.state1 && form.value.state1 != '') || (form.value.patientZip && form.value.patientZip != '')) {
      if (!form.value.address1 || form.value.address1 == '') {
        form.form.controls['address1'].setErrors({ 'incorrect': true });
      }
      if (!form.value.city1 || form.value.city1 == '') {
        form.form.controls['city1'].setErrors({ 'incorrect': true });
      }
      if (form.form.value.state1 == '' || !form.form.value.state1 || form.form.value.state1 == null) {
        form.form.controls['state1'].setErrors({ 'incorrect': true });
      }
      if (!form.value.patientZip || form.value.patientZip == '') {
        form.form.controls['patientZip'].setErrors({ 'incorrect': true });
      }
    } else {
      form.form.controls['address1'].setErrors(null);
      form.form.controls['city1'].setErrors(null);
      form.form.controls['state1'].setErrors(null);
      form.form.controls['patientZip'].setErrors(null);
      //console.log(form);
    }
  }
  processPatient(form) {
    this.formValid(form);
    if (form.form.valid) {
      if (this['validatePatientDOB'] == false || this['pastDateValidPatientDOB'] == true || this['validateAccidentDate'] == false || this['pastDateValidAccidentDate'] == true) {
        this.sharedService.pushToaster('error', "Error", "Date validation error");
        return;
      }
      if (this['validatePrimary'] == false || this['validateSecondary'] == false || this['validateTertiary'] == false || this['pastDateValidPrimary'] == true || this['pastDateValidSecondary'] == true || this['pastDateValidTertiary']) {
        this.sharedService.pushToaster('error', "Error", "Date validation error");
        return;
      }

      let mName = '';
      if (this.patient.address.zip) {
        let tempZip = this.patient.address.zip.split('-');
        this.patient.address.zip = tempZip.join('');
      }
      if (this.patient.cases[this.caseindex].guarantor && this.patient.cases[this.caseindex].guarantor.address && this.patient.cases[this.caseindex].guarantor.address.zip) {
        let tempZip = this.patient.cases[this.caseindex].guarantor.address.zip.split('-');
        this.patient.cases[this.caseindex].guarantor.address.zip = tempZip.join('');
      }
      if (this.patient.cases[this.caseindex].insurances) {
        this.patient.cases[this.caseindex].insurances.forEach((element, i) => {
          if (element.subscriber.address.zip) {
            let tempZip = element.subscriber.address.zip.split('-');
            this.patient.cases[this.caseindex].insurances[i].subscriber.address.zip = tempZip.join('');
          }
        });
      } else if (this.patient.cases[this.caseindex].guarantor && this.patient.cases[this.caseindex].guarantor.address) {
        let tempZip = this.patient.cases[this.caseindex].guarantor.address.zip.split('-');
        this.patient.cases[this.caseindex].guarantor.address.zip = tempZip.join('');
      }
      /*if(this.patient.homephone) {
        let phoneTemp = this.patient.homephone.match(/\d+/g);
        this.patient.homephone = phoneTemp.join('');
      }
      if(this.patient.workphone) {
        let workphoneTemp = this.patient.workphone.match(/\d+/g);
        this.patient.workphone = workphoneTemp.join('');
      }
      if(this.patient.cellphone) {
        let cellphoneTemp = this.patient.cellphone.match(/\d+/g);
        this.patient.cellphone = cellphoneTemp.join('');
      }*/
      // for (let number_type of ['phone', 'homephone', 'workphone', 'cellphone']) {
      //   if (this.patient[number_type]) {
      //     let pn = new PhoneNumber(this.patient[number_type], 'US');
      //     this.patient[number_type] = (pn.isValid() ? pn.getNumber() : '');
      //   }
      // }
      if (this.patient.middle_name) {
        mName = this.patient.middle_name.charAt(0);
      }
      let fName = this.patient.last_name + ', ' + this.patient.first_name + ' ' + mName;
      this.patient['patient_name'] = fName.trim();

      for (let inscase of this.patient.cases) {
        if (inscase.insurances) {
          for (let insurance of inscase.insurances) {
            if (insurance.patient_is_subscriber) {
              insurance.subscriber.relationship = '18';
            }
          }
        }
      }

      if (this.fromModule == 'patient') {
        // console.log('\n\nTASK DATA:', this.taskData);
        // console.log('\n\nPATIENT:', this.patient);
        // this.patient.dob = moment(this.patient.dob).format('YYYY-MM-DD');
        if (this.patient.last_name == '') {
          this.demograph_lastname_isempty = true;
        }
        // submit patient
        this.dataUpdates = {};

        // find what are the changes made to patient data

        this.findChanges(this.taskData.patient, this.patient);
        this.taskData.patient.patient_name = this.patient.patient_name;
        this.taskData.patient.first_name = this.patient.first_name;
        this.taskData.patient.last_name = this.patient.last_name;
        this.taskData.patient.middle_name = this.patient.middle_name;
        this.taskData.patient.dob = this.patient.dob;
        this.taskData.patient.gender = this.patient.gender;

        let chargeObject = {
          charge_id: this.taskData.charge_id
        };

        delete this.patient.activities;
        let data = {
          patient: this.patient,
          charge: chargeObject,
          dataUpdates: this.dataUpdates
        };
        this.patientService.savePatientInfo(data).subscribe(res => {
          if (res.status == 'success') {
            this.sharedService.pushToaster('success', res.status, 'Patient information submitted successfully');
            data.patient._id = res.patient_id;
            // console.log('\nRESPONSE:', data);
            // let tmp = {};
            // tmp['activity_time'] = new Date().toISOString();
            // tmp['activity_user'] = this.DataHelper.getItem('userid');
            // tmp['description'] = 'Patient demographics information updated';
            // tmp['type'] = 'Patient activity';
            // this.activities.unshift(tmp);
            // data.patient.tmp = tmp;
            this.updateActivityAfterSuccess();
            data.patient.activityLog = this.activities;

            // validate charge
            // this.taskService.validateChargeInsurance(this.taskData.charge_id, res.chargeinstanceid).subscribe(validateResponse => {
            //   if(validateResponse.result && validateResponse.result[0]){
            //     data.patient.validations = validateResponse.result[0].validations;
            //   }
            //   this.submit.emit(data);
            // });

            this.submit.emit(data);
          } else {
            this.sharedService.pushToaster('error', res.status, res.message);
          }
        });
      } else {
        // this.patient.dob = moment(this.patient.dob).format('YYYY-MM-DD');
        if (this.patient.last_name == '') {
          this.demograph_lastname_isempty = true;
        } else {
          if (this.patient.last_name == '') {

          }
          // this.submit.emit();
        }
        // submit patient
        this.dataUpdates = {};
        let isValid = true;
        let validationMessage = '';

        this.taskData.charge.patient_firstname = this.taskData.patient.first_name = this.patient.first_name;
        this.taskData.charge.patient_lastname = this.taskData.patient.last_name = this.patient.last_name;
        this.taskData.charge.patient_middlename = this.taskData.patient.middle_name = this.patient.middle_name;
        this.taskData.charge.patient_dob = this.taskData.patient.dob = this.patient.dob;
        this.taskData.charge.gender = this.taskData.patient.gender = this.patient.gender;

        // find what are the changes made to patinet and charge data
        // this.findChanges(this.taskData, this.taskData.patient);
        this.findChanges(this.taskData, this.patient);
        // console.log(this.taskData,this.taskData.charge,this.taskData.patient);
        if (this.dataUpdates['patient_demograpgy_updated'] || this.dataUpdates['cases_updated'] || this.dataUpdates['cases_added']) {

          let chargeObject = {
            charge_id: this.taskData.charge_id,
            selected_case: this.patient.cases[this.caseindex].case_name
          };

          this.patient.cases[this.caseindex].isactive = true;
          if (this.patient.cases[this.caseindex].insurances) {
            this.patient.cases[this.caseindex].insurances.forEach(element => {
              if (element.patient_is_subscriber == true) {
                // element['relationship'] = "18"; // this is duplication
                element.subscriber.relationship = '18';
              } else {
                // element['relationship'] = element.subscriber.relationship; // this is duplicate property
              }
            });
          }
          let data = {
            patient: this.patient,
            charge: chargeObject,
            dataUpdates: this.dataUpdates
          };

          this.taskData.charge.selected_case = this.patient.cases[this.caseindex].case_name;
          this.taskService.processPatient(this.taskData._id, data).subscribe(res => {
            if (res.status == 'success') {
              this.sharedService.pushToaster('success', res.status, 'Patient information submitted successfully');

              // console.log("diagno info",currentTaskChargeInfo.diagnosis_codes_info,this.taskData.charge_info[0].diagnosis_codes_info)
              data.patient._id = res.patient_id;
              this.taskData.patient = _.assign({}, this.taskData.patient, this.patient);
              this.taskData.patient.patient_name = (this.patient.last_name + ", " + this.patient.first_name + (this.patient.middle_name != "" ? " " + this.patient.middle_name.charAt(0) : "")).trim();
              this.patientData.emit(this.patient);
              // validate charge
              this.taskService.validateChargeInsurance(this.taskData.charge_id, res.chargeinstanceid).subscribe(validateResponse => {
                if (validateResponse.result && validateResponse.result[0]) {
                  data.patient.validations = validateResponse.result[0].validations;
                }
                this.submit.emit(data);
              });


              // this.submit.emit(data);
              this.updateActivityAfterSuccess();
            } else {
              this.sharedService.pushToaster('error', res.status, res.message);
            }
          });
        }
      }
    }
  }



  copyAddressFromPatient() {
    this.patient.cases[this.caseindex].guarantor.address = Object.assign({}, this.patient.address);
    if (this.patient.cases[this.caseindex].guarantor.address && this.patient.cases[this.caseindex].guarantor.address.state && this.patient.cases[this.caseindex].guarantor.address.state != '') {
      this.getState('guarantor', this.patient.cases[this.caseindex].guarantor.address.state, 0, null);
    }
    // this.patient.cases[this.caseindex].guarantor.address.street_line_2 = this.patient.address.street_line_2;
    // this.patient.cases[this.caseindex].guarantor.address.city = this.patient.address.city;
    // this.patient.cases[this.caseindex].guarantor.address.state = this.patient.address.state;
    // this.patient.cases[this.caseindex].guarantor.address.zip = this.patient.address.zip;
  }

  undoChanges() {
    // console.log("this.taskService.currentTaskChargeInfo",this.taskService.currentTaskChargeInfo)
    // console.log("this.taskService.currentTaskChargeInfo",this.taskData.charge_info[0])
    // this.patient = JSON.parse(JSON.stringify(this.taskData.charge_info[0].patient));
    // this.ngOnChanges();
    this.loadData();
  }
  datechangedata(value, from, index) {
    let dateSplit = value.split('/');
    let datePattern = '^[0-9]{2}/[0-9]{2}/[0-9]{4}$';
    let regExVal = new RegExp(datePattern);
    let patternMatchBool = regExVal.test(value);
    let inputDateFormate = `${dateSplit[2]}-${dateSplit[0]}-${dateSplit[1]}`;
    let compareDate = new Date(inputDateFormate);
    let isFuture = moment().diff(compareDate);

    if (value != undefined ) {
      let date,dynamicvar,pastDateValid=''
      if( this.country=="US"){
         date = moment(value, ['MM/DD/YYYY', 'YYYY-MM-DD']);
         dynamicvar = 'validate' + from;
         pastDateValid = 'pastDateValid' + from;
      }else{
        date = moment(value, ['DD/MM/YYYY', 'YYYY-MM-DD']);
        dynamicvar = 'validate' + from;
       pastDateValid = 'pastDateValid' + from;
      }
      if (index >= 0) {
        this[dynamicvar] = date.isValid();
        this[pastDateValid] = false;
        this[pastDateValid] = (isFuture < 0) ? true : false;
        if (dateSplit[2] == '0000' || patternMatchBool == false) {
          this[dynamicvar] = false;
        }

      } else {
        this[dynamicvar] = date.isValid();
        this[pastDateValid] = false;
        this[pastDateValid] = (isFuture < 0) ? true : false;
        if (dateSplit[2] == '0000' || patternMatchBool == false) {
          this[dynamicvar] = false;
        }
      }

    }
  }

  // datechangedata(value) {
  //   if (value != undefined) {
  //     let date = moment(value, ['MM/DD/YYYY', 'YYYY-MM-DD']);
  //     let today = moment();
  //     this.doberror = date.isValid();
  //     if (today.diff(date) < 0) {
  //       this.doberror = false;
  //     }
  //   }
  // }
  updateActivityAfterSuccess() {
    // for (let number_type of ['phone', 'homephone', 'workphone', 'cellphone']) {
    //   if (this.patient[number_type] && this.patient[number_type] != '') {
    //     let pn = new PhoneNumber(this.patient[number_type], 'US');
    //     this.patient[number_type] = (pn.isValid() ? pn.getNumber('significant') : '');
    //   }
    // }
    if (this.dataUpdates.patient_demograpgy_updated) {
      let tmp = {};
      tmp['activity_time'] = new Date().toISOString();
      tmp['activity_user'] = this.DataHelper.getItem('userid');
      tmp['description'] = 'Patient demographics information updated';
      tmp['type'] = 'Patient activity';
      this.activities.unshift(tmp);
    }
    if (this.dataUpdates.cases_added || this.dataUpdates.cases_updated) {
      let tmp = {};
      tmp['activity_time'] = new Date().toISOString();
      tmp['activity_user'] = this.DataHelper.getItem('userid');
      tmp['description'] = 'Case information updated';
      tmp['type'] = 'Patient activity';
      this.activities.unshift(tmp);
    }
    if (this.dataUpdates.selected_case_updated) {
      let tmp = {};
      tmp['activity_time'] = new Date().toISOString();
      tmp['activity_user'] = this.DataHelper.getItem('userid');
      tmp['description'] = 'Case updated for charge submit';
      tmp['type'] = 'Charge activity';
      this.activities.unshift(tmp);
    }
  }

  allowOnlyNum(event: any) {
    let pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.key);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  formResetterFun() {
    //console.log(this.myform.control.markAsUntouched());
  }

  validatephoneno(value, from) {
    try {
      if (value && value.length > 4 && value.length < 18 && value.search(/[a-z]/i) == -1) {
        const phoneNumber = value;
        const regionCode = 'US';
        const number = phoneUtil.parseAndKeepRawInput(phoneNumber, regionCode);

        if (from == 'home') {
          this.homeError = phoneUtil.isValidNumber(number) == false ? true : false;
          if (phoneUtil.getRegionCodeForNumber(number) == 'US') { this.patient.homephone = '+1' + number.values_[2]; }
        }
        if (from == 'work') {
          this.workError = phoneUtil.isValidNumber(number) == false ? true : false;
          if (phoneUtil.getRegionCodeForNumber(number) == 'US') { this.patient.workphone = '+1' + number.values_[2]; }
        }
        if (from == 'cell') {
          this.cellError = phoneUtil.isValidNumber(number) == false ? true : false;
          if (phoneUtil.getRegionCodeForNumber(number) == 'US') { this.patient.cellphone = '+1' + number.values_[2]; }
        }
      } else {
        if (from == 'home') { this.homeError = (value.length == 0) ? false : true; }
        if (from == 'work') { this.workError = (value.length == 0) ? false : true; }
        if (from == 'cell') { this.cellError = (value.length == 0) ? false : true; }
      }
    } catch (e) {
      if (from == 'home') { this.homeError = true; }
      if (from == 'work') { this.workError = true; }
      if (from == 'cell') { this.cellError = true; }
    }
  }
  ngOnDestroy(): void {
    this.SharedPopupService.dismiss();
  }

}
