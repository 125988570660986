import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {

  constructor(private http: HttpClient) { }
  seachFeatures(searchKey, showInactive) {
    return this.http.get<any>(myGlobals.Features_URL + '/' + '?q=' + searchKey + '&showInactive=' + showInactive);
  }
  addNewFeature(FeatureData) {
    return this.http.post<any>(myGlobals.Features_URL, FeatureData);
  }

  editFeature(FeatureData, featureId) {
    return this.http.put<any>(myGlobals.Features_URL + '/' + featureId, FeatureData);
  }
  updateFeaturesStatus(featureId, status) {
    let req = { status: status };
    return this.http.put<any>(myGlobals.Features_URL + '/' + featureId + '/status', req);
  }
  getAccountgroupsAndPractices(featureId) {
    return this.http.get<any>(myGlobals.Features_URL + '/' + featureId);
  }
  mapAccountgroups(id, data) {
    return this.http.post<any>(myGlobals.Features_URL + '/' + id + '/accountgroups', data);
  }
  mapPractices(id, data) {
    return this.http.post<any>(myGlobals.Features_URL + '/' + id + '/practices', data);
  }

  addPractices(id, practice_id) {
    return this.http.post<any>(myGlobals.Features_URL + '/' + id + '/practice/'+practice_id, {});
  }

  deletePractices(id, practice_id) {
    return this.http.delete<any>(myGlobals.Features_URL + '/' + id + '/practice/'+practice_id, {});
  }
  
  fetchPracticeList(searchKey) {
    return this.http.get<any>(myGlobals.Practices_URL + '/?q=' + searchKey + '&showActive=true&pagesize=20');
  }
  deletePractice(feature_id, practice_id) {
    //console.log(practice_id)
    return this.http.delete<any>(myGlobals.Features_URL + '/' + feature_id + '/' + practice_id);
  }
  mapAccgroupsToFeature(feature_id,accgroup_id) {
    return this.http.post<any>(myGlobals.Features_URL + '/' + feature_id + '/accountgroups/' + accgroup_id, '' );
  }
  removeAccgroupsToFeature(feature_id,accgroup_id) {
    return this.http.delete<any>(myGlobals.Features_URL + '/' + feature_id + '/accountgroups/' + accgroup_id);
  }	
}
