import { Component, OnInit, TemplateRef } from '@angular/core';
import { PtpModifierService } from '../../../_services/settings/ptp-modifier.service';
import { SharedPopupService } from '../../../_services/popup.service';
import { SharedService } from '../../../_services/shared.service';
import constants from '../../../constants';


@Component({
  selector: 'app-ppt-modifiers',
  templateUrl: './ppt-modifiers.component.html',
  styleUrls: ['./ppt-modifiers.component.css']
})

export class PptModifiersComponent implements OnInit {
  timeout = null;
  showInactive: boolean;
  modifiersCategoryData: any;
  searchtext: string;
  messageInfo: string;
  selected_Code: any;
  ConfirmPopupfrom: any;
  addNewSkillForm:any;
  ptp_modifers:any;
  popUpfrom: any;
  popupTitle: string;
  code_list: { categoryname: any; tasktype: any; enabled: boolean; };
  UserValidationMsg: any;
  modifier: any;
  selected_Code_index: any;
  contacts: any;
  errorFlag:boolean;
  UserValidationMessage: any;
  selected_applicable_modifier_i: any;
  selected_applicable_modifier: any;
  constants:any;
  loaderIndicator: boolean;
  popupTitle1: string;
  popinfo: any;
  selectedCodeStatus:boolean;
  constructor(public SharedPopupService: SharedPopupService, private sharedService: SharedService, private ptpModifierService: PtpModifierService,) {
   this.modifiersCategoryData=[];
   this.searchtext='';
   this.showInactive=false;
   this.messageInfo='';
   this.errorFlag=false;
   this.loaderIndicator=true;
   this.constants=constants;
   this.selectedCodeStatus=true;
   }

  ngOnInit(): void {
    let status = this.showInactive ? false : true;
    this.getModifiersList(status);
  }
  getModifiersList(status) {
    //this.showInactive = status;
    this.ptpModifierService.ptpModifierList(this.searchtext, status).subscribe((data: any) => {
      this.loaderIndicator=false;
      data.status == 'success' ? this.modifiersCategoryData = data.results : this.modifiersCategoryData = [];
      
    });
  }
  triggerSearch(searchValue: string){
    let status = this.showInactive ? false : true;
        this.getModifiersList(status);
        this.loaderIndicator=false;
}
 
 searchUserList(searchValue: string) {
    let tempBool = false;
    let testPattern = /^[a-zA-Z0-9 ]*$/;
    let regExVal = new RegExp(testPattern);
    tempBool = regExVal.test(searchValue);
    if (tempBool) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(x => {
        this.triggerSearch(searchValue);
      }, 1000);
    }
  }
 
  activeStatus(){
   this.getModifiersList(this.showInactive);
   this.loaderIndicator=false;
  }
  
  openModal(template: TemplateRef<any>,from,item,i) {
    this.popinfo=item;  
    this.selected_Code=item;  
    this.selected_Code_index = i;
    this.popUpfrom = from;
        if(this.popUpfrom =='newCode'){
          this.popupTitle1 = 'Add new code';
          this.ptp_modifers= ''
    } 
    else if (this.popUpfrom == 'editCode'){
      this.popupTitle1 = 'Edit code';
      this.ptp_modifers=item.code
    }
      this.SharedPopupService.show(template, {
        animated: true,
        backdrop: 'static'
      });
  }
  openModifierModal(template: TemplateRef<any>,modifierfrom,item, i, items= null, c_i = null) {
    this.popinfo = item;
    this.selected_Code=item
    this.selected_Code_index = i;
    this.selected_applicable_modifier= items;
    this.selected_applicable_modifier_i=c_i
    this.popUpfrom = modifierfrom;

    if(this.popUpfrom == 'addModifier') {
    this.popupTitle = 'Add new modifiers';
    this.modifier= '';
  } 
  else if(this.popUpfrom == 'editModifier') {
    this.popupTitle = 'Edit modifier';
    this.modifier = items.code
  }
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static'
    });
  }

  modalClose() {
    this.errorFlag=false;
    this.SharedPopupService.dismiss();
  }

  addCategory() {
    if(this.popUpfrom== 'newCode'){
    let codeData = /^[a-zA-Z]+$/.test(this.ptp_modifers);
    if(codeData==false){
        let code_list = {
          "code": this.ptp_modifers
        };
        this.ptpModifierService.addPtpCode(code_list).subscribe(data => {
          this.contacts = data;          
          this.UserValidationMessage = this.contacts.Message;
          if(data.status=="success"){
            this.modifiersCategoryData.unshift(data.results);
            this.modalClose();
            this.sharedService.pushToaster('success', 'Success', 'New Code added successfully');
          }else{
            this.sharedService.pushToaster('error', 'Error', this.UserValidationMessage);
          }
        });
        this.errorFlag=false;
      }else{
        this.errorFlag=true;
      }
    }
    if(this.popUpfrom=='editCode'){
      let codeData = /^[a-zA-Z]+$/.test(this.ptp_modifers);
      if(codeData==false){
        let code_list = {
          "code": this.ptp_modifers,
          "enabled": this.selected_Code.enabled
        };
        this.ptpModifierService.activateCode(this.selected_Code._id,code_list).subscribe(data => {
          this.contacts = data;          
          this.UserValidationMessage = this.contacts.Message;
          if(data.status=="success"){
            this.modifiersCategoryData[this.selected_Code_index].code =this.ptp_modifers
            this.modalClose();
            this.sharedService.pushToaster('success', 'Success', 'CPT Code updated successfully');
          }else{
            this.sharedService.pushToaster('error', 'Error', this.UserValidationMessage);
          }
        });
        this.errorFlag=false;
      }else{
        this.errorFlag=true;
      }
    }
  }

  addModifiers() {
    if(this.popUpfrom == 'addModifier') {
    let code_list = {
      "code_id":this.selected_Code._id,
      "modifier": this.modifier
    };
    this.ptpModifierService.addPtpmodifer(code_list).subscribe(data => {
      this.contacts = data;      
      this.UserValidationMsg = this.contacts.message;
      if(data.status=="success"){
        this.modifiersCategoryData[this.selected_Code_index].applicableModifier.unshift({code: this.modifier, status: 1});
        this.modalClose();
        this.sharedService.pushToaster('success', 'Success', 'New Modifiers added successfully');
      }else{
        this.sharedService.pushToaster('error', 'Error', this.UserValidationMsg);
      }
    });
  }
  if(this.popUpfrom == 'editModifier') {
    let code_list = {
      "modifier": this.modifier,
      "status":this.selected_applicable_modifier.status
    };
    this.ptpModifierService.activateModifiers(this.selected_Code._id,this.selected_applicable_modifier.code,code_list).subscribe(data=>{
      this.constants = data;
      this.UserValidationMessage = this.constants.message;
      if (data.status == 'success') {
        this.modifiersCategoryData[this.selected_Code_index].applicableModifier[this.selected_applicable_modifier_i].code = this.modifier;
        this.modalClose();
        this.sharedService.pushToaster('success', 'Success', 'Modifier Updated Successfully');
      } else {
        this.sharedService.pushToaster('error', 'Error', this.UserValidationMessage);
      }
    });
  }
  }
 ConfirmPopup( Confirmtemplate: TemplateRef<any>, i, code){
  this.selected_Code=code
  this.selected_Code_index = i;
  this.selectedCodeStatus= code.enabled;
    if (this.selectedCodeStatus == true) {
      this.messageInfo = 'Are you sure want to Deactivate the Code ?';
    } else {
      this.messageInfo = 'Are you sure want to Activate the Code ?';
    }
  
  this.SharedPopupService.show(Confirmtemplate, {
    animated: true,
    backdrop: 'static',
  });
}
onConfirm() {
  if (this.selected_Code.enabled== false) {
    this.ptpModifierService.activateCode(this.selected_Code._id,{enabled:true}).subscribe(result => {
      if (result.status == 'success') {
        this.modifiersCategoryData[this.selected_Code_index].enabled = false;
        this.modifiersCategoryData.splice(this.selected_Code_index,1);
        this.sharedService.pushToaster('success', 'Success', 'Activate Code Successfully');
        this.modalClose();
      }
    });
  } else {
    this.ptpModifierService.deactivateCode(this.selected_Code._id,{}).subscribe((result: any) => {
      if (result.status == 'success') {
        this.modifiersCategoryData.splice(this.selected_Code_index,1);
        this.sharedService.pushToaster('success', 'Success', 'Deactivate Code Successfully');
        this.modalClose();
      }
    });
  }
}
ConfirmPopupcodelist( Confirmtemplate: TemplateRef<any>, item,items, i,c_i){
  this.selected_Code=item
  this.selected_Code_index = i;
  this.selected_applicable_modifier= items;
  this.selected_applicable_modifier_i=c_i
  let enabled= items.status;
    if (enabled == 1) {
      this.messageInfo = 'Are you sure want to Deactivate the Modifier ?';
    } else {
      this.messageInfo = 'Are you sure want to Activate the Modifier ?';
    }
  
  this.SharedPopupService.show(Confirmtemplate, {
    animated: true,
    backdrop: 'static',
  });
}
updateSatus() {
  if (this.selected_applicable_modifier.status== 0) {
    this.ptpModifierService.activateModifiers(this.selected_Code._id,this.selected_applicable_modifier.code,{status :1}).subscribe(result => {
      if (result.status == 'success') {
        this.modifiersCategoryData[this.selected_Code_index].applicableModifier[this.selected_applicable_modifier_i].status = 1;
        this.sharedService.pushToaster('success', 'Success', 'Activate Modifiers Successfully');
        this.modalClose();
      }
    });
  } else {
    this.ptpModifierService.deactivateModifiers(this.selected_Code._id,this.selected_applicable_modifier.code,'').subscribe((result: any) => {
      if (result.status == 'success') {
        this.modifiersCategoryData[this.selected_Code_index].applicableModifier[this.selected_applicable_modifier_i].status = 0;
        this.sharedService.pushToaster('success', 'Success', 'Deactivate Modifiers  Successfully');
        this.modalClose();
      }
    });
  }
}
}