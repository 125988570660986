import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { from } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable({
  providedIn: 'root'
})
export class DataHelperService {
  salt: any;
  toggleChecker:Boolean;
  constructor(private authenticationService: AuthenticationService, private jwtHelperService: JwtHelperService) {

  }
  setItem(key, value) {
    if (Array.isArray(value) == true || (typeof (value) == 'object' && value != null)) {
      value = JSON.stringify(value);
      value = '<parsedata>' + value;
    }
    let encdata = CryptoJS.AES.encrypt(value, this.salt).toString();
    localStorage.setItem(key, encdata);
  }
  getItem(key): any {
    let encdata = localStorage.getItem(key);
    let parsedData = '';
    if (!encdata) {
        return null;
    }
    try {
      parsedData = CryptoJS.AES.decrypt(encdata, this.salt).toString(CryptoJS.enc.Utf8);
      if (parsedData.indexOf('<parsedata>') == 0) {
        // console.log(parsedData.substr(11));
        parsedData = JSON.parse(parsedData.substr(11));
      }
    } catch (err) {
//console.log(err);
    }
    return parsedData;
  }
  setSalt() {
    if (!this.salt || this.salt == '' || this.salt == undefined) {

      if(localStorage.getItem('authRefreshToken')){
        this.salt =localStorage.getItem('authRefreshToken');
      }
     
    }

  }
  setSaltById(id) {
    this.salt =id;
    // if (!this.salt || this.salt == '' || this.salt == undefined) {
    //   let token = this.authenticationService.getToken();
    //   let decodeToken = this.jwtHelperService.decodeToken(token);
    //   if (decodeToken) {
    //     this.salt =  decodeToken.token_code.id;
    //   }
    // } else {
    //   let token = this.authenticationService.getToken();
    //   let decodeToken = this.jwtHelperService.decodeToken(token);
    //   if (decodeToken) {
    //     this.salt =  decodeToken.token_code.id;
    //   }
    // }

  }
}
