import { Component, OnInit, TemplateRef } from '@angular/core';
import { AuthenticationService } from '../../_services/auth/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertConfig } from 'ngx-bootstrap/alert';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DataHelperService } from '../../_services/auth/data-helper.service';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ToasterModule, ToasterService, ToasterConfig } from 'angular2-toaster/angular2-toaster';
import { SharedService } from '../../_services/shared.service';
import { RouteAuthorizationService } from '../../_services/auth/route-authorization.service';
import { PlatformLocation } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgModel } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { LoginService } from '../../_services/login.service';
import { SharedPopupService } from '../../_services/popup.service';


class User {
  name: string;
  password: string;
}

class TempPass {
  tempPassword: string;
  confirmTempPassword: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['login.scss'],
  providers: [ToasterService]
})
export class LoginComponent implements OnInit {
  router: Router;
  public user: User;
  public tempPass: TempPass;
  seconds: number;
  lockedusersList: any[];
  userlocktimercount_show: any;
  userLockTime: number;
  userlocktimercount: any;
  currentLockedUsername: string;
  loginresponse: string;
  logoutMessage$: Observable<any>;
  isTemporaryFlag: any;
  logoUrl: string;
  tempUserid: any;
  tempPassword: any;
  tempNewPassword: any;
  tempAuthToken: any;
  loginresponse_success: any;
  loginresponse_error: any;
  isPasswordExpired: any;
  isPasswordOtp: any;
  brand: any;
  isLoadingLogin:boolean;
  otpType: any;
  userInfo: any;
  temp_password: string;
  sendOtp: any;


  constructor(private authenticationService: AuthenticationService,
    private routers: Router, private route: ActivatedRoute,
    private jwtHelperService: JwtHelperService,
    private dataHelperService: DataHelperService,
    private _router: Router,
    private sharedService: SharedService,
    public routeAuthorizationService: RouteAuthorizationService,
    private platformLocation: PlatformLocation,
    public SharedPopupService: SharedPopupService,
    public loginService: LoginService
  ) {
    this.otpType = '';
    this.user = {
      name: '',
      password: ''
    };
    this.tempPass = {
      tempPassword: '',
      confirmTempPassword: ''
    };
    this.isTemporaryFlag = false;
    this.loginService.isPasswordExpired = false;
    this.loginService.isPasswordOtp = false;
    this.lockedusersList = [];
    this.seconds = 0;
    this.userlocktimercount = 0;
    if (this.authenticationService.getToken() != null) {
      if(this.dataHelperService.getItem('userRoles')=="Portal ART User RBAC")
      {
        this.routers.navigate(['/task/assigned-to-me']);
      }
      else{
        this.routers.navigate(['/dashboard']);
      }
    } else {
      this.authenticationService.clear();
    }
    this.router = _router;
    this.loginService.loginresponse = '';
    this.isLoadingLogin = false;
  }

  ngOnInit() {
    if (this.authenticationService.logoutMsg) {
      this.authenticationService.logoutMsg = undefined;
    }

    this.brand = this.dataHelperService.getItem('userBrand');
    if (this.platformLocation.hostname.includes('globalhealthcareresource.io') || this.brand == 'Global' || this.platformLocation.hostname == 'localhost') {
      // this.logoUrl='assets/img/Global-logo-dark.png';
      //this.logoUrl = 'assets/img/ghrfull_logo_no_shadow.webp';
      this.logoUrl = 'assets/img/Global-art-logo.png';
      this.brand = 'Global';
    }
    if (this.platformLocation.hostname.includes('claimocity.io') || this.platformLocation.hostname == 'localhost') {
      this.logoUrl = 'assets/img/Global-art-logo.png';
      this.brand = 'Global';
    }
  }

  login(sendotpTemplate: TemplateRef<any>, model: User, isValid: boolean) {
    localStorage.clear();
    if (isValid == true) {
      this.isLoadingLogin=true;
      this.authenticationService.loginAuth2(model.name, model.password).subscribe(data => {
        if (data.status == 'success') {
          this.isLoadingLogin=false;
            localStorage.setItem('Region',data.region);
          if (data.hasOwnProperty('mfatoken') && data.mfatoken && data.isTemporary == false && data.passwordIsOtp == false) {
            localStorage.setItem('mfa-token', data.mfatoken);
            if (data.hasOwnProperty('otp-type')) {
              localStorage.setItem('otp-type', data['otp-type']);
            }
            if (data.required_otp_option == true) {
              this.SharedPopupService.show(sendotpTemplate, {
                animated: true,
                backdrop: 'static',
              });
            } else {
              this.router.navigate(['mfa-login']);
            }
          } else if (data.hasOwnProperty('mfatoken') && data.mfatoken && (data.isTemporary == true || data.passwordIsOtp == true)) {
            if (data.isTemporary == true && data.hasOwnProperty('secure_code') || data.passwordIsOtp == true && data.hasOwnProperty('secure_code')) {
              this.routers.navigate(['/set-password/' + data.user._id + '/' + data.secure_code]);
            }
          } else {
            // this.loginService.userLoginInformation=data;
            // this.temp_password = model.password
            this.loginService.userBasiclogin(data);
          }
        } else {
          this.isLoadingLogin=false;
          this.loginService.loginresponse = data.message ? data.message : 'Login failed...!';
        }

      });
    }
  }

  changeTemporaryPassword(model: TempPass, isValid: boolean) {
    if (isValid) {
      let strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');
      let password = model.tempPassword;

      if (model.tempPassword == model.confirmTempPassword) {
        if (password.match(strongRegex)) {
          this.tempNewPassword = model.tempPassword;
          let data = {
            'userId': this.tempUserid,
            'password': this.tempPassword,
            'newpassword': this.tempNewPassword
          };

          this.authenticationService.changeTemporaryPassword(data, this.loginService.tempAuthToken).subscribe(resdata => {
            if (resdata.status == 'success') {
              // this.loginresponse_success = 'Password changed successfully';
              //  this.toasterService.pop('success', 'Success', 'Password changed successfully');
              this.sharedService.pushToaster('success', 'Success', 'Password changed successfully');
              setTimeout(() => {
                this.isTemporaryFlag = false;
                this.loginService.loginresponse = '';
              }, 2000);
            } else {
              // this.loginresponse_error = resdata.message;
              this.sharedService.pushToaster('error', 'Failure', resdata.message);
              // this.toasterService.pop('error', 'Failure', resdata.message);
            }
          });

        } else {
          this.loginresponse_error = 'Password should be 8 characters with 1 lowercase, 1 uppercase, 1 special character, 1 numeric';
        }
      } else {
        this.loginresponse_error = 'Password does not match. please try again';
      }
    }
  }

  onCancel() {
    this.isTemporaryFlag = false;
    // this.router.navigate(['/']);
  }
  sendotpValue() {
    // this.otpType = "";
    let userdata = {
      'mfa-token': localStorage.getItem('mfa-token')
    };
    if (this.otpType == '2') {
      localStorage.setItem('otp-type', 'mail');
      this.authenticationService.generateMilOtp(userdata).subscribe(result => {
        if (this.sendOtp) {
          this.SharedPopupService.dismiss();
        }
        //   this.loginprocess(this.loginInfo);
        this.router.navigate(['mfa-login']);
      });
    } else {
      localStorage.setItem('otp-type', 'mobile');
      this.authenticationService.generateOtp(userdata).subscribe(result => {
        if (this.sendOtp) {
          this.SharedPopupService.dismiss();
        }
        // this.loginprocess(this.loginInfo);
        this.router.navigate(['mfa-login']);
      });
    }
  }
  loginprocess(data) {
    if ((data.isTemporary == undefined || data.isTemporary == false) && (data.passwordexpired == undefined || data.passwordexpired == false) && (data.passwordIsOtp == false)) {
      this.loginService.userBasiclogin(data);
    } else {
      this.tempUserid = data.user._id;
      this.tempPassword = this.temp_password;
      this.loginService.tempAuthToken = data.authToken;

      if (data.isTemporary == true && data.passwordexpired == true) {  // password is Temporary and also expired
        this.loginService.isTemporaryFlag = true;
        this.loginService.isPasswordExpired = false;
      } else if (data.isTemporary == true) { // Temporary password
        this.loginService.isTemporaryFlag = true;
        this.loginService.isPasswordExpired = false;
      } else if (data.passwordexpired == true) { // Expired password
        this.loginService.isTemporaryFlag = true;
        this.loginService.isPasswordExpired = true;
      } else if (data.passwordIsOtp == true) {
        this.loginService.isTemporaryFlag = true;
        this.loginService.isPasswordOtp = true;
      }
      // this.loginresponse = 'Sorry...! No Account Mapped to this user. Please try again.';
      /// this.routers.navigate(['/reset-password']);
    }
  }
  ModalClose() {
    this.SharedPopupService.dismiss();
    this.otpType = '';
  }
  ngOnDestroy(): void {
    this.SharedPopupService.dismiss();
  }

  forgotpass(){
    this.sharedService.pushToaster('error', 'Contact administrator for password reset','');
  }

}
