import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as myGlobals from '../../globals';

@Injectable({
  providedIn: 'root'
})

export class TasktypesService {

  public showInactive: boolean;
  public searchname: string;
  public tasktype_list_status: any;
  public tasktype_list: any[];
  public selected: string;
  public tasktype: any;

  constructor(private http: HttpClient) {
    this.showInactive = false;
    this.searchname = '';
    this.tasktype_list_status = false;
    this.tasktype_list = [];
  }

  add_new_task_types(data): any {
    let url = myGlobals.Settings_URL + '/tasktypes';
    return this.http.post(url, data);
  }

  edit_task_types(tasktypeid, data): any {
    let req = {};
    req = {
      tasktypeid: tasktypeid,
      tasktype: data.tasktype,
      task_description: data.task_description,
      is_charge: data.is_charge,
      is_patient: data.is_patient
    };
    let url = myGlobals.Settings_URL + '/tasktypes' + '/' + tasktypeid;
    return this.http.put(url, req);
  }
  toggletasktypestatus(id, status): any {
    let req = {};
    req = {
      tasktypeid: id,
      status: status
    };
    let url = myGlobals.Settings_URL + '/tasktypes' + '/' + id + '/status';
    return this.http.put(url, req);
  }
  tasktypes_list(str, inactive) {
    let url = myGlobals.Settings_URL + '/tasktypes?q=' + str + '&inactive=' + inactive;
    return this.http.get<any>(url);
  }
  viewtasktype(tasktype_id) {
    let url = myGlobals.Settings_URL + '/tasktypes' + '/' + tasktype_id;
    return this.http.get<any>(url);
  }
  taskType_template_list(data): any {
    let url = myGlobals.Settings_URL + '/tasktypes/' + data ;
    return this.http.get(url);
  }
  taskType_emailnotification_list(data): any {
    let url = myGlobals.Settings_URL + '/tasktypes/' + data  + '/emailnotification';
    return this.http.get(url);
  }
  
  edit_taskType_emailnotification(data): any {
    let url = myGlobals.Settings_URL + '/tasktypes/' + data._id + '/' + data.taskStatus_id + '/emailnotification';
    return this.http.put(url, data);
  }
  addNew_taskTemplate(data): any {
    let url = myGlobals.Settings_URL + '/tasktypes/' + data.tasktype_id + '/templates';
    return this.http.post<any>(url, data);
  }
  edit_task_template(data): any {
    let url = myGlobals.Settings_URL + '/tasktypes/' + data.tasktypeid + '/templates/' + data.template_id;
    return this.http.put<any>(url, data);
  }
  update_template_status(data): any {
    let url = myGlobals.Settings_URL + '/tasktypes/' + data.tasktype_id + '/' + data.template_id + '/status';
    return this.http.put<any>(url, data);
  }

  addusernotification_map(data):any{
    let url = myGlobals.Settings_URL + '/tasktypes/' + data.tasktype_id + '/taskstatus/' +data.taskstatus_id +'/users/'+data.user_id;
    return this.http.post<any>(url,{});
  }

  deleteusernotification_map(data):any{
    let url = myGlobals.Settings_URL + '/tasktypes/' + data.tasktype_id + '/taskstatus/' +data.taskstatus_id +'/users/'+data.user_id;
    return this.http.delete<any>(url);
  }
}
