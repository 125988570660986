import { Component, TemplateRef, ViewEncapsulation, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SkillsService } from '../../../_services/settings/skills.service';
import { TaskService } from '../../../_services/task/task.service';
import { NgForm } from '@angular/forms';
import { DataHelperService } from '../../../_services/auth/data-helper.service';
import { LoginHistoryService } from '../../../_services/login-history/login-history.service';
import * as _ from 'lodash';
import { SharedService } from '../../../_services/shared.service';
import { PracticesService } from '../../../_services/settings/practices.service';
import { TasktypesService } from '../../../_services/settings/tasktypes.service';
import { SharedPopupService } from '../../../_services/popup.service';
import constants from '../../../constants';

class NewSkill {
  _id: string;
  category_id: string
  skill_name: string;
  enabled: boolean;
  skill_value: string;
}

class Category {
  categoryid: string;
  categoryname: string;
  tasktype: string;
}

@Component({
  templateUrl: 'skills.component.html',
  styleUrls: ['skills.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: []
})
export class SkillsComponent implements OnDestroy {
  userAllList: Array<{}>;
  userAction: Array<{}>;
  showInactive: boolean;
  skillCategoryData: any;
  timeout = null;
  CreateNewUserForm: any;
  User_Management_Data_list: any;

  popupTitle: string;
  popUpfrom: any;
  taskReason: any;
  taskInstructions: any;
  popUpinfo: any;
  popUpinfoselectedIndex: any;
  parentIndexValue: any;
  contacts: any;
  UserValidationMsg: any;
  UserValidationStatus: any;
  messageInfo: string;
  selectedaccountData: any;
  selectedUserData: any;
  ConfirmPopupfrom: any;
  userIndex: any;
  actionIndex: any;
  notAvailable: String = 'Not available';
  selected_id: any;
  usersinfo: { user_id: any; user_name: any; };
  selectedTaskType: any;
  skillOperatorList: any[];
  logError(arg0: any): any {
    throw new Error('Method not implemented.');
  }
  successflag: any;
  adduser_timer: any;
  UserEditStatus: any;
  UserEditMsg: any;
  SelectedUserId: any;
  userStatus: any;
  popupUserInfo: any;
  searchtext: string;
  searchModel: string;
  loaderIndicator:boolean;
  constants:any;
  public newSkill: NewSkill;
  public category: Category;
  childindex: any;
  @ViewChild('adduserpopup', { static: false }) adduserpopup: ElementRef;
  constructor(private tasktypesService: TasktypesService, public SharedPopupService: SharedPopupService, private loginhistory: LoginHistoryService, private practicesService: PracticesService, private skillsService: SkillsService, public modalService: BsModalService, private sharedService: SharedService, public taskService: TaskService, private DataHelper: DataHelperService) {
    this.showInactive = false;
    this.triggerSearch('');
    this.taskService.viewHistory = false;
    this.taskService.viewSessionHistory = false;
    this.searchtext = '';
    this.searchModel = '';
    this.skillCategoryData = [];
    this.loaderIndicator=true;
    this.constants=constants;
    this.skillOperatorList = [
      {
        label: 'is Equal to',
        value: '='
      }, {
        label: 'is Greater than',
        value: '>'
      }, {
        label: 'is less than',
        value: '<'
      }
    ]
  }

  ngOnInit() {
    this.newSkill = {
      _id: "",
      category_id: '',
      skill_name: '',
      enabled: true,
      skill_value: ''
    }
    this.category = {
      categoryid: '',
      categoryname: '',
      tasktype: ''
    };
    this.contacts = 'fail';

  }
  onChangeSkillOperator() {
  }
  getSkillsList() {
    this.loaderIndicator=true;
    let status = this.showInactive ? false : true;
    this.skillsService.skillCategorylist(this.searchtext, status).subscribe((data: any) => {
      this.loaderIndicator=false;
      data.status == 'success' ? this.skillCategoryData = data.results : this.skillCategoryData = [];
    });
  }

  searchUserList(searchValue: string) {
    let tempBool = false;
    let testPattern = /^[a-zA-Z0-9 ]*$/;
    let regExVal = new RegExp(testPattern);
    tempBool = regExVal.test(searchValue);
    if (tempBool) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(x => {
        this.triggerSearch(searchValue);
      }, 1000);
    }
  }

  triggerSearch(searchValue: string) {
    this.loaderIndicator=true;
    this.skillCategoryData=[];
    let status = this.showInactive ? false : true;
    this.skillsService.skillCategorylist(searchValue, status).subscribe((data: any) => {
      data.status == 'success' ? this.skillCategoryData = data.results : this.skillCategoryData = [];
      this.skillCategoryData.map(item => {
        item['type'] = '';
      });
        this.loaderIndicator=false;
    });
    this.taskTypeList();
  }

  removeempety() {
    this.skillCategoryData.forEach(element => {
      if (_.isEmpty(element.practice_users[0])) {
        element.practice_users = [];
      }
    });
  }

  activeStatus() {
    this.loaderIndicator=true;
    let active = this.showInactive ? false : true;
    this.skillsService.skillCategorylist(this.searchtext, active).subscribe((userList: any) => {
      this.loaderIndicator=false;
      userList.status == 'success' ? this.skillCategoryData = userList.results : this.skillCategoryData = [];
    });
  }

  toggleLimit(item, limit, type) {
    item[type] = limit;
  }

  viewLoginHistory(id, name) {
    this.loginhistory.user_id = id;
    this.loginhistory.user_name = name;
    this.taskService.viewHistory = true;
  }

  viewSessionHistory(id, name) {
    this.usersinfo = {
      user_id: id,
      user_name: name
    }
    this.selected_id = id
    this.taskService.viewSessionHistory = true;
    this.taskService.viewHistory = false;
  }

  backToUsers() {
    this.taskService.viewHistory = false;
    this.taskService.viewSessionHistory = false;
  }

  backToPrevious() {
    window.history.back();
  }

  openModal(template: TemplateRef<any>, from, infoData, parentIndex, childindex, childData) {
    this.popUpfrom = from;
    this.parentIndexValue = parentIndex;
    this.popUpinfo = infoData;
    this.childindex = childindex;
    if (this.popUpfrom == 'addSkill') {
      this.popupTitle = 'Add new skill';
      this.newSkill = {
        _id: '',
        category_id: '',
        skill_name: '',
        enabled: true,
        skill_value: "="
      };
    } else if (this.popUpfrom == 'editSkill') {
      this.popupTitle = 'Edit skill';
      this.popUpinfo = infoData;
      this.newSkill = {
        _id: this.skillCategoryData[this.parentIndexValue]['skillset_data'][this.childindex]._id,
        category_id: this.popUpinfo._id,
        skill_name: childData.skill_set_name,
        enabled: this.skillCategoryData[this.parentIndexValue]['skillset_data'][this.childindex].enabled,
        skill_value: this.skillCategoryData[this.parentIndexValue]['skillset_data'][this.childindex].operator ? this.skillCategoryData[this.parentIndexValue]['skillset_data'][this.childindex].operator : "="
      };

    }

    if (this.popUpfrom == 'newCategory') {
      this.popupTitle = 'New Category';
      this.category = {
        categoryid: '',
        categoryname: '',
        tasktype: ''
      };
    } else if (this.popUpfrom == 'editCategory') {
      this.popupTitle = 'Edit Category';
      this.popUpinfo = infoData;
      this.category = {
        categoryid: this.popUpinfo._id,
        categoryname: this.popUpinfo.name,
        tasktype: this.popUpinfo.tasktype
      };
      this.selectedTaskType = this.popUpinfo.tasktype_id;
    }
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static'
    });
  }

  modalClose() {
    this.taskReason = '';
    this.taskInstructions = '';
    this.selectedTaskType = '';
    this.SharedPopupService.dismiss();
  }

  addSkill(model: NewSkill, isValid: boolean, mynform: NgForm) {
    let req = {
      "category_id": this.popUpinfo._id,
      "skill_set_name": model.skill_name.trim(),
      "enabled": true,
      "operator": model.skill_value
    }
    if (isValid == true) {
      if (this.popUpfrom == 'addSkill') {
        this.skillsService.addSkillSet(req).subscribe(doc => {
          if (doc.status == 'success') {
            req['_id'] = doc._id
            this.skillCategoryData[this.parentIndexValue]['skillset_data'].push(req);
            this.sharedService.pushToaster('success', 'Success', doc.message);
            mynform.resetForm();
            this.modalClose();
          } else {
            this.sharedService.pushToaster('error', 'Error', doc.message);

          }
        }, err => this.logError(err));
      }
      if (this.popUpfrom == 'editSkill') {
        let edit = {
          "_id": this.skillCategoryData[this.parentIndexValue]['skillset_data'][this.childindex]._id,
          "category_id": this.popUpinfo._id,
          "skill_set_name": model.skill_name,
          "enabled": this.skillCategoryData[this.parentIndexValue]['skillset_data'][this.childindex].enabled,
          "operator": (model.skill_value != '' || model.skill_value == undefined) ? model.skill_value : "="
        }
        this.skillsService.updateSkillSet(edit).subscribe(doc => {
          if (doc.status == 'success') {
            this.skillCategoryData[this.parentIndexValue]['skillset_data'][this.childindex].skill_set_name = model.skill_name;
            this.skillCategoryData[this.parentIndexValue]['skillset_data'][this.childindex].operator = edit.operator;

            this.sharedService.pushToaster('success', 'Success', 'Updated Skill Set successfully');
            mynform.resetForm();
            this.modalClose();
          } else {
            this.sharedService.pushToaster('error', 'Error', 'Something went wrong');
          }
        }, err => this.logError(err));
      }
    }
  }

  removeNullEntries(obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object')
        obj[key] = this.removeNullEntries(obj[key]);
      else if (obj[key] === undefined || obj[key] === null)
        obj[key] = '';
      else
        obj[key] = (typeof obj[key] === 'string') ? obj[key].trim() : obj[key];
    });
    return obj;
  }

  activeUser(id, userStatus, parentIndex) {
    this.SelectedUserId = id;
    userStatus = !userStatus;
    this.skillsService.activeCategory(id, userStatus).subscribe(userStatus => this.userStatus = userStatus, err => this.logError(err), () => {
      this.skillCategoryData[parentIndex].enabled = userStatus;
      this.SharedPopupService.dismiss();
      this.sharedService.pushToaster('success', 'Success', userStatus == false ? 'Skill Category Deactivated Successfully' : 'Skill Category Activated Successfully');
    });
  }

  enablemodel(createnewtemplate) {
    this.SharedPopupService.show(createnewtemplate, {
      animated: true,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }

  ConfirmPopup(Confirmtemplate: TemplateRef<any>, from, userData, accountData, userindex, actionindex) {
    this.ConfirmPopupfrom = from;
    this.selectedUserData = userData;
    this.selectedaccountData = accountData;
    this.userIndex = userindex;
    this.actionIndex = actionindex;
    if (this.ConfirmPopupfrom == 'skillcategorystatus') {
      if (this.selectedaccountData == true) {
        this.messageInfo = 'Are you sure want to Deactivate Skill Category ?';
      } else {
        this.messageInfo = 'Are you sure want to Activate Skill Category ?';
      }
    }
    this.SharedPopupService.show(Confirmtemplate, {
      animated: true,
      backdrop: 'static',
    });
  }

  ConfirmPopupSkillset(Confirmtemplate: TemplateRef<any>, from, infoData, parentIndex, childindex, childData) {
    this.ConfirmPopupfrom = from;
    this.parentIndexValue = parentIndex;
    this.popUpinfo = infoData;
    this.childindex = childindex;
    if (childData.enabled == true) {
      this.messageInfo = 'Are you sure want to Deactivate Skill Set?';
    } else if (childData.enabled == false) {
      this.messageInfo = 'Are you sure want to Activate Skill Set?';
    }
    this.SharedPopupService.show(Confirmtemplate, {
      animated: true,
      backdrop: 'static',
    });
  }

  onConfirm() {
    if (this.ConfirmPopupfrom == 'skillcategorystatus') {
      this.activeUser(this.selectedUserData, this.selectedaccountData, this.userIndex);
    }
    if (this.ConfirmPopupfrom == 'editSkill') {
      let edit = {
        "_id": this.skillCategoryData[this.parentIndexValue]['skillset_data'][this.childindex]._id,
        "category_id": this.popUpinfo._id,
        "skill_set_name": this.skillCategoryData[this.parentIndexValue]['skillset_data'][this.childindex].skill_set_name,
        "enabled": this.skillCategoryData[this.parentIndexValue]['skillset_data'][this.childindex].enabled == true ? false : true,
        "operator": (this.skillCategoryData[this.parentIndexValue]['skillset_data'][this.childindex].operator != '' || this.skillCategoryData[this.parentIndexValue]['skillset_data'][this.childindex].operator == undefined) ? this.skillCategoryData[this.parentIndexValue]['skillset_data'][this.childindex].operator : "="

      }
      this.skillsService.updateSkillSet(edit).subscribe(doc => {
        if (doc.status == 'success') {
          this.skillCategoryData[this.parentIndexValue]['skillset_data'][this.childindex].enabled = edit.enabled;

          this.sharedService.pushToaster('success', 'Success', 'Updated Skill Set status successfully');
          this.SharedPopupService.dismiss();
        } else {
          this.sharedService.pushToaster('error', 'Error', 'Something went wrong');

        }
      })
    }
  }

  onCancel() {
    this.SharedPopupService.dismiss();
  }

  addCategory(model: Category, isValid: boolean, mynform: NgForm) {
    model = this.removeNullEntries(model);
    if (isValid == true) {
      if (this.popUpfrom == 'newCategory') {
        this.User_Management_Data_list = {
          categoryname: model.categoryname.trim(),
          tasktype: model.tasktype,
          enabled: true
        };
        this.skillsService.addCategory(this.User_Management_Data_list).subscribe(data => {
          this.contacts = data;
          this.UserValidationMsg = this.contacts.message;
          this.UserValidationStatus = this.contacts.status;
          if (this.UserValidationStatus == 'success') {
            this.User_Management_Data_list['_id'] = data.data._id;
            this.User_Management_Data_list['categoryid'] = data.data._id;
            this.User_Management_Data_list['enabled'] = true;
            this.skillCategoryData.unshift(this.User_Management_Data_list);
            mynform.resetForm();
            this.modalClose();
            this.sharedService.pushToaster('success', 'Success', this.UserValidationMsg);
            this.getSkillsList();
          } else {
            this.sharedService.pushToaster('error', 'Error', this.UserValidationMsg);
          }
        },
          err => this.logError(err),
        );
      }

      if (this.popUpfrom == 'editCategory') {
        this.User_Management_Data_list = {
          categoryid: model.categoryid,
          categoryname: model.categoryname,
          tasktype: model.tasktype,
          enabled: true
        };
        this.skillsService.editCategory(this.User_Management_Data_list).subscribe(data => {
          data = this.removeNullEntries(data);
          this.contacts = data;
          this.UserEditStatus = this.contacts.status;
          this.UserValidationMsg = this.contacts.message;
          if (this.UserEditStatus == 'success') {
            this.skillCategoryData[this.parentIndexValue].Categoryname = model.categoryname;

            mynform.resetForm();
            this.modalClose();
            this.sharedService.pushToaster('success', 'Success', 'Skill Category Updated Successfully');
            this.getSkillsList();
          } else {
            if (this.UserValidationMsg) {
              this.sharedService.pushToaster('error', 'Error', this.UserValidationMsg);
            }
            if (this.contacts.status) {
              this.sharedService.pushToaster('error', 'Error', this.contacts.status);
            }
          }
        },
          err => this.logError(err)
        );
      }
    } else {
      let categoryname = new String(model.categoryname);
      if (categoryname.length != 0 && categoryname.length < 3) {
        this.sharedService.pushToaster('error', 'Error', 'categoryname should not be less than 3 character');
      }
    }
  }

  taskTypeList() {
 // this.loaderIndicator=true;
    let showInactive = this.tasktypesService.showInactive;
    let str = '';
    this.tasktypesService.tasktypes_list(str, showInactive).subscribe(
      data => {
        this.tasktypesService.tasktype_list_status = data.status;
  //console.log( this.tasktypesService.tasktype_list_status,'data');
        
   //     this.loaderIndicator=false;
        if (data.status == 'success') {
          if ((data.results).length > 0) {
            this.tasktypesService.tasktype_list = data.results;
          }
        }
      });
  }
  ngOnDestroy(): void {
    this.SharedPopupService.dismiss();
  }
}