import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {
  transform(items: any, filter: any, totallength: any, isAnd: any): any {
    if (filter && Array.isArray(items)) {
      let filterKeys = Object.keys(filter);
      if (isAnd) {
        return items.filter(item =>
          filterKeys.reduce((memo, keyName) =>
            (memo && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] === '', true));
      } else {
        let newdata = items.filter(item => {
          return filterKeys.some((keyName) => {
            if (typeof filter[keyName] == 'object') {
              let nestedfilterKeys = Object.keys(filter[keyName]);
              return nestedfilterKeys.some((nkeyName) => {
                return new RegExp(filter[keyName][nkeyName], 'gi').test(item[keyName][nkeyName]) || filter[keyName] === '';
              });
            } else {
              return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] === '';
            }
          });

        });
        if (totallength && totallength != undefined) {
          totallength.count = newdata.length;
        }
        return newdata;
      }
    } else {
      return items;
    }
  }
}
