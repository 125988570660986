import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private http: HttpClient) { }

  seachRoles(searchKey, showInactive) {
    return this.http.get<any>(myGlobals.Role_URL + '?q=' + searchKey + '&showInactive=' + showInactive);
  }

  getRolesPermissionsAndUsers(roldId) {
    return this.http.get<any>(myGlobals.Role_URL + '/' + roldId);
  }

  updateRolesStatus(roleId, status) {
    let req = { status: status };
    return this.http.put<any>(myGlobals.Role_URL + '/' + roleId + '/status', req);
  }

  addNewRole(roleData) {
    return this.http.post<any>(myGlobals.Role_URL, roleData);
  }

  editRole(roleData, roleId) {
    return this.http.put<any>(myGlobals.Role_URL + '/' + roleId, roleData);
  }

  listPermissions(searchKey, roleId) {
    return this.http.get<any>(myGlobals.Role_URL + '/' + roleId + '/permissions?q=' + searchKey + '&enabled=true');
  }

  savemappedPermissionsToRoles(mappedPermissions) {
    return this.http.put<any>(myGlobals.Role_URL + '/' + mappedPermissions.roleid + '/permissions', mappedPermissions);
  }

  savemappedUsersToRoles(mappedUsers) {
    return this.http.put<any>(myGlobals.Role_URL + '/' + mappedUsers.roleid + '/users', mappedUsers);
  }

  listUsers(searchKey) {
    return this.http.get<any>(myGlobals.User_URL + '?q=' + searchKey + '&showActive=true');
  }

  deleteUsers(user_id,role_id){
    return this.http.delete<any>(myGlobals.User_URL + '/' +user_id +'/roles/' +role_id);
  }

  addUsers(user_id,role_id){
    return this.http.put<any>(myGlobals.User_URL + '/' +user_id +'/roles/' +role_id,null);
  }

  deleteAllPermission(permssion_id,role_id){
    return this.http.delete<any>(myGlobals.Role_URL + '/' +role_id +'/permissions/<DeactivateAllPermssions>');
  }

  deletePermission(permssion_id,role_id){
    return this.http.delete<any>(myGlobals.Role_URL + '/' +role_id +'/permissions/' +permssion_id);
  }

  addPermission(permssion_id,role_id){
    return this.http.post<any>(myGlobals.Role_URL + '/' +role_id +'/permissions/' +permssion_id,null);
  }
}
