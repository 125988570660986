import { Component, OnInit ,Input,SimpleChanges,Renderer2, SecurityContext,EventEmitter,Output,OnChanges} from '@angular/core';
import ImageViewer from 'iv-viewer';
import {FullScreenViewer} from 'iv-viewer';
import { TaskService } from '../../_services/task/task.service';
import {DomSanitizer} from '@angular/platform-browser';
import { SharedService } from '../../_services/shared.service';
import { DataHelperService } from '../../_services/auth/data-helper.service';
import * as _ from 'lodash';


const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise<any>(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};
@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit,OnChanges{
  @Input('imageData') imageData:any;
  @Output() sendDataToParent = new EventEmitter<string>();
  indexImagemAtual: number;
  zoomPercent = 100;
  viewer;
  wrapper;
  ROTACAO_PADRAO_GRAUS :number = 90;
  rotacaoImagemAtual: number =0;
  isImagemVertical: boolean;
  selectedindex: any;
  classification_type: any;
  nopreview:boolean = false;
  extWindow:boolean;
  constructor(private renderer: Renderer2,public taskService: TaskService,private domSanitizer: DomSanitizer,private sharedService:SharedService,private dataHelperService:DataHelperService) { 
    this.isImagemVertical= false;
  }
  
  ngOnInit() {
    if(this.dataHelperService.getItem("img-viewer_info")){
      this.extWindow = true;
      this.imageData = JSON.parse(this.dataHelperService.getItem("img-viewer_info"));
      //this.imageData = this.dataHelperService.getItem("img-viewer_info");
      this.preselecctImg();
    //  localStorage.removeItem("img-viewer_info");
    }else{
      this.extWindow = false;
    }
    this.sharedService.getGlobalData('document_classification').subscribe(result => {
      this.classification_type  = this.sharedService.globalData['document_classification'];
    });
  }
  preselecctImg(){
    if(this.imageData.selected_attachement_info){
      let allrecord  = this.imageData.selected_attachement_info 
      this.imageData.selected_attachement_info = _.uniqBy(allrecord, v => [v["file_name"], v["size"]].join())
      // console
      // this.imageData.selected_attachement_info = _.uniq(allrecord,<any>function(obj) {
      //   return obj.file_name && obj.size;
      // });
      this.selectedindex = this.imageData.selected_attachement_info.findIndex((data) =>{
        return data.file_name == this.imageData.selected_image_info.file_name;
      })
      this.viewAttachment(this.imageData.selected_image_info)
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if(this.imageData){
      this.imageData = this.imageData;
      this.preselecctImg();
    }else{
      this.imageData ={};
    }
    console.log(this.imageData,"imageDataimageDataimageData");
  }
  ngAfterViewInit() {
  this.inicializarImageViewer();
    // setTimeout(() => {
    //   this.showImage();
    // },1000)
  }
  showImage() {
   // this.limparCacheElementos()
    let imgObj = this.imageData.selected_image_info.url
    if(this.imageData.selected_image_info.url){
      this.viewer.load(imgObj, imgObj);
    }
  
    // setTimeout(() => {
    //   document.getElementsByClassName("iv-image")['0'].style.top = 0
    // }, 700);
    
  }
  inicializarImageViewer(){
    this.indexImagemAtual = 1;
    this.wrapper = document.getElementById("img-gal");
    if (this.wrapper) {
      this.viewer = new ImageViewer(this.wrapper.querySelector('.image-container'));
    }
    
  }
  
  imageDetailView(image_info,i){
    this.prepararTrocaImagem();
    if(image_info){
      this.selectedindex = i;
      this.nopreview = false;
      this.imageData.selected_image_info = image_info; 
      this.viewAttachment(this.imageData.selected_image_info);
      
    }else{
      this.imageData.selected_image_info ={};
      this.nopreview = true;
    }
   // this.limparCacheElementos(); 
  }
  resetarZoom(): number {
    this.zoomPercent = 100;
    this.viewer.zoom(this.zoomPercent);
    let timeout = 800;
    if (this.viewer._state.zoomValue === this.zoomPercent) {
        timeout = 0;
    }
    return timeout;
}
rotateRight(){
  const timeout = this.resetarZoom();
  setTimeout(() => {
      this.rotacaoImagemAtual += this.ROTACAO_PADRAO_GRAUS;
      this.isImagemVertical = !this.isImagemVertical;
      this.atualizarRotacao();
  }, timeout);
}

rotateLeft(){
  const timeout = this.resetarZoom();
  setTimeout(() => {
      this.rotacaoImagemAtual -= this.ROTACAO_PADRAO_GRAUS;
      this.isImagemVertical = !this.isImagemVertical;
      this.atualizarRotacao();
  }, timeout);
}

atualizarRotacao(isAnimacao = true) {
  let scale = '';
  const novaRotacao = `rotate(${this.rotacaoImagemAtual}deg)`;
  this.carregarImagem(novaRotacao, scale, isAnimacao);
}


carregarImagem(novaRotacao: string, scale: string, isAnimacao = true) {
  if (isAnimacao) {
      this.adicionarAnimacao('iv-snap-image');
      this.adicionarAnimacao('iv-large-image');
  }
  this.adicionarRotacao('iv-snap-image', novaRotacao, scale);
  this.adicionarRotacao('iv-large-image', novaRotacao, scale);
  setTimeout(() => {
      if (isAnimacao) {
          this.retirarAnimacao('iv-snap-image');
          this.retirarAnimacao('iv-large-image');
      }
  }, 501);
}
adicionarAnimacao(componente: string) {
  this.setStyleClass(componente, 'transition', `0.5s linear`);
}
adicionarRotacao(componente: string, novaRotacao: string, scale: string) {
  this.setStyleClass(componente, 'transform', `${novaRotacao} ${scale}`);
}
retirarAnimacao(componente: string) {
  this.setStyleClass(componente, 'transition', 'auto');
}

setStyleClass(nomeClasse: string, nomeStyle: string, cor: string) {

  let cont;
  const listaElementos = document.getElementById("img-gal").getElementsByClassName(nomeClasse);


  for (cont = 0; cont < listaElementos.length; cont++) {

      this.renderer.setStyle(listaElementos.item(cont), nomeStyle, cor);
  }
}
zoomIn() {
  this.zoomPercent += 10;
  this.viewer.zoom(this.zoomPercent);
}
zoomOut() {
  if (this.zoomPercent === 100) {

      return;
  }

  this.zoomPercent -= 10;

  if (this.zoomPercent < 0) {

      this.zoomPercent = 0;
  }

  this.viewer.zoom(this.zoomPercent);
}
nextImg(){
  if(this.selectedindex != this.imageData.selected_attachement_info.length){
    this.selectedindex++;
    this.imageData.selected_image_info = this.imageData.selected_attachement_info[this.selectedindex];        
    this.viewAttachment(this.imageData.selected_image_info);  
   // this.limparCacheElementos() 
  }
}
preImg(){
  if(this.selectedindex){
    this.selectedindex--;
    this.imageData.selected_image_info = this.imageData.selected_attachement_info[this.selectedindex];
    this.viewAttachment(this.imageData.selected_image_info);        
   // this.limparCacheElementos()
  }

}
viewAttachment(attachment) {
  if(attachment.url){
    setTimeout(() => {
      this.showImage();
    }, 1000);
  }else{
    if (this.imageData.selectedFilterValue != 'task') {
      this.taskService.downloadsingleattachment(attachment.file_name).subscribe(data => {
        this.attachamentDetails(data);
      });
    } else {
      this.taskService.downloadattachment(attachment.file_name, attachment.taskid).subscribe(data => {
        this.attachamentDetails(data);
      });
    }
  }

}

// isPDF() {
//   return this.getImagemAtual().startsWith('JVBE') || this.getImagemAtual().startsWith('0M8R');
// }
getIdIframe() {
  return 'img-gal-iframe'
}
converterPDFBase64ParaBlob(data) {

  // const arrBuffer = this.base64ToArrayBuffer(this.getImagemAtual());

  // const newBlob = new Blob([arrBuffer], { type: 'application/pdf' });

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(data);
      return;
  }

  return window.URL.createObjectURL(data);
}
injetarIframe(widthIframe: number, heightIframe: number,data) {
  const ivImageWrap = document.getElementById("img-gal").getElementsByClassName('iv-image-wrap').item(0);

  const iframe = document.createElement('iframe');

  iframe.id = this.getIdIframe();
  iframe.style.width = `${widthIframe}px`;
  iframe.style.height = `${heightIframe}px`;
  iframe.src = `${this.converterPDFBase64ParaBlob(data)}`;

  this.renderer.appendChild(ivImageWrap, iframe);
}
getTamanhoIframe() {
  const container = document.getElementById('img-gal');
  const widthIframe = container.offsetWidth;
  const heightIframe = container.offsetHeight;
  return {widthIframe, heightIframe};
}

esconderBotoesImageViewer() {
  this.setStyleClass('iv-loader', 'visibility', 'hidden');
  this.setStyleClass('options-image-viewer', 'visibility', 'hidden');
}
carregarViewerPDF(data) {
  this.esconderBotoesImageViewer();
  const {widthIframe, heightIframe} = this.getTamanhoIframe();
  this.injetarIframe(widthIframe, heightIframe,data);
}
attachamentDetails(data){


let fileType  = this.imageData.selected_image_info.file_name.toLowerCase();
var ext = fileType.split('.').pop().toLowerCase();
if(ext == 'pdf'){
  this.carregarViewerPDF(data);
  this.imageData.selected_image_info.url = ''
  this.showImage();
}else if(ext != 'mp3' && ext !="xlsx" && ext != "xlx" && ext !="csv"){
  blobToBase64(data).then(res => {
    this.imageData.selected_image_info.url = res
    this.showImage(); 
  })
}
else{
  blobToBase64(data).then(res => {
    this.imageData.selected_image_info.url = this.domSanitizer.bypassSecurityTrustResourceUrl(res)
    this.showImage(); 
  })
}





  
 
}
print(){
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write(document.getElementById("img-gal").innerHTML);
    mywindow.print();
}
changeClassification(){
  let updateInfo={
    file_id:this.imageData.selected_image_info.file_name,
		is_internal:this.imageData.selected_image_info.is_internal,
		
  }
  if(this.imageData.selected_image_info.file_classification){
    updateInfo["file_classification"] = this.imageData.selected_image_info.file_classification;
  }

  this.taskService.updateFileInfo(updateInfo).subscribe(data =>{
    console.log(data);
    console.log(this.extWindow)
    console.log(this.imageData.selected_image_info);
    var update_info = {
      "file_id":this.imageData.selected_image_info.file_id ? this.imageData.selected_image_info.file_id:this.imageData.selected_image_info.file_name,
      "file_name": this.imageData.selected_image_info.file_name,
      "is_internal":this.imageData.selected_image_info.is_internal,
      "info_update":true,
      "created_user_name":this.imageData.selected_image_info.created_user_name,
      "file_date":this.imageData.selected_image_info.file_date,
      "file_type":this.imageData.selected_image_info.file_type,
      "size":this.imageData.selected_image_info.size,
      "file_classification":this.imageData.selected_image_info.file_classification?this.imageData.selected_image_info.file_classification:""
     }
   if(this.extWindow == true && data.status == "success"){
      this.dataHelperService.setItem("img-viewer_info",update_info)
   }else{
      this.sendDataToParent.emit(JSON.stringify(update_info));
    
   }

  })
}
  
limparCacheElementos() {

  const container = document.getElementById("img-gal");
  const iframeElement = document.getElementById(this.getIdIframe());
  const ivLargeImage = document.getElementById("img-gal").getElementsByClassName('iv-large-image').item(0);

  if (iframeElement) {
      this.renderer.removeChild(container, iframeElement);
  }
  if (ivLargeImage) {

    this.renderer.removeChild(container, ivLargeImage);
}

  // if (iframeElement) {
  // }

  // this.setStyleClass('iv-loader', 'visibility', 'auto');
  // this.setStyleClass('options-image-viewer', 'visibility', 'inherit');
}
  nofileinfo(){
    if(this.imageData.selectedFilterValue =="All"){
      let allrecord = this.imageData.files.otherAttachments.concat(this.imageData.files.attachmentsForCharge,this.imageData.files.attachmentsForTask)
      this.imageData.selected_attachement_info = _.uniqBy(allrecord, v => [v["file_name"], v["size"]].join())
      this.imageDetailView(this.imageData.selected_attachement_info[0],0);
    }else if(this.imageData.selectedFilterValue =="task"){
      this.imageData.selected_attachement_info = this.imageData.files.attachmentsForTask;
      this.imageDetailView(this.imageData.selected_attachement_info[0],0);
    }else if(this.imageData.selectedFilterValue =="charge"){
      this.imageData.selected_attachement_info = this.imageData.files.attachmentsForCharge;
      this.imageDetailView(this.imageData.selected_attachement_info[0],0);
    }else if(this.imageData.selectedFilterValue == "others"){
      this.imageData.selected_attachement_info = this.imageData.files.otherAttachments;
      this.imageDetailView(this.imageData.selected_attachement_info[0],0);
    }else{
      this.imageData.selected_attachement_info=[];

    }
  }

  changeInternal(){
   // this.imageData.selected_image_info.enableTooltip="chandran"
    setTimeout(() => {
      this.changeClassification();
    }, 500);

  }
  prepararTrocaImagem() {
    this.rotacaoImagemAtual = 0;
    this.limparCacheElementos();
  }
  
}
