import { Component, TemplateRef, OnInit, OnDestroy } from '@angular/core';
import { RolesService } from '../../../../_services/settings/roles.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../../../../_services/shared.service';
import * as _ from 'lodash';
import { SharedPopupService } from '../../../../_services/popup.service';
import constants from '../../../../constants';
class NewRole {
  'roleName': string;
  'roleDescription': string;
  'roleType': string;
}

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit, OnDestroy {

  searchKey: any;
  showInActive: any;
  rolesList: any;
  seletedTab: any;
  selectedRolesObject: any;
  parentIndexValue: any;
  childIndexValue: any;
  confirmPopupFrom: any;
  messageInfo: any;
  selectedRoleId: any;

  statusChangeTo: any;
  public newRole: NewRole;
  popupTitle: any;
  listAllPermissions: any;
  mappedPermissions: any;
  tmpMappingPermissionData: any[];
  timeout = null;
  selectedPermissionId: any;
  updatePermissionStatus: any;

  // users mapping
  tmpMappingUsersData: any[];
  selectedUserId: any;
  updateUserStatus: any;
  mappedUsers: any;
  listAllUsers: any;
  loaderIndicator:boolean;
  tabloaderIndicator:boolean;
  constants:any;

  constructor(private rolesService: RolesService, public SharedPopupService: SharedPopupService, public modalService: BsModalService, private sharedService: SharedService) {
    this.showInActive = false;
    this.searchKey = '';
    this.tmpMappingPermissionData = [];
    this.tmpMappingUsersData = [];
    this.searchRoles(this.searchKey, this.showInActive);
    this.rolesList = [];
    this.loaderIndicator=true;
    this.tabloaderIndicator=true;
    this.constants=constants;
  }

  ngOnInit() {
    this.newRole = {
      'roleName': '',
      'roleDescription': '',
      'roleType': ''
    };
  }

  searchRoles(searchKey, showInActive) {
    this.loaderIndicator=true;
    this.rolesList=[];
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.rolesService.seachRoles(searchKey, showInActive).subscribe(result => {
        this.loaderIndicator=false;
        this.rolesList = (result.results && result.results.length > 0) ? result.results : [];
        this.rolesList.map(item => {
          item['buttontype'] = '';
        });
      });
    }, 1000);
   
  }

  actionButton(item) {
    if (item.buttontype == 'Permissions') {
      return 'Map permissions';
    } else if (item.buttontype == 'Users') {
      return 'Map users';
    }
  }

  tabChange(selectedTab, item, index) {
   // this.rolesList[index].tabloaderIndicator=true;
    if (selectedTab.target.innerText == 'Permissions' || selectedTab.target.innerText == 'Users') {
      this.seletedTab = selectedTab.target.innerText;
      item['buttontype'] = selectedTab.target.innerText;
      if (selectedTab.target.innerText == 'Permissions') {
        if (!this.rolesList[index]['roles_permissions']) {
          this.getPermissionsAndUsers(item._id, index);
        }
      } else if (selectedTab.target.innerText == 'Users') {
        if (!this.rolesList[index]['roles_users']) {
          this.getPermissionsAndUsers(item._id, index);
        }
      }
    } else {
      // console.log('other');
    }
  }

  getPermissionsAndUsers(roleId, index) {
    this.rolesList[index].tabloaderIndicator=true;
    this.rolesService.getRolesPermissionsAndUsers(roleId).subscribe(result => {
      this.rolesList[index].tabloaderIndicator=false;
      this.rolesList[index]['roles_permissions'] = (result.roleDetail && result.roleDetail.permisDetails.length > 0) ? result.roleDetail.permisDetails : [];
      this.rolesList[index]['roles_permissions_limit'] = 10;
      this.rolesList[index]['roles_users'] = (result.roleDetail && result.roleDetail.userRole.length > 0) ? result.roleDetail.userRole : [];
      this.rolesList[index]['roles_users_limit'] = 10;
      
    });
  }

  confirmPopup(accountConfirmPopup: TemplateRef<any>, status, rolesSelectedData, type, index, cindex) {
    this.selectedRolesObject = rolesSelectedData;
    this.parentIndexValue = index;
    this.childIndexValue = cindex;
    this.confirmPopupFrom = type;
    if (type == 'rolesStatusChange') {
      this.messageInfo = (status == true) ? 'Are you sure to deactivate Role ?' : 'Are you sure to Activate Role ?';
      this.selectedRoleId = this.selectedRolesObject._id;
      this.statusChangeTo = (status == true) ? false : true;
    } else if (type == 'rolesPermissionStatusChange') {
      this.messageInfo = (status == true) ? 'Are you sure to Deactivate Permission ?' : 'Are you sure to Activate Permission ?';
      this.selectedRoleId = this.rolesList[index]._id;
      this.selectedPermissionId = this.selectedRolesObject.PermD._id;
      this.updatePermissionStatus = (status == true) ? false : true;
    } else if (type == 'rolesUserStatusChange') {
      this.messageInfo = (status == true) ? 'Are you sure to Deactivate User ?' : 'Are you sure to Activate User ?';
      this.selectedRoleId = this.rolesList[index]._id;
      this.selectedUserId = this.selectedRolesObject.UserD._id;
      this.updateUserStatus = (status == true) ? false : true;
    } else if (type == 'deactivateAllPermissions') {
      this.messageInfo = 'Are you sure to Deactivate All Permissions ?';
      this.selectedRoleId = this.rolesList[index]._id;
    } else if (type == 'deactivateAllUsers') {
      this.messageInfo = 'Are you sure to Deactivate All Users ?';
      this.selectedRoleId = this.rolesList[index]._id;
    }
    this.SharedPopupService.show(accountConfirmPopup, {
      animated: true,
      backdrop: 'static'
    });
  }

  modalClose() {
    this.SharedPopupService.dismiss();
  }

  onConfirm() {
    if (this.confirmPopupFrom == 'rolesStatusChange') {
      this.rolesStatusChange();
    } else if (this.confirmPopupFrom == 'rolesPermissionStatusChange') {
      this.rolesPermissionsStatusChange('');
    } else if (this.confirmPopupFrom == 'rolesUserStatusChange') {
      this.rolesUsersStatusChange('');
    } else if (this.confirmPopupFrom == 'deactivateAllPermissions') {
      this.rolesPermissionsStatusChange('all');
    } else if (this.confirmPopupFrom == 'deactivateAllUsers') {
      this.rolesUsersStatusChange('all');
    }
  }

  rolesStatusChange() {
    this.rolesService.updateRolesStatus(this.selectedRoleId, this.statusChangeTo).subscribe(() => {
      this.sharedService.pushToaster('success', 'Success', 'Role status updated successfully');
      this.modalClose();
      this.rolesList.splice(this.parentIndexValue, 1);
    });
  }

  rolesPermissionsStatusChange(type) {
    let permissionArr = [];
    let permissionObj;
    if (type == 'all') {
      this.rolesList[this.parentIndexValue].roles_permissions.forEach(element => {
        element.PermD.enable = false;
      });
      this.modalClose();
      this.rolesService.deleteAllPermission(this.selectedPermissionId,this.selectedRoleId).subscribe(result => { 
        this.sharedService.pushToaster('success', 'Success', 'All Permissions have been removed');
      })
      
      // let updateStatusObj = {
      //   'roleid': this.selectedRoleId,
      //   'permissions': permissionArr
      // };

      // this.rolesService.savemappedPermissionsToRoles(updateStatusObj).subscribe(result => {
      //   if (result.status == 'success') {
      //     this.sharedService.pushToaster('success', 'Success', 'Permissions status updated successfully');
      //     if (type == 'all') {
      //       this.rolesList[this.parentIndexValue].roles_permissions.forEach(element => {
      //         element.PermD.enable = false;
      //       });
      //     } else {
      //       this.rolesList[this.parentIndexValue].roles_permissions[this.childIndexValue].PermD.enable = this.updatePermissionStatus;
      //     }
      //     this.modalClose();
      //   } else {
      //     this.sharedService.pushToaster('error', 'Failure', 'Failed to update. Please try again');
      //   }
      // });

    } else {
      if(this.updatePermissionStatus){
        this.rolesList[this.parentIndexValue].roles_permissions[this.childIndexValue].PermD.enable = true;
        this.modalClose();
        this.rolesService.addPermission(this.selectedPermissionId,this.selectedRoleId).subscribe(result => { 
          this.sharedService.pushToaster('success', 'Success', 'Permission has been added');
        })
      }else{
        this.rolesList[this.parentIndexValue].roles_permissions[this.childIndexValue].PermD.enable = false;
        this.modalClose();
        this.rolesService.deletePermission(this.selectedPermissionId,this.selectedRoleId).subscribe(result => { 
          this.sharedService.pushToaster('success', 'Success', 'Permission has been removed');
        })
      }
    }


   
  }

  rolesUsersStatusChange(type) {
    let userArr = [];
    let userObj;
    if (type == 'all') {
      this.rolesList[this.parentIndexValue].roles_users.forEach(element => {
        userObj = {
          'user_id': element.UserD._id,
          'isCheck': false
        };
        userArr.push(userObj);
      });
    } else {
      userObj = {
        'user_id': this.selectedUserId,
        'isCheck': this.updateUserStatus
      };
      userArr.push(userObj);
    }

    let updateStatusObj = {
      'roleid': this.selectedRoleId,
      'users': userArr
    };

    this.rolesService.savemappedUsersToRoles(updateStatusObj).subscribe(result => {
      if (result.status == 'success') {
        this.sharedService.pushToaster('success', 'Success', 'Users status updated successfully');
        if (type == 'all') {
          this.rolesList[this.parentIndexValue].roles_users.forEach(element => {
            element.UserD.enable = false;
          });
        } else {
          this.rolesList[this.parentIndexValue].roles_users[this.childIndexValue].UserD.enable = this.updateUserStatus;
        }
        this.modalClose();
      } else {
        this.sharedService.pushToaster('error', 'Failure', 'Failed to update. Please try again');
      }
    });
  }

  showActiveOrInactiveRoles() {
    this.searchRoles(this.searchKey, this.showInActive);
  }

  addNewRoleOpenModel(template: TemplateRef<any>, from) {

    if (from == 'addRoles') {
      this.popupTitle = 'New Role';
      this.newRole = {
        'roleName': '',
        'roleDescription': '',
        'roleType': ''
      };
      this.selectedRoleId = '';
    }
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static'
    });
  }

  editRoleOpenModel(template: TemplateRef<any>, from, rolesData) {

    if (from == 'editRoles') {
      this.popupTitle = 'Edit Role';
      this.newRole = {
        'roleName': rolesData.groupname,
        'roleDescription': rolesData.description,
        'roleType': rolesData.type
      };
      this.selectedRoleId = rolesData._id;
    }
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static'
    });
  }

  saveRoles(model, isValid) {
    if (model.editRoleData == '') { // Add Role
      if (isValid == true) {
        this.rolesService.addNewRole(model).subscribe(result => {
          if (result.status == 'success') {
            this.sharedService.pushToaster('success', 'Success', 'New Role added successfully');
            this.modalClose();
            this.searchRoles(this.searchKey, this.showInActive);
          } else {
            this.sharedService.pushToaster('error', 'Failure', result.Message);
          }
        });
      }
    } else {  // Edit Role
      if (isValid == true) {
        this.rolesService.editRole(model, model.editRoleData).subscribe(result => {
          if (result.status == 'success') {
            this.sharedService.pushToaster('success', 'Success', 'Role updated successfully');
            this.modalClose();
            this.searchRoles(this.searchKey, this.showInActive);
          } else {
            this.sharedService.pushToaster('error', 'Failure', result.message);
          }
        });
      }
    }
  }

  mapPermissionsModal(template: TemplateRef<any>, from, rolesData, index) {
    this.selectedRoleId = rolesData._id;
    this.selectedRolesObject = rolesData;
    this.parentIndexValue = index;

    this.tmpMappingPermissionData = [];
    this.mappedPermissions = [];
    let tmpexistingData = _.cloneDeep(rolesData.roles_permissions);
    if (tmpexistingData) {
      tmpexistingData.forEach(element => {
        if (element.PermD.enable == true) {
          this.mappedPermissions.push(element);
        }
        this.tmpMappingPermissionData.push(element);
      });
    }

    this.rolesService.listPermissions('', this.selectedRoleId).subscribe(result => {
      if (result.status == 'success') {
        this.listAllPermissions = (result.permList.length > 0) ? result.permList : [];
        this.defaultSelection();
      }
    });

    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }

  searcPermissionKey(searchKey) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.rolesService.listPermissions(searchKey, this.selectedRoleId).subscribe(result => {
        if (result.status == 'success') {
          this.listAllPermissions = (result.permList.length > 0) ? result.permList : [];
          this.defaultSelection();
        }
      });
    }, 1000);
  }

  defaultSelection() {
    this.listAllPermissions.forEach(element => {
      let flag = false;
      this.mappedPermissions.forEach(tmp => {
        if (element._id == tmp.PermD._id && tmp.PermD.enable == true) {
          flag = true;
        }
      });
      if (flag == false) {
        element.enable = false;
      } else {
        element.enable = true;
      }
    });
  }

  removeMappedPermissions(rolePermission, indexs) {
    if (rolePermission) {
      let index = this.listAllPermissions.findIndex(result => result._id === rolePermission.PermD._id);
      if (index >= 0) {
        this.listAllPermissions[index].enable = false;
        this.tmpMappingPermissionData.map(elem => {
          if (elem.PermD._id === rolePermission.PermD._id) {
            elem.PermD.enable = false;
          }
          return elem;
        });
      }
      this.mappedPermissions.splice(indexs, 1);
      this.rolesService.deletePermission(rolePermission.PermD._id,this.selectedRoleId).subscribe(result => {
        this.sharedService.pushToaster('success', 'Success', 'Permssion has been removed'); 
        this.rolesList[this.parentIndexValue].roles_permissions = this.tmpMappingPermissionData;
      })
    }
  }

  selectPermissionRole(data, i) {
    if (data.enable) {
      if (this.mappedPermissions == undefined) {
        this.mappedPermissions = [];
      }
      data['enable'] = true;
      this.mappedPermissions.push({ 'PermD': data });

      this.tmpMappingPermissionData.map(elem => {
        if (elem.PermD._id === data._id) {
          elem.PermD.enable = true;
        }
        return elem;
      });
      this.rolesService.addPermission(data._id,this.selectedRoleId).subscribe(result => { 
        const check_filter = this.tmpMappingPermissionData.findIndex(elem =>{
          if(elem.PermD._id === data._id) {
            return true;
          }
        
          return false;
        });
  
        if(check_filter === -1){
          this.tmpMappingPermissionData.push({ _id:this.selectedRoleId,'PermD': data })
        }
        this.sharedService.pushToaster('success', 'Success', 'Permission has been added');

        this.rolesList[this.parentIndexValue].roles_permissions = this.tmpMappingPermissionData;
      })


    } else {
      let index = this.mappedPermissions.findIndex(result => result.PermD._id === data._id);
      this.tmpMappingPermissionData.map(elem => {
        if (elem.PermD._id === data._id) {
          elem.PermD.enable = false;
        }
        return elem;
      });
      this.mappedPermissions.splice(index, 1);
      this.rolesService.deletePermission(data._id,this.selectedRoleId).subscribe(result => {
        this.sharedService.pushToaster('success', 'Success', 'Permssion has been removed'); 
        this.rolesList[this.parentIndexValue].roles_permissions = this.tmpMappingPermissionData;
      })

    }
  }

  mapNewPermissionsToRoles() {
    let allPermissionRoles = this.tmpMappingPermissionData;
    let updatedDataIds = [];
    this.mappedPermissions.forEach(element => {
      updatedDataIds.push(element.PermD._id);
    });

    let mappedData = JSON.parse(JSON.stringify(this.mappedPermissions));
    allPermissionRoles.forEach(element => {
      let index = this.mappedPermissions.findIndex(result => result.PermD._id === element._id);
      if (index != -1) {
        this.mappedPermissions.splice(index, 1);
      }

      if (updatedDataIds.indexOf(element.PermD._id) == -1) {
        mappedData.push(element);
      }
    });
    let finelmappedData = [];
    let i = 0;
    let selectedAccount;
    mappedData.forEach(element => {
      element.PermD.enable = (element.PermD.enable == undefined) ? true : element.PermD.enable;
      selectedAccount = {
        'perm_id': element.PermD._id,
        'isCheck': element.PermD.enable
      };
      finelmappedData.push(selectedAccount);
      i++;
    });

    let permissionsObject = {
      'roleid': this.rolesList[this.parentIndexValue]._id,
      'permissions': finelmappedData
    };
    if (i == mappedData.length) {
      this.rolesService.savemappedPermissionsToRoles(permissionsObject).subscribe(result => {
        if (result.status == 'success') {
          this.rolesList[this.parentIndexValue].roles_permissions = mappedData;
          this.sharedService.pushToaster('success', 'Success', 'Permissions mapped successfully');
          this.modalClose();
        } else {
          this.sharedService.pushToaster('error', 'Failure', 'Mapping Failed. Please try again');
        }
      });
    }
  }

  mapUsersModal(template: TemplateRef<any>, from, rolesData, index) {
    this.selectedRoleId = rolesData._id;
    this.selectedRolesObject = rolesData;
    this.parentIndexValue = index;

    this.tmpMappingUsersData = [];
    this.mappedUsers = [];
    let tmpexistingData = _.cloneDeep(rolesData.roles_users);
    if (tmpexistingData) {
      tmpexistingData.forEach(element => {
        if (element.UserD.enable == true) {
          this.mappedUsers.push(element);
        }
        this.tmpMappingUsersData.push(element);
      });
    }

    this.rolesService.listUsers('').subscribe(result => {
      if (result.status == 'success') {
        this.listAllUsers = (result.results.length > 0) ? result.results : [];
        this.defaultSelectionUsers();
      }
    });

    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }

  defaultSelectionUsers() {
    this.listAllUsers.forEach(element => {
      let flag = false;
      this.mappedUsers.forEach(tmp => {
        if (element._id == tmp.UserD._id && tmp.UserD.enable == true) {
          flag = true;
        }
      });
      if (flag == false) {
        element.enable = false;
      } else {
        element.enable = true;
      }
    });
  }

  searcUsersKey(searchKey) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
      this.rolesService.listUsers(searchKey).subscribe(result => {
        if (result.status == 'success') {
          this.listAllUsers = (result.results.length > 0) ? result.results : [];
          this.defaultSelectionUsers();
        }
      });
    }, 1000);
  }

  selectUserRole(data, i) {
    if (data.enable) {
      if (this.mappedUsers == undefined) {
        this.mappedUsers = [];
      }
      data['enable'] = true;
      this.mappedUsers.push({ 'UserD': data });
      this.tmpMappingUsersData.map(elem => {
        if (elem.UserD._id === data._id) {
          elem.UserD.enable = true;
        }
        return elem;
      });
      
      this.rolesService.addUsers(data._id,this.selectedRoleId).subscribe(result => { 
        const check_filter = this.tmpMappingUsersData.findIndex(elem =>{
          if(elem.UserD._id === data._id) {
            return true;
          }
        
          return false;
        });
  
        if(check_filter === -1){
          this.tmpMappingUsersData.push({ _id:this.selectedRoleId,'UserD': data })
        }
        this.sharedService.pushToaster('success', 'Success', 'User has been added');
        this.rolesList[this.parentIndexValue].roles_users = this.tmpMappingUsersData;
      })

    } else {
      let index = this.mappedUsers.findIndex(result => result.UserD._id === data._id);
      this.tmpMappingUsersData.map(elem => {
        if (elem.UserD._id === data._id) {
          elem.UserD.enable = false;
        }
        return elem;
      });
      this.mappedUsers.splice(index, 1);
      this.sharedService.pushToaster('success', 'Success', 'User has been removed');
      this.rolesService.deleteUsers(data._id,this.selectedRoleId).subscribe(result => { 
        this.rolesList[this.parentIndexValue].roles_users = this.tmpMappingUsersData;
      })
    }
  }

  removeMappedUsers(roleUser, indexs) {
    if (roleUser) {
      let index = this.listAllUsers.findIndex(result => result._id === roleUser.UserD._id);
       if (index >= 0) {
         this.listAllUsers[index].enable = false;
       }
        this.tmpMappingUsersData.map(elem => {
          if (elem.UserD._id === roleUser.UserD._id) {
            elem.UserD.enable = false;
          }
          return elem;
        });
      // }
      this.mappedUsers.splice(indexs, 1);

      this.sharedService.pushToaster('success', 'Success', 'User has been removed');
      this.rolesService.deleteUsers(roleUser.UserD._id,roleUser._id).subscribe(result => { 
        this.rolesList[this.parentIndexValue].roles_users = this.tmpMappingUsersData;
      })
    }
  }

  mapNewUsersToRoles(type=null) {
    let allUsersRoles = this.tmpMappingUsersData;
    let updatedDataIds = [];
    this.mappedUsers.forEach(element => {
      updatedDataIds.push(element.UserD._id);
    });

    let mappedData = JSON.parse(JSON.stringify(this.mappedUsers));
    allUsersRoles.forEach(element => {
      let index = this.mappedUsers.findIndex(result => result.UserD._id === element._id);
      if (index != -1) {
        this.mappedUsers.splice(index, 1);
      }

      if (updatedDataIds.indexOf(element.UserD._id) == -1) {
        mappedData.push(element);
      }
    });
    let finelmappedData = [];
    let i = 0;
    let selectedUsers;
    mappedData.forEach(element => {
      element.UserD.enable = (element.UserD.enable == undefined) ? true : element.UserD.enable;
      selectedUsers = {
        'user_id': element.UserD._id,
        'isCheck': element.UserD.enable
      };
      finelmappedData.push(selectedUsers);
      i++;
    });

    let usersObject = {
      'roleid': this.rolesList[this.parentIndexValue]._id,
      'users': finelmappedData
    };
    if (i == mappedData.length) {
      this.rolesService.savemappedUsersToRoles(usersObject).subscribe(result => {
        if (result.status == 'success') {
          if(type=='delete')
          {
            this.rolesList[this.parentIndexValue].roles_users = mappedData;
            this.sharedService.pushToaster('success', 'Success', 'Users removed successfully');
          }
          else{
            this.rolesList[this.parentIndexValue].roles_users = mappedData;
            this.sharedService.pushToaster('success', 'Success', 'Users mapped successfully');
            this.modalClose();
          }

        } else {
          this.sharedService.pushToaster('error', 'Failure', 'Mapping Failed. Please try again');
        }
      });
    }
  }

  toggleLimit(item, limit, type) {
    item[type] = limit;
  }
  ngOnDestroy(): void {
    this.SharedPopupService.dismiss();
  }
}
