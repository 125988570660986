import { HttpClient, HttpHeaders,HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import * as myGlobals from '../../globals';
@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient) { }

  searchUsers(searchValue: String, showActive: any = 'any', pagesize: number = 20, page: number = 0, isInternal = true,showreport = false) {
    let url = myGlobals.User_URL + '?q=' + searchValue + '&showActive=' + showActive
      + '&pagesize=' + pagesize
      + '&page=' + page
      // + '&size=10' 
      + '&isInternal=' + isInternal
      + '&showreport=' + showreport;
    return this.http.get<any>(url);
  }

  searchuser(name: string, size = '', showActive = '') {
    // search user URL changed from post to get pattern to use query paramter
    // return this.http.post(myGlobals.SearchUser_URL + name, '', this.authenticationService.options).map(res => res.json());
    return this.http.get(myGlobals.User_URL + '?q=' + name + '&size=' + size + '&showActive=' + showActive);
  }

  viewuser(user_id) {
    // return this.http.post(myGlobals.ViewUser_URL + id, '', this.authenticationService.options).map((res) => {
    return this.http.get<any>(myGlobals.User_URL + '/' + user_id)
  }


  addUser(newContact, practiceUser?) {
    let req = {};
    req = { newuser: newContact };
    if (practiceUser) {
      return this.http.post<any>(myGlobals.Base_URL + '/currentpractice/users', req);
    } else {
      return this.http.post<any>(myGlobals.User_URL, req);
    }
  }

  editUser(newContact, practiceUser?) {
    let user_id = newContact.userid;
    if (practiceUser) {
      return this.http.put(myGlobals.Base_URL + '/currentpractice/users/' + user_id, newContact);
    } else {
      return this.http.put(myGlobals.User_URL + '/' + user_id, newContact);
    }
  }

  userMfaUpdate(data, practiceUser?) {
    let user_id = data._id;
    if (practiceUser) {
      return this.http.put<any>(myGlobals.Base_URL + '/currentpractice/users/' + user_id + '/mfa', data);
    } else {
      return this.http.put<any>(myGlobals.User_URL + '/' + user_id + '/mfa', data);
    }
  }

  activeUser(user_id: string, status: string) {
    let req = {};
    req = { user_id: user_id, status: status };
    // return this.http.post(myGlobals.ActiveUser_URL + id + '/' + status,'',this.authenticationService.options)
    return this.http.put(myGlobals.User_URL + '/' + user_id + '/' + 'status', req);
  }

  // ==============
  // User Practices
  // List accounts mapped to user
  loadUserAccounts(user_id, searchkey: string, enabled: any) {
    if (user_id != '') {
      enabled = true;
    }
    return this.http.get<any>(myGlobals.User_URL + '/' + user_id + '/' + 'practices?q=' + searchkey + '&enabled=' + enabled);
  }

  searchBulkAccount(searchKey, user_id) {
    if (user_id != null && user_id != '') {
      let enabled = false;
    }
    // if (searchKey!=null && searchKey!='')
    //   {
    //return this.http.get<any>(myGlobals.User_URL + '/' + user_id + '/' + 'practices?q=' + searchKey + '&enabled=' + 'any');
    return this.http.get<any>(myGlobals.Base_URL + '/' + 'practices?q=' + searchKey + '&enabled=' + 'any');
    // }
  }

  deleteAccount(user_id, practice_id, data) {
    return this.http.put<any>(myGlobals.User_URL + '/' + user_id + '/practices/' + practice_id, data);
  }
  bulkAccountInsert(userId, mappingData) {
    let req = {};
    req = { bulkMappingData: mappingData };
    return this.http.put(myGlobals.User_URL + '/' + userId + '/' + 'practices', req);
  }
  deleteAllAccount(user_id: string) {
    return this.http.delete(myGlobals.User_URL + '/' + user_id + '/' + 'practices');
  }

  // ===================
  // password management
  resetPasswordWithOTP(data) {
    let req = { username: data };
    return this.http.post<any>(myGlobals.ResetPassword_URL + '/sendOTP', req);
  }
  resetPasswordWithEmail(userId) {
    let req = {};
    return this.http.post<any>(myGlobals.ResetPassword_URL + '/generateToken/' + userId, req);
  }
  setTemporaryPassword(data) {
    return this.http.post<any>(myGlobals.ResetPassword_URL + '/temporaryPassword', data);
  }

  // =====================
  // user roles management
  loadRoles(searchKey?: any, fromPracticeUsers?) {
    let roleName = searchKey ? searchKey : '';
    if (fromPracticeUsers) {
      return this.http.get<any>(myGlobals.Base_URL + '/currentpractice/roles?q=' + roleName);
    } else {
      return this.http.get<any>(myGlobals.Role_URL + '?q=' + roleName);
    }
  }
  get_mapped_roles(user_id, size = 0, practiceUser?) {
    if (practiceUser) {
      let url = myGlobals.Base_URL + '/currentpractice/users/' + user_id + '/roles?size=' + size;
      return this.http.get<any>(url);
    } else {
      let url = myGlobals.User_URL + '/' + user_id + '/roles?size=' + size;
      return this.http.get<any>(url);
    }
  }
  updateRoles(userid, roles, fromPracticeUser?) {
    let req = {};
    if (fromPracticeUser) {
      req = { userid: userid, roles: roles };
      return this.http.post<any>(myGlobals.Base_URL + '/currentpractice/users/' + userid + '/roles', req);
    } else {
      req = { userid: userid, roles: roles };
      return this.http.post<any>(myGlobals.User_URL + '/' + userid + '/roles', req);
    }
  }
  addUserRole(userId, roleId, practiceUser?) {
    if (practiceUser) {
      let url = myGlobals.Base_URL + '/currentpractice/users/' + userId + '/roles/' + roleId;
      return this.http.post<any>(url, undefined);
    } else {
      let url = myGlobals.User_URL + '/' + userId + '/roles/' + roleId;
      return this.http.post<any>(url, undefined);
    }
  }
  removeUserRole(userId, roleId, practiceUser?): any {
    if (practiceUser) {
      let url = myGlobals.Base_URL + '/currentpractice/users/' + userId + '/roles/' + roleId;
      return this.http.delete<any>(url, undefined);
    } else {
      let url = myGlobals.User_URL + '/' + userId + '/roles/' + roleId;
      return this.http.delete<any>(url, undefined);
    }
  }

  removeUserAllRoles(userId ): any {
   
      let url = myGlobals.User_URL + '/' + userId + '/roles/';
      return this.http.delete<any>(url, undefined);
    
  }

  // getlist of all practices mapped to a user
  get_mapped_practices(user_id, size = 0) {
    let url = myGlobals.User_URL + '/' + user_id + '/practices?size=' + size;
    return this.http.get<any>(url);
  }

  bulkProviderInsert(userId, practiceId, mappingData) {
    let req = {};
    req = { bulkMappingData: mappingData };
    return this.http.put(myGlobals.User_URL + '/' + userId + '/' + 'practices' + '/' + practiceId + '/providers', req);
  }


  // ===================
  // user sessions
  getSessionHistory(user_id, page) {
    return this.http.get<any>(myGlobals.User_URL + '/' + user_id + '/sessionhistory?page=' + page);
  }

  removeSessionHistory(user_id,token) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: token
    };
    return this.http.delete<any>(myGlobals.User_URL + '/' + user_id + '/sessionhistory/v2', options);
    //return this.http.delete<any>(myGlobals.User_URL + '/sessionhistory', options);
  }

  getUserPractices() {
    return this.http.get<any>(myGlobals.Base_URL + '/currentuser/practice');
  }
  getFeatures() {
    return this.http.get<any>(myGlobals.Base_URL + '/currentuser/features');
  }

  // task system user list
  get_users_list(checklist: any[]) {
    let checklistid = { 'taskid': checklist };
    return this.http.post<any>(myGlobals.User_URL + '/userbased', checklistid);
  }

  // ===================
  // user provider mapping
  getUserProviders(user_id, practiceUser) { // to be deleted
    if (practiceUser) {
      return this.http.get<any>(myGlobals.Base_URL + '/currentpractice/users/' + user_id + '/providers');
    } else {
      return this.http.get<any>(myGlobals.User_URL + '/' + user_id + '/providers');
    }
  }

  addUserProvider(user_id, provider_id, practiceUser) {
    if (practiceUser) {
      return this.http.post<any>(myGlobals.Base_URL + '/currentpractice/users/' + user_id + '/providers/' + provider_id, undefined);
    } else {
      return this.http.post<any>(myGlobals.User_URL + '/' + user_id + '/providers/' + provider_id, undefined);
    }
  }

  removeUserProvider(user_id, provider_id, practiceUser) {
    if (practiceUser) {
      return this.http.delete<any>(myGlobals.Base_URL + '/currentpractice/users/' + user_id + '/providers/' + provider_id, undefined);
    } else {
      return this.http.delete<any>(myGlobals.User_URL + '/' + user_id + '/providers/' + provider_id);
    }
  }

  // user practice mapping
  addUserPractice(user_id, practice_id) {
    return this.http.post<any>(myGlobals.User_URL + '/' + user_id + '/practices/' + practice_id,'');
  }

  deleteUserPractice(user_id, practice_id) {
    return this.http.delete<any>(myGlobals.User_URL + '/' + user_id + '/practices/' + practice_id);
  }

  mapPractice(user_id: string,data:any) {
    return this.http.put(myGlobals.User_URL + '/' + user_id + '/' + 'practices',data);
  }
  
  updateQuickNotes (data) {
    let url = myGlobals.User_URL +  "/currentuser/quicknotes";
    return this.http.put<any>(url,data);
  }
  // getlistInventories(data) {
  //   return this.http.get<any>(myGlobals.HRMS_Integrate+'/hrmsIntegration/listInventories'+data);
  // }
  syncUserInfo(){
    return this.http.post<any>(myGlobals.HRMS_Integrate+'/hrmsIntegration','');
  }
  getlistInventories(searchText) {
    return this.http.post<any>(myGlobals.HRMS_Integrate+'/hrmsIntegration/listInventories',searchText);
  }
  getUserSkillSetInfo(data) {
    return this.http.post<any>(myGlobals.Skill_Set_URL+'/getSkillsetinfo',data);
  }
  updateUserSkill(data)
  {
    return this.http.post<any>(myGlobals.Skill_Set_URL+'/updateUserSkill',data);
  }
  get_mapped_skillset(user_id) {
      return this.http.get<any>(myGlobals.Skill_Set_URL + '/userskillset/' + user_id);
  }
  getReportedInfo() {
    return this.http.get<any>(myGlobals.Skill_Set_URL + '/getReportedInfo');
  }
  skillsetapprove(data){
  return this.http.post<any>(myGlobals.Skill_Set_URL+'/skillsetapprove',data);
  }
}
