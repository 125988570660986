import { Component, TemplateRef, ViewEncapsulation, ElementRef, ViewChild, Output, OnDestroy } from "@angular/core"; import { FormGroup, FormControl, NgForm } from "@angular/forms";
import { Observable } from "rxjs/Rx";
// import { from } from 'rxjs';
// import { findIndex } from 'rxjs-compat/operator/findIndex';
// import { AngularWaitBarrier } from 'blocking-proxy/built/lib/angular_wait_barrier';
import { UsersService } from "../../../_services/settings/users.service";
import { TaskService } from "../../../_services/task/task.service";
import { DataHelperService } from "../../../_services/auth/data-helper.service";
import { LoginHistoryService } from "../../../_services/login-history/login-history.service";
import { SharedService } from "../../../_services/shared.service";
import { PracticesService } from "../../../_services/settings/practices.service";
import * as _ from "lodash";
import libphonenumber from "google-libphonenumber";

import { any } from "underscore";
import { SharedPopupService } from "../../../_services/popup.service";
import constants from '../../../constants';
import { result } from "lodash";
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

class NewUser {
  userid: string;
  user_title: string;
  username: string;
  employee_name:string
  display_name: string;
  email: string;
  mobile: string;
  is_internal = true;
  practice = true;
  brand: string;
  pseudonym: string;
  globexIntegration:boolean;
}

@Component({
  templateUrl: "user.component.html",
  styleUrls: ["user.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [],
})
export class UserComponent implements OnDestroy {
  userAllList: Array<{}>;
  userAction: Array<{}>;
  brandList: Array<{}>;
  dynamicBtn: String;
  showInactive: boolean;
  seletedTab: String;
  allUserList: any;
  timeout = null;
  CreateNewUserForm: any;
  AlreadyExistFlag: any;
  User_Management_Data_list: any;
  popupTitle: string;
  popUpfrom: any;
  taskReason: any;
  taskInstructions: any;
  popUpinfo: any;
  popUpinfoselectedIndex: any;
  parentIndexValue: any;
  taskName: any;
  contacts: any;
  UserValidationMsg: any;
  UserValidationStatus: any;
  allPracticesAccountandrole: any;
  existmappedaccountandrole: any[];
  messageInfo: string;
  selectedAccountGroup: any;
  selectedaccountData: any;
  selectedUserData: any;
  selecteduserIndex: any;
  ConfirmPopupfrom: any;
  userIndex: any;
  actionIndex: any;
  editedTitle: string;
  notAvailable: String = "Not available";
  displayuserName: string;
  userName: string;
  selectedPracticeName: string;
  selectedPracticeObject: any;
  checkall: any;
  selectedUserObject: any;
  selectedChildIndex: any;

  allPracticeProviders: any;
  existmappedProviders: any[];
  selected_id: any;
  usersinfo: { user_id: any; user_name: any };
  country: string;

  logError(arg0: any): any {
    throw new Error("Method not implemented.");
  }
  successflag: any;
  adduser_timer: any;
  UserEditStatus: any;
  UserEditMsg: any;
  SelectedUserId: any;
  userStatus: any;
  resetPasswordType: any;
  popupUserInfo: any;
  tempConfirmPassword: any;
  tempPassword: any;
  tmpMappingData: any[];
  searchtext: string;
  searchModel: string;
  mobilenoerror: boolean;
  loaderIndicator: boolean;
  tabloaderIndicator:boolean;
  constants:any;
  providerscurrentPage:number;
  providerspageskip :any;
  providerspaginationCounts:number;
  providerspage:number;
  pagelimit:number;
  providerTemplate:any;
  searchProviderValue:any;

  public phoneModel = "";
  public phoneMask = [
    /[+]/,
    /[1-9]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  public newuser: NewUser;

  // refactoring = practices and roles
  showInternalOnly: boolean;
  searchResultsRoles: any;
  selected_user_id: any;
  selected_user_displayname: any;
  userRolesSelected: any;
  userRolesUpdates: any;

  @ViewChild("adduserpopup", { static: false }) adduserpopup: ElementRef;
  constructor(
    private loginhistory: LoginHistoryService,
    private practicesService: PracticesService,
    private userService: UsersService,
    public SharedPopupService: SharedPopupService,
    private sharedService: SharedService,
    public taskService: TaskService,
    private DataHelper: DataHelperService
  ) {
    this.allUserList = [];

    this.dynamicBtn = "Map accounts";
    this.showInactive = false;
    this.showInternalOnly = true;
    this.seletedTab = "Accounts";
    this.providerspage = 0;
    this.providerspageskip = 0;
    this.providerspaginationCounts = 0;
    this.pagelimit = 10;
    this.searchtext = "";
    this.searchUsers();
    this.searchProviderValue = "";

    this.taskService.viewHistory = false;
    this.taskService.viewSessionHistory = false;
    this.resetPasswordType = 0;
    this.tempPassword = "";
    this.tempConfirmPassword = "";
    this.existmappedaccountandrole = [];
    this.tmpMappingData = [];
    this.searchModel = "";
    this.loaderIndicator=true;
    this.tabloaderIndicator=true;
    this.constants=constants;
    this.providerTemplate={};
    this.country = localStorage.getItem('Region');
 
    this.brandList = [
      { name: "Global", url: "" },
      { name: "Claimocity", url: "" },
    ];
  }
  onBlurMethod(){
    if(this.newuser.brand == 'Global'){
      if(this.newuser.employee_name== null  || this.newuser.employee_name== undefined ){
        this.newuser.employee_name=""
       }
     this.newuser.display_name=this.newuser.username + "_" + this.newuser.employee_name ;
    }  else{
      this.newuser.display_name=""
    }
   
  }
  ngOnInit() {
    this.newuser = {
      userid: "",
      user_title: "",
      username: "",
      employee_name:"",
      display_name: "",
      email: "",
      mobile: "",
      is_internal: true,
      practice: true,
      brand: "Global",
      pseudonym: "",
      globexIntegration:false
    };
    this.contacts = "fail";
  }

  // getAllUser() {
  //   let status = this.showInactive ? false : true;
  //   let isInternal = this.showInternalOnly ? true : false;
  //   this.userService.searchUsers(this.searchtext, status, isInternal).subscribe((data: any) => {
  //     data.status == 'success' ? this.allUserList = data.results : this.allUserList = [];
  //   });
  // }

  searchUserList() {
    let searchValue = this.searchtext;
    let tempBool = false;
    let testPattern = /^[a-zA-Z0-9 ]*$/;
    let regExVal = new RegExp(testPattern);
    tempBool = regExVal.test(searchValue);
    if (tempBool) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout((x) => {
        this.searchUsers();
      }, 1000);
    }
  }

  searchUsers() {
    this.loaderIndicator = true;
    this.allUserList = [];
    let status = this.showInactive ? false : true;
    let isInternal = this.showInternalOnly ? true : false;
    //this.loaderIndicator = false;
    this.userService
      .searchUsers(this.searchtext, status, 20, 0, isInternal)
      .subscribe(
        (data: any) => {
          this.loaderIndicator = false;
          this.allUserList = data.status == "success" ? data.results : [];
       //   data['loaderIndicator']=true;
          // this.allUserList.forEach(user => {
          //   user['type'] = ''; // BK not sure yet what is the purpose of this
          // });
        },
        (err) => {
          this.allUserList = [];
        }
      );
      //this.loaderIndicator = false;
  }

  tabChange(selectedTab: any, item, index) {
     //this.allUserList[index]=[];
    this.allUserList[index].tabloaderIndicator=true;
    if (
      selectedTab.target.innerText == "Accounts" ||
      selectedTab.target.innerText == "Roles" ||
      selectedTab.target.innerText == "SkillSet"
    ) {
      this.existmappedaccountandrole = [];
      this.seletedTab = selectedTab.target.innerText;
      this.dynamicBtn = "";
      if (this.seletedTab == "Accounts") {
        this.dynamicBtn = "Map accounts";
        if (!this.allUserList[index]["practice_users"]) {
          this.userService.get_mapped_practices(item._id).subscribe((data) => {
            this.allUserList[index].tabloaderIndicator=false;
            if (data.status == "success") {
             
              data.results.forEach((element) => {
                if (element.user_info.providers == null) {
                  element.user_info.providers = [];
                }
              });
              this.allUserList[index]["practice_users"] = data.results;
              this.allUserList[index]["practice_users_limit"] = 10;
              item["type"] = selectedTab.target.innerText;
            }
          });
        } else {
          this.allUserList[index].tabloaderIndicator=false;
          item["type"] = selectedTab.target.innerText;
        }
      } else if (this.seletedTab == "Roles") {
       
        this.dynamicBtn = "Map roles";
        if (!this.allUserList[index]["user_roles"]) {
          this.userService.get_mapped_roles(item._id).subscribe((data) => {
            this.allUserList[index].tabloaderIndicator=false;
            if (data.status == "success") {
             
              this.allUserList[index]["user_roles"] = data.results;
              this.allUserList[index]["user_roles_limit"] = 10;
              item["type"] = selectedTab.target.innerText;
            }
          });
        } else {
          this.allUserList[index].tabloaderIndicator=false;
          item["type"] = selectedTab.target.innerText;
        }
      }else if(this.seletedTab == "SkillSet"){
          this.dynamicBtn = "Update SkillSet";
          item["type"] = selectedTab.target.innerText;
          if (!this.allUserList[index]["user_skill"]) {
            this.userService.get_mapped_skillset(item._id).subscribe((data) => {
              this.allUserList[index].tabloaderIndicator=false;
              if(data.result !=null){
                this.allUserList[index].user_skill = data.result;
              }
            });
          } 
        }
    }
  }

  toggleLimit(item, limit, type) {
    item[type] = limit;
  }

  viewLoginHistory(id, name) {
    this.loginhistory.user_id = id;
    this.loginhistory.user_name = name;
    this.taskService.viewHistory = true;
  }

  viewSessionHistory(id, name) {
    this.usersinfo = {
      user_id: id,
      user_name: name,
    };
    this.selected_id = id;
    //this.Sessionhistory.user_id = id;
    //this.Sessionistory.user_name = name;
    this.taskService.viewSessionHistory = true;
    this.taskService.viewHistory = false;
  }

  backToUsers() {
    this.taskService.viewHistory = false;
    this.taskService.viewSessionHistory = false;
  }

  backToPrevious() {
    window.history.back();
  }

  openModal(template: TemplateRef<any>, from, infoData, parentIndex) {
    this.popUpfrom = from;
    this.parentIndexValue = parentIndex;
    if (this.popUpfrom == "newUser") {
      this.popupTitle = "New User";
      this.newuser = {
        userid: "",
        user_title: "",
        username: "",
        employee_name:"",
        display_name: "",
        email: "",
        mobile: "",
        is_internal: true,
        practice: false,
        brand: "Global",
        pseudonym: "",
        globexIntegration:false
      };
    } else if (this.popUpfrom == "editUser") {
      this.popupTitle = "Edit User";
      this.popUpinfo = infoData;
      this.newuser = {
        userid: this.popUpinfo._id,
        user_title: this.popUpinfo.user_title,
        username: this.popUpinfo.username,
        employee_name:this.popUpinfo.employeename,
        display_name:this.popUpinfo.displayname,
       // display_name: (this.popUpinfo.brand == 'Global') ? this.popUpinfo.username + "_" + this.popUpinfo.employee_name ? this.popUpinfo.employee_name : '' : this.popUpinfo.displayname,
        email: this.popUpinfo.email,
        mobile: this.popUpinfo.mobile,
        is_internal: this.popUpinfo.isInternal
          ? this.popUpinfo.isInternal
          : false,
        practice: this.popUpinfo.practice_visibility
          ? this.popUpinfo.practice_visibility
          : false,
        brand: this.popUpinfo.brand,
        pseudonym: this.popUpinfo.pseudonym,
        globexIntegration : this.popUpinfo.globexIntegration
      };
    }
    if (this.popUpfrom == "passwordReset") {
      this.popupTitle = "Reset password";
      this.popupUserInfo = infoData;
      this.resetPasswordType = 0;
      this.tempConfirmPassword = "";
      this.tempPassword = "";
    }
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: "static",
    });
  }
  
  modalClose() {
    this.taskReason = "";
    this.taskInstructions = "";
    this.SharedPopupService.dismiss();
    this.AlreadyExistFlag = false;
  }

  addUser(model: NewUser, isValid: boolean, mynform: NgForm) {
    this.mobilenoerror = false;
    model.mobile = model.mobile ? model.mobile.replace(/\_/g, "") : "";
    let strlen = model.mobile.length;

    model = this.removeNullEntries(model);
    this.User_Management_Data_list = {
      userid: model.userid,
      user_title: model.user_title,
      username: model.username,
      employeename:model.employee_name,
      displayname: model.display_name ? model.display_name : this.newuser.display_name,
      email: model.email,
      phone: model.mobile,
      is_internal: model.is_internal,
      practice_visibility: model.practice ? model.practice : false,
      brand: (model.brand) ? model.brand : 'Global',
      pseudonym: model.pseudonym,
      globexIntegration : (model.globexIntegration) ? model.globexIntegration : false
      // useris: model.useris
    };
    if (isValid == true) {
      if (this.popUpfrom == "newUser") {
        if (strlen != 0 && strlen < 12) {
          this.mobilenoerror = true;
        } else {
          this.mobilenoerror = false;
          this.userService.addUser(this.User_Management_Data_list).subscribe(
            (data) => {
              this.contacts = data;
              this.ValidatingNewUser(this.contacts.status);
              this.UserValidationMsg = this.contacts.message;
              this.UserValidationStatus = this.contacts.status;
              if (this.UserValidationStatus == "success") {
                this.User_Management_Data_list["_id"] = data.data._id;
                this.User_Management_Data_list["userid"] = data.data._id;
                this.User_Management_Data_list["enabled"] = true;
                this.allUserList.unshift(this.User_Management_Data_list);
                mynform.resetForm();
                this.modalClose();
                this.sharedService.pushToaster(
                  "success",
                  "Success",
                  "New User added successfully"
                );
                this.searchUsers();
              } else {
                this.sharedService.pushToaster(
                  "error",
                  "Error",
                  this.UserValidationMsg
                );
              }
            },
            (err) => this.logError(err)
          );
        }
      }

      if (this.popUpfrom == "editUser") {
        if (strlen != 0 && strlen < 12) {
          this.mobilenoerror = true;
        } else {
          this.mobilenoerror = false;
          this.userService.editUser(this.User_Management_Data_list).subscribe(
            (data) => {
              data = this.removeNullEntries(data);
              this.contacts = data;
              this.ValidatingNewUser(this.contacts.status);
              this.UserEditStatus = this.contacts.status;
              this.UserValidationMsg = this.contacts.message;
              if (this.UserEditStatus == "success") {
                this.allUserList[this.parentIndexValue].displayname = model.display_name;
                this.allUserList[this.parentIndexValue].employeename = model.employee_name;
                this.allUserList[this.parentIndexValue].mobile = model.mobile;
                this.allUserList[this.parentIndexValue].username = model.username;
                this.allUserList[this.parentIndexValue].user_title = model.user_title;
                this.allUserList[this.parentIndexValue].email = model.email;
                this.allUserList[this.parentIndexValue].practice_visibility = model.practice;
                this.allUserList[this.parentIndexValue].is_internal = model.is_internal;
                this.allUserList[this.parentIndexValue].brand = model.brand;
                this.allUserList[this.parentIndexValue].pseudonym = model.pseudonym;
                this.allUserList[this.parentIndexValue].globexIntegration = model.globexIntegration;
                mynform.resetForm();
                this.modalClose();
                this.sharedService.pushToaster(
                  "success",
                  "Success",
                  "User Update Successfully"
                );
                this.searchUsers();
              } else {
                if (this.UserValidationMsg) {
                  this.sharedService.pushToaster(
                    "error",
                    "Error",
                    this.UserValidationMsg
                  );
                }
                if (this.contacts.status) {
                  this.sharedService.pushToaster(
                    "error",
                    "Error",
                    this.contacts.status
                  );
                }
              }
            },
            (err) => this.logError(err)
          );
        }
      }
    } else {
      let username = new String(model.username);
      if (username.length != 0 && username.length < 3) {
        this.sharedService.pushToaster(
          "error",
          "Error",
          "username should not be less than 3 character"
        );
      }
      // let usernamelen = username.length()
      // model.username
    }
    // this.UserManageService.searchuser("").subscribe(userManagesA => this.manageuser = userManagesA);
  }

  userMfaUpdate(user, user_id) {
    if (user_id != undefined && user.mfa_enabled != undefined) {
      let mfa_enabled = {
        _id: user._id,
        mfa_enabled: user.mfa_enabled,
      };
      this.userService.userMfaUpdate(mfa_enabled).subscribe((data) => {
        if (data.status == "success") {
          this.sharedService.pushToaster(
            "success",
            "Success",
            "User MFA Update Successfully"
          );
        } else {
          this.sharedService.pushToaster(
            "error",
            "Error",
            "Something went wrong"
          );
        }
      });
    }
  }

  ValidatingNewUser(userresponse) {
    if (userresponse == "Username Already Exist") {
      this.successflag = false;
      this.AlreadyExistFlag = true;
    } else if (userresponse == "success") {
      this.AlreadyExistFlag = false;
      this.successflag = true;
      this.adduser_timer = setInterval(() => {
        this.successflag = false;
        // closing popup start
        // let el: HTMLElement = this.adduserpopup.nativeElement as HTMLElement;
        // el.click();
        // closing popup end
        clearInterval(this.adduser_timer);
      }, 1000);
    }
  }

  removeNullEntries(obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "object")
        obj[key] = this.removeNullEntries(obj[key]);
      else if (obj[key] === undefined || obj[key] === null) obj[key] = "";
      else obj[key] = typeof obj[key] === "string" ? obj[key].trim() : obj[key];
    });
    return obj;
  }

  clearform(mynform: NgForm) {
    mynform.resetForm();
    this.AlreadyExistFlag = false;
  }

  activeUser(id, userStatus, parentIndex) {
    this.SelectedUserId = id;
    userStatus = !userStatus;
    this.userService.activeUser(id, userStatus).subscribe(
      (userStatus) => (this.userStatus = userStatus),
      (err) => this.logError(err),
      () => {
        this.allUserList[parentIndex].enabled = userStatus;
        this.SharedPopupService.dismiss();
        this.sharedService.pushToaster(
          "success",
          "Success",
          userStatus == false
            ? "Deactivate User Successfully"
            : "Activate User Successfully"
        );
      }
    );
  }

  createnewgrouporrole(
    createnewtemplate: TemplateRef<any>,
    selectedUser,
    index
  ) {
    this.searchModel = "";
    "Accounts" == selectedUser.type
      ? (this.dynamicBtn = "Map accounts")
      : "Roles" == selectedUser.type
        ? (this.dynamicBtn = "Map roles")
        : "";
    this.displayuserName = selectedUser.username;
    this.selectedUserData = selectedUser;
    this.selecteduserIndex = index;
    this.existmappedaccountandrole = [];
    this.tmpMappingData = [];
    if (selectedUser.practice_users || selectedUser.user_roles) {
      let tmpexistingData = _.cloneDeep(
        selectedUser.type == "Accounts"
          ? selectedUser.practice_users
          : selectedUser.user_roles
      );
      if (selectedUser.type != "Accounts") {
        this.existmappedaccountandrole = tmpexistingData;
        this.tmpMappingData = JSON.parse(JSON.stringify(tmpexistingData));
      } else {
        tmpexistingData.forEach((element) => {
          if (element.enabled == true) {
            this.existmappedaccountandrole.push(element);
          }
          this.tmpMappingData.push(element);
        });
      }
    }

    if (selectedUser.type == "Accounts") {
      this.userService.searchBulkAccount("", selectedUser._id).subscribe(
        (data) => {
          if (data.status == "success") {
            this.allPracticesAccountandrole =
              data.results.length > 0 ? data.results : [];
            this.defaultSelection();
            this.providerTemplate = createnewtemplate;
            this.showModal(createnewtemplate);
          }
        },
        (err) => {
          alert("err");
          this.allPracticesAccountandrole = [];
        }
      );
    } else if (selectedUser.type == "Roles") {
      this.userService.loadRoles().subscribe(
        (data) => {
          if (data.status == "success") {
            this.allPracticesAccountandrole =
              data.results.length > 0 ? data.results : [];
            this.defaultSelection();
            this.showModal(createnewtemplate);
          }
        },
        (err) => {
          alert(err);
          this.allPracticesAccountandrole = [];
        }
      );
    }
  }

  showModal(createnewtemplate) {
    this.SharedPopupService.show(createnewtemplate, {
      animated: true,
      backdrop: "static",
      class: "modal-lg",
    });
  }

  defaultSelection() {
    if (this.dynamicBtn == "Map accounts") {
      this.allPracticesAccountandrole.forEach((element) => {
        let flag = false;
        this.existmappedaccountandrole.forEach((tmp) => {
          if (element._id == tmp.practice_id) {            
            flag = true;
          }
        });
        if (flag == false) {
          element.enabled = false;
        } else {
          element.enabled = true;
        }
      });
    } else {
      this.allPracticesAccountandrole.forEach((element) => {
        let flag = false;
        //this.existmappedaccountandrole.forEach((tmp) => {
        this.userRolesSelected.forEach((tmp) => {
          if (element._id == tmp.role_id) {  
            flag = true;
          }
        });
        if (flag == false) {
          element.isSelected = false;
        } else {
          element.isSelected = true;
        }
      });
    }
  }

  searchKey(value) {
   // this.loaderIndicator=true;
    clearTimeout(this.timeout);
    if (this.dynamicBtn == "Map accounts") {
      this.timeout = setTimeout((x) => {
        this.userService.searchBulkAccount(value,this.allUserList[this.selecteduserIndex]._id).subscribe((data) => {
            this.allPracticesAccountandrole = data.results.length > 0 ? data.results : [];
            this.defaultSelection();
          });
      }, 700);
    } else {
      this.timeout = setTimeout((x) => {
        this.userService.loadRoles(value).subscribe((data) => {
          if (data.status == "success") {
            this.allPracticesAccountandrole =
              data.results.length > 0 ? data.results : [];
            this.defaultSelection();
          } else {
            this.allPracticesAccountandrole = [];
          }
        });
      }, 400);
    }
  }

  
  ConfirmPopup(
    Confirmtemplate: TemplateRef<any>,
    from,
    userData,
    accountData,
    userindex,
    actionindex
  ) {
    this.ConfirmPopupfrom = from;
    this.selectedUserData = userData;
    this.selectedaccountData = accountData;
    this.userIndex = userindex;
    this.actionIndex = actionindex;
    if (this.ConfirmPopupfrom == "deactivatesingleaccount") {
      this.messageInfo = "Are you sure want to Deactivate Account?";
    } else if (this.ConfirmPopupfrom == "deactivateallaccount") {
      this.messageInfo = "Are you sure want to Deactivate All Accounts?";
    } else if (this.ConfirmPopupfrom == "activatesingleaccount") {
      this.messageInfo = "Are you sure want to Activate Account?";
    } else if (this.ConfirmPopupfrom == "userstatus") {
      if (this.selectedaccountData == true) {
        this.messageInfo = "Are you sure want to Deactivate user ?";
      } else {
        this.messageInfo = "Are you sure want to Activate user ?";
      }
    } else if (this.ConfirmPopupfrom == "deactivaterole") {
      this.messageInfo = "Are you sure want to Deactivate role ?";
    } else if (this.ConfirmPopupfrom == "deactivateallrole") {
      this.messageInfo = "Are you sure want to Deactivate all roles ?";
    }
    // else if(this.ConfirmPopupfrom == "activaterole"){
    //   this.messageInfo = "Are you sure want to Activate role ?"
    // }
    this.SharedPopupService.show(Confirmtemplate, {
      animated: true,
      backdrop: "static",
    });
  }

  onConfirm() {
    if (this.ConfirmPopupfrom == "deactivatesingleaccount") {
      this.deactivatSingleAccount("deactivate");
      // this.messageInfo = "Are you sure want to Deactivate Account group ?"
    } else if (this.ConfirmPopupfrom == "deactivateallaccount") {
      this.deactivatAllAccount();
      // this.messageInfo = "Are you sure want to Deactivate All Account group ?"
    } else if (this.ConfirmPopupfrom == "activatesingleaccount") {
      this.deactivatSingleAccount("activate");
    } else if (this.ConfirmPopupfrom == "deactivaterole") {
      this.deactivatoractiveSinglerole();
    } else if (this.ConfirmPopupfrom == "userstatus") {
      this.activeUser(
        this.selectedUserData,
        this.selectedaccountData,
        this.userIndex
      );
    } else if (this.ConfirmPopupfrom == "deactivateallrole") {
      this.deactivatoractiveallrole();
    }
    // else if(this.ConfirmPopupfrom == "activaterole"){
    //   this.deactivatoractiveSinglerole("active")
    // }
  }

  deactivatSingleAccount(actiontype) {
    if (this.selectedUserData && this.selectedaccountData) {
      let updateinfo = {
        enabled: actiontype == "deactivate" ? false : true,
        user_title: this.selectedUserData.user_title,
        practice_visibility: this.selectedUserData.practice_visibility,
      };
      this.userService
        .deleteAccount(
          this.selectedUserData._id,
          this.selectedaccountData.practice_id,
          updateinfo
        )
        .subscribe(
          (data) => {
            if (data.status == "success") {
              this.allUserList[this.userIndex].practice_users[
                this.actionIndex
              ].enabled = data.results.enabled;
            }
            this.SharedPopupService.dismiss();
            let msg = "Account deactivated successfully";
            if (data.results.enabled) {
              msg = "Account activated successfully";
            }
            this.sharedService.pushToaster("success", "Success", msg);
          },
          (err) => {
            this.SharedPopupService.dismiss();
          }
        );
    }
  }

  deactivatAllAccount() {
    if (this.selectedUserData) {
      this.userService.deleteAllAccount(this.selectedUserData._id).subscribe(
        (data) => {
          if (this.selectedUserData.practice_users.length) {
            this.selectedUserData.practice_users.forEach((element) => {
              element.enabled = false;
            });
          }
          this.sharedService.pushToaster(
            "success",
            "Success",
            "Account deactivated successfully"
          );
          this.SharedPopupService.dismiss();
        },
        (err) => {
          this.SharedPopupService.dismiss();
        }
      );
    }
  }
  deactivatoractiveallrole() {
    this.userService
      .removeUserAllRoles(this.selectedUserData._id)
      .subscribe((data) => {
        if (data.status == "success") {
          this.allUserList[this.userIndex].user_roles = [];
          this.SharedPopupService.dismiss();
          this.sharedService.pushToaster(
            "Success",
            "Success",
            "Deactivated all roles Successfully"
          );
        }
      });
  }
  deactivatoractiveSinglerole() {
    this.userService
      .removeUserRole(
        this.selectedUserData._id,
        this.selectedaccountData.role_id
      )
      .subscribe((data) => {
        if (data.status == "success") {
          this.allUserList[this.userIndex].user_roles.splice(
            this.actionIndex,
            1
          );
          this.SharedPopupService.dismiss();
          this.sharedService.pushToaster(
            "success",
            "Success",
            "Role deactivated successfully"
          );
        }
      });
    // rolesData.push({
    //   role_id: this.selectedaccountData._id,
    //   isCheck: action == 'active' ? true : false
    // });

    // this.userService.updateRoles(this.selectedUserData._id, rolesData).subscribe(data => {
    //   if (data.status == 'success') {
    //     if (from == 'all') {
    //       this.allUserList[this.userIndex].user_roles = [];
    //     } else {
    //       this.allUserList[this.userIndex].user_roles.splice(this.actionIndex, 1);
    //     }

    //     this.confirmmodalRef.hide();
    //     this.sharedService.pushToaster('success', 'Success', 'Role deactivated successfully');
    //   }
    // }, err => {
    //   alert(err);
    //   this.confirmmodalRef.hide();
    // });
  }

  onCancel() {
    this.SharedPopupService.dismiss();
  }

  selectPracticeAccount(data, i) {
    if (data.enabled) {
      if (this.existmappedaccountandrole == undefined) {
        this.existmappedaccountandrole = [];
      }
      data["enabled"] = true;
      data["practice_id"]=data._id;
      data['accountgroup'] = data.mappedaccount[0].accountgroup;
      this.existmappedaccountandrole.push(data);
      this.userService.addUserPractice(this.selectedUserData._id, data._id).subscribe((result) => {
     if (result.status == "success") {
        let mapped_user_index =this.allUserList[this.selecteduserIndex].practice_users.findIndex(user => {
           return user.practice_id === data._id 
          });
        if (mapped_user_index == -1) {
          data.practice_id =data._id;
        this.allUserList[this.selecteduserIndex].practice_users.push(data);
      } else {
        this.allUserList[this.selecteduserIndex].practice_users[mapped_user_index].enabled = true;
      }
            this.sharedService.pushToaster(
              "success",
              "Success",
              "Practice Added Successfully"
            );
          }
        });
    } else {
      if (this.dynamicBtn == "Map accounts") {
        let index = this.existmappedaccountandrole.findIndex( (result) => result.practice_id === data._id );
          if(index !== -1)
          {
            this.existmappedaccountandrole.splice(index, 1);
        }
        this.userService.deleteUserPractice(this.selectedUserData._id, data._id).subscribe(result => {
          if (result.status == "success") {
            let index =this.allUserList[this.selecteduserIndex].practice_users.findIndex((user) => {return user.practice_id == data._id });
            this.allUserList[this.selecteduserIndex].practice_users[index].enabled = false;
            this.sharedService.pushToaster(
              "success",
              "Success",
              "User removed from practice successfully"
            );
          }
        });

      } else {
        //var index = this.existmappedaccountandrole.findIndex((result) => result._id === data._id );
        // this.tmpMappingData.map((elem) => {
        //   if (elem._id === data._id) {
        //     elem.enabled = false;
        //   }
        //   return elem;
        // });
      }
      //this.existmappedaccountandrole.splice(index, 1);
    }
  }



  removeMappedAccount(userAccount, indexs) {
    if (userAccount) {
      if (this.dynamicBtn == "Map accounts") {
        let pindex = this.allPracticesAccountandrole.findIndex(
          (result) => {
            return result._id === userAccount.practice_id}
        );
        if (pindex != -1) {
          this.allPracticesAccountandrole[pindex].enabled = false;
        } 
       // this.allPracticesAccountandrole.push(userAccount);
        this.userService
          .deleteUserPractice(this.selectedUserData._id, userAccount.practice_id)
          .subscribe((data) => {
            if (data.status == "success") {
            let index =this.allUserList[this.selecteduserIndex].practice_users.findIndex((user) => {return user.practice_id == userAccount._id });

            if(index >= 0){
              this.allUserList[this.selecteduserIndex].practice_users[index].enabled = false;
            }

            this.existmappedaccountandrole.splice(indexs, 1);

            this.sharedService.pushToaster(
                "success",
                "Success",
                "Practice Removed Successfully"
              );
            }
          });
      } 
      // else {
      //   let index = this.allPracticesAccountandrole.findIndex(
      //     (result) => result._id === userAccount._id
      //   );
      //   if (index >= 0) {
      //     this.allPracticesAccountandrole[index].enabled = false;
      //     this.tmpMappingData.map((elem) => {
      //       if (elem._id === userAccount._id) {
      //         elem.enabled = false;
      //       }
      //       return elem;
      //     });
      //   }
      // }
      //this.existmappedaccountandrole.splice(indexs, 1);
    }
   }

  mapNewAccoutoUser() {
    let loopCount = 0;
    let allUsersAccounts = this.tmpMappingData;
    let updatedDataIds = [];
    this.existmappedaccountandrole.forEach((element) => {
      updatedDataIds.push(element.practice_id);
    });
    let mappedData = JSON.parse(JSON.stringify(this.existmappedaccountandrole));
    allUsersAccounts.forEach((element) => {
      let index = this.existmappedaccountandrole.findIndex(
        (result) => result.practice_id === element.practice_id
      );
      if (index != -1) {
        this.existmappedaccountandrole.splice(index, 1);
      }

      //
      if (updatedDataIds.indexOf(element.practice_id) == -1) {
        mappedData.push(element);
      }
    });
    let finalmappedData = [];
    let i = 0;
    mappedData.forEach((element) => {
      let selectedAccount = {
        practice_id: element.practice_id,
        user_title: element.user_title ? element.user_title : "default",
        user_id: this.allUserList[this.selecteduserIndex]._id,
        practice_visibility: element.practice_visibility
          ? element.practice_visibility
          : false,
        enabled: element.enabled,
        last_modified_date: new Date(),
        last_modified_user_id: this.DataHelper.getItem("userid"),
      };
      finalmappedData.push(selectedAccount);
      i++;
    });
    if (i == mappedData.length) {
      this.userService
        .bulkAccountInsert(
          this.allUserList[this.selecteduserIndex]._id,
          finalmappedData
        )
        .subscribe(
          (data) => {
            this.allUserList[this.selecteduserIndex].practice_users = mappedData;
            this.SharedPopupService.dismiss();
            this.sharedService.pushToaster(
              "success",
              "Success",
              "Accounts mapped successfully"
            );
          },
          (err) => {
            this.SharedPopupService.dismiss();
            alert("bulk account mapping failed ");
          }
        );
    }
  }

  mapNewRole() {
    let rolesData = [];
    let roleIds = [];
    this.existmappedaccountandrole.forEach((element) => {
      roleIds.push(element._id);
      rolesData.push({
        role_id: element._id,
        isCheck: true,
      });
    });
    this.tmpMappingData.forEach((element) => {
      if (roleIds.indexOf(element._id) == -1) {
        rolesData.push({
          role_id: element._id,
          isCheck: false,
        });
      }
    });
    this.userService
      .updateRoles(this.allUserList[this.selecteduserIndex]._id, rolesData)
      .subscribe(
        (data) => {
          if (data.status == "success") {
            this.allUserList[this.selecteduserIndex].user_roles = JSON.parse(
              JSON.stringify(this.existmappedaccountandrole)
            );
            this.sharedService.pushToaster(
              "success",
              "Success",
              "Roles mapped successfully"
            );
            this.SharedPopupService.dismiss();
          }
        },
        (err) => {
          this.SharedPopupService.dismiss();
        }
      );
  }

  passwordresetting() {
    if (this.resetPasswordType == 1) {
      if (this.popupUserInfo.username) {
        this.userService
          .resetPasswordWithOTP(this.popupUserInfo.username)
          .subscribe((data) => {
            if (data.status == "success") {
              this.sharedService.pushToaster(
                "success",
                "Success",
                "OTP Sent to your mobile number."
              ); // Please enter the OTP to reset your password
              this.modalClose();
            } else {
              this.sharedService.pushToaster("error", "Failure", data.message);
            }
          });
      }
    } else if (this.resetPasswordType == 2) {
      if (this.popupUserInfo._id) {
        this.userService
          .resetPasswordWithEmail(this.popupUserInfo._id)
          .subscribe((data) => {
            if (data.status == "success") {
              this.sharedService.pushToaster(
                "success",
                "Success",
                "Check your registered email to reset your password."
              ); // Please enter the OTP to reset your password
              this.modalClose();
            } else {
              this.sharedService.pushToaster("error", "Failure", data.message);
            }
          });
      }
    } else if (this.resetPasswordType == 3) {
      let strongRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
      );
      let password = this.tempPassword;

      if (this.tempPassword == this.tempConfirmPassword) {
        if (password.match(strongRegex)) {
          let data = {
            userId: this.popupUserInfo._id,
            newpassword: this.tempPassword,
          };

          this.userService.setTemporaryPassword(data).subscribe((data) => {
            if (data.status == "success") {
              this.sharedService.pushToaster(
                "success",
                "Success",
                "Temporary password sent to your registered email."
              );
              this.modalClose();
            } else {
              this.sharedService.pushToaster("error", "Failure", data.message);
            }
          });
        } else {
          this.sharedService.pushToaster(
            "error",
            "Failure",
            "Password should be 8 characters with 1 lowercase, 1 uppercase, 1 special character, 1 numeric"
          );
        }
      } else {
        this.sharedService.pushToaster(
          "error",
          "Failure",
          "Password does not match. please try again"
        );
      }
    } else if (this.resetPasswordType == 0) {
      this.sharedService.pushToaster(
        "error",
        "Alert",
        "Please select any option to reset your password"
      );
    }
  }

  editTitleinfo(
    titleeditpopup: TemplateRef<any>,
    userData,
    from,
    accountData,
    userindex,
    actionindex
  ) {
    this.selectedUserData = userData;
    this.selectedaccountData = accountData;
    this.userIndex = userindex;
    this.actionIndex = actionindex;
    this.editedTitle = accountData.user_title;
    this.SharedPopupService.show(titleeditpopup, {
      animated: true,
      backdrop: "static",
    });
  }

  updatetitle() {
    let updateinfo = {
      enabled: this.selectedaccountData.enabled,
      user_title: this.editedTitle,
      practice_visibility: this.selectedUserData.practice_visibility,
    };
    this.userService
      .deleteAccount(
        this.selectedUserData._id,
        this.selectedaccountData.practice_id,
        updateinfo
      )
      .subscribe((data) => {
        if (data.status == "success") {
          this.allUserList[this.userIndex].practice_users[
            this.actionIndex
          ].user_title = data.results.user_title;
          this.SharedPopupService.dismiss();
          this.sharedService.pushToaster(
            "success",
            "Success",
            "Practice title updated successfully"
          );
        }
      });
  }

  cancelmapNewAccouto() {
    this.SharedPopupService.dismiss();
  }
  cancelTitle() {
    this.SharedPopupService.dismiss();
  }

  onproviderspageChanged(ev){
    if(ev.page * this.pagelimit - this.pagelimit != this.providerspage){
      this.providerspage = ev.page * this.pagelimit - this.pagelimit;
      this.loaderIndicator = true;
      this.allPracticeProviders = [];
      this.pageChange();
    } 
  }


  pageChange(){
    this.loaderIndicator =true;
    this.practicesService
        .searchProvider(this.selectedPracticeObject.practice_id, this.searchProviderValue,"any",10,this.providerspage)
        .subscribe(
          (data) => {
            this.loaderIndicator =false;
            if (data.status == "success") {
              this.allPracticeProviders =
                data.results.length > 0 ? data.results : [];
              this.defaultProvidersSelection();
              //this.showModal(this.providerTemplate);
              this.providerspaginationCounts = data.totalCount;
              this.providerspageskip = 0;
            }
          },
          (err) => {
            alert("err");
            this.allPracticeProviders = [];
          }
        );
  }

  /*Mapping providers to practice start */
  mappingProviders(
    createnewprovidertemplate: TemplateRef<any>,
    selectedUser,
    index,
    selectedPractice,
    childindex
  ) {
    this.searchModel = "";
    this.dynamicBtn = selectedUser.type == "Accounts" ? "Map accounts" : "";
    this.displayuserName = selectedUser.displayname;
    this.userName = selectedUser.username;
    this.selectedPracticeName = selectedPractice.organization_name;
    this.selectedPracticeObject = selectedPractice;
    this.selectedUserObject = selectedUser;
    this.selecteduserIndex = index;
    this.selectedChildIndex = childindex;
    this.existmappedProviders = [];
    this.tmpMappingData = [];
    if (selectedPractice.user_info) {
      let tmpexistingData = _.cloneDeep(
        selectedUser.type == "Accounts"
          ? selectedPractice.user_info.providers
          : []
      );
      if (selectedUser.type != "Accounts") {
        this.existmappedProviders = tmpexistingData;
        this.tmpMappingData = JSON.parse(JSON.stringify(tmpexistingData));
      } else {
        tmpexistingData.forEach((element) => {
          // if(element.enabled==true){
          this.existmappedProviders.push(element);
          // }
          this.tmpMappingData.push(element);
        });
      }
    }

    if (selectedUser.type == "Accounts") {
      this.practicesService
        .searchProvider(selectedPractice.practice_id, "")
        .subscribe(
          (data) => {
            if (data.status == "success") {
              this.allPracticeProviders =
                data.results.length > 0 ? data.results : [];
              this.defaultProvidersSelection();
              this.providerTemplate = createnewprovidertemplate;
              this.showModal(createnewprovidertemplate);
              this.providerspaginationCounts = data.totalCount;
              this.providerspageskip = 0;
            }
          },
          (err) => {
            alert("err");
            this.allPracticeProviders = [];
          }
        );
    }
  }

  defaultProvidersSelection() {
    this.allPracticeProviders.forEach((element) => {
      let flag = false;
      this.existmappedProviders.forEach((tmp) => {
        if(tmp._id == undefined){
          tmp._id = tmp.provider_id
        }
        if (element._id == tmp._id) {
          tmp.organization_name = element.organization_name
            ? element.organization_name
            : element.first_name + " " + element.last_name;
          flag = true;
        }
      });
      if (flag == false) {
        element.enabled = false;
      } else {
        element.enabled = true;
      }
    });
  }

  searcProviderForMapping(value) {
    this.loaderIndicator =true;
    this.searchProviderValue = value;
    this.allPracticeProviders=[];
    clearTimeout(this.timeout);
    this.timeout = setTimeout((x) => {
      this.practicesService
        .searchProvider(this.selectedPracticeObject.practice_id, this.searchProviderValue)
        .subscribe((data) => {
          this.loaderIndicator =false;
          this.providerscurrentPage = 1;
          this.allPracticeProviders =
            data.results.length > 0 ? data.results : [];
          this.defaultProvidersSelection();
          this.providerspaginationCounts = data.totalCount;
          this.providerspageskip = 0;
        });
    }, 1000);
  }

  selectPracticeProviders(data, i) {
    let selectedAccount = {
      practice_id: data.practice_id,
      provider_id: data._id,
      status:data.enabled
    };
    if (data.enabled) {
      if (this.existmappedProviders == undefined) {
        this.existmappedProviders = [];
      }
      

      let index = this.allPracticeProviders.findIndex(
        (result) => result._id === selectedAccount.provider_id
      );
      if (index >= 0) {
        this.allPracticeProviders[index].enabled = true;
      }else{
        let subindex = this.allPracticeProviders.findIndex(
          (result) => result.provider_id === selectedAccount.provider_id
        );
        if (subindex >= 0) {
          this.allPracticeProviders[subindex].enabled = true;
        }
      }
      this.existmappedProviders.push({organization_name:data.organization_name!=null||data.organization_name!=undefined?data.organization_name:data.first_name +" "+data.last_name,practice_id:data.practice_id,provider_id:data._id});
      this.tmpMappingData.push({organization_name:data.organization_name!=null||data.organization_name!=undefined?data.organization_name:data.first_name +" "+data.last_name,practice_id:data.practice_id,provider_id:data._id})
            if (this.selectedPracticeObject.user_info) {
        this.selectedPracticeObject.user_info.providers.push({practice_id: selectedAccount.practice_id,
          _id: selectedAccount.provider_id});
      } else {
        this.selectedPracticeObject.user_info = { providers: {practice_id: selectedAccount.practice_id,
          _id: selectedAccount.provider_id} };
      }

      

      this.userService.mapPractice(this.selectedUserObject._id,selectedAccount).subscribe(
        (data) => {
          
          this.sharedService.pushToaster(
            "success",
            "Success",
            "Provider added successfully"
          );
        },
        (err) => {
          this.SharedPopupService.dismiss();
        }
      );

    } else {

      let index = this.allPracticeProviders.findIndex(
        (result) => result._id === selectedAccount.provider_id
      );
      if (index >= 0) {
        this.allPracticeProviders[index].enabled = false;
      }
     
      let index2 = this.existmappedProviders.findIndex(
        (result) => result.provider_id === selectedAccount.provider_id
      );
      if (index2 >= 0) {
        this.existmappedProviders.splice(index2, 1);
      }else{
        let subindex2 = this.existmappedProviders.findIndex(
          (result) => result._id === selectedAccount.provider_id
        );
        if (subindex2 >= 0) {
          this.existmappedProviders.splice(subindex2, 1);
        }
      }

      let index3 = this.tmpMappingData.findIndex(
        (result) => result.provider_id === selectedAccount.provider_id
      );
      if (index3 >= 0) {
        this.tmpMappingData.splice(index3, 1);
      }


      if (this.selectedPracticeObject.user_info) {

        let indexes = this.selectedPracticeObject.user_info.providers.findIndex(
          (result) => result.provider_id === selectedAccount.provider_id
        );
        if (indexes >= 0) {
          this.selectedPracticeObject.user_info.providers.splice(indexes, 1);
        }else{
          let subindexes = this.selectedPracticeObject.user_info.providers.findIndex(
            (result) => result._id === selectedAccount.provider_id
          );
          if (subindexes >= 0) {
            this.selectedPracticeObject.user_info.providers.splice(subindexes, 1);
          }
        }
      }

      this.userService.mapPractice(this.selectedUserObject._id,selectedAccount).subscribe(
        (data) => {
           
     

          this.sharedService.pushToaster(
            "success",
            "Success",
            "Provider removed successfully"
          );

        },
        (err) => {
          this.SharedPopupService.dismiss();
        }
      );

    }
  }

  removeMappedProvider(userAccount, indexs) {
    if (userAccount) {
      let index = this.allPracticeProviders.findIndex(
        (result) => result._id === userAccount._id
      );
      if (index >= 0) {
        this.allPracticeProviders[index].enabled = false;
      }else{
        let subindex = this.allPracticeProviders.findIndex(
          (result) => result._id === userAccount.provider_id
        );

        if (subindex >= 0) {
          this.allPracticeProviders[subindex].enabled = false;
        }
      }

      this.existmappedProviders.splice(indexs, 1);
      this.tmpMappingData.splice(indexs, 1);
      this.checkall = false;

      let selectedAccount = {
        practice_id: userAccount.practice_id,
        provider_id: userAccount._id != undefined ? userAccount._id:userAccount.provider_id,
        status:false
      };
            this.userService.mapPractice(this.selectedUserObject._id,selectedAccount).subscribe(
              (data) => {
                if (this.selectedPracticeObject.user_info) {

                  let indexes = this.selectedPracticeObject.user_info.providers.findIndex(
                    (result) => result._id === selectedAccount.provider_id
                  );
                  if (indexes >= 0) {
                    this.selectedPracticeObject.user_info.providers.splice(indexes, 1);
                  }else{
                    let subindexes = this.selectedPracticeObject.user_info.providers.findIndex(
                      (result) => result.provider_id === selectedAccount.provider_id
                    );
                    if (subindexes >= 0) {
                      this.selectedPracticeObject.user_info.providers.splice(subindexes, 1);
                    }
                  }
                }
                this.sharedService.pushToaster(
                  "success",
                  "Success",
                  "Provider removed successfully"
                );
              },
              (err) => {
                this.SharedPopupService.dismiss();
              }
            );
      
      

    }
  }

  selectAllProviders() {
    if (this.checkall == true) {
      this.existmappedProviders = [];
      this.allPracticeProviders.forEach((tmp) => {
        tmp.enabled = true;
        tmp["provider_id"] = tmp._id;
        this.existmappedProviders.push(tmp);
        this.tmpMappingData.push(tmp);
      });
    } else if (this.checkall == false) {
      this.allPracticeProviders.forEach((tmp) => {
        tmp.enabled = false;
        this.existmappedProviders = [];
        this.tmpMappingData = [];
      });
    }
  }

  saveMappedProviders() {
    let loopCount = 0;
    let allMappedData = this.tmpMappingData;
    let updatedDataIds = [];
    this.existmappedProviders.forEach((element) => {
      updatedDataIds.push(element.provider_id);
    });
    let mappedData = JSON.parse(JSON.stringify(this.existmappedProviders));
    allMappedData.forEach((element) => {
      let index = this.existmappedProviders.findIndex(
        (result) => result.provider_id === element.provider_id
      );
      if (index != -1) {
        this.existmappedProviders.splice(index, 1);
      }

      if (updatedDataIds.indexOf(element.provider_id) == -1) {
        mappedData.push(element);
      }
    });
    let finalmappedData = [];
    let i = 0;
    mappedData.forEach((element) => {
      let selectedAccount = {
        practice_id: element.practice_id,
        provider_id: element.provider_id,
      };
      finalmappedData.push(selectedAccount);
      i++;
    });
    if (i == mappedData.length) {
      this.userService
        .bulkProviderInsert(
          this.selectedUserObject._id,
          this.selectedPracticeObject.practice_id,
          finalmappedData
        )
        .subscribe(
          (data) => {
            if (this.selectedPracticeObject.user_info) {
              this.selectedPracticeObject.user_info.providers = mappedData;
            } else {
              this.selectedPracticeObject.user_info = { providers: mappedData };
            }
            this.SharedPopupService.dismiss();
            this.sharedService.pushToaster(
              "success",
              "Success",
              "Provider mapped successfully"
            );
          },
          (err) => {
            this.SharedPopupService.dismiss();
           // alert("bulk provider mapping failed ");
          }
        );
    }
  }
  /*Mapping providers to practice end */

  validatephoneno(value) {
    try {
      if (
        value &&
        value.length > 4 &&
        value.length < 18 &&
        value.search(/[a-z]/i) == -1
      ) {
        const phoneNumber = value;
        const regionCode = "US";
        const number = phoneUtil.parseAndKeepRawInput(phoneNumber, regionCode);
        this.mobilenoerror =
          phoneUtil.isValidNumber(number) == false ? true : false;
        if (phoneUtil.getRegionCodeForNumber(number) == "US") {
          this.newuser.mobile = "+1" + number.values_[2];
        }
      } else {
        this.mobilenoerror = value.length == 0 ? false : true;
      }
    } catch (e) {
      this.mobilenoerror = true;
    }
  }

  // Mapping roles to a user
  mapRoles(modalTemplate: TemplateRef<any>, selectedUser, index) {
    debugger;
    //createnewgrouporrole
    // initialize user roles data
    this.searchModel = "";

    this.selected_user_id = selectedUser.user_id;
    this.selected_user_displayname = selectedUser.displayname;

    this.parentIndexValue = index;

    this.searchResultsRoles = [];
    this.userRolesSelected = [];
    this.userRolesUpdates = {};

    Observable.forkJoin([
      this.userService.get_mapped_roles(selectedUser._id, 0),
      this.userService.loadRoles(""),
    ]).subscribe(
      (data) => {
        if (data[0].status == "success") {
          selectedUser.user_roles = data[0].results;
        } else {
          selectedUser.user_roles = [];
        }
        if (data[1].status == "success") {
          this.allPracticesAccountandrole =
            data[1].results.length > 0 ? data[1].results : [];
          this.searchResultsRoles =
            data[1].results.length > 0 ? data[1].results : [];
        } else {
          this.allPracticesAccountandrole = [];
          this.searchResultsRoles = [];
        }
        this.userRolesSelected = selectedUser.user_roles.map((r) => {
          // we could simply just use the source object as well
          return {
            role_id: r.role_id,
            groupname: r.groupname,
            description: r.description,
          };
        });
        this.markSelectedRoles();
        this.showModal(modalTemplate);
      },
      (err) => {
        this.sharedService.pushToaster(
          "error",
          "Error",
          "Something went wrong"
        );
      }
    );
  }

  markSelectedRoles() {
    // mark already enabled roles for user in search results
    this.searchResultsRoles.forEach((element) => {
      element.isSelected =
        this.userRolesSelected.findIndex((r) => r.role_id == element._id) > -1;
    });
  }

  // add/remove role based on selection
  toggleRole(selectedRole, i) {
    if (selectedRole.isSelected) {
      this.userService
        .addUserRole(this.selected_user_id, selectedRole._id)
        .subscribe(
          (data) => {
            if (data.status == "success") {
              // this.allUserList[this.userIndex].user_roles.splice(i, 1);
              this.allUserList[this.parentIndexValue].user_roles.push({
                role_id: selectedRole._id,
                groupname: selectedRole.groupname,
                description: selectedRole.description,
              });

              this.userRolesSelected.push({
                role_id: selectedRole._id,
                groupname: selectedRole.groupname,
                description: selectedRole.description,
              });
              this.sharedService.pushToaster(
                "success",
                "Success",
                "Role mapped successfully"
              );
            }
          },
          (error) => {
            selectedRole.isSelected = !selectedRole.isSelected;
            this.sharedService.pushToaster(
              "error",
              "Error",
              "Something went wrong"
            );
          }
        );
    } else {
      this.userService
        .removeUserRole(this.selected_user_id, selectedRole._id)
        .subscribe(
          (data) => {
            if (data.status == "success") {
              let selectedIndex = this.userRolesSelected.findIndex(
                (r) => r.role_id == selectedRole._id
              );
              if (selectedIndex > -1)
                this.userRolesSelected.splice(selectedIndex, 1);
              this.allUserList[this.parentIndexValue].user_roles.splice(
                selectedIndex,
                1
              );
              this.sharedService.pushToaster(
                "success",
                "Success",
                "Role removed successfully"
              );
            }
          },
          (error) => {
            selectedRole.isSelected = !selectedRole.isSelected;
            this.sharedService.pushToaster(
              "error",
              "Error",
              "Something went wrong"
            );
          }
        );
    }
  }

  removeMappedRole(selectedRole, i) {
    // user removes an item from the selected list
    this.userService
      .removeUserRole(this.selected_user_id, selectedRole.role_id)
      .subscribe(
        (data) => {
          if (data.status == "success") {
            console.log(this.searchResultsRoles,'test')
            let searchIndex = this.searchResultsRoles.findIndex(
              (r) => r._id == selectedRole.role_id
            );
            if (searchIndex > -1)
            this.userRolesSelected.splice(i, 1);
            this.allUserList[this.parentIndexValue].user_roles.splice(
              i,
              1
            );
              this.searchResultsRoles[searchIndex].isSelected = false;

              this.defaultSelection();
            this.sharedService.pushToaster(
              "success",
              "Success",
              "Role removed successfully"
            );
      //console.log(this.userRolesSelected, "jghfhgjfhhfj");
          }
        },
        (error) => {
          selectedRole.isSelected = !selectedRole.isSelected;
          this.sharedService.pushToaster(
            "error",
            "Error",
            "Something went wrong"
          );
        }
      );
  }

  searchRoles(value) {
    let tempBool;
    let testPattern = /^[a-zA-Z0-9\.\-\/ ]*$/;
    let regExVal = new RegExp(testPattern);
    tempBool = regExVal.test(value);
    if (tempBool) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout((x) => {
        this.userService.loadRoles(value).subscribe((data) => {
          if (data.status == "success") {
            this.searchResultsRoles =
              data.results.length > 0 ? data.results : [];
            this.defaultSelection();
          } else {
            this.searchResultsRoles = [];
          }
        });
      }, 400);
    }
  }
  // Mapping roles to use end
  ngOnDestroy(): void {
    this.SharedPopupService.dismiss();
  }
  // collapse skillset
  isCollapsedcalling: boolean = true;
  isCollapsedpractice:boolean = true;
  isCollapsedspecialties:boolean = true;

}
