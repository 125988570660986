import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as myGlobals from '../../globals';
@Injectable({
  providedIn: 'root'
})
export class StatuscodesService {

  constructor(private http: HttpClient) { }

  statusCodeList(search_query,status, page= 0, limit= 20) {
    //alert(1)
    return this.http.get<any>(myGlobals.status_codes_URL+"?q="+search_query+"&status="+status+ '&page=' + page + '&pagesize=' + limit );
  }
  createNewStatusCode(statusCode){
    return this.http.post<any>(myGlobals.status_codes_URL,statusCode);
  }
  updateStatusCode(status_code_id,updateData){
    return this.http.put<any>(myGlobals.status_codes_URL+"/"+status_code_id,updateData);
  }

  get_mapped_action_codes(statuscode_id) {
    return this.http.get<any>(myGlobals.status_codes_URL +'/'+ statuscode_id +"/actioncode?size=0");
  }

  searchBulkActionCodes(search) {
    return this.http.get<any>(myGlobals.status_codes_URL +"/list?q="+search);
  }

  // bulkActioncodesInsert(action_code_id, data){
  //   return this.http.put<any>(myGlobals.status_codes_URL + '/' + action_code_id +'/mapping',data);
  // }

  ActioncodesInsert(status_code_id, action_code_id){
    return this.http.post<any>(myGlobals.status_codes_URL + '/' + status_code_id +'/actioncode/' + action_code_id , '');
  }

  activeAndDeactive(statuscodeid,actioncodeid, data){
    let req={ actioncode : data };
    return this.http.put<any>(myGlobals.status_codes_URL + '/' + statuscodeid +"/"+actioncodeid+'/statusupdate', req);
  }

  ActioncodesDelete(status_code_id, action_code_id){
    return this.http.delete<any>(myGlobals.status_codes_URL + '/' + status_code_id +'/actioncode/' + action_code_id);
  }
  
}
