import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, TemplateRef } from '@angular/core';
import { TaskService } from '../../_services/task/task.service';
import * as moment from 'moment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../../_services/shared.service';
@Component({
  selector: 'app-task-summary',
  templateUrl: './task-summary.component.html',
  styleUrls: ['./task-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaskSummaryComponent implements OnInit {
  @Input('taskData') taskData: any;
  @Input('canModifyReasons') canModifyReasons: boolean;
  @Input('fromModule') fromModule: string;
  @Output() canModify = new EventEmitter();
  disableModify: Boolean;
  admitDate:any;
  // modalRef: BsModalRef;
  // chargeStatusTransition:any;
  // enableSaveButton:boolean;
  // selectStatus:any;
  // global_data:any;
  constructor(public taskService: TaskService, public modalService: BsModalService, private sharedService: SharedService) {
  }

  ngOnInit() {
    // console.log("this.taskService.currentTaskData.practice_name.address['city']", this.taskService.currentTaskData.charge_info[0].selected_case)
  }
  ngOnChanges() {
     if(this.taskData?.charge_info?.length > 0){
      const data = (this.taskData.charge_info[0].diagnosis_codes_info) ? this.taskData.charge_info[0].diagnosis_codes_info : [];
      const duplicateIds = data.map(v => v.code).filter((v, i, vIds) => vIds.indexOf(v) !== i);
      const duplicates = data.filter(obj => duplicateIds.includes(obj.code));
      duplicates.forEach((el, i) => {
        el.isduplicate = true;
      });

      if(this.taskData.charge_info[0].admission_date){
        this.admitDate = this.taskData.charge_info[0].admission_date;
      }else{
        this.admitDate = '';
      }
  	}
  }

  childOnSelect(eve, template) {
    this.disableModify = true;
    // console.log("childOnSelectchildOnSelect", eve, template)
    this.canModify.emit({ eve, template });
    setTimeout(() => {
      this.disableModify = false;
    }, 600);
  }

//   changeStatusPopup(template: TemplateRef<any>) {
//     this.enableSaveButton=false;
//     this.selectStatus='';
//     console.log(this.taskData);
//     //this.selectedCurrentUser = this.taskData.assigned_user_id;
//     //this.searchList = '';
//     //this.disableAssignedTo = true;
//     this.taskService.searchChargeStatuses('visit_statuses').subscribe(data => {
//       if(data.status=='success'){
   //console.log(data.results[0].values[this.taskData.charge_info[0].status].statuses)
//         //this.allUserList = data;
//         this.chargeStatusTransition = data.results[0].values[this.taskData.charge_info[0].status].statuses;
//         this.global_data = data.results[0].values;
//         this.modalRef = this.modalService.show(template, {
//             animated: true,
//             backdrop: 'static'
//         });
//       }
//     }, function (err) {
//         // this.modalRef = this.modalService.show(template, {
//         //     animated: true,
//         //     backdrop: 'static'
//         // });
//     });
// }

// modalClose() {
//   this.modalRef.hide();
// }

// selectedStatus(value){
//   if(value !=''){
//     this.enableSaveButton = true;
//     this.selectStatus= value;
//   }
//   console.log(value)
// }

// savechanges(){
//   this.taskService.saveChargeStatus(this.taskData.charge_id, this.selectStatus).subscribe(data => {
//     this.modalRef.hide();
//     if(data.status=='success'){
//       this.taskData.charge_info[0].status = this.selectStatus;
//       this.taskData.charge_info[0].charge_status_color = this.global_data[this.taskData.charge_info[0].status].task_color;
//       this.taskData.charge_info[0].activities.push(data.activities)
//       this.sharedService.pushToaster('success', 'Success', 'Charge status updated successfully');
//     }
//   });
// }

// phiVisibility(phiFlag, from){
//   if(phiFlag.length > 0 && phiFlag[0].show_phi == true){
//     if(from == 'patient_dob'){
//       this.taskData.patient.show_dob = true;
//       this.taskData.patient.hide_dob = true;
//       setTimeout(() => {
//           this.taskData.patient.show_dob = false;
//           this.taskData.patient.hide_dob = false;
//       }, 5000);
//     }else if(from == 'ins_policy'){
//         this.taskData.show = true;
//         this.taskData.hide = true;
//         setTimeout(() => {
//             this.taskData.show = false;
//             this.taskData.hide = false;
//         }, 5000);
//     }
//   }else{
//     this.sharedService.pushToaster('warning', 'warning', 'PHI information is disabled');
//   }
// }
phiVisibility( phiFlag, from, event,leaveFlag){
 if(phiFlag.length > 0 && phiFlag[0].show_phi == true) 
 {
  if(from == 'ins_policy'){ 
          if(event == 'down'){
            this.taskData.show = true;
            this.taskData.hide = true;
          }else{
            this.taskData.show = false;
           this.taskData.hide = false;
          }
  }else if(from == 'patient_dob'){
          if(event == 'down'){
            this.taskData.patient.show_dob = true;
            this.taskData.patient.hide_dob = true;
          }else{
            this.taskData.patient.show_dob = false;
            this.taskData.patient.hide_dob = false;
          }
  }
}else if(leaveFlag!=1){
  this.sharedService.pushToaster('warning', 'warning', 'PHI information is disabled');
}
}
 }
