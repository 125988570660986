import { Injectable } from '@angular/core';
import { navItems } from '../../_nav';
import { DataHelperService } from './data-helper.service';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { TaskService } from '../task/task.service';
import { features } from 'process';

@Injectable({
  providedIn: 'root'
})
export class RouteAuthorizationService {
  navLists = navItems;
  navLists1 = navItems;
  purePersonalNavLists: any;
  public dashboardViewChangeEmitter = new Subject<any>();
  userPermissions = [];
  userFeatures = [];
  private routerRefresh = new Subject<any>();
  changeEmitted$ = this.routerRefresh.asObservable();
  constructor(private dataHelperService: DataHelperService, private taskService: TaskService) {
    this.navLists = [];
    this.filterRoutes();
    this.purePersonalNavLists = new BehaviorSubject([]);
  }

  addPersionalViewList(): void {
    let purePersonalNavLists = [];
    this.taskService.getSavedPersonalViews().subscribe(data => {
      if (data.hasOwnProperty('personalviews')) {
      this.navLists1['1']['children'].splice(6, 7);
      data['personalviews'].forEach((element, i, array) => {
        if (i < 5) {
          this.navLists1['1']['children'].push({
            name: element.filter_name,
            url: '/task/' + element.filter_name,
            icon: 'fal fa-clipboard-list-check',
            data: {'urlFilter': element.filter_name},
            permissions : ['Portal Task Read', 'Portal Task Admin']
          });
        }
        if (i == 4) {
          this.navLists1['1']['children'].push({
            name: 'More Personal views',
            url: '/task/more-personal-views',
            icon: 'fal fa-clipboard-list-check',
            data: {'urlFilter': 'more-personal-views'},
            permissions : ['Portal Task Read', 'Portal Task Admin']
          });
        }
        purePersonalNavLists.push({
          name: element.filter_name,
          url: '/task/' + element.filter_name,
          icon: 'fal fa-clipboard-list-check',
          data: {'urlFilter': element.filter_name},
          taskstatusObj :  element['taskstatusObj'],
          tasktypeObj : element['tasktypeObj'],
          assignedUsersList : element['assignedUserskeyList'],
          accountskeyList : element['accountskeyList'],
          providerskeyList : element['providerskeyList'],
          facilitieskeyList: element['facilitieskeyList'],
          dateOfServiceRange: element['dateOfServiceRange'],
          modifiedDateRange: element['modifiedDateRange'],
          createdDateRange: element['createdDateRange'],
          permissions : ['Portal Task Read', 'Portal Task Admin']
        });
        if (i == array.length - 1) {
          // this.purePersonalNavLists = purePersonalNavLists;
        this.purePersonalNavLists.next(purePersonalNavLists);
        this.filterRoutes();
        }
      });
      } else {
        this.navLists1['1']['children'].splice(6, 7);
        this.purePersonalNavLists.next(purePersonalNavLists);
        this.filterRoutes();
      }
    });
  }
  filterRoutes() {
    this.userPermissions = this.dataHelperService.getItem('userPermissions');
    this.userFeatures = this.dataHelperService.getItem('features');
    return new Promise((resolve, reject) => {
      let src_navItems = JSON.parse(JSON.stringify(this.navLists1));
      this.navLists = this.filterObj(src_navItems);
      this.routerRefresh.next(this.navLists);
      resolve(this.navLists);
    });
  }

  localRefresh(local) {
    return new Promise((resolve, reject) => {
      this.routerRefresh.next(local);
    });
  }


  filterObj(parents: any[]) {
    let local_parents = [];
    parents.forEach(parent => {
      if (!this.checkPermission(parent)) {
        // console.log('not authorizeed', parent);
      } else {
        if (parent.children) {
          parent.children = this.filterObj(parent.children);
        } else {
          // return parent;
        }
        local_parents.push(parent);
      }
    });
    return local_parents;
  }
  checkPermission(parent) {
    let valid = false;
    let featureValid = false;
    if (!parent.permissions) {
      valid = true;
    } else {
      parent.permissions.forEach(permission => {
        if (this.userPermissions && this.userPermissions.indexOf(permission) != -1) {
          valid = true;
        }
      });
    }

    if (!parent.features) {
      featureValid = true;
    } else {
      parent.features.forEach(feature => {
        if (this.userFeatures && this.userFeatures.indexOf(feature) != -1) {
          featureValid = true;
        }
      });
    }

    return valid && featureValid;
  }

  sendMessage(message: string) {
    this.dashboardViewChangeEmitter.next(message);
  }

  clearMessages() {
    this.dashboardViewChangeEmitter.next();
  }

  onMessage(): Observable<any> {
    return this.dashboardViewChangeEmitter.asObservable();
  }
}
