import { Component, OnDestroy, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { conformToMask } from 'angular2-text-mask';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { SharedPopupService } from '../../../_services/popup.service';
import { DataHelperService } from '../../../_services/auth/data-helper.service';
import { HeaderService } from '../../../_services/header/header.service';
import { PracticesService } from '../../../_services/settings/practices.service';
import { SharedService } from '../../../_services/shared.service';
import { TaskService } from '../../../_services/task/task.service';
import constants from '../../../constants';




class ProviderObject {
  providerId: String;
  practiceId: String;
  practiceName: String;
  userId: String;
  userName: String;
  npi: String;
  ssn: String;
  ein: String;  // This is tax_id field in API
  type: String;
  entity: boolean;
  organizationName: String;
  firstName: String;
  lastName: String;
  middleName: String;
  shortName: String;
  prefixName: String;
  streetLine1: String;
  streetLine2: String;
  city: String;
  state: String;
  zipcode: String;
  enabled: boolean;
  taxonomyCode: String;
  alternativeTaxonomy: String;
  listTypes: any[];
  extCode: string;
  extId: string;
  extName: string;
}

@Component({
  selector: 'app-provider-details',
  templateUrl: './provider-details.component.html',
  styleUrls: ['./provider-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProviderDetailsComponent implements OnInit, OnDestroy {
  subscription1$: Subscription;
  selectedPracticeVal: string;
  practiceProviders: any;
  practiceIdFlag: boolean;
  searchValue: any;
  popUpfrom: any;
  popUpinfo: any;
  providerDetails: any;
  stateSuccessResult: string;
  popupTitle: string;
  public providerObject: ProviderObject;
  listTypes: any[];
  userSelectedText: any;
  public zipCodeFive = [/\d/, /\d/, /\d/, /\d/, /\d/];
  public zipCodeNine = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public ssnMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public einMask = [/[1-9]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];


  sameAsPracticeFacility: boolean;
  sameAsPractice: boolean;
  currentPracticeAddressProviders: any;
  public taxonomy_array_item: Array<{ id: string, text: string }>;
  public alt_taxonomy_array_item: Array<{ id: string, text: string }>;
  taxonomy_timer: any;
  taxonomySelectedCode: any;
  altTaxonomySelectedCode: any;
  public state_array_item: Array<{ id: string, text: string }>;
  practiceUserEmptyResult: any;
  public user_array_item: Array<{ id: string, text: string }>;
  timeout = null;
  userSelectedId: any;
  selectedStateCode: any;
  providerData: any;
  allAccountsList: any;
  contacts: any;
  validationMessage: any;
  validationStatus: any;
  practiceAccountEditStatus: any;
  successflag: any;
  alreadyExistFlag: any;
  timer: any;
  maskValidationPro: boolean;
  maskValidation: boolean;
  confirmPopupFrom: any;
  messageInfo: String;
  selectedPracticeUserObject: any;
  providerIdex: any;
  tmpMappingDataProvider: any[];
  existingMappedFacilities: any[];
  practiceFacilitiesData: any;
  groupedFacilities: any;
  searchKeyWordFacility: String;
  showInactive: boolean;
  pagelimit: number;
  pageCount: any;
  paginationCounts: number;
  paginationCountV2: number;
  pageskip: any;
  page: number;
  selectedProviderIndex: number;
  isValidProviderForm: boolean;
  currentPage: number;
  currentPageV2: number;
  inactivePageBool: boolean;
  flagIndicator: boolean;
  medicalCodingAvailable: boolean;
  constants:any;
  loaderIndicator:boolean;
  modelOpen:boolean;
  constructor(
    private practicesService: PracticesService,
    private DataHelper: DataHelperService,
    public bsModalService: BsModalService,
    public SharedPopupService: SharedPopupService,
    public taskService: TaskService,
    private sharedService: SharedService,
    private headerService: HeaderService) {
    this.practiceProviders = [];
    this.flagIndicator = false;
    this.practiceIdFlag = true;
    this.searchValue = '';
    this.stateSuccessResult = '';
    this.sameAsPracticeFacility = false;
    this.sameAsPractice = false;
    this.taxonomy_array_item = [];
    this.alt_taxonomy_array_item = [];
    this.state_array_item = [];
    this.user_array_item = [];
    this.allAccountsList = [];
    this.tmpMappingDataProvider = [];
    this.existingMappedFacilities = [];
    this.groupedFacilities = [];
    this.searchKeyWordFacility = '';
    this.showInactive = false;
    this.paginationCounts = 0;
    this.paginationCountV2 = 0;
    this.pagelimit = 10;
    this.pageskip = 0;
    this.page = 0;
    this.inactivePageBool = false;
    this.isValidProviderForm = false;
    this.constants=constants;

    if (localStorage.getItem('practice_id')) {
      this.selectedPracticeVal = this.DataHelper.getItem('practice_id');
      this.searchProvider('');
      this.practiceIdFlag = false;
      this.searchValue = '';
      this.popUpinfo = '';
      this.userSelectedText = '';

    }
    this.subscription1$ = this.headerService.practiceEmitted$.subscribe(newPractice => {
      this.selectedPracticeVal = newPractice;
      this.practiceIdFlag = false;
      this.pageskip = 0;
      this.currentPage = 1;
      this.currentPageV2 = 1;
      this.showInactive = false;
      this.searchValue = '';
      this.searchProvider('');
    });
    this.providerObject = {
      providerId: '',
      practiceId: '',
      practiceName: '',
      userId: '',
      userName: '',
      npi: '',
      ssn: '',
      ein: '',  // This is tax_id field in API
      type: '',
      entity: false,
      organizationName: '',
      firstName: '',
      lastName: '',
      middleName: '',
      shortName: '',
      prefixName: '',
      streetLine1: '',
      streetLine2: '',
      city: '',
      state: '',
      zipcode: '',
      enabled: true,
      taxonomyCode: '',
      alternativeTaxonomy: '',
      listTypes: this.listTypes,
      extCode: '',
      extId: '',
      extName: ''
    };
    this.loaderIndicator= true;
    this.modelOpen=false;
  }

  ngOnInit() {
    this.listTypes = ['Individual', 'Organization'];
    this.contacts = 'fail';
    this.pageskip = 0;
    this.taskService.initialStateList();
  }

  /*initialStateList(){
    this.taskService.listStateNames().subscribe(result => {
      if (result.status == 'success') {
        this.taskService.stateList=[];
        result.results.forEach(element => {
          this.taskService.stateList.push({"state_code" : element.state_code});
        });
      }
    });
  }*/

  logError(arg0: any): any {
    throw new Error('Method not implemented.');
  }
  ngOnDestroy() {
    this.subscription1$.unsubscribe();
    this.SharedPopupService.dismiss();
  }
  zipCodeMask(): any {
    return {
      mask: (value) => {
        if (value.length <= 5) {
          return this.zipCodeFive;
        } else {
          return this.zipCodeNine;
        }
      },
      guide: false
    };
  }
  checkDigit(event: any) {
    let pattern = /\d/g;
    let checkIsNumericKey = event.key.match(pattern);
    if (checkIsNumericKey != null) {
      let conformedNumber = conformToMask(
        event.key,
        this.einMask,
        { guide: false }
      );
      this.maskValidation = conformedNumber.meta.someCharsRejected;
    }
  }
  ssnDigit(event: any) {
    let pattern = /\d/g;
    let checkIsNumericKey = event.key.match(pattern);
    if (checkIsNumericKey != null) {
      let conformedNumber = conformToMask(
        event.key,
        this.ssnMask,
        { guide: false }
      );
      this.maskValidationPro = conformedNumber.meta.someCharsRejected;
    }
  }
  onpageChanged(ev) {
    if(this.modelOpen == false){
      this.page = ev.page * this.pagelimit - this.pagelimit;
      this.searchProvider('pagination');
      this.inactivePageBool = false;
    } else {
      this.page = ev == 0 ? 0 : (ev.page * this.pagelimit - this.pagelimit) ;
      this.mapFacilitiesToProviderV2();
    }
  }

  searchProvider(from, actionType = '') {
    //this.practiceProviders=[];
    this.loaderIndicator=true;
    let tempBool = false;
    if (actionType == 'search') {
      let datePattern = /^[a-zA-Z0-9\.\- ]*$/;
      let regExVal = new RegExp(datePattern);
      tempBool = regExVal.test(from);
    }
    let newactive = (this.showInactive == true) ? 'false' : 'true';
    if (tempBool || from == "pagination" || from == "") {
      if (from != 'pagination' && actionType != 'deactivated') {
        this.page = 0;
        this.currentPage = 1;
      }
      clearTimeout(this.timeout);
      this.timeout = setTimeout(x => {
        this.practicesService.searchProvider(this.selectedPracticeVal, this.searchValue.trim(), newactive, this.pagelimit, this.page, true).subscribe((data: any) => {
          this.loaderIndicator=false;
          if (data.status == 'success') {
            this.practiceProviders = data.results;
            if (data.current_practice && data.current_practice.medical_coding != undefined) {
              this.medicalCodingAvailable = (data.current_practice.medical_coding) ? true : false;
            } else {
              this.medicalCodingAvailable = false;
            }
            if (this.practiceProviders.length > 0) {
              this.practiceIdFlag = false;
            }
            if (this.searchValue && this.practiceProviders.length == 0) {
              this.practiceIdFlag = false;
            }
            if (data.current_practice && data.current_practice.practice_address && data.current_practice.practice_address[0]) {
              this.currentPracticeAddressProviders = data.current_practice.practice_address[0];
            } else {

            }
            this.paginationCounts = data.totalCount;
            this.pageskip = this.page;

            if (data.results.length == 0 && actionType == 'deactivated') {
              // this.pagelimit=this.pagelimit;
              if (this.page != 0) {
                let currentPageLength = this.practiceProviders.length % this.pagelimit;
                if (currentPageLength == 0) {
                  this.page = this.page - this.pagelimit;
                  this.searchProvider('', 'deactivated');
                }
              }
            }
            
          } else {
            this.practiceIdFlag = false;
          }
         
        });
      }, 700);
    }
  }

  inactive() {
    this.showInactive = this.showInactive ? false : true;
    this.inactivePageBool = true;
    this.page = 0;
    this.currentPage = 1;
    this.searchProvider('');
    this.searchValue = '';
  }
  /*  Start - Add & Edit Providers */
  // tslint:disable-next-line: no-shadowed-variable
  openProviderModal(template: TemplateRef<any>, from, infoData, index) {
    this.popUpfrom = from;
    this.popUpinfo = infoData;
    this.providerDetails = infoData;
    this.selectedProviderIndex = index;
    if (from == 'addProvider') {
      this.stateSuccessResult = 'success';
      this.popupTitle = 'New Provider for Practice';
      this.taxonomySearch('', '', 'initial');
      if (this.practiceProviders.length == 0) {
        this.practicesService.getCurrentPracticeDetails(this.selectedPracticeVal).subscribe(practice => {
          if (practice.length > 0) {
            this.currentPracticeAddressProviders = practice[0].address;
          } else {
            this.currentPracticeAddressProviders = [];
          }
        });
      }
      this.providerObject = {
        providerId: '',
        practiceId: '',
        practiceName: '',
        userId: '',
        userName: '',
        npi: '',
        ssn: '',
        ein: '',  // This is tax_id field in API
        type: 'Individual',
        entity: false,
        organizationName: '',
        firstName: '',
        lastName: '',
        middleName: '',
        shortName: '',
        prefixName: '',
        streetLine1: '',
        streetLine2: '',
        city: '',
        state: '',
        zipcode: '',
        enabled: true,
        taxonomyCode: '',
        alternativeTaxonomy: '',
        listTypes: this.listTypes,
        extCode: '',
        extId: '',
        extName: ''
      };

    } else if (from == 'editProvider') {
      this.popupTitle = 'Edit Provider Details';
      if (this.providerDetails.address) {
        this.getState(null, this.providerDetails.address.state);
      }
      if (this.userSelectedText && (this.userSelectedText.length > 0)) {
        this.getUser(this.userSelectedText);
      } else {
        this.getUser(this.providerDetails.username);
      }
      let openEin;
      if (this.providerDetails.tax_id) {
        openEin = conformToMask(
          this.providerDetails.tax_id,
          this.einMask,
          { guide: false }
        ).conformedValue;
      }
      this.providerObject = {
        providerId: this.providerDetails._id,
        practiceId: this.providerDetails.practice_id,
        practiceName: '', // Need to fetch this for title purpose
        userId: this.providerDetails.user_id,
        userName: this.providerDetails.username,
        npi: this.providerDetails.npi,
        ssn: this.providerDetails.ssn,
        ein: this.providerDetails.tax_id ? openEin : this.providerDetails.tax_id,  // This is tax_id field in API
        type: this.providerDetails.type,
        entity: this.providerDetails.entity,
        organizationName: this.providerDetails.organization_name,
        firstName: this.providerDetails.first_name,
        lastName: this.providerDetails.last_name,
        middleName: this.providerDetails.middle_name,
        shortName: this.providerDetails.short_name,
        prefixName: this.providerDetails.prefix_name ? this.providerDetails.prefix_name : this.providerDetails.title,
        streetLine1: this.providerDetails.address.street_line_1,
        streetLine2: this.providerDetails.address.street_line_2,
        city: this.providerDetails.address.city,
        state: this.providerDetails.address.state,
        zipcode: this.providerDetails.address.zip,
        enabled: this.providerDetails.enabled,
        taxonomyCode: this.providerDetails.taxonomy_code,
        alternativeTaxonomy: this.providerDetails.alt_taxonomy,
        listTypes: this.listTypes,
        extCode: this.providerDetails.external_ids.ext_code,
        extId: this.providerDetails.external_ids.ext_id,
        extName: this.providerDetails.external_ids.ext_name
      };
      if (this.providerObject.zipcode) {
        this.zipCodeMask();
      }
      this.taxonomySearch(this.providerObject.taxonomyCode, 'taxonomy', 'initial');
      this.taxonomySearch(this.providerObject.alternativeTaxonomy, 'alt_taxonomy', 'initial');
    }
    this.SharedPopupService.show(template, {
      animated: true,
      // backdrop: true
      backdrop: 'static'
      // backdrop: false,
    });
  }
  // modalClose() {
  //   this.modalRef.hide();
  // }
  sameAsPracticeAddressProvider(addProvider, editProvider, val) {
    this.stateSuccessResult = '';
    this.sameAsPracticeFacility = val;
    if ((addProvider == 'addProvider' && this.sameAsPractice == true) || (editProvider == 'editProvider' && this.sameAsPracticeFacility == true)) {
      if (this.currentPracticeAddressProviders) {
        this.getState(null, this.currentPracticeAddressProviders.state);
        this.providerObject.streetLine1 = this.currentPracticeAddressProviders.street_line_1;
        this.providerObject.streetLine2 = this.currentPracticeAddressProviders.street_line_2;
        this.providerObject.city = this.currentPracticeAddressProviders.city;
        this.providerObject.state = this.currentPracticeAddressProviders.state;
        this.providerObject.zipcode = this.currentPracticeAddressProviders.zip;
      }
    } else if (addProvider == 'addProvider' && this.sameAsPractice == false) {
      this.stateSuccessResult = 'success';
      this.providerObject.streetLine1 = '';
      this.providerObject.streetLine2 = '';
      this.providerObject.city = '';
      this.providerObject.state = '';
      this.providerObject.zipcode = '';
    } else if (editProvider == 'editProvider' && this.sameAsPracticeFacility == false) {
      if (this.providerDetails.address) {
        this.getState(null, this.providerDetails.address.state);
        this.providerObject.streetLine1 = this.providerDetails.address.street_line_1;
        this.providerObject.streetLine2 = this.providerDetails.address.street_line_2;
        this.providerObject.city = this.providerDetails.address.city;
        this.providerObject.state = this.providerDetails.address.state;
        this.providerObject.zipcode = this.providerDetails.address.zip;
      }
    }
  }
  taxonomySearch(searchKey, searchfrom, flag) {
    if (this.popUpfrom == 'editProvider') {
      this.taxonomy_array_item = (searchfrom == 'taxonomy') ? [] : this.taxonomy_array_item;
      this.alt_taxonomy_array_item = (searchfrom == 'alt_taxonomy') ? [] : this.alt_taxonomy_array_item;

      if (flag == '') {
        clearTimeout(this.taxonomy_timer);
        this.taxonomy_timer = setTimeout(() => {
          this.taxonomyAPICall(searchKey, searchfrom);
        }, 1000);
      } else {
        this.taxonomyAPICall(searchKey, searchfrom);
      }

    } else {
      clearTimeout(this.taxonomy_timer);
      this.taxonomy_timer = setTimeout(() => {
        this.practicesService.searchTaxonomy(searchKey).subscribe(result => {
          this.alt_taxonomy_array_item = [];
          this.taxonomy_array_item = [];
          if (result.status == 'success') {
            for (let i in result.results) {
              if (this.taxonomySelectedCode != result.results[i].Code) {
                this.alt_taxonomy_array_item[i] = {
                  id: result.results[i].Code,
                  text: result.results[i].Code
                };
              }
              if (this.altTaxonomySelectedCode != result.results[i].Code) {
                this.taxonomy_array_item[i] = {
                  id: result.results[i].Code,
                  text: result.results[i].Code
                };
              }
            }
          }
        });
      }, 1000);
    }
  }
  taxonomyAPICall(searchKey, searchfrom) {
    this.practicesService.searchTaxonomy(searchKey).subscribe(result => {
      if (result.status == 'success') {
        for (let i in result.results) {
          if (searchfrom == 'taxonomy') {
            if (this.altTaxonomySelectedCode != result.results[i].Code) {
              this.taxonomy_array_item[i] = {
                id: result.results[i].Code,
                text: result.results[i].Code
              };
            }
          } else if (searchfrom == 'alt_taxonomy') {
            if (this.taxonomySelectedCode != result.results[i].Code) {
              this.alt_taxonomy_array_item[i] = {
                id: result.results[i].Code,
                text: result.results[i].Code
              };
            }
          }
        }
      }
    });
  }

  // getState(practiceId, searchKey) {
  //   return new Promise((resolve, reject) => {
  //     this.taskService.listStateNames().subscribe(result => {
  //       if (result.status == 'success') {
  //         this.stateSuccessResult = result.status;
  //         for (let i in result.results) {

  //           this.state_array_item[i] = {
  //             id: result.results[i].state_code,
  //             text: result.results[i].state_code
  //           };
  //         }
  //         resolve();
  //       }
  //     });
  //   });
  // }

  getState(practiceId, searchKey) {
    let filtered_data = [];
    let stateval = this.taskService.stateList;
    filtered_data = this.taskService.filterByValue(stateval, searchKey);

    for (let i in filtered_data) {
      if (parseInt(i) < 5) {
        this.stateSuccessResult = 'success';
        this.state_array_item[i] = {
          id: filtered_data[i].state_code,
          text: filtered_data[i].state_code
        };
      }
    }
  }

  getUser(searchKey) {
    this.practicesService.listPracticeUsers(this.selectedPracticeVal, searchKey, 'any', '', '', '').subscribe(res => {
      if (res.status == 'success') {
        this.practiceUserEmptyResult = res;
        this.user_array_item = [];
        for (let i in res.results) {
          // if(res.results[i].isInternal != undefined && res.results[i].isInternal == true){
          this.user_array_item[i] = {
            id: res.results[i].user_id,
            text: res.results[i].username
          };
          // }
        }
      }
    });
  }
  searchUser(searchKey) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
      this.getUser(searchKey);
    }, 500);
  }
  userSelectedValue(userId) {
    this.userSelectedId = userId;
    this.userSelectedText = this.user_array_item.find(x => x.id == userId).text;
  }
  npiProviderData(npi) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
      this.practicesService.npiProviderDataAPI(npi).subscribe(data => {
        if (data.results && data.results.length > 0) {
          if (data.results && data.results.length > 0) {
            let npi_data = data.results[0];
            this.providerObject.firstName = npi_data.basic.first_name;
            this.providerObject.lastName = npi_data.basic.last_name;
            this.providerObject.prefixName = npi_data.basic.name_prefix;

            this.taxonomy_array_item = [];
            this.alt_taxonomy_array_item = [];
            this.providerObject.taxonomyCode = '';
            this.providerObject.alternativeTaxonomy = '';

            npi_data.taxonomies.forEach(element => {
              if (element.primary == true) {
                this.taxonomy_array_item[0] = {
                  id: element.code,
                  text: element.code
                };
                this.providerObject.taxonomyCode = element.code;
              } else if (element.primary == false) {
                this.alt_taxonomy_array_item[0] = {
                  id: element.code,
                  text: element.code
                };
                this.providerObject.alternativeTaxonomy = element.code;
              }
            });

            let npi_address = npi_data.addresses[0];
            if (npi_address) {
              this.getState(null, npi_address.state);
              this.providerObject.streetLine1 = npi_address.address_1;
              this.providerObject.streetLine2 = npi_address.address_2;
              this.providerObject.city = npi_address.city;
              this.providerObject.state = npi_address.state;
              this.providerObject.zipcode = npi_address.postal_code;
            }
          }
        } else {
          this.providerObject.firstName = '';
          this.providerObject.lastName = '';
          this.providerObject.prefixName = '';
          this.taxonomySearch('', '', 'initial');
          this.providerObject.streetLine1 = '';
          this.providerObject.streetLine2 = '';
          this.providerObject.city = '';
          this.providerObject.state = '';
          this.providerObject.zipcode = '';
        }
      });
    }, 1100);
  }
  stateSelectedValue(stateCode) {
    this.selectedStateCode = stateCode;
  }
  taxonomySelectedValue(taxonomyCode) {
    let existing_alttaxomomy_index = this.alt_taxonomy_array_item.map(function (o) { return o.id; }).indexOf(taxonomyCode);
    if (existing_alttaxomomy_index > -1) {
      delete this.alt_taxonomy_array_item[existing_alttaxomomy_index];
    }
    this.taxonomySelectedCode = taxonomyCode;
    this.providerObject.taxonomyCode = taxonomyCode;
  }
  altTaxonomySelectedValue(taxonomyCode) {
    let existing_taxomomy_index = this.taxonomy_array_item.map(function (o) { return o.id; }).indexOf(taxonomyCode);
    if (existing_taxomomy_index > -1) {
      delete this.taxonomy_array_item[existing_taxomomy_index];
    }
    this.altTaxonomySelectedCode = taxonomyCode;
    this.providerObject.alternativeTaxonomy = taxonomyCode;
  }
  addEditProvider(model: ProviderObject, isValid: boolean, mynform: NgForm, providerIndex) {
    this.isValidProviderForm = isValid;
    if (model.ein) {
      let temp = model.ein.replace(/-/g, '');
      model.ein = temp;
    }
    if (model.zipcode) {
      let tempZip = model.zipcode.split('-');
      model.zipcode = tempZip.join('');
    }
    this.providerData = {
      practice_id: this.selectedPracticeVal,
      entity: model.entity,
      npi: model.npi,
      type: model.type,
      ssn: model.ssn,
      first_name: model.firstName,
      middle_name: model.middleName,
      last_name: model.lastName,
      short_name: model.shortName || '',
      prefix_name: model.prefixName,
      'address': {
        'city': model.city || '',
        'state': model.state || '',
        'zip': model.zipcode || '',
        'street_line_1': model.streetLine1 || '',
        'street_line_2': model.streetLine2 || ''
      },
      enabled: model.enabled,
      taxonomy_code: model.taxonomyCode,
      alt_taxonomy: model.alternativeTaxonomy || '',
      tax_id: model.ein || '',
      organization_name: model.organizationName,
      user_id: model.userId || '',
      provider_type: 'Normal Provider',
      username: model.userId || '',
      external_ids: {
        ext_code: model.extCode,
        ext_id: model.extId,
        ext_name: model.extName
      }
    };

    if (isValid == true) {
      if (this.popUpfrom == 'addProvider') {
        this.practicesService.addProvider(this.providerData, 'providerDetail').subscribe(data => {
          this.contacts = data;
          this.ValidatingNewProvider(this.contacts.status);
          this.validationMessage = this.contacts.message;
          this.validationStatus = this.contacts.status;
          if (this.validationStatus == 'success') {
            // if(this.userSelectedText) {
            //   this.practiceProviders[this.selectedProviderIndex].username = this.userSelectedText;
            // }
            if (this.userSelectedText && (this.userSelectedText.length > 0)) {
              this.getUser(this.userSelectedText);
            } else {
              this.getUser(this.providerData.username);
            }
            // console.log(":::::::::::::::::::::::", this.userSelectedText, this.providerData.username)
            // this.practiceProviders.unshift(this.contacts.doc);
            this.providerData = {
              practice_id: this.selectedPracticeVal,
              entity: this.contacts.doc.entity,
              npi: this.contacts.doc.npi,
              type: this.contacts.doc.type,
              ssn: this.contacts.doc.ssn,
              first_name: this.contacts.doc.first_name,
              middle_name: this.contacts.doc.middle_name,
              last_name: this.contacts.doc.last_name,
              short_name: this.contacts.doc.short_name,
              prefix_name: model.prefixName,
              'address': {
                'city': this.contacts.doc.address.city,
                'state': this.contacts.doc.address.state,
                'zip': this.contacts.doc.address.zip,
                'street_line_1': this.contacts.doc.address.street_line_1,
                'street_line_2': this.contacts.doc.address.street_line_2
              },
              taxonomy_code: this.contacts.doc.taxonomy_code,
              alt_taxonomy: this.contacts.doc.alt_taxonomy,
              tax_id: this.contacts.doc.tax_id,
              organization_name: this.contacts.doc.organization_name,
              user_id: this.contacts.doc.user_id,
              userId: this.userSelectedText || '',
              provider_type: 'Normal Provider',
              _id: this.contacts.doc._id,
              enabled: (this.showInactive == false) ? true : false,
              username: this.userSelectedText,
              external_ids : {
                ext_code : this.contacts.doc.external_ids.ext_code,
                ext_id : this.contacts.doc.external_ids.ext_id,
                ext_name : this.contacts.doc.external_ids.ext_name
              }
            };
            this.practiceProviders.unshift(this.providerData);
            this.paginationCounts = this.paginationCounts + 1;
            if (this.practiceProviders.length == (this.pagelimit + 1)) {
              this.practiceProviders.pop();
            }
            this.sharedService.pushToaster('success', 'Success', 'New provider created successfully.');
            // mynform.resetForm();
            this.modalClose();
          } else {
            if (data.status) {
              // mynform.resetForm();
              // this.providerObject.state = "";
              // this.providerObject.taxonomyCode = "";
              this.sharedService.pushToaster('error', 'Error', data.status);
              this.timer = setInterval(() => {
                this.alreadyExistFlag = false;
                clearInterval(this.timer);
              }, 3000);
            } else {
              // mynform.resetForm();
              // this.providerObject.state = "";
              // this.providerObject.taxonomyCode = "";
              this.providerObject.type = 'Individual';
              this.sharedService.pushToaster('error', 'Error', this.contacts.message);
              this.timer = setInterval(() => {
                this.alreadyExistFlag = false;
                clearInterval(this.timer);
              }, 3000);
            }
          }
        },
          err => this.logError(err),
        );
      }

      if (this.popUpfrom == 'editProvider') {
        this.sameAsPractice == false;
        //this.providerData.external_ids= new Object(); 
        this.providerData.providerId = this.providerDetails._id;
        //this.providerData.external_ids.ext_code = model.extCode;
        //this.providerData.external_ids.ext_id = model.extId;
        //this.providerData.external_ids.ext_name = model.extName;
        if (this.providerData.ssn) {
          this.providerData.ssn = (this.providerData.ssn).replace(/-/gi, '');
        }
        this.practicesService.updateProvider(this.providerData, 'providerDetail').subscribe(data => {
          if (this.userSelectedText) {
            this.practiceProviders[this.selectedProviderIndex].username = this.userSelectedText;
          }
          data = this.removeNullEntries(data);
          this.contacts = data;
          this.ValidatingNewProvider(this.contacts.status);
          this.practiceAccountEditStatus = this.contacts.status;
          this.validationMessage = this.contacts.message;
          if (this.practiceAccountEditStatus == 'success') {
            let obj = this.popUpinfo;
            obj.practice_id = model.practiceId;
            obj.providerId = model.providerId;
            obj.entity = model.entity;
            obj.npi = model.npi;
            obj.type = model.type;
            obj.ssn = model.ssn;
            obj.first_name = model.firstName;
            obj.middle_name = model.middleName;
            obj.last_name = model.lastName;
            obj.short_name = model.shortName;
            obj.title = model.prefixName;
            obj.address.street_line_1 = model.streetLine1;
            obj.address.street_line_2 = model.streetLine2;
            obj.address.city = model.city;
            obj.address.state = model.state;
            obj.address.zip = model.zipcode.toString();
            obj.taxonomy_code = model.taxonomyCode;
            obj.alt_taxonomy = model.alternativeTaxonomy;
            obj.tax_id = model.ein;
            obj.organization_name = model.organizationName;
            obj.user_id = model.userId;
            if (obj.prefixName != '' || obj.prefixName != null || obj.prefixName != undefined) {
              obj.prefixName = model.prefixName;
            }
            obj.external_ids.ext_code = model.extCode;
            obj.external_ids.ext_id = model.extId;
            obj.external_ids.ext_name = model.extName;
            mynform.resetForm();
            this.modalClose();
            this.sharedService.pushToaster('success', 'Success', 'Provider details updated successfully.');
          } else {
            this.sharedService.pushToaster('error', 'Error', this.contacts.message);
          }
        },
          err => this.logError(err)
        );
      }
    }
  }
  ValidatingNewProvider(response) {
    if (response == 'Provider Already Exists') {
      this.successflag = false;
      this.alreadyExistFlag = true;
    } else if (response == 'success') {
      this.alreadyExistFlag = false;
      this.successflag = true;
      this.timer = setInterval(() => {
        this.successflag = false;
        clearInterval(this.timer);
      }, 1000);
    }
  }
  modalClose() {
    this.searchKeyWordFacility = '';
    this.sameAsPractice = false;
    this.sameAsPracticeFacility = false;
    this.maskValidationPro = false;
    this.SharedPopupService.dismiss();
    this.alreadyExistFlag = false;
    this.currentPageV2 = 1;
    this.modelOpen = false;
  }
  removeNullEntries(obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        obj[key] = this.removeNullEntries(obj[key]);
      } else if (obj[key] === undefined || obj[key] === null) {
        obj[key] = '';
      } else {
        obj[key] = obj[key];
      }
    });
    return obj;
  }
  onCancel() {
    this.SharedPopupService.dismiss();
  }
  onConfirm() {
    if (this.confirmPopupFrom == 'providerStatusChange') {
      this.providerStatus(this.selectedPracticeUserObject);
    } else if (this.confirmPopupFrom == 'providerMedicalCodingStatus') {
      this.providerMedicalCodingStatus(this.selectedPracticeUserObject);
    }

    this.SharedPopupService.dismiss();
  }
  confirmPopup(accountConfirmPopup: TemplateRef<any>, status: Boolean, practiceSelectedData, type, index) {
    this.selectedPracticeUserObject = practiceSelectedData;
    // this.parentIndexValue = index;
    this.providerIdex = index;
    if (type == 'providerStatusChange') {
      this.messageInfo = (status == true) ? 'Are you sure want to deactivate this provider?' : 'Are you sure want to Activate this provider?';
    } else if (type == 'providerMedicalCodingStatus') {
      this.messageInfo = (status == true) ? 'Are you sure want to deactivate Medical Coding for this provider?' : 'Are you sure want to Activate Medical Coding for this provider?';
      this.selectedPracticeUserObject.medical_coding = status;
    }
    this.confirmPopupFrom = type;

    this.SharedPopupService.show(accountConfirmPopup, {
      animated: true,
      backdrop: 'static'
    });
  }

  providerStatus(provider) {
    let providerData = {
      'practice_id': provider.practice_id,
      'provider_id': provider._id,
      'enabled': (provider.enabled == true) ? false : true
    };
    // tslint:disable-next-line: no-shadowed-variable
    this.practicesService.providerStatusChange(providerData, 'providerDetails').subscribe(data => {
//console.log('update', data, this.practiceProviders);
      if (data.status == 'success') {
        let result = data.result;
        // this.searchProvider('', 'deactivated');
        this.practiceProviders.splice(this.providerIdex, 1);
        // this.practiceProviders[this.providerIdex].enabled = result.enabled;
        // this.practiceProviders[this.providerIdex].last_modified_date = result.last_modified_date;
        // this.practiceProviders[this.providerIdex].updated_user_id = result.updated_user_id;
        this.sharedService.pushToaster('success', 'Success', 'Provider status updated successfully.');
      } else {
        this.sharedService.pushToaster('danger', 'Update failed', 'Provider status update failed.');
      }
    });
  }

  providerMedicalCodingStatus(provider) {
    let providerData = {
      'practice_id': provider.practice_id,
      'provider_id': provider._id,
      'medical_coding': (provider.medical_coding == true) ? false : true
    };
    // tslint:disable-next-line: no-shadowed-variable
    this.practicesService.providerCodingChange(providerData).subscribe(data => {
      if (data.status == 'success') {
        let result = data.result;
        this.practiceProviders[this.providerIdex].medical_coding = result.medical_coding;
        this.practiceProviders[this.providerIdex].last_modified_date = result.last_modified_date;
        this.practiceProviders[this.providerIdex].updated_user_id = result.updated_user_id;
        this.sharedService.pushToaster('success', 'Success', 'Provider medical coding updated successfully.');
      } else {
        this.sharedService.pushToaster('danger', 'Update failed', 'Provider medical coding update failed.');
      }
    });
  }

  openProviderFacilityMappingModal(template: TemplateRef<any>, from, infoData, index) {
    this.loaderIndicator = true;
    this.popUpfrom = from;
    this.modelOpen = true;
    this.popUpinfo = infoData;
    if (from == 'mapFacilitiesToProvider') {
      this.tmpMappingDataProvider = [];
      this.existingMappedFacilities = [];
      this.practiceFacilitiesData = [];

      // this.practicesService.fetchEnabledFacilitesForProvider(this.popUpinfo.practice_id, this.popUpinfo._id, '', false, 'providerDetails').subscribe(result => {
      //   if (result.status == 'success') {
      //     this.existingMappedFacilities = result.results;
      //   }
      // },
      //   err => 'error',
      //   () => {
      //     this.practicesService.listSearchFacilities(this.popUpinfo.practice_id, '', 'true').subscribe(result => {
      //       if (result.status == 'success') {
      //         this.practiceFacilitiesData = result.results;
      //         this.groupFacilities();
      //         this.defaultSelectionProviderFacilities();
      //       }
      //     });
      //   });
      this.mapFacilitiesToProviderV2();
    }

    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }

  mapFacilitiesToProviderV2(){
    this.loaderIndicator = true;
    this.practicesService.mapFacilitiesV2(this.popUpinfo._id, this.searchKeyWordFacility, this.pagelimit , this.page).subscribe(result => {
      if (result.status == 'success') {
        this.loaderIndicator = false;
        this.existingMappedFacilities = result.results;
        this.practiceFacilitiesData = result.results;
        this.paginationCountV2 = result['totalCount'];
        this.groupFacilities();
        this.defaultSelectionProviderFacilities();
        this.existRecordFound('mapFacilitiesToProvider');
      }
    });
  }

  groupFacilities() {
    this.groupedFacilities = [];
    let groupedFacilities: any = {};
    this.practiceFacilitiesData.forEach(element => {
      let c = this.existingMappedFacilities.find(x => x._id == element._id);
      if (!groupedFacilities[element.place_of_service]) {
        groupedFacilities[element.place_of_service] = {
          place_of_service_name: element.place_of_service_name,
          facilities: []
        };
      }
      if (c == undefined || c.enabled == true) {
        element.enabled = true;
      } else {
        element.enabled = false;
      }
      groupedFacilities[element.place_of_service].facilities.push(element);
    });
    let temp = [];
    for (let tmp in groupedFacilities) {

      temp.push(groupedFacilities[tmp]);

    }
    this.groupedFacilities = temp;
  }
  defaultSelectionProviderFacilities() {
    this.practiceFacilitiesData.forEach(element => {
      let flagResult = this.existingMappedFacilities.find(tmp => element._id == tmp._id);
      if (flagResult == undefined || flagResult.enabled == true) {
        element.enabled = true;
      } else if (flagResult.enabled == false) {
        element.enabled = false;
      }
    });
  }

  searchTaskKeyFacilities(facilities = []) {
    let find = facilities.filter(facility => {
      if ((facility.short_name && facility.short_name.toLowerCase().includes(this.searchKeyWordFacility.toLowerCase())) ||
        (facility.name && facility.name.toLowerCase().includes(this.searchKeyWordFacility.toLowerCase())) ||
        (facility.npi && facility.npi.toString().toLowerCase().includes(this.searchKeyWordFacility.toLowerCase()))) {
        return facility;
      }
    });
    return (find.length > 0) ? true : false;
  }

  existRecordFound(check: string) {
    if (this.searchKeyWordFacility != '') {
      if (check == 'mapFacilitiesToProvider') {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(x => {
        this.practicesService.mapFacilitiesV2(this.popUpinfo._id, this.searchKeyWordFacility, this.pagelimit , this.page).subscribe(result => {
          if (result.status == 'success') {
            this.existingMappedFacilities = result.results;
            this.practiceFacilitiesData = result.results;
            this.paginationCountV2 = result['totalCount'];
            this.groupFacilities();
            this.defaultSelectionProviderFacilities();
          }
        });
      },500);     
    }
        // let find = this.practiceFacilitiesData.filter(facility => {
        //   if ((facility.short_name && facility.short_name.toLowerCase().includes(this.searchKeyWordFacility.toLowerCase())) ||
        //     (facility.name && facility.name.toLowerCase().includes(this.searchKeyWordFacility.toLowerCase())) ||
        //     (facility.npi && facility.npi.toString().toLowerCase().includes(this.searchKeyWordFacility.toLowerCase()))) {
        //     return facility;
        //   }
        // });
        // this.flagIndicator = (find.length > 0) ? false : true;      
  }
}
  mapNewFacilityToProvider(x: String) {

    if (x == 'mapFacilitiesToProvider') {
      let mappedData = JSON.parse(JSON.stringify(this.groupedFacilities));
      let finalmappedData = [];
      mappedData.forEach(element => {
        element.facilities.forEach(elem => {
          let selectedFacility = {
            'practice_id': this.popUpinfo.practice_id,
            'provider_id': this.popUpinfo._id,
            'facility_id': elem._id,
            'enabled': elem.enabled
          };
          finalmappedData.push(selectedFacility);
        });
      });
      this.practicesService.bulkFacilitiesMapToProvider(this.popUpinfo.practice_id, this.popUpinfo._id, finalmappedData).subscribe(_data => {
        this.modalClose();
        this.timeout = setTimeout(_x => {
          this.sharedService.pushToaster('success', 'Success', 'Facilities mapped to provider successfully.');
        }, 1000);
      }, _err => {
        this.modalClose();
        this.sharedService.pushToaster('error', 'Failure', 'Bulk facility mapping failed.');
      });
    }
  }

  mapFacilitytoProvider(facility) {
    if(facility.enabled) { 
      this.practicesService.addFacilityToProvider(this.popUpinfo._id, facility._id).subscribe(data => {
        if(data.status == "success") {
        this.sharedService.pushToaster('success', 'Success', 'Facility to Providers mapped successfully.');
      } else {
        this.sharedService.pushToaster('error','Error', 'Providers mapping failed');
      }
      });
    } else {
      this.practicesService.removeFacilityToProvider(this.popUpinfo._id,facility._id).subscribe(data => {
          if(data.status == "success") {
          this.sharedService.pushToaster('success', 'Success', 'Facility removed successfully.')
          }
        });
      }
  }
  /* End - Provider-Facility mapping */
}
