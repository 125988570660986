import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToasterService, ToasterConfig } from 'angular2-toaster/angular2-toaster';
import { ResourcesService } from '../../../../_services/settings/resources.service';
import { SharedService } from '../../../../_services/shared.service';
import { SharedPopupService } from '../../../../_services/popup.service';
import constants from '../../../../constants';

class Resources {
  _id: any;
  resource_type: String;
  server: String;
  resource_path: String;
  resource_description: String;
  resource_created_date: String;
 
}

@Component({
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
  providers: []
})

export class ResourcesComponent implements OnInit, OnDestroy {
  private toasterService: ToasterService;
  public resources: Resources;

  resourceList: any;
  permissionList: any;
  selectedPermissionList: any;
  timeout: any;
  allPermissionLists: any;
  typeOfResources: Array<{}>;
  selectedPermission: Array<{}>;
  showAll: Array<{}>;
  popupTitle: string;
  popupMode: string;
  searchData: string;
  addStatusMessage: string;
  selectedRes: string;
  selectedColor: string;
  messageInfo: string;
  currResourceId: string;
  permissionId: string;
  filterTxt: string;
  currIndex: number;
  showInactive: boolean;
  activeFlag: boolean;
  selectedEnable: boolean;
  selectedTab: string;
  activeTab: any;
  docLimit: Array<{}>;;
  loaderIndicator:boolean;
  tabloaderIndicator:boolean;
  constants:any;
  constructor(private resourcesService: ResourcesService, public SharedPopupService: SharedPopupService, public bsModalService: BsModalService, toasterService: ToasterService, private sharedService: SharedService) {
    this.toasterService = toasterService;
    this.permissionList = [];
    this.selectedPermissionList = [];
    this.searchData = "",
      this.filterTxt = "",
      this.selectedRes = "",
      this.resources = {
        _id: "",
        resource_type: "",
        server: "",
        resource_path: "",
        resource_description: "",
        resource_created_date: ""
      }
    this.selectedColor = "";
    this.showInactive = false;
    this.activeFlag = true;
    this.selectedPermission = [];
    this.activeTab = 'about';
    this.showAll = [];
    this.docLimit = [];
    this.currIndex = 0;
    this.resourceList = [];
    this.loaderIndicator= true;
    this.tabloaderIndicator=true;
    this.constants=constants;
  }

  ngOnInit() {
    this.getResourcesList("", this.showInactive);
    this.getPermissionList('');
  }

  /* resources get/search/validate/add/edit/active/deactive/show inactive/select methods*/

  getResourcesList(searchValue: string, status: boolean) {
    this.loaderIndicator=true;
    this.permissionList = [];
    this.resourcesService.getResources(searchValue, status).subscribe((res: any) => {
      if (res.status == "success") {
        this.resourceList = res.results;
        this.resourceList.forEach((ele: any, i: number) => {
          this.resourceList[i]["buttonType"] = '';
        });
      } else if (res.status == "failure") {
        this.resourceList = [];
      }
      this.loaderIndicator=false;
    });
  }

  searchResources(searchValue: string) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
      this.getResourcesList(searchValue, this.showInactive);
    }, 400);
  }

  validateResources(model: Resources, isValid: boolean) {
    this.resources = {
      _id: model._id,
      resource_type: model.resource_type,
      server: model.server,
      resource_path: model.resource_path,
      resource_description: model.resource_description,
      resource_created_date: this.resources.resource_created_date
    }
    "addResources" == this.popupMode ? this.addNewResource(this.resources, isValid) : "editResources" == this.popupMode && this.editResource(this.resources, isValid);
  }

  addNewResource(resources: any, isValid: boolean) {
    if (isValid) {
      this.resourcesService.addResource(resources).subscribe((response: any) => {
        let res = response;
        let lastInserted = response.data;
        if (res.status == "success") {
          this.addStatusMessage = "success";
          this.validationMessage();
          setTimeout(() => {
            if ("success" == res.status) {
              this.addStatusMessage = "";
              this.resourceList.unshift(lastInserted);
              this.SharedPopupService.dismiss();
            }
          }, 2000);
        } else if (response.status == "failed") {
          if (response.message == "doc already exist") {
            this.addStatusMessage = "permissionFailed";
            this.validationMessage();
          }
        }
      });
    }
  }

  editResource(resources: any, isValid: boolean) {
    if (isValid) {
      this.resourcesService.editResource(resources, this.selectedRes).subscribe((response: any) => {
        let res = response;
        let lastUpdated = response.data;
        let setUpdated = lastUpdated;
        setUpdated._id = this.selectedRes;
        this.showInactive ? setUpdated.enable = false : setUpdated.enable = true;
        if (res.status == "success") {
          this.addStatusMessage = "update";
          setTimeout(() => {
            if ("success" == res.status) {
              this.addStatusMessage = "";
              this.resourceList[this.currIndex] = setUpdated;
              this.SharedPopupService.dismiss();
              this.selectedColor = "";
            }
          }, 2000);
        }
        this.validationMessage();
      });
    }
  }

  activeDeactiveRes() {
    let selectedEnable = this.selectedEnable ? false : true;
    this.resourcesService.activeResource(this.selectedRes, selectedEnable).subscribe((response: any) => {
      if (response.status = "success") {
        this.getResourcesList("", this.showInactive);
        this.showInactive ? this.addStatusMessage = "resourcesActive" : this.addStatusMessage = "resourcesDeactive";
        this.validationMessage();
        this.SharedPopupService.dismiss();
      }
    });
  }

  activeStatus() {
    this.getResourcesList(this.searchData, this.showInactive);
  }

  selectResources(resId: string, currId: number) {
    this.currResourceId = resId;
    this.currIndex = currId;
  }


  /* permission get/search/view/add/selectAll/remove/removeAll methods*/

  getPermissionList(searchKey: string) {
    //this.allPermissionLists=[];
    this.resourcesService.getPermissionList(searchKey).subscribe((response: any) => {
      let res = response;
      if (res.status == "success") {
        this.allPermissionLists = res.results;
      }
      this.mappedPermission();
    });
  }

  currentSelectedPermissions() {
    this.allPermissionLists.forEach((element: any) => {
      this.selectedPermissionList[this.currIndex].forEach((ele: any) => {
        if (element._id == ele._id) {
          element.enable = true;
        }
      });
    });
  }

  filterPermission(searchKey: string) {
    this.filterTxt = searchKey;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
      this.getPermissionList(searchKey);
    }, 1000);
  }

  viewpermission(resourceId: string, index: number) {
    this.resourceList[index].tabloaderIndicator=true;
    this.docLimit[index] = 10;
    this.showAll[index] = "Show All";
    this.resourcesService.viewpermission(resourceId).subscribe((res: any) => {
      if (res.status == "success") {

        let permissionList = res.results;
        this.permissionList[index] = permissionList;
        this.selectedPermissionList[index] = [...permissionList];

      } else if (res.status == "failure") {
        this.permissionList[index] = [];
        this.selectedPermissionList[index] = [];
      }
      this.resourceList[index].tabloaderIndicator=false;
    });
  }

  addPermissionList() {
    let updatedPermissions = [];
    this.selectedPermissionList[this.currIndex].forEach((ele: any) => {
      updatedPermissions.push({ permissionId: ele._id, isChecked: ele.status });
    });
    this.resourcesService.addPermissionList(this.selectedRes, updatedPermissions).subscribe((response: any) => {
      if (response.status = "success") {
        this.permissionList[this.currIndex] = [...this.selectedPermissionList[this.currIndex]];
        this.addStatusMessage = "permissionSuccess";
        this.validationMessage();
        setTimeout(() => {
          this.SharedPopupService.dismiss();
        }, 2000);
      }
    });
  }

  selectPermission(isChecked: boolean, permission: any, index: number) {
    let pushSelected = {
      permission_description: permission.permission_description,
      permission_name: permission.permission_name,
      permission_status: 1,
      status: true,
      _id: permission._id
    }
    
    if (isChecked) {

      this.resourcesService.addPermission(this.selectedRes, permission._id).subscribe((response: any) => {
        if (response.status == "success") {
          this.permissionList[this.currIndex].push(pushSelected);
          this.selectedPermissionList[this.currIndex] = this.permissionList[this.currIndex];
          this.addStatusMessage = "addAnPermission";
          this.validationMessage();
        }
      });

    } else {
      this.selectedPermissionList[this.currIndex] = this.selectedPermissionList[this.currIndex].filter((ele: any) => {
        return ele._id != pushSelected._id;
      });

      this.resourcesService.deletePermission(this.selectedRes, permission._id).subscribe((response: any) => {
        if (response.status == "success") {
          this.permissionList[this.currIndex] = this.permissionList[this.currIndex].filter((ele: any) => {
            return ele._id != permission._id;
          });
          this.selectedPermissionList[this.currIndex] = [...this.permissionList[this.currIndex]];
          this.addStatusMessage = "removeAnPermission";
          this.validationMessage();
        }
      });

    }
  }

  removeMappedResources(index: number,permission:any) {
    let permissionId = permission._id;
    this.selectedPermissionList[this.currIndex].splice(index, 1);
    this.mappedPermission();
    this.resourcesService.deletePermission(this.selectedRes, permissionId).subscribe((response: any) => {
      if (response.status == "success") {
        this.permissionList[this.currIndex] = this.permissionList[this.currIndex].filter((ele: any) => {
          return ele._id != permissionId;
        });
        this.selectedPermissionList[this.currIndex] = [...this.permissionList[this.currIndex]];
        this.addStatusMessage = "removeAnPermission";
        this.validationMessage();
      }
    });
  }

  removeAnPermission() {
    this.resourcesService.deleteAnPermission(this.currResourceId, this.permissionId).subscribe((response: any) => {
      if (response.status == "success") {
        this.permissionList[this.currIndex] = this.permissionList[this.currIndex].filter((ele: any) => {
          return ele._id != this.permissionId;
        });
        this.selectedPermissionList[this.currIndex] = [...this.permissionList[this.currIndex]];
        this.SharedPopupService.dismiss();
        this.addStatusMessage = "removeAnPermission";
        this.validationMessage();
      }
    });
  }

  removeAllPermission() {
    this.resourcesService.deleteAllPermission(this.currResourceId).subscribe((response: any) => {
      if (response.status == "success") {
        this.permissionList[this.currIndex] = [];
        this.selectedPermissionList[this.currIndex] = [];
        this.SharedPopupService.dismiss();
        this.addStatusMessage = "removeAllPermission";
        this.validationMessage();
      }
    });
  }


  mappedPermission() {
    this.allPermissionLists.forEach((ele: any) => { ele["enable"] = false; });
    let selectedPermission = this.selectedPermissionList[this.currIndex];
    let allPermissionLists = this.allPermissionLists;
    if (selectedPermission && selectedPermission.length > 0) {
      selectedPermission.forEach((sEl: any) => allPermissionLists.forEach((aEl: any) => {
        if (sEl._id === aEl._id) {
          aEl.enable = true;
        }
      }));
    }
  }

  /* popup and tab handler methods */

  openModal(template: TemplateRef<any>, triggeredFrom: string, selectedRes?: any, index?: number, perIndex?: number) {
    this.selectedColor = "";
    this.filterTxt = '';
    this.filterPermission('');
    this.popupMode = triggeredFrom;
    this.selectedRes = selectedRes != null ? selectedRes._id : "add resources";
    let popupProperties = { animated: true, keyboard: false };
    popupProperties['backdrop'] = 'static';
    this.currIndex = index;
    switch (this.popupMode) {
      case 'addResources':
        this.popupTitle = "Add New Resources";
        this.resources = {
          _id: "",
          resource_type: "",
          server: "",
          resource_path: "",
          resource_description: "",
          resource_created_date: ""
        }
        break;
      case 'editResources':
        this.popupTitle = "Edit Resources";
        this.resources = {
          _id: selectedRes._id,
          resource_type: selectedRes.resource_type,
          server: selectedRes.server,
          resource_path: selectedRes.resource_path,
          resource_description: selectedRes.resource_description,
          resource_created_date: selectedRes.resource_created_date
        }
        this.selectedColor = selectedRes.resource_type;
        break;
      case 'resourceStatus':
        this.messageInfo = selectedRes.enable ? "deactivate resources" : "activate resources";
        this.selectedEnable = selectedRes.enable;
        break;
      case 'addPermission':
        this.popupTitle = "Add Permissions";
        popupProperties['class'] = 'modal-lg';
        this.mappedPermission();
        break;
      case "removeAnPermission":
        this.messageInfo = "remove permission";
        this.permissionId = this.permissionList[index][perIndex]._id;
        break;
      case "removeAllPermission":
        this.messageInfo = "remove all permission";
        break;
    }
    this.SharedPopupService.show(template, popupProperties);
  }

  confirmPopup() {
    "resourceStatus" == this.popupMode ? this.activeDeactiveRes() : "removeAnPermission" == this.popupMode ? this.removeAnPermission() : "removeAllPermission" == this.popupMode && this.removeAllPermission();
  }

  validationMessage() {
    "success" == this.addStatusMessage ? this.sharedService.pushToaster("success", "Added", "New resources added successfully.") :
      "update" == this.addStatusMessage ? this.sharedService.pushToaster("success", "Updated", "Resources updated successfully.") :
        "permissionSuccess" == this.addStatusMessage ? this.sharedService.pushToaster("success", "Added", "Permission added successfully.") :
          "resourcesDeactive" == this.addStatusMessage ? this.sharedService.pushToaster("success", "Deactivated", "Resources deactivated successfully.") :
            "resourcesActive" == this.addStatusMessage ? this.sharedService.pushToaster("success", "Activated", "Resources activated successfully.") :
              "removeAnPermission" == this.addStatusMessage ? this.sharedService.pushToaster("success", "Removed", "Permission removed successfully.") :
               "addAnPermission" == this.addStatusMessage ? this.sharedService.pushToaster("success", "Added", "Permission added successfully.") :
                "removeAllPermission" == this.addStatusMessage ? this.sharedService.pushToaster("success", "Removed", "All permission removed successfully.") :
                  "permissionFailed" == this.addStatusMessage ? this.sharedService.pushToaster("error", "Failure", "Resources already exist.") : '';
  }

  closePopup() {
    this.SharedPopupService.dismiss();
    this.selectedColor = "";
    if (this.permissionList.length > 0) {
      this.selectedPermissionList[this.currIndex] = [...this.permissionList[this.currIndex]];
    }
    this.mappedPermission();
  }

  tabChange(selectedTab: any, resource: any, index: number) {
   // this.resourceList[index]=[];
    
    if (selectedTab.target.tagName == 'A' || selectedTab.target.tagName == 'SPAN') {
//console.log(selectedTab.target.innerText);
      this.currResourceId = resource._id;
      this.currIndex = index;
      if ("Permissions" == selectedTab.target.innerText) {
        //this.resourceList[index].tabloaderIndicator=false;
        this.selectedTab = selectedTab.target.innerText;
        resource["buttonType"] = this.selectedTab;
        this.resourceList[index]["removeAllPer"] = true;
        
        this.viewpermission(resource._id, index);
      

      }
    }
  }

  typeOnChange(value: string) {
    this.selectedColor = value;
  }

  showAllLess(index: number) {
    this.currIndex = index;
    if (this.showAll[this.currIndex] == "Show All") {
      this.docLimit[this.currIndex] = this.permissionList[this.currIndex].length;
      this.showAll[this.currIndex] = "Show Less";
    } else {
      this.docLimit[this.currIndex] = 10;
      this.showAll[this.currIndex] = "Show All";
    }
  }
  ngOnDestroy(): void {
    this.SharedPopupService.dismiss();
  }
}
