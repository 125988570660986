import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { AuthenticationService } from '../../../../_services/auth/authentication.service';
import { DataHelperService } from '../../../../_services/auth/data-helper.service';
import { BatchesService } from '../../../../_services/batches/batches.service';
import * as myGlobals from '../../../../globals';
import { BulkCodeService } from '../../../../_services/bulk-code/bulk-code.service';



const SIZE_LIMIT = myGlobals.TaskAttachment_SIZE;
const URL = myGlobals.BulkCodeUpload_URL;

@Component({
  selector: 'app-bulk-code-uploades',
  templateUrl: './bulk-code-uploades.component.html',
  styleUrls: ['./bulk-code-uploades.component.css']
})
export class BulkCodeUploadesComponent implements OnInit {
  public uploadcancel: boolean;
  public file_name: string;
  public upload_screen: string;
  public fileuploadFailure: boolean;
  public batch_error_msg: string;
  public hasBaseDropZoneOver = false;
  public batchname: any;
  public batch_progress: number;
  public fileuploadSuccess: boolean;
  temp:any;
  show_error_msg: boolean;
  country: string;


  @ViewChild('buttonBrowse', { static: false }) buttonBrowse: ElementRef;
  @ViewChild('downurl', { static: false }) public downurl: ElementRef;

  public uploader: FileUploader = new FileUploader({
    url: URL,
    queueLimit: 1
  });

  constructor(public batchesService: BatchesService,
    // private taskTypesService: TaskTypesService,
    // private socket: SocketConversationService,
    // public dataHelperService: DataHelperService,
    // private socketVisitService: SocketVisitService,
    // public taskService: TaskService,
    private BulkCodeservice:BulkCodeService,
    private authenticationService: AuthenticationService
  ) {
    this.show_error_msg = false;
    this.uploader.authToken = this.authenticationService.getToken();
  }

  ngOnInit() {
    this.upload_screen = 'browse';
    this.country =  localStorage.getItem('Region')
  }

  public fileOverBase(e: any): void {
    let tmp = '';
    let AllowedForamts = ['xlsx', 'xls'];
    this.uploader.onBuildItemForm = (item, form) => {
      form.append('size', item.file.size);
      tmp = item.file.name;
      form.append('name', this.batchname || item.file.name);
      this.file_name = tmp;
      // tslint:disable-next-line: no-shadowed-variable
      let lastdot = tmp.lastIndexOf('.');
      tmp = tmp.substr(lastdot + 1);
      form.append('type', tmp);
      if(this.country == 'UAE'){
        form.append('version', localStorage.getItem('UaeVersion'));
      }
    };
    let file_name = '';
    this.uploadcancel = true;
    this.hasBaseDropZoneOver = e;

    if ((e.type == 'change') || (!e)) {
      for (let item of this.uploader.queue) {
        tmp = item.file.name;
        this.file_name = tmp;
        // tslint:disable-next-line: no-var-keyword
        var lastdot = tmp.lastIndexOf('.');
        tmp = tmp.substr(lastdot + 1);
        tmp = tmp.toLowerCase();
        this.fileuploadFailure = true;

        if (AllowedForamts.indexOf(tmp) != -1) {
          this.batch_error_msg = '';
          if (item.file.size >= SIZE_LIMIT) {
            this.batch_error_msg = this.batch_error_msg + 'File is too big to upload';
            this.uploader.clearQueue();
            this.upload_screen = 'error';
          } else {
            this.fileuploadFailure = false;
            this.batch_error_msg = '';
            this.upload_screen = 'title';
            this.file_name = this.file_name.substr(0, lastdot);
            this.batchname = this.file_name;
          }
        } else {
          this.batch_error_msg = 'File format not supported';
          this.upload_screen = 'error';
          this.uploader.clearQueue();
        }
      }
      this.uploader.onProgressItem = (percent: any) => {
        this.batch_progress = percent;
        this.upload_screen = 'progress';
      };

      this.uploader.onCancelItem = (item: any, response: any, status: any, headers: any) => {
        this.uploader.clearQueue();
        this.upload_screen = 'browse';
        this.clearInput();
        this.uploadcancel = false;
      };
      this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        if (!this.uploadcancel) {
          this.clearInput();
          this.uploader.clearQueue();
          return false;
        }
        let tmp_response = JSON.parse(response);

        if (tmp_response.status == 'error' || tmp_response.status == undefined) {

          this.fileuploadFailure = true;
          this.uploader.clearQueue();

          setTimeout(() => {
            this.fileuploadFailure = false;
            this.upload_screen = 'error';
            this.batch_error_msg = tmp_response.message;
            return false;
          }, 1000);
        }

        if (tmp_response.status == 'success' && this.uploadcancel) {
          this.fileuploadSuccess = true;
          setTimeout(() => {
            this.fileuploadSuccess = false;
            this.upload_screen = 'uploaded';
          }, 500);
          this.file_name = this.file_name.substr(0, lastdot);
          this.batchname = this.file_name;
        }
        this.uploadcancel = true;
      };
    }
  }


  updateBrowse() {
    let el: HTMLElement = this.buttonBrowse.nativeElement as HTMLElement;
    el.click();
  }

  clearInput() {
    this.uploadcancel = false;
  }

  saveattachment() {
    let title = this.batchname;
    for (let item of this.uploader.queue) {
      item.url = item.url;
      item.file._createFromObject = title;
    }
    this.uploader.uploadAll();
  }

  newbatch() {
    this.batch_progress = -1;
    this.batchname = '';
    this.fileuploadSuccess = false;
    this.fileuploadFailure = false;
    this.batch_error_msg = '';
    this.uploader.clearQueue();
    this.upload_screen = 'browse';
  }
  downloadTemplate(){
    this.BulkCodeservice.downloadTemplate().subscribe(data=>{
      let blob = data;
      let url = window.URL.createObjectURL(blob);
      let el: HTMLElement = this.downurl.nativeElement as HTMLElement;
      el.setAttribute('href', url);
      el.setAttribute('download', 'codeTemplate.xlsx');
      el.click();
      //window.open(url)
    })
  }
}
