import { Component, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TaskService } from '../../_services/task/task.service';


@Component({
  templateUrl: 'setting.component.html'
})
export class SettingComponent {

  constructor(public taskService: TaskService) {
   
  }


}
