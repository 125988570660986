import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { BulkCodeService } from '../../_services/bulk-code/bulk-code.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../../_services/shared.service';
import * as _ from 'lodash';
import { DataHelperService } from '../../_services/auth/data-helper.service';
import { SharedPopupService } from '../../_services/popup.service';
import constants from '../../constants';
import { HeaderService } from '../../_services/header/header.service';

@Component({
  selector: 'app-bulk-uploads-view',
  templateUrl: './bulk-uploads-view.component.html',
  styleUrls: ['./bulk-uploads-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: []
})
export class BulkUploadsViewComponent implements OnInit, OnDestroy {
  innerWidth: number;
  showFilter: boolean;
  mobileview: boolean;
  mobileviewTaskcontent: boolean;
  mobileViewLeftsidePanel: boolean;
  searchValue: string;
  totalCodeCount: number;
  page: number;
  pageskip: number;
  defaultLimit: number;
  currentPage: number;
  allPaginationDefaultLimit: number;
  // procedure code add
  procedureCodeAddTotalCount: number;
  procedureCodeAddPage: number;
  procedureCodeAddCurrentPage: number;
  procedureCodeAddPageskip: number;

  // procedure code update
  procedureCodeUpdateTotalCount: number;
  procedureCodeUpdatePage: number;
  procedureCodeUpdateCurrentPage: number;
  procedureCodeUpdatePageskip: number;

  // diagnosis code add
  diagnosisCodeAddTotalCount: number;
  diagnosisCodeAddPage: number;
  diagnosisCodeAddCurrentPage: number;
  diagnosisCodeAddPageskip: number;

  // diagnosis code update
  diagnosisCodeUpdateTotalCount: number;
  diagnosisCodeUpdatePage: number;
  diagnosisCodeUpdateCurrentPage: number;
  diagnosisCodeUpdatePageskip: number;

  // procedure modifier add
  procedureModifierAddPageskip: number;
  procedureModifierAddPage: number;
  procedureModifierAddTotalCount: number;
  procedureModifierAddCurrentPage: number;

  // procedure modifier update
  procedureModifierUpdatePageskip: number;
  procedureModifierUpdateTotalCount: number;
  procedureModifierUpdatePage: number;
  procedureModifierUpdateCurrentPage: number;


  timeout = null;
  bulkCodes: any;
  diagnosisCodesAdd: any;
  diagnosisCodesUpdate: any;
  procedureCodesAdd: any;
  procedureCodesUpdate: any;
  procedureModifierAdd: any;
  procedureModifierUpdate: any;
  selected_bulkcode_id: string;
  codesViewFlag: boolean;
  selectedListData: any;
  showList: boolean;
  approvalpermission: any;
  constants:any;
  country:any;
  viewCodeType: string;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    this.screenValues();
  }
  constructor(private bulkCodeService: BulkCodeService, public SharedPopupService: SharedPopupService, public modalService: BsModalService, private DataHelper: DataHelperService, private sharedService: SharedService,private headerService: HeaderService) {
    this.searchBulkCode = _.debounce(this.searchBulkCode, 200);
    this.mobileview = false;
    this.showFilter = false;
    this.mobileViewLeftsidePanel = true;
    this.searchValue = '';
    this.totalCodeCount = 0;
    this.defaultLimit = 10;
    this.page = 0;
    this.pageskip = 0;
    this.allPaginationDefaultLimit = 200;
    // start pagination of procedure add
    this.procedureCodeAddTotalCount = 0;
    this.procedureCodeAddPage = 0;
    this.procedureCodeAddCurrentPage = 1;
    this.procedureCodeAddPageskip = 0;

    // start pagination of procedure update
    this.procedureCodeUpdateTotalCount = 0;
    this.procedureCodeUpdatePage = 0;
    this.procedureCodeUpdateCurrentPage = 1;
    this.procedureCodeUpdatePageskip = 0;

    // start pagination of diagnosis add
    this.diagnosisCodeAddTotalCount = 0;
    this.diagnosisCodeAddPage = 0;
    this.diagnosisCodeAddCurrentPage = 1;
    this.diagnosisCodeAddPageskip = 0;

    // start pagination of diagnosis update
    this.diagnosisCodeUpdateTotalCount = 0;
    this.diagnosisCodeUpdatePage = 0;
    this.diagnosisCodeUpdateCurrentPage = 1;
    this.diagnosisCodeUpdatePageskip = 0;

    // start pagination for Modifier add
    this.procedureModifierAddPage = 0;
    this.procedureModifierAddPageskip = 0;
    this.procedureModifierAddTotalCount = 0;
    this.procedureModifierAddCurrentPage = 1;

    // start pagination for Modifier update
    this.procedureModifierUpdatePageskip = 0;
    this.procedureModifierUpdatePage = 0;
    this.procedureModifierUpdateTotalCount = 0;
    this.procedureModifierUpdateCurrentPage = 1;

    this.innerWidth = window.innerWidth;
    this.screenValues();
    this.showList = true;
    // this.bulkCodes = [];
    this.searchBulkCode();
    this.procedureCodesAdd = [];
    this.procedureCodesUpdate = [];
    this.diagnosisCodesAdd = [];
    this.diagnosisCodesUpdate = [];

    this.procedureModifierAdd = [];
    this.procedureModifierUpdate = [];
    this.selected_bulkcode_id = '';
    this.codesViewFlag = true;
    this.selectedListData = [];
    let permissionList = this.DataHelper.getItem('userPermissions');
    this.approvalpermission = permissionList.indexOf('code set approver');
    this.viewCodeType = '';
    this.constants=constants;
    this.country = localStorage.getItem('Region');
    if(this.country=="UAE"){
      this.headerService.versionEmitted$.subscribe(data => {
        this.bulkCodesData();
      });
    }
  }

  ngOnInit(): void {
  }

  searchBulkCode() {
    this.page = 0;
    this.currentPage = 1;
    this.bulkCodesData();
  }

  bulkCodesData() {
    this.bulkCodeService.searchBulkCode(this.searchValue.trim(), this.page, this.defaultLimit).subscribe(data => {
      if (data.status == 'success') {
        this.bulkCodes = data.totalData;
        this.totalCodeCount = data.totalCount;
        this.pageskip = this.page;
      }
    },
      err => 'error',
      () => {
        if (this.bulkCodes && this.bulkCodes.length > 0) {
          this.viewBulkcode(this.bulkCodes[0]._id);
        }
      }
    );
  }

  viewBulkcode(bulkCode_id, from = null, page = this.procedureCodeAddPage, limit = this.allPaginationDefaultLimit, action = '') {
    if ((this.selected_bulkcode_id != bulkCode_id) || (from == 'pagination')) {
      if (from != 'pagination') {
        this.resetAllPagination();
      }
      this.selected_bulkcode_id = bulkCode_id;
      this.bulkCodeService.selectedBulkCode(bulkCode_id, this.viewCodeType, page, limit, action).subscribe(data => {
        if (data.status == 'success') {
          this.selectedListData = this.bulkCodes.filter(code => code._id == bulkCode_id)[0];
          if (this.viewCodeType == '' && action == '') {
            // Procedure code data and court
            this.procedureCodesAdd = data.procedure_code_add;
            this.procedureCodesUpdate = data.procedure_code_update;
            this.procedureCodeAddTotalCount = data.procedure_code_count_add;
            this.procedureCodeUpdateTotalCount = data.procedure_code_count_update;

            // Diagnosis code data and court
            this.diagnosisCodesAdd = data.diagnosis_code_add;
            this.diagnosisCodesUpdate = data.diagnosis_code_update;
            this.diagnosisCodeAddTotalCount = data.diagnosis_code_count_add;
            this.diagnosisCodeUpdateTotalCount = data.diagnosis_code_count_update;

            // Procedure Modifier data and count
            this.procedureModifierAdd = data.procedure_modifiers_add;
            this.procedureModifierAddTotalCount = data.procedure_modifiers_count_add;
            this.procedureModifierUpdate = data.procedure_modifiers_update;
            this.procedureModifierUpdateTotalCount = data.procedure_modifiers_count_update;
          }
          if (this.viewCodeType == 'procedureCode') {
            if (action == 'ADD') {
              this.procedureCodesAdd = data.procedure_code_add;
              this.procedureCodeAddPageskip = this.procedureCodeAddPage;
            }
            if (action == 'UPDATE') {
              this.procedureCodesUpdate = data.procedure_code_update;
              this.procedureCodeUpdatePageskip = this.procedureCodeUpdatePage;
            }
          }

          if (this.viewCodeType == 'diagnosisCode') {
            if (action == 'ADD') {
              this.diagnosisCodesAdd = data.diagnosis_code_add;
              this.diagnosisCodeAddPageskip = this.diagnosisCodeAddPage;
            }
            if (action == 'UPDATE') {
              this.diagnosisCodesUpdate = data.diagnosis_code_update;
              this.diagnosisCodeUpdatePageskip = this.diagnosisCodeUpdatePage;
            }
          }

          if (this.viewCodeType == 'procedureModifiers') {
            if (action == 'ADD') {
              this.procedureModifierAdd = data.procedure_modifiers_add;
              this.procedureModifierAddPageskip = this.procedureModifierAddPage;
            }
            if (action == 'UPDATE') {
              this.procedureModifierUpdate = data.procedure_modifiers_update;
              this.procedureModifierUpdatePageskip = this.procedureModifierUpdatePage;
            }
          }

          if (this.codesViewFlag) {
            this.viewCodeType = 'procedureCode';
          } else if (this.codesViewFlag) {
            this.viewCodeType = 'diagnosisCode';
          } else {
            this.viewCodeType = 'procedureModifiers';
          }
        }
      });
    }
  }

  bulkCodeSelected() {
    this.mobileviewTaskcontent = true;
    if (this.innerWidth < 767) {
      this.showList = false;
      this.showFilter = false;

    } else if (this.innerWidth < 1200) {
      this.showList = true;
      this.showFilter = false;

    } else {
      this.showList = true;
      this.showFilter = false;
    }
  }

  screenValues() {
    this.showList = true;
    if (this.innerWidth < 768) {
      // mobile
      this.mobileview = true;
      this.mobileviewTaskcontent = false;
      this.showFilter = false;
    } else if (this.innerWidth < 1200) {
      // big tablet screen or laptop
      this.mobileview = true;
      this.mobileviewTaskcontent = true;
      this.showFilter = true;
    } else {
      this.showFilter = false;
      // desktop
      this.mobileview = false;
      this.mobileviewTaskcontent = true;
    }
  }

  resetAll() {
    this.page = 0;
    this.currentPage = 1;
    this.searchValue = '';
    this.selected_bulkcode_id = '';
    this.viewCodeType = '';
    this.bulkCodesData();
  }

  onpageChanged(ev) {
    this.page = ev.page * this.defaultLimit - this.defaultLimit;
    this.bulkCodesData();
  }

  procedureCodeAddPageChanged(ev) {
    this.procedureCodeAddPage = ev.page * this.allPaginationDefaultLimit - this.allPaginationDefaultLimit;
    this.viewBulkcode(this.selected_bulkcode_id, 'pagination', this.procedureCodeAddPage, this.allPaginationDefaultLimit, 'ADD');
  }

  procedureCodeUpdatePageChanged(ev) {
    this.procedureCodeUpdatePage = ev.page * this.allPaginationDefaultLimit - this.allPaginationDefaultLimit;
    this.viewBulkcode(this.selected_bulkcode_id, 'pagination', this.procedureCodeUpdatePage, this.allPaginationDefaultLimit, 'UPDATE');
  }

  diagnosisCodeAddPageChanged(ev) {
    this.diagnosisCodeAddPage = ev.page * this.allPaginationDefaultLimit - this.allPaginationDefaultLimit;
    this.viewBulkcode(this.selected_bulkcode_id, 'pagination', this.diagnosisCodeAddPage, this.allPaginationDefaultLimit, 'ADD');
  }

  diagnosisCodeUpdatePageChanged(ev) {
    this.diagnosisCodeUpdatePage = ev.page * this.allPaginationDefaultLimit - this.allPaginationDefaultLimit;
    this.viewBulkcode(this.selected_bulkcode_id, 'pagination', this.diagnosisCodeUpdatePage, this.allPaginationDefaultLimit, 'UPDATE');
  }

  procedureModifierAddPageChanged(ev) {
    this.procedureModifierAddPage = ev.page * this.allPaginationDefaultLimit - this.allPaginationDefaultLimit;
    this.viewBulkcode(this.selected_bulkcode_id, 'pagination', this.procedureModifierAddPage, this.allPaginationDefaultLimit, 'ADD');

  }

  procedureModifierUpdatePageChanged(ev) {
    this.procedureModifierUpdatePage = ev.page * this.allPaginationDefaultLimit - this.allPaginationDefaultLimit;
    this.viewBulkcode(this.selected_bulkcode_id, 'pagination', this.procedureModifierUpdatePage, this.allPaginationDefaultLimit, 'UPDATE');
  }

  switchViews(from) {
    this.viewCodeType = from;
    // if (from == 'procedure') {
    //   this.codesViewFlag = true;
    //   this.viewCodeType = 'procedureCode';
    // }
    // if (from == 'diagnosis') {
    //   this.codesViewFlag = false;
    //   this.viewCodeType = 'diagnosisCode';
    // }
  }
  confirm(template: TemplateRef<any>) {
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static'
    });
  }
  modalClose() {
    this.SharedPopupService.dismiss();
  }
  approvecode() {
    this.bulkCodeService.approveCode(this.selectedListData._id).subscribe(data => {
      if (data.status == 'success') {
        let index = this.bulkCodes.findIndex(data => {
          return data._id == this.selectedListData._id;
        });
        if (index != -1) {
          this.bulkCodes[index].status = 'approved';
        }
        this.sharedService.pushToaster('success', 'Success', 'Approved successfully');
        this.modalClose();
      }
    }, err => {
      this.sharedService.pushToaster('error', 'Error', 'Approved Failed');
      this.modalClose();
    });
  }
  decline(id) {
    this.bulkCodeService.declineFile(id).subscribe(data => {
      if (data.status == 'success') {
        this.selectedListData.status = 'declined';
        this.sharedService.pushToaster('success', 'Success', 'File declined successfully.');
      }
    });
  }

  backToResults() {
    this.mobileviewTaskcontent = false;
    this.showFilter = false;
    this.showList = true;
  }

  resetAllPagination() {
    this.procedureCodeAddPage = 0;
    this.procedureCodeAddCurrentPage = 1;
    this.procedureCodeUpdatePage = 0;
    this.procedureCodeUpdateCurrentPage = 1;
    this.diagnosisCodeAddPage = 0;
    this.diagnosisCodeAddCurrentPage = 1;
    this.diagnosisCodeUpdatePage = 0;
    this.diagnosisCodeUpdateCurrentPage = 1;
    this.procedureCodeAddPageskip = 0;
    this.procedureCodeUpdatePageskip = 0;
    this.diagnosisCodeAddPageskip = 0;
    this.diagnosisCodeUpdatePageskip = 0;

    this.procedureModifierAddPageskip = 0;
    this.procedureModifierAddPage = 0;
    this.procedureModifierAddCurrentPage = 1;
    this.procedureModifierUpdatePageskip = 0;
    this.procedureModifierUpdatePage = 0;
    this.procedureModifierUpdateCurrentPage = 1;
    this.viewCodeType = '';
  }

  ngOnDestroy(): void {
    this.SharedPopupService.dismiss();
  }
}
