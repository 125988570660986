'use strict';
import { environment } from '../environments/environment';
//const HostName = 'http://localhost:4000';                    // development
//const GlobalSettingsHostName = 'http://localhost:3600';      // development
// const serviceHost ='http://localhost:3300'                    // development
// const SocketUrl='http://localhost:3333';
// const PracticeSuiteUrl='http://localhost:3636';

const HostName = environment.HostName; // 'https://collector-dev.claimocity.io';     // production
const GlobalSettingsHostName = environment.GlobalSettingsHostName; // 'https://settings-dev.claimocity.io';   // production
const serviceHost = environment.serviceHost; // 'https://collectornodeapi-dev.claimocity.io';      // production
const SocketUrl = environment.SocketUrl; // 'https://socket-dev.claimocity.io';
const PracticeSuiteUrl = environment.PracticeSuiteUrl; // 'https://practicesuite-dev.claimocity.io';

export const Get_ServiceLayer_Url = HostName;
export const Base_URL = GlobalSettingsHostName;
export const Socket_Url = SocketUrl;
// export const Get_Current_Url = window.location.href.slice(0,24);
export const Get_Current_Url = window.location.href.split('/#');


// login
export const LoginAuth_URL = HostName + '/authenticate';
export const LoginAuth_URL_V2 = HostName + '/authenticate/v2';
export const LoginAuth_URL_V3 = HostName + '/authenticate/v3';
export const ResetPassword_URL = HostName + '/resetpassword';
export const GetEnv_URL = HostName + '/environment';
export const UserLogin_History_URL = HostName + '/userloginhistory';


export const Settings_URL = GlobalSettingsHostName + '/settings';

// update practice token
export const update_token_URL = HostName + '/updatetoken';

// update uae version
export const update_uae_version = HostName + '/updatetokenversion';

// dashboard
export const Report = HostName + '/report';
export const Reports = HostName + '/reports';


// Task management
export const TaskDashboard_URL = HostName + '/taskdashboard';
export const TaskBase_URL = HostName + '/tasks';
export const Task_Basepath = HostName + '/task/';

/* Practices-setting*/
export const Practices_URL = GlobalSettingsHostName + '/practices';
export const Integration_URL = GlobalSettingsHostName + '/integration';

export const PracticeInsurances_URL = GlobalSettingsHostName + '/practiceinsurances';

export const User_URL = GlobalSettingsHostName + '/users';
export const Role_URL = GlobalSettingsHostName + '/roles';
export const Skill_Category_URL = GlobalSettingsHostName + '/skillcategory';
export const Skill_Set_URL = GlobalSettingsHostName + '/skillset';

/* Search */
export const Search_URL = GlobalSettingsHostName + '/search';

/* codesets */
export const ProcedureCodes_URL = GlobalSettingsHostName + '/procedurecodes';
export const DiagnosisCodes_URL = GlobalSettingsHostName + '/diagnosiscodes';
export const ProcedureModifiers_URL = GlobalSettingsHostName + '/proceduremodifiers';


/* Permissions */
export const Permissions_URL = GlobalSettingsHostName + '/permissions';

/* security */
export const Resource_URL = GlobalSettingsHostName + '/resources/';

/* Features */
export const Features_URL = GlobalSettingsHostName + '/features';

// charges

export const charges_URL = HostName + '/charges';

//Charge Dashboard

export const ChargeDashboard_URL = HostName + '/chargedashboard';

// patients

export const patients_URL = HostName + '/patients';

// Batches
export const batch_URL = HostName + '/batches';
export const searchbatch_URL = HostName + '/batches/filter';
export const BatchesUpload_URL = HostName + '/batches/upload';
export const ProcessesBatch_URL = HostName + '/batches/batches';
export const BulkCodeUpload_URL = GlobalSettingsHostName + '/bulkcodes/upload';
export const BatchSourceUpload_URL = HostName + '/batches/uploadsource';

// Bulk code
export const bulkCode_URL = GlobalSettingsHostName + '/bulkcodes';


// Size limit for Batch file upload
export const batch_SIZE = 100000000; // 100mb

/* Attachment aws s3 */
export const TaskAttachment_SIZE = 100000000; // 100mb
// export const TaskAttachment_SIZE = 10000; //1mb test
export const AllowedForamts = ['xls', 'xlsx', 'xlsm', 'xltx', 'xltm', 'xlsb', 'xlam', 'csv'
    , 'pdf', 'doc', 'docs', 'docm', 'dotm', 'dotx', 'docx'
    , 'xml', 'json'
    , 'ppt', 'pptx', 'pptm', 'potx', 'potm', 'ppam', 'ppsx', 'ppsm', 'sldx', 'sldm', 'thmx'
    , 'mp3'// 'mp4','mov'
    , 'jpg', 'jpeg', 'png', 'gif', 'psd', 'psb', 'svg', 'txt'
];

// global data
export const globaldata_URL = GlobalSettingsHostName + '/globaldata';

// rules validator
export const validator_rules_URL = HostName + '/rules';

// rules Crud url
export const rules_URL = GlobalSettingsHostName + '/rules/v2';

/* Validation Reports */
export const Validation_Reports_URL = HostName + '/validationreport';

export const current_Practice = GlobalSettingsHostName + '/currentpractice';
// Get image url
export const images_URL = HostName + '/files/image';

export const invoicing_URL = GlobalSettingsHostName + '/practiceinvoice';
export const status_codes_URL = GlobalSettingsHostName + '/statuscode';

// Taskassign URL 
export const TaskAssign_URL = GlobalSettingsHostName + '/taskassignbatches';
export const TaskAssignUpload_URL = HostName + '/taskassignbatches/upload';
export const ProcessesTaskAssign_URL = HostName + '/taskassignbatches/taskassignbatches';

//DoctorNow URL
export const DoctorNow_URL = GlobalSettingsHostName + '/doctornow';


//Patient360 URL
export const PATIENT360_URL = GlobalSettingsHostName + '/patient360';

//timesheet
export const TIMESHEET_URL = GlobalSettingsHostName + '/timesheet';

//promeni
export const PROMENI_URL = HostName + '/promeni';

//HRMS Integration
export const HRMS_Integrate = HostName + '/hrms';