import { Component, OnInit,Input} from '@angular/core';
import { UsersService } from '../../../_services/settings/users.service';
import { LoginHistoryService } from '../../../../app/_services/login-history/login-history.service';
import { DataHelperService } from '../../../_services/auth/data-helper.service';
import {Location} from '@angular/common';
import { Router } from '@angular/router';
import * as moment from 'moment';
import constants from '../../../constants';
import { SharedService } from '../../../_services/shared.service';

@Component({
  selector: 'app-session-history',
  templateUrl: './session-history.component.html',
  styleUrls: ['./session-history.component.scss']
})
export class SessionHistoryComponent implements OnInit {
  allHistoryData: any;
  totalCount: any;
  currentPage:number;
  defaultLimit:number;
  page: any;
  enableback:boolean;
  timeout = null;
  loaderIndicator: boolean;
  loginHistoryToday_date: string;
  constants: any;

  @Input('usersInfo') usersInfo: any;
  userName: any;
  selectedpagenumber: any;
  constructor(private router: Router, private _location: Location, public usersService:UsersService,public loginHistoryService:LoginHistoryService,public dataHelperService:DataHelperService,public sharedService: SharedService) { 
    this.allHistoryData=[];
    this.currentPage=1;
    this.defaultLimit=20;
    this.page = 0;
    this.selectedpagenumber = 1;
    // console.log(this.router.url,'test route')
    this.enableback = (this.router.url == '/session-history') ? true : false;
    this.loaderIndicator = true;
    this.constants = constants;
    this.loginHistoryToday_date = moment().format('MM-DD-YYYY') + ' - ' + moment().format('MM-DD-YYYY');
    //console.log(this.loginHistoryService)
    
  }

  backClicked() {
    this._location.back();
  }
  
  ngOnInit() {
    //console.log(this.usersInfo,"usersInfousersInfousersInfo")
    this.getSessionHistory();
  }

  getSessionHistory(){
    if(!this.usersInfo){
      var user_id = this.dataHelperService.getItem('userid');
      this.userName = this.dataHelperService.getItem('currentUserDisplayname');
    }else{
      var user_id = this.usersInfo.user_id;
      this.userName = this.usersInfo.user_name;
    }
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
    this.usersService.getSessionHistory(user_id,this.page).subscribe(data =>{
      this.loaderIndicator = false;
      this.allHistoryData= data[0].totalData
      this.totalCount = data[0].totalCount
      this.allHistoryData.forEach(element => {
        if(element.token == localStorage.getItem('authToken')){
          element.currentSession=true;
        }
      });
      if(this.totalCount[0]){
      let contvalue = Math.floor(this.totalCount[0].count/20)
      if(contvalue+1 == this.selectedpagenumber){
        this.allHistoryData.splice(-1,1)
      }
    }
    });
  },100);
  }

  pageChanged(val) {
    this.selectedpagenumber = val.page;
    this.page = val == 0 ? 0 : (val.page * this.defaultLimit - this.defaultLimit);
    this.getSessionHistory();
  }
  
  removesession(sessiondata,index){
    //let user_id = this.usersInfo.user_id;
    let req = {token : sessiondata.token};
    this.usersService.removeSessionHistory(sessiondata.user_id,req).subscribe(data =>{
      if(data){
        this.allHistoryData.splice(index,1);
        this.sharedService.pushToaster("success","Success", "Session removed sucessfully");
      }else{
        this.sharedService.pushToaster('error',"Error", "Remove session failled");
        return;
      }
    });
  }
    
  }


