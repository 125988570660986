import { Component, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
// import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { HeaderService } from '../../../_services/header/header.service';
import { DataHelperService } from '../../../_services/auth/data-helper.service';
import { AppointmentTypesService } from '../../../_services/appointment-types/appointment-types.service';
import { SharedService } from '../../../_services/shared.service';
import { IntegrationService } from '../../../_services/integration/integration.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import constants from '../../../constants';

class Integration {
  newPatientToHl7: Boolean;
  updatePatientToHl7: Boolean;
  hl7ToUpdatePatient: Boolean;
  chargesToHl7: Boolean;
  appointmentUpdatesToHl7: Boolean;
  hl7ToappointmentUpdates: Boolean;
  updateChargesHl7Only: Boolean;
  updateDailyChargesHl7Only: Boolean;
}

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IntegrationComponent implements OnInit {
  integrationShow: Boolean = false;
  public integration: Integration;
  current_practice_id: String;
  integrationForm: any;
  subscription1$: Subscription;
  constants:any;
  constructor(
    private headerService: HeaderService,
    private sharedService: SharedService,
    private integrationService: IntegrationService,
    private DataHelper: DataHelperService) {
      if (localStorage.getItem('practice_id')) {
        let newPractice = this.DataHelper.getItem('practice_id');
        this.current_practice_id = newPractice;
        this.integrationShow = false;
        this.getIntegrationSettings();
      }
      this.subscription1$ = this.headerService.practiceEmitted$.subscribe(newPractice => {
        this.current_practice_id = newPractice;
        this.integrationShow = false;
        this.constants=constants;
        this.getIntegrationSettings();
      });

      this.integrationForm = [
        {
          name: 'Send HL7 for new patient',
          ngValue: 'newPatientToHl7'
        },
        {
          name: 'Send HL7 for patient updates',
          ngValue: 'updatePatientToHl7'
        },
        {
          name: 'Process inbound HL7 for patient updated',
          ngValue: 'hl7ToUpdatePatient'
        },
        {
          name: 'Send HL7 for charges',
          ngValue: 'chargesToHl7'
        },
        {
          name: 'Send HL7 appointment updates',
          ngValue: 'appointmentUpdatesToHl7'
        },
        {
          name: 'Process inbound HL7 appointment updates',
          ngValue: 'hl7ToappointmentUpdates'
        },
        {
          name: 'Send charges immediately upon submission',
          ngValue: 'updateChargesHl7Only'
        },
        {
          name: 'Automatic daily submission of charges',
          ngValue: 'authomaticSubmissionOfDailyCharges'
        },{ 
          name: 'Send patient documents',
          ngValue: 'sendPatientDocuments'
        }
      ];
      
     }

  ngOnInit() {
  }

  getIntegrationSettings() {
    this.integration = {
      newPatientToHl7: false,
      updatePatientToHl7: false,
      hl7ToUpdatePatient: false,
      chargesToHl7: false,
      appointmentUpdatesToHl7: false,
      hl7ToappointmentUpdates: false,
      updateChargesHl7Only: false,
      updateDailyChargesHl7Only: false
    };
    this.integrationService.getIntegration().subscribe(data => {
      this.integrationShow = false;
      if (data.status == 'success') {
        this.integration = _.assign({}, this.integration, data.results.integration);
        this.integrationShow = data.results.hasOwnProperty('ps_integration') ? data.results.ps_integration : false;
      }
    });
  }

  integrationChange(ev, from) {
    let toggleValue = {
      settingsKey: from,
      settingsValue : ev.target.checked
    };
    this.integrationService.updateSettings(toggleValue).subscribe(data => {
      if (data.status == 'success') {
        this.sharedService.pushToaster('success', 'Success', 'HL7 Integration settings updated successfully');
      } else if (data.status == 'failure') {
        this.sharedService.pushToaster('error', 'Error', 'HL7 Integration settings not update');
      }
    });
  }

  ngOnDestroy() {
    this.subscription1$.unsubscribe();
  }
}
