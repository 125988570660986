import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, TemplateRef } from '@angular/core';
import { TaskService } from '../../_services/task/task.service';
import * as moment from 'moment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../../_services/shared.service';

@Component({
  selector: 'app-practice-invoice-summary',
  templateUrl: './practice-invoice-summary.component.html',
  styleUrls: ['./practice-invoice-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PracticeInvoiceSummaryComponent implements OnInit {
  @Input('invoicesummary') invoicesummary: any;
  @Output() invoiceEvent = new EventEmitter<any>();
  summaryData:any;
  statusArr:any;
  constructor(public taskService: TaskService, public modalService: BsModalService, private sharedService: SharedService) {
  
    this.statusArr=[
      {label : 'draft', value : 'draft'},
      {label : 'done', value : 'done'}
    ];
  }

  ngOnInit() {
  }
  
  ngOnChanges() {
    if (this.invoicesummary) {
      this.summaryData = this.invoicesummary;
    } else {
      this.summaryData = [];
    }
  }

  onSelected(status){
    this.invoiceEvent.emit(status);
  }

}
