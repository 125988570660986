import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SharedPopupService } from '../../../_services/popup.service';
// import { Component, OnInit, TemplateRef } from '@angular/core';
import { DataHelperService } from '../../../_services/auth/data-helper.service';
// import { BsModalService, BsModalRef,ModalDirective } from 'ngx-bootstrap';
import { TaskstatusesService } from '../../../_services/settings/taskstatuses.service';
import { TasktypesService } from '../../../_services/settings/tasktypes.service';
import { UsersService } from '../../../_services/settings/users.service';
import { SharedService } from '../../../_services/shared.service';
import constants from '../../../constants';
import { result } from 'lodash';
import { format } from 'path';
@Component({
  selector: 'app-tasktypes',
  templateUrl: './tasktypes.component.html',
  styleUrls: ['./tasktypes.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TasktypesComponent implements OnInit, OnDestroy {

  selectedindex: string = '';
  activeid: string = '';
  elementRef: ElementRef;

  popupTitle: string;
  popUpfrom: any;
  popUpinfo: any;
  popUpinfoselectedIndex: any;
  parentIndexValue: any;
  taskType: String;
  taskDescription: String;
  is_charge: boolean;
  is_patient: boolean;
  notifyusers: any;
  userDtList: any[];
  userDt: any[];
  taskTypeData: any;
  // modalRef: BsModalRef;
  templateTitleDisplay: string;
  enabled: boolean;
  templateTitleName: any;
  templateName: any;
  logged_in_user_id: any;
  addEditTemplateFrom: any;
  currenttemplateVal: any;
  templateConfirmMsg: string;
  currentStatusConfirmData: any;
  allTasktypeData = [];
  seletedTab: any;
  templateIndex: any;
  taskTypeIndex: any;
  timeout: any;
  templatefrom: String;
  showInactive: boolean;
  searchValue: String;
  childIndexValue: any;
  practiceMappedUsers: any[];
  practiceUserEmptyResult: any;
  practiceUsersData: any;
  searchUserValue: string;
  statusList: any;
  uses: string;
  searchText: string;
  loaderIndicator:boolean
  tabloaderIndicator: boolean;
  constants:any;
  chkbxdisabled:boolean;
  disablebtn: boolean;
  // tslint:disable-next-line: no-shadowed-variable
  constructor(public taskTypeservice: TasktypesService, public SharedPopupService: SharedPopupService, public modalService: BsModalService, private sharedService: SharedService, private DataHelperService: DataHelperService, private usersService: UsersService, private taskstatusesService: TaskstatusesService) {
    this.searcTaskKey = _.debounce(this.searcTaskKey, 400);
    this.statusList = this.userDt = [];
    this.logged_in_user_id = this.DataHelperService.getItem('userid');
    this.templateTitleName = '';
    this.searchUserValue = '';
    this.uses = '';
    this.templateName = '';
    this.is_charge = false;
    this.is_patient = false;
    this.taskTypeservice.showInactive = false;
    this.searchValue = '';
    this.searchText = '';
    this.loaderIndicator=true;
    this.tabloaderIndicator=true;
    this.constants=constants;
    this.chkbxdisabled=true;
    this.disablebtn = false;
  }

  ngOnInit() {
    this.loadtasktypes();
  }

  loadtasktypes() {
    this.taskTypeservice.tasktype_list = [];
    this.loaderIndicator=true;
    let showInactive = this.taskTypeservice.showInactive;
    let str = this.searchValue;
    this.taskTypeservice.tasktypes_list(str, showInactive).subscribe(
      data => {         this.taskTypeservice.tasktype_list_status = data.status;
        this.loaderIndicator=false;
        if (data.status == 'success') {
          if ((data.results).length > 0) {
            this.taskTypeservice.tasktype_list = data.results;
            this.taskTypeservice.tasktype_list.forEach((element) => {
              element.type = '';
              element.tabloaderIndicator=true;
              this.allTasktypeData.push(element);
            });
            //this.loaderIndicator=false;
            // this.taskTypeservice.selected = data.results[0]._id;
            // this.viewtasktype(this.taskTypeservice.selected);
            // this.notifyusers = data.results[0]['emailNotification'];
          }
        }
      }
    );
  }

  activeStatus() {
    //this.loaderIndicator=true;
    this.taskTypeservice.showInactive = (this.showInactive == false || this.showInactive == undefined) ? true : false;
   // this.loaderIndicator=false;
    this.loadtasktypes();
   
  }

  changetasktypes() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.loadtasktypes();
    }, 1100);
  }
  tabChange(selectedTab: any, selectedTemplate, index) {
    this.allTasktypeData[index]=[];
    this.allTasktypeData[index].tabloaderIndicator=true;
    if (selectedTab == 'Template' || selectedTab == 'Notifications') {
      this.selectedindex = index;
      this.activeid = selectedTemplate._id;
      this.seletedTab = selectedTab;
      if (this.seletedTab == 'Template') {
        this.taskTypeservice.taskType_template_list(selectedTemplate._id).subscribe(data => {
         
    this.allTasktypeData[index].tabloaderIndicator=false;
          if (data.status == 'success') {
            this.allTasktypeData[index] = data.results;
            this.allTasktypeData[index]['template_limit'] = 10;
            this.allTasktypeData[index]['type'] = selectedTab;
            //this.tabloaderIndicator=false;
          } else {
      //console.log('network error');
      this.allTasktypeData[index].tabloaderIndicator=true;
          }
        });
      } else if (this.seletedTab == 'Notifications') {
        this.allTasktypeData[index]['type'] = selectedTab;
        this.taskTypeservice.taskType_emailnotification_list(selectedTemplate._id).subscribe(data => {
          this.allTasktypeData[index].tabloaderIndicator=false;
          if (data.status == 'success') {
            this.allTasktypeData[index]['emailnotification_template_limit'] = 10;
            this.allTasktypeData[index]['emailNotification'] = data.results;

          } else {
            this.allTasktypeData[index].tabloaderIndicator=false;
            this.allTasktypeData[index]['emailNotification']=[];
      //console.log('network error');
          }
        });
      }


    } else {
      // Other tab text
//console.log('Other text', selectedTab);
    }
  }

  toggleLimit(item, limit, type) {
    item[type] = limit;
  }
  addEditTasktypeTemplate(addEditTemplate: TemplateRef<any>, from, addEditData?, TasktypeIndex?, templateIndex?) {
    this.addEditTemplateFrom = from;
    this.currenttemplateVal = addEditData;
    this.templateIndex = templateIndex;
    this.taskTypeIndex = TasktypeIndex;
    if (from == 'addNewTemplate') {
      this.templateTitleDisplay = 'Create new Template';
    } else if (from == 'editTemplate') {
      this.templateTitleDisplay = 'Edit Template';
      this.templateTitleName = addEditData.template_title;
      this.templateName = addEditData.template;
    }
    this.SharedPopupService.show(addEditTemplate, {
      animated: true,
      backdrop: 'static'
    });
  }
  saveTemplate() {
    if (this.addEditTemplateFrom == 'addNewTemplate') {
      let addreqTemplateData = {
        tasktype_id: this.allTasktypeData[this.taskTypeIndex]._id,
        template: this.templateName,
        template_title: this.templateTitleName,
        userid: this.logged_in_user_id
      };
      this.taskTypeservice.addNew_taskTemplate(addreqTemplateData).subscribe(res => {
        if (res.status == 'success') {
          if (this.allTasktypeData[this.taskTypeIndex].template_limit == undefined) {
            this.allTasktypeData[this.taskTypeIndex].template_limit = 10;
          }
          this.allTasktypeData[this.taskTypeIndex].templates = res.data;
        }
        this.modalClose();
        this.sharedService.pushToaster('success', 'Success', 'Template added successfully');
      }, () => {
        this.modalClose();
        this.sharedService.pushToaster('info', 'Error', 'Error information');
      });
    } else if (this.addEditTemplateFrom == 'editTemplate') {
      let editreqTemplateData = {
        tasktypeid: this.allTasktypeData[this.taskTypeIndex]._id,
        template: this.templateName,
        template_id: this.allTasktypeData[this.taskTypeIndex].templates[this.templateIndex].template_id,
        template_title: this.templateTitleName,
        userid: this.logged_in_user_id
      };

      this.taskTypeservice.edit_task_template(editreqTemplateData).subscribe(res => {
        if (res.status == 'success') {
          this.allTasktypeData[this.taskTypeIndex].templates[this.templateIndex] = res.data[this.templateIndex];

        }
        this.modalClose();
        this.sharedService.pushToaster('success', 'Success', 'Template updated successfully');

      }, () => {
        this.modalClose();
        this.sharedService.pushToaster('info', 'Error', 'Error information');
      });
    }

  }
  tasktypeTemplateConfirm(templateConfirmpopup: TemplateRef<any>, from, templateData, tasktype_index, template_index) {
    this.templatefrom = from;
    this.currentStatusConfirmData = templateData;
    this.taskTypeIndex = tasktype_index;
    this.templateIndex = template_index;
    if (this.templatefrom == 'deActivateTasktypes') {
      this.templateConfirmMsg = this.taskTypeIndex == false ? 'Are you sure want to Deactivate Task type?' : 'Are you sure want to Activate Task type?';
    } else if (this.templatefrom == 'deactivateTemplate') {
      this.templateConfirmMsg = templateData.enabled ? 'Are you sure want to Deactivate status?' : 'Are you sure want to Activate status?';
    }

    this.SharedPopupService.show(templateConfirmpopup, {
      animated: true,
      backdrop: 'static'
    });
  }

  confirmTemplateStatus() {
    if (this.templatefrom == 'deactivateTemplate') {
      let reqUpdateStatus = {
        status: this.allTasktypeData[this.taskTypeIndex].templates[this.templateIndex].enabled ? false : true,
        tasktype_id: this.allTasktypeData[this.taskTypeIndex]._id,
        template_id: this.allTasktypeData[this.taskTypeIndex].templates[this.templateIndex].template_id
      };

      this.taskTypeservice.update_template_status(reqUpdateStatus).subscribe(res => {
        if (res.status == 'success') {
          this.allTasktypeData[this.taskTypeIndex].templates[this.templateIndex] = res.data[this.templateIndex];
        }
        this.modalClose();
        this.sharedService.pushToaster('success', 'Success', 'Template status updated successfully');
      });
    }
    if (this.templatefrom == 'deActivateTasktypes') {
      this.taskTypeIndex = !this.taskTypeIndex;
      this.taskTypeservice.toggletasktypestatus(this.currentStatusConfirmData, this.taskTypeIndex).subscribe(
        data => {
          if (data.status == 'success') {
            // this.taskTypeservice.tasktype_list[this.taskTypeIndex]
            // this.taskTypeservice.tasktype_list.splice()
            this.taskTypeservice.tasktype_list.splice(this.templateIndex, 1);
            // this.taskTypeservice.tasktype_list[this.templateIndex].isinactive = this.taskTypeIndex;
            this.modalClose();
          }
        });
    }
  }

  // modalClose(){
  //   this.modalRef.hide()
  //   this.templateTitleName = '';
  //   this.templateName = '';
  // }


  viewtasktype(id) {
    this.taskTypeservice.selected = id;
    this.taskTypeservice.viewtasktype(id).subscribe(
      data => {
        if (data.status == 'success') {
          this.taskTypeservice.tasktype = data.results;
          if (data.results && data.results['emailNotification']) {
            this.notifyusers = data.results['emailNotification'];
          } else {
            this.notifyusers = [];
          }
          // this.userDetails();
          // this.notifyusers = data.results[0]['emailNotification'];
        }
      }
    );
  }

  // userDetails(){
  //   var userArr = [];
  //   this.userDt = [];
  //   if(this.notifyusers.length==0)this.userDtList = [];
  //   this.notifyusers.forEach((list) => {
  //    userArr.push.apply(userArr, list.user_id);
  //   });
  //    this.taskTypeservice.userListService(userArr).subscribe(data => {
  //     this.userMapping(data);
  //   });
  // }

  openModal(template: TemplateRef<any>, from, infoData?, selectedIndex?, parentIndex?) {
    this.popUpfrom = from;
    this.parentIndexValue = parentIndex;
    this.popUpinfo = infoData;
    this.popUpinfoselectedIndex = selectedIndex;
    if (from == 'newTaskTypes') {
      this.popupTitle = 'Create a new task type';
    } else if (from == 'editTaskTypes') {
      this.popupTitle = 'Edit task type';
      this.taskType = infoData.tasktype;
      this.taskDescription = infoData.task_description ? infoData.task_description : '';
      this.is_charge = infoData.is_charge;
      this.is_patient = infoData.is_patient;
    }
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static'
    });
  }

  onCancel() {
    this.SharedPopupService.dismiss();
    this.chkbxdisabled = true;
  }

  addTaskTypes() {
    let data = {
      tasktype: this.taskType,
      task_description: this.taskDescription,
      is_charge: this.is_charge,
      is_patient: this.is_patient
    };
    if (this.taskType) {
      // tslint:disable-next-line: no-shadowed-variable
      this.taskTypeservice.add_new_task_types(data).subscribe(data => {
        // console.log(data);
        if (data.status == 'success') {
          //if (data.data) {
            // let task_type=data.data
            // task_type.templates= null
            // task_type.modified_at = task_type.created_at
            // task_type.modified_by = task_type.created_by
            // task_type.tabloaderIndicator = false
            // task_type.emailNotification = null

            //this.taskTypeservice.tasktype_list.push(task_type);
            this.loadtasktypes();
            this.sharedService.pushToaster('success', 'Success', 'Task Type Added Successfully');
            
          //}
            setTimeout(()=>this.modalClose(),500)
        } else if(data.status == 'validation_failure') {
          this.sharedService.pushToaster('error', 'Error', data.errmessage);
        } else {
          this.sharedService.pushToaster('error', 'Error', data.Message);
        }
      }, () => {
        this.modalClose();
        this.sharedService.pushToaster('info', 'Error information', 'Task Types Already Exists');
      });
    }
  }

  modalClose() {
    this.SharedPopupService.dismiss();
    this.taskType = '';
    this.taskDescription = '';
    this.is_charge = false;
    this.is_patient = false;
    this.templateTitleName = '';
    this.templateName = '';
    this.chkbxdisabled = true;
  }

  editTaskTypes() {
    let data = {
      tasktype: this.taskType,
      task_description: this.taskDescription,
      is_charge: this.is_charge,
      is_patient: this.is_patient
    };
    // tslint:disable-next-line: no-shadowed-variable
    this.taskTypeservice.edit_task_types(this.popUpinfo._id, data).subscribe(data => {
      if (data.status == 'success') {
        this.taskTypeservice.tasktype_list[this.popUpinfoselectedIndex].tasktype = this.taskType;
        this.taskTypeservice.tasktype_list[this.popUpinfoselectedIndex].task_description = this.taskDescription;
        this.taskTypeservice.tasktype_list[this.popUpinfoselectedIndex].is_charge = this.is_charge;
        this.taskTypeservice.tasktype_list[this.popUpinfoselectedIndex].is_patient = this.is_patient;

        this.sharedService.pushToaster('success', 'Success', 'Task Type Updated Successfully');
        this.modalClose();
      } else {
        this.sharedService.pushToaster('info', 'Error information', 'Task Type Already Exists');
      }
    }, () => {
      this.sharedService.pushToaster('info', 'Error information', 'Task Type Already Exists');
    });
  }

  openUserModal(template: TemplateRef<any>, from, uses, infoData, parentIndex, childIndex) {
    this.searchText = '';
    this.popUpfrom = from;
    this.parentIndexValue = parentIndex;
    this.childIndexValue = childIndex;
    this.popUpinfo = infoData;
    // console.log(this.popUpinfo);
    // console.log(infoData);
    this.uses = uses;
    if (from == 'mapUser' && uses == 'new') {
      this.taskstatusesService.task_statuses_list('').subscribe(result => {
        let statusList = result;
        this.statusList = statusList.filter(x => x.task_active_status == 1).map(x => {
          return { label: x.task_status_name, value: x._id };
        });
      });
      this.practiceMappedUsers = [];
      this.searcTaskKey('');
    }
    if (from == 'mapUser' && uses == 'edit') {
      this.taskstatusesService.task_statuses_list('').subscribe(result => {
        let statusList = result;
        this.statusList = statusList.filter(x => x.task_active_status == 1).map(x => {
          return { label: x.task_status_name, value: x._id };
        });
      });
      
      this.practiceMappedUsers = [];
      //let tmpexistingData = _.cloneDeep({user_id:this.popUpinfo.useridlist,username:this.popUpinfo.usernamelist});
      if (this.popUpinfo.useridlist) {
        for(var i=0;i<this.popUpinfo.useridlist.length;i++)
        {
          this.practiceMappedUsers.push({ '_id': this.popUpinfo.useridlist[i], 'displayname': this.popUpinfo.usernamelist[i] });
        }
        //this.defaultSelection();
      }
      this.searcTaskKey('');
    }
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }

  searcTaskKey(value: string) {
    this.loaderIndicator=true;
    let tempBool = false;
    let datePattern = /^[a-zA-Z0-9\.\- ]*$/;
    let regExVal = new RegExp(datePattern);
    tempBool = regExVal.test(value);
    if (tempBool) {
      this.usersService.searchuser(value, '10', 'true').subscribe(result => {
        this.loaderIndicator=false;
        if (result['status'] == 'success') {
          this.practiceUserEmptyResult = result;
          this.practiceUsersData = result['results'];
          this.defaultSelection();
        }
      });
    }
  }
  defaultSelection() {
    this.practiceUsersData.forEach(element => {
      let c = this.practiceMappedUsers.find(tmp => element._id == tmp._id);
      if (c != undefined && c.hasOwnProperty('_id')) {
        element.enabled = true;
      } else {
        element.enabled = false;
      }
    });
  }

  selectPracticeUser(data, _i) {
    if (data.enabled) {
      if (this.practiceMappedUsers == undefined) {
        this.practiceMappedUsers = [];
      }
      data['enabled'] = true;
      this.practiceMappedUsers.push(data);

      let postdata={
        tasktype_id:this.popUpinfo.r_id,
        taskstatus_id:this.popUpinfo.task_status_id,
        user_id:data.user_id
      }
      
      

      if(this.allTasktypeData[this.selectedindex]['emailNotification']){
        let selectedIndex = this.allTasktypeData[this.selectedindex]['emailNotification'].findIndex(result => result.task_status_id == this.popUpinfo.task_status_id )
        if(selectedIndex === -1)
        {
          let statusName = this.statusList.filter(result => {
            if(result.value == this.popUpinfo.task_status_id)
            return result.label
          })
          let pdata={
            _id:{
              _id:this.popUpinfo.r_id,
              task_status_id:this.popUpinfo.task_status_id
            },
            useridlist:[data.user_id],
            usernamelist:[data.displayname],
            r_id:this.popUpinfo.r_id,
            task_status_id:this.popUpinfo.task_status_id,
            task_status_name:statusName[0].label
          }
          this.allTasktypeData[this.selectedindex]['emailNotification'].push(pdata)
          this.taskTypeservice.tasktype_list[this.parentIndexValue].emailNotification.push({
            taskStatus_id: this.popUpinfo.task_status_id,
            user_id: data.user_id,
            username: data.displayname
          })
  
          
        }
        else
        {
          this.allTasktypeData[this.selectedindex]['emailNotification'][selectedIndex].useridlist.push(data.user_id)
          this.allTasktypeData[this.selectedindex]['emailNotification'][selectedIndex].usernamelist.push(data.displayname)
          this.taskTypeservice.tasktype_list[this.parentIndexValue].emailNotification.push({
            taskStatus_id: this.popUpinfo.task_status_id,
            user_id: data.user_id,
            username: data.displayname
          })
        }
      }
      else{
        let statusName = this.statusList.filter(result => {
          if(result.value == this.popUpinfo.task_status_id){
            return result.label
          }
        })

        let statusIndex = this.allTasktypeData.findIndex(results => results._id === this.popUpinfo.r_id)
        let pdata={
          _id:{
            _id:this.popUpinfo.r_id,
            task_status_id:this.popUpinfo.task_status_id
          },
          useridlist:[data.user_id],
          usernamelist:[data.displayname],
          r_id:this.popUpinfo.r_id,
          task_status_id:this.popUpinfo.task_status_id,
          task_status_name:statusName[0].label
        }
        if(statusIndex !== -1){
          this.allTasktypeData[statusIndex]['emailNotification'] = [pdata]
        }else{
          this.allTasktypeData.push({
            emailNotification: [{
              taskStatus_id: this.popUpinfo.task_status_id,
              user_id: data.user_id,
              username: data.displayname
            }],
tasktype: statusName[0].label,
_id: this.popUpinfo.r_id
          })

//           this.allTasktypeData.push({
//             emailNotification: [{
//               taskStatus_id: this.popUpinfo.task_status_id,
//               user_id: data.user_id,
//               username: data.displayname
//             }],
// tasktype: statusName[0].label,
// _id: this.popUpinfo.task_status_id
//           })
        }
        
        //delete this.taskTypeservice.tasktype_list[this.parentIndexValue].emailnotification
       // this.taskTypeservice.tasktype_list[this.parentIndexValue].emailNotification=[];
        this.taskTypeservice.tasktype_list[this.parentIndexValue].emailNotification[0]={
          taskStatus_id: this.popUpinfo.task_status_id,
          user_id: data.user_id,
          username: data.displayname
        }
        

      }

      this.taskTypeservice.addusernotification_map(postdata).subscribe(data => {
        this.sharedService.pushToaster('success', 'Success', 'User added to Email Configuration successfully');
      });
      
    } else {
      let index = this.practiceMappedUsers.findIndex(result => result._id === data._id);
      this.practiceMappedUsers.splice(index, 1);
      let selectedIndex = this.allTasktypeData[this.selectedindex]['emailNotification'].findIndex(result => result.task_status_id == this.popUpinfo.task_status_id )
      if(selectedIndex !== -1)
      {
        let selectedindexuserid = this.allTasktypeData[this.selectedindex]['emailNotification'][selectedIndex].useridlist.findIndex(result => result == data.user_id)
        let selectedindexusername = this.allTasktypeData[this.selectedindex]['emailNotification'][selectedIndex].usernamelist.findIndex(result => result == data.displayname)
        if(this.allTasktypeData[this.selectedindex]['emailNotification'][selectedIndex].useridlist.length > 1 ) {
          this.allTasktypeData[this.selectedindex]['emailNotification'][selectedIndex].useridlist.splice(selectedindexuserid,1)
          this.allTasktypeData[this.selectedindex]['emailNotification'][selectedIndex].usernamelist.splice(selectedindexusername,1)
        }
        else{
          this.allTasktypeData[this.selectedindex]['emailNotification'].splice(selectedIndex,1)
        }
        
        

        let enIndex = this.taskTypeservice.tasktype_list[this.parentIndexValue].emailNotification.findIndex(result => result.user_id== data.user_id && result.taskStatus_id == this.popUpinfo.task_status_id)
        this.taskTypeservice.tasktype_list[this.parentIndexValue].emailNotification.splice(enIndex,1)
      }

      let postdata={
        tasktype_id:this.popUpinfo.r_id,
        taskstatus_id:this.popUpinfo.task_status_id,
        user_id:data.user_id
      }
  
      this.taskTypeservice.deleteusernotification_map(postdata).subscribe(data => {
        this.sharedService.pushToaster('success', 'Success', 'User removed to Email Configuration successfully');
      });
    }

    
      // this.allTasktypeData[this.parentIndexValue].emailNotification = mappedData;
      // this.allTasktypeData[this.parentIndexValue].emailNotification_template_limit = mappedData.length;

  
  }

  removeMappedUser(userAccount, indexs) {
    if (userAccount) {
      let index = this.practiceMappedUsers.findIndex(result => result._id === userAccount._id); // this.practiceUsersData
      if (index >= 0) {
        let localindex = this.practiceUsersData.findIndex(result => result._id === userAccount._id);
        if (localindex >= 0) {
          this.practiceUsersData[localindex].enabled = false;
        }
      }
       this.practiceMappedUsers.splice(indexs, 1); // this.practiceUsersData
      let selectedIndex = this.allTasktypeData[this.selectedindex]['emailNotification'].findIndex(result => result.task_status_id == this.popUpinfo.task_status_id )
      if(selectedIndex !== -1)
      {
        let selectedindexuserid = this.allTasktypeData[this.selectedindex]['emailNotification'][selectedIndex].useridlist.findIndex(result => result == userAccount._id)
        let selectedindexusername = this.allTasktypeData[this.selectedindex]['emailNotification'][selectedIndex].usernamelist.findIndex(result => result == userAccount.displayname)
        if(this.allTasktypeData[this.selectedindex]['emailNotification'][selectedIndex].useridlist.length > 1 ) {
          this.allTasktypeData[this.selectedindex]['emailNotification'][selectedIndex].useridlist.splice(selectedindexuserid,1)
          this.allTasktypeData[this.selectedindex]['emailNotification'][selectedIndex].usernamelist.splice(selectedindexusername,1)
        }
        else{
          this.allTasktypeData[this.selectedindex]['emailNotification'].splice(selectedIndex,1)
        }
        
        

        let enIndex = this.taskTypeservice.tasktype_list[this.parentIndexValue].emailNotification.findIndex(result => result.user_id== userAccount._id && result.taskStatus_id == this.popUpinfo.task_status_id)
        this.taskTypeservice.tasktype_list[this.parentIndexValue].emailNotification.splice(enIndex,1)

        let postdata={
          tasktype_id:this.popUpinfo.r_id,
          taskstatus_id:this.popUpinfo.task_status_id,
          user_id:userAccount._id
        }
    
        this.taskTypeservice.deleteusernotification_map(postdata).subscribe(data => {
          this.sharedService.pushToaster('success', 'Success', 'User removed to Email Configuration successfully');
        });

        // this.taskTypeservice.taskType_emailNotification_list(this.activeid).subscribe(data => {
        //   if (data.status == 'success') {
        //     this.allTasktypeData[this.selectedindex]['emailNotification_template_limit'] = 10;
        //     this.allTasktypeData[this.selectedindex]['emailNotification'] = data.results;

        //   } else {
        //     console.log('network error');
        //   }
        // });
      }
    }
  }

  mapNewUserToPractice() {
    let map = {};
    let mappedData = JSON.parse(JSON.stringify(this.practiceMappedUsers));
    if (this.uses == 'new') {
      map['_id'] = this.popUpinfo.r_id,
        map['taskStatus_id'] = this.popUpinfo.task_status_id,
        map['user_id'] = mappedData.map(x => x._id);
    }
    if (this.uses == 'edit') {
      map['_id'] = this.popUpinfo.r_id,
        map['taskStatus_id'] = this.popUpinfo.task_status_id,
        map['user_id'] = mappedData.map(x => x._id);
    }
    if (map['_id'] != '' && map['_id'] != undefined && map['taskStatus_id'] != '' && map['taskStatus_id'] != undefined && map['user_id'].length > 0) {
      this.taskTypeservice.edit_taskType_emailnotification(map).subscribe(_data => {
        // this.allTasktypeData[this.parentIndexValue].emailnotification = mappedData;
        // this.allTasktypeData[this.parentIndexValue].emailnotification_template_limit = mappedData.length;

        this.allTasktypeData[this.selectedindex]['type'] = 'Notifications';
        this.taskTypeservice.taskType_emailnotification_list(this.activeid).subscribe(data => {
          if (data.status == 'success') {
            this.allTasktypeData[this.selectedindex]['emailnotification_template_limit'] = 10;
            this.allTasktypeData[this.selectedindex]['emailNotification'] = data.results;

          } else {
      //console.log('network error');
          }
        });

        this.modalClose();
        this.timeout = setTimeout(_x => {
          this.sharedService.pushToaster('success', 'Success', 'Users mapped to Email Configuration successfully');
        }, 1000);
      }, _err => {
        this.modalClose();
        this.sharedService.pushToaster('error', 'Failure', 'Bulk user mapping failed');
      });
    } else {
      this.sharedService.pushToaster('error', 'Failure', 'Task type or Task status or Mapped is empty');
    }
  }

  taskStatusChange() {
    this.chkbxdisabled = false;
    this.practiceMappedUsers = [];
    let dataTask = (!this.taskTypeservice.tasktype_list[this.parentIndexValue].emailNotification ? [] : this.taskTypeservice.tasktype_list[this.parentIndexValue].emailNotification);
    let filterData = dataTask.filter(item => item.taskStatus_id === this.popUpinfo.task_status_id);
    //let tmpexistingData = (filterData && filterData[0]) ? _.cloneDeep(filterData[0].user_id) : [];
    if (filterData) {
      filterData.forEach((element, i) => {

        if(element.user_id){
          this.practiceMappedUsers.push({ '_id': element.user_id, 'displayname': element.username })};
      });
    }
    this.defaultSelection();
  }
  disablebutton(err, len) {
    if(err === undefined) {
      this.disablebtn = false;
    } else if(err === null) {
      if(len >=3) {
        this.disablebtn = true;
      } else {
        this.disablebtn = false;
      }
    } else {
      this.disablebtn = false;
    }
  }

  ngOnDestroy() {
    //this.modalService.hide(1);
    this.SharedPopupService.dismiss();

  }
}
