import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class DiagnosisCodesService {

  constructor(private http: HttpClient) { }
  addDiagnosisCode(val) {
    let country = localStorage.getItem('Region');
    if(country=="UAE"){
      val.version=localStorage.getItem('UaeVersion')
    }
    return this.http.post(myGlobals.DiagnosisCodes_URL, val);
  }
  editDiagnosisCode(val, id) {
    return this.http.put(myGlobals.DiagnosisCodes_URL + '/' + id, val);
  }

  searchdiagnosiscode(searchkey, status, page= 0, limit= 20) {
    if (searchkey == '') {
      searchkey = '';
    }
    let version=localStorage.getItem('UaeVersion')
    let url=myGlobals.Base_URL + '/diagnosiscodes?q=' + searchkey + '&page=' + page + '&pagesize=' + limit + '&resStatus=' + status
    if(version && version.length>0){
      url=url+'&version='+ version
    }
    return this.http.get<any>(url);
  }

  activeDiagnosis(code, diaStatus) {
    //  var req = {enabled : resStatus};
    return this.http.put<any>(myGlobals.Base_URL + '/diagnosisCodes/' + code + '/' + diaStatus, {});
  }

  findManyDaignosisCodes(codeArray) {
    let diagnosis_Codes = {
      diagnosis_Codes: codeArray
    }
    return this.http.post<any>(myGlobals.DiagnosisCodes_URL + '/code' , diagnosis_Codes);
  }

}
