import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class PracticesService {

  constructor(private http: HttpClient) {

  }

  searchProvider(practiceId: String, searchKey: String, status = 'any', pagesize = 1000, offset = 0, currentPractice?) {
    let url;
    if (currentPractice) {
      url = myGlobals.Base_URL + '/currentpractice/providers?q=' + searchKey + '&enabled=' + status + '&pagesize=' + pagesize + '&offset=' + offset;
    } else {
      url = myGlobals.Practices_URL + '/' + practiceId + '/providers?q=' + searchKey + '&enabled=' + status+ '&pagesize=' + pagesize + '&offset=' + offset;
    }
    return this.http.get<any>(url);
  }

  searchFacilities(practiceId: String, providerId: String, status = 'any') {
    return this.http.get(myGlobals.Practices_URL + '/' + practiceId + '/providers/' + providerId + '/facilities?enabled=' + status);
  }

  searchPracticeFacilities(practiceId: String, status = 'any') {
    return this.http.get(myGlobals.Practices_URL + '/' + practiceId + '/facilities?enabled=' + status);
  }

  searchAccount(searchKey: String, showActive: boolean) {
    if (searchKey == undefined) {
      searchKey = '';
    }
    let url = myGlobals.Practices_URL + '?q=' + searchKey + '&showActive=' + showActive + '&pagesize=10';
    return this.http.get(url);
  }
  searchLargeAccount(searchKey: String, showActive: boolean) {
    if (searchKey == undefined) {
      searchKey = '';
    }
    let url = myGlobals.Practices_URL + '?q=' + searchKey + '&showActive=' + showActive + '&pagesize=1000000000000';
    return this.http.get(url);
  }
  // usersPracticePagination(batchname: string, iscompleted: boolean, page: any, limit: any, batchStatus, batchType, batchPractice) {
  //   // let req = { "batchname": batchname, "iscompleted": iscompleted, "practice_id": practice_id};
  //   let req = {
  //     'searchkey': batchname,
  //     'status': batchStatus,
  //     'batchType': batchType,
  //     'practice': batchPractice,
  //     'skip': page,
  //     'iscompleted': iscompleted,
  //     'limit': limit 
  //   };
  //   return this.http.post<any>(myGlobals.UserPagination_URL, req);
  // }

  searchAccountById() {
    let url = myGlobals.Base_URL + '/currentpractice';
    return this.http.get<any>(url);
  }

  updateAccountById(data: any) {
    // type can be Organization or Individual -- here setting Organization as default
    let url = myGlobals.Base_URL + '/currentpractice';
    return this.http.put<any>(url, data);
  }

  // List of account groups
  fetchAccountGroupList() {
    return this.http.get<any>(myGlobals.Practices_URL + '/accountgroups');
  }

  accountStatusChange(status: boolean, practiceId: String) {
    let req = { enabled: status };
    return this.http.put(myGlobals.Practices_URL + '/' + practiceId + '/status', req);
  }

  // Create a new practice account
  addPracticeAccount(practiceAccount) {
    return this.http.post(myGlobals.Practices_URL + '/', practiceAccount);
  }
  // Update the sensitive account information for a practice account
  updatePracticeAccount(practiceAccount) {
    let practiceId = practiceAccount.practiceId;
    return this.http.put(myGlobals.Practices_URL + '/' + practiceId + '/account', practiceAccount);
  }

  deactivateAllPracticeUsers(practiceId) {
    return this.http.delete(myGlobals.Practices_URL + '/' + practiceId + '/users');
  }

  /*listPracticeUsers(searchKey, enabled){
    return this.http.get<any>(myGlobals.User_URL + '?q='+ searchKey + '&showActive=' + enabled );  // tried with user api and stuck in filtering of users with practice.
  }*/

  listPracticeUsers(practiceId, searchKey='', enabled, internalUsersOnly, pagesize, offset) {
    internalUsersOnly = internalUsersOnly ? internalUsersOnly : 'false';
    // console.log('internal only', internalUsersOnly);
    return this.http.get<any>(myGlobals.Practices_URL + '/' + practiceId + '/users?q=' + searchKey + '&enabled=' + enabled + '&internalUsersOnly=' + internalUsersOnly + '&pagesize=' + pagesize + '&offset=' + offset  );
  }

  bulkUserInsert(practiceId, mappingData) {
    let req = {};
    req = { selectedData: mappingData };
    return this.http.post<any>(myGlobals.Practices_URL + '/' + practiceId + '/users/usermap', req);
  }

  mapUserFromPractice(practiceId, userId) {
    return this.http.post<any>(myGlobals.Practices_URL + '/' + practiceId + '/users/' + userId,'');
  }
  deleteUser(practiceId, userId) {
    return this.http.delete<any>(myGlobals.Practices_URL + '/' + practiceId + '/users/' + userId,);
  }

  updateUserTitle(practiceId, userId, data) {
    return this.http.put<any>(myGlobals.Practices_URL + '/' + practiceId + '/users/' + userId, data);
  }

  get_mapped_users(practiceId?, searchKey?, pagesize?, offset?, showInternal?, enabled?) {
    if(practiceId) {
      let url = myGlobals.Practices_URL + '/' + practiceId + '/users?q=' + searchKey 
        + (pagesize ? '&size=' + pagesize : '') + (offset ? '&offset=' + offset : '') 
        + (showInternal? '&showinternal=true' : '');
      return this.http.get<any>(url);
    } else {
      return this.http.get<any>(myGlobals.Base_URL + '/currentpractice/users?q=' + searchKey + (pagesize ? '&size=' + pagesize : '') 
      +  '&offset=' + offset  + '&enabled='+enabled + ('&showinternal=' + showInternal));
    }
  }
  get_allmapped_practiceusers(practiceId) {
    if(practiceId) {
      let url = myGlobals.Practices_URL + '/mappedusers';
      return this.http.post<any>(url,practiceId);
    } 
  }
  // this is wrong -> 
  userStatusChange(data, fromPracticeSettings?) {
    if(fromPracticeSettings) {
      return this.http.put<any>(myGlobals.Base_URL + '/currentpractice/users/' + data.user_id , data);
    } else {
      return this.http.put<any>(myGlobals.User_URL + '/' + data.user_id + '/practices/' + data.practice_id, data);
    }
  }

  practiceUserStatusChange(data, fromPracticeSettings?) {
    if(fromPracticeSettings) {
      return this.http.put<any>(myGlobals.Base_URL + '/currentpractice/users/' + data.user_id +'/changestatus' , data);
    }
  }

  // Create a new provider
  addProvider(providerData, fromProviderDetail?) {
    let practiceId = providerData.practice_id;
    let url;
    if (fromProviderDetail) {
      url = myGlobals.Base_URL + '/currentpractice/providers';
    } else {
      url = myGlobals.Practices_URL + '/' + practiceId + '/providers';
    }
    return this.http.post<any>(url, providerData);
  }

  // Update the provider details
  updateProvider(providerData, fromProviderDetail?) {
    let practiceId = providerData.practice_id;
    let providerId = providerData.providerId;
    let data = JSON.parse(JSON.stringify(providerData));
    delete data.providerId;
    let url;
    if (fromProviderDetail) {
      url = myGlobals.Base_URL + '/currentpractice/providers/' + providerId;
    } else {
      url = myGlobals.Practices_URL + '/' + practiceId + '/providers' + '/' + providerId;
    }
    return this.http.put(url, data);
  }

  // search the list of taxonomy codes
  searchTaxonomy(searchkey) {
    if (searchkey == undefined) {
      searchkey = '';
    }
    return this.http.get<any>(myGlobals.Search_URL + '/taxonomy?q=' + searchkey);
  }

  providerStatusChange(data, fromProviderDetail?) {
    let url;
    if (fromProviderDetail) {
      url = myGlobals.Base_URL + '/currentpractice/providers/' + data.provider_id + '/status';
    } else {
      url = myGlobals.Practices_URL + '/' + data.practice_id + '/providers/' + data.provider_id + '/status';
    }
    return this.http.put<any>(url, data);
  }
  
  providerCodingChange(data) {
    let url = myGlobals.Base_URL + '/currentpractice/providers/' + data.provider_id + '/codingstatus';
    return this.http.put<any>(url, data);
  }

  fetchEnabledFacilitesForProvider(practiceId: String, providerId: String, enabled = 'any', mapped: boolean, fromProviderDetail?) {
    let url;
    if (fromProviderDetail) {
      url = myGlobals.Base_URL + '/currentpractice/providers/' + providerId + '/facilities?enabled=' + enabled + '&mapped=' + mapped + '&from=mapFacilityToProvider';
    } else {
      url = myGlobals.Practices_URL + '/' + practiceId + '/providers/' + providerId + '/facilities?enabled=' + enabled + '&mapped=' + mapped;
    }

    return this.http.get<any>(url);
  }

  bulkFacilitiesMapToProvider(practiceId: String, providerId: String, mappingData, fromProviderDetail?) {
    let req = {};
    req = { facilities: mappingData };
    let url;
    if (fromProviderDetail) {
      url = myGlobals.Base_URL + '/currentpractice/providers/' + providerId + '/facilities';
    } else {
      url = myGlobals.Practices_URL + '/' + practiceId + '/providers/' + providerId + '/facilities';
    }
    return this.http.post<any>(url, req);
  }

  fetchEnabledProvidersForFacility(practiceId: String, providerId: String, enabled = 'any', mapped: boolean, currentpracticefacilities?) {
    let url;
    if (currentpracticefacilities) {
      url = myGlobals.Base_URL + '/currentpractice/facilities/' + providerId + '/providers?enabled=' + enabled + '&mapped=' + mapped;
    } else {
      url = myGlobals.Practices_URL + '/' + practiceId + '/facilities/' + providerId + '/providers?enabled=' + enabled + '&mapped=' + mapped;
    }
    return this.http.get<any>(url);
  }

  bulkProvidersMapToFacility(practiceId: String, providerId: String, mappingData, currentpracticefacilities?) {
    let req = {};
    req = { facilities: mappingData };
    let url;
    if (currentpracticefacilities) {
      url = myGlobals.Base_URL + '/currentpractice/facilities/' + providerId + '/providers';
    } else {
      url = myGlobals.Practices_URL + '/' + practiceId + '/facilities/' + providerId + '/providers';
    }
    return this.http.post<any>(url, req);
  }

addProvidersMapToFacility(facility_id, provider_id) {
  return this.http.post<any>(myGlobals.Base_URL + '/currentpractice/facilities/' + facility_id + '/providers/' + provider_id, '');
}

removeProviderMapToFacility( facility_id, provider_id) {
 return this.http.delete<any>(myGlobals.Base_URL + '/currentpractice/facilities/' + facility_id + '/providers/' + provider_id);
}

addFacilityToProvider(provider_id, facility_id) {
  return this.http.post<any>(myGlobals.Base_URL + '/currentpractice/providers/' + provider_id + '/facility/' + facility_id, '')
}

removeFacilityToProvider(provider_id, facility_id) {
  return this.http.delete<any>(myGlobals.Base_URL + '/currentpractice/providers/' + provider_id + '/facility/' + facility_id)
}

  listSearchFacilities(practice_id, searchKey: String, status = 'any', loadPage?) {
    let url;
    if (loadPage) {
      url = myGlobals.Base_URL + '/currentpractice/facilities?q=' + searchKey + '&status=' + status + '&pagesize=' + loadPage.pagesize + '&offset=' + loadPage.offset;
    } else {
      url = myGlobals.Practices_URL + '/' + practice_id + '/facilities?q=' + searchKey + '&status=' + status;
    }
    return this.http.get<any>(url);
  }

  facilitiesStatusChange(facilityid, practice_id, data, currentpracticefacilities?) {
    let url;
    if (currentpracticefacilities) {
      url = myGlobals.Base_URL + '/currentpractice/facilities' + '/' + facilityid + '/status';
    } else {
      url = myGlobals.Practices_URL + '/' + practice_id + '/facilities' + '/' + facilityid + '/status';
    }
    return this.http.put<any>(url, data);
  }

  deactivateAllFacility(practiceId) {    
    return this.http.delete(myGlobals.Practices_URL + '/' + practiceId + '/facilities' + '/deactiveallfacility');
  }

  addFacilityService(practice_id, data, currentpracticefacilities?) {
    let url;
    if (currentpracticefacilities) {
      url = myGlobals.Base_URL + '/currentpractice/facilities/';
    } else {
      url = myGlobals.Practices_URL + '/' + practice_id + '/facilities/';
    }
    return this.http.post<any>(url, data);
  }

  viewFacility(practice_id, facilityid, currentpracticefacilities?) {
    let url;
    if (currentpracticefacilities) {
      url = myGlobals.Base_URL + '/currentpractice/facilities' + '/' + facilityid;
    } else {
      url = myGlobals.Practices_URL + '/' + practice_id + '/facilities' + '/' + facilityid;
    }
    return this.http.get<any>(url);
  }

  editFacility(practice_id, facilityid, data, currentpracticefacilities?) {
    let url;
    if (currentpracticefacilities) {
      url = myGlobals.Base_URL + '/currentpractice/facilities' + '/' + facilityid;
    } else {
      url = myGlobals.Practices_URL + '/' + practice_id + '/facilities' + '/' + facilityid;
    }
    return this.http.put<any>(url, data);
  }

  searchPlaceOfService(searchResult) {
    return this.http.get<any>(myGlobals.Base_URL + '/search' + '/searchPlaceOfService?q=' + searchResult + '&enabled=true');
  }

  updatePracticeOptions(practice_id, data) {
    return this.http.put<any>(myGlobals.Practices_URL + '/' + practice_id + '/practiceoptions', data);
  }
  updateexternalid(data,practice_id) {
    return this.http.put<any>(myGlobals.Practices_URL + '/' + practice_id + '/patient_360_integration', data);
  }


  npiProviderDataAPI(npi) {
    return this.http.get<any>(myGlobals.Base_URL + '/search' + '/nppes?npi=' + npi);
  }

  updatePracticeInfo(api_type, p_id, data, e_i) {
    return this.http.put<any>(myGlobals.Get_ServiceLayer_Url + '/practices/' + api_type + '/' + p_id + '/' + e_i, data);
  }

  policy_info_create(data) {
    if (data._id) {
      return this.http.put<any>(myGlobals.Base_URL + '/providercompensation/' + data._id, data);
    } else {
      return this.http.post<any>(myGlobals.Base_URL + '/providercompensation', data);
    }
  }

  update_policy_estimation(id, data) {
    return this.http.put<any>(myGlobals.Base_URL + '/providercompensation/estimation/' + id, data);
  }

  deactivate_policy(id) {
    return this.http.delete<any>(myGlobals.Base_URL + '/providercompensation/' + id);
  }

  policy_info_get(p_id) {
    return this.http.get<any>(myGlobals.Base_URL + '/providerCompensation/' + p_id);
  }

  policy_info_update(p_id, policy_id, data) {
    return this.http.put<any>(myGlobals.Base_URL + '/providerCompensation/' + p_id + '/' + policy_id, data);
  }

  getCurrentPracticeDetails(practice_id) {
    return this.http.get<any>(myGlobals.Practices_URL + '/' + practice_id);
  }

  addPracticeInvoice(data) {
    let url = myGlobals.Practices_URL + '/' + data.practice_id + '/invoice';
    return this.http.post<any>(url, data);
  }

  updatePracticeInvoice(data) {
    let url = myGlobals.Practices_URL + '/' + data.practice_id + '/invoice';
    return this.http.put<any>(url, data);
  }

  getPracticeInvoice(practiceid) {
    let url = myGlobals.Practices_URL + '/' + practiceid + '/invoice';
    return this.http.get<any>(url);
  }
  
  getPCCDetails(orgUuid, faceId){
    return this.http.get<any>(myGlobals.Base_URL + '/pccfacilities/settings/' + orgUuid+'/'+faceId);
  }

  removePccIntegration(facId){
    let url = myGlobals.Base_URL + '/pccfacilities/removeintegration';
    return this.http.put<any>(url, {facId:facId});
  }

  mapFacilityToProvider(practiceId, providerId, facilityId) {
    let url = myGlobals.Practices_URL + '/' + practiceId + '/providers/' + providerId + '/facility/' + facilityId;
    return this.http.post<any>(url, '');
  }

  deleteFacilityToProvider(practiceId, providerId, facilityId) {
    return this.http.delete<any>(myGlobals.Practices_URL + '/' + practiceId + '/providers/' + providerId + '/facility/' + facilityId);
  }


  mapProvidersToFacility(practice_id, facility_id, provider_id) {
    let url = myGlobals.Practices_URL + '/' + practice_id + '/facilities/' + facility_id +'/providers/'+ provider_id ;
    return this.http.post<any>(url,'');
  }
  deleteProvidersToFacility(practice_id, facility_id, provider_id) {
    return this.http.delete<any>(myGlobals.Practices_URL + '/' + practice_id + '/facilities/'+ facility_id + '/providers/'+ provider_id);
  }

  fetchEnabledFacilitesForProviderV2(practiceId: String, providerId: String, pagelimit, offset,searchString = null) {
    let url = myGlobals.Practices_URL + '/' + practiceId + '/providers/' + providerId + '/facilities/v2?q=' + searchString + '&pagelimit=' + pagelimit + '&offset=' + offset;
    return this.http.get<any>(url);
  }

  fetchEnabledProviderForFacilitesV2(practiceId: String, facility_id: String, pagelimit, offset,searchString = null) {
    let url = myGlobals.Practices_URL + '/' + practiceId + '/facilities/' + facility_id + '/providers/v2?q=' + searchString + '&pagelimit=' + pagelimit + '&offset=' + offset;
    return this.http.get<any>(url);
  }

  mapProvidersV2(facility_id: String, searchKey, pagelimit, offset) {
    let url = myGlobals.Base_URL + '/currentpractice/facilities/' + facility_id + '/providers/v2?q='+ searchKey + '&pagelimit=' + pagelimit + '&offset=' + offset;
    return this.http.get<any>(url);
  }

  mapFacilitiesV2(providerId: String, searchKey, pagelimit, offset) {
    let url = myGlobals.Base_URL + '/currentpractice/providers/' + providerId + '/facilities/v2?q='+ searchKey + '&pagelimit=' + pagelimit + '&offset=' + offset;
    return this.http.get<any>(url);
  }

  patient360IntegrationPracties(data) {
    let url = myGlobals.PATIENT360_URL  ;
    return this.http.post<any>(url,data);
  }

  getPatient360Details(practiceId: String) {
    let url = myGlobals.PATIENT360_URL + '/practice/' + practiceId;
    return this.http.get<any>(url);
  }
  updateExternalIds(facility_id,data){
    let url = myGlobals.Integration_URL +"/"+facility_id+"/updateexternalids";
    return this.http.put<any>(url,data);
  }
  mcProgressNote(data,practice_id) {
    return this.http.put<any>(myGlobals.Integration_URL + '/' + practice_id + '/mc_progress_note', data);
  }

  sigmaProgressNote(data, practice_id ) {
    return this.http.put<any>(myGlobals.Integration_URL + '/' + practice_id + '/sc_progressnotes', data);
  }

  updateSigmaExternalid (facility_id,data) {
    let url = myGlobals.Integration_URL + "/" + facility_id + "/sigmaexternalid";
    return this.http.put<any>(url,data);
  }
  updateSigmaCareid (practice_id,data) {
    let url = myGlobals.Integration_URL + '/' + practice_id + "/updatesigmacareids";
    return this.http.put<any>(url,data);
  }

  getSigmaCareIds(practiceId: String) {
    let url = myGlobals.Integration_URL + '/practice/' + practiceId;
    return this.http.get<any>(url);
  }
}

