import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root'
})
export class SharedPopupService {
  public popupRef: BsModalRef;
  public popupRefForNPI: BsModalRef;
  constructor(public modalService: BsModalService) {
  }

  show(template, option) {
    this.popupRef = this.modalService.show(template, option);
  }

  dismiss() {
    if (this.popupRef) {
      this.popupRef.hide();
    }
  }

  showNPI(template, option) {
    this.popupRefForNPI = this.modalService.show(template, option);
  }

  dismissNPI() {
    if (this.popupRef) {
      this.popupRefForNPI.hide();
    }
  }

}