import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as myGlobals from '../../globals';

/*Header start */
let headers = new Headers({ 'Content-Type': 'application/json' });
headers.append('Access-Control-Allow-Origin', '*');
//headers.append('authorization', localStorage.getItem('authToken'));
// let options = new RequestOptions({ headers: headers });
/*Header end */

@Injectable({
  providedIn: 'root'
})

export class PromeniService {

  constructor(private http: HttpClient) {
  }

  authenticate(data:any){
    let req={
      data
    }
    const url = myGlobals.PROMENI_URL +'/authtoken';
    return this.http.post<any>(url, req);
  }

  calculatePrediction(data:any) {
    if(data && data.length>0){
      //let url = "http://promenidatascience-env.eba-4fx6f3my.us-west-2.elasticbeanstalk.com/predict";
      //return this.http.post<any>(url, req, {header:headers});
      const url = myGlobals.PROMENI_URL + '/prediction/charge/'+data[0].ChargesID;
      return this.http.post<any>(url, data);
    }
    }
  }