import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(private http: HttpClient) { }

  searchpermission(searchKey, status) {
    let req = {};

    // if (searchKey == '') {
    //   searchKey = '*'
    // }
    let page = 1;
    let pagesize = 10;
    return this.http.get(myGlobals.Permissions_URL + '?q=' + searchKey + '&status=' + status + '&page=' + page + '&size=' + pagesize);
  }

  updatePermissionStatus(updateData) {
    let id = updateData._id;
    return this.http.put(myGlobals.Permissions_URL + '/status/' + id, updateData);
  }
  get_mapped_resources(id, size = 0) {
    return this.http.get<any>(myGlobals.Permissions_URL + '/' + id + '/resources?size=' + size);
  }
  get_mapped_roles(id, size = 0) {
    return this.http.get<any>(myGlobals.Permissions_URL + '/' + id + '/roles?size=' + size);
  }

  resource_link(permissionid, resources) {
    let req = {};
    req = { permissionid: permissionid, resources: resources };
    //console.log('reqreqreq', req);
    return this.http.post<any>(myGlobals.Permissions_URL + '/' + permissionid, req);
  }

  role_deactivate(permissionId, roleStatus, roleId) {
    let req = {};
    //console.log(permissionId, roleId, roleStatus);
    // req={permissionid:permissionId,status:roleStatus,roleid:roleId};
    req = {
      'roles': [
        {
          'role_id': roleId,
          'isCheck': roleStatus
        }
      ]
    };
    return this.http.post<any>(myGlobals.Permissions_URL + '/' + permissionId + '/roles', req);
  }

  searchBulkResources(searchKey, res_id) {
    // if (res_id!=null && res_id!='')
    //   {
    //     var enabled =false;
    //   }
    // return this.http.get<any>(myGlobals.Base_URL + '/permissions/'+ res_id +'/'+'practices?q=' + searchKey +'&enabled=' + "any")
    let req = {};
    req = { searchkey: searchKey };
    return this.http.get<any>(myGlobals.Permissions_URL + '/list/resources?q=' + searchKey);
  }

  resource_list(searchval) {
    let req = {};
    req = { searchkey: searchval };
    return this.http.get<any>(myGlobals.Permissions_URL + '/list/resources?q=' + searchval);
  }

  viewRoles(searchval) {
    return this.http.get<any>(myGlobals.Base_URL + '/roles?q=' + searchval + '&showInactive=false');
  }

  bulkResourcesInsert(PermissionId, mappingData) {
    let req = {};
    req = { resources: mappingData };
    return this.http.post<any>(myGlobals.Permissions_URL + '/' + PermissionId, req);
  }
  bulkRolesLink(PermissionId, roles) {
    return this.http.post<any>(myGlobals.Permissions_URL + '/' + PermissionId + '/roles', roles);
  }
  addPermission(permissionData) {
    let req = {};
    req = {
      'permission_name': permissionData.permission_name,
      'permission_description': permissionData.permission_description,
      'enable': true
    };
    return this.http.post<any>(myGlobals.Permissions_URL, req);
  }
  editPermission(permissionData) {
    let id = permissionData.id;
    return this.http.put<any>(myGlobals.Permissions_URL + '/' + id, permissionData);
  }

  addPermissions(resourceid: string, permissionsid: any) {
    return this.http.post(`${myGlobals.Resource_URL}${resourceid}/permissions/${permissionsid}`,{});
  }

  deletePermissions(resourceid: string, permissionsid: any) {
    return this.http.delete(`${myGlobals.Resource_URL}${resourceid}/permissions/${permissionsid}`);
  }

  deletePermissionrole(permssion_id,role_id){
    return this.http.delete<any>(myGlobals.Role_URL + '/' +role_id +'/permissions/' +permssion_id);
  }

  addPermissionrole(permssion_id,role_id){
    return this.http.post<any>(myGlobals.Role_URL + '/' +role_id +'/permissions/' +permssion_id,null);
  }
}
