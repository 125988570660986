import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingComponent } from './setting.component';
import { TaskstatusesComponent } from './taskstatuses/taskstatuses.component';
import { UserComponent } from './users/user.component';
import { PracticeAccountsComponent } from './practice-accounts/practice-accounts.component';
import { ProceduresComponent } from './codesets/procedures/procedures.component';
import { DiagnosisCodesComponent } from './codesets/diagnosis-codes/diagnosis-codes.component';
import { RolesComponent } from './security/roles/roles.component';
import { PermissionsComponent } from './security/permissions/permissions.component';
import { ResourcesComponent } from './security/resources/resources.component';
import { TasktypesComponent } from './tasktypes/tasktypes.component';
import { LoginHistoryComponent } from './../settings/login-history/login-history.component';
import { CustomListComponent } from './custom-list/custom-list.component'
import { FeaturesComponent } from './security/features/features.component';
import { BulkCodeUploadesComponent } from './codesets/bulk-code-uploades/bulk-code-uploades.component';
import { SkillsComponent } from './skills/skills.component';
import { StatusCodesComponent } from './status-codes/status-codes.component';
import { ActionCodesComponent } from './action-codes/action-codes.component';
import { ProcedureModifiersComponent } from './codesets/procedure-modifiers/procedure-modifiers.component';
import { PptModifiersComponent } from './ppt-modifiers/ppt-modifiers.component';
import { MUEComponent } from './mue/mue.component';
import { InventoryListComponent } from './inventory-list/inventory-list.component';


const routes: Routes = [
  {
    path: '',
    // redirectTo: '/heroes', pathMatch: 'full'
    component: SettingComponent,
    data: {
      title: 'Administration',
      permissions: ['Portal Settings Access', 'Portal Settings Admin'],
    },
    children: [
      {
        path: '',
        redirectTo: 'taskstatus', pathMatch: 'full'
      },
      {
        path: 'taskstatus',
        component: TaskstatusesComponent,
        pathMatch: 'full',
        data: {
          title: 'Task Statuses',
          permissions: ['Portal Task Management Read', 'Portal Task Management Admin'],
        }
      },
      {
        path: 'tasktypes',
        component: TasktypesComponent,
        pathMatch: 'full',
        data: {
          title: 'Task Types',
          permissions: ['Portal Task Management Read', 'Portal Task Management Admin'],
        }
      },
      {
        path: 'user',
        component: UserComponent,
        pathMatch: 'full',
        data: {
          title: 'Users',
          permissions: ['Portal User Management Read', 'Portal User Management Admin']
        }
      },
      {
        path: 'login-history',
        component: LoginHistoryComponent,
        pathMatch: 'full',
        data: {
          title: 'Login History',
          permissions: ['Portal User Management Read', 'Portal User Management Admin']
        }
      },
      {
        path: 'skills',
        component: SkillsComponent,
        pathMatch: 'full',
        data: {
          title: 'Skills',
          permissions: ['Portal User Management Read', 'Portal User Management Admin']
        }
      },
      {
        path: 'practice-accounts',
        component: PracticeAccountsComponent,
        pathMatch: 'full',
        data: {
          title: 'Practice Accounts',
          permissions: ['Portal Practice Management Read', 'Portal Practice Management Admin']
        }
      },
      {
        path: 'procedure-codes',
        component: ProceduresComponent,
        pathMatch: 'full',
        data: {
          title: 'Procedure codes',
          permissions: ['Portal Codeset Management Read', 'Portal Codeset Management Admin']
        }
      },
      {
        path: 'procedure-modifiers',
        component: ProcedureModifiersComponent,
        pathMatch: 'full',
        data: {
          title: 'Procedure Modifiers',
          permissions: ['Portal Codeset Management Read', 'Portal Codeset Management Admin']
        }
      },
      {
        path: 'diagnosis-codes',
        component: DiagnosisCodesComponent,
        pathMatch: 'full',
        data: {
          title: 'Diagnosis codes',
          permissions: ['Portal Codeset Management Read', 'Portal Codeset Management Admin']
        }
      },
      {
        path: 'permissions',
        component: PermissionsComponent,
        pathMatch: 'full',
        data: {
          title: 'Permissions',
          permissions: ['Portal Permission Management Read', 'Portal Permission Management Admin']
        }
      },
      {
        path: 'roles',
        component: RolesComponent,
        pathMatch: 'full',
        data: {
          title: 'Roles',
          permissions: ['Portal Roles Management Read', 'Portal Roles Management Admin']
        }
      },
      {
        path: 'resources',
        component: ResourcesComponent,
        pathMatch: 'full',
        data: {
          title: 'Resources',
          permissions: ['Portal Resource Management Read', 'Portal Resource Management Admin']
        }
      },
      {
        path: 'features',
        component: FeaturesComponent,
        pathMatch: 'full',
        data: {
          title: 'Features',
          permissions: ['Portal Features Management Read', 'Portal Features Management Admin']
        }
      },
      {
        path: 'custom-list',
        component: CustomListComponent,
        pathMatch: 'full',
        data: {
          title: 'Custom list',
          permissions: ['Portal Resource Management Read', 'Portal Resource Management Admin']
        }
      },
      {
        path: 'mue',
        component: MUEComponent,
        pathMatch: 'full',
        data: {
          title: 'MUE Modifier',
          permissions: ['Portal Codeset Management Read', 'Portal Codeset Management Admin']
        }
      },
      {
        path: 'bulk-code-uploads',
        component: BulkCodeUploadesComponent,
        pathMatch: 'full',
        data: {
          title: 'Bulk Code Uploads',
          permissions: ['Portal Codeset Management Admin']
        }
      },
      {
        path: 'status_codes',
        component: StatusCodesComponent,
        pathMatch: 'full',
        data: {
          title: 'Status Codes',
          permissions: ['Portal Codeset Management Admin']
        }
      },
      {
        path: 'action_codes',
        component: ActionCodesComponent,
        pathMatch: 'full',
        data: {
          title: 'Action Codes',
          permissions: ['Portal Codeset Management Admin']
        }
      },
      {
        path: 'globex-integrate',
        component: InventoryListComponent,
        pathMatch: 'full',
        data: {
          title: 'Globex Integrate',
          permissions: ['Portal Codeset Management Admin']
        }
      },
      {
        path: 'ppt_modifiers',
        component: PptModifiersComponent,
        pathMatch: 'full',
        data: {
          title: 'PTP',
          permissions: ['Portal Codeset Management Admin PTP']
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
