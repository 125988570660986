import { Component, TemplateRef, OnInit, HostListener, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ProceduremodifiersService } from '../../../../_services/settings/proceduremodifiers.service';
import { SharedService } from '../../../../_services/shared.service';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs/Rx';
import { SharedPopupService } from '../../../../_services/popup.service';
import constants from '../../../../constants';
import regiondateformat from '../../../../regiondateformat';


class Modifier {
  modifier: String;
  category: String;
  description: String;
  enabled: Boolean;
  from_date: string;
  to_date: string;
}
@Component({
  selector: 'app-procedure-modifiers',
  templateUrl: './procedure-modifiers.component.html',
  styleUrls: ['./procedure-modifiers.component.scss']
})
export class ProcedureModifiersComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  public modifier: Modifier;


  disableBtnFlag: boolean;
  addStatusMessage: string;
  public dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  procedureModifierlist: any;
  searchtext: String = '';
  messageStatus: string;
  showInactive: boolean;
  inactiveFlag: boolean;
  popupMode: String;
  cloneTmpProcedureModifier: any;
  date_validation: any;
  procedureIndex: number;
  from_date: any;
  to_date: any;
  popupTitle: String;
  categoryList: any;
  ConfirmPopupfrom: any;
  procedureModifier: any;
  modifierStatus: any;
  userIndex: any;
  messageInfo: String;
  innerWidth: number;
  mobileviewTaskcontent: boolean;
  diagnosisModifierAddPage: number;
  allPaginationDefaultLimit: number;
  diagnosisModifierAddCurrentPage: number;
  diagnosisModifierAddPageskip: number;
  diagnosisModifierAddTotalCount: number;
  procedureModifierAddTotalCount: string[];
  ModifierStatus: any;
  Modifier: any;
  loaderIndicator:boolean;
  constants:any;
  country:any
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    this.screenValues();
  }

  constructor(public bsModalService: BsModalService, public SharedPopupService: SharedPopupService, public procedureModifiersService: ProceduremodifiersService, private sharedService: SharedService, public modalService: BsModalService) {
    this.disableBtnFlag = true;
    this.categoryList = ['Level-I', 'Level-II'];
    this.searchtext = '';
    this.inactiveFlag = true;
    this.diagnosisModifierAddTotalCount = 0;
    this.diagnosisModifierAddPage = 0;
    this.diagnosisModifierAddCurrentPage = 0;
    this.diagnosisModifierAddPageskip = 0;
    this.allPaginationDefaultLimit = 20;
    this['valideerrordatefrom'] = false;
    this['valideerrordateto'] = false;
    this.loaderIndicator=true;
    this.constants=constants;
  }

  ngOnInit() {
    this.modifier = {
      modifier: '',
      category: '',
      description: '',
      enabled: true,
      from_date: '',
      to_date: ''
    };
    this.searchModifier();
    this.country = localStorage.getItem('Region');
  }

  validateProcedureModifier(model: Modifier, isValid: boolean, myform: NgForm) {
    if (isValid && this.date_validation == false) {
      this.modifier = {
        modifier: model.modifier.trim(),
        category: model.category ? model.category : null,
        description: model.description.trim(),
        enabled: ('addProcedureModifier' == this.popupMode) ? true : this.modifier.enabled,
        from_date: model.from_date ? model.from_date : null,
        to_date: model.to_date ? model.to_date : null
      };
      console.log(this.modifier,'modifier');
      
      isValid && ('addProcedureModifier' == this.popupMode ? this.addProcedureModifiers(this.modifier) : 'editProcedureModifier' == this.popupMode && this.editProcedureModifiers(this.modifier));
    }
  }
  activeStatus() {
    this.inactiveFlag = (this.showInactive == true) ? true : false;
    this.searchModifier();
  }
  // page list
  searchModifier(type = '') {
    this.loaderIndicator=true;
    this.procedureModifierlist=[]
    this.ngUnsubscribe.next();
    if (type === '') {
      this.resetAllPagination();
    }
    this.procedureModifiersService.searchProcedureModifiers(this.searchtext, this.inactiveFlag, this.diagnosisModifierAddCurrentPage, 20).debounceTime(400).takeUntil(this.ngUnsubscribe).subscribe((data: any) => {
      this.loaderIndicator=false;
      this.procedureModifierlist = data.results;
      this.procedureModifierAddTotalCount = data.totalCount;
    });
  }
  procedureModifierAddPageChanged(ev) {
    this.diagnosisModifierAddCurrentPage = ev.page * this.allPaginationDefaultLimit - this.allPaginationDefaultLimit;
    this.searchModifier('pagination');
  }
  resetAllPagination() {
    this.diagnosisModifierAddPage = 0;
    this.diagnosisModifierAddCurrentPage = 0;
    this.diagnosisModifierAddPageskip = 0;
    this.diagnosisModifierAddTotalCount = 0;
  }

  ConfirmPopup(Confirmtemplate: TemplateRef<any>, Modifier, i) {
    this.Modifier = Modifier;
    this.userIndex = i;
    this.messageStatus = (this.Modifier.enabled == true) ? ' Deactivate ' : ' Activate ';
    this.messageInfo = 'Are you sure want to' + this.messageStatus + 'Procedure Modifier ?';
    this.SharedPopupService.show(Confirmtemplate, {
      animated: true,
      backdrop: 'static',
    });
  }
  onConfirm() {
    this.deactiveStatus();
  }
  onCancel() {
    this.SharedPopupService.dismiss();
  }
  closePopup() {
    this.SharedPopupService.dismiss();
  }
  deactiveStatus() {
    this.procedureModifiersService.changeStatus(this.Modifier._id, this.Modifier.enabled == true ? false : true).subscribe((data: any) => {
      this.SharedPopupService.dismiss();
      this.procedureModifierlist.splice(this.userIndex, 1);
      if (data) {
        this.searchModifier();
        this.sharedService.pushToaster('success', 'Success', 'Procedure Modifier' + this.messageStatus + ' Successfully');
      }
    });
  }
  validationMessage() {
    'success' == this.addStatusMessage ? this.sharedService.pushToaster('success', 'Added', 'New procedure code added successfully.') : 'error' == this.addStatusMessage ? this.sharedService.pushToaster('error', 'Already Exists', 'Procedure Modifier Already Exists.') : 'update' == this.addStatusMessage && this.sharedService.pushToaster('success', 'Updated', 'procedure Modifier updated successfully.');
  }
  screenValues() {
    if (this.innerWidth < 768) {
      // mobile
      this.mobileviewTaskcontent = false;
    } else if (this.innerWidth < 1200) {
      // big tablet screen or laptop
      this.mobileviewTaskcontent = true;
    } else {
      // desktop
      this.mobileviewTaskcontent = true;
    }
  }

  /* Add New Modifier in the list */
  addProcedureModifiers(modifier: any) {
    if (this.modifier.from_date || this.modifier.to_date) {
      this.modifier.from_date = this.modifier.from_date ? this.modifier.from_date: '';
      this.modifier.to_date = this.modifier.to_date ? this.modifier.to_date: '';
    }
    this.procedureModifiersService.addProcedureModifiers(modifier).subscribe((response: any) => {
      let res = response;
      if ('success' == res.status) {
        this.addStatusMessage = 'success';
        if (res.status == 'success') {
          if (this.inactiveFlag == true) {
            this.procedureModifierlist.unshift(res.result);
          }
          this.sharedService.pushToaster('success', 'Added', 'New Modifier added successfully.');
          this.SharedPopupService.dismiss();
        }
      } else {
        this.addStatusMessage = 'error';
        this.sharedService.pushToaster('error', 'Error', res.message);
      }
    });
  }
  /* Edit the Procedure Modifier */
  editProcedureModifiers(modifier) {
    let copyModifiers = JSON.parse(JSON.stringify(modifier));
    if (copyModifiers.from_date || copyModifiers.to_date) {
      copyModifiers.from_date = copyModifiers.from_date ? copyModifiers.from_date: '';
      copyModifiers.to_date = copyModifiers.to_date ?  copyModifiers.to_date: '';
    }

    this.procedureModifiersService.editProcedureModifiers(this.cloneTmpProcedureModifier._id, copyModifiers).subscribe((response: any) => {
      if (response.status == 'success') {
        copyModifiers._id = this.cloneTmpProcedureModifier._id;
        this.procedureModifierlist[this.procedureIndex] = copyModifiers;
        this.SharedPopupService.dismiss();
        this.sharedService.pushToaster('success', 'Success', 'Modifier updated successfully');
      }else {
        this.sharedService.pushToaster('error', 'Error', response.message);
      }
    });
  }
  openPopup(template: TemplateRef<any>, popupMode: String, listItem: any, procedureIndex = null) {
    this.popupMode = popupMode;
    this.cloneTmpProcedureModifier = listItem;
    this.procedureIndex = procedureIndex;
    this.date_validation = '';
    this.from_date = '';
    this.to_date = '';
    this['valideerrordatefrom'] = true;
    this['valideerrordateto'] = true;
    if ('addProcedureModifier' == this.popupMode) {
      this.popupTitle = 'Add new Procedure Modifier';
      this.modifier = {
        modifier: '',
        category: '',
        description: '',
        enabled: true,
        from_date: '',
        to_date: ''
      };
    } else if ('editProcedureModifier' == this.popupMode) {
      this.popupTitle = 'Edit Procedure Modifier';
      if (listItem.from_date && listItem.from_date != null && listItem.from_date != 'Invalid date') {
        listItem.from_date = listItem.from_date
      } else {
        listItem.from_date = '';
      }
      if (listItem.to_date && listItem.to_date != null && listItem.to_date != 'Invalid date') {
        listItem.to_date =listItem.to_date
      } else {
        listItem.to_date = '';
      }
      this.modifier = {
        modifier: listItem.modifier,
        category: listItem.category,
        description: listItem.description,
        enabled: listItem.enabled,
        from_date: listItem.from_date,
        to_date: listItem.to_date
      };
      this.datechangedata(listItem.to_date, 'dateto', '');
    }
    this.SharedPopupService.show(template, { animated: true, backdrop: 'static', keyboard: false });
  }
  datechangedata(value, from, index) {
    let dateSplit = value.split('/');
    let datePattern = '^[0-9]{2}/[0-9]{2}/[0-9]{4}$';
    let regExVal = new RegExp(datePattern);
    let patternMatchBool = regExVal.test(value);
    if (from == 'datefrom') {
      this.from_date = regiondateformat(value);
    }
    if (from == 'dateto') {
      this.to_date = regiondateformat(value);
    }
    if (this.popupMode == 'editProcedureModifier') {
      if (this.modifier.from_date != null) {
        this.from_date = regiondateformat(this.modifier.from_date);
      }
      if (this.modifier.to_date != null) {
        this.to_date = regiondateformat(this.modifier.to_date);
      }
      this.date_validation = moment(this.to_date).diff(this.from_date);
      this.date_validation = (this.date_validation < 0) ? true : false;
    } else {
      if (this.from_date != 'Invalid date' && this.from_date != undefined && this.from_date != '' && this.to_date != 'Invalid date' && this.to_date != undefined && this.to_date != '') {
        this.date_validation = moment(this.to_date).diff(this.from_date);
        this.date_validation = (this.date_validation < 0) ? true : false;
      }
    }
    if (value != undefined && this.country=='US') {
      let date = moment(value, 'MM/DD/YYYY');      
      let dynamicvar = 'valideerror' + from;
      this[dynamicvar] = date.isValid();
      if (dateSplit[2] == '0000' || patternMatchBool == false) {
        this[dynamicvar] = false;
      }
    }else if(this.country == "UAE"){      
      let date = moment(value, 'DD/MM/YYYY');
      let dynamicvar = 'valideerror' + from;
      this[dynamicvar] = date.isValid();
      if (dateSplit[2] == '0000' || patternMatchBool == false) {
        this[dynamicvar] = false;
      }
    }
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.SharedPopupService.dismiss();
  }
}
