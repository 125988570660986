interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  data?: object;
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
  permissions?: string[];
  features?: string[];
}

export const navItems: NavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fal fa-tachometer'
  },
  {
    name: 'Tasks',
    url: '/task',
    icon: 'fal fa-clipboard-list-check',
    permissions: ['Portal Task Read', 'Portal Task Admin'],
    children: [
      {
        name: 'Assigned to me',
        url: '/task/assigned-to-me',
        icon: 'fal fa-clipboard-list-check',
        data: { 'urlFilter': 'assigned-to-me' },
        permissions: ['Portal Task Assigned to me']
      },
      {
        name: 'All unsolved',
        url: '/task/all-unsolved',
        icon: 'fal fa-clipboard-list-check',
        data: { 'urlFilter': 'all-unsolved' },
        permissions: ['Portal Task All unsolved']
      },
      {
        name: 'All unassigned',
        url: '/task/all-unassigned',
        icon: 'fal fa-clipboard-list-check',
        data: { 'urlFilter': 'all-unassigned' },
        permissions: ['Portal Task All unassigned']
      },
      {
        name: 'High priority',
        url: '/task/high-priority',
        icon: 'fal fa-clipboard-list-check',
        data: { 'urlFilter': 'high-priority' },
        permissions: ['Portal Task High priority']
      },
      {
        name: 'Recently updated',
        url: '/task/recently-updated',
        icon: 'fal fa-clipboard-list-check',
        data: { 'urlFilter': 'recently-updated' },
        permissions: ['Portal Task Recently updated']
      },
      {
        name: 'Recently closed',
        url: '/task/recently-closed',
        icon: 'fal fa-clipboard-list-check',
        data: { 'urlFilter': 'recently-closed' },
        permissions: ['Portal Task Recently closed']
      }
    ]
  },
  {
    name: 'Reports',
    icon: 'fal fa-user-chart',
    url: '/reports-parent',
    permissions: ['Portal Report View', 'Portal Report Admin'],
    children: [
      {
        name: 'Task',
        url: '/taskdashboard',
        icon: 'fal fa-clipboard-list-check',
        permissions: ['Portal Task Report View']
      },
      {
        name: 'Patient Report',
        url: '/reports/patientreport',
        icon: 'fal fa-clipboard-list-check',
        permissions: ['Portal Patient Report View']
      },
      {
        name: 'Validation',
        url: '/reports/validation-dashboard',
        icon: 'fal fa-clipboard-list-check',
        permissions: ['Portal Validation Report View']
        // children: [
        //   {
        //     name: 'Taskuuuu',
        //     url: '/taskdashboard',
        //     icon: 'fal fa-clipboard-list-check',
        //     permissions : ['Rule Usefbgfghs']
        //   }
        // ]
      },
      {
        name: 'Charge Report',
        url: '/reports/charge-dashboard',
        icon: 'fal fa-clipboard-list-check',
        permissions: ['Portal Charge Report View']
      },
      {
        name: 'ART Report',
        url: '/reports/art-dashboard',
        icon: 'fal fa-clipboard-list-check',
        // permissions: ['Portal Art Report View']
      },
      {
        name: 'User Tracking',
        url: '/reports/user-tracking',
        icon: 'fal fa-clipboard-list-check',
        permissions: ['Portal User Tracking View']
      },
    ]
  },
  {
    name: 'Batches',
    url: '/batches',
    icon: 'fal fa-file-import',
    permissions: ['Portal Batches Read', 'Portal Batches Admin']
  },
  {
    name: 'Skillset Approval',
    url: '/skillsetapproval',
    icon: 'fal fa-clipboard-list-check',
    permissions: ['Portal Skillset Approval']
  },
  {
    name: 'TaskAssign',
    url: '/task-assign',
    icon: 'fal fa-user-tag',
    permissions: ['Portal TaskAssign Read', 'Portal TaskAssign Admin']
  },
  {
    name: 'Doctor now',
    url: '/doctor-now',
    icon: 'fal fa-file-exclamation',
    permissions: ['Portal Doctornow Admin']
  },
  {
    name: 'Patients',
    url: '/patients',
    icon: 'fal fa-address-book',
    permissions: ['Portal Patient Read', 'Portal Patient Admin']
  },
  {
    name: 'Charges',
    url: '/charges',
    icon: 'fal fa-file-invoice-dollar',
    permissions: ['Portal Charges Read', 'Portal Charges Admin']
  },
  {
    name: 'Invoicing',
    url: '/invoicing',
    icon: 'fal fa-file-invoice-dollar',
    permissions: ['Practice owner/partner']
  },
  {
    name: 'Administration',
    icon: 'fal fa-cog',
    url: '/settings-parent',
    permissions: ['Portal Settings Access', 'Portal Settings Admin'],
    children: [
      {
        title: true,
        name: 'Task Management',
        permissions: ['Portal Task Management Read', 'Portal Task Management Admin'],
      },
      {
        name: 'Task Statuses',
        url: '/settings/taskstatus',
        icon: 'fal fa-cog',
        permissions: ['Portal Task Management Read', 'Portal Task Management Admin'],
      },
      {
        name: 'Task Types',
        url: '/settings/tasktypes',
        icon: 'fal fa-cog',
        permissions: ['Portal Task Management Read', 'Portal Task Management Admin']
      },
      {
        title: true,
        name: 'USERS AND ACCOUNTS'
      },
      {
        name: 'Practice Accounts',
        url: '/settings/practice-accounts',
        icon: 'fal fa-cog',
        permissions: ['Portal Practice Management Read', 'Portal Practice Management Admin']
      },
      {
        name: 'Users',
        url: '/settings/user',
        icon: 'fal fa-cog',
        permissions: ['Portal User Management Read', 'Portal User Management Admin']
      },
      {
        name: 'Login History',
        url: '/settings/login-history',
        icon: 'fal fa-cog',
        permissions: ['Portal User Management Read', 'Portal User Management Admin']
      },
      {
        name: 'Skills',
        url: '/settings/skills',
        icon: 'fal fa-cog',
        permissions: ['Portal User Management Read', 'Portal User Management Admin']
      },
      {
        title: true,
        name: 'ROLES AND PERMISSIONS'
      },
      {
        name: 'Resources',
        url: '/settings/resources',
        icon: 'fal fa-cog',
        permissions: ['Portal Resource Management Read', 'Portal Resource Management Admin']
      },
      {
        name: 'Permissions',
        url: '/settings/permissions',
        icon: 'fal fa-cog',
        permissions: ['Portal Permission Management Read', 'Portal Permission Management Admin']
      },
      {
        name: 'Roles',
        url: '/settings/roles',
        icon: 'fal fa-cog',
        permissions: ['Portal Roles Management Read', 'Portal Roles Management Admin']
      },
      {
        name: 'Features',
        url: '/settings/features',
        icon: 'fal fa-cog',
        permissions: ['Portal Features Management Read', 'Portal Features Management Admin']
      },
      {
        title: true,
        name: 'CODESETS',
        permissions: ['Portal Codeset Management Read', 'Portal Codeset Management Admin']
      },
      {
        name: 'Bulk code uploads',
        url: '/settings/bulk-code-uploads',
        icon: 'fal fa-cog',
        permissions: ['Portal Codeset Management Admin']
      },
      {
        name: 'Procedure codes',
        url: '/settings/procedure-codes',
        icon: 'fal fa-cog',
        permissions: ['Portal Codeset Management Read', 'Portal Codeset Management Admin']
      },
      {
        name: 'Diagnosis codes',
        url: '/settings/diagnosis-codes',
        icon: 'fal fa-cog',
        permissions: ['Portal Codeset Management Read', 'Portal Codeset Management Admin']
      },
      {
        name: 'Procedure Modifiers',
        url: '/settings/procedure-modifiers',
        icon: 'fal fa-cog',
        permissions: ['Portal Codeset Management Read', 'Portal Codeset Management Admin']
      },
      {
        name: 'MUE',
        url: '/settings/mue',
        icon: 'fal fa-cog',
        permissions: ['Portal Codeset Management Read', 'Portal Codeset Management Admin']
      },
      {
        name: 'Custom list',
        url: '/settings/custom-list',
        icon: 'fal fa-cog',
        permissions: ['Portal Codeset Management Read', 'Portal Codeset Management Admin']
      }, {
        title: true,
        name: 'ART',
        permissions: ['Portal Codeset Management Read', 'Portal Codeset Management Admin']
      }, {
        name: 'Status Codes',
        url: '/settings/status_codes',
        icon: 'fal fa-cog',
        permissions: ['Portal Codeset Management Admin']
      },
      {
        name: 'Action Codes',
        url: '/settings/action_codes',
        icon: 'fal fa-cog',
        permissions: ['Portal Codeset Management Admin']
      },
      {
        name: 'Globex Integrate',
        url: '/settings/globex-integrate',
        icon: 'fal fa-cog',
        permissions: ['Portal Codeset Management Admin']
      }
    ]
  },
  {
    name: 'Practice Setting',
    icon: 'fal fa-user-cog',
    url: '/practice-setting',
    permissions: ['Practice Setting Admin'],
    children: [
      {
        name: 'Practice',
        url: '/practice-setting/practice',
        icon: 'fal fa-user-cog',
        permissions: ['Practice Setting Admin']
      },
      {
        name: 'Providers',
        url: '/practice-setting/provider',
        icon: 'fal fa-user-md',
        permissions: ['Practice Setting Admin']
      },
      {
        name: 'Facilities',
        url: '/practice-setting/facility',
        icon: 'fal fa-building',
        permissions: ['Practice Setting Admin']
      },
      {
        name: 'Users',
        url: '/practice-setting/user',
        icon: 'fal fa-user',
        permissions: ['Practice Setting Admin']
      },
      {
        name: 'Insurance Payers',
        url: '/practice-setting/insurence-payers',
        icon: 'fal fa-shield-alt',
        permissions: ['Practice Setting Admin']
      },
      {
        name: 'Appointment Types',
        url: '/practice-setting/appointment-types',
        icon: 'fal fa-calendar-alt',
        permissions: ['Practice Setting Admin'],
        features: ['appointmentTypes']
      }
    ]
  },
  {
    name: 'Rules',
    url: '/rules',
    icon: 'fal fa-user-shield',
    permissions: ['Portal Rule Users']
  },
  
  {
    name: 'Bulk Uploads View',
    url: '/bulk-uploads-view',
    icon: 'fal fa-upload',
    //permissions: ['Portal Codeset Management Read', 'Portal Codeset Management Admin', 'code set approver']
    permissions: ['Portal Bulk Uploads']
  },
  {
    name: 'Timesheet',
    url: '/timesheet',
    icon: 'fal fa-clock',
    permissions: ['Portal Timesheet Users']
  }
];
