import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '../../globals';
@Injectable({
  providedIn: 'root'
})
export class PtpModifierService {

  constructor(private http:HttpClient) { }
  
// List of Modifiers
  ptpModifierList(search_query,status, page= 0, limit= 20) {
    return this.http.get<any>(myGlobals.Base_URL + '/ptpmodifier?q=' + search_query + '&status=' + status + '&page=' + page + '&pagesize=' + limit );
  }
// Add the Code
  addPtpCode(code) {
    return this.http.post<any>(myGlobals.Base_URL + '/ptpmodifier' + '/addcode', code);
  }

  // Add the Modifier
  addPtpmodifer(modifier) {
    return this.http.post<any>(myGlobals.Base_URL +'/ptpmodifier' +'/addModifier', modifier)
  }

  activateCode(code_id,data) {
    return this.http.put<any>(myGlobals.Base_URL + '/ptpmodifier/'+code_id , data);
  }
 
  activateModifiers(code_id,modifier_id,modifier) {
    return this.http.put<any>(myGlobals.Base_URL +'/ptpmodifier/'+ code_id + '/modifier/'+modifier_id, modifier)
  }
    deactivateCode(code_id,code){
      return this.http.delete<any>(myGlobals.Base_URL + '/ptpmodifier/'+code_id , code)
    }
    deactivateModifiers(code_id,modifier_id,modifier) {
      return this.http.delete<any>(myGlobals.Base_URL +'/ptpmodifier/'+ code_id + '/modifier/'+modifier_id, modifier)
    }
}
