import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class TaskstatusesService {

  constructor(private http: HttpClient) { }
  task_statuses_list(str): any {
    let url = myGlobals.Settings_URL + '/taskstatus?task_status_name=' + str;
    return this.http.get(url);
  }
  add_new_reason(task_id, data): any {
    let req = {};
    let url = myGlobals.Settings_URL + '/taskstatus/' + task_id + '/reason';
    return this.http.post(url, data);
  }
  // deacivate_all_reason(id,title):any{
  //   var url=myGlobals.Settings_URL+"/taskstatus/"+id+"/reason?reasoncode_title="+title;
  //   return this.http.delete(url);
  // }
  deactivate_all_reason(id, title): any {
    let url = myGlobals.Settings_URL + '/taskstatus/' + id + '/reason/status/false';
    let data = { 'reasoncode_title': title };
    return this.http.put<any>(url, data);
  }
  // deactivate_status(id,task_status):any{
  //   let url=myGlobals.Settings_URL+"/taskstatus/"+id+'?task_status='+task_status;
  //   return this.http.delete(url,task_status);
  // }
  deactivate_status(id, task_status): any {
    let url = myGlobals.Settings_URL + '/taskstatus/' + id + '/status/' + task_status;
    return this.http.put<any>(url, '');
  }
  get_task_status(): any {
    // var url=myGlobals.Settings_URL+ '/taskstatus?task_status_name=';
    // return this.http.put(url,req);
  }
  edit_Reason_save(id, title, data): any {
    let url = myGlobals.Settings_URL + '/taskstatus/' + id + '/reason?reasoncode_title=' + title;
    return this.http.put(url, data);
  }
  edit_Status_save(id, data): any {
    let url = myGlobals.Settings_URL + '/taskstatus/' + id;
    return this.http.put(url, data);
  }
  add_new_task_status(data) {
    let url = myGlobals.Settings_URL + '/taskstatus';
    return this.http.post<any>(url, data);
  }
  update_task_status_flow(id: String, data: any): any {
    let url = myGlobals.Settings_URL + '/taskstatus/' + id + '/transition_state/'+data._id;
    return this.http.post(url, {});
  }
  delete_task_status_flow(id: String, taskID: any): any {
    let url = myGlobals.Settings_URL + '/taskstatus/' + id + '/transition_state/' +taskID;
    return this.http.delete(url);
  }
}
