import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UsersService } from "../../../_services/settings/users.service";
import { SharedService } from "../../../_services/shared.service";
import constants from '../../../constants';
import * as _ from 'lodash';
@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class InventoryListComponent implements OnInit {
  constants:any;
  loaderIndicator:any;
  searchData:any;
  inventoryList:any=[];
  globalinventoryList:any=[];
  isLoading:boolean=false;
  constructor(private userService: UsersService,  private sharedService: SharedService) { 
    this.constants=constants;
  }

  ngOnInit(): void {
    this.loaderIndicator=true;
    this.inventoryList=[];
    this.globalinventoryList=[];
    this.inventoryOnload('');
  }
  syncUser(){
    this.isLoading=true;
    this.userService.syncUserInfo().subscribe(res=>{
      this.isLoading=false;
      if(res.status=='success')
      {
       this.inventoryOnload('');
       // this.inventoryList=res.results;
        this.sharedService.pushToaster(
          "success",
          "Success",
          res.message
        );
      }
      else{
        this.sharedService.pushToaster(
          "warning",
          "Warning",
          res.message
        );
      }
    })
  }
  filterInventory(value){
    if(value=='Manual'){
      this.inventoryList=[];
      this.inventoryList=this.globalinventoryList.filter(item => item.syncType === 'Manual');
    } else if(value=='Automatic') {
      this.inventoryList=[];
      this.inventoryList=this.globalinventoryList.filter(item => item.syncType === 'automatic');
    } else if(value=='All'){
      this.inventoryList=this.globalinventoryList;
    }
  }

  inventoryOnload(data:any){
    let value={
      searchText:data
    }
    this.userService.getlistInventories(value).subscribe(res=>{
          this.loaderIndicator=false;
      if(res.status=='success'){
        this.inventoryList=res.results;
        this.globalinventoryList=_.cloneDeep(this.inventoryList);
      }else{
        this.inventoryList=[];
      }
    });
  }
}
