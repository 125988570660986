import { Component, OnInit, ViewChild, OnDestroy, TemplateRef, EventEmitter, Output } from '@angular/core';
import { HeaderService } from '../../../_services/header/header.service';
import { PracticesService } from '../../../_services/settings/practices.service';
import { TaskService } from '../../../_services/task/task.service';
import { DataHelperService } from '../../../_services/auth/data-helper.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../_services/shared.service';
import * as _ from 'lodash';
import libphonenumber from 'google-libphonenumber';
import { conformToMask } from 'angular2-text-mask';
import { Observable } from 'rxjs/Rx';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SharedPopupService } from '../../../_services/popup.service';
import constants from '../../../constants';
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

class PracticeObject {
  accountname: String;
  organization_name: String;
  accountno: String;
  accountgroupname:String;
  accountgroup: String;
  npi: String;
  address: {
    street1: String;
    street2: String;
    city: String;
    state: String;
    zip: String
  };
  phone: String;
  fax: String;
  taxid: String;
  taxonomy_code: String;
  alt_taxonomy: String;
  website: String;
  emailReport: String
}

@Component({
  selector: 'app-practice-details',
  templateUrl: './practice-details.component.html',
  styleUrls: ['./practice-details.component.scss']
})
export class PracticeDetailsComponent implements OnInit, OnDestroy {
  public practiceAccountForm: PracticeObject;
  subscription1$: Subscription;
  practiceIdFlag: boolean;
  @ViewChild('f') myform;
  @ViewChild('npiPopup') npiPopup: any;
  accountGroupList: any;
  taxonomyList: any;
  state_array_item: any;
  taxonomy_array_item: any;
  alt_taxonomy_array_item: any;
  taxonomySelectedCode: any;
  altTaxonomySelectedCode: any;
  currentPracticeId: string;
  statusFlag: Boolean = false;
  taxonoFlag: Boolean = false;
  errHandlingFlag: Boolean = false;
  maskValidation: boolean;
  isValidPracticeForm: boolean;
  phoneMask = ['(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  zipCodeFive = [/\d/, /\d/, /\d/, /\d/, /\d/];
  zipCodeNine = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public einMask = [/[0-9]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  taxonomy_timer: any;
  timeout = null;
  phoneError: boolean;
  valideURL: boolean;
  keepSelectedItemValue: boolean;
  taxIdErrFlag: boolean;
  keepSelectedItemValueState: boolean;
  npiError: boolean;
  npi_address: any;
  tempOrgName: String;
  constants:any;
  constructor(private headerService: HeaderService, private practicesService: PracticesService,
    public taskService: TaskService, private DataHelper: DataHelperService,
    public SharedPopupService: SharedPopupService,
    private sharedService: SharedService, public bsModalService: BsModalService) {
    this.practiceAccountForm = {
      'accountname': '',
      'organization_name': '',
      'accountno': '',
      'accountgroupname':'',
      'accountgroup': '',
      'npi': '',
      'address': {
        'street1': '',
        'street2': '',
        'city': '',
        'state': '',
        'zip': ''
      },
      'phone': '',
      'fax': '',
      'taxid': '',
      'taxonomy_code': '',
      'alt_taxonomy': '',
      'website': '',
      "emailReport": ''
    };
    this.practiceIdFlag = true;
    // this.taxonomySearch('', '', true);
    this.npiProviderData = _.debounce(this.npiProviderData, 400);
    this.state_array_item = [];
    this.valideURL = true;
    this.isValidPracticeForm = false;
    this.taxonomy_array_item = [];
    this.alt_taxonomy_array_item = [];
    this.keepSelectedItemValue = false;
    this.taxIdErrFlag = true;
    this.keepSelectedItemValueState = false;
    this.npiError = false;
    this.constants=constants;
  }

  ngOnInit() {
    this.taskService.initialStateList();
    if (localStorage.getItem('practice_id')) {
      let newPractice = this.DataHelper.getItem('practice_id');
      this.currentPracticeId = newPractice;
      this.keepSelectedItemValue = false;
      this.keepSelectedItemValueState = false;
      this.loadData();
      this.practiceIdFlag = false;
      this.taxonoFlag = this.statusFlag = false;
    }
    this.practiceEmit();
  }

  practiceEmit() {
    this.subscription1$ = this.headerService.practiceEmitted$.subscribe(newPractice => {
      this.currentPracticeId = newPractice;
      this.keepSelectedItemValue = false;
      this.keepSelectedItemValueState = false;
      this.practiceAccountForm.taxonomy_code = '';
      this.practiceAccountForm.alt_taxonomy = '';
      this.loadData();
      if (this.DataHelper.getItem('practice_id')) {
        this.myform.resetForm();
      }
      this.practiceIdFlag = false;
      this.taxonoFlag = this.statusFlag = false;
    });
  }

  zipCodeMask(): any {
    return {
      mask: (value) => {
        if (value.length <= 5) {
          return this.zipCodeFive;
        } else {
          return this.zipCodeNine;
        }
      },
      guide: false
    };
  }
  checkDigit(event: any) {
    let pattern = /\d/g;
    let checkIsNumericKey = event.key.match(pattern);
    if (checkIsNumericKey != null) {
      let conformedNumber = conformToMask(
        event.key,
        this.einMask,
        { guide: false }
      );
      this.maskValidation = conformedNumber.meta.someCharsRejected;
    }
  }



  loadData() {
    Observable.forkJoin([this.practicesService.searchAccountById(), this.practicesService.fetchAccountGroupList()]).subscribe(data => {
      if (data && data[0] && data[1].status == 'success') {
        // this.myform.resetForm();
        if (data[0].status = "success") {
          let practiceAccountData = data[0].result;
          let openEin;
          if (practiceAccountData.taxid) {
            openEin = conformToMask(
              practiceAccountData.taxid,
              this.einMask,
              { guide: false }
            ).conformedValue;
          }
          this.practiceAccountForm.accountgroup = practiceAccountData.accountgroup ? practiceAccountData.accountgroup : '';
          this.practiceAccountForm.accountname = practiceAccountData.accountname ? practiceAccountData.accountname : practiceAccountData.organization_name ? practiceAccountData.organization_name : '';
          this.practiceAccountForm.accountgroupname = practiceAccountData.accountgroup_name ? practiceAccountData.accountgroup_name : '';
          this.practiceAccountForm.organization_name = practiceAccountData.organization_name ? practiceAccountData.organization_name : '';
          this.tempOrgName = practiceAccountData.organization_name ? practiceAccountData.organization_name : '';
          this.practiceAccountForm.accountno = practiceAccountData.accountno ? practiceAccountData.accountno : '';
          this.practiceAccountForm.npi = practiceAccountData.npi ? practiceAccountData.npi : '';
          this.practiceAccountForm.address.street1 = practiceAccountData.address.street_line_1 ? practiceAccountData.address.street_line_1 : '';
          this.practiceAccountForm.address.street2 = practiceAccountData.address.street_line_2 ? practiceAccountData.address.street_line_2 : '';
          this.practiceAccountForm.address.city = practiceAccountData.address.city ? practiceAccountData.address.city : '';
          this.practiceAccountForm.address.state = practiceAccountData.address.state ? practiceAccountData.address.state : '';
          if (this.practiceAccountForm.address) {
            this.getState(this.practiceAccountForm.address.state, '');
          }
          this.practiceAccountForm.address.zip = practiceAccountData.address.zip ? practiceAccountData.address.zip : '';
          this.practiceAccountForm.phone = practiceAccountData.phone ? practiceAccountData.phone : '';
          this.practiceAccountForm.fax = practiceAccountData.fax ? practiceAccountData.fax : '';
          this.practiceAccountForm.taxid = practiceAccountData.taxid ? openEin : practiceAccountData.taxid;
          this.practiceAccountForm.taxonomy_code = practiceAccountData.taxonomy_code ? practiceAccountData.taxonomy_code : '';
          // this.taxonomySearch(this.practiceAccountForm.taxonomy_code, '', '')
          this.practiceAccountForm.alt_taxonomy = practiceAccountData.alt_taxonomy ? practiceAccountData.alt_taxonomy : '';
          this.practiceAccountForm.website = practiceAccountData.website ? practiceAccountData.website : '';
          this.practiceAccountForm.emailReport = practiceAccountData.emailReport ? practiceAccountData.emailReport : ''

          this.taxonomySearch(this.practiceAccountForm.taxonomy_code, 'taxonomy', 'initial');
          this.taxonomySearch(this.practiceAccountForm.alt_taxonomy, 'alt_taxonomy', 'initial');


          // this.practiceAccountForm.address.street1 = this.practiceAccountForm.address.street_line_1;
          // this.practiceAccountForm.address.street2 = this.practiceAccountForm.address.street_line_2;
          // this.myform.control.markAsUntouched();
        }
        if (data[1].results && data[1].results.length > 0) {
          this.accountGroupList = data[1].results;
        }
      }
    });

  }
  getState(searchKey, flag) {
    this.keepSelectedItemValue = flag == 'search' ? true : false;
    let filtered_data = [];
    let stateval = this.taskService.stateList;
    filtered_data = this.taskService.filterByValue(stateval, searchKey);

    for (let i in filtered_data) {
      if (parseInt(i) < 5) {
        this.state_array_item[i] = {
          id: filtered_data[i].state_code,
          text: filtered_data[i].state_code
        };
      }
    }
  }


  taxonomySearch(searchKey, searchfrom, flag) {
    this.keepSelectedItemValue = flag == '' ? true : false;
    if (flag != true) {
      this.taxonomy_array_item = (searchfrom == 'taxonomy') ? [] : this.taxonomy_array_item;
      this.alt_taxonomy_array_item = (searchfrom == 'alt_taxonomy') ? [] : this.alt_taxonomy_array_item;

      if (flag == '') {
        clearTimeout(this.taxonomy_timer);
        this.taxonomy_timer = setTimeout(() => {
          this.taxonomyAPICall(searchKey, searchfrom);
        }, 1000);
      } else {
        this.taxonomyAPICall(searchKey, searchfrom);
      }

    } else {
      clearTimeout(this.taxonomy_timer);
      this.taxonomy_timer = setTimeout(() => {
        this.practicesService.searchTaxonomy(searchKey).subscribe(result => {
          this.alt_taxonomy_array_item = [];
          this.taxonomy_array_item = [];
          if (result.status == 'success') {
            for (let i in result.results) {
              if (this.taxonomySelectedCode != result.results[i].Code) {
                this.alt_taxonomy_array_item[i] = {
                  id: result.results[i].Code,
                  text: result.results[i].Code
                };
              }
              if (this.altTaxonomySelectedCode != result.results[i].Code) {
                this.taxonomy_array_item[i] = {
                  id: result.results[i].Code,
                  text: result.results[i].Code
                };
              }
            }
          }
        });
      }, 1000);
    }
  }
  taxonomyAPICall(searchKey, searchfrom) {
    this.practicesService.searchTaxonomy(searchKey).subscribe(result => {
      if (result.status == 'success') {
        for (let i in result.results) {
          if (searchfrom == 'taxonomy') {
            if (this.altTaxonomySelectedCode != result.results[i].Code) {
              this.taxonomy_array_item[i] = {
                id: result.results[i].Code,
                text: result.results[i].Code
              };
            }
          } else if (searchfrom == 'alt_taxonomy') {
            if (this.taxonomySelectedCode != result.results[i].Code) {
              this.alt_taxonomy_array_item[i] = {
                id: result.results[i].Code,
                text: result.results[i].Code
              };
            }
          }
        }
      }
    });
  }




  // taxonomySearch(searchKey, searchfrom, flag) {
  //   // if (this.popUpfrom == 'editProvider') {
  //   //   this.taxonomy_array_item = (searchfrom == 'taxonomy') ? [] : this.taxonomy_array_item;
  //   //   this.alt_taxonomy_array_item = (searchfrom == 'alt_taxonomy') ? [] : this.alt_taxonomy_array_item;

  //   //   if(flag ==''){
  //   //       this.taxonomyAPICall(searchKey,searchfrom);
  //   //   }else{
  //   //     this.taxonomyAPICall(searchKey,searchfrom);
  //   //   }

  //   // } else {
  //       this.practicesService.searchTaxonomy(searchKey).subscribe(result => {
  //         this.alt_taxonomy_array_item = [];
  //         this.taxonomy_array_item = [];
  //         if (result.status == 'success') {
  //           for (let i in result.results) {
  //             if (this.taxonomySelectedCode != result.results[i].Code) {
  //               this.alt_taxonomy_array_item[i] = {
  //                 id: result.results[i].Code,
  //                 text: result.results[i].Code
  //               };
  //             }
  //             if (this.altTaxonomySelectedCode != result.results[i].Code) {
  //               this.taxonomy_array_item[i] = {
  //                 id: result.results[i].Code,
  //                 text: result.results[i].Code
  //               };
  //             }
  //           }
  //         }
  //       });
  //   // }
  // }

  // taxonomyAPICall(searchKey, searchfrom) {
  //   this.practicesService.searchTaxonomy(searchKey).subscribe(result => {
  //     if (result.status == 'success') {
  //       for (let i in result.results) {
  //         if (searchfrom == 'taxonomy') {
  //           if (this.altTaxonomySelectedCode != result.results[i].Code) {
  //             this.taxonomy_array_item[i] = {
  //               id: result.results[i].Code,
  //               text: result.results[i].Code
  //             };
  //           }
  //         } else if (searchfrom == 'alt_taxonomy') {
  //           if (this.taxonomySelectedCode != result.results[i].Code) {
  //             this.alt_taxonomy_array_item[i] = {
  //               id: result.results[i].Code,
  //               text: result.results[i].Code
  //             };
  //           }
  //         }
  //       }
  //     }
  //   });
  // }

  taxonomySelectedValue(taxonomyCode) {
    let existing_alttaxomomy_index = this.alt_taxonomy_array_item.map(function (o) { return o.id; }).indexOf(taxonomyCode);
    if (existing_alttaxomomy_index > -1) {
      delete this.alt_taxonomy_array_item[existing_alttaxomomy_index];
    }
    this.taxonomySelectedCode = taxonomyCode;
    this.practiceAccountForm.taxonomy_code = taxonomyCode;
  }

  altTaxonomySelectedValue(taxonomyCode) {
    let existing_taxomomy_index = this.taxonomy_array_item.map(function (o) { return o.id; }).indexOf(taxonomyCode);
    if (existing_taxomomy_index > -1) {
      delete this.taxonomy_array_item[existing_taxomomy_index];
    }
    this.altTaxonomySelectedCode = taxonomyCode;
    this.practiceAccountForm.alt_taxonomy = taxonomyCode;
  }
  npiProviderData(npi) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
      this.practicesService.npiProviderDataAPI(npi).subscribe(data => {
        if (data.results && data.results.length > 0) {
          if (data.results && data.results.length > 0) {
            this.npiError = false;
            this.openModal(this.npiPopup);
            let npi_data = data.results[0];

            // this.practiceAccountForm.taxonomy_code = '';
            // this.taxonomySearch(this.practiceAccountForm.taxonomy_code, '', '')
            // this.practiceAccountForm.alt_taxonomy = '';
            // this.taxonomy_array_item = [];
            // this.alt_taxonomy_array_item = [];
            // this.practiceAccountForm.taxonomy_code = '';
            // this.practiceAccountForm.alt_taxonomy = '';

            this.npi_address = npi_data.addresses[0];
            npi_data.taxonomies.forEach(element => {
              if (element.primary == true) {
                this.taxonomy_array_item[0] = {
                  id: element.code,
                  text: element.code
                };
                this.npi_address.taxonomy_code = element.code;
              } else if (element.primary == false) {
                this.alt_taxonomy_array_item[0] = {
                  id: element.code,
                  text: element.code
                };
                this.npi_address.alt_taxonomy = element.code;
              }
            });

            // this.getState(npi_address.state, '');
            // this.practiceAccountForm.address.street1 = npi_address.address_1;
            // this.practiceAccountForm.address.street2 = npi_address.address_2;
            // this.practiceAccountForm.address.city = npi_address.city;
            // this.practiceAccountForm.address.state = npi_address.state;
            // this.practiceAccountForm.address.zip = npi_address.postal_code;
          }
        } else {
          this.npiError = true;
          // this.taxonomySearch('', '', 'initial');
          // this.practiceAccountForm.address.street1 = '';
          // this.practiceAccountForm.address.street2 = '';
          // this.practiceAccountForm.address.city = '';
          // this.practiceAccountForm.address.state = '';
          // this.getState(this.practiceAccountForm.address.state, '');
          // this.practiceAccountForm.address.zip = '';
        }
      });
    }, 1100);
  }

  openModal(template: TemplateRef<any>) {
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static',
      class: 'modal-md'
    });
  }

  onCancel() {
    this.SharedPopupService.dismiss();
  }

  async onUpdateDetails() {
    await this.getState((this.npi_address) ? this.npi_address.state : '', '');
    this.practiceAccountForm.address.street1 = this.npi_address.address_1;
    this.practiceAccountForm.address.street2 = this.npi_address.address_2;
    this.practiceAccountForm.address.city = this.npi_address.city;
    setTimeout(() => {
      this.practiceAccountForm.address.state = this.npi_address.state;
    }, 1);
    this.practiceAccountForm.address.zip = this.npi_address.postal_code;
    this.practiceAccountForm.taxonomy_code = this.npi_address.taxonomy_code;
    this.practiceAccountForm.alt_taxonomy = this.npi_address.alt_taxonomy;
    this.SharedPopupService.dismiss();
  }
  // npiProviderData(npi) {
  //     this.practicesService.npiProviderDataAPI(npi).subscribe(data => {
  //       if (data.results && data.results.length > 0) {
  //         if (data.results && data.results.length > 0) {
  //           let npi_data = data.results[0];
  //           this.practiceAccountForm.facilityName = npi_data.basic.name_prefix;
  //           let npi_address = npi_data.addresses[0];
  //           this.practiceAccountForm.address.street_line_1 = npi_address.address_1;
  //           this.practiceAccountForm.address.street_line_2 = npi_address.address_2;
  //           this.practiceAccountForm.address.city = npi_address.city;
  //           this.practiceAccountForm.address.state = npi_address.state;
  //           this.practiceAccountForm.address.zip = npi_address.postal_code;
  //           this.practiceAccountForm.address.street1 = this.practiceAccountForm.address.street_line_1;
  //           this.practiceAccountForm.address.street2 = this.practiceAccountForm.address.street_line_2;
  //         }
  //       } else {
  //         this.practiceAccountForm.address.street_line_1 = '';
  //           this.practiceAccountForm.address.street_line_2 = '';
  //           this.practiceAccountForm.address.city = '';
  //           this.practiceAccountForm.address.state = '';
  //           this.practiceAccountForm.address.zip = '';
  //           this.practiceAccountForm.address.street1 = '';
  //           this.practiceAccountForm.address.street2 = '';
  //       }
  //     });
  // }

  update(isValid: boolean, mynform: NgForm) {
    let practiceAccountForm = JSON.parse(JSON.stringify(this.practiceAccountForm));
    if (practiceAccountForm.taxid) {
      let temp = practiceAccountForm.taxid.replace(/-/g, '');
      practiceAccountForm.taxid = temp;
    }
    if (practiceAccountForm.address.zip) {
      let tempZip = practiceAccountForm.address.zip.split('-');
      practiceAccountForm.address.zip = tempZip.join('');
    }
    this.isValidPracticeForm = isValid;
    if (isValid) {
      //this.taxIdErrFlag = false;
      this.practicesService.updateAccountById(practiceAccountForm).subscribe(result => {
        let openEin;
        if (this.practiceAccountForm.taxid) {
          openEin = conformToMask(
            this.practiceAccountForm.taxid,
            this.einMask,
            { guide: false }
          ).conformedValue;
        }
        this.practiceAccountForm.taxid = this.practiceAccountForm.taxid ? openEin : '';
        if (result['status'] == 'success') {
          if (this.tempOrgName != this.practiceAccountForm.organization_name) {
            this.tempOrgName = this.practiceAccountForm.organization_name;
            this.DataHelper.setItem('practice_name', this.practiceAccountForm.organization_name);
            this.headerService.userPracticeAccount('', 500).subscribe(data => {
              if (data.status == 'success') {
                this.headerService.practiceList = [];
                this.headerService.practiceList = data.results.filter(practice =>{
                  return practice.enabled;
                });
              }
            });
          }
          //this.taxIdErrFlag = true;
          this.sharedService.pushToaster('success', result['status'], 'Practice information updated successfully');
        } else {
          this.sharedService.pushToaster('error', result['message'], 'Practice information updated failed');
        }
      });
    }
  }

  ngOnDestroy() {
    this.subscription1$.unsubscribe();
    this.SharedPopupService.dismiss();
  }
  validatephoneno(value) {
    try {
      if (value && value.length > 4 && value.length < 18 && value.search(/[a-z]/i) == -1) {
        const phoneNumber = value;
        const regionCode = 'US';
        const number = phoneUtil.parseAndKeepRawInput(phoneNumber, regionCode);
        this.phoneError = phoneUtil.isValidNumber(number) == false ? true : false;
        if (phoneUtil.getRegionCodeForNumber(number) == 'US') { this.practiceAccountForm.phone = '+1' + number.values_[2]; }
      } else {
        this.phoneError = (value.length == 0) ? false : true;
      }
    } catch (e) {
      this.phoneError = true;
    }

  }

  validateURL(textval) {
    let urlregex = new RegExp('^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\\\'\\\+&amp;%\$#\=~_\-]+))*$');
    this.valideURL = urlregex.test(textval);
  }
}
