import { Component, OnInit, ViewChild, SimpleChanges, Input, EventEmitter, Output } from '@angular/core';

import { DaterangepickerConfig } from 'ng2-daterangepicker';
import * as moment from 'moment';
@Component({
  selector: 'app-daterange-picker-new',
  templateUrl: './daterange-picker.component.html',
  styleUrls: ['./daterange-picker.component.scss']
})
// tslint:disable-next-line: component-class-suffix
export class DaterangePickerComponentNew implements OnInit {
  @Input('inputDate') inputDate: any;
  @Input('resetValue') resetValue: boolean;
  // tslint:disable-next-line: no-output-rename
  @Output('outputDate') outputDate = new EventEmitter<any>();

  daterangepickerOptionssettings: any;
  country:any;
  constructor(private daterangepickerOptions: DaterangepickerConfig) {
    this.daterangepickerOptionssettings = {};
  }

  ngOnInit() {
    this.country = localStorage.getItem('Region');

  }
  public selectedDate(value: any, datetype: any) {
    this.inputDate = { 'startDate': value.start.format('YYYY-MM-DD'), 'endDate': value.end.format('YYYY-MM-DD') };
    this.outputDate.emit(this.inputDate);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.inputDate) {
      if(this.country=='US'){
        this.daterangepickerOptionssettings = {
          locale: { format: 'MM-DD-YYYY' },
          alwaysShowCalendars: false,
          ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
          },
          startDate: this.inputDate.startDate ? this.inputDate.startDate : moment().format('YYYY-MM-DD'),
          endDate: this.inputDate.endDate ? this.inputDate.endDate : moment().format('YYYY-MM-DD')
        };
      }
    }else{
      this.daterangepickerOptionssettings = {
        locale: { format: 'DD-MM-YYYY' },
        alwaysShowCalendars: false,
        ranges: {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        startDate: this.inputDate.startDate ? this.inputDate.startDate : moment().format('YYYY-MM-DD'),
        endDate: this.inputDate.endDate ? this.inputDate.endDate : moment().format('YYYY-MM-DD')
      };
    }
  }
  btnattr(event) {
  }
}
