import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DataTableModule } from 'angular2-datatable';
import { MomentModule } from 'angular2-moment';
import { TextMaskModule } from 'angular2-text-mask';
import { SelectModule } from 'ng-select';
import { Daterangepicker } from 'ng2-daterangepicker';
import { DragulaModule } from 'ng2-dragula';
import { FileUploadModule } from 'ng2-file-upload';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ColorPickerModule } from 'ngx-color-picker';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { INgxSelectOptions, NgxSelectModule } from 'ngx-select-ex';
import { ImageViewerComponent } from '.././views/image-viewer/image-viewer.component';
import { ChargeDataComponent } from '../components/charge-data/charge-data.component';
import { DaterangePickerComponentNew } from '../components/daterange-picker-new/daterange-picker.component';
import { DaterangePickerComponent } from '../components/daterange-picker/daterange-picker.component';
import { PatientDetailsComponent } from '../components/patient-details/patient-details.component';
import { PracticeInvoiceLinesComponent } from '../components/practice-invoice-lines/practice-invoice-lines.component';
import { PracticeInvoiceSummaryComponent } from '../components/practice-invoice-summary/practice-invoice-summary.component';
import { AppointmentTypesComponent } from '../components/practice-settings/appointment-types/appointment-types.component';
import { FacilityDetailsComponent } from '../components/practice-settings/facility-details/facility-details.component';
import { InsurancePayerDetailsComponent } from '../components/practice-settings/insurance-payer-details/insurance-payer-details.component';
import { IntegrationComponent } from '../components/practice-settings/integration/integration.component';
import { PracticeDetailsComponent } from '../components/practice-settings/practice-details/practice-details.component';
import { ProviderDetailsComponent } from '../components/practice-settings/provider-details/provider-details.component';
import { UserDetailsComponent } from '../components/practice-settings/user-details/user-details.component';
import { SelectPracticeComponent } from '../components/select-practice/select-practice.component';
import { TaskActivityComponent } from '../components/task-activity/task-activity.component';
import { TaskAttachmentsComponent } from '../components/task-attachments/task-attachments.component';
import { TaskSummaryComponent } from '../components/task-summary/task-summary.component';
import { VisitHistoryListComponent } from '../components/visit-history-list/visit-history-list.component';
import { AllowedRegExpDirective } from '../directives/allowed-reg-exp-directive.directive';
import { AttributeDisableDirective } from '../directives/attribute-disable.directive';
import { AttributeVisibilityDirective } from '../directives/attribute-visibility.directive';
import { OnlyNumbersDirective } from '../directives/only-numbers.directive';
import { TrimInputDirective } from '../directives/trim-input.directive';
import { CapitalizePipe } from './capitalize.pipe';
import { FilterPipe } from './customfilter.pipe';
import { DiagnosiscodePipe } from './diagnosiscode.pipe';
import { StringValidationDirective } from '../directives/string-validation.directive';
import { FeatureVisibilityDirective } from '../directives/feature-visibility.directive';
import { ResetPasswordComponent } from '../components/reset-password/reset-password.component';
import { UserLoginHistoryComponent } from '../components/user-login-history/user-login-history.component';
import { ViewfilterPipe } from '../views/task/viewfilter.pipe';
import { BulkUploadsViewComponent } from '../views/bulk-uploads-view/bulk-uploads-view.component';
import { ArtFormValidationDirective } from '../directives/art-form-validation.directive';
import { PatientHistoryListComponent } from '../components/patient-history-list/patient-history-list.component';
import { PatientCoveragesComponent } from '../components/patient-coverages/patient-coverages.component';
import { PromeniDetailsComponent } from '../components/promeni-details/promeni-details.component';
import { RegionaldateformotPipe } from './regionaldateformot.pipe';

const CustomSelectOptions: INgxSelectOptions = { // Check the interface for more options
  optionValueField: 'key',
  optionTextField: 'value',
  keepSelectedItems: true
};

@NgModule({
  declarations: [CapitalizePipe,
    FilterPipe,
    DaterangePickerComponent,
    DaterangePickerComponentNew,
    DiagnosiscodePipe,
    AttributeVisibilityDirective,
    AttributeDisableDirective,
    PatientDetailsComponent,
    TaskActivityComponent,
    TaskSummaryComponent,
    SelectPracticeComponent,
    TaskAttachmentsComponent,
    VisitHistoryListComponent,
    OnlyNumbersDirective,
    ProviderDetailsComponent,
    PracticeDetailsComponent,
    FacilityDetailsComponent,
    UserDetailsComponent,
    InsurancePayerDetailsComponent,
    AppointmentTypesComponent,
    IntegrationComponent,
    TrimInputDirective,
    ImageViewerComponent,
    PracticeInvoiceLinesComponent,
    PracticeInvoiceSummaryComponent,
    ChargeDataComponent,
    AllowedRegExpDirective,
    StringValidationDirective,
    ArtFormValidationDirective,
    FeatureVisibilityDirective, ResetPasswordComponent,
    UserLoginHistoryComponent,
    ViewfilterPipe,
    BulkUploadsViewComponent,
    PatientHistoryListComponent,
    PatientCoveragesComponent,
    PromeniDetailsComponent,
    RegionaldateformotPipe
  ],
  imports: [
    CommonModule,
    MomentModule,
    Daterangepicker,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    FormsModule,
    TextMaskModule,
    NgxSelectModule,
    SelectModule,
    TabsModule,
    FileUploadModule,
    DataTableModule,
    BsDropdownModule,
    PerfectScrollbarModule,
    ColorPickerModule,
    PaginationModule.forRoot(),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    DragulaModule,
    NgxSelectModule.forRoot(CustomSelectOptions),

  ],
  exports: [CapitalizePipe,
    FilterPipe,
    MomentModule,
    Daterangepicker,
    DaterangePickerComponent,
    DaterangePickerComponentNew,
    BsDatepickerModule,
    DiagnosiscodePipe,
    AttributeVisibilityDirective,
    AttributeDisableDirective,
    PatientDetailsComponent,
    TaskActivityComponent,
    TaskSummaryComponent, SelectPracticeComponent, TaskAttachmentsComponent, VisitHistoryListComponent, PracticeInvoiceLinesComponent, PracticeInvoiceSummaryComponent,
    ProviderDetailsComponent,
    PracticeDetailsComponent,
    FacilityDetailsComponent,
    UserDetailsComponent,
    InsurancePayerDetailsComponent,
    BsDropdownModule, OnlyNumbersDirective, TrimInputDirective,
    DataTableModule, PerfectScrollbarModule, ChargeDataComponent,
    AllowedRegExpDirective,
    StringValidationDirective,
    ArtFormValidationDirective,
    FeatureVisibilityDirective,
    UserLoginHistoryComponent,
    ViewfilterPipe,
    BulkUploadsViewComponent,
    PatientHistoryListComponent,
    FileUploadModule,
    PatientCoveragesComponent,
    PromeniDetailsComponent,
    RegionaldateformotPipe
  ]
})
export class SharedModule { }
