import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'diagnosiscode'
})
export class DiagnosiscodePipe implements PipeTransform {

  transform(value: string, ...args) {
    if (value != '' && value != undefined) {
      return value.length > 3 ? [value.slice(0, 3), '.', value.slice(3)].join('') : value;
    }
  }

}
