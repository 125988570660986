import { Component, OnInit, ViewChild, ElementRef, Input, TemplateRef, Output, EventEmitter, ViewEncapsulation, SimpleChanges, OnChanges, SecurityContext, OnDestroy } from '@angular/core';
import * as myGlobals from '../../globals';
import { AuthenticationService } from '../../_services/auth/authentication.service';
import { FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { TaskService } from '../../_services/task/task.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'underscore';
import { DataHelperService } from '../../_services/auth/data-helper.service';
import { SharedPopupService } from '../../_services/popup.service';

const SIZE_LIMIT = myGlobals.TaskAttachment_SIZE;
const URL = myGlobals.TaskBase_URL;
@Component({
  selector: 'app-task-attachments',
  templateUrl: './task-attachments.component.html',
  styleUrls: ['./task-attachments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaskAttachmentsComponent implements OnInit, OnDestroy, OnChanges {
  public taskid: string;
  charge_id: string;
  patient_id: string;
  public file_name: string;
  public uploadcancel: boolean;
  public hasBaseDropZoneOver = false;
  public fileuploadFailure: boolean;
  public batch_error_msg: string;
  public upload_screen: string;
  public batchname: any;
  public batch_progress: number;
  public fileuploadSuccess: boolean;
  public internalAttachement: boolean;

  public attachments: any;
  public attachmentsForTask: any[];
  public attachmentsForCharge: any[];
  public attachmentsForPracticeInvoice: any[];
  public otherAttachments: any[];

  public selectedTask: any;
  public removeAttachErr: boolean;

  public filterAttachmentOptions: any[];
  public selectedFilterValue: string;

  public mode: any;

  @ViewChild('buttonBrowse', { static: false }) buttonBrowse: ElementRef;
  @Input('taskAttachmentData') taskAttachmentData: any;
  @Input('fromModule') fromModule: any;
  @Input('practice_id') practice_id: any;
  @Input('upload_option') upload_option: any;
  @Input('mobileView') mobileView: boolean;
  @Input('invoicedata') invoicedata: any;
  @ViewChild('downurl', { static: false }) public downurl: ElementRef;
  @Output() attachmentEvent = new EventEmitter<boolean>();
  popup: Window;

  eventFromChild(data) {
    this.updateprocess(JSON.parse(data));
    if (this.popup)
      this.popup.close();
  }

  public uploader: FileUploader = new FileUploader({
    url: URL,
    queueLimit: 1
  });
  nofileFound: boolean;
  html: string = `<span class="btn btn-danger">Never trust not sanitized HTML!!!</span>`;
  mobView: any;

  attachamenData: {};
  hideotherDownload: boolean;
  cloneData: string;
  constructor(public taskService: TaskService, public SharedPopupService: SharedPopupService, private authenticationService: AuthenticationService, public modalService: BsModalService, private domSanitizer: DomSanitizer, private dataHelperService: DataHelperService) {
    this.attachmentsForTask = [];
    this.attachmentsForPracticeInvoice = [];
    this.attachmentsForCharge = [];
    this.otherAttachments = [];
    this.selectedTask = {};
    this.removeAttachErr = false;
    this.patient_id = '';
    this.uploader.authToken = this.authenticationService.getToken();
  }

  ngOnInit() {
    this.upload_screen = 'browse';
    this.internalAttachement = false;
    if (this.fromModule == 'charge') {
      this.filterAttachmentOptions = ['charge'];
      this.selectedFilterValue = 'charge';
    }
    if (this.fromModule != 'charge') {
      this.filterAttachmentOptions = ['All', 'task', 'charge', 'others'];
    }
    if (this.fromModule == 'task') {
      this.selectedFilterValue = 'task';
    }
    if (this.fromModule == 'practiceinvoice') {
      this.filterAttachmentOptions = ['practiceinvoice'];
      this.selectedFilterValue = 'practiceinvoice';
      this.practice_id = this.invoicedata.practice_id;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.mobView = this.mobileView;
    if (this.taskAttachmentData) {
      this.charge_id = this.taskAttachmentData.charge_id ? this.taskAttachmentData.charge_id : '';
      this.patient_id = this.taskAttachmentData.patient_id ? this.taskAttachmentData.patient_id : '';
      this.taskid = this.taskAttachmentData._id;
      if (this.fromModule == 'task') {
        this.selectedFilterValue = 'All';
        this.loadattachments();
      } else if (this.fromModule == 'practiceinvoice') {
        this.selectedFilterValue = 'practiceinvoice';
        this.invoiceattachments();
      } else {
        this.selectedFilterValue = 'All';
        this.chargeattachments();
      }
    }
    this.upload_screen = 'browse';
    this.internalAttachement = false;
  }
  /* attachment start*/

  clearInput() {
    // let el: HTMLElement = this.buttonBrowse.nativeElement as HTMLElement;
    // this.buttonBrowse.nativeElement.value = "";
    this.internalAttachement = false;
    this.uploadcancel = false;
  }

  public fileOverBase(e: any): void {
    let tmp = '';
    let AllowedForamts = myGlobals.AllowedForamts;
    let taskid = this.taskid;
    this.taskid = taskid;
    this.uploader.onBuildItemForm = (item, form) => {
      form.append('taskid', taskid);
      form.append('chargeid', this.charge_id);
      form.append('practice_id', this.practice_id);
      form.append('isInternal', this.internalAttachement == undefined ? false : this.internalAttachement);
      form.append('size', item.file.size);
      if (this.patient_id) {
        form.append('patientid', this.patient_id);
      }
      tmp = item.file.name;
      this.file_name = tmp;
      // tslint:disable-next-line: no-shadowed-variable
      let lastdot = tmp.lastIndexOf('.');
      tmp = tmp.substr(lastdot + 1);
      form.append('type', tmp);
    };
    let file_name = '';
    this.uploadcancel = true;
    this.hasBaseDropZoneOver = e;

    if ((e.type == 'change') || (!e)) {
      for (let item of this.uploader.queue) {
        tmp = item.file.name;
        this.file_name = tmp;
        // tslint:disable-next-line: no-var-keyword
        var lastdot = tmp.lastIndexOf('.');
        tmp = tmp.substr(lastdot + 1);
        tmp = tmp.toLowerCase();
        this.fileuploadFailure = true;

        if (AllowedForamts.indexOf(tmp) != -1) {
          this.batch_error_msg = '';
          if (item.file.size >= SIZE_LIMIT) {
            this.batch_error_msg = this.batch_error_msg + 'File is too big to upload';
            this.uploader.clearQueue();
            this.upload_screen = 'error';
          } else {
            this.fileuploadFailure = false;
            this.batch_error_msg = '';
            this.upload_screen = 'title';
            this.file_name = this.file_name.substr(0, lastdot);
            this.batchname = this.file_name;
          }
        } else {
          this.batch_error_msg = 'File format not supported';
          this.upload_screen = 'error';
          this.uploader.clearQueue();
        }
      }
      this.uploader.onProgressItem = (percent: any) => {
        this.batch_progress = percent;
        this.upload_screen = 'progress';
      };

      this.uploader.onCancelItem = (item: any, response: any, status: any, headers: any) => {
        this.uploader.clearQueue();
        this.upload_screen = 'browse';
        this.clearInput();
        this.uploadcancel = false;
      };
      this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        if (!this.uploadcancel) {
          this.clearInput();
          this.uploader.clearQueue();
          return false;
        }
        let tmp_response = JSON.parse(response);

        if (tmp_response.status == 'error' || tmp_response.status == undefined) {

          this.fileuploadFailure = true;
          this.uploader.clearQueue();

          setTimeout(() => {
            this.fileuploadFailure = false;
            this.upload_screen = 'error';
            this.batch_error_msg = tmp_response.message;
            return false;
          }, 1000);
        }

        if (tmp_response.status == 'success' && this.uploadcancel) {
          this.fileuploadSuccess = true;
          if (this.fromModule != 'practiceinvoice') {
            this.loadattachments();
          }
          setTimeout(() => {
            this.fileuploadSuccess = false;
            this.upload_screen = 'uploaded';
          }, 500);
          this.file_name = this.file_name.substr(0, lastdot);
          this.batchname = this.file_name;
          this.attachmentEvent.emit(true);
        }
        this.uploadcancel = true;
      };
    }
  }

  saveattachment() {
    let title = this.batchname;
    let taskid = this.taskid;
    if (this.fromModule == 'practiceinvoice') {
      let practiceInvoiceId = this.invoicedata._id;
      for (let item of this.uploader.queue) {
        item.url = item.url + '/' + practiceInvoiceId + '/' + title + '/invoiceattachments';
        item.file._createFromObject = title;
      }
    } else {
      for (let item of this.uploader.queue) {
        item.url = item.url + '/' + taskid + '/' + title + '/attachments';
        item.file._createFromObject = title;
      }
    }
    this.uploader.uploadAll();
  }

  newbatch() {
    this.batch_progress = -1;
    this.batchname = '';
    this.fileuploadSuccess = false;
    this.fileuploadFailure = false;
    this.batch_error_msg = '';
    this.uploader.clearQueue();
    this.upload_screen = 'browse';
  }

  loadattachments() {
    this.taskService.attachments(this.charge_id, this.taskid, this.patient_id).subscribe(data => {
      this.attachments = data.attachments;
      this.attachmentsForTask = [];
      this.attachmentsForPracticeInvoice = [];
      this.attachmentsForCharge = [];
      this.otherAttachments = [];
      this.nofileFound = true
      if (data.attachments && data.attachments.length > 0) {
        this.nofileFound = false
        data.attachments.forEach(element => {
          if(element !=null){
            element.file_type = element.file_type.toLowerCase();
            if (element.file_type == 'jpg' || element.file_type == 'jpeg' || element.file_type == 'image/jpeg') {
              element['attachment_type'] = 'Image';
              element.icon_src = "assets/img/document_image.png";
              element.icon = "fal fa-file-image";
              element.canView = true;
            } else if (element.file_type == "image/png" || element.file_type == 'png') {
              element['attachment_type'] = 'Image';
              element.icon = "fal fa-file-image";
              element.canView = true;
            } else if (element.file_type == 'pdf' || element.file_type == 'application/pdf') {
              element['attachment_type'] = 'PDF';
              element.icon_src = "assets/img/pdfSample.png";
              element.icon = "fal fa-file-pdf";
            } else if (element.file_type == 'gif' || element.file_type == 'psd' || element.file_type == 'psb' || element.file_type == 'svg') {
              element['attachment_type'] = 'Image (other)';
              element.icon = "fal fa-file-image";
            } else if (element.file_type == 'mp3' || element.file_type == 'audio/wav' || element.file_type == 'wav') {
              element['attachment_type'] = 'Audio';
              element.icon_src = "assets/img/mp3-file.png";
              element.icon = "fal fa-file-audio";
            } else {
              element['attachment_type'] = 'Other';
              element.icon_src = "assets/img/document_image.png";
              element.icon = "fal fa-file-alt"
            }
  
            let toolTipHTML = '<div style="width:500px">'
              + '<span><strong class="t-c">By</strong> :' + element.created_user_name + '</span>'
              + '<br><span><strong class="t-c">Date</strong> : ' + element.file_date + '</span>'
              + '<br><span><strong class="t-c">Internal</strong> : ' + element.is_internal + '</span>'
              + '<br><span><strong class="t-c">Type</strong> : ' + element.file_type + '</span>'
              + '<br><span><strong class="t-c">Size</strong> : ' + element.size + '</span>'
              + '</div>';
            element.enableTooltip = this.domSanitizer.sanitize(SecurityContext.HTML, toolTipHTML);
  
            this.attachementpush(element);
          }
        });
        // console.log(this.attachmentsForCharge, this.attachmentsForTask, this.otherAttachments);
      }
    });
  }

  removeAttachmentPop(removeAttachmentModal: TemplateRef<any>, selectedTask) {
    // console.log('removeAttachmentPop', removeAttachmentModal, selectedTask);
    this.selectedTask = selectedTask;
    this.SharedPopupService.show(removeAttachmentModal, {
      animated: true,
      backdrop: 'static'
    });
  }

  Oncancel() {
    this.SharedPopupService.dismiss();
  }

  attachementpush(element) {
    if (element.ref_type == 'charge' && element.ref_id == this.charge_id) {
      this.getthumbnail(element, "attachmentsForCharge");
    } else if (element.ref_type == 'task' && element.ref_id == this.taskid) {
      this.getthumbnail(element, "attachmentsForTask");
    } else if (element.ref_type == 'practice_invoice') {
      this.getthumbnail(element, "attachmentsForPracticeInvoice");
    } else {
      element.relatedAttachment = true;
      this.getthumbnail(element, "otherAttachments");
    }
  }

  getthumbnail(element, dynamicarray) {
    //console.log(element,"elementelementelement")
    return this[dynamicarray].push(element);  // disable thumbnails
    if (element.canView) {
      this.taskService.viewthumbnail(element.file_name).subscribe(data => {
        if (data.status == "success") {
          element.thumbnail = data;
          this[dynamicarray].push(element);
          //console.log(this[dynamicarray],"this.otherAttachments");
        } else {
          this[dynamicarray].push(element);
        }
      }, error => {
  //console.log(error);
        this[dynamicarray].push(element);
      });
      // } else if(element.attachment_type == "pdf"){
      //   this[dynamicarray].push(element);
    } else {
      this[dynamicarray].push(element);
    }
    setTimeout(() => {
      this.nofileinfo()
    }, 1000);
  }

  chargeattachments() {
    this.otherAttachments = [];
    //this.attachmentsForTask=[];
    this.attachmentsForPracticeInvoice = [];
    this.attachmentsForCharge = [];
    this.newbatch();
    this.nofileFound = true;
    // console.log(this.taskAttachmentData,"this.taskAttachmentData")
    if (this.taskAttachmentData.length > 0) {
      this.nofileFound = false
      this.taskAttachmentData.forEach(element => {
        element.file_type = element.file_type.toLowerCase();
        if (element.file_type == 'jpg' || element.file_type == 'jpeg' || element.file_type == 'image/jpeg') {
          element['attachment_type'] = 'Image';
          element.icon_src = "assets/img/document_image.png";
          element.icon = "fal fa-file-image";
          element.canView = true;
        } else if (element.file_type == "image/png" || element.file_type == 'png') {
          element['attachment_type'] = 'Image';
          element.icon = "fal fa-file-image";
          element.canView = true;
        } else if (element.file_type == 'pdf' || element.file_type == 'application/pdf') {
          element['attachment_type'] = 'PDF';
          element.icon_src = "assets/img/pdfSample.png";
          element.icon = "fal fa-file-pdf";
        } else if ( element.file_type == 'gif' || element.file_type == 'psd' || element.file_type == 'psb' || element.file_type == 'svg') {
          element['attachment_type'] = 'Image (other)';
          element.icon = "fal fa-file-image";
        } else if (element.file_type == 'mp3' || element.file_type == 'audio/wav' || element.file_type == 'wav') {
          element['attachment_type'] = 'Audio';
          element.icon_src = "assets/img/mp3-file.png";
          element.icon = "fal fa-file-audio";
        } else {
          element['attachment_type'] = 'Other';
          element.icon_src = "assets/img/document_image.png";
          element.icon = "fal fa-file-alt"
        }
        let toolTipHTML = '<div style="width:500px">' 
        + '<span><strong class="t-c">By</strong> :' + element.created_user_name + '</span>' 
        + '<br><span><strong class="t-c">Date</strong> : ' + element.file_date + '</span>' 
        + '<br><span><strong class="t-c">Internal</strong> : ' + element.is_internal + '</span>' 
        + '<br><span><strong class="t-c">Type</strong> : ' + element.file_type + '</span>' 
        + '<br><span><strong class="t-c">Size</strong> : ' + element.size + '</span>' 
        + '</div>';
      element.enableTooltip = this.domSanitizer.sanitize(SecurityContext.HTML, toolTipHTML);

        // if (element.file_type == 'pdf') {
        //   element['attachment_type'] = 'PDF';
        // } else if (element.file_type == 'jpg' || element.file_type == 'jpeg' || element.file_type == 'png' || element.file_type == 'gif' ||
        //   element.file_type == 'psd' || element.file_type == 'psb' || element.file_type == 'svg' || element.file_type == 'image/jpeg' ||
        //   element.file_type == 'image/jpg') {
        //   element['attachment_type'] = 'Image';
        // } else if (element.file_type == 'mp3' || element.file_type == 'audio/wav' || element.file_type == 'wav') {
        //   element['attachment_type'] = 'Audio';
        // } else {
        //   element['attachment_type'] = 'Other';
        // }
        this.attachementpush(element)
      });
    }
  }

  deleteattachment(attachment) {
    this.taskService.deleteattachment(attachment, this.taskid).subscribe(data => {
      if (data.status == 'success') {
        this.loadattachments();
        this.SharedPopupService.dismiss();
        this.removeAttachErr = false;
      } else {
        this.removeAttachErr = true;
      }
    });
  }
  markFileAsInternal(task) {
    // console.log(task);
    this.taskService.fileDataUpdate(task.file_id, task.is_internal).subscribe(data => {
      if (data.status == 'success') {
        task.is_internal = true;
      } else {
        task.is_internal = false;
      }
    });
  }

  viewAttachment(attachment, view) {
    if (attachment.status != 'sign') return;
    //console.log(this.fromModule);
    if (this.fromModule != 'task') {
      this.taskService.downloadsingleattachment(attachment.file_name).subscribe(data => {
        this.deliverAttachment(attachment, data, view);
      });
    } else {
      this.taskService.downloadattachment(attachment.file_id, this.taskid).subscribe(data => {
        this.deliverAttachment(attachment, data, view);
      });
    }
  }

  deliverAttachment(attachment, data, view) {
    let url = window.URL.createObjectURL(data);
    if ((attachment.canView) && view) {
      window.open(url, '_blank');
    } else {
      let el: HTMLElement = this.downurl.nativeElement as HTMLElement;
      el.setAttribute('href', url);
      if (this.fromModule != 'task') {
        el.setAttribute('download', attachment.file_name);
      } else {
        el.setAttribute('download', attachment.file_id);
      }
      el.click();
    }
  }

  // downloadAttachment(attachment,option) {
  //   this.downloadAllAttachment(this.getSelectedAttachement(this.selectedFilterValue),option);
  // }

  downloadAllAttachment() {
    var file_ids = _.pluck(this.attachments, 'file_name');
    //console.log(file_ids);
    this.taskService.getpdfDocument(file_ids).subscribe(data => {
      var el = document.createElement('a');
      let url = window.URL.createObjectURL(data);
      // let el: HTMLElement = this.downurl.nativeElement as HTMLElement;
      el.setAttribute('href', url);
      el.click();
    });
  }

  getSelectedAttachement(fil_type) {
    if (fil_type == "All") {
      return this.otherAttachments.concat(this.attachmentsForTask, this.attachmentsForCharge);
    } else if (fil_type == "task") {
      return this.attachmentsForTask;
    } else if (fil_type == "charge") {
      return this.attachmentsForCharge;
    } else if (fil_type == "practiceinvoice") {
      return this.attachmentsForPracticeInvoice;
    } else if (fil_type == "others") {
      return this.otherAttachments;
    } else {
      return []
    }
  }

  openImageViewer(template: TemplateRef<any>, attachement) {
    this.attachamenData = {
      files: {
        "attachmentsForCharge": this.attachmentsForCharge,
        "attachmentsForTask": this.attachmentsForTask,
        "attachmentsForPracticeInvoice": this.attachmentsForPracticeInvoice,
        "otherAttachments": this.otherAttachments,
      },
      "selectedFilterValue": this.selectedFilterValue,
      "filterAttachmentOptions": this.filterAttachmentOptions,
      "selected_image_info": attachement,
      "selected_attachement_info": this.getSelectedAttachement(this.selectedFilterValue)
    }

    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static',
      class: 'modal-p'
    });
  }

  modalClose() {
    this.SharedPopupService.dismiss();
  }

  nofileinfo() {
    let taskl = this.attachmentsForTask.length;
    let invoicel = this.attachmentsForPracticeInvoice.length;
    let chargel = this.attachmentsForCharge.length;
    let otherl = this.otherAttachments.length;
    this.nofileFound = false
    this.hideotherDownload = false;
    if (this.selectedFilterValue == 'All') {
      if (taskl == 0 && chargel == 0 && otherl == 0 && invoicel == 0) {
        this.nofileFound = true;
      } else {
        this.hideotherDownload = true;
      }
    } else if (this.selectedFilterValue == 'others' && otherl == 0) {
      this.nofileFound = true;
    } else if (this.selectedFilterValue == 'task' && taskl == 0) {
      this.nofileFound = true;
    } else if (this.selectedFilterValue == 'charge' && chargel == 0) {
      this.nofileFound = true;
    } else if (this.selectedFilterValue == 'practiceinvoice' && invoicel == 0) {
      this.nofileFound = true;
    } else {
      this.nofileFound = false;
    }
  }

  openFullScreenMode() {
    this.attachments.map(data => {
      if (data.url)
        delete data.url
    })
    let cloneData = JSON.stringify(this.attachamenData);
    this.dataHelperService.setItem("img-viewer_info", cloneData);
    this.popup = window.open("/#/image-viewer ", "_blank", 'location=yes,scrollbars=yes,status=yes');
    window.addEventListener("storage", this.storageEventListener.bind(this));
    //popup.addEventListener("onbeforeunload", this.closepopup.bind(this));
    this.popup.onbeforeunload = function () {
      localStorage.removeItem("img-viewer_info");
      window.removeEventListener;
    };
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      let v = this.dataHelperService.getItem("img-viewer_info");
      localStorage.removeItem("img-viewer_info")
      this.updateprocess(v);
    }
  }

  updateprocess(v) {
    if (v && v.info_update) {
      let checkarray = ['otherAttachments', 'attachmentsForTask', "attachmentsForCharge", "attachmentsForPracticeInvoice"]
      checkarray.forEach(data => {
        this[data].map(d => {
          if (d.file_name == v.file_name) {
            d.file_classification = v.file_classification
            d.is_internal = v.is_internal;
            d.enableTooltip = this.domSanitizer.sanitize(SecurityContext.HTML, '<div style="width:500px"><span><strong class="t-c">By</strong> :' + v.created_user_name + '</span><br><span><strong class="t-c">Date</strong> : ' + v.file_date + '</span><br><span><strong class="t-c">Internal</strong> : ' + v.is_internal + '</span><br><span><strong class="t-c">Type</strong> : ' + v.file_type + '</span><br><span><strong class="t-c">Size</strong> : ' + v.size + '</span></div>');
          }
        })
        // let indexvalue = this[data].findIndex(x => x.file_name == v.file_name);
        // if(indexvalue != -1){
        //   this[data][indexvalue].file_classification =  v.file_classification 
        //   this[data][indexvalue].is_internal = v.is_internal;
        //   this[data][indexvalue].enableTooltip =this.domSanitizer.sanitize(SecurityContext.HTML,'<div style="width:500px"><span><strong class="t-c">By</strong> :'+"element.created_user_name "+'</span><br><span><strong class="t-c">Date</strong> : '+ v.file_date +'</span><br><span><strong class="t-c">Internal</strong> : '+ v.is_internal+'</span><br><span><strong class="t-c">Type</strong> : '+v.file_type+'</span><br><span><strong class="t-c">Size</strong> : '+v.size+'</span></div>');
        // }
      })


    }
  }

  invoiceattachments() {
    this.otherAttachments = [];
    this.attachmentsForPracticeInvoice = [];
    this.attachmentsForCharge = [];
    this.newbatch();
    this.nofileFound = true;
    if (this.taskAttachmentData.length > 0) {
      this.nofileFound = false
      this.taskAttachmentData.forEach(element => {

        element.files[0]['ref_id'] = element.ref_id;
        element.files[0]['ref_type'] = element.ref_type;
        element.files[0]['created_user_name'] = element.created_user_name;
        element = element.files[0];
        element.enableTooltip = this.domSanitizer.sanitize(SecurityContext.HTML, '<div style="width:500px"><span><strong class="t-c">By</strong> :' + element.created_user_name + '</span><br><span><strong class="t-c">Date</strong> : ' + element.created_on + '</span><br><span><strong class="t-c">Internal</strong> : ' + element.is_internal + '</span><br><span><strong class="t-c">Type</strong> : ' + element.file_type + '</span><br><span><strong class="t-c">Size</strong> : ' + element.size + '</span></div>');

        if (element.file_type == 'pdf') {
          element['attachment_type'] = 'PDF';
        } else if (element.file_type == 'jpg' || element.file_type == 'jpeg' || element.file_type == 'png' || element.file_type == 'gif' ||
          element.file_type == 'psd' || element.file_type == 'psb' || element.file_type == 'svg' || element.file_type == 'image/jpeg' ||
          element.file_type == 'image/jpg') {
          element['attachment_type'] = 'Image';
        } else if (element.file_type == 'mp3' || element.file_type == 'audio/wav' || element.file_type == 'wav') {
          element['attachment_type'] = 'Audio';
        } else {
          element['attachment_type'] = 'Other';
        }
        this.attachementpush(element)
      });
    }
  }


  ngOnDestroy(): void {
    this.SharedPopupService.dismiss();
  }
}
