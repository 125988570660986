import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class PracticeInsurancesService {

  constructor(private http: HttpClient) { }

  updateInsurancePayerById(id: String, data: any) {
    let url = myGlobals.PracticeInsurances_URL + '/' + id;
    return this.http.put<any>(url, data);
  }
  // Create a new practice account
  addInsurancePayer(practiceAccount) {
    return this.http.post<any>(myGlobals.Base_URL + '/currentpractice/practiceinsurances/', practiceAccount);
  }
  insurancePayerStatusChange(facilityid, data) {
    let url;
      url = myGlobals.Base_URL + '/currentpractice/practiceinsurances' + '/' + facilityid;
    return this.http.post<any>(url, data);
  }
}
