import { Injectable } from '@angular/core';
import { DataHelperService } from './auth/data-helper.service';
import { RouteAuthorizationService } from './auth/route-authorization.service';
import { AuthenticationService } from './auth/authentication.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
userLoginInformation: any;
  loginresponse: string;
  isTemporaryFlag: boolean;
  isPasswordExpired: boolean;
  isPasswordOtp: boolean;
  tempUserid: any;
  tempPassword: any;
  tempAuthToken: any;
  constructor(
    private authenticationService: AuthenticationService,
    private dataHelperService: DataHelperService,
    public routeAuthorizationService: RouteAuthorizationService,
    private routers: Router
  ) { }

  userBasiclogin(data) {
    if (data.user) {
      if (data.user.active == 1) {
        if (data.roles && data.roles.length > 0) {
          if (data.account == 'mapped') {
            if ((data.isTemporary == undefined || data.isTemporary == false) && (data.passwordexpired == undefined || data.passwordexpired == false) && (data.passwordIsOtp == false)) {
              localStorage.setItem('authToken', data.authToken);
              localStorage.setItem('authRefreshToken', data.authRefreshToken);
              this.dataHelperService.setSaltById(data.authRefreshToken);
              this.dataHelperService.setItem('currentUser', data.user.username);
              this.dataHelperService.setItem('currentUserDisplayname', data.user.displayname);
              this.dataHelperService.setItem('currentUser_psudo', data.user.pseudonym);
              this.dataHelperService.setItem('usermail', data.user.email);
              this.dataHelperService.setItem('userid', data.user._id);
              if(data.user.email){this.dataHelperService.setItem('uem', data.user.email);}
              if(data.user.mobile){this.dataHelperService.setItem('umob', data.user.mobile);}
              let isInternal = false;

              if (data.user && data.user.isInternal) {
                isInternal = data.user.isInternal;
              }
              this.dataHelperService.setItem('userIsInternal', isInternal.toString());
              this.dataHelperService.setItem('userPermissions', data.permissions);
              this.routeAuthorizationService.navLists = [];
              this.routeAuthorizationService.filterRoutes().then(data => {
                // this.routeAuthorizationService.navLists = [];
              });
              this.dataHelperService.setItem('userRoles', data.roles);
              if (data.brand) {
                this.dataHelperService.setItem('userBrand', data.brand);
              }
              let tempv3data = data;
              // tslint:disable-next-line: no-shadowed-variable
              this.authenticationService.GetEnvironment().subscribe(data => {
                this.dataHelperService.setItem('ENV_Indigator', data.ENV);
                this.dataHelperService.setItem('Genie_Recent_Commit', data.Genie_Recent_Commit);
                if (this.authenticationService.redirectUrl && this.authenticationService.redirectUrl.length > 0) {
                  this.routers.navigateByUrl(this.authenticationService.redirectUrl);
                } else {
                  if(this.dataHelperService.getItem('userRoles')=="Portal ART User RBAC")
                  {
                    this.routers.navigate(['/task/assigned-to-me']);
                  }
                  else{
                    this.routers.navigate(['/dashboard']);
                  }
                }
              });
            } else {
              this.tempUserid = data.user._id;
              // this.tempPassword = model.password;
              this.tempAuthToken = data.authToken;

              if (data.isTemporary == true && data.passwordexpired == true) {  // password is Temporary and also expired
                this.isTemporaryFlag = true;
                this.isPasswordExpired = false;
              } else if (data.isTemporary == true) { // Temporary password
                this.isTemporaryFlag = true;
                this.isPasswordExpired = false;
              } else if (data.passwordexpired == true) { // Expired password
                this.isTemporaryFlag = true;
                this.isPasswordExpired = true;
              } else if (data.passwordIsOtp == true) {
                this.isTemporaryFlag = true;
                this.isPasswordOtp = true;
              }

              if (data.isTemporary == true && data.mfa_enabled == false && data.hasOwnProperty('secure_code') || data.passwordIsOtp == true && data.hasOwnProperty('secure_code') && data.mfa_enabled == false) {
                this.routers.navigate(['/set-password/' + data.user._id + '/' + data.secure_code]);
              } else {
              localStorage.setItem('authToken', data.authToken);
              localStorage.setItem('authRefreshToken', data.authRefreshToken);
              this.dataHelperService.setSaltById(data.authRefreshToken);
              this.dataHelperService.setItem('currentUser', data.user.username);
              this.dataHelperService.setItem('currentUserDisplayname', data.user.displayname);
              this.dataHelperService.setItem('currentUser_psudo', data.user.pseudonym);
              this.dataHelperService.setItem('usermail', data.user.email);
              this.dataHelperService.setItem('userid', data.user._id);
              if(data.user.email){this.dataHelperService.setItem('uem', data.user.email);}
              if(data.user.mobile){this.dataHelperService.setItem('umob', data.user.mobile);}
              let isInternal = false;

              if (data.user && data.user.isInternal) {
                isInternal = data.user.isInternal;
              }
              this.dataHelperService.setItem('userIsInternal', isInternal.toString());
              this.dataHelperService.setItem('userPermissions', data.permissions);
              this.routeAuthorizationService.navLists = [];
              this.routeAuthorizationService.filterRoutes().then(data => {
                // this.routeAuthorizationService.navLists = [];
              });
              this.dataHelperService.setItem('userRoles', data.roles);
              if (data.brand) {
                this.dataHelperService.setItem('userBrand', data.brand);
              }
              let tempv3data = data;
              // tslint:disable-next-line: no-shadowed-variable
              this.authenticationService.GetEnvironment().subscribe(data => {
                this.dataHelperService.setItem('ENV_Indigator', data.ENV);
                this.dataHelperService.setItem('Genie_Recent_Commit', data.Genie_Recent_Commit);
                if (this.authenticationService.redirectUrl && this.authenticationService.redirectUrl.length > 0) {
                  this.routers.navigateByUrl(this.authenticationService.redirectUrl);
                } else {
                  if(this.dataHelperService.getItem('userRoles')=="Portal ART User RBAC")
                  {
                    this.routers.navigate(['/task/assigned-to-me']);
                  }
                  else{
                    this.routers.navigate(['/dashboard']);
                  }
                }
              });
              }
              // this.loginresponse = 'Sorry...! No Account Mapped to this user. Please try again.';
              /// this.routers.navigate(['/reset-password']);
            }
            // if(data.user && data.user.email && data.user.email!=''&& data.user.mobile && data.user.mobile!= ""){
            //   this.sendOtp = this.modalService.show(sendotpTemplate, {
            //     animated: true,
            //     backdrop: 'static',
            //   });
            // }else{
            //   if(data.user.email && data.user.email!=''){
            //     this.otpType = "mail"
            //     this.sendotpValue();

            //   }
            //   if(data.user.mobile &&  data.user.mobile !=""){
            //     this.otpType = "phone"
            //     this.sendotpValue();
            //   }
            // }


          } else {
            this.loginresponse = 'Sorry...! No Account Mapped to this user. Please try again.';
          }
        } else {
          this.loginresponse = 'Sorry...! No Role Assigned to this user. Please try again.';
        }
      } else {
        this.loginresponse = 'Account Deactivated...!';
      }
    } else {
      this.loginresponse = 'No user exists...!';
    }
  }
}
