import { Component, OnInit, ViewChild, SimpleChanges, Input, EventEmitter, Output } from '@angular/core';

import { DaterangepickerConfig } from 'ng2-daterangepicker';
import * as moment from 'moment';
@Component({
  selector: 'app-daterange-picker',
  templateUrl: './daterange-picker.component.html',
  styleUrls: ['./daterange-picker.component.scss']
})
export class DaterangePickerComponent implements OnInit {
  @Input('inputDate') inputDate: string;
  @Input('resetValue') resetValue: boolean;
  // tslint:disable-next-line: no-output-rename
  @Output('outputDate') outputDate = new EventEmitter<any>();
  @Input('customFlag') customFlag: boolean = false;

  daterangepickerOptionssettings: any; // { locale: { format: string; }; alwaysShowCalendars: boolean; "startDate": string; "endDate": string; };
  country: string;
  // @ViewChild('dateinput') dateinput:DaterangePickerComponent;
  constructor(
    private daterangepickerOptions: DaterangepickerConfig
  ) {
    // this.inputDate="";
    this.daterangepickerOptionssettings = {};
    // console.log(this.inputDate);
    this.country = localStorage.getItem('Region');
  }

  ngOnInit() {
    // this.daterangepickerOptions.settings = {
    //   locale: { format: 'YYYY-MM-DD' },
    //   alwaysShowCalendars: false
    // };
    // console.log(this.inputDate)
  }
  btnattr(event) {

  }
  public selectedDate(value: any, datetype: any) {
    // console.log("changes happened",value,value.start.format("YYYY-MM-DD") + " - " + value.end.format("YYYY-MM-DD"))
    // this[datetype] = value.start.format("YYYY-MM-DD") + " - " + value.end.format("YYYY-MM-DD");
    this.inputDate = value.start.format('YYYY-MM-DD') + ' - ' + value.end.format('YYYY-MM-DD');
    this.outputDate.emit(this.inputDate);
  }
  ngOnChanges(changes: SimpleChanges) {
    let dateOfServiceRangeArr = [];
    if (this.inputDate) {
      dateOfServiceRangeArr = this.inputDate.split(' - ');
    }
    if (this.customFlag){
      if(this.country== 'US'){
        this.daterangepickerOptionssettings = {
          locale: { format: 'MM-DD-YYYY' },
          alwaysShowCalendars: false,
          ranges: {
          }
        };
      }else if(this.country=='UAE'){
        this.daterangepickerOptionssettings = {
          locale: { format: 'DD-MM-YYYY' },
          alwaysShowCalendars: false,
          ranges: {
          }
        };
      }
     
    }
    else{
      if(this.country=='US'){
        this.daterangepickerOptionssettings = {
          locale: { format: 'MM-DD-YYYY' },
          alwaysShowCalendars: false,
          ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
          }
        };
      }else if (this.country=='UAE'){
        this.daterangepickerOptionssettings = {
          locale: { format: 'DD-MM-YYYY' },
          alwaysShowCalendars: false,
          ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
          }
        };
        
      }
      
    }
    if (dateOfServiceRangeArr.length > 0) {
      this.daterangepickerOptionssettings['startDate'] = dateOfServiceRangeArr[0];
      this.daterangepickerOptionssettings['endDate'] = dateOfServiceRangeArr[1];
    }
    // console.log("data passed",this.daterangepickerOptionssettings);
    // this.outputDate.emit(this.inputDate);
    // this.selectedDate(this.daterangepickerOptionssettings, '');
  }
}
