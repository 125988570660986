import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as myGlobals from '../../globals';

HttpClient

@Injectable({
  providedIn: 'root'
})
export class ProceduremodifiersService {
 constructor( private http: HttpClient) { }

 addProcedureModifiers(req: any) {
  return this.http.post(myGlobals.ProcedureModifiers_URL, req);
}

editProcedureModifiers(mdifier_id, data) {
  return this.http.put(myGlobals.ProcedureModifiers_URL + '/' + mdifier_id, data);
}

searchProcedureModifiers(searchKey, status, page=0, limit=20) {
  if (searchKey == undefined) {
    searchKey = '';
  }
  return this.http.get(myGlobals.Base_URL + '/proceduremodifiers?searchKey=' + searchKey + '&status=' + status + '&page=' + page + '&pagesize=' + limit);
}

changeStatus(modifier_id, enabled) {
  let req = { 'enabled': enabled };
  return this.http.put(myGlobals.Base_URL + '/proceduremodifiers/' + modifier_id + '/status', req);
}




}
