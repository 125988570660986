import { Component, OnInit, TemplateRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HeaderService } from '../../../_services/header/header.service';
import { TaskService } from '../../../_services/task/task.service';
import { PracticeInsurancesService } from '../../../_services/settings/practice-insurances.service';
import { DataHelperService } from '../../../_services/auth/data-helper.service';
import { Subscription, Subject } from 'rxjs';
import { SharedService } from '../../../_services/shared.service';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import libphonenumber from 'google-libphonenumber';
import { SharedPopupService } from '../../../_services/popup.service';
import constants from '../../../constants';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

class InsurancePayer {
  _id: string;
  payer_name: string;
  payer_id: string;
  insurance_type: string;
  street_line_1: string;
  street_line_2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  fax: string;
  email: string;
  type: string;
  ext_id: string;
  ext_payer_id: string;
  address: any;
  external_ids: any;
}
@Component({
  selector: 'app-insurance-payer-details',
  templateUrl: './insurance-payer-details.component.html',
  styleUrls: ['./insurance-payer-details.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class InsurancePayerDetailsComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  phoneMask = ['(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  subscription1$: Subscription;
  practiceIdFlag: boolean;
  config: any;
  searchValue: string;

  popupTitle: string;
  popUpfrom: any;
  showInactive: boolean;
  pagelimit: number;
  pageskip: number;
  paginationCounts: number;
  pageCount: any;
  preBtn: boolean;
  nextBtn: boolean;
  preData: boolean;
  currentPage = 1;
  taskReason: any;
  errHandlingFlag: Boolean = false;
  lastSearch: string;
  defaultLimit: number;
  reasonInstructions: any;
  popUpinfo: any;
  popUpinfoselectedIndex: any;
  parentIndexValue: any;
  page: number;
  public insurancePayer: InsurancePayer;
  insurancePayerList: any;
  savetype: string;
  state_array_item: any;
  selectedObject: any;
  facilityIdex: any;
  messageInfo: String;
  confirmPopupFrom: any;
  loaderIndicator:boolean;
  

  insurencetype_array_item: any = [
    { 'id': 'CH', 'text': 'CH' },
    { 'id': 'MC', 'text': 'MC' },
    { 'id': 'MB', 'text': 'MB' },
    { 'id': 'BL', 'text': 'BL' },
    { 'id': 'VA', 'text': 'VA' },
    { 'id': 'WC', 'text': 'WC' },
    { 'id': 'LM', 'text': 'LM' },
    { 'id': 'LIEN', 'text': 'LIEN' },
    { 'id': 'CI', 'text': 'CI' }
  ];
  externaltype_array_item: any;
  insurancePhoneError: boolean;
  public zipCodeFive = [/\d/, /\d/, /\d/, /\d/, /\d/];
  public zipCodeNine = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  constants:any;

  constructor(public modalService: BsModalService, private headerService: HeaderService,
    private taskService: TaskService, private sharedService: SharedService, public bsModalService: BsModalService,
    private practiceInsurancesService: PracticeInsurancesService,
    public SharedPopupService: SharedPopupService,
    private DataHelper: DataHelperService) {
    this.getPayers = _.debounce(this.getPayers, 400);
    this.externaltype_array_item = [];
    this.practiceIdFlag = true;
    this.showInactive = false;
    this.defaultLimit = 10;
    this.pagelimit = 10;
    this.lastSearch = this.searchValue = '';
    this.pageskip = 0;
    this.page = 0;
    this.paginationCounts = 0;
    this.nextBtn = false;
    this.preBtn = false;
    this.preData = false;
    if (localStorage.getItem('practice_id')) {
      this.getPayers('', 'changed');
      this.practiceIdFlag = false;
      this.getExternalType();
    }
    this.subscription1$ = this.headerService.practiceEmitted$.subscribe(() => {
      this.getPayers('', 'changed');
      this.getExternalType();
    });
    this.insurancePhoneError = true;
    this.loaderIndicator=true;
    this.constants=constants
  }

  ngOnInit() {
    this.getState();
  }

  zipCodeMask(): any {
    return {
      mask: (value) => {
        if (value.length <= 5) {
          return this.zipCodeFive;
        } else {
          return this.zipCodeNine;
        }
      },
      guide: false
    };
  }

  openModal(template: TemplateRef<any>, from, infoData?, selectedIndex?, parentIndex?) {
    this.popUpfrom = from;
    this.parentIndexValue = parentIndex;
    this.popUpinfo = infoData;
    this.popUpinfoselectedIndex = selectedIndex;
    if (from == 'editInsurance') {
      this.savetype = 'edit';
      this.popupTitle = 'Edit Insurance Payers';
      this.insurancePayer = this.popUpinfo;
      //console.log("popup data", this.insurancePayer);
      this.insurancePayer.street_line_1 = this.insurancePayer.address.street_line_1;
      this.insurancePayer.street_line_2 = this.insurancePayer.address.street_line_2;
      this.insurancePayer.city = this.insurancePayer.address.city;
      this.insurancePayer.state = this.insurancePayer.address.state;
      this.insurancePayer.zip = this.insurancePayer.address.zip;
      this.insurancePayer.type = this.insurancePayer.external_ids.type;
      this.insurancePayer.ext_id = this.insurancePayer.external_ids.ext_id;
      this.insurancePayer.ext_payer_id = this.insurancePayer.external_ids.ext_payer_id;
    } else if (from == 'newInsurance') {
      this.savetype = 'new';
      this.insurancePayer = {
        'payer_name': '',
        'payer_id': '',
        'insurance_type': '',
        'street_line_1': '',
        'street_line_2': '',
        'city': '',
        'state': '',
        'zip': '',
        'phone': '',
        'fax': '',
        'email': '',
        'type': '',
        'ext_id': '',
        'ext_payer_id': '',
        '_id': '',
        'address': {},
        'external_ids': {}
      };
      this.popupTitle = 'Create New Insurance Payers';
    }
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }
  getPayers(searchKey, reason = '') {
    this.loaderIndicator=true;
    this.ngUnsubscribe.next();
    // if (this.searchValue.length > 2 || reason != '') {
    let newactive = (this.showInactive == true) ? 'false' : 'true';
    let loadPage = {
      pagesize: this.defaultLimit,
      offset: this.page
    };
    if (this.searchValue != '' && this.lastSearch != this.searchValue) {
      this.lastSearch = this.searchValue;
      this.currentPage = 1;
      this.pageCount = 0;
      loadPage.pagesize = 10;
      loadPage.offset = 0;
    }
    this.practiceIdFlag = false;
    this.taskService.listpayersGrid(this.searchValue, newactive, loadPage).takeUntil(this.ngUnsubscribe).subscribe(result => {
      this.loaderIndicator=false;
      if (result.status == 'success') {
        this.insurancePayerList = result.results;
        if (this.insurancePayerList.length == 0 && reason == 'delete') {
          this.getPayers('');
          this.page != 0 ? (this.page -= this.defaultLimit) : 0;
        } else {
          this.paginationCounts = result.totalCount;
          this.pageskip = this.page;
        }
        // this.insurancePayerList.map(m => m.enabled = m.hasOwnProperty('enabled') ? true : false );
      } else {
        this.insurancePayerList = [];
      }
    });
    // } else {
    //   this.insurancePayerList = [];
    // }
  }
  modalClose() {
    this.SharedPopupService.dismiss();
    this.taskReason = '';
    this.reasonInstructions = '';
  }
  getExternalType() {
    this.taskService.listExternalType().subscribe(result => {
      if (result.status == 'success') {
        for (let i in result.results) {
          this.externaltype_array_item[i] = {
            id: result.results[i].name,
            text: result.results[i].name
          };
        }
      }
    });
  }
  getState() {
    this.taskService.listStateNames().subscribe(result => {
      if (result.status == 'success') {
        this.state_array_item = [];
        this.taskService.stateList = [];
        // this.taskService.stateList = result.results;
        for (let i in result.results) {
          this.state_array_item[i] = {
            id: result.results[i].state_code,
            text: result.results[i].state_code
          };
        }

        result.results.forEach(element => {
          this.taskService.stateList.push({ 'state_code': element.state_code });
        });

      }
    });
  }

  submittted() {
    if (this.savetype == 'new') {
      this.practiceInsurancesService.addInsurancePayer(this.insurancePayer).subscribe(result => {
        if (result['status'] == 'success') {
          this.insurancePayerList.unshift(result.data);
          this.paginationCounts = this.paginationCounts + 1;
          if (this.insurancePayerList.length == (this.pagelimit + 1)) {
            this.insurancePayerList.pop();
          }
          this.sharedService.pushToaster('success', result['status'], 'Insurance Payers information submitted successfully');
          this.SharedPopupService.dismiss();
        } else {
          this.sharedService.pushToaster('error', result['status'], result['message']);
        }
      });
    }
    if (this.savetype == 'edit') {

      this.practiceInsurancesService.updateInsurancePayerById(this.insurancePayer._id, this.insurancePayer).subscribe(result => {
        if (result['status'] == 'success') {
          this.insurancePayerList[this.popUpinfoselectedIndex] = this.insurancePayer;
          this.insurancePayerList[this.popUpinfoselectedIndex].address.street_line_1 = this.insurancePayer.street_line_1;
          this.insurancePayerList[this.popUpinfoselectedIndex].address.street_line_2 = this.insurancePayer.street_line_2;
          this.insurancePayerList[this.popUpinfoselectedIndex].address.city = this.insurancePayer.city;
          this.insurancePayerList[this.popUpinfoselectedIndex].address.state = this.insurancePayer.state;
          this.insurancePayerList[this.popUpinfoselectedIndex].address.zip = this.insurancePayer.zip;
          this.insurancePayerList[this.popUpinfoselectedIndex].external_ids.type = this.insurancePayer.type;
          this.paginationCounts = this.paginationCounts + 1;
          //console.log("///////////////", this.insurancePayerList[this.popUpinfoselectedIndex]);
          this.sharedService.pushToaster('success', result['status'], 'Insurance Payers information updated successfully');
          this.SharedPopupService.dismiss();
        } else {
          this.sharedService.pushToaster('error', result['status'], result['message']);
        }
      });
    }
  }

  pageChanged(ev) {
    this.page = ev.page * this.defaultLimit - this.defaultLimit;
    this.onSearchChange('pagination');
  }


  onSearchChange(from) {
    if (from != 'pagination') {
      this.page = 0;
      this.currentPage = 1;
      this.getPayers('');
    } else {
      this.getPayers('');
    }
  }
  confirmPopup(accountConfirmPopup: TemplateRef<any>, status: Boolean, selectedData, type, index) {
    this.selectedObject = selectedData;
    // this.parentIndexValue = index;
    this.facilityIdex = index;
    if (type == 'deactivate') {
      this.messageInfo = (status == true) ? 'Are you sure to Deactivate Insurance Payer ?' : 'Are you sure to Activate Insurance Payer ?';
    }
    this.confirmPopupFrom = type;
    this.SharedPopupService.show(accountConfirmPopup, {
      animated: true,
      backdrop: 'static'
    });
  }


  onConfirm() {
    if (this.confirmPopupFrom == 'deactivate') {
      this.insurancePayerStatus(this.selectedObject);
    }
    this.SharedPopupService.dismiss();
  }
  inactive() {
    this.showInactive = this.showInactive ? false : true;
    // this.inactivePageBool = true;
    this.page = 0;
    this.currentPage = 1;
    this.getPayers('');
    this.searchValue = '';
  }
  insurancePayerStatus(data) {
    let payerid = data._id;
    let payerStatusData = {
      'status': (data.enabled == true) ? false : true
    };
    this.practiceInsurancesService.insurancePayerStatusChange(payerid, payerStatusData).subscribe(data => {
      if (data.status == 'success') {
        this.getPayers('', 'delete');
        this.insurancePayerList[this.facilityIdex].enabled = payerStatusData.status;
        this.sharedService.pushToaster('success', 'Success', 'Insurance Payers status updated successfully');
      }
    });
  }
  onCancel() {
    this.SharedPopupService.dismiss();
  }
  ngOnDestroy() {
    this.searchValue = '';
    this.subscription1$.unsubscribe();
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();

    this.SharedPopupService.dismiss();
    this.SharedPopupService.dismiss();
  }

  validatephoneno(value) {
    try {
      if (value && value.length > 4 && value.length < 18 && value.search(/[a-z]/i) == -1) {
        const phoneNumber = value;
        const regionCode = 'US';
        const number = phoneUtil.parseAndKeepRawInput(phoneNumber, regionCode);
        this.insurancePhoneError = phoneUtil.isValidNumber(number);
        if (phoneUtil.getRegionCodeForNumber(number) == 'US') { this.insurancePayer.phone = '+1' + number.values_[2]; }
      } else {
        this.insurancePhoneError = false;
      }
    } catch (e) {
      this.insurancePhoneError = false;
    }
  }

}
