
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as myGlobals from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class ActionCodesService {

  constructor(private http: HttpClient) { }
  
  addActionCode(val) {
    return this.http.post(myGlobals.Base_URL + '/actioncodes', val);
  }

  editActionCode(val, id) {
    return this.http.put(myGlobals.Base_URL + '/actioncodes/' + id, val);
  }

  searchActionCode(searchkey, status, page= 0, limit= 20) {
    if (searchkey == '') {
      searchkey = '';
    }
    return this.http.get<any>(myGlobals.Base_URL + '/actioncodes?q=' + searchkey + '&page=' + page + '&pagesize=' + limit + '&resStatus=' + status);
  }

  activeActionCode(code, diaStatus) {
    return this.http.put<any>(myGlobals.Base_URL + '/actioncodes/' + code + '/' + diaStatus, {});
  }
 
}