import { Component } from '@angular/core';
import { AuthenticationService } from '../../_services/auth/authentication.service';
import { Router } from '@angular/router';

@Component({
  templateUrl: '404.component.html'
})
export class P404Component {

  constructor(private authenticationService: AuthenticationService, private routers: Router) {
    console.log(this.authenticationService.redirectUrl);
  }
  goback() {
    if (this.authenticationService.redirectUrl && this.authenticationService.redirectUrl.length > 0) {
      this.routers.navigateByUrl(this.authenticationService.redirectUrl);
    } else {
      this.routers.navigate(['/dashboard']);
    }
  }

}
