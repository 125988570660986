import { Component, OnDestroy, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { PracticesService } from '../../../_services/settings/practices.service';
import { DataHelperService } from '../../../_services/auth/data-helper.service';
import { Subscription } from 'rxjs';
import { HeaderService } from '../../../_services/header/header.service';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { SharedService } from '../../../_services/shared.service';
import libphonenumber from 'google-libphonenumber';
import { FormGroup, FormControl, NgForm } from '@angular/forms';
import { UsersService } from '../../../_services/settings/users.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs/Rx';
import { LoginHistoryService } from '../../../_services/login-history/login-history.service';
import { TaskService } from '../../../_services/task/task.service';
import { SharedPopupService } from '../../../_services/popup.service';
import constants from '../../../constants';



const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

class NewUser {
  userid: string;
  user_title: string;
  username: string;
  display_name: string;
  employee_name:string
  email: string;
  mobile: string;
  is_internal = false;
  practice_visibility = false;
  brand: string;
}

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  selectedPracticeVal: string;
  subscription1$: Subscription;

  selected_user_id: any;
  selected_user_displayname: string;
  selectedUserData: any;

  allUserList: any;
  popUpfrom: string;
  ConfirmPopupfrom: string;
  parentIndexValue: any;
  popupTitle: string;
  public newuser: NewUser;
  popUpinfo: any;
  popupUserInfo: any;

  userStatus: boolean;
  messageInfo: string;
  brandList: Array<{}>;
  showInactive: boolean;
  showInternal: boolean;
  pagelimit: number;
  page: number;
  providerspage:number;
  inactivePageBool: boolean;
  searchValue: any;
  currentPage: number;
  providerscurrentPage:number;

  userindex: number;
  pageskip: any;
  providerspageskip :any;
  timeout = null;
  practiceIdFlag: boolean;
  paginationCounts: number;
  providerspaginationCounts:number;
  mobilenoerror: boolean;
  contacts: any;
  UserValidationMsg: any;


  searchModel: string;
  userRolesSelected: any[];
  userRolesUpdates: any;
  // tmpMappingData: any[];
  searchResultsRoles: any;
  resetPasswordType: number;
  tempConfirmPassword: string;
  tempPassword: string;
  providerList: any;
  mappedProviderList: any = [];
  copyproviderList: any;
  searchproviderValue: String;
  // userRole: any;
  internal_user: any;
  constants:any;
  loaderIndicator:boolean;

  constructor(
    private practicesService: PracticesService,
    private dataHelperService: DataHelperService,
    private headerService: HeaderService,
    public modalService: BsModalService,
    public SharedPopupService: SharedPopupService,
    private userService: UsersService,
    private sharedService: SharedService,
    private loginhistory: LoginHistoryService,
    public taskService: TaskService
  ) {
    this.pagelimit = 10;
    this.page = 0;
    this.providerspage = 0;
    this.allUserList = [];
    this.inactivePageBool = false;
    this.pageskip = 0;
    this.providerspageskip = 0;
    this.practiceIdFlag = true;
    this.searchValue = '';
    this.paginationCounts = 0;
    this.providerspaginationCounts = 0;
    this.contacts = 'fail';
    this.searchModel = '';
    this.userRolesSelected = [];
    // this.tmpMappingData = [];
    this.searchproviderValue = "";
    this.showInternal = true;
    this.constants=constants;
    this.loaderIndicator = true;
    if (localStorage.getItem('practice_id')) {
      this.selectedPracticeVal = this.dataHelperService.getItem('practice_id');
      this.showInactive = false;
      this.showInternal = true;
      this.practiceIdFlag = false;
      this.searchValue = '';
      this.popUpinfo = '';
      this.loadUserData('');
      this.searchProviders();
      // this.userSelectedText = '';
    }

    this.subscription1$ = this.headerService.practiceEmitted$.subscribe(newPractice => {
      this.selectedPracticeVal = newPractice;
      this.practiceIdFlag = false;
      this.pageskip = 0;
      this.currentPage = 1;
      this.providerscurrentPage=1;
      this.showInactive = false;
      this.showInternal = true;
      this.searchValue = '';
      this.loadUserData('');
      this.searchProviders();
      // this.loaderIndicator=true;

    });

    this.brandList = [{ 'name': 'Global', 'url': '' }, { 'name': 'Claimocity', 'url': '' }];
    this.internal_user = this.dataHelperService.getItem('userIsInternal')

    // this is not needed as the entire page requires the practice admin permission
    // let roleList = this.dataHelperService.getItem('userRoles');
    // let permissions = this.dataHelperService.getItem('userPermissions');
    // this.userRole = permissions.indexOf("Practice Setting Admin");
  }
  onBlurMethod(){
    if(this.newuser.brand == 'Global'){
      if(this.newuser.employee_name== null  || this.newuser.employee_name== undefined ){
        this.newuser.employee_name=""
       }
     this.newuser.display_name=this.newuser.username + "_" + this.newuser.employee_name ;
    }  else{
      this.newuser.display_name=""
    }
  }
  ngOnInit() {
  }

  onpageChanged(ev) {
    this.page = ev.page * this.pagelimit - this.pagelimit;
    this.loadUserData('pagination');
    this.inactivePageBool = false;
  }

  loadUserData(from, actionType = '') {
    // this.loaderIndicator=true;
    let tempBool = false;
    if (actionType == 'search') {
      let testPattern = /^[a-zA-Z0-9 ]*$/;
      let regExVal = new RegExp(testPattern);
      tempBool = regExVal.test(from);
    }
    let enabled = (this.showInactive == true) ? 'false' : 'true';
    let isInternal = this.showInternal ? true : false;
    if (tempBool || from == "pagination" || from == "") {
      if (from != 'pagination' && actionType != 'deactivated') {
        this.page = 0;
        this.currentPage = 1;
      }
      let loadPage = {
        pagesize: this.page,
        offset: this.pagelimit,
        isInternal: false
      };
      clearTimeout(this.timeout);
      this.timeout = setTimeout(x => {
        this.practicesService.get_mapped_users(null, this.searchValue, 10, this.page, isInternal, enabled).subscribe(data => {
         this.loaderIndicator=false;
          if (data.status == 'success') {
            this.allUserList = data.results;
            this.allUserList.forEach(element => {
            });
            if (this.allUserList.length > 0) {
              this.practiceIdFlag = false;
            }
            if (this.searchValue && this.allUserList.length == 0) {
              this.practiceIdFlag = false;
            }
            this.paginationCounts = data.totalCount;
            this.pageskip = this.page;

            if (data.results.length == 0 && actionType == 'deactivated') {
              // this.pagelimit=this.pagelimit;
              if (this.page != 0) {
                let currentPageLength = this.allUserList.length % this.pagelimit;
                if (currentPageLength == 0) {
                  this.page = this.page - this.pagelimit;
                  this.loadUserData('', 'deactivated');
                }
              }
            }
          } else {
            this.practiceIdFlag = false;
          }
        });
      }, 700);
    }
  }

  ngOnDestroy() {
    this.subscription1$.unsubscribe();
    this.SharedPopupService.dismiss();

  }

  openModal(template: TemplateRef<any>, from, infoData, parentIndex) {
    this.popUpfrom = from;
    this.parentIndexValue = parentIndex;
    if (this.popUpfrom == 'newUser') {
      this.popupTitle = 'New User';
      this.newuser = {
        userid: '',
        user_title: '',
        username: '',
        display_name: '',
        employee_name:'',
        email: '',
        mobile: '',
        is_internal: false,
        practice_visibility: false,
        brand: ''
      };
    } else if (this.popUpfrom == 'editUser') {
      this.popupTitle = 'Edit User';
      this.popUpinfo = infoData;
      this.newuser = {
        userid: this.popUpinfo.user_id,
        user_title: this.popUpinfo.user_title,
        username: this.popUpinfo.username,
        employee_name:this.popUpinfo.employeename,
        display_name: this.popUpinfo.displayname,
        email: this.popUpinfo.email,
        mobile: this.popUpinfo.mobile,
        is_internal: false,
        practice_visibility: this.popUpinfo.practice_visibility ? this.popUpinfo.practice_visibility : false,
        brand: this.popUpinfo.brand
      };
    }
    if (from == 'passwordReset') {
      this.popupTitle = 'Reset password';
      this.popupUserInfo = infoData;
    }
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }

  showModal(templateRef) {
    this.SharedPopupService.show(templateRef, {
      animated: true,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }

  closeModal() {
    this.SharedPopupService.dismiss();
    if (this.searchproviderValue != "") {
      this.searchproviderValue = "";
      this.searchProviders();
    }
  }

  // Provider mapping
  mapProvider(modalTemplate: TemplateRef<any>, selectedUser, index) {
    //console.log(selectedUser);
    this.selected_user_id = selectedUser.user_id;
    this.selected_user_displayname = selectedUser.displayname;

    this.mappedProviderList = [];
    this.userService.getUserProviders(selectedUser.user_id, true).subscribe(data => {
      if (data.status == 'success') {
        this.mappedProviderList = data.results.filter(p => p.practice_id == this.selectedPracticeVal);
      }
      this.searchproviderValue = '';
      this.searchProviders();
      this.showModal(modalTemplate);
    }, error => {
      this.sharedService.pushToaster('error', 'Error', 'Sorry... search failed');
    });
  }

  searchmapProviders(){
    let tempBool;
    let testPattern = /^[a-zA-Z0-9\.\-\/ ]*$/;
    let regExVal = new RegExp(testPattern);
    tempBool = regExVal.test(this.searchproviderValue.toString());
    if (tempBool) {
    clearTimeout(this.timeout);
      this.timeout = setTimeout(x => {
        this.searchProviders()
      }, 400);
    }
  }

  searchProviders() {
    this.loaderIndicator = true;
    this.providerList = [];
    this.providerspaginationCounts = 0;
    //this.providerspageskip = 0;
    this.practicesService.searchProvider(this.selectedPracticeVal, this.searchproviderValue, "any", 10, 0, true).subscribe(data => {

      this.loaderIndicator = false;
      if (data.status == "success") {
        // this.copyproviderList = JSON.stringify(data.results);
        let providerList = data.results;
        providerList.forEach(element => {
          element.isSelected = this.mappedProviderList.findIndex(r => r.provider_id == element._id) > -1;
        });
        this.providerList = providerList;
        this.providerspaginationCounts = data.totalCount;
        this.providerspageskip = 0;
      }
    }, error => {
      this.sharedService.pushToaster('error', 'Error', 'Sorry... search failed');
    });
  }
  onproviderspageChanged(ev){
    if(ev.page * this.pagelimit - this.pagelimit != this.providerspage){
      this.providerspage = ev.page * this.pagelimit - this.pagelimit;
      this.loaderIndicator = true;
      this.providerList = [];
      this.pageChange();
    } 
  }

  pageChange(){
    this.practicesService.searchProvider(this.selectedPracticeVal, this.searchproviderValue, "any", 10, this.providerspage, true).subscribe(data => {
      this.loaderIndicator = false;
      if (data.status == "success") {
        // this.copyproviderList = JSON.stringify(data.results);
        let providerList = data.results;
        providerList.forEach(element => {
          element.isSelected = this.mappedProviderList.findIndex(r => r.provider_id == element._id) > -1;
        });
        this.providerList = providerList;
        this.providerspaginationCounts = data.totalCount;
        this.providerspageskip = this.providerspage;
      }
      this.inactivePageBool = false;
    }, error => {
      this.sharedService.pushToaster('error', 'Error', 'Sorry... search failed');
      this.inactivePageBool = false;
    });
  }
  addorRemoveProvider(providerData) {
    if (providerData.isSelected) {
      this.userService.addUserProvider(this.selected_user_id, providerData._id, true).subscribe(data => {
        if (data.status == "success") {
          this.mappedProviderList.push({
            "provider_id": providerData._id,
            "first_name": providerData.first_name,
            "last_name": providerData.last_name,
            "title": providerData.title
          });
          this.sharedService.pushToaster('success', 'Success', 'Provider mapped successfully')
        }
      }, error => {
        providerData.isSelected = !providerData.isSelected;
        this.sharedService.pushToaster('error', 'Error', 'Something went wrong');
      });
    } else {
      this.userService.removeUserProvider(this.selected_user_id, providerData._id, true).subscribe(data => {
        if (data.status == "success") {
          let selectedIndex = this.mappedProviderList.findIndex(p => p.provider_id == providerData._id);
          if (selectedIndex > -1) this.mappedProviderList.splice(selectedIndex, 1);
          this.sharedService.pushToaster('success', 'Success', 'Provider removed successfully')
        }
      }, error => {
        providerData.isSelected = !providerData.isSelected;
        this.sharedService.pushToaster('error', 'Error', 'Something went wrong');
      });
    }
  }

  removeUserProvider(selectedProvider, i) {
    //console.log('remove provider', selectedProvider, i);
    this.userService.removeUserProvider(this.selected_user_id, selectedProvider.provider_id, true).subscribe(data => {
      if (data.status == "success") {
        this.mappedProviderList.splice(i, 1);
        let searchIndex = this.providerList.findIndex(p => p._id == selectedProvider.provider_id);
        if (searchIndex > -1) this.providerList[searchIndex].isSelected = false;
        this.sharedService.pushToaster('success', 'Success', 'Provider mapped successfully')
      }
    }, error => {
      selectedProvider.isSelected = !selectedProvider.isSelected;
      this.sharedService.pushToaster('error', 'Error', 'Something went wrong');
    });
  }

  confirmPopup(Confirmtemplate: TemplateRef<any>, from, userData, userStatus, userindex, actionindex) {
    this.ConfirmPopupfrom = from;
    this.selectedUserData = userData;
    this.userStatus = userStatus;
    this.userindex = userindex;
    if (this.ConfirmPopupfrom == 'userstatus') {
      if (this.userStatus == true) {
        this.messageInfo = 'Are you sure want to Deactivate user ?';
      } else {
        this.messageInfo = 'Are you sure want to Activate user ?';
      }
    }
    this.SharedPopupService.show(Confirmtemplate, {
      animated: true,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }

  onConfirm() {
    this.userStatusChange(this.selectedUserData);
    this.SharedPopupService.dismiss();
  }

  onCancel() {
    this.SharedPopupService.dismiss();
  }

  userStatusChange(userObj) {
    let userData = {
      'user_id': userObj.user_id,
      'enabled': (userObj.enabled == true) ? false : true
    };
    this.practicesService.practiceUserStatusChange(userData, 'fromPracticeSettings').subscribe(data => {
      if (data.status == 'success') {
        this.loadUserData('', 'deactivated');
        this.allUserList.splice(this.userindex, 1);
        //this.allUserList[this.userindex].enabled = data.results.enabled;
        this.sharedService.pushToaster('success', 'Success', 'User status updated successfully');
      }
    });
  }

  inactive() {
    this.showInactive = this.showInactive ? false : true;
    this.inactivePageBool = true;
    this.page = 0;
    this.currentPage = 1;
    this.loadUserData('');
    this.searchValue = '';
  }
  internal() {
    this.showInternal = this.showInternal ? false : true;
    this.inactivePageBool = true;
    this.page = 0;
    this.currentPage = 1;
    this.loadUserData('');
    this.searchValue = '';
  }
  validatephoneno(value) {
    try {
      if (value && value.length > 4 && value.length < 18 && value.search(/[a-z]/i) == -1) {
        const phoneNumber = value;
        const regionCode = 'US';
        const number = phoneUtil.parseAndKeepRawInput(phoneNumber, regionCode);
        this.mobilenoerror = phoneUtil.isValidNumber(number) == false ? true : false;
        if (phoneUtil.getRegionCodeForNumber(number) == 'US') { this.newuser.mobile = '+1' + number.values_[2]; }
      } else {
        this.mobilenoerror = (value.length == 0) ? false : true;
      }
    } catch (e) {
      this.mobilenoerror = true;
    }
  }

  addEditUser(model: NewUser, isValid: boolean, mynform: NgForm) {
    this.mobilenoerror = false;
    model.mobile = model.mobile ? model.mobile.replace(/\_/g, '') : '';
    let strlen = model.mobile.length;

    model = this.removeNullEntries(model);
    let User_Management_Data_list = {
      userid: this.newuser.userid ? this.newuser.userid : '',
      user_title: model.user_title,
      username: model.username,
      displayname:model.display_name ? model.display_name : this.newuser.display_name,
      employeename:model.employee_name,
      email: model.email,
      phone: model.mobile,
      is_internal: false,
      practice_visibility: model.practice_visibility ? model.practice_visibility : false,
      brand: model.brand
    }
    if (isValid == true && (model.email || model.mobile)) {
      if (this.popUpfrom == 'newUser') {
        if (strlen != 0 && strlen < 12) {
          this.mobilenoerror = true;
        } else {
          this.mobilenoerror = false;
          this.userService.addUser(User_Management_Data_list, 'practiceUser').subscribe(data => {
            this.contacts = data;
            this.UserValidationMsg = this.contacts.message;
            if (this.contacts.status == 'success') {
              User_Management_Data_list['user_id'] = data.data._id;
              User_Management_Data_list['enabled'] = true;
              User_Management_Data_list['mobile'] = model.mobile;
              this.allUserList.unshift(User_Management_Data_list);
              mynform.resetForm();
              this.paginationCounts = this.paginationCounts + 1;
              if (this.allUserList.length == (this.pagelimit + 1)) {
                this.allUserList.pop();
              }
              this.closeModal();
              this.sharedService.pushToaster('success', 'Success', 'New User added successfully');
            } else {
              this.sharedService.pushToaster('error', 'Error', this.UserValidationMsg);
            }
          },
            err => {
              this.sharedService.pushToaster('error', 'Error', 'Something went wrong');
            },
          );
        }
      }

      if (this.popUpfrom == 'editUser') {
        if (strlen != 0 && strlen < 12) {
          this.mobilenoerror = true;
        } else {
          this.mobilenoerror = false;
          this.userService.editUser(User_Management_Data_list, 'practiceUser').subscribe(data => {
            data = this.removeNullEntries(data);
            this.contacts = data;

            this.UserValidationMsg = this.contacts.message;
            if (this.contacts.status == 'success') {
              this.allUserList[this.parentIndexValue].displayname = model.display_name;
              this.allUserList[this.parentIndexValue].employeename = model.employee_name;
              this.allUserList[this.parentIndexValue].mobile = model.mobile;
              this.allUserList[this.parentIndexValue].username = model.username;
              this.allUserList[this.parentIndexValue].user_title = model.user_title;
              this.allUserList[this.parentIndexValue].email = model.email;
              this.allUserList[this.parentIndexValue].practice_visibility = model.practice_visibility;
              this.allUserList[this.parentIndexValue].is_internal = false;
              this.allUserList[this.parentIndexValue].brand = model.brand;

              mynform.resetForm();
              this.closeModal();
              this.sharedService.pushToaster('success', 'Success', 'User Update Successfully');
            } else {
              if (this.UserValidationMsg) {
                this.sharedService.pushToaster('error', 'Error', this.UserValidationMsg);
              }
              if (this.contacts.status && this.UserValidationMsg == undefined) {
                this.sharedService.pushToaster('error', 'Error', this.contacts.status);
              }
            }
          },
            err => {
              this.sharedService.pushToaster('error', 'Error', 'Something went wrong');
            },
          );
        }
      }
    } else {
      let username = new String(model.username);
      if (username.length != 0 && username.length < 3) {
        this.sharedService.pushToaster('error', 'Error', 'username should not be less than 3 character');
      }
      // let usernamelen = username.length()
      // model.username
    }
  }

  userMfaUpdate(user, user_id) {
    if (user_id != undefined && user.mfa_enabled != undefined) {
      let mfa_enabled = {
        _id: user.user_id,
        mfa_enabled: user.mfa_enabled
      }
      this.userService.userMfaUpdate(mfa_enabled, "currentPracticeUser").subscribe(data => {
        if (data.status == "success") {
          this.sharedService.pushToaster('success', 'Success', 'User MFA Update Successfully');
        } else {
          this.sharedService.pushToaster('error', 'Error', 'Something went wrong');
        }
      });
    }
  }

  removeNullEntries(obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object')
        obj[key] = this.removeNullEntries(obj[key]);
      else if (obj[key] === undefined || obj[key] === null)
        obj[key] = '';
      else
        obj[key] = (typeof obj[key] === 'string') ? obj[key].trim() : obj[key];
    });
    return obj;
  }

  // open role mapping modal
  mapRoles(modalTemplate: TemplateRef<any>, selectedUser, index) { //createnewgrouporrole
    // initialize user roles data
    this.searchModel = '';

    this.selected_user_id = selectedUser.user_id;
    this.selected_user_displayname = selectedUser.displayname;

    this.parentIndexValue = index;

    this.searchResultsRoles = [];
    this.userRolesSelected = [];
    this.userRolesUpdates = {};

    Observable.forkJoin([this.userService.get_mapped_roles(selectedUser.user_id, 0, 'fromPracticeUser'), this.userService.loadRoles('', 'fromPracticeUsers')]).subscribe(data => {
      if (data[0].status == 'success') {
        selectedUser.user_roles = data[0].results;
      } else {
        selectedUser.user_roles = [];
      }
      if (data[1].status == 'success') {
        this.searchResultsRoles = data[1].results.length > 0 ? data[1].results : [];
      } else {
        this.searchResultsRoles = [];
      }
      this.userRolesSelected = selectedUser.user_roles.map(r => { // we could simply just use the source object as well
        return {
          "role_id": r.role_id,
          "groupname": r.groupname,
          "description": r.description
        }
      });
      this.defaultSelection();
      this.showModal(modalTemplate);
    }, err => {
      this.sharedService.pushToaster('error', 'Error', 'Something went wrong');
    });
  }

  defaultSelection() {
    // mark already enabled roles for user in search results
    this.searchResultsRoles.forEach(element => {
      element.isSelected = this.userRolesSelected.findIndex(r => r.role_id == element._id) > -1;
    });
  }

  // add/remove role based on selection
  toggleRole(selectedRole, i) {
    if (selectedRole.isSelected) {
      this.userService.addUserRole(this.selected_user_id, selectedRole._id, true).subscribe(data => {
        if (data.status == "success") {
          this.userRolesSelected.push({
            "role_id": selectedRole._id,
            "groupname": selectedRole.groupname,
            "description": selectedRole.description
          });
          this.sharedService.pushToaster('success', 'Success', 'Role mapped successfully')
        }
      }, error => {
        selectedRole.isSelected = !selectedRole.isSelected;
        this.sharedService.pushToaster('error', 'Error', 'Something went wrong');
      });
    } else {
      this.userService.removeUserRole(this.selected_user_id, selectedRole._id, true).subscribe(data => {
        if (data.status == "success") {
          let selectedIndex = this.userRolesSelected.findIndex(r => r.role_id == selectedRole._id);
          if (selectedIndex > -1) this.userRolesSelected.splice(selectedIndex, 1);
          this.sharedService.pushToaster('success', 'Success', 'Role removed successfully')
        }
      }, error => {
        selectedRole.isSelected = !selectedRole.isSelected;
        this.sharedService.pushToaster('error', 'Error', 'Something went wrong');
      });
    }
  }

  removeMappedRole(selectedRole, i) {
    // user removes an item from the selected list
    //console.log('removeMappedRole', selectedRole, i);
    this.userService.removeUserRole(this.selected_user_id, selectedRole.role_id, true).subscribe(data => {
      if (data.status == "success") {
        this.userRolesSelected.splice(i, 1);
        let searchIndex = this.searchResultsRoles.findIndex(r => r._id == selectedRole.role_id);
        if (searchIndex > -1) this.searchResultsRoles[searchIndex].isSelected = false;
        this.sharedService.pushToaster('success', 'Success', 'Role removed successfully')
      }
    }, error => {
      selectedRole.isSelected = !selectedRole.isSelected;
      this.sharedService.pushToaster('error', 'Error', 'Something went wrong');
    });
  }

  searchRoles(value) {
    let tempBool;
    let testPattern = /^[a-zA-Z0-9\.\-\/ ]*$/;
    let regExVal = new RegExp(testPattern);
    tempBool = regExVal.test(value);
    if (tempBool) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(x => {
        this.userService.loadRoles(value, 'fromPracticeUsers').subscribe(data => {
          if (data.status == 'success') {
            this.searchResultsRoles = data.results.length > 0 ? data.results : [];
            this.defaultSelection();
          } else {
            this.searchResultsRoles = [];
          }
        });
      }, 400);
    }
  }

  viewLoginHistory(id, name) {
    this.loginhistory.user_id = id;
    this.loginhistory.user_name = name;
    this.taskService.viewHistory = true;
  }

  backToUsers() {
    this.taskService.viewHistory = false;
  }


}
