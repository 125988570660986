import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalize' })

export class CapitalizePipe implements PipeTransform {

  transform(value: any, words: boolean) {

    if (value) {
      if (words) {
        return value.replace(/\b\w/g, first => first.toLocaleUpperCase());
      } else {
        if (value.indexOf(' ') == -1) {
          return value.charAt(0).toUpperCase();
        } else {
          let x = value.substr(value.indexOf(' ') + 1);
          return value.charAt(0).toUpperCase() + x.charAt(0).toUpperCase();
        }
      }
    }

    return value;
  }
}
