import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './_services/auth/authentication.service';
import { DataHelperService } from './_services/auth/data-helper.service';
import { flatMap } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AuthRouteGuard implements CanActivate, CanActivateChild {
  constructor(private authenticationService: AuthenticationService, private routers: Router, private dataHelperService: DataHelperService) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // console.log( this.authenticationService.redirectUrl)
    this.authenticationService.redirectUrl = state.url;
    if (this.authenticationService.getToken()) {
      this.dataHelperService.setSalt();
      return this.checkAccess(next.data.permissions, next.data.features);

    } else {
      this.routers.navigate(['/login']);
      return false;
    }

  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);

  }
  checkAccess(routePermissions, features) {
    let local_features = this.dataHelperService.getItem('features');
    if (routePermissions) {
      let userPermissions = this.dataHelperService.getItem('userPermissions');
      
      let permissionValid =routePermissions.some(function (element) {
        if (userPermissions.indexOf(element) > -1) {
          return true;
        } else {
          return false;
        }
      });
      let featureValid = false;
      if ( features ) {
        featureValid =features.some(function (element) {
          if (local_features && local_features.indexOf(element) > -1) {
            return true;
          } else {
            return false;
          }
        });
      } else {
        featureValid = true;
      }
      
      
      return permissionValid && featureValid;

    } else {

      if ( features ) {

       return features.some(function (element) {
          if (local_features.indexOf(element) > -1) {
            return true;
          } else {
            return false;
          }
        });
      } else {
        return true;
      }

      
    }

  }

}
