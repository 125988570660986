import { Component, OnInit, TemplateRef, ViewEncapsulation, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HeaderService } from '../../../_services/header/header.service';
import { DataHelperService } from '../../../_services/auth/data-helper.service';
import { AppointmentTypesService } from '../../../_services/appointment-types/appointment-types.service';
import { SharedService } from '../../../_services/shared.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { SharedPopupService } from '../../../_services/popup.service';
import constants from '../../../constants';


class AppointmentTypes {
  type: String;
  code: String;
  color: String;
  description: String;
  duration: String;
  isActive: boolean;
}

@Component({
  selector: 'app-appointment-types',
  templateUrl: './appointment-types.component.html',
  styleUrls: ['./appointment-types.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppointmentTypesComponent implements OnInit, OnDestroy {
  public appointmentTypes: AppointmentTypes;
  searchValue: string;

  popupTitle: string;
  popUpfrom: any;
  reasonInstructions: any;
  popUpinfo: any;
  popUpinfoselectedIndex: any;
  parentIndexValue: any;
  appointmentTypesList: any;
  fullData: any;
  savetype: string;
  current_practice_id: String;
  timeDurationUi: Array<object>;
  showInactive: boolean;
  messageInfo: String;
  dataObject: any;
  page: number;
  pageskip: number;
  defaultLimit: number;
  currentPage: number;
  totalCount: number;
  primaryCode: string;
  searchFlag: boolean;
  subscription1$: Subscription;
  constants: any;
  constructor(public modalService: BsModalService, private headerService: HeaderService,
    private sharedService: SharedService,
    private DataHelper: DataHelperService,
    public SharedPopupService: SharedPopupService,
    private appointmentTypesService: AppointmentTypesService,
    private cdRef: ChangeDetectorRef) {
    this.searchValue = '';
    this.showInactive = false;
    this.fullData = [];
    this.defaultLimit = 10;
    this.page = 0;
    this.pageskip = 0;
    this.currentPage = 1;
    this.appointmentTypesList = [];
    this.searchFlag = true;
    if (localStorage.getItem('practice_id')) {
      let newPractice = this.DataHelper.getItem('practice_id');
      this.current_practice_id = newPractice;
      this.getAppointment();
    }
    
    this.subscription1$ = this.headerService.practiceEmitted$.subscribe(newPractice => {
      this.current_practice_id = newPractice;
      this.searchValue = '';
      this.showInactive = false;
      this.fullData = [];
      this.searchFlag = true;
      this.getAppointment();
    });

    this.timeDurationUi = [
      {
        id: 0,
        text: '15 min',
        value: '00:15'
      },
      {
        id: 1,
        text: '30 min',
        value: '00:30'
      },
      {
        id: 2,
        text: '45 min',
        value: '00:45'
      },
      {
        id: 3,
        text: '1 hr',
        value: '01:00'
      },
      {
        id: 4,
        text: '1 hr 15 min',
        value: '01:15'
      },
      {
        id: 5,
        text: '1 hr 30 min',
        value: '01:30'
      },
      {
        id: 6,
        text: '1 hr 45 min',
        value: '01:45'
      },
      {
        id: 7,
        text: '2 hr',
        value: '02:00'
      }
    ];
    this.constants=constants;
  }

  ngOnInit() {
  }

  getAppointment() {
    this.appointmentTypesList = [];
    this.resetPaginationVal();
    if (this.searchFlag) {
      this.appointmentTypesService.getAppointmentList(this.searchValue).subscribe(result => {
        if (result.status == 'success') {
          result.results.forEach(element => {
            element['tempDuration'] = element.duration.substring(1).replace(':', 'hrs ') + 'min';
          });
          this.fullData = result.results.reverse();
          this.dataMapping();
          this.searchFlag = false;
        }
      });
    } else {
      this.dataMapping();
      // if (from == 'search') {
      //   setTimeout(() => {
      //     this.resetPaginationVal();
      //   }, 1);

      // }
    }

  }

  resetPaginationVal() {
    this.page = 0;
    this.pageskip = 0;
    this.currentPage = 1;
  }

  onpageChanged(ev) {
    this.currentPage = ev.page;
    this.page = ev.page * this.defaultLimit - this.defaultLimit;
    this.pageskip = this.page;
    this.dataMapping();
  }

  openModal(template: TemplateRef<any>, from, infoData?, selectedIndex?) {
    this.popUpfrom = from;
    this.popUpinfo = infoData;
    this.popUpinfoselectedIndex = selectedIndex;

    if (from == 'newTypes') {
      this.savetype = 'add';
      this.popupTitle = 'Add Appointment Type';
      this.appointmentTypes = {
        type: '',
        code: '',
        color: '',
        description: '',
        duration: '',
        isActive: true
      };
    } else {
      this.savetype = 'edit';
      this.popupTitle = 'Edit Appointment Type';
      this.primaryCode = this.popUpinfo.code;
      this.appointmentTypes = {
        type: this.popUpinfo.type,
        code: this.popUpinfo.code,
        color: this.popUpinfo.color,
        description: this.popUpinfo.description,
        duration: this.popUpinfo.duration,
        isActive: this.popUpinfo.isActive
      };
    }

    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }

  modalClose() {
    this.SharedPopupService.dismiss();
  }

  async submittted() {
    this.appointmentTypesService.addAppointmentType(this.appointmentTypes, this.savetype, this.primaryCode).subscribe(data => {
      if (data.status == 'success') {
        if (this.savetype == 'add') {
          this.appointmentTypesList.unshift(data.result);
          this.fullData.unshift(data.result);
          this.fullData[0]['tempDuration'] = (data.result.duration != '') ? data.result.duration.substring(1).replace(':', 'hrs ') + 'min' : '';

          this.resetPaginationVal();
          this.searchValue = '';
          this.dataMapping();
          this.sharedService.pushToaster('success', 'Success', 'Appointment Type Added successfully');
        } else {
          this.appointmentTypesList = this.appointmentTypesList.map(
            item => item.code === this.primaryCode ? _.assign({}, item, this.appointmentTypes) : item,
            this.appointmentTypesList[this.popUpinfoselectedIndex].tempDuration = this.appointmentTypes.duration.substring(1).replace(':', 'hrs ') + 'min'
          );

          this.fullData = this.fullData.map(
            item => item.code === this.primaryCode ? _.assign({}, item, this.appointmentTypes) : item,
            this.fullData[this.popUpinfoselectedIndex].tempDuration = this.appointmentTypes.duration.substring(1).replace(':', 'hrs ') + 'min'
          );
          this.sharedService.pushToaster('success', 'Success', 'Appointment Type Updated successfully');
        }
        this.SharedPopupService.dismiss();
      } else {
        this.sharedService.pushToaster('error', 'error', data.message);
      }
    });
    // }
  }

  inactive(ev) {
    this.resetPaginationVal();
    this.showInactive = ev.target.checked;
    this.dataMapping();
    // this.cdRef.detectChanges();
  }

  ConfirmPopup(confirmpopup: TemplateRef<any>, from, item, i) {
    this.dataObject = {
      from: from,
      item: item,
      i: i
    };


    if (from == 'statusDeactivate') {
      if (item.isActive == true) {
        this.messageInfo = 'Are you sure want to Deactivate Appointment Types?';
      } else {
        this.messageInfo = 'Are you sure want to Activate Appointment Types?';
      }
    }

    this.SharedPopupService.show(confirmpopup, {
      animated: true,
      backdrop: 'static'
    });
  }

  onConfirm() {
    if (this.dataObject.from == 'statusDeactivate') {
      let updateData = {
        code: this.dataObject.item.code,
        isActive: !this.dataObject.item.isActive
      };
      let statusMsg = !updateData.isActive == true ? ' Deactivated' : ' Activated';
      this.appointmentTypesService.deactivate_status(updateData).subscribe(data => {
        if (data.status == 'success') {
          this.appointmentTypesList.splice(this.dataObject.i, 1);
          this.fullData = this.fullData.map(
            item => item.code == updateData.code ? _.assign({}, item, updateData) : item
          );
          this.dataMapping();
          if (this.totalCount != 0 && this.appointmentTypesList.length == 0) {
            // this.pagelimit=this.pagelimit;
            if (this.page != 0) {
              this.page = this.page - this.defaultLimit;
              this.dataMapping();
            }
          }
          this.sharedService.pushToaster('success', 'Success', 'Appointment Type Status' + statusMsg);
        }
      });
    }
    this.SharedPopupService.dismiss();
  }

  dataMapping() {
    let tempData = this.fullData.filter(item => item.isActive === !this.showInactive && (item.type).search(new RegExp(this.searchValue, 'i')) > -1);
    let endLimit = (tempData.length <= 10) ? tempData.length : this.defaultLimit;
    this.appointmentTypesList = tempData.slice(this.page, this.page + endLimit);
    this.totalCount = tempData.length;
    // this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.subscription1$.unsubscribe();
    this.SharedPopupService.dismiss();
  }
}
