import * as moment from "moment";
function regiondateformat(date)  {
    let returnDate;
    let country = localStorage.getItem('Region');
    if(country == 'US'){
      returnDate=moment(date).format('MM/DD/YYYY')
      return returnDate;
    }else if(country=="UAE"){
        returnDate=moment(date).format('DD/MM/YYYY')
        return returnDate;
    }
}
export default  regiondateformat ;