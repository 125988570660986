import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as myGlobals from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class AppointmentTypesService {

  constructor(private http: HttpClient) { }

  getAppointmentList(searchkey: String) {
    const url = myGlobals.current_Practice + '/appointmenttype?q=' + searchkey;
    return this.http.get<any>(url);
  }

  // Create a new Appointment Type
  addAppointmentType(data, from, primaryCode) {
    if (from == 'add') {
      return this.http.post<any>(myGlobals.current_Practice + '/appointmenttype', data);
    } else {
      data['primaryCode'] = primaryCode;
      return this.http.put<any>(myGlobals.current_Practice + '/appointmenttype', data);
    }

  }

  // update a existing Appointment Type
  deactivate_status(data: any) {
    return this.http.put<any>(myGlobals.current_Practice + '/appointmenttype/status', data);
  }
}
