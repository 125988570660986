import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { throwError, Observable, of, BehaviorSubject, empty } from 'rxjs';
import { catchError, filter, take, switchMap, tap, finalize } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';
import { DataHelperService } from './data-helper.service';
import { SharedService } from '../../_services/shared.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private AUTH_HEADER = "Authorization";
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private refreshTokenInProgress = false;

  constructor( private sharedService: SharedService, private authenticationService: AuthenticationService, private dataHelperService: DataHelperService) {
    // console.log('hi token interceptor');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // request = this.addHeader(request);
    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json')
      });
    }
    request = this.addAuthenticationToken(request);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // console.log('error', error);
        if(error.status >= 500 ){
          this.sharedService.pushToaster('error',"Technical Issues","Please try again later");
        }else if (error.status == 401 && (error.error.error == 'Authorization-expired' || error.error.error == 'Authorization-mismatch')) {
          // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
          // which means the new token is ready and we can retry the request again
          if (this.refreshTokenInProgress) {
            return this.refreshTokenSubject.pipe(
              filter(result => result !== null),
              take(1),
              switchMap(() => next.handle(this.addAuthenticationToken(request)))
            )
          }
          
          else {
            this.refreshTokenInProgress = true;

            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);

            let userid = this.dataHelperService.getItem('userid');
            let refreshToken = localStorage.getItem('authRefreshToken');
            let authToken = localStorage.getItem('authToken');

            return this.authenticationService.checkRefreshToken(userid, refreshToken, authToken).pipe(  //return this.refreshAccessToken().pipe(
              tap((resp: any) => {
                // console.log('refreshtoken auth response tap', resp);
                if (resp && resp.status == 'success') {
                  localStorage.setItem('authToken', resp.authToken);
                  this.authenticationService.authToken = resp.authToken;
                  // this.fcm.listenNotification();
                } else {
            //console.log('no refresh auth response')
                  return of(false);
                }
              }),
              switchMap((success: boolean) => {
                // can we store the token here
                this.refreshTokenSubject.next(success);
                return next.handle(this.addAuthenticationToken(request));
              }),
              catchError((error) => {
                return empty()
              }),
              // When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              finalize(() => this.refreshTokenInProgress = false)
            );
          }
        } else if (this.authenticationService.getToken() == '' || error.error.error == 'Authorization-noheader') {
          this.authenticationService.logout();
          return throwError(error);
        } else {
          // console.log('unhandled error', error.error);
          return throwError(error);
        }
      })
    );
  }

  private addHeader(request) {
    let token = this.authenticationService.getToken();

    if (token) {
      return request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      });
    }
    return request;
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    // If we do not have a token yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.
    if (!this.authenticationService.getToken()) {
//console.log('no token')
      return request;
    }
    // If you are calling an outside domain then do not add the token.
    if (!request.url.match(/globalhealthcareresource.io\/|claimocity.io\/|localhost:/)) {
      return request;
    }
    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, this.authenticationService.getToken()) //"Bearer " + 
    });
  }
}
