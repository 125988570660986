import { Component, OnInit, Input,Output,EventEmitter} from '@angular/core';
import { UsersService } from '../../_services/settings/users.service';
import { SharedService } from '../../_services/shared.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  @Input() userInformation: any;
  @Output() popupClose = new EventEmitter();
  popupUserInfo: any;
  resetPasswordType: number;
  tempConfirmPassword: string;
  tempPassword: string;
  popupTitle: string;

  constructor(private userService: UsersService, private sharedService: SharedService) {
    this.popupUserInfo = {};
    this.resetPasswordType = 0;
    this.tempConfirmPassword = '';
    this.tempPassword = '';
    this.popupTitle = 'Reset password';
    //console.log(this.userInformation,"userinfo");
   }

   
   ngOnChanges(){
    this.popupUserInfo = this.userInformation;
    //console.log(this.userInformation,"userinfo");
   }
   passwordresetting() {
    //console.log(this.resetPasswordType,"rewreset"); 
    if (this.resetPasswordType == 1) {
      if (this.popupUserInfo.username) {
        this.userService.resetPasswordWithOTP(this.popupUserInfo.username).subscribe(data => {
          if (data.status == 'success') {
            this.sharedService.pushToaster('success', 'Success', 'OTP Sent to your mobile number.'); // Please enter the OTP to reset your password
            //this.modalClose();
            this.popupClose.emit();
          } else {
            this.sharedService.pushToaster('error', 'Failure', data.message);
          }
        });
      }
    } else if (this.resetPasswordType == 2) {
      //console.log(this.popupUserInfo,"rer4")
      if (this.popupUserInfo._id || this.popupUserInfo.user_id) {
        let user_id = this.popupUserInfo.user_id || this.popupUserInfo._id;
        this.userService.resetPasswordWithEmail(user_id).subscribe(data => {
          if (data.status == 'success') {
            this.sharedService.pushToaster('success', 'Success', 'Check your registered email to reset your password.'); // Please enter the OTP to reset your password
           // this.modalClose();
           this.popupClose.emit();
          } else {
            this.sharedService.pushToaster('error', 'Failure', data.message);
          }
        });
      }
    } else if (this.resetPasswordType == 3) {
      let strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');
      let password = this.tempPassword;

      if (this.tempPassword == this.tempConfirmPassword) {
        if (password.match(strongRegex)) {

          let data = {
            'userId': this.popupUserInfo._id,
            'newpassword': this.tempPassword
          };

          this.userService.setTemporaryPassword(data).subscribe(data => {
            if (data.status == 'success') {
              this.sharedService.pushToaster('success', 'Success', 'Temporary password sent to your registered email.');
             // this.modalClose();
             this.popupClose.emit();
            } else {
              this.sharedService.pushToaster('error', 'Failure', data.message);
            }
          });

        } else {
          this.sharedService.pushToaster('error', 'Failure', 'Password should be 8 characters with 1 lowercase, 1 uppercase, 1 special character, 1 numeric');
        }
      } else {
        this.sharedService.pushToaster('error', 'Failure', 'Password does not match. please try again');
      }
    } else if (this.resetPasswordType == 0) {
      this.sharedService.pushToaster('error', 'Alert', 'Please select any option to reset your password');
    }
  }
  ngOnInit(): void {
  }

}
