
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as myGlobals from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class SkillsService {

  constructor(private http: HttpClient) { }

  skillCategorylist(name: String, showActive: any) {
    return this.http.get<any>(myGlobals.Skill_Category_URL + '?q=' + name + '&showActive=' + showActive);
  }

  addSkillSet(data) {
    return this.http.post<any>(myGlobals.Base_URL + '/skillset', data);
  }

  updateSkillSet(data) {
    return this.http.put<any>(myGlobals.Base_URL + '/skillset', data);
  }

  addCategory(categoryData) {
    return this.http.post<any>(myGlobals.Skill_Category_URL + '/add', categoryData);
  }

  editCategory(updateData) {
    let categoryid = updateData.categoryid;
    return this.http.put(myGlobals.Skill_Category_URL + '/' + categoryid, updateData);
  }

  activeCategory(categoryid: string, status: string) {
    let req = {};
    req = { status: status };
    return this.http.put(myGlobals.Skill_Category_URL + '/' + categoryid + '/' + 'status', req);
  }
  get_users_list(checklist: any[]) {
    let checklistid = { 'taskid': checklist };
    return this.http.post<any>(myGlobals.User_URL + '/userbased', checklistid);
  }
  update_user_provider(user_id, type, data) {
    return this.http.put<any>(myGlobals.User_URL + '/' + user_id + '/' + type, data)
  }
 
  skillsetList(searchKey){
    return this.http.get<any>(myGlobals.Skill_Set_URL+"?search="+searchKey)
  }
  updateuserSkill(user_id,skillData){
    return this.http.put<any>(myGlobals.Skill_Set_URL+'/'+user_id+'/update_skill',skillData);
  }
  existing_user_mapped_skill(user_id){
    return this.http.get<any>(myGlobals.Skill_Set_URL+'/'+user_id+"/mapped/user_skill");
  }
  remove_single_skill(user_id,skill_id){
    return this.http.put<any>(myGlobals.Skill_Set_URL+'/'+user_id+"/"+skill_id+"/removeSkill",{})
  }
 
}
