
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserIdleService } from 'angular-user-idle';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import * as myGlobals from '../../globals';
//import { SocketConversationService } from '../socket/socket-conversation.service';
//import { SocketVisitService } from '../socket/socket-visit.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  redirectUrl: string;
  logoutMsg: any;
  authToken: any;

  constructor(private http: HttpClient, private routers: Router, private jwtHelperService: JwtHelperService, private userIdle: UserIdleService, 
    //private socket: SocketConversationService,
    //private socketVisitService: SocketVisitService
    ) {
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  loginAuth(username: string, password: string): any {
    let creds = { 'name': username, 'password': password };
    return this.http.post<any>(myGlobals.LoginAuth_URL, creds).pipe(tap(data => {
      if (data.status == 'success') {
        localStorage.setItem('timer', new Date().toISOString());
      }
    }));
  }
  loginAuth2(username: string, password: string): any {
    let creds = { 'name': btoa(username), 'password': btoa(password) };
    return this.http.post<any>(myGlobals.LoginAuth_URL_V3, creds).pipe(tap(data => {
      if (data.status == 'success') {
        localStorage.setItem('permissions', JSON.stringify(data.permissions));
        localStorage.setItem('timer', new Date().toISOString());
      }
    }));
  }
  public getToken(): string {
    let token = localStorage.getItem('authToken');
    this.authToken = localStorage.getItem('authToken');
    if (token) {
      if (localStorage.getItem('timer')) { // checking time difference at any action if more than 15 min. than log out
        let timer = localStorage.getItem('timer');
        let timeDiff = moment().diff(moment(timer), 'minutes');
        if (timeDiff > 15) {
          this.logoutMsg = {
            'message': 'Timeout..Please login again!'
          };
          this.logout();
          return null;
        } else {
          try {
            const decodeToken = this.jwtHelperService.decodeToken(token);
            // check if it was decoded successfully, if not the token is not valid, deny access
            if (!decodeToken) {
              return null;

            } else {
              localStorage.setItem('timer', new Date().toISOString());
              return token;
            }
          } catch (err) {
            return null;
          }
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  logout() {
    this.clear();
    this.redirectUrl = '';
    this.routers.navigate(['/']);
  }
  clear() {
    //this.socket.disconnect();
    //this.socketVisitService.disconnect();
    localStorage.clear();
  }
  clearToken() {
    return this.http.delete(myGlobals.Get_ServiceLayer_Url + '/cleartoken');
  }
  GetEnvironment(): any {
    return this.http.post(myGlobals.GetEnv_URL, '').retry(1);
  }
  getImage() {
    let url = 'https://collector-dev.claimocity.io/files/5c359e38e0058644f1fc8783/attachments/5aed54e33d9692be4af7b3b2_1536632215326Edp6zL.jpg';

    return this.http.get(url, {
      responseType: 'blob'
    });
  }

  changePasswordViaLink(data) {
    return this.http.post<any>(myGlobals.ResetPassword_URL + '/changePassword', data);
  }

  changeTemporaryPassword(data, auth) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': auth
      })
    };

    return this.http.post<any>(myGlobals.ResetPassword_URL + '/changeTempPassword', data, httpOptions);
  }

  validateToken(id: string, token: string) {
    let credss = { 'id': id, 'token': token };
    return this.http.post<any>(myGlobals.ResetPassword_URL + '/validateToken', credss);
  }
  setPassword(param) {
    return this.http.post<any>(myGlobals.ResetPassword_URL + '/setpassword', param);
  }
  resetpassword(Rusername: string) {
    var credss = { "name": Rusername };
    return this.http.post(myGlobals.ResetPassword_URL, credss);
  }
  generateToken(id: string) {
    return this.http.post(myGlobals.ResetPassword_URL + "/generateToken/" + id, "");
  }
  generateOtp(user_info) {
    return this.http.post<any>(myGlobals.Get_ServiceLayer_Url + "/mfa/otp", user_info);
  }
  generateMilOtp(user_info) {
    return this.http.post<any>(myGlobals.Get_ServiceLayer_Url + "/mfa/mailOtp", user_info)
  }
  checkRefreshToken(userId, refreshToken, authToken) {
    let req = { userId: userId, refreshToken: refreshToken, authToken: authToken }
    return this.http.post<any>(myGlobals.Get_ServiceLayer_Url + "/refreshtoken", req);
  }
  verifyOTp(user) {
    return this.http.post<any>(myGlobals.Get_ServiceLayer_Url + "/verify/otp", user)
  }
}
