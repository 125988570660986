import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '../../globals';
@Injectable({
  providedIn: 'root'
})
export class CustomListService {

  constructor(private http: HttpClient) { }

  getAllCustomListData() {
    return this.http.get<any>(myGlobals.Base_URL + '/customlists');
  }
  addNewCustomList(sendData) {
    return this.http.post<any>(myGlobals.Base_URL + '/customlists/', sendData);
  }
  editCustomList(sendData, list_id) {
    return this.http.put<any>(myGlobals.Base_URL + '/customlists/' + list_id, sendData);
  }
  deleteCustomList(list_id) {
    return this.http.delete<any>(myGlobals.Base_URL + '/customlists/' + list_id);
  }
}
