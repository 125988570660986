import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PromeniService } from '../../_services/promeni/promeni.service';
import { SharedService } from '../../_services/shared.service';
import * as moment from 'moment';
import { DataHelperService } from '../../_services/auth/data-helper.service';
import constants from '../../constants';

@Component({
  selector: 'app-promeni-details',
  templateUrl: './promeni-details.component.html',
  styleUrls: ['./promeni-details.component.css']
})
export class PromeniDetailsComponent implements OnInit {
  @Input('taskData') taskData: any;
  @Output() newItemEvent = new EventEmitter<string>();
  estimationAmtPatient: number | null;
  estimationAmtPrimaryPayer: number | null;
  estimationAmtSecondaryPayer: number | null;
  estimationAmtTertiaryPayer: number | null;
  loopdata: any;
  patientsloopdata: any;
  sequence: any;
  totalChargeAmt: any;
  totalPrimaryPaidAmt: any;
  totalSecondaryPaidAmt: any;
  totalTertiaryPaidAmt: any;
  totalPatientPaidAmt: any;
  constants: any;
  loaderStatus: boolean;
  emptyresults: boolean;
  baderror:boolean;
  totalActualAmount: any;
  totalPredictedAmount: any;
  actualAccuracy: any;
  PrimaryInsuranceDate: any;
  SecondaryInsuranceDate: any;
  TertiaryInsuranceDate: any;
  PatientInsuranceDate: any;
  estimated_duration_primary_payer_min: any;
  estimated_duration_primary_payer_max:any;
  estimated_duration_secondary_payer_min:any;
  estimated_duration_secondary_payer_max:any;
  estimated_duration_tertiary_payer_min:any;
  estimated_duration_tertiary_payer_max:any;
  estimated_duration_patient_min:any;
  estimated_duration_patient_max:any;

  constructor(public promeniService: PromeniService, private dataHelperService: DataHelperService, private sharedService: SharedService) {
    this.loopdata = [];
    this.patientsloopdata = [];
    this.sequence = ['Primary', 'Secondary', 'Tertiary']
    this.totalChargeAmt = 0;
    this.totalPrimaryPaidAmt = 0;
    this.totalSecondaryPaidAmt = 0;
    this.totalTertiaryPaidAmt = 0;
    this.totalPatientPaidAmt = 0;
    this.PrimaryInsuranceDate = 0;
    this.SecondaryInsuranceDate = 0;
    this.PatientInsuranceDate = 0;
    this.TertiaryInsuranceDate = 0;
    this.constants = constants;
    this.loaderStatus = false;
    this.emptyresults = false;
    this.baderror = false;
    this.totalActualAmount = "0.00";
    this.totalPredictedAmount = "0.00";
    this.actualAccuracy = "0.00";
    this.estimated_duration_primary_payer_min=0;
    this.estimated_duration_primary_payer_max=0;
    this.estimated_duration_secondary_payer_min=0;
    this.estimated_duration_secondary_payer_max=0;
    this.estimated_duration_tertiary_payer_min=0;
    this.estimated_duration_tertiary_payer_max=0;
    this.estimated_duration_patient_min=0;
    this.estimated_duration_patient_max=0;
  }

  ngOnInit(): void {
  }

  ngOnChanges():void{
    if(this.taskData.tasktype == 'Promeni') {
      this.totalPrimaryPaidAmt=0;
      this.totalSecondaryPaidAmt=0;
      this.totalTertiaryPaidAmt=0;
      this.totalPatientPaidAmt=0;
      this.PrimaryInsuranceDate = 0;
      this.SecondaryInsuranceDate = 0;
      this.TertiaryInsuranceDate = 0;
      this.PatientInsuranceDate = 0;
      this.totalChargeAmt=0;
      this.loopdata = this.taskData.charge_info[0].service_lines?this.taskData.charge_info[0].service_lines:[];
      this.loaderStatus=false;
      this.emptyresults=true;
      this.baderror = false;
      let estimationAmtPatient= 0;
      let estimationAmtPrimaryPayer=0;
      let estimationAmtSecondaryPayer = 0;
      let estimationAmtTertiaryPayer = 0;
      this.estimationAmtPatient= 0;
      this.estimationAmtPrimaryPayer=0;
      this.estimationAmtSecondaryPayer = 0;
      this.estimationAmtTertiaryPayer = 0;
      this.actualAccuracy = "0.00";
      this.estimated_duration_primary_payer_min=0;
      this.estimated_duration_primary_payer_max=0;
      this.estimated_duration_secondary_payer_min=0;
      this.estimated_duration_secondary_payer_max=0;
      this.estimated_duration_tertiary_payer_min=0;
      this.estimated_duration_tertiary_payer_max=0;
      this.estimated_duration_patient_min=0;
      this.estimated_duration_patient_max=0;
      
      this.loopdata.forEach((element,index) => {
        this.emptyresults=false;
        let charge_amount = Number(element.charge_amount);
        element.charge_amount = Number(element.charge_amount).toFixed(2);
        element.service_date_from = moment(element.service_date_from).format('MM/DD/YYYY')
        let dos = moment(element.service_date_from);
        if (element.Primary_Insurance_Date) {
          let end = moment(element.Primary_Insurance_Date);
          let primaryDiffDate = end.diff(dos, 'days');
          this.PrimaryInsuranceDate = (Math.sign(primaryDiffDate) == 1) ? primaryDiffDate : 0;
        }
        if (element.Secondary_Insurance_Date) {
          let end1 = moment(element.Secondary_Insurance_Date);
          let secondryDiffDate = end1.diff(dos, 'days');
          this.SecondaryInsuranceDate = (Math.sign(secondryDiffDate) == 1) ? secondryDiffDate : 0;
        }
        if (element.Tertiary_Insurance_Date) {
          let end2 = moment(element.Tertiary_Insurance_Date);
          let tertiaryDiffDate = end2.diff(dos, 'days');
          this.TertiaryInsuranceDate = (Math.sign(tertiaryDiffDate) == 1) ? tertiaryDiffDate : 0;
        }
        if (element.Patient_Insurance_Date) {
          let end3 = moment(element.Patient_Insurance_Date);
          let patientDiffDate = end3.diff(dos, 'days');
          this.PatientInsuranceDate = (Math.sign(patientDiffDate) == 1) ? patientDiffDate : 0;
        }
        this.totalChargeAmt += charge_amount;
        this.totalPrimaryPaidAmt += element.Primary_Insurance_Amount;
        this.totalSecondaryPaidAmt += element.Secondary_Insurance_Amount;
        this.totalTertiaryPaidAmt += element.Tertiary_Insurance_Amount;
        this.totalPatientPaidAmt += element.Patient_Insurance_Amount;

        element.Primary_Insurance_Amount = element.Primary_Insurance_Amount ? Number(element.Primary_Insurance_Amount).toFixed(2) : "0.00";
        element.Secondary_Insurance_Amount = element.Secondary_Insurance_Amount ? Number(element.Secondary_Insurance_Amount).toFixed(2) : "0.00";
        element.Tertiary_Insurance_Amount = element.Tertiary_Insurance_Amount ? Number(element.Tertiary_Insurance_Amount).toFixed(2) : "0.00";
        element.Patient_Insurance_Amount = element.Patient_Insurance_Amount ? Number(element.Patient_Insurance_Amount).toFixed(2) : "0.00";
        element.list_id = this.taskData.charge_info[0]._id + element.procedure_code;
        let predictions = this.taskData.charge_info[0].Promeni_predictions && this.taskData.charge_info[0].Promeni_predictions.length>0 ? this.taskData.charge_info[0].Promeni_predictions:[];
        element.primary_accuracy = "0.00";
        element.secondary_accuracy = "0.00";
        element.tertiary_accuracy = "0.00";
        element.patient_accuracy ="0.00"
        
        
        predictions.forEach(predictions => {
          if(predictions.line_id == element.list_id){
            element.primary_predicted_amount = parseFloat(predictions.estimated_amount_primary_payer).toFixed(2);
            element.secondary_predicted_amount = parseFloat(predictions.estimated_amount_secondary_payer).toFixed(2);
            element.tertiary_predicted_amount = parseFloat(predictions.estimated_amount_tertiary_payer).toFixed(2);
            element.patient_predicted_amount = parseFloat(predictions.estimated_amount_patient).toFixed(2);
            estimationAmtPatient += parseFloat(predictions.estimated_amount_patient);
            estimationAmtPrimaryPayer += parseFloat(predictions.estimated_amount_primary_payer);
            estimationAmtSecondaryPayer += parseFloat(predictions.estimated_amount_secondary_payer);
            estimationAmtTertiaryPayer += parseFloat(predictions.estimated_amount_tertiary_payer);
           // min max days
            this.estimated_duration_primary_payer_min += predictions.estimated_duration_primary_payer.min ? parseFloat(predictions.estimated_duration_primary_payer.min):0;
            this.estimated_duration_primary_payer_max += predictions.estimated_duration_primary_payer.max ? parseFloat(predictions.estimated_duration_primary_payer.max):0;
            this.estimated_duration_secondary_payer_min += predictions.estimated_duration_secondary_payer.min ? parseFloat(predictions.estimated_duration_secondary_payer.min):0;
            this.estimated_duration_secondary_payer_max += predictions.estimated_duration_secondary_payer.max ? parseFloat(predictions.estimated_duration_secondary_payer.max):0;
            this.estimated_duration_tertiary_payer_min += predictions.estimated_duration_tertiary_payer.min ? parseFloat(predictions.estimated_duration_tertiary_payer.min):0;
            this.estimated_duration_tertiary_payer_max += predictions.estimated_duration_tertiary_payer.max ? parseFloat(predictions.estimated_duration_tertiary_payer.max):0;
            this.estimated_duration_patient_min += predictions.estimated_duration_patient.min ? parseFloat(predictions.estimated_duration_patient.min):0;
            this.estimated_duration_patient_max += predictions.estimated_duration_patient.max ? parseFloat(predictions.estimated_duration_patient.max):0;


            let primaryAccuracy = (parseFloat(element.primary_predicted_amount) != 0 && parseFloat(element.Primary_Insurance_Amount) != 0) ? ((1 - Math.abs(parseFloat(element.primary_predicted_amount) / parseFloat(element.Primary_Insurance_Amount) - 1))) * 100 : 0;
            let secondaryAccuracy = (parseFloat(element.secondary_predicted_amount) != 0 && parseFloat(element.Secondary_Insurance_Amount) != 0) ? ((1 - Math.abs(parseFloat(element.secondary_predicted_amount) / parseFloat(element.Secondary_Insurance_Amount) - 1))) * 100 : 0;
            let tertiaryAccuracy = (parseFloat(element.tertiary_predicted_amount) != 0 && parseFloat(element.Tertiary_Insurance_Amount) != 0) ? ((1 - Math.abs(parseFloat(element.tertiary_predicted_amount) / parseFloat(element.Tertiary_Insurance_Amount) - 1))) * 100 : 0;
            let patientAccuracy = (parseFloat(element.patient_predicted_amount) != 0 && parseFloat(element.Patient_Insurance_Amount) != 0) ? ((1 - Math.abs(parseFloat(element.patient_predicted_amount) / parseFloat(element.Patient_Insurance_Amount) - 1))) * 100 :0;


            element.primary_accuracy = Math.sign(primaryAccuracy) == 1 ? (primaryAccuracy).toFixed(2) : "0.00";
            element.secondary_accuracy = Math.sign(secondaryAccuracy) == 1 ? (secondaryAccuracy).toFixed(2) : "0.00";
            element.tertiary_accuracy = Math.sign(tertiaryAccuracy) == 1 ? (tertiaryAccuracy).toFixed(2) : "0.00";
            element.patient_accuracy = Math.sign(patientAccuracy) == 1 ? (patientAccuracy).toFixed(2) : "0.00";

            // element.primary_accuracy = (parseFloat(element.primary_predicted_amount) !=0 && parseFloat(element.Primary_Insurance_Amount) != 0)?  ((parseFloat(element.primary_predicted_amount)/parseFloat(element.Primary_Insurance_Amount))*100).toFixed(2):"0.00";
            // element.secondary_accuracy = (parseFloat(element.secondary_predicted_amount) !=0 && parseFloat(element.Secondary_Insurance_Amount) != 0)?  ((parseFloat(element.secondary_predicted_amount)/parseFloat(element.Secondary_Insurance_Amount))*100).toFixed(2):"0.00";
            // element.tertiary_accuracy = (parseFloat(element.tertiary_predicted_amount) != 0 && parseFloat(element.Tertiary_Insurance_Amount) != 0)?   ((parseFloat(element.tertiary_predicted_amount)/parseFloat(element.Tertiary_Insurance_Amount))*100).toFixed(2):"0.00";
            // element.patient_accuracy = (parseFloat(element.patient_predicted_amount) != 0 && parseFloat(element.Patient_Insurance_Amount) != 0)?   ((parseFloat(element.patient_predicted_amount)/parseFloat(element.Patient_Insurance_Amount))*100).toFixed(2):"0.00";
          }
        });
        element.service_location = element.service_location ? element.service_location : "";
        element.charge_unit = element.charge_unit ? element.charge_unit : "";
      });
      this.estimationAmtPatient = estimationAmtPatient;
      this.estimationAmtPrimaryPayer = estimationAmtPrimaryPayer;
      this.estimationAmtSecondaryPayer = estimationAmtSecondaryPayer;
      this.estimationAmtTertiaryPayer = estimationAmtTertiaryPayer;
      this.totalActualAmount = parseFloat(this.totalPrimaryPaidAmt) + parseFloat(this.totalSecondaryPaidAmt) + parseFloat(this.totalTertiaryPaidAmt) + parseFloat(this.totalPatientPaidAmt);
      this.totalPredictedAmount = this.estimationAmtPatient + this.estimationAmtPrimaryPayer + this.estimationAmtSecondaryPayer + this.estimationAmtTertiaryPayer;
      let actualAccuracy = (this.totalPredictedAmount != 0 && this.totalActualAmount != 0) ? (parseFloat((1 - Math.abs(parseFloat(this.totalPredictedAmount) / parseFloat(this.totalActualAmount) - 1)).toFixed(2))) * 100 : 0;
      this.actualAccuracy = Math.sign(actualAccuracy) == 1 ? (actualAccuracy).toFixed(2) : "0.00";
    }
  }

  promeniPrediction(){
    this.loaderStatus=true;
    let chargesData=[];
    this.loopdata.forEach(element => {

      let cData={
        "line_id": this.taskData.charge_info[0]._id + element['procedure_code'],
        "gender": this.taskData.patient.gender,
        "gurantor_relation": "SELF",
        "dob": moment(this.taskData.patient.dob).format('YYYY-MM-DD'),
        "dos": moment(element.service_date_from).format('YYYY-MM-DD'),
        "cpt": element['procedure_code'],
        "practice_id": this.taskData.practice_id,
        "provider_id": this.taskData.provider_id,
        "primary_payer_name": element.Primary_Insurance_Name,
        "secondary_payer_name": element.Secondary_Insurance_Name,
        "tertiary_payer_name": element.Tertiary_Insurance_Name,
        "charge_unit": element.charge_unit,
        "service_location":element.service_location,
        "npi":Number(this.taskData.provider_info[0].npi) 
      };

      chargesData.push(cData);
    });
      

      let data={
        user_id:this.dataHelperService.getItem('userid'),
        user_mail:this.dataHelperService.getItem('usermail'),
        user_name:this.dataHelperService.getItem('currentUser'),
        mobile_no:"",
        application_name:"portal",
        ip_address:"193.4.5.4"
      }
    this.promeniService.authenticate(data).subscribe(resp => {
      if(resp.data.token){
        chargesData[0].token = resp.data.token;
        chargesData[0].taskID = this.taskData._id;
        chargesData[0].ChargesID = this.taskData.charge_info[0]._id;
        this.promeniService.calculatePrediction(chargesData).subscribe(resp => {
          if(resp.code == "1"){
            let response = resp.data;
            this.sharedService.pushToaster('success', 'Success', 'Predictions updated successfully');
            let estimated_amount_patient = 0;
            let estimated_amount_primary_payer = 0;
            let estimated_amount_secondary_payer = 0;
            let estimated_amount_tertiary_payer = 0;
            this.estimated_duration_primary_payer_min=0;
            this.estimated_duration_primary_payer_max=0;
            this.estimated_duration_secondary_payer_min=0;
            this.estimated_duration_secondary_payer_max=0;
            this.estimated_duration_tertiary_payer_min=0;
            this.estimated_duration_tertiary_payer_max=0;
            this.estimated_duration_patient_min=0;
            this.estimated_duration_patient_max=0;
            response.forEach(element => {
              this.loopdata.forEach(ele => {
                if (ele.list_id == element.line_id) {
                  ele.primary_predicted_amount = element.estimated_amount_primary_payer ? parseFloat(element.estimated_amount_primary_payer).toFixed(2) : "0.00";
                  ele.secondary_predicted_amount = element.estimated_amount_secondary_payer ? parseFloat(element.estimated_amount_secondary_payer).toFixed(2) : "0.00";
                  ele.tertiary_predicted_amount = element.estimated_amount_tertiary_payer ? parseFloat(element.estimated_amount_tertiary_payer).toFixed(2) : "0.00";
                  ele.patient_predicted_amount = element.estimated_amount_patient ? parseFloat(element.estimated_amount_patient).toFixed(2) : "0.00";
                    
                  let primaryAccuracy = (parseFloat(element.estimated_amount_primary_payer) != 0 && parseFloat(ele.Primary_Insurance_Amount) != 0) ? ((1 - Math.abs(parseFloat(element.estimated_amount_primary_payer) / parseFloat(ele.Primary_Insurance_Amount) - 1))) * 100 : 0;
                  let secondaryAccuracy = (parseFloat(element.estimated_amount_secondary_payer) != 0 && parseFloat(ele.Secondary_Insurance_Amount) != 0) ? ((1 - Math.abs(parseFloat(element.estimated_amount_secondary_payer) / parseFloat(ele.Secondary_Insurance_Amount) - 1))) * 100 : 0;
                  let tertiaryAccuracy = (parseFloat(element.estimated_amount_tertiary_payer) != 0 && parseFloat(ele.Tertiary_Insurance_Amount) != 0) ? ((1 - Math.abs(parseFloat(element.estimated_amount_tertiary_payer) / parseFloat(ele.Tertiary_Insurance_Amount) - 1))) * 100 : 0;
                  let patientAccuracy = (parseFloat(element.estimated_amount_patient) != 0 && parseFloat(ele.Patient_Insurance_Amount) != 0) ? ((1 - Math.abs(parseFloat(element.estimated_amount_patient) / parseFloat(ele.Patient_Insurance_Amount) - 1))) * 100 : 0;
                  ele.primary_accuracy = Math.sign(primaryAccuracy) == 1 ? (primaryAccuracy).toFixed(2) : "0.00";
                  ele.secondary_accuracy = Math.sign(secondaryAccuracy) == 1 ? (secondaryAccuracy).toFixed(2) : "0.00";
                  ele.tertiary_accuracy = Math.sign(tertiaryAccuracy) == 1 ? (tertiaryAccuracy).toFixed(2) : "0.00";
                  ele.patient_accuracy = Math.sign(patientAccuracy) == 1 ? (patientAccuracy).toFixed(2) : "0.00";
  
                  // ele.primary_accuracy =  (parseFloat((1-Math.abs(parseFloat(element.estimated_amount_primary_payer)/(parseFloat(ele.Primary_Insurance_Amount)-1))).toFixed(2))*100).toFixed(2);
                  // ele.secondary_accuracy =  (parseFloat((1-Math.abs(parseFloat(element.estimated_amount_secondary_payer)/(parseFloat(ele.Secondary_Insurance_Amount)-1))).toFixed(2))*100).toFixed(2);
                  // ele.tertiary_accuracy =   (parseFloat((1-Math.abs(parseFloat(element.estimated_amount_tertiary_payer)/(parseFloat(ele.Tertiary_Insurance_Amount)-1))).toFixed(2))*100).toFixed(2);
                  // ele.patient_accuracy =  (parseFloat((1-Math.abs(parseFloat(element.estimated_amount_patient)/(parseFloat(ele.Patient_Insurance_Amount)-1))).toFixed(2))*100).toFixed(2);
                }
              });
              estimated_amount_patient += element.estimated_amount_patient ? parseFloat(element.estimated_amount_patient) : 0;
              estimated_amount_primary_payer += element.estimated_amount_primary_payer ? parseFloat(element.estimated_amount_primary_payer) : 0;
              estimated_amount_secondary_payer += element.estimated_amount_secondary_payer ? parseFloat(element.estimated_amount_secondary_payer) : 0;
              estimated_amount_tertiary_payer += element.estimated_amount_tertiary_payer ? parseFloat(element.estimated_amount_tertiary_payer) : 0;
              this.estimated_duration_primary_payer_min += element.estimated_duration_primary_payer.min ? parseFloat(element.estimated_duration_primary_payer.min):0;
             
              this.estimated_duration_primary_payer_max += element.estimated_duration_primary_payer.max ? parseFloat(element.estimated_duration_primary_payer.max):0;
              this.estimated_duration_secondary_payer_min += element.estimated_duration_secondary_payer.min ? parseFloat(element.estimated_duration_secondary_payer.min):0;
              this.estimated_duration_secondary_payer_max += element.estimated_duration_secondary_payer.max ? parseFloat(element.estimated_duration_secondary_payer.max):0;
              this.estimated_duration_tertiary_payer_min += element.estimated_duration_tertiary_payer.min ? parseFloat(element.estimated_duration_tertiary_payer.min):0;
              this.estimated_duration_tertiary_payer_max += element.estimated_duration_tertiary_payer.max ? parseFloat(element.estimated_duration_tertiary_payer.max):0;
              this.estimated_duration_patient_min += element.estimated_duration_patient.min ? parseFloat(element.estimated_duration_patient.min):0;
              this.estimated_duration_patient_max += element.estimated_duration_patient.max ? parseFloat(element.estimated_duration_patient.max):0;
            });
            this.estimationAmtPatient = estimated_amount_patient;
            this.estimationAmtPrimaryPayer = estimated_amount_primary_payer;
            this.estimationAmtSecondaryPayer = estimated_amount_secondary_payer;
            this.estimationAmtTertiaryPayer = estimated_amount_tertiary_payer;
            this.totalPredictedAmount = this.estimationAmtPatient + this.estimationAmtPrimaryPayer + this.estimationAmtSecondaryPayer + this.estimationAmtTertiaryPayer;
            let actualAccuracy = (this.totalPredictedAmount != 0 && this.totalActualAmount != 0) ? (parseFloat((1 - Math.abs(parseFloat(this.totalPredictedAmount) / parseFloat(this.totalActualAmount) - 1)).toFixed(2))) * 100 : 0;
            this.actualAccuracy = Math.sign(actualAccuracy) == 1 ? (actualAccuracy).toFixed(2) : "0.00";
            this.newItemEvent.emit('promeni api called');
          }else if (resp.code){            
            let errortoster=resp.description
            this.sharedService.pushToaster('error', 'error',errortoster);
            this.baderror=true;
          } 
          this.loaderStatus = false;
         // this.baderror=false;
        })
      }
    });
  }

}
