import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

/*Ag-grid license code start*/
import { LicenseManager } from '@ag-grid-enterprise/core';
// LicenseManager.setLicenseKey("Evaluation_License_Valid_Until__13_October_2018__MTUzOTM4NTIwMDAwMA==b5fbbcd5248da7b75520ceb913b70cfb");
LicenseManager.setLicenseKey('Claimocity_Inc._MultiApp_1Devs14_November_2019__MTU3MzY4OTYwMDAwMA==4a7d9b97b00edf952975c965f4b99767');
/*Ag-grid license code end*/

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, {
  preserveWhitespaces: true
})
  .catch(err => console.log(err));
