import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import * as myGlobals from '../../globals';



@Injectable({
  providedIn: 'root'
})
export class InvoicingService {

  constructor(private http: HttpClient) { }

  getPracticeInvoices(data) {
    return this.http.get<any>(myGlobals.invoicing_URL + '/settings?searchkey='+data.searchKey+'&limit='+data.limit+'&skip='+data.skip);
  }

  savePracticeInvoice(data, practiceinvoiceid){
    return this.http.put<any>(myGlobals.invoicing_URL + '/settings/' + practiceinvoiceid, data);
  }

  invoiceUpdateStatus(data, practiceinvoiceid){
    return this.http.put<any>(myGlobals.invoicing_URL + '/settings/' + practiceinvoiceid + '/status', data);
  }

  getPracticeInvoicesById(invoiceId) {
    return this.http.get<any>(myGlobals.invoicing_URL + '/settings/' + invoiceId);
  }

}
