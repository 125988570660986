import { Directive, HostListener, ElementRef, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[trim]'
})
export class TrimInputDirective {

  constructor(private _el: ElementRef,
    @Self() private ngControl: NgControl) {
    }

  @HostListener('blur', ['$event']) ngOnChanges(event) {
    const initalValue = this._el.nativeElement.value;

    this._el.nativeElement.value = initalValue.replace(/\s+/g, ' ').trim();
    this.ngControl.control.patchValue(this._el.nativeElement.value);
    if ( initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
