import { Component, TemplateRef, OnInit, ViewEncapsulation, HostListener, OnDestroy, } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { NgForm } from "@angular/forms";
import { ProcedurecodesService } from "../../../../_services/settings/procedurecodes.service";
import { SharedService } from "../../../../_services/shared.service";
import * as moment from "moment";
import * as _ from "lodash";
import { Subject } from "rxjs/Rx";
import { SharedPopupService } from "../../../../_services/popup.service";
import constants from '../../../../constants';
import regiondateformat from '../../../../regiondateformat';
import { HeaderService } from '../../../../_services/header/header.service';
class Procedure {
  code: String;
  code_type: String;
  description: String;
  long_description: String;
  enabled: Boolean;
  category: String;
  from_date: string;
  to_date: string;
}

@Component({
  selector: "app-procedures-codes",
  templateUrl: "./procedures.component.html",
  styleUrls: ["./procedures.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [],
})
export class ProceduresComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  public procedure: Procedure;

  popupTitle: String;
  procedureCodeList: any;
  inactiveFlag: boolean;
  showInactive: boolean;
  ConfirmPopupfrom: any;
  userIndex: any;
  messageInfo: string;
  procedureStatus: any;

  procedureCode: any;
  searchtext: string;
  timeout = null;
  messageStatus: string;
  popupMode: String;
  addStatusMessage: String;
  cloneTmpProcedureCode: any;
  public dateMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
  from_date: any;
  to_date: any;
  date_validation: any;
  procedureIndex: number;
  typeList: any;
  disableBtnFlag: boolean;

  mobileviewTaskcontent: boolean;
  diagnosisCodeAddTotalCount: number;
  diagnosisCodeAddPage: number;
  diagnosisCodeAddCurrentPage: number;
  diagnosisCodeAddPageskip: number;
  allPaginationDefaultLimit: number;
  innerWidth: number;
  valideerrordateto: boolean;
  valideerrordatefrom: boolean;
  loaderIndicator:boolean;
  constants:any;
  country:any;
  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    this.screenValues();
  }
  constructor(
    private sharedService: SharedService,
    public bsModalService: BsModalService,
    public procedurecodesService: ProcedurecodesService,
    public SharedPopupService: SharedPopupService,
    public modalService: BsModalService,
    private headerService: HeaderService
  ) {
    this.inactiveFlag = true;
    this.searchtext = "";
    this.procedureCodeSearch(this.searchtext);
    this.procedureCodeList = [];
    this.typeList = ["CPT", "HCPCS"];
    this.disableBtnFlag = true;
    this.diagnosisCodeAddTotalCount = 0;
    this.diagnosisCodeAddPage = 0;
    this.diagnosisCodeAddCurrentPage = 1;
    this.diagnosisCodeAddPageskip = 0;
    this.allPaginationDefaultLimit = 20;
    this.innerWidth = window.innerWidth;
    this.loaderIndicator=true;
    this.constants=constants;
    this.screenValues();
    this.country = localStorage.getItem('Region');

    this.headerService.versionEmitted$.subscribe(data => {
      this.procedureCodeSearch(this.searchtext, 0, 20);
    });
  }

  ngOnInit() {
    this.procedure = {
      code: "",
      code_type: "",
      description: "",
      long_description: "",
      enabled: true,
      category: "",
      from_date: "",
      to_date: "",
    };
    this.country = localStorage.getItem('Region');
  }

  logError(arg0: any): any {
    throw new Error("Method not implemented.");
  }

  /* validate and called add/edit method */
  validateProcedureCode(model: Procedure, isValid: boolean, isEnabled) {
    
    if (isValid && this.date_validation == false) {
      this.disableBtnFlag = false;
      this.procedure = {
        code: model.code.trim(),
        code_type: model.code_type ? model.code_type : null,
        description: model.description.trim(),
        long_description: model.long_description.trim(),
        enabled: this.popupMode == "addProcedure" ? true : isEnabled,
        category: model.category ? model.category : null,
        from_date: model.from_date ? model.from_date : null,
        to_date: model.to_date ? model.to_date : null,
      };
      // tslint:disable-next-line: no-unused-expression
      isValid &&
        ("addProcedure" == this.popupMode
          ? this.addProcedureCode(this.procedure)
          : "editProcedure" == this.popupMode &&
          this.editProcedureCode(this.procedure));
    }
  }

  /* add procedure code */
  addProcedureCode(procedure: any) {
    if (this.procedure.from_date || this.procedure.to_date) {
      this.procedure.from_date = this.procedure.from_date
        ? this.procedure.from_date
        : "";
      this.procedure.to_date = this.procedure.to_date
        ? this.procedure.to_date
        : "";
    }
    this.procedurecodesService
      .addProcedureCode(procedure)
      .subscribe((response: any) => {
        this.disableBtnFlag = true;
        let res = response;
        // "success"==res.status?this.addStatusMessage="success":"error"==res.status&&(this.addStatusMessage="error");
        if ("success" == res.status) {
          this.addStatusMessage = "success";
          this.validationMessage();
          setTimeout(() => {
            if ("success" == res.status) {
              this.addStatusMessage = "";
              this.procedureCodeList.unshift(res.data);
              this.SharedPopupService.dismiss();
            }
          }, 2000);
        } else {
          this.addStatusMessage = "error";
          this.sharedService.pushToaster("error", "Error", res.message);
        }
      });
  }

  /* edit procedure code */
  editProcedureCode(procedure: any) {
    if (this.procedure.from_date || this.procedure.to_date) {
      this.procedure.from_date = this.procedure.from_date
        ? this.procedure.from_date
        : "";
      this.procedure.to_date = this.procedure.to_date
        ? this.procedure.to_date
        : "";
    }
    this.procedurecodesService
      .editProcedureCode(procedure, this.cloneTmpProcedureCode.code)
      .subscribe((response: any) => {
        this.disableBtnFlag = true;
        let status = response.status;
        if (status == "success") {
          if (response.from_date || response.to_date) {
            response.from_date = response.from_date
            response.to_date = response.to_date
          }
          this.addStatusMessage = "update";
          this.validationMessage();
          setTimeout(() => {
            if ("success" == status) {
              this.addStatusMessage = "";
              // this.appointmentTypesList = this.appointmentTypesList.map(
              //   item => item.code === this.primaryCode ? _.assign({}, item, this.appointmentTypes) : item,
              // );
              this.procedureCodeList[this.procedureIndex] = _.assign(
                {},
                this.procedure[this.procedureIndex],
                response.data
              );
              this.SharedPopupService.dismiss();
            }
          }, 2000);
        } else {
          this.addStatusMessage = "error";
          this.sharedService.pushToaster("error", "Error", response.message);
        }
      });
  }

  validationMessage() {
    // tslint:disable-next-line: no-unused-expression
    "success" == this.addStatusMessage
      ? this.sharedService.pushToaster(
        "success",
        "Added",
        "New procedure code added successfully."
      )
      : "error" == this.addStatusMessage
        ? this.sharedService.pushToaster(
          "error",
          "Already Exists",
          "Procedure Code Already Exists."
        )
        : "update" == this.addStatusMessage &&
        this.sharedService.pushToaster(
          "success",
          "Updated",
          "procedure code updated successfully."
        );
  }

  /* open popup */
  openPopup(
    template: TemplateRef<any>,
    poupMode: String,
    listItem: any,
    procedureIndex = null
  ) {
    this.popupMode = poupMode;
    this.addStatusMessage = "";
    this.cloneTmpProcedureCode = listItem;
    this.date_validation = "";
    this.from_date = "";
    this.to_date = "";
    this.procedureIndex = procedureIndex;
    this.valideerrordatefrom = true;
    this.valideerrordateto = true;







    if ("addProcedure" == this.popupMode) {
      this.popupTitle = "Add new Procedure code";
      this.procedure = {
        code: "",
        code_type: "",
        description: "",
        long_description: "",
        enabled: true,
        category: "",
        from_date: "",
        to_date: "",
      };
    } else if ("editProcedure" == this.popupMode) {
      this.popupTitle = "Edit Procedure code";
      if (listItem.from_date) {
        this.datechangedata(listItem.from_date, "datefrom", "");
      }
      if (listItem.to_date) {
        this.datechangedata(listItem.to_date, "dateto", "");
      }

      if (listItem.from_date || listItem.to_date) {
        listItem.from_date = listItem.from_date
        listItem.to_date = listItem.to_date
      }
      this.procedure = {
        code: listItem.code,
        code_type: listItem.code_type,
        description: listItem.description,
        long_description: listItem.long_description,
        enabled: listItem.enabled,
        category: listItem.category,
        from_date: listItem.from_date,
        to_date: listItem.to_date,
      };
    }
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: "static",
      keyboard: false,
    });

    this.valideerrordatefrom = true;
    this.valideerrordateto = true;

    // console.log({valideerrordateto:this.valideerrordateto});
  }

  /* close popup */
  closePopup() {
    this.SharedPopupService.dismiss();
  }

  activeStatus() {
    this.loaderIndicator=true;
    //this.procedureCodeList = [];
    this.resetAllPagination();
    this.inactiveFlag = this.showInactive == true ? true : false;
    this.procedurecodesService
      .searchProcedureCodes(this.searchtext, this.inactiveFlag)
      .subscribe((userList: any) => {
        this.procedureCodeList = [];
        this.procedureCodeList = userList.results;  
        this.loaderIndicator=false;
        this.diagnosisCodeAddTotalCount = userList.totalCount;
      });
     
  }

  procedureCodeSearch(searchKey, page = 0, limit = 20, type = "") {
    this.loaderIndicator=true;
    this.ngUnsubscribe.next();
    if (type === "") {
      this.resetAllPagination();
    }
    this.procedurecodesService
      .searchProcedureCodes(searchKey.trim(), this.inactiveFlag, page, limit)
      .debounceTime(400)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (code) => {
          if (code) {
            this.procedureCodeList = code;
            this.procedureCodeList = this.procedureCodeList.results;
            this.loaderIndicator=false;
            this.diagnosisCodeAddTotalCount = code["totalCount"];
          } else {
            this.procedureCodeList = [];
          }
        },
        (err) => this.logError(err)
      );
  }

  changeStatus(code, status) {
    let enabled = !status;
    this.procedurecodesService.changeStatus(code, enabled).subscribe(
      (data) => {
        if (data) {
          this.procedureCodeSearch(this.searchtext);
          this.sharedService.pushToaster(
            "success",
            "Success",
            "Procedure Code" + this.messageStatus + " Successfully"
          );
        }
      },
      (err) => this.logError(err)
    );
  }

  ConfirmPopup(
    Confirmtemplate: TemplateRef<any>,
    from,
    code,
    procedureStatus,
    i
  ) {
    this.ConfirmPopupfrom = from;
    this.procedureCode = code;
    this.procedureStatus = procedureStatus;
    this.userIndex = i;
    this.messageStatus =
      this.procedureStatus == true ? " Deactivate " : " Activate ";
    if (this.ConfirmPopupfrom == "codestatus") {
      this.messageInfo =
        "Are you sure want to" + this.messageStatus + "Procedure Code ?";
    }
    this.SharedPopupService.show(Confirmtemplate, {
      animated: true,
      backdrop: "static",
    });
  }

  onConfirm() {
    if (this.ConfirmPopupfrom == "codestatus") {
      this.changeStatus(this.procedureCode, this.procedureStatus);
      // this.messageInfo = "Are you sure want to Deactivate Account group ?"
    }
    this.SharedPopupService.dismiss();
  }

  onCancel() {
    this.SharedPopupService.dismiss();
  }

  datechangedata(value, from, index) {
    let dateSplit = value.split("/");
    let datePattern = "^[0-9]{2}/[0-9]{2}/[0-9]{4}$";
    let regExVal = new RegExp(datePattern);
    let patternMatchBool = regExVal.test(value);

    if (from == "datefrom") {
      this.from_date = regiondateformat(value)
    }
    if (from == "dateto") {
      this.to_date = regiondateformat(value)
    }

    if (this.popupMode == "editProcedure") {
      if (this.procedure.to_date != null) {
        this.to_date = regiondateformat(this.procedure.to_date)
      }
      if (this.procedure.from_date != null) {
        this.from_date = regiondateformat(this.procedure.from_date)
      }
      this.date_validation = moment(this.to_date).diff(this.from_date);
      this.date_validation = this.date_validation < 0 ? true : false;
    } else {
      if (
        this.from_date != "Invalid date" &&
        this.from_date != undefined &&
        this.from_date != "" &&
        this.to_date != "Invalid date" &&
        this.to_date != undefined &&
        this.to_date != ""
      ) {
        this.date_validation = moment(this.to_date).diff(this.from_date);
        this.date_validation = this.date_validation < 0 ? true : false;
      }
    }
    if (value != undefined  ) {
      if(this.country== 'US'){
        let date = moment(value, "MM/DD/YYYY");
        let dynamicvar = "valideerror" + from;
        this[dynamicvar] = date.isValid();
        if (dateSplit[2] == "0000" || patternMatchBool == false) {
          this[dynamicvar] = false;
        }
      }else if( this.country== 'UAE') {
        let date = moment(value, 'DD/MM/YYYY');
        let dynamicvar = "valideerror" + from;
        this[dynamicvar] = date.isValid();        
        if (dateSplit[2] == "0000" || patternMatchBool == false) {
          this[dynamicvar] = false;
        }
      }
    }
  }

  diagnosisCodeAddPageChanged(ev) {
    this.diagnosisCodeAddPage =
      ev.page * this.allPaginationDefaultLimit - this.allPaginationDefaultLimit;
    this.procedureCodeSearch(
      this.searchtext,
      this.diagnosisCodeAddPage,
      this.allPaginationDefaultLimit,
      "Pagination"
    );
  }

  resetAllPagination() {
    this.diagnosisCodeAddPage = 0;
    this.diagnosisCodeAddCurrentPage = 1;
    this.diagnosisCodeAddPageskip = 0;
    this.diagnosisCodeAddTotalCount = 0;
  }

  screenValues() {
    if (this.innerWidth < 768) {
      // mobile
      this.mobileviewTaskcontent = false;
    } else if (this.innerWidth < 1200) {
      // big tablet screen or laptop
      this.mobileviewTaskcontent = true;
    } else {
      // desktop
      this.mobileviewTaskcontent = true;
    }
  }
  ngOnDestroy() {
    this.resetAllPagination();
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();
    this.SharedPopupService.dismiss();
  }
}
