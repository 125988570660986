import { Component, TemplateRef, OnInit, ViewEncapsulation, HostListener, OnDestroy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { SharedService } from '../../../_services/shared.service';
import * as _ from 'lodash';
import { Subject } from 'rxjs/Rx';
import { ActionCodesService } from '../../../_services/settings/actioncodes.service';
import { SharedPopupService } from '../../../_services/popup.service';
import constants from '../../../constants';
class ActionCode {
  code: String;
  description: String;
  enabled: Boolean;
}

@Component({
  selector: 'app-action-codes',
  templateUrl: './action-codes.component.html',
  styleUrls: ['./action-codes.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActionCodesComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  timeout: any;
  popupMode: String;
  addStatusMessage: String;
  popupTitle: String;
  public actionCodeObj: ActionCode;

  searchtext: String = '';
  actionCodeData: any;
  ConfirmPopupfrom: String;
  actionCodeInfo: any;
  actionCodeStatus: boolean;
  mobileviewTaskcontent: boolean;
  actionCodeTotalCount: number;
  actionCodePage: number;
  actionCodeCurrentPage: number;
  actionCodePageskip: number;
  allPaginationDefaultLimit: number;
  actionCodeIndex: number;
  messageStatus: String;
  messageInfo: String;

  inactiveFlag = true;
  showInactive: boolean;
  actionCodeId: string;
  actionCodeArrayIndex: number;
  typeList: any[];
  public dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  date_validation: any;
  enableSaveButton: boolean;
  innerWidth: number;
  loaderIndicator:boolean;
  constants:any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    this.screenValues();
  }
  constructor(private sharedService: SharedService, public SharedPopupService: SharedPopupService, public bsModalService: BsModalService, public actionCodesService: ActionCodesService, public modalService: BsModalService) {
    this.onSearchChange(this.searchtext);
    this.actionCodeData = [];
    this.enableSaveButton = false;
    this.actionCodeTotalCount = 0;
    this.actionCodePage = 0;
    this.actionCodeCurrentPage = 1;
    this.actionCodePageskip = 0;
    this.allPaginationDefaultLimit = 20;
    this.innerWidth = window.innerWidth;
    this.loaderIndicator=true;
    this.constants=constants;
    this.screenValues();
  }

  ngOnInit() {
    this.actionCodeObj = {
      code: '',
      description: '',
      enabled: true
    };
  }

  openPopup(template: TemplateRef<any>, poupMode: String, listItem: any, actionCodeArrayIndex) {

    this.popupMode = poupMode;
    this.addStatusMessage = '';
    this.date_validation = '';
    this['valideerrordatefrom'] = true;
    this['valideerrordateto'] = true;

    if ('addActionCode' == this.popupMode) {
      this.popupTitle = 'Add New Action code';
      this.actionCodeObj = {
        code: '',
        description: '',
        enabled: true
      };
    } else if ('editActionCode' == this.popupMode) {
      this.actionCodeId = listItem._id;
      this.actionCodeArrayIndex = actionCodeArrayIndex;
      this.popupTitle = 'Edit Action code';
      this.actionCodeObj = {
        code: listItem.actionCode,
        description: listItem.title,
        enabled: listItem.enabled
      };
    }
    this.SharedPopupService.show(template, { animated: true, backdrop: 'static', keyboard: false });
  }

  closePopup() {
    this.SharedPopupService.dismiss();
  }

  validateActionCode(model: ActionCode, isValid: boolean, myform: NgForm) {
    if (isValid == true && (this.date_validation == false || this.date_validation == '')) {
      this.enableSaveButton = true;
      this.actionCodeObj = {
        code: model.code.trim(),
        description: model.description.trim(),
        enabled: true
      };
      let actionCodeVar = JSON.parse(JSON.stringify(this.actionCodeObj));
      if (this.popupMode == 'addActionCode') {
        this.actionCodesService.addActionCode(actionCodeVar).subscribe((response: any) => {
          this.enableSaveButton = false;
          if (response.status == 'success') {
            this.actionCodeData.unshift(response.results);
            this.sharedService.pushToaster('success', 'Added', 'New Action code added successfully.');
            myform.resetForm();
            this.closePopup();
          }
           else {
            this.sharedService.pushToaster('error', 'Error', response.Message);
            // myform.resetForm();
          }
        });
      } else if (this.popupMode == 'editActionCode') {
        this.actionCodesService.editActionCode(actionCodeVar, this.actionCodeId).subscribe((response: any) => {
          this.enableSaveButton = false;
          if (response.status == 'success') {
            this.actionCodeData[this.actionCodeArrayIndex] = _.assign({}, this.actionCodeData[this.actionCodeArrayIndex], response.results);
            this.sharedService.pushToaster('success', 'Updated', 'Action code updated successfully.');
            this.closePopup();
          } else {
            this.sharedService.pushToaster('error', 'Error', response.Message);
            myform.resetForm();
            this.closePopup();
          }
        }, (err) => {
          if (err.error.error.includes('duplicate key')) {
            this.sharedService.pushToaster('error', 'Error', 'Action code Already Exists');
          } else {
            this.sharedService.pushToaster('error', 'Error', err.error.error);
          }
          myform.resetForm();
          this.closePopup();
        });
      }
    } else {
      this.sharedService.pushToaster('error', 'Error', 'form validation error');
    }
  }

  onSearchChange(searchValue, page = 0, limit = 20, type = '') {
    this.loaderIndicator=true;
    this.actionCodeData=[];
    let tempBool = false;
    let testPattern = /^[a-zA-Z0-9 ]*$/;
    let regExVal = new RegExp(testPattern);
    tempBool = regExVal.test(searchValue);
    if (tempBool) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(x => {
        this.ngUnsubscribe.next();
        if (type === '') {
          this.resetAllPagination();
        }
        let tempResult = [];
        this.actionCodesService.searchActionCode(searchValue, this.inactiveFlag, page, limit).debounceTime(400).takeUntil(this.ngUnsubscribe).subscribe(data => {
         
          if (data) {
            tempResult = data.results;
            this.actionCodeData = data.results;

            this.actionCodeTotalCount = data['totalCount'];
          } else {
            this.actionCodeData = [];
          }
          this.loaderIndicator=false;
        }, err => console.log(err));
      }, 400);
    }
  }

  ConfirmPopup(Confirmtemplate: TemplateRef<any>, from, code, actionCodeStatus, i) {
    this.ConfirmPopupfrom = from;
    this.actionCodeInfo = code;
    this.actionCodeStatus = actionCodeStatus;
    this.actionCodeIndex = i;
    this.messageStatus = (this.actionCodeStatus == true) ? ' Deactivate ' : ' Activate ';
    if (this.ConfirmPopupfrom == 'actionCodeStatus') {
      this.messageInfo = 'Are you sure want to' + this.messageStatus + 'Action Code ?';
    }
    this.SharedPopupService.show(Confirmtemplate, {
      animated: true,
      backdrop: 'static',
    });
  }

  onConfirm() {
    if (this.ConfirmPopupfrom == 'actionCodeStatus') {
      this.changeStatus(this.actionCodeInfo, this.actionCodeStatus);
    }
    this.SharedPopupService.dismiss();
  }

  changeStatus(code, status) {
    let enabled = !status;
    this.actionCodesService.activeActionCode(code, enabled).subscribe(data => {
      if (data) {
        this.onSearchChange(this.searchtext);
        this.sharedService.pushToaster('success', 'Success', 'Action Code' + this.messageStatus + 'Successfully');
      }
    });
  }

  onCancel() {
    this.SharedPopupService.dismiss();
  }

  activeStatus() {
    this.loaderIndicator=true;
    this.actionCodeData=[];
    this.resetAllPagination();
    this.inactiveFlag = (this.showInactive == true) ? true : false;
    this.actionCodesService.searchActionCode(this.searchtext, this.inactiveFlag).subscribe((userList: any) => {
         this.loaderIndicator=false;
      this.actionCodeData = userList.results;
      this.actionCodeTotalCount = userList.totalCount;
    });
  }

  actioncodePageChanged(ev) {
    this.actionCodePage = ev.page * this.allPaginationDefaultLimit - this.allPaginationDefaultLimit;
    this.onSearchChange(this.searchtext, this.actionCodePage, this.allPaginationDefaultLimit, 'Pagination');
  }

  resetAllPagination() {
    this.actionCodePage = 0;
    this.actionCodeCurrentPage = 1;
    this.actionCodePageskip = 0;
    this.actionCodeTotalCount = 0;
  }

  screenValues() {
    if (this.innerWidth < 768) {
      // mobile
      this.mobileviewTaskcontent = false;
    } else if (this.innerWidth < 1200) {
      // big tablet screen or laptop
      this.mobileviewTaskcontent = true;
    } else {
      // desktop
      this.mobileviewTaskcontent = true;
    }
  }

  ngOnDestroy() {
    this.resetAllPagination();
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();
    this.SharedPopupService.dismiss();
  }
}
