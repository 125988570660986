import { Component, OnInit, Input, OnChanges, TemplateRef, Output, EventEmitter, OnDestroy, } from '@angular/core';
import * as _ from 'underscore';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { InvoicingService } from '../../_services/invoicing/invoicing.service';
import { SharedService } from '../../_services/shared.service';
import { SharedPopupService } from '../../_services/popup.service';
//import { ColumnsToolPanelModule } from '@ag-grid-enterprise/all-modules';

@Component({
  selector: 'app-practice-invoice-lines',
  templateUrl: './practice-invoice-lines.component.html',
  styleUrls: ['./practice-invoice-lines.component.scss']
})
export class PracticeInvoiceLinesComponent implements OnInit, OnDestroy, OnChanges {
  @Input('lineslist') lineslist: any;
  @Output() invoiceLinesEvent = new EventEmitter<any>();
  lineslistData: any;


  invoiceName: any;
  invoiceDescription: any;
  invoiceValue: any;
  invoiceQuantity: any;
  invoiceTotal: any;
  parentIndex: any;
  totalInvoice: any;

  constructor(private sharedService: SharedService, public SharedPopupService: SharedPopupService, public modalService: BsModalService, private invoicingService: InvoicingService) { }

  ngOnInit() { }

  ngOnChanges() {
    this.totalInvoice = 0;
    if (this.lineslist) {
      this.lineslistData = this.lineslist.invoice_lines;
    } else {
      this.lineslistData = [];
    }
  }

  editinvoice(editInvoicePopup: TemplateRef<any>, data, i) {

    this.parentIndex = i;
    this.invoiceName = data.name;
    this.invoiceDescription = data.description;
    this.invoiceValue = data.value;
    this.invoiceQuantity = data.quantity;
    this.invoiceTotal = data.total;

    this.SharedPopupService.show(editInvoicePopup, {
      animated: true,
      backdrop: 'static'
    });
  }

  ModalClose() {
    this.SharedPopupService.dismiss();
  }

  saveInvoiceLines() {
    if (this.invoiceName.trim() && this.invoiceValue && this.invoiceQuantity) {
      this.lineslistData[this.parentIndex].name = this.invoiceName;
      this.lineslistData[this.parentIndex].description = this.invoiceDescription;
      this.lineslistData[this.parentIndex].quantity = this.invoiceQuantity;
      this.lineslistData[this.parentIndex].value = this.invoiceValue;
      this.lineslistData[this.parentIndex].total = this.invoiceTotal;

      this.lineslistData.forEach(element => {
        this.totalInvoice += parseFloat(element.total);
      });

      let updatedData = {
        "invoice_lines": this.lineslistData,
        "invoice_total": this.totalInvoice
      };

      this.invoicingService.savePracticeInvoice(updatedData, this.lineslist._id).subscribe(data => {
        if (data.status == 'success') {
          this.lineslistData[this.parentIndex] = data.data.invoice_lines[this.parentIndex];
          this.invoiceLinesEvent.emit({ 'totalInvoice': this.totalInvoice });
          this.sharedService.pushToaster('success', 'Success', 'Invoice lines updated successfully');
        } else {
          this.sharedService.pushToaster('error', 'Error', 'Invoice not updated');
        }
        this.ModalClose();
      });
    } else {
      this.sharedService.pushToaster('error', 'Error', 'Invoice fields should not be empty');
      return;
    }
  }

  calculateTotal() {
    this.invoiceTotal = this.invoiceValue * this.invoiceQuantity;
  }
  ngOnDestroy(): void {
    this.SharedPopupService.dismiss();
  }

}
