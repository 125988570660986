import { Directive, HostListener, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[stringValidation]',
  providers: [{ provide: NG_VALIDATORS, useExisting: StringValidationDirective, multi: true }]
})
export class StringValidationDirective {

  constructor() { }
  @Input() stringValidation: string;
  patternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp(this.stringValidation);
      const valid = regex.test(control.value);
      return valid ? null : { invalidValue: true };
    };
  }
  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.patternValidator()(control);
  }

}


