import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, Input, SimpleChanges, HostListener } from '@angular/core';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import * as moment from 'moment';
import { LoginHistoryService } from '../../../app/_services/login-history/login-history.service';

@Component({
  selector: 'app-user-login-history',
  templateUrl: './user-login-history.component.html',
  styleUrls: [
    './user-login-history.component.scss',
    '../../../scss/vendors/bs-datepicker/bs-datepicker.scss'
  ]
})
export class UserLoginHistoryComponent implements OnInit {
  tableData: any;
  loginHistoryToday_date: string;
  allHistoryData: any;
  totalCount: any;
  currentPage: any;
  page: any;
  // countLength: any;
  userName: string;
  defaultLimit = 20;
  historytype: string;
  printUserTitle: string;
  public innerWidth: any;
  mobileview: boolean;
  mobileviewTaskcontent: boolean;
  pageCountNum: number;

  @ViewChild('downurl', { static: false }) public downurl: ElementRef;
  @Input('userloginhistory') userloginhistory: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 800) {
      this.mobileview = true;
      this.mobileviewTaskcontent = true;
    } else {
      this.mobileview = false;
      this.mobileviewTaskcontent = false;
    }
  }
  constructor(
    private loginHistoryedaterangepickerOptions: DaterangepickerConfig,
    private loginHistoryService: LoginHistoryService
  ) {
    this.loginHistoryToday_date = moment().format('MM-DD-YYYY') + ' - ' + moment().format('MM-DD-YYYY');
    this.loginHistoryedaterangepickerOptions.settings = {
      locale: { format: 'MM-DD-YYYY' },
      alwaysShowCalendars: false
    };
    this.page = 0;
    this.printUserTitle = '';
  }
  ngOnInit() {
    this.historytype = this.userloginhistory;

  }
  ngOnChanges(change: SimpleChanges) {
    // this.innerWidth = window.innerWidth;
    //console.log('inner-width', this.innerWidth);
    this.historytype = this.userloginhistory;
    this.getUserHistoryData();
    if (this.historytype == 'userloginhistory') {
      this.printUserTitle = `User ${this.loginHistoryService.user_name} Login History`;
    } else if (this.historytype == 'practiceloginhistory') {
      this.printUserTitle = `${this.loginHistoryService.organization_name} Login History`;
    }
  }
  getUserHistoryData() {
    let reqFormate = {};
    reqFormate = {
      dateRange: this.loginHistoryToday_date,
      skip_pages: this.page ? this.page : 0
    };
    if (this.historytype == 'userloginhistory') {
      reqFormate['user_id'] = this.loginHistoryService.user_id;
    } else if (this.historytype == 'practiceloginhistory') {
      reqFormate['practice_id'] = this.loginHistoryService.practice_id;
    }
    // console.log("reqFormatereqFormatereqFormatereqFormatereqFormate",this.historytype)
    this.loginHistoryService.getUserLoginHistory(reqFormate, this.historytype).subscribe(data => {
      if (data.status == 'success') {
        this.pageCountNum = this.page;
        this.allHistoryData = data.resData[0].totalData;
        this.totalCount = data.resData[0].totalCount;
      }
    });
  }
  userLogin_datetriggered(date) {
    this.loginHistoryToday_date = date;
    this.currentPage = '';
    this.getUserHistoryData();
  }
  pageChanged(val) {
    this.page = val.page * this.defaultLimit - this.defaultLimit;
    this.getUserHistoryData();
  }

  downloadCsvFormate(val) {
    let downloadReq = {
      dateRange: this.loginHistoryToday_date,
      // user_id: this.loginHistoryService.user_id
    };
    if (this.historytype == 'userloginhistory') {
      downloadReq['user_id'] = this.loginHistoryService.user_id;
    } else if (this.historytype == 'practiceloginhistory') {
      downloadReq['practice_id'] = this.loginHistoryService.practice_id;
    }
    this.loginHistoryService.downloadAsCsv(downloadReq, this.historytype).subscribe(data => {
      let blob = data;
      let url = window.URL.createObjectURL(blob);
      let el: HTMLElement = this.downurl.nativeElement as HTMLElement;
      el.setAttribute('href', url);
      el.setAttribute('download', 'history.xlsx');
      el.click();
    });
  }
}
