import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTableModule } from 'angular2-datatable';
import { TextMaskModule } from 'angular2-text-mask';
import { SelectModule } from 'ng-select';
import { Ng5SliderModule } from 'ng5-slider';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxPrintModule } from 'ngx-print';
import { INgxSelectOptions, NgxSelectModule } from 'ngx-select-ex';
import { SharedModule } from '../../pipes/shared.module';
import { DiagnosisCodesComponent } from './codesets/diagnosis-codes/diagnosis-codes.component';
import { ProceduresComponent } from './codesets/procedures/procedures.component';
import { CustomListComponent } from './custom-list/custom-list.component';
import { LoginHistoryComponent } from './login-history/login-history.component';
import { PracticeAccountsComponent } from './practice-accounts/practice-accounts.component';
import { PermissionsComponent } from './security/permissions/permissions.component';
import { ResourcesComponent } from './security/resources/resources.component';
import { RolesComponent } from './security/roles/roles.component';
import { SessionHistoryComponent } from './session-history/session-history.component';
import { SettingComponent } from './setting.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { DataFilterPipe } from './taskstatuses/datafilterpipe';
import { TaskstatusesComponent } from './taskstatuses/taskstatuses.component';
import { TasktypesComponent } from './tasktypes/tasktypes.component';
// import { ToasterModule, ToasterService} from 'angular2-toaster/angular2-toaster';
import { UserComponent } from './users/user.component';
import { FeaturesComponent } from './security/features/features.component';
import { BulkCodeUploadesComponent } from './codesets/bulk-code-uploades/bulk-code-uploades.component';
import { FileUploadModule } from 'ng2-file-upload';
import { SkillsComponent } from './skills/skills.component';
import { StatusCodesComponent } from './status-codes/status-codes.component';
import { ActionCodesComponent } from './action-codes/action-codes.component';
import { ProcedureModifiersComponent } from './codesets/procedure-modifiers/procedure-modifiers.component';
import { PptModifiersComponent } from './ppt-modifiers/ppt-modifiers.component';
import { MUEComponent } from './mue/mue.component';
import { InventoryListComponent } from './inventory-list/inventory-list.component';

const CustomSelectOptions: INgxSelectOptions = { // Check the interface for more options
  optionValueField: 'key',
  optionTextField: 'value',
  keepSelectedItems: true
};

@NgModule({
  declarations: [
    SettingComponent,
    TaskstatusesComponent,
    UserComponent,
    SkillsComponent,
    PracticeAccountsComponent,
    ProceduresComponent,
    // UserLoginHistoryComponent,
    PermissionsComponent,
    RolesComponent,
    ResourcesComponent,
    DiagnosisCodesComponent,
    TasktypesComponent,
    LoginHistoryComponent,
    DataFilterPipe,
    SessionHistoryComponent,
    CustomListComponent,
    FeaturesComponent,
    BulkCodeUploadesComponent,
    StatusCodesComponent,
    ActionCodesComponent,
    ProcedureModifiersComponent,
    PptModifiersComponent,
    MUEComponent,
    InventoryListComponent
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    //ToasterModule.forRoot(),
    TabsModule,
    BsDatepickerModule.forRoot(),
    TextMaskModule,
    SharedModule,
    PaginationModule.forRoot(),
    NgxSelectModule,
    NgxPrintModule,
    DataTableModule,
    Ng5SliderModule,
    CollapseModule.forRoot(),
    NgxSelectModule.forRoot(CustomSelectOptions),
    SelectModule,
    FileUploadModule
  ]
})
export class SettingsModule { }
