import { Directive, Input, ElementRef } from '@angular/core';
import { DataHelperService } from '../_services/auth/data-helper.service';
import { RouteAuthorizationService } from '../_services/auth/route-authorization.service';

@Directive({
  selector: '[appFeature]'
})
export class FeatureVisibilityDirective {
  @Input('appFeature') feature: any;
  constructor(private el: ElementRef, private dataHelperService: DataHelperService, private routeAuthorizationService: RouteAuthorizationService) {

    this.routeAuthorizationService.changeEmitted$.subscribe(data => {
       // console.log('changes happended');
        this.checkFeatures();

      });
  }
  
  ngOnInit() {
    this.checkFeatures();
  }
  checkFeatures() {
    let features = this.dataHelperService.getItem('features');
    //console.log("features", features, this.feature);
    if (features) {
        if (features.indexOf(this.feature) == -1) {
            this.el.nativeElement.style.display = 'none';
          } else {
            // setting non existing attribute so that existing css for display will work
            this.el.nativeElement.style.display = 'block';
          }
    } else {
      // setting non existing attribute so that existing css for display will work
      this.el.nativeElement.style.display = 'none';
    }
  }
  ngAfterContentChecked() {
    this.checkFeatures();
  }

}
