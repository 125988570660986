import { Component, TemplateRef, ViewEncapsulation, ElementRef, OnDestroy } from '@angular/core';
import { TaskstatusesService } from '../../../_services/settings/taskstatuses.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../../../_services/shared.service';
import { SharedPopupService } from '../../../_services/popup.service';
import constants from '../../../constants';

@Component({
  templateUrl: 'taskstatuses.component.html',
  styleUrls: ['taskstatuses.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: []
})
export class TaskstatusesComponent implements OnDestroy {

  elementRef: ElementRef;
  taskStatusList: any;
  activeTab: any;
  popupTitle: string;
  popUpfrom: any;
  taskReason: any;
  reasonInstructions: any;
  popUpinfo: any;
  popUpinfoselectedIndex: any;
  parentIndexValue: any;
  taskName: any;
  taskDescription: String;
  disablebtn:boolean;
  messageInfo: string;
  dataObject: { from: any; item: any; i: any; title: any; parenrIndex: any; };
  inactiveFlag: any;
  showInactive: boolean;
  timeout = null;
  activeOnly: any;
  inactiveOnly: any;
  searchValue: any;
  taskColor: any;
  selectedTaskColor: any;
  mappingTaskStatusFlow: any;
  alreadyMapped: any;
  searchKeyWordFacility: string;
  loaderChecks: string;
  loaderIndicator: boolean;
  constants:any;
  constructor(private taskStatus: TaskstatusesService, public SharedPopupService: SharedPopupService, public modalService: BsModalService, private sharedService: SharedService) {
    this.activeOnly = [];
    this.inactiveOnly = [];
    this.searchValue = '';
    this.gettaskStatusList('');
    this.inactiveFlag = 1;
    this.showInactive = false;
    this.taskColor = ['info', 'danger', 'success', 'light'];
    this.taskStatusList = [];
    this.mappingTaskStatusFlow = [];
    this.loaderIndicator = true;
    this.constants=constants;
    this.activeTab = 0;
    this.disablebtn=false;
  }
  searcTaskKey(search) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.gettaskStatusList(search);
    }, 500);

  }
  gettaskStatusList(str,check=false) {
    this.loaderIndicator = true;
    this.taskStatusList = [];
    this.inactiveOnly = [];
    this.activeOnly = [];
    if (str !== undefined) {
      this.taskStatus.task_statuses_list(str).subscribe(data => {
        this.loaderIndicator = false;
        this.taskStatusList = data; 
        this.taskStatusList = this.taskStatusList.map(v => ({ ...v, reasonButton: true, mapFlowButton: false }));
       
        if (str === '' && check == false) {
          this.mappingTaskStatusFlow = this.taskStatusList.map(m => {
            return { '_id': m._id, 'task_status_name': m.task_status_name, 'task_active_status': m.task_active_status, 'alreadymapped': false };
          });
        }
        if (data.length > 0) {
          data.forEach((element) => {
            if (element.task_active_status == 1) {
              this.activeOnly.push(element.task_active_status);
            } else {
              this.inactiveOnly.push(element.task_active_status);
            }
            if (element.reason_code) {
              let filteredArray = element.reason_code.filter(function (itm) {
                return itm.enabled == true;
              });
              element.reason_code = filteredArray;
            }
          });
        }
      });
    }
    if(check)
    {
      setTimeout(()=>{
        document.getElementById('statusflow'+this.activeTab+'-link').click();
      },2000) 
    }
  }
  openModal(template: TemplateRef<any>, from, infoData?, selectedIndex?, parentIndex?) {
    this.popUpfrom = from;
    this.parentIndexValue = parentIndex;
    this.popUpinfo = infoData;
    this.popUpinfoselectedIndex = selectedIndex;
    if (from == 'newReason') {
      this.popupTitle = 'New Reason for Task Status "' + infoData.task_status_name + '"';
    } else if (from == 'editTask') {
      this.popupTitle = 'Edit Task Status';
      this.taskName = infoData.task_status_name;
      this.taskDescription = infoData.task_status_description ? infoData.task_status_description : '';
      this.selectedTaskColor = (this.taskStatusList[this.popUpinfoselectedIndex].task_color) ? this.taskStatusList[this.popUpinfoselectedIndex].task_color : infoData.task_color;
    } else if (from == 'editReason') {
      this.popupTitle = 'Edit Reason';
      this.taskReason = infoData.code_title;
      this.reasonInstructions = infoData.code_description;
    } else if (from == 'newTask') {
      this.popupTitle = 'Create New Task Status';
      this.taskName = '';
    } else if (from == 'manageTransitions') {
      this.popupTitle = 'Manage Transitions';
      this.taskName = '';
      this.alreadyMapped = infoData.transition_state.map(m => {
        return m._id;
      });
      this.mappingTaskStatusFlow.map(m => m.alreadymapped = this.alreadyMapped.includes(m._id) ? true : false);
    }
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static'
    });
  }

  onChangebtn(values:any,taskStatus){
    let check = values.currentTarget.checked;
    let TaskStatusID = taskStatus._id;
    let PopupID  = this.popUpinfo._id;    
    if(check){
      this.taskStatus.update_task_status_flow(this.popUpinfo._id,taskStatus ).subscribe(data => {
        if (data.status == 'success') {
          this.sharedService.pushToaster('success', 'Success', 'Status Flow updated successfully');
          // const foundItem = this.taskStatusList.find(item => item._id===PopupID)
          // foundItem.transition_state.push({_id:taskStatus._id})
          // const LastfoundItem = this.taskStatusList.find(item => item._id===TaskStatusID)
          // foundItem.likes.push({_id:taskStatus._id,mapFlowButton:LastfoundItem.mapFlowButton,reasonButton:LastfoundItem.reasonButton,task_active_status:LastfoundItem.task_active_status,task_color:LastfoundItem.task_color,task_stages:LastfoundItem.task_stages,task_status_description:LastfoundItem.task_status_description,task_status_name:LastfoundItem.task_status_name,transition_state:LastfoundItem.transition_state})
          this.gettaskStatusList(this.searchValue,true);
        } else {
          // this.modalClose();
          this.sharedService.pushToaster('error', 'Error', data.message);
        }
      }, err => {
        // this.modalClose();
        // this.sharedService.pushToaster('info', 'Error information', 'Task status Already Exists');
        this.sharedService.pushToaster('error', 'Error', err.message);
      });
    }else{
      this.taskStatus.delete_task_status_flow(this.popUpinfo._id,TaskStatusID ).subscribe(data => {
        if (data.status == 'success') {
           
          this.sharedService.pushToaster('success', 'Success', 'Status Flow updated successfully');
          this.gettaskStatusList(this.searchValue,true);
          // const foundItem = this.taskStatusList.find(item => item._id===PopupID)
          // foundItem.transition_state.push({_id:taskStatus._id})
          // const LastfoundItem = this.taskStatusList.find(item => item._id===TaskStatusID)
          // foundItem.likes.pop({_id:taskStatus._id,mapFlowButton:LastfoundItem.mapFlowButton,reasonButton:LastfoundItem.reasonButton,task_active_status:LastfoundItem.task_active_status,task_color:LastfoundItem.task_color,task_stages:LastfoundItem.task_stages,task_status_description:LastfoundItem.task_status_description,task_status_name:LastfoundItem.task_status_name,transition_state:LastfoundItem.transition_state})
        } else {
          // this.modalClose();
          this.sharedService.pushToaster('error', 'Error', data.message);
        }
      }, err => {
        // this.modalClose();
        // this.sharedService.pushToaster('info', 'Error information', 'Task status Already Exists');
        this.sharedService.pushToaster('error', 'Error', err.message);
      });
    }
  }


  saveStatusFlowUpdate() {
    let trnsition_statue = this.mappingTaskStatusFlow.filter(function (x) {
      if (x.alreadymapped == true) {
        return x._id;
      }
    }).map(x => x._id);
    let putInfo = {
      'transition_state': trnsition_statue
    };
     
    this.taskStatus.update_task_status_flow(this.popUpinfo._id, putInfo).subscribe(data => {
      if (data.status == 'success') {
        this.modalClose();
        this.sharedService.pushToaster('success', 'Success', 'Status Flow updated successfully');
        this.gettaskStatusList(this.searchValue);
      } else {
        // this.modalClose();
        this.sharedService.pushToaster('error', 'Error', data.message);
      }
    }, err => {
      // this.modalClose();
      // this.sharedService.pushToaster('info', 'Error information', 'Task status Already Exists');
      this.sharedService.pushToaster('error', 'Error', err.message);
    });
  }

  addReason() {
    let postInfo = {
      code_title: this.taskReason,
      code_description: this.reasonInstructions,
      enabled: true
    };
    this.taskStatus.add_new_reason(this.popUpinfo._id, postInfo).subscribe(data => {
      if (data.status == 'success') {
        if (this.popUpinfoselectedIndex >= 0 && this.taskStatusList[this.popUpinfoselectedIndex] && this.taskStatusList[this.popUpinfoselectedIndex].reason_code) {
          this.taskStatusList[this.popUpinfoselectedIndex].reason_code.unshift(postInfo);
        } else if (this.popUpinfoselectedIndex >= 0) {
          this.taskStatusList[this.popUpinfoselectedIndex].reason_code = [postInfo];
        }
        this.modalClose();
        this.sharedService.pushToaster('success', 'Success', 'Reason code added successfully');
      }else{
        this.sharedService.pushToaster('error', 'error', data.message );
      }
    });
  }
  deactivateAllReasons(item, i, title, parenrIndex) {
    let deactivate = false;
    if(item.reason_code){
      item.reason_code.forEach(element => {
        if(element.enabled){
          deactivate = true;
        }
      });
      if(deactivate){
        this.taskStatus.deactivate_all_reason(item._id, title).subscribe(data => {
          if (title && parenrIndex >= 0) {
            if (data.status == 'success') {
              this.taskStatusList[i].reason_code.splice(parenrIndex, 1);
              this.sharedService.pushToaster('success', 'Success', 'All reasons for '+item.task_status_name+' has been deactivated');
            }
          } else {
            this.taskStatusList[i].reason_code = [];
            this.sharedService.pushToaster('success', 'Success', 'All reasons for '+item.task_status_name+' has been deactivated');
          }
        });
      }else{
        this.sharedService.pushToaster('warning', 'Warning', 'No reasons to deactivate');  
      }
      
    }
    else{
      this.sharedService.pushToaster('warning', 'Warning', 'No reasons to deactivate');
    }
    
  }
  deactivateStatus(item, i) {
    let task_status = (item.task_active_status == 1) ? 0 : 1;

    // this.modalRef = this.modalService.show(warningmessageComponent);
    // this.modalRef.content.onClose.subscribe(result => {
    //     console.log('results', result);
    // })

    // this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to ... ?')
    // .then((confirmed) => console.log('User confirmed:', confirmed))
    // .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    //   this._serviceSubscription = this.service.confirmationpopup.subscribe({
    //     next: () => {
    //console.log("Received message");
    //     }
    // })
    this.taskStatus.deactivate_status(item._id, task_status).subscribe(data => {
      if (data.status == 'success') {
        this.taskStatusList[i].task_active_status = task_status;
        this.sharedService.pushToaster('success', 'Success', task_status == 0 ? 'Deactivate Task Status Successfully' : 'Activate Task Status Successfully');
        // this.taskStatusList.splice(1,i)
      }
    });
  }
  selectedTab($event: any, i: number) {
    this.activeTab = i;
    this.taskStatusList[i]['reasonButton'] = false;
    this.taskStatusList[i]['mapFlowButton'] = false;
    if ($event.heading == 'Reasons') {
      this.taskStatusList[i]['reasonButton'] = true;
    }
    if ($event.heading == 'Status Flow') {
      this.taskStatusList[i]['mapFlowButton'] = true;
    }
  }
  changeButtonText(permission) {
    if (permission.type == 'Resources') {
      return 'Map resources';
    } else if (permission.type == 'Roles') {
      return 'Map roles';
    }
  }
  ConfirmPopup(confirmpopup: TemplateRef<any>, from, item, i, title, parenrIndex) {
    this.dataObject = {
      from: from,
      item: item,
      i: i,
      title: title,
      parenrIndex: parenrIndex
    };

    if (from == 'statusDeactivate') {
      if (item.task_active_status == 1) {
        this.messageInfo = 'Are you sure want to Deactivate status?';
      } else {
        this.messageInfo = 'Are you sure want to Activate status?';
      }
    } else if (from == 'Deactivateallreasons') {
      this.messageInfo = 'Are you sure want to Deactivate Allreasons?';
    } else if (from == 'deactivatesingle') {
      this.messageInfo = 'Are you sure want to Deactivate Reason?';
    }




    this.SharedPopupService.show(confirmpopup, {
      animated: true,
      backdrop: 'static'
    });
  }
  onConfirm() {
    if (this.dataObject.from == 'statusDeactivate') {
      this.deactivateStatus(this.dataObject.item, this.dataObject.i);
    } else if (this.dataObject.from == 'Deactivateallreasons') {
      this.deactivateAllReasons(this.dataObject.item, this.dataObject.i, '', '');
    } else if (this.dataObject.from == 'deactivatesingle') {
      this.deactivateAllReasons(this.dataObject.item, this.dataObject.i, this.dataObject.title, this.dataObject.parenrIndex);
    }
    this.SharedPopupService.dismiss();
  }
  onCancel() {
    this.SharedPopupService.dismiss();
  }
  editTaskData() {
    let data = {
      task_status_name: this.taskName,
      task_status_description: this.taskDescription,
      task_color: this.selectedTaskColor
    };
    // tslint:disable-next-line: no-shadowed-variable
    this.taskStatus.edit_Status_save(this.popUpinfo._id, data).subscribe(data => {
      if (data.status == 'success') {
        this.taskStatusList[this.popUpinfoselectedIndex].task_status_name = this.taskName;
        this.taskStatusList[this.popUpinfoselectedIndex].task_status_description = this.taskDescription;
        this.taskStatusList[this.popUpinfoselectedIndex].task_color = this.selectedTaskColor;
        this.sharedService.pushToaster('success', 'Success', 'Task status updated Successfully');
        this.modalClose();
      } else if(data.status == 'failure') {
        this.sharedService.pushToaster('error', 'Error', data.Message);
        // alert("update fdailed")
      } else {
        this.sharedService.pushToaster('error', 'Error', data.message1)
      }
    }, err => {
      this.modalClose();
      // this.sharedService.pushToaster('info', 'Error information', 'Task status Already Exists');
      this.sharedService.pushToaster('error', 'Error', err.message);
    });
  }
  saveEditReason() {
    let updateData = {
      code_title: this.taskReason,
      code_description: this.reasonInstructions,
      enabled: true
    };
    this.taskStatus.edit_Reason_save(this.taskStatusList[this.parentIndexValue]._id, this.popUpinfo.code_title, updateData).subscribe(data => {
      if (data.status == 'success') {
        if (this.parentIndexValue >= 0 && this.popUpinfoselectedIndex >= 0) {
          this.taskStatusList[this.parentIndexValue].reason_code.splice(this.popUpinfoselectedIndex, 1, updateData);
        }
      }else if(data.status == "failure"){
        this.sharedService.pushToaster('error', 'Error', data.message);
      }
      this.modalClose();
      this.sharedService.pushToaster('success', 'success', data.message);
    });

  }
  addTaskStatus() {
    let data = {
      task_status_name: this.taskName,
      task_status_description: this.taskDescription,
      task_color: this.selectedTaskColor
    };
    if (this.taskName) {
      // tslint:disable-next-line: no-shadowed-variable
      this.taskStatus.add_new_task_status(data).subscribe(data => {
        if (data.status == 'success') {
          if (data.data) {
            this.taskStatusList.unshift(data.data);
          }
          this.modalClose();
          this.sharedService.pushToaster('success', 'Success', 'Task status created Successfully');
        } else if (data.status == 'failure') {
          this.sharedService.pushToaster('error', 'Error', data.Message);
        }else{
          this.sharedService.pushToaster('error', 'Error', data.message1);
        }
      }, () => { // error
        this.modalClose();
        this.sharedService.pushToaster('info', 'Error information', 'Task status Already Exists');
      });
    }
  }
  // showNoReasons(resCode){
  //   if(!resCode || resCode.length ==0){
  //     return true
  //   }else{
  //    console.log(resCode.filter(function(key){ return key.enabled == false}))
  //     return true
  //   }


  // }

  modalClose() {
    this.SharedPopupService.dismiss();
    this.taskReason = '';
    this.reasonInstructions = '';
    this.taskName = '';
    this.taskDescription = '';
    this.searchKeyWordFacility = '';
  }

  inactive() {
    //this.showInactive = [];
    //let active = (this.showInactive == true) ? true : false;
    this.inactiveFlag = (this.showInactive == true) ? true : false;
    this.gettaskStatusList(this.searchValue);
 

  }
  ngOnDestroy(): void {
    this.SharedPopupService.dismiss();
  }
  disablebutton(err, len) {
    if(err === undefined) {
      this.disablebtn = false;
    } else if(err === null) {
      if(len >=3) {
        this.disablebtn = true;
      } else {
        this.disablebtn = false;
      }
    } else {
      this.disablebtn = false;
    }
  }
}
