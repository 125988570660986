import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Http, Headers, RequestOptions } from '@angular/http';
import * as myGlobals from '../../globals';

/*Header start */
let headers = new Headers({ 'Content-Type': 'application/json' });
headers.append('authorization', localStorage.getItem('authToken'));
// let options = new RequestOptions({ headers: headers });
/*Header end */

@Injectable({
  providedIn: 'root'
})
export class BatchesService {
  batchMatch: any;
  selectedbatch: any;
  public batch_valid_percent: any;
  batch_interval: any;
  batch_status: boolean;
  batch_first_status: boolean;
  batch_message: any;
  batch_rows_totals: any;
  oldbatch_id: any;
  final_status: string;

  constructor(private http: HttpClient) {
    this.batch_message = '';
    this.batch_status = false;
    this.batch_first_status = false;
    this.batch_valid_percent = 0;
    this.batch_rows_totals = 0;
    this.final_status = '';
    this.selectedbatch = {
      'activities': []
    };
  }

  searchbatch(batchname: string, iscompleted: boolean, page: any, limit: any, batchStatus, batchType, batchPractice) {
    // let req = { "batchname": batchname, "iscompleted": iscompleted, "practice_id": practice_id};
    let req = {
      'searchkey': batchname,
      'status': batchStatus,
      'batchType': batchType,
      'practice': batchPractice,
      'skip': page,
      'iscompleted': iscompleted,
      'limit': limit
    };
    return this.http.post<any>(myGlobals.searchbatch_URL, req);
  }

  viewbatch(batchid) {
    this.batch_valid_percent = 0;
    clearInterval(this.batch_interval);
    return this.http.get<any>(myGlobals.batch_URL + '/' + batchid);
  }


  processbatch(req) {
    this.batch_message = '';
    this.batch_status = false;
    this.batch_first_status = false;
    this.batch_valid_percent = 1;
    let url = myGlobals.ProcessesBatch_URL;
    //console.log('url ' + url);
    this.batch_status = true;
    return this.http.post<any>(url, req);
  }

  batchstatus(batch_id) {
    //console.log(batch_id);

    if (batch_id == undefined) {
      clearInterval(this.batch_interval);

    }


    this.batch_interval = setInterval(() => {
      this.batch_status = false;
      this.batch_message = 'Batch process initiated..!';
      // console.log('Batch check started');
      // this.lastbatch(batch_id).subscribe(data => {
      //   this.batch_first_status = true;
      //   this.batch_status = true;
      //   if (data.status == 'done' || data.status == 'failed') {
      //     clearInterval(this.batch_interval);

      //     this.batch_status = false;
      //     this.final_status = data.status;
      //     this.batch_rows_totals = data.rows;
      //     this.batch_message = 'Batch successfully processed.';
      //     this.batch_valid_percent = 100;
      //     console.log('Batch check Done');

      //     // code to execute to list batches or refresh batch list
      //   } else {
      //     this.batch_valid_percent = data.percent;
      //     this.batch_rows_totals = data.rows;
      //     console.log(this.batch_valid_percent);
      //   }
      // });
    }, 100);
  }

  lastbatch(batch_id) {
    let url = myGlobals.ProcessesBatch_URL + '/' + batch_id + '/status';
    return this.http.get<any>(url);
  }

  allPractices(status: string) {
    status = 'true';
    return this.http.get<any>(myGlobals.Practices_URL + '/?showActive=' + status);
  }
  getPracticeAccountGroups() {
    return this.http.get<any>(myGlobals.Practices_URL + '/accountgroups');
  }

  downloadexcel(batchId: string) {
    return this.http.get<any>(myGlobals.batch_URL + '/batch_export/' + batchId);
  }
  downloadSourceReport(data: any) {
    return this.http.post(myGlobals.batch_URL + '/downloadsource', data, {
      responseType: 'blob'
    });
  }
  downloadAllexcel(data: any) {
    return this.http.post<any>(myGlobals.batch_URL + '/batch_export/download',{"batchIds":data.selectedBatches,"unselectedbatchIds":data.unselectedBatches,"enableAll":data.enableAll,"selectedPractice":data.selectedPractice});
  }  
  downloadCVAexcel(batchId: string, type: string) {
    return this.http.get(myGlobals.Validation_Reports_URL + '/export?&batch_id='+batchId+"&type="+type, {
      responseType: 'blob'
    });
  }
  downloadNewCVAexcel(batchId: string, type: string) {
    return this.http.get(myGlobals.Validation_Reports_URL + '/cvaexport?batch_id='+batchId+"&type="+type, {
      responseType: 'blob'
    });
  }
  downloadTemplate(type: string) {
    return this.http.get(myGlobals.batch_URL + '/download/' + type, {
      responseType: 'blob'
    });
  }
  clientFileDownload(batchId: string) {
    return this.http.get(myGlobals.batch_URL +'/'+ batchId +'/download/initial', {
      responseType: 'blob'
    });
  }
  outputFileDownload(batchId:string){
    return this.http.get(myGlobals.batch_URL +'/'+ batchId +'/download/output', {
      responseType: 'blob'
    });
  }
  batchReport(batch_id){
    return this.http.get(myGlobals.Reports +'/validations/batch/'+ batch_id );
  }
}
