import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  constructor(private http: HttpClient) { }

  // update practice integration settings
  updateSettings(data) {
      return this.http.put<any>(myGlobals.current_Practice + '/integration', data);
  }

  getIntegration() {
    const url = myGlobals.current_Practice + '/integration';
    return this.http.get<any>(url);
  }
}
