import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'regionaldateformot'
})
export class RegionaldateformotPipe implements PipeTransform {
  
  transform(value: unknown, ...args: unknown[]): unknown {
    if(value !== undefined && value !== '' && args.length == 0){
      let country = localStorage.getItem('Region');
      if(country == 'US'){
        return moment(value).format('MM/DD/YYYY');
      }else if(country=="UAE"){
        return moment(value).format('DD/MM/YYYY');
      }else{
        return null;
      }
    }else if(value !== undefined && value !== '' && args.length !==0){
      let country = localStorage.getItem('Region');
      if(country == 'US'){
        return moment(value).format('MM/DD/YYYY' + args.toString());
      }else if(country=="UAE"){
        return moment(value).format('DD/MM/YYYY' + args.toString());
      }else{
        return null;
      }
    }
    return null;
  }

}
