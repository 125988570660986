import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm, AbstractControl, FormControl } from '@angular/forms';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as jutil from '@jshunters/jutil';
import { conformToMask } from 'angular2-text-mask';
import * as _ from 'lodash';
import * as moment from 'moment';
// import { reject } from 'q';
import { Options } from 'ng5-slider';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DataHelperService } from '../../../_services/auth/data-helper.service';
import { LoginHistoryService } from '../../../_services/login-history/login-history.service';
import { PracticesService } from '../../../_services/settings/practices.service';
import { SharedService } from '../../../_services/shared.service';
import { TaskService } from '../../../_services/task/task.service';
import { UsersService } from '../../../_services/settings/users.service';
import { SharedPopupService } from '../../../_services/popup.service';
import constants from '../../../constants';
import { result } from 'lodash';
import libphonenumber from 'google-libphonenumber';
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
class PracticeAccount {
  practiceId: String;
  practiceName: String;
  // accountName: String;
  // accountGroup: String;
  accountGroupId: String;
  accountNumber: String;
  practiceNumber: String;
  npiNumber:String;
  taxId:String;
  streetLine1: String;
  streetLine2: String;
  city: String;
  state: String;
  zipcode: String;
  practice_visibility: boolean;
  show_phi:boolean;
  emailReport: String;
  pms_info:String;
  alt_taxonomy:String;
  taxonomy_code:String;
  phoneno:String;
  fax:String;
  callbackphoneno:String;
  openArtFormat:String;
  providerFormat:String;
  paitentFileFormat:String;
  payerFormat:String;
  openAR:boolean;
  patientInfo:boolean;
  payerInfo:boolean;
  providerInfo:boolean;
  
}

class ProviderObject {
  providerId: String;
  practiceId: String;
  practiceName: String;
  userId: String;
  userName: String;
  npi: String;
  ssn: String;
  ein: String;  // This is tax_id field in API
  type: String;
  entity: boolean;
  organizationName: String;
  firstName: String;
  lastName: String;
  middleName: String;
  shortName: String;
  prefixName: String;
  streetLine1: String;
  streetLine2: String;
  city: String;
  state: String;
  zipcode: String;
  enabled: boolean;
  taxonomyCode: String;
  alternativeTaxonomy: String;
  extCode: string;
  extId: string;
  extName: string;
  listTypes: any[];
}

class FacilityData {
  npi: any;
  ein: any;
  exId: any;
  facilityName: string;
  nickName: string;
  pos: any;
  cliaNumber: any;
  street1: string;
  street2: string;
  city: any;
  state: any;
  zipcode: any;
  phone: any;
  fax: any;
}

@Component({
  selector: 'app-practice-accounts',
  templateUrl: 'practice-accounts.component.html',
  styleUrls: ['practice-accounts.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  providers: [],
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '(document:click)': 'onClick($event.target)',
  }
})

export class PracticeAccountsComponent implements OnInit, OnDestroy {
  form: FormGroup;
  formSubmitted = false;
  public dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  selectedproviderList: any;
  unselectedproviderList: any;
  editPolicyIndex: any;
  pmsArray=[
    "ADVANCEDMD",
    "ALLEGIANCEMD",
    "ALLSCRIPTS",
    "ATHENAHEALTH",
    "AZALEA HEALTH",
    "BRIGHTREE",
    "CARECLOUD",
    "CENTRALREACH",
    "CENTRICITY EMR",
    "CERNER",
    "CHARMHEALTH",
    "CHARTLOGIC",
    "CUREMD",
    "DRCHRONO",
    "ECLINICALWORKS",
    "EPIC",
    "EPIC SYSTEMS",
    "GE HEALTHCARE CENTRICITY PRACTICE SOLUTION",
    "GREENWAY HEALTH",
    "HEALTHFUSION MEDITOUCH",
    "INTERGY EHR",
    "IPATIENTCARE",
    "KAREO",
    "MCKESSON PRACTICE CHOICE",
    "MEDITECH",
    "MODMED",
    "NEXTGEN",
    "NEXTGEN HEALTHCARE",
    "NUEMD",
    "OFFICE ALLY",
    "PABAU",
    "PCC EHR",
    "PRACTICE EHR",
    "PRACTICE FUSION",
    "PRACTICESUITE",
    "PROGNOCIS BY BIZMATICS",
    "RXNT",
    "SIMPLEPRACTICE",
    "SRS HEALTH",
    "THERABILL",
    "TIGERCONNECT",
    "TOUCHWORKS EHR BY ALLSCRIPTS",
    "VIRENCE HEALTH CENTRICITY PRACTICE SOLUTION",
    "WEBPT","WRS HEALTH","ZOCDOC"]
  p_replace: boolean;
  public zipCodeFive = [/\d/, /\d/, /\d/, /\d/, /\d/];
  public zipCodeNine = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  rvu: any;
  selectedpagenumber: any
  policyinfo: any;
  replace_policy: any;
  policyData: any = {};
  value: number;
  revenuePercentRate: number;
  rvuPercentRate: number;
  policies: any[];
  policy: any;
  isaddnewProvider: boolean;
  flagIndicator: boolean;
  flagIndicatorprovider: boolean;
  Patient_360_Integration: boolean;
  matrixCare: boolean;
  revenueOptions: Options = {
    floor: 0,
    ceil: 100,
    step: 0.01,
    showTicks: false,
    // readOnly: true,
    hideLimitLabels: false,
    showSelectionBar: true,
    getSelectionBarColor: (value: number): string => {
      // console.log('this.revenuePercentRate', this.revenuePercentRate);
      if (this.revenuePercentRate >= 99.99 && this.revenuePercentRate <= 100.01) {
        return 'white';
      } else {
        return 'red';
      }
    },
    translate: (value: number): string => {
      return value + '%';
    }
  };

  rvuOptions: Options = {
    floor: 0,
    ceil: 100,
    step: 0.01,
    showTicks: false,
    // readOnly: true,
    hideLimitLabels: false,
    showSelectionBar: true,
    getSelectionBarColor: (value: number): string => {
      if (this.rvuPercentRate >= 99.99 && this.rvuPercentRate <= 100.01) {
        return 'white';
      } else {
        return 'red';
      }
    },
    translate: (value: number): string => {
      return value + '%';
    }
  };
  preSelectedList: any[];
  revert_effective_for: any;
  mapped_provider_name: string;
  isCollapse: any;

  dynamicBtn: String;
  showInactive: boolean;
  seletedTab: String;
  allAccountsList: any;
  searchValue: String;
  searchData: any;
  accountList: any;
  timeout = null;

  messageInfo: String;
  statusChangeObj: any;
  popupTitle: string;
  popUpfrom: any;
  popUpinfo: any;
  popUpinfoselectedIndex: any;
  parentIndexValue: any;
  accountGroupList: any;
  accountGroupMap: any;
  practiceAccountForm: any;
  alreadyExistFlag: any;
  public practiceAccount: PracticeAccount;
  public providerObject: ProviderObject;
  PracticeAccountData: any;
  providerData: any;
  listTypes: any[];
  public user_array_item: Array<{ id: string, text: string }>;
  userSelectedId: any;
  userSelectedText: any;
  public taxonomy_array_item: Array<{ id: string, text: string }>;
  taxonomySelectedCode: any;
  altTaxonomySelectedCode: any;
  public alt_taxonomy_array_item: Array<{ id: string, text: string }>;
  userActiveItem: any;
  contacts: any;
  validationMessage: any;
  validationStatus: any;
  successflag: any;
  timer: any;
  practiceAccountEditStatus: any;
  practiceAccountEditMsg: any;
  patientDemographData: any;
  stateActiveItem: any;
  selectedStateCode: any;
  autoHide: Boolean = false;
  notAvailable: String = 'Not available';
  public state_array_item: Array<{ id: string, text: string }>;
  public pos_array_item: Array<{ id: string, text: string }>;
  selectedposCode: any;
  selectedPracticeId: any;
  selectedPracticeIndex: any;
  practiceUsersData: any;
  practiceFacilitiesData: any;
  practiceMappedUsers: any[]; // existmappedaccountandrole
  existingMappedFacilities: any[];
  userTitle: any;
  practice_visibility:any;
  show_phi: Boolean = false;
  childIndexValue: any;
  tmpMappingData: any[];
  selectedPracticeUserObject: any;
  practice_id: string;
  confirmPopupFrom: any;
  providerDetails: any;

  public facilityData: FacilityData;
  facilityDataObj: any;
  newfacility: any;
  facilityPopupTitle: string;
  viewFacilityData: any;
  selectedFacilityId: any;
  facilityErrMessage: string;
  // public sameAsPractice: any

  customProcedureJson: any;
  hasErrorInJson: any;
  isValidJson: any;
  selectedPracticeName: any;
  searchKeyWordFacility: String;
  sameAsPractice: boolean;
  sameAsPracticeFacility: boolean;
  currentPracticeAddress: any;
  newPractice_id: any;
  successResult: string;
  public ssnMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public einMask = [/[0-9]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  maskValidation: boolean;
  maskValidationPro: boolean;
  stateSuccessResult: string;
  currentPracticeAddressProviders: any;
  // practiceUserEmptyResult: any;
  groupedFacilities: any;
  taxonomy_timer: any;
  durationOption: any;
  fromlabel: any;
  tolabel: any;
  totalCount: any;
  fromPopup: string;
  compensationPopupList: any;
  comRevenue: Array<{
    '_id': string,
    'provider_name': string,
    'revenue': number,
    'revenuePercent': number,
    'rvu': number,
    'rvus': any,
    'rvuPercent': number,
    'base_compensation': number,
    'total_compensation': number,
    'collection_amount': number,
    'temp_rvu': number,
    'tempIndex': number
  }>;
  comRvu: any[];
  totalRevenue: number;
  totalRvu: number;
  providerFullData: any[];

  invoicelist: any;
  billing_service_fee: any;
  effective_month_from: any;
  effective_month_to: any;
  practiceInvoiceID: any;
  dateOverlapping: boolean;
  providerList: any[];
  searchValueUser: string;
  @ViewChild('addEditPracticeAccountPopup', { static: false }) addEditPracticeAccountPopup: ElementRef;
  @ViewChild('staticMenu', { static: false }) staticMenus: ElementRef;
  @ViewChild('npiPopup') npiPopup: any;
  selected_policy: any;
  policy_obj: {};
  currentPage: number;
  defaultLimit: number;
  page: any;
  commaSepEmail: any;
  loaderIndicator: boolean;
  loaderIndicatorprovider : boolean;
  tabloaderIndicator: boolean;
  mips_measures: string;
  mipsList: any[][];
  constants: any;
  errorFlag: boolean;
  errorMessage: string;
  patient360List: any[];
  mips_360: string;
  userName:string;
  passcode:string;
  errorFlag360: boolean;
  errorMessage360: string;
  // error360: boolean;
  // errorMessage360Pass: string;
  // error360Pass: boolean;
  selected_facility: any;
  selected_facility_for_integration: any[];
  selected_practice_for_integration: any[];
  enableMatrixCare:boolean;
  matrixFacilityArray:any[];
  country:any;
  npiError: boolean;
  npi_address: any;
  phoneError:boolean;
  callbackphoneError:boolean;
  practiceFormValue:any;
  showReportGroups:any[];
  fileFormats:any;
  openArtFormat:any;
  providerFormat:any;
  payerInfoflag:any;
  patientInfoflag:any;
  openARflag:any;
  providerInfoflag:any;
  constructor(private formBuilder: FormBuilder,
    public userhistoryservice: LoginHistoryService,
    public modalService: BsModalService,
    public SharedPopupService: SharedPopupService,
    private sharedService: SharedService,
    private practicesService: PracticesService,
    public taskService: TaskService,
    private usersService: UsersService,
    private DataHelper: DataHelperService
  ) {
    this.showReportGroups=[{name:'Open AR',format:'',formName:'openAR'},{name:'Patient Info',format:'',formName:'patientInfo'},{name:'Payer Insurance',format:'',formName:'payerInfo'},{name:'Provider Info',format:'',formName:'providerInfo'}];
    this.fileFormats=[{format:".xls"},{format:".xlsx"},{format: ".txt"},{format: ".pdf"}];
    this.flagIndicator = false;
    this.flagIndicatorprovider = false;
    this.selectedpagenumber = 1;
    this.currentPage = 1;
    this.defaultLimit = 10;
    this.page = 0;
    this.value = 50;
    this.openARflag=false;
    this.payerInfoflag=false;
    this.patientInfoflag=false;
    this.providerInfoflag=false;
    this.showInactive = false;
    this.p_replace = false;
    this.dynamicBtn = 'Map users';
    this.triggerSearch('');
    this.getAccountGroupList();
    this.state_array_item = [];
    this.pos_array_item = [];
    this.user_array_item = [];
    this.taxonomy_array_item = [];
    this.alt_taxonomy_array_item = [];
    this.accountGroupMap = new Map();
    this.taskService.accountHistory = false;
    this.tmpMappingData = [];
    this.practiceMappedUsers = [];
    this.existingMappedFacilities = [];
    this.practiceMappedUsers = [];
    this.practice_id = '';
    this.popUpinfo = '';
    this.sameAsPractice = false;
    this.sameAsPracticeFacility = false;
    this.stateSuccessResult = '';
    // this.currentPracticeAddress = '';
    this.isValidJson = true;
    this.searchKeyWordFacility = '';
    this.successResult = '';
    this.userSelectedText = '';
    this.groupedFacilities = [];
    this.allAccountsList = [];
    // this.policyinfo={rvu_to:[],rvu_rate:[]};
    this.durationOption = ['per month'];
    this.fromlabel = 0;
    this.tolabel = 100;
    this.fromPopup = '';
    this.policyinfo = {};
    this.policyinfo.rvu = [{
      'from': '0',
      'to': '',
      'rate': ''
    }];
    // this.comRevenue = [];
    this.comRvu = [];
    this.rvuPercentRate = 0;
    this.revenuePercentRate = 0;
    this.policies = [];
    this.policy = {};
    this.isaddnewProvider = false;
    this.providerList = [];
    this.searchValueUser = '';
    this.loaderIndicator = true
    this.loaderIndicatorprovider = false;
    this.tabloaderIndicator = true;
    this.constants = constants;
    this.Patient_360_Integration = false;
    this.matrixCare=false;
    this.mips_measures = '';
    this.mipsList = [];
    this.errorFlag = false;
    this.errorMessage = '';
    this.patient360List = [];
    this.userName ='';
    this.passcode ='';
    this.errorFlag360 = false;
    this.errorMessage360 = '';
    this.country = localStorage.getItem('Region');
    // this.error360 = false;
    // this.errorMessage360Pass = '';
    // this.error360Pass=false;
    this.isCollapse = {};
    this.enableMatrixCare=false;
    //this.isCollapse['matrixCareCollapse'] = true;
    // this.isCollapse['sigmastatus'] = false;
  }

  logError(_arg0: any): any {
    throw new Error('Method not implemented.');
  }

  expanded(event: any): void {
  }
  sliderOptions(): Options {
    return {
      floor: 0,
      ceil: 100,
      step: 1,
      showTicks: false,
      // readOnly: true,
      hideLimitLabels: false,
      showSelectionBar: true,
      getSelectionBarColor: (value: number): string => {
        return 'white';
      }
    };
  }

  ngOnInit() {
    this.buildPracticeForm();
    // this.practiceAccount = {
    //   practiceId: '',
    //   practiceName: '',
    //   accountGroupId: '',
    //   accountNumber: '',
    //   practiceNumber: '',
    //   streetLine1: '',
    //   streetLine2: '',
    //   city: '',
    //   state: '',
    //   zipcode: '',
    //   practice_visibility: false
    // };
    this.contacts = 'fail';

    this.listTypes = ['Individual', 'Organization'];
    this.user_array_item = [];

    this.providerObject = {
      providerId: '',
      practiceId: '',
      practiceName: '',
      userId: '',
      userName: '',
      npi: '',
      ssn: '',
      ein: '',  // This is tax_id field in API
      type: '',
      entity: false,
      organizationName: '',
      firstName: '',
      lastName: '',
      middleName: '',
      shortName: '',
      prefixName: '',
      streetLine1: '',
      streetLine2: '',
      city: '',
      state: '',
      zipcode: '',
      enabled: true,
      taxonomyCode: '',
      alternativeTaxonomy: '',
      extCode: '',
      extId: '',
      extName: '',
      listTypes: this.listTypes
    };

    this.facilityData = {
      'npi': '',
      'ein': '',
      'exId': '',
      'facilityName': '',
      'nickName': '',
      'street1': '',
      'street2': '',
      'city': '',
      'state': '',
      'zipcode': '',
      'phone': '',
      'fax': '',
      'cliaNumber': '',
      'pos': ''
    };
    this.taskService.initialStateList();
  }

  buildPracticeForm() {
    this.commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
      if (!_.isEmpty(control.value)) {
        var emails = control.value.split(',');
        const forbidden = emails.some((email: any) => Validators.email(new FormControl(email)));
        return forbidden ? { 'emailReport': { value: control.value.trim() } } : null;
      }
    };
    this.form = this.formBuilder.group({
      practiceId: [null],
      //practiceName: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9,./ ]*$')]],
      practiceName: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(75)]],
      accountGroupId: [null, [Validators.required]],
      accountNumber: [null, [Validators.required, Validators.maxLength(7), Validators.pattern('^[a-zA-Z0-9\-/ ]*$')]],
      practiceNumber: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
      streetLine1: [null, [Validators.required]],
      streetLine2: [null],
      city: [null, [Validators.required, Validators.minLength(3), Validators.pattern("^[a-zA-Z_. ]*$")]],
      state: [null, [Validators.required]],
      zipcode: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(10), Validators.pattern("(^[0-9]{5}$)|(^[0-9]{5}-[0-9]{4}$)|(^[0-9]{9}$)")]],
      practice_visibility: [false],
      show_phi:[false,[Validators.required]],
      openArtFormat:[null],
      providerFormat:[null],
      paitentFileFormat:[null],
      payerFormat:[null],
      openAR:[false],
      patientInfo:[false],
      payerInfo:[false],
      providerInfo:[false],
      pms_info:[null],
      emailReport: ['',
        Validators.compose([
          Validators.pattern("^([ ]?[^, ]+@[a-zA-Z0-9][^, ]+[.][^, ]+[ ]?,)*([ ]?[^, ]+@[^, ]+[.][^, ]+)$"), this.commaSepEmail
        ])],
        npiNumber: [null, [Validators.required, Validators.maxLength(10), Validators.pattern('^[0-9\-/ ]*$')]],
        taxId: [null, [Validators.required,  Validators.maxLength(10)]],
        taxonomy_code:[null, [Validators.required]],
        alt_taxonomy:[null],
        phoneno:[null, [Validators.required,  Validators.maxLength(15)]],
        callbackphoneno:[null, [Validators.maxLength(15)]],
        fax:[null, [Validators.maxLength(10), Validators.pattern('^[0-9]*$')]]
    });
    this.form.get('state').setValue(null);
  }

  editPracticeForm(infoData) {
    this.npiIdProviderData(infoData.npi,infoData,1);
    if(infoData.show_phi!=undefined&&infoData.show_phi!='')
    {
      this.show_phi=infoData.show_phi; 
    }
    else{
      this.show_phi=false
    }
    this.form = this.formBuilder.group({
      practiceId: [infoData._id],
      practiceName: [infoData.organization_name, [Validators.required, Validators.minLength(2), Validators.maxLength(75), Validators.pattern('^[a-zA-Z0-9,./ ]*$')]],
      accountGroupId: [infoData.accountgroup, [Validators.required]],
      accountNumber: [infoData.accountno, [Validators.required, Validators.maxLength(7), Validators.pattern('^[a-zA-Z0-9\-/ ]*$')]],
      practiceNumber: [infoData.practiceno == "null" ? null : infoData.practiceno, [Validators.required, Validators.minLength(2), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
      streetLine1: [(infoData.address) ? infoData.address.street_line_1 : '', [Validators.required]],
      streetLine2: [(infoData.address) ? infoData.address.street_line_2 : ''],
      city: [(infoData.address) ? infoData.address.city : '', [Validators.required, Validators.minLength(3), Validators.pattern("^[a-zA-Z_. ]*$")]],
      state: [(infoData.address) ? infoData.address.state : '', [Validators.required]],
      zipcode: [(infoData.address) ? infoData.address.zip : '', [Validators.required, Validators.minLength(5), Validators.maxLength(10), Validators.pattern("(^[0-9]{5}$)|(^[0-9]{5}-[0-9]{4}$)|(^[0-9]{9}$)")]],
      practice_visibility: [infoData.practice_visibility ? infoData.practice_visibility : false, [Validators.required]],
      show_phi: [infoData.show_phi ? infoData.show_phi : false, [Validators.required]],
      pms_info: [(infoData.pms_info) ? infoData.pms_info : ''],
      emailReport: [(infoData.emailReport) ? infoData.emailReport : '', [
        Validators.compose([
          Validators.pattern("^([ ]?[^, ]+@[a-zA-Z0-9][^, ]+[.][^, ]+[ ]?,)*([ ]?[^, ]+@[^, ]+[.][^, ]+)$"), this.commaSepEmail
        ])]],
        npiNumber: [infoData.npi, [Validators.required, Validators.maxLength(10), Validators.pattern('^[0-9\-/ ]*$')]],
        taxId: [infoData.taxid, [Validators.required,Validators.maxLength(10)]],
        taxonomy_code:[infoData.taxonomy_code, [Validators.required]],
        alt_taxonomy:[infoData.alt_Taxonomy],
        phoneno:[infoData.phone, [Validators.required,  Validators.maxLength(15)]],
        callbackphoneno:[infoData.callbackphoneno, [Validators.maxLength(15)]],
        fax:[infoData.fax, [Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],  
        openArtFormat:[null],
        paitentFileFormat:[null],
        payerFormat:[null],
        providerFormat:[null],     
        openAR:[false],
        patientInfo:[false],
        payerInfo:[false],  
        providerInfo:[false]
    });
    if(infoData.sourceReportType!=undefined&&infoData.sourceReportType!='')
    {
       const updatedArray = this.showReportGroups.map(report => {
        const formName = infoData.sourceReportType.find(itemA => itemA.name === report.name);
        if (formName) {
          return {name:report.name,format:formName.format,formName: report.formName };
        } else {
          return {name:report.name, format:null,formName: report.formName};
        }
      });
      infoData.sourceReportType=updatedArray;  
      if(infoData.sourceReportType[0].format!=null&&infoData.sourceReportType[0].format!=''&&infoData.sourceReportType[0].format!=undefined )
      {
         this.openARflag=true;
         this.form.get('openAR').setValue(true);
         this.form.get('openArtFormat').setValue(infoData.sourceReportType[0].format);
      }
      if(infoData.sourceReportType[1].format!=null&&infoData.sourceReportType[1].format!=''&&infoData.sourceReportType[1].format!=undefined )
      {
         this.patientInfoflag=true;
         this.form.get('patientInfo').setValue(true);
         this.form.get('paitentFileFormat').setValue(infoData.sourceReportType[1].format);  
      }
      if(infoData.sourceReportType[2].format!=null&&infoData.sourceReportType[2].format!=''&&infoData.sourceReportType[2].format!=undefined )
      {
        this.form.get('payerInfo').setValue(true);
        this.form.get('payerFormat').setValue(infoData.sourceReportType[2].format);
        this.payerInfoflag=true;
      }
      if(infoData.sourceReportType[3].format!=null&&infoData.sourceReportType[3].format!=''&&infoData.sourceReportType[3].format!=undefined )
      {
       this.form.get('providerInfo').setValue(true);
       this.form.get('providerFormat').setValue(infoData.sourceReportType[3].format);
       this.providerInfoflag=true;
      }
    }
    else{
      this.showReportGroups=[{name:'Open AR',format:'',formName:'openAR'},{name:'Patient Info',format:'',formName:'patientInfo'},{name:'Payer Insurance',format:'',formName:'payerInfo'},{name:'Provider Info',format:'',formName:'providerInfo'}];
    }
  }
  /*initialStateList(){
    this.taskService.listStateNames().subscribe(result => {
      if (result.status == 'success') {
        this.taskService.stateList=[];
        result.results.forEach(element => {
          this.taskService.stateList.push({"state_code" : element.state_code});
        });
      }
    });
  }*/

  zipCodeMask(): any {
    return {
      mask: (value) => {
        if (value.length <= 5) {
          return this.zipCodeFive;
        } else {
          return this.zipCodeNine;
        }
      },
      guide: false
    };
  }
  backToPracticeAccounts(_val) {
    this.taskService.accountHistory = false;
  }
  AccountHistory(id, name) {
    this.userhistoryservice.practice_id = id;
    this.userhistoryservice.organization_name = name;
    this.taskService.accountHistory = true;
  }

  onClick(event: any) {
    if (this.staticMenus != undefined) {
      this.staticMenus.nativeElement.contains(event) ? this.autoHide = !1 : this.autoHide = !0;
    }
  }

  accountSearch(practiceSearch: string) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(_x => {
      this.triggerSearch(practiceSearch);
    }, 1100);
  }

  triggerSearch(searchKey: string) {
    this.allAccountsList = [];
    this.loaderIndicator = true;
    this.searchValue = searchKey;
    let status = this.showInactive ? false : true;
    this.practicesService.searchAccount(this.searchValue, status).subscribe((accountList: any) => {
      // console.log(accountList);
      accountList.status == 'success' ? this.allAccountsList = accountList.results : this.allAccountsList = [];
      let temp=0;
      this.allAccountsList.map(item => {
        item['tabloaderIndicator'] = true;
        item["matrix"]={};
        item["sigma"] = {};
        item['patient_360']={};
        item['type'] = '';
        item['policy_information'] = [];
        this.mipsList[temp]=[];
        temp++;
      });
      this.loaderIndicator = false;
    });
  }

  activeStatus() {
    this.allAccountsList = [];
    this.loaderIndicator = true;
    let active = (this.showInactive == true) ? true : false;
    this.practicesService.searchAccount(this.searchData, active).subscribe((accountList: any) => {
      accountList.status == 'success' ? this.allAccountsList = accountList.results : this.allAccountsList = [];
      this.allAccountsList.map(item => {
        item['tabloaderIndicator'] = true;
        item["matrix"]={};
        item["sigma"] = {};
        item['patient_360']={};
        item['type'] = '';
        item['policy_information'] = [];
      });
      this.loaderIndicator = false;
    });
  }

  tabChange(selectedTab: any, item, index) {
    // this.allAccountsList[index]=[];
    this.allAccountsList[index].tabloaderIndicator = true;
    this.parentIndexValue = index;
    this.seletedTab = selectedTab.target.innerText;
    if (selectedTab.target.innerText == 'Users' || selectedTab.target.innerText == 'Providers' || selectedTab.target.innerText == 'Facilities' || selectedTab.target.innerText == 'Compensation'|| selectedTab.target.innerText == 'Integration' || selectedTab.target.innerText == 'Options') {
      this.practiceMappedUsers = [];
      if (selectedTab.target.innerText != 'Providers') {
        item['type'] = selectedTab.target.innerText;
      }
      this.dynamicBtn = '';
      if (this.seletedTab == 'Users') {
        this.dynamicBtn = 'Map users';
        if (!this.allAccountsList[index]['practice_users']) {
          let enabled = "any";
          let searchKey = "";
          this.practicesService.get_mapped_users(item._id, searchKey, 0, 0, true, true).subscribe(data => {
            this.allAccountsList[index].tabloaderIndicator = false;
            if (data.status == 'success') {
              let practice_user = [];
              data.results.forEach(element => {
                if (element.username) {
                  practice_user.push(element);
                }
              });
              this.allAccountsList[index]['practice_users'] = practice_user;
              this.allAccountsList[index]['practice_users_limit'] = 10;
            }
          });
        }
      } else if (this.seletedTab == 'Providers' || this.seletedTab == 'Compensation') {
        this.selectedPracticeId = this.allAccountsList[index]._id;
        this.compensationPopupList = [];
        if (!this.allAccountsList[index]['practice_providers']) {
          this.practicesService.searchProvider(item._id, '').subscribe((data: any) => {
            this.allAccountsList[index].tabloaderIndicator = false;
            if (data.status == 'success') {
              this.compensationPopupList = data.results;
              item['type'] = selectedTab.target.innerText;
              this.allAccountsList[index]['practice_providers'] = data.results;
              this.allAccountsList[index]['practice_providers_limit'] = 10;
              this.currentPracticeAddressProviders = this.allAccountsList[index].address;
            }
          });
        } else {
          this.allAccountsList[index].tabloaderIndicator = false;
          item['type'] = selectedTab.target.innerText;
        }
        if (this.seletedTab == 'Compensation') {
          this.p_replace = false;
          this.get_policy_mapped(item._id);
        }
      } else if (this.seletedTab == 'Facilities'|| this.seletedTab == 'Integration') {
        this.allAccountsList[index].Patient_360_Integration = (item.external_ids && item.external_ids.patient_360_integration) ? item.external_ids.patient_360_integration : false;
        this.allAccountsList[index].mcProgressNote = (item.external_ids && item.external_ids.mc_progressnotes) ? item.external_ids.mc_progressnotes : false;
        this.allAccountsList[index].scProgressNote = (item.external_ids && item.external_ids.sc_progressnotes) ? item.external_ids.sc_progressnotes : false;
        this.patient360List[this.parentIndexValue] = (item.patient360_mips && item.patient360_mips.length > 0) ? item.patient360_mips : [];
        this.mipsList[this.parentIndexValue] = (item.mips_measures && item.mips_measures.length > 0) ? item.mips_measures : [];
        this.practice_id = this.allAccountsList[index]._id;
        this.getSigmaCareIds(item._id,index);
        this.getPatient360(item._id,index);
        this.errorFlag=false;
        this.errorFlag360=false;
       
        this.dynamicBtn = 'Map facilities';       
        if (!this.allAccountsList[index]['facilities']) {
          this.practicesService.listSearchFacilities(item._id, '').subscribe(data => {
            this.allAccountsList[index].tabloaderIndicator = false;
            if (data.status == 'success') {
              this.allAccountsList[index]['facilities'] = data.results;
              this.allAccountsList[index]['practice_facilities_limit'] = 10;
              this.currentPracticeAddress = this.allAccountsList[index].address;

              // this.matrixFacilityArray=[];
              // if(this.allAccountsList[index]['facilities'] && this.allAccountsList[index]['facilities'].length>0){
              //   this.allAccountsList[index]['facilities'].forEach(element => {
              //     if(element.enabled){
              //       this.matrixFacilityArray.push(element);
              //     }
              //   });
              //   this.enableMatrixCare = (this.matrixFacilityArray.length>0) ? true : false;
              // }
            }
          });
        }
        
      }
    } else if (selectedTab.target.innerText == 'Invoicing') {
      this.practiceinvoice(item, this.parentIndexValue);
    } else if (selectedTab.target.innerText == 'Options') {
    //this.Patient_360_Integration = item.external_ids.patient_360_integration;
     // this.patient360List = (item.patient360_mips && item.patient360_mips.length > 0) ? item.patient360_mips : [];
      // this.mipsList[this.parentIndexValue] = (item.mips_measures && item.mips_measures.length > 0) ? item.mips_measures : [];
     // this.getPatient360(item._id,index);
      // this.errorFlag=false;
      // this.errorFlag360=false;
      // this.error360=false;

    } else {
      this.allAccountsList[index].tabloaderIndicator = false;
    }
  }

  toggleLimit(item, limit, type) {
    item[type] = limit;
  }

  accountStatus(accountStatus: boolean, practiceId: any) {
    let status = accountStatus ? false : true;
    this.practicesService.accountStatusChange(status, practiceId).subscribe(data => {
      if (data) {
        if (accountStatus) {
          this.sharedService.pushToaster('success', 'Success', 'Practice Account Deactivated');
        }
        else {
          this.sharedService.pushToaster('success', 'Success', 'Practice Account Activated');
        }
        this.triggerSearch(this.searchData);
      }
    });
  }

  userStatus(data) {
    let userData = {
      'user_title': data.user_title,
      'user_id': data.user_id,
      'practice_id': data.practice_id,
      'enabled': (data.enabled == true) ? false : true
    };
    // tslint:disable-next-line: no-shadowed-variable
    this.practicesService.userStatusChange(userData).subscribe(data => {
      if (data.status == 'success') {
        this.allAccountsList[this.parentIndexValue].practice_users[this.childIndexValue].enabled = data.results.enabled;
        this.sharedService.pushToaster('success', 'Success', 'User status updated successfully');
      }
    });
  }

  providerStatus(data) {
    let providerData = {
      'practice_id': data.practice_id,
      'provider_id': data._id,
      'enabled': (data.enabled == true) ? false : true
    };
    // tslint:disable-next-line: no-shadowed-variable
    this.practicesService.providerStatusChange(providerData).subscribe(data => {
      if (data.status == 'success') {
        let result = data.result;
        this.allAccountsList[this.parentIndexValue].practice_providers[this.childIndexValue].enabled = result.enabled;
        this.allAccountsList[this.parentIndexValue].practice_providers[this.childIndexValue].last_modified_date = result.last_modified_date;
        this.allAccountsList[this.parentIndexValue].practice_providers[this.childIndexValue].updated_user_id = result.updated_user_id;
        this.sharedService.pushToaster('success', 'Success', 'Provider status updated successfully.');
      } else {
        this.sharedService.pushToaster('danger', 'Update failed', 'Provider status update failed.');
      }
    });
  }

  /*Get list of Account Groups*/
  getAccountGroupList() {
    this.practicesService.fetchAccountGroupList().subscribe(data => {
      this.accountGroupList = data;
      this.accountGroupList.results.forEach(element => {
        this.accountGroupMap.set(element._id, element.accountgroup);
      });
    },
      _err => 'error'
    );
  }

  confirmPopup(accountConfirmPopup: TemplateRef<any>, status: Boolean, practiceSelectedData, type, index, cindex, practice_id) {
    this.selectedPracticeUserObject = practiceSelectedData;
    this.parentIndexValue = index;
    this.childIndexValue = cindex;
    this.confirmPopupFrom = type;
    if (type == 'deactivateAllUsers') {
      this.messageInfo = 'Are you sure want to deactivate All Users ?';
      this.selectedPracticeId = practiceSelectedData._id;
      this.selectedPracticeIndex = index;
    } else if (type == 'deactivateAllFacilities') {
      this.messageInfo = 'Are you sure want to deactivate All Facilities ?';
      this.selectedPracticeId = practiceSelectedData._id;
      this.selectedPracticeIndex = index;
    } else if (type == 'practiceUserStatusChange') {
      this.messageInfo = (status == true) ? 'Are you sure to deactivate User ?' : 'Are you sure to Activate User ?';
    } else if (type == 'providerStatusChange') {
      this.messageInfo = (status == true) ? 'Are you sure want to deactivate this provider?' : 'Are you sure want to Activate this provider?';
    } else if (type == 'deactivatefacilities') {
      this.messageInfo = (status == true) ? 'Are you sure to deactivate Facility ?' : 'Are you sure to Activate Facility ?';
      this.practice_id = practice_id;
    } else if (type == 'deActivatePractice') {
      this.statusChangeObj = { 'status': status, 'practiceId': practiceSelectedData._id };
      this.showInactive ? this.messageInfo = 'Are you sure want to Activate Account ?' : this.messageInfo = 'Are you sure want to Deactivate Account ?';
    }
    this.SharedPopupService.show(accountConfirmPopup, {
      animated: true,
      backdrop: 'static'
    });
  }

  onConfirm() {
    if (this.confirmPopupFrom == 'deactivateAllUsers') {
      this.deactivateAllPracticeUsers();
    } else if (this.confirmPopupFrom == 'deactivateAllFacilities') {
      this.deactiveallFacilities();
    } else if (this.confirmPopupFrom == 'practiceUserStatusChange') {
      this.userStatus(this.selectedPracticeUserObject);
    } else if (this.confirmPopupFrom == 'providerStatusChange') {
      this.providerStatus(this.selectedPracticeUserObject);
    } else if (this.confirmPopupFrom == 'deactivatefacilities') {
      this.facilityStatus(this.selectedPracticeUserObject);
    } else if (this.confirmPopupFrom == 'deActivatePractice') {
      this.accountStatus(this.statusChangeObj.status, this.statusChangeObj.practiceId);
    }
    this.SharedPopupService.dismiss();
  }

  deactivateAllPracticeUsers() {
    this.practicesService.deactivateAllPracticeUsers(this.selectedPracticeId).subscribe(_data => {
      this.allAccountsList[this.selectedPracticeIndex].practice_users.forEach(el => {
        el.enabled = false;
      });
    });
  }

  deactiveallFacilities() {
    this.practicesService.deactivateAllFacility(this.selectedPracticeId).subscribe(_data => {
      this.allAccountsList[this.selectedPracticeIndex].facilities.forEach(el => {
        el.enabled = false;
      });
      this.sharedService.pushToaster('success', 'Success', 'All Facility status updated successfully');
    });
  }

  facilityStatus(data) {
    let facilityid = data._id;
    let facilityStatusData = {
      'status': (data.enabled == true) ? false : true
    };
    // tslint:disable-next-line: no-shadowed-variable
    this.practicesService.facilitiesStatusChange(facilityid, this.practice_id, facilityStatusData).subscribe(data => {
      if (data.status == 'success') {
        this.allAccountsList[this.parentIndexValue].facilities[this.childIndexValue].enabled = facilityStatusData.status;
        this.sharedService.pushToaster('success', 'Success', 'Facility status updated successfully');
      }
    });
  }

  onCancel() {
    this.SharedPopupService.dismiss();
  }

  onCancelNPI() {
    this.SharedPopupService.dismissNPI();
  }

  checkDigit(event: any) {
    let pattern = /\d/g;
    let checkIsNumericKey = event.key.match(pattern);
    if (checkIsNumericKey != null) {
      let conformedNumber = conformToMask(
        event.key,
        this.einMask,
        { guide: false }
      );
      this.maskValidation = conformedNumber.meta.someCharsRejected;
    }
  }

  ssnDigit(event: any) {
    let pattern = /\d/g;
    let checkIsNumericKey = event.key.match(pattern);
    if (checkIsNumericKey != null) {
      let conformedNumber = conformToMask(
        event.key,
        this.ssnMask,
        { guide: false }
      );
      this.maskValidationPro = conformedNumber.meta.someCharsRejected;
    }
  }

  sameAsPracticeAddressProvider(addProvider, editProvider, val) {
    this.stateSuccessResult = '';
    this.sameAsPracticeFacility = val;
    if ((addProvider == 'addProvider' && this.sameAsPractice == true) || (editProvider == 'editProvider' && this.sameAsPracticeFacility == true)) {
      if (this.currentPracticeAddressProviders) {
        this.getState(null, this.currentPracticeAddressProviders.state);
        this.providerObject.streetLine1 = this.currentPracticeAddressProviders.street_line_1;
        this.providerObject.streetLine2 = this.currentPracticeAddressProviders.street_line_2;
        this.providerObject.city = this.currentPracticeAddressProviders.city;
        this.providerObject.state = this.currentPracticeAddressProviders.state;
        this.providerObject.zipcode = this.currentPracticeAddressProviders.zip;
      }
    } else if (addProvider == 'addProvider' && this.sameAsPractice == false) {
      this.npiProviderData(this.providerObject.npi, 'sameAsPracticeToggle');
      this.stateSuccessResult = 'success';
      // this.providerObject.streetLine1 = '';
      // this.providerObject.streetLine2 = '';
      // this.providerObject.city = '';
      // this.providerObject.state = '';
      // this.providerObject.zipcode = '';
    } else if (editProvider == 'editProvider' && this.sameAsPracticeFacility == false) {
      if (this.providerDetails.address) {
        this.getState(null, this.providerDetails.address.state);
        this.providerObject.streetLine1 = this.providerDetails.address.street_line_1;
        this.providerObject.streetLine2 = this.providerDetails.address.street_line_2;
        this.providerObject.city = this.providerDetails.address.city;
        this.providerObject.state = this.providerDetails.address.state;
        this.providerObject.zipcode = this.providerDetails.address.zip;
      }
    }
  }

  sameAsPracticeAddress(addfacility, editfacility, val) {
    this.stateSuccessResult = '';
    this.sameAsPracticeFacility = val;
    if ((addfacility == 'addfacility' && this.sameAsPractice == true) || (editfacility == 'editfacility' && this.sameAsPracticeFacility == true)) {
      if (this.currentPracticeAddress) {
        this.getState(null, this.currentPracticeAddress.state);
        this.facilityData.street1 = this.currentPracticeAddress.street_line_1;
        this.facilityData.street2 = this.currentPracticeAddress.street_line_2;
        this.facilityData.city = this.currentPracticeAddress.city;
        this.facilityData.zipcode = this.currentPracticeAddress.zip;
        this.facilityData.state = this.currentPracticeAddress.state;
      }
    } else if (addfacility == 'addfacility' && this.sameAsPractice == false) {
      this.stateSuccessResult = 'success';
      this.facilityData.street1 = '';
      this.facilityData.street2 = '';
      this.facilityData.city = '';
      this.facilityData.state = '';
      this.facilityData.zipcode = '';
    } else if (editfacility == 'editfacility' && this.sameAsPracticeFacility == false) {
      if (this.viewFacilityData.address) {
        this.getState(null, this.viewFacilityData.address.state);
        this.facilityData.street1 = this.viewFacilityData.address.street_line_1;
        this.facilityData.street2 = this.viewFacilityData.address.street_line_2;
        this.facilityData.city = this.viewFacilityData.address.city;
        this.facilityData.state = this.viewFacilityData.address.state;
        this.facilityData.zipcode = this.viewFacilityData.address.zip;
      }
    }
  }
  // tslint:disable-next-line: no-shadowed-variable
  openFacilityModal(facilityTemplate: TemplateRef<any>, from, infoData, parentIndex, childIndex, practice_id) {
    this.popUpfrom = from;
    this.parentIndexValue = parentIndex;
    this.childIndexValue = childIndex;
    this.popUpinfo = infoData;
    if (from == 'addFacility') {
      this.stateSuccessResult = 'success';
      this.facilityPopupTitle = 'Add new Facility';
      this.facilityData = {
        'npi': '',
        'ein': '',
        'exId': '',
        'facilityName': '',
        'nickName': '',
        'street1': '',
        'street2': '',
        'city': '',
        'state': '',
        'zipcode': '',
        'phone': '',
        'fax': '',
        'cliaNumber': '',
        'pos': ''
      };
      this.SharedPopupService.show(facilityTemplate, {
        animated: true,
        backdrop: 'static'
      });
    } else if (from == 'editFacility') {
      if (infoData.address) {
        this.getState(null, infoData.address.state);
      }
      this.practicesService.viewFacility(practice_id, infoData._id).subscribe(data => {
        if (data.status == 'success') {
          this.viewFacilityData = data.results[0];
          this.successResult = data.status;
          // tslint:disable-next-line: no-shadowed-variable
          this.getPlaceOfService(this.viewFacilityData.place_of_service).then(_data => {
            this.selectedFacilityId = infoData._id;
            this.facilityPopupTitle = `Edit Facility for ${this.viewFacilityData.name}`;
            this.facilityData = {
              npi: this.viewFacilityData.npi,
              ein: conformToMask(
                this.viewFacilityData.ein,
                this.einMask,
                { guide: false }
              ).conformedValue,
              exId: this.viewFacilityData.external_ids.ext_locationid,
              facilityName: this.viewFacilityData.name,
              nickName: this.viewFacilityData.short_name,
              street1: this.viewFacilityData.address.street_line_1,
              street2: this.viewFacilityData.address.street_line_2,
              city: this.viewFacilityData.address.city,
              state: this.viewFacilityData.address.state,
              zipcode: this.viewFacilityData.address.zip,
              phone: this.viewFacilityData.phone,
              fax: this.viewFacilityData.fax,
              cliaNumber: this.viewFacilityData.clia_number,
              pos: this.viewFacilityData.place_of_service
            };
            if (this.facilityData.zipcode) {
              this.zipCodeMask();
            }
            this.SharedPopupService.show(facilityTemplate, {
              animated: true,
              backdrop: 'static'
            });
          });

        }

      });

    }


  }

  facilityModalClose() {
    this.sameAsPractice = false;
    this.sameAsPracticeFacility = false;
    this.maskValidation = false;
    this.SharedPopupService.dismiss();
    // this.alreadyExistFlag = false
  }

  // tslint:disable-next-line: no-shadowed-variable
  openModal(template: TemplateRef<any>, from, infoData, parentIndex, childIndex) {
    this.popUpfrom = from;
    this.parentIndexValue = parentIndex;
    this.childIndexValue = childIndex;
    this.popUpinfo = infoData;
    if (from == 'addPractice') {
      this.popupTitle = 'New Practice Account';
      this.buildPracticeForm();
      this.show_phi=false;
      // this.practiceAccount = {
      //   practiceId: '',
      //   practiceName: '',
      //   accountGroupId: '',
      //   accountNumber: '',
      //   practiceNumber: '',
      //   streetLine1: '',
      //   streetLine2: '',
      //   city: '',
      //   state: '',
      //   zipcode: '',
      //   practice_visibility: false
      // };
    } else if (from == 'editPractice') {
      this.popupTitle = 'Edit Practice Account';
      if (infoData.address) {
        this.getState(null, infoData.address.state);
      }
      if (infoData.address && infoData.address.zip) {
        this.zipCodeMask();
      }
      let check_group_id = infoData.accountgroup ? infoData.accountgroup : infoData.mappedaccount[0]._id;
      let tempGrpName = this.accountGroupList.results.filter(name => name._id == check_group_id);
      infoData.accountgroup = tempGrpName[0] ? tempGrpName[0].accountgroup : "";
      this.editPracticeForm(infoData);
      // this.practiceAccount = {
      //   practiceId: infoData._id,
      //   practiceName: infoData.organization_name,
      //   accountGroupId: infoData.accountgroup,
      //   accountNumber: infoData.accountno,
      //   practiceNumber: infoData.practiceno,
      //   streetLine1: (infoData.address)?infoData.address.street_line_1:'',
      //   streetLine2: (infoData.address)?infoData.address.street_line_2:'',
      //   city: (infoData.address)?infoData.address.city:'',
      //   state: (infoData.address)?infoData.address.state:'',
      //   zipcode: (infoData.address)?infoData.address.zip:'',
      //   practice_visibility: infoData.practice_visibility
      // };
    } else if (from == 'editUserTitle') {
      this.popupTitle = 'Edit User Title';
      this.userTitle = this.popUpinfo.user_title;
     this. practice_visibility = this.popUpinfo.practice_visibility
    }
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static'
    });
  }

  modalClose() {
    this.searchValueUser = '';
    this.searchKeyWordFacility = '';
    this.sameAsPractice = false;
    this.sameAsPracticeFacility = false;
    this.maskValidationPro = false;
    this.SharedPopupService.dismiss();
    this.alreadyExistFlag = false;
    this.buildPracticeForm();
    this.formSubmitted = false;
    this.currentPage = 1;
    this.state_array_item = [];
    this.selectedStateCode=null;
    this.openARflag=false;
    this.patientInfoflag=false;
    this.payerInfoflag=false;
    this.providerInfoflag=false;
    this.form.get('paitentFileFormat').setValue('')
    this.form.get('openArtFormat').setValue('')
    this.form.get('payerFormat').setValue('')
    this.form.get('providerFormat').setValue('')
  }

  // tslint:disable-next-line: no-shadowed-variable
  openUserModal(template: TemplateRef<any>, from, infoData, parentIndex, childIndex) {
    this.currentPage = 1;
    this.popUpfrom = from;
    this.parentIndexValue = parentIndex;
    this.childIndexValue = childIndex;
    this.popUpinfo = infoData;
    if (from == 'mapUser') {
      this.tmpMappingData = [];
      this.practiceMappedUsers = [];
      let tmpexistingData = _.cloneDeep(this.popUpinfo.practice_users);
      if (tmpexistingData) {
        tmpexistingData.forEach(element => {
          if (element.enabled == true) {
            this.practiceMappedUsers.push(element);
          }
          this.tmpMappingData.push(element);
        });
      }
      this.searchUsers(this.searchValueUser, true, 10, 0, true);
      // this.practicesService.listPracticeUsers(this.popUpinfo._id, this.searchValueUser, 'any', 'true',10,0).subscribe(result => {
      //   if (result.status == 'success') {
      //     // this.practiceUserEmptyResult = result;
      //     this.practiceUsersData = result.results[0]['results'];
      //     this.totalCount = result.results && result.results[0] && result.results[0]['totalCount'] && result.results[0]['totalCount'][0] ? result.results[0]['totalCount'][0]['count'] : 0;
      //     this.defaultSelection();
      //   }
      // });
    }
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }

  pageChanged(val) {
    this.selectedpagenumber = val.page
    this.page = val.page ? val.page - 1 : 0;
    this.searchUsers(this.searchValueUser, true, 10, this.page, true);
  }

  pageChangeProviderV2(val) {
    this.selectedpagenumber = val.page
    this.page = val == 0 ? 0 : (val.page * this.defaultLimit - this.defaultLimit);
    this.mapFacilitiesToProviderV2();
  }

  pageChangeFacilityV2(val) {
    this.selectedpagenumber = val.page
    this.page = val == 0 ? 0 : (val.page * this.defaultLimit - this.defaultLimit);
    this.mapProviderToFacilitiesv2();
  }

  getState(_practiceId, searchKey) {
    let filtered_data = [];
    let stateval = this.taskService.stateList;
    filtered_data = this.taskService.filterByValue(stateval, searchKey);
    if (filtered_data.length > 0) {
      this.stateSuccessResult = 'success';
    }
    for (let i in filtered_data) {
      if (parseInt(i) < 5) {
        this.state_array_item[i] = {
          id: filtered_data[i].state_code,
          text: filtered_data[i].state_code
        };
      }
    }
  }

  stateSelectedValue(stateCode) {
    this.selectedStateCode = stateCode;
  }

  addEditPracticeAccount(model: PracticeAccount, isValid: boolean, mynform: NgForm) {
  if(this.openARflag == false && this.patientInfoflag==false&& this.payerInfoflag==false &&
     this.providerInfoflag==false)
     { 
      this.sharedService.pushToaster('warning', 'Info',  'Please select Source Report Type');
      return;
     }
   if((this.openARflag && (this.form.get('openArtFormat')==null||this.form.get('openArtFormat').value==''||this.form.get('openArtFormat').value==null))||
   (this.patientInfoflag && (this.form.get('paitentFileFormat')==null||this.form.get('paitentFileFormat').value==''||this.form.get('paitentFileFormat').value==null))
   ||(this.payerInfoflag && (this.form.get('payerFormat')==null||this.form.get('payerFormat').value==''||this.form.get('payerFormat').value==null))||
  (this.providerInfoflag && (this.form.get('providerFormat')==null||this.form.get('providerFormat').value==''||this.form.get('providerFormat').value==null)))
   { 
    this.sharedService.pushToaster('warning', 'Info',  'Please select File extension');
    return;
   }
    this.practiceFormValue=model
    this.formSubmitted = true;
    if (this.form.value.accountGroupId == 'Imagine') {
      const practiceNumberCtrl = this.form.get('practiceNumber');
      const npiNumberCtrl=this.form.get('npiNumber');
      const taxIdCtrl=this.form.get('taxId');
      const streetLine1Ctrl = this.form.get('streetLine1');
      const cityCtrl = this.form.get('city');
      const stateCtrl = this.form.get('state');
      const zipcodeCtrl = this.form.get('zipcode');
      const emailReportCtrl = this.form.get('emailReport');
      const pmsCtrl=this.form.get('pms_info');
      const alt_taxonomyCtrl = this.form.get('alt_taxonomy');
      const taxonomy_codeCtrl = this.form.get('taxonomy_code');
      const phonenoCtrl = this.form.get('phoneno');
      const faxCtrl = this.form.get('fax');
      const callbackphonenoCtrl=this.form.get('callbackphoneno');
      const show_phiCtrl=this.form.get('show_phi');
      
      practiceNumberCtrl.setValidators([Validators.minLength(2), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]);
      streetLine1Ctrl.setValidators(null);
      cityCtrl.setValidators([Validators.minLength(3), Validators.pattern("^[a-zA-Z_. ]*$")]);
      stateCtrl.setValidators(null);
      zipcodeCtrl.setValidators([Validators.minLength(5), Validators.maxLength(10), Validators.pattern("(^[0-9]{5}$)|(^[0-9]{5}-[0-9]{4}$)|(^[0-9]{9}$)")]);
      emailReportCtrl.setValidators([Validators.pattern("^([ ]?[^, ]+@[a-zA-Z0-9][^, ]+[.][^, ]+[ ]?,)*([ ]?[^, ]+@[^, ]+[.][^, ]+)$")]);
      pmsCtrl.setValidators(null);
      practiceNumberCtrl.updateValueAndValidity();
      streetLine1Ctrl.updateValueAndValidity();
      cityCtrl.updateValueAndValidity();
      stateCtrl.updateValueAndValidity();
      zipcodeCtrl.updateValueAndValidity();
      emailReportCtrl.updateValueAndValidity();
      npiNumberCtrl.setValidators([Validators.minLength(10), Validators.pattern("^[a-zA-Z_. ]*$")]);
      taxIdCtrl.setValidators([Validators.minLength(10)]);
      alt_taxonomyCtrl.setValidators([Validators.minLength(10)])
      taxonomy_codeCtrl.setValidators([Validators.minLength(10), Validators.pattern("^[a-zA-Z_. ]*$")]);
      phonenoCtrl.setValidators([Validators.minLength(15) ]);
      callbackphonenoCtrl.setValidators([Validators.minLength(15) ]);
      show_phiCtrl.updateValueAndValidity();
      faxCtrl .setValidators(null);
    }
    let sourceReportType={}
    let globalSourceType=[]
    globalSourceType=[ {
        name:'Open AR',
        format:this.form.get('openArtFormat').value
      },{
        name:'Patient Info',
        format:this.form.get('paitentFileFormat').value
      },{
        name:'Payer Insurance',
        format:this.form.get('payerFormat').value
      },{
      name:'Provider Info',
      format:this.form.get('providerFormat').value
     }]
     sourceReportType= globalSourceType.filter(item => item.format !== null && item.format !== '');
    if (model.zipcode) {
      let tempZip = model.zipcode.split('-');
      model.zipcode = tempZip.join('');
    }
    let tempGrpName = this.accountGroupList.results.filter(name => name.accountgroup == model.accountGroupId);
    model.accountGroupId = tempGrpName[0] ? tempGrpName[0]._id : "";
    this.PracticeAccountData = {
      practiceId: model.practiceId,
      accountgroup: model.accountGroupId,
      accountno: model.accountNumber,
      practiceno: model.practiceNumber,
      practice_visibility: model.practice_visibility,
      show_phi:model.show_phi,
      organization_name: model.practiceName,
      sourceReportType:sourceReportType,
      'address': {
        'city': model.city || '',
        'state': model.state || '',
        'zip': model.zipcode || '',
        'street_line_1': model.streetLine1 || '',
        'street_line_2': model.streetLine2 || ''
      },
      accountgroupName: tempGrpName[0] ? tempGrpName[0].accountgroup : "",
      npis: model.npiNumber,
      eins: model.taxId,
      taxonomycode:model.taxonomy_code,
      alttaxonomy:model.alt_taxonomy,
      phoneno:model.phoneno,
      faxno: model.fax,
      callbackphoneno:model.callbackphoneno,
      pms_info:model.pms_info
    }
this.show_phi = model.show_phi;
    this.PracticeAccountData.emailReport = model.emailReport;
    if (this.form.valid == true) {
      if (this.popUpfrom == 'addPractice') {
        this.practicesService.addPracticeAccount(this.PracticeAccountData).subscribe(data => {
          this.contacts = data;
          this.ValidatingNewPracticeAccount(this.contacts.status);
          if(this.contacts.message)
          {
            this.validationMessage = this.contacts.message;
          }
          if(this.contacts.status)
          {
            this.validationStatus = this.contacts.status;
          }
          if (this.validationStatus == 'success') {
          this.contacts.data['tabloaderIndicator'] = true;
          this.contacts.data["matrix"]={};
          this.contacts.data["sigma"] = {};
          this.contacts.data['patient_360']={};
          this.contacts.data['type'] = '';
          this.contacts.data['policy_information'] = [];
            this.allAccountsList.unshift(this.contacts.data);
            this.buildPracticeForm();
            this.modalClose();
            this.sharedService.pushToaster('success', 'Success', 'New practice account created successfully.');
          } else {
            this.sharedService.pushToaster('error', 'Error', this.contacts.message);
          }
        },
          err => this.logError(err),
        );
      }

      if (this.popUpfrom == 'editPractice') {
        this.practicesService.updatePracticeAccount(this.PracticeAccountData).subscribe(data => {
          data = this.removeNullEntries(data);
          this.contacts = data;
          this.ValidatingNewPracticeAccount(this.contacts.status);
          this.practiceAccountEditStatus = this.contacts.status;
          this.validationMessage = this.contacts.message;
          if (this.practiceAccountEditStatus == 'success') {
            this.allAccountsList[this.parentIndexValue].organization_name = model.practiceName;
            this.allAccountsList[this.parentIndexValue].practiceno = model.practiceNumber;
            this.allAccountsList[this.parentIndexValue].accountno = model.accountNumber;
            this.allAccountsList[this.parentIndexValue].accountgroup = model.accountGroupId;
            this.allAccountsList[this.parentIndexValue].address.street_line_1 = model.streetLine1;
            this.allAccountsList[this.parentIndexValue].address.street_line_2 = model.streetLine2;
            this.allAccountsList[this.parentIndexValue].address.city = model.city;
            this.allAccountsList[this.parentIndexValue].address.state = model.state;
            this.allAccountsList[this.parentIndexValue].address.zip = model.zipcode.toString();
            this.allAccountsList[this.parentIndexValue].practice_visibility = model.practice_visibility;
            this.allAccountsList[this.parentIndexValue].show_phi = model.show_phi;
            this.allAccountsList[this.parentIndexValue].sourceReportType =globalSourceType;
            this.allAccountsList[this.parentIndexValue].openArtFormat =model.openArtFormat;
            this.allAccountsList[this.parentIndexValue].paitentFileFormat =model.paitentFileFormat;
            this.allAccountsList[this.parentIndexValue].payerFormat =model.payerFormat;
            this.allAccountsList[this.parentIndexValue].providerFormat =model.providerFormat;
            this.allAccountsList[this.parentIndexValue].openAR=model.openAR;
            this.allAccountsList[this.parentIndexValue].patientInfo =model.patientInfo;
            this.allAccountsList[this.parentIndexValue].payerInfo =model.payerInfo;
            this.allAccountsList[this.parentIndexValue].providerInfo =model.providerInfo;
            this.allAccountsList[this.parentIndexValue].emailReport = model.emailReport;
            this.allAccountsList[this.parentIndexValue].pms_info = model.pms_info;
            this.allAccountsList[this.parentIndexValue].npino = model.npiNumber;
            this.allAccountsList[this.parentIndexValue].taxid = model.taxId;
            this.allAccountsList[this.parentIndexValue].fax = model.fax;
            this.buildPracticeForm();
            this.modalClose();
            if(this.DataHelper.getItem('practice_id')== model.practiceId){  //To avoid delay in assigning sourceReportType in batch Module
              this.DataHelper.setItem('source_report_type', sourceReportType);
            }
            this.sharedService.pushToaster('success', 'Success', 'Practice account updated successfully.');
          } else {
            this.sharedService.pushToaster('error', 'Error', this.contacts.message);
          }
        },
          err => this.logError(err)
        );
      }
    }
  }

  getPlaceOfService(searchKey) {
    return new Promise<void>((resolve, _reject) => {
      this.practicesService.searchPlaceOfService(searchKey).subscribe(data => {
        if (data.status == 'success') {
          for (let i in data.results) {
            this.pos_array_item[i] = {
              id: data.results[i].code,
              text: `${data.results[i].code} - ${data.results[i].place_of_service}`
            };
          }
          resolve();
        }
      });
    });
  }

  posSelectedValue(selectedPos) {
    this.selectedposCode = selectedPos;
  }

  addEditFacility(model: FacilityData, isValid: boolean, mynform: NgForm) {
    if (isValid == true) {
      let temp = model.ein.replace(/-/g, '');
      model.ein = temp;
      if (model.zipcode) {
        let tempZip = model.zipcode.split('-');
        model.zipcode = tempZip.join('');
      }
      if (this.popUpfrom == 'addFacility') {
        this.facilityDataObj = {
          'newfacility': {
            'npi': model.npi,
            'ein': model.ein,
            'extid': model.exId,
            'name': model.facilityName,
            'nickname': model.nickName,
            'street1': model.street1,
            'street2': model.street2,
            'city': model.city,
            'state': model.state,
            'zip': model.zipcode,
            'phone': '',
            'fax': '',
            'clia': model.cliaNumber,
            'pos': model.pos
          },
          hasExternalIds : false
        };

        this.practicesService.addFacilityService(this.practice_id, this.facilityDataObj).subscribe(data => {
          if (data.status == 'success') {
            if (this.allAccountsList[this.parentIndexValue].practice_facilities_limit == undefined) {
              this.allAccountsList[this.parentIndexValue].practice_facilities_limit = 10;
            }
            if (this.allAccountsList[this.parentIndexValue].facilities) {
              this.allAccountsList[this.parentIndexValue].facilities.unshift(data.results);
              this.sharedService.pushToaster('success', 'Success', 'New facility account created successfully.');
            } else if (this.allAccountsList[this.parentIndexValue].facilities == undefined) {
              this.allAccountsList[this.parentIndexValue]['facilities'] = [data.results];
              this.sharedService.pushToaster('success', 'Success', 'New facility account created successfully.');
            }
            mynform.resetForm();
            this.facilityModalClose();
          } else {
            this.facilityErrMessage = data.message;
            this.sharedService.pushToaster('error', 'Error', this.facilityErrMessage);
            // mynform.resetForm();
            // this.facilityModalClose();
          }
        });
      } else if (this.popUpfrom == 'editFacility') {
        this.facilityDataObj = {
          'editfacility': {
            'npi': model.npi,
            'ein': model.ein,
            'extid': model.exId,
            'name': model.facilityName,
            'nickname': model.nickName,
            'street1': model.street1,
            'street2': model.street2,
            'city': model.city,
            'state': model.state,
            'zip': model.zipcode,
            'phone': '',
            'fax': '',
            'clia': model.cliaNumber,
            'pos': model.pos
          },
          hasExternalIds : false
        };
        // tslint:disable-next-line: no-unused-expression
        this.sameAsPractice == false;
        this.practicesService.editFacility(this.practice_id, this.selectedFacilityId, this.facilityDataObj).subscribe(data => {
          if (data.status == 'success') {
            this.allAccountsList[this.parentIndexValue].facilities[this.childIndexValue].name = model.facilityName,
              this.allAccountsList[this.parentIndexValue].facilities[this.childIndexValue].npi = model.npi;
            this.allAccountsList[this.parentIndexValue].facilities[this.childIndexValue].short_name = model.nickName,
              this.allAccountsList[this.parentIndexValue].facilities[this.childIndexValue].address.city = model.city,
              this.allAccountsList[this.parentIndexValue].facilities[this.childIndexValue].address.state = model.state,
              this.allAccountsList[this.parentIndexValue].facilities[this.childIndexValue].address.street_line_1 = model.street1,
              this.allAccountsList[this.parentIndexValue].facilities[this.childIndexValue].address.street_line_2 = model.street2,
              this.allAccountsList[this.parentIndexValue].facilities[this.childIndexValue].address.zip = model.zipcode;
            mynform.resetForm();
            this.facilityModalClose();
            this.sharedService.pushToaster('success', 'Updated', 'Facility account updated successfully.');
          } else {
            this.facilityErrMessage = data.message;
            this.sharedService.pushToaster('error', 'Error', this.facilityErrMessage);
            // mynform.resetForm();
            // this.facilityModalClose();
          }
        });

      } else {
        // console.log('not met the conditions')
      }
    }

  }

  ValidatingNewPracticeAccount(userresponse) {
    if (userresponse == 'Practice name already exist.') {
      this.successflag = false;
      this.alreadyExistFlag = true;
    } else if (userresponse == 'success') {
      this.alreadyExistFlag = false;
      this.successflag = true;
      this.timer = setInterval(() => {
        this.successflag = false;
        clearInterval(this.timer);
      }, 1000);
    }
  }

  removeNullEntries(obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        obj[key] = this.removeNullEntries(obj[key]);
      } else if (obj[key] === undefined || obj[key] === null) {
        obj[key] = '';
      } else {
        obj[key] = obj[key];
      }
    });
    return obj;
  }

  clearform(mynform: NgForm) {
    mynform.resetForm();
    this.alreadyExistFlag = false;
  }

  selectPracticeUser(data, _i) {
    if (data.enabled) {
      if (this.practiceMappedUsers == undefined) {
        this.practiceMappedUsers = [];
      }
      data['enabled'] = true;
      this.practiceMappedUsers.push(data);
    } else {
      if (this.dynamicBtn == 'Map users') {
        var index = this.practiceMappedUsers.findIndex(result => result.user_id === data.user_id);
        this.tmpMappingData.map(elem => {
          if (elem.user_id === data.user_id) {
            elem.enabled = false;
          }
          return elem;
        });
      }
      this.practiceMappedUsers.splice(index, 1);
    }
  }

  selectPracticeUsers(data, i) {
    if (data.enabled) {
      if (this.practiceMappedUsers == undefined) {
        this.practiceMappedUsers = [];
      }
      
      data['enabled'] = true;
      data['practice_id'] = this.allAccountsList[this.parentIndexValue]._id;
      this.practiceMappedUsers.push(data);
      this.practicesService.mapUserFromPractice(this.allAccountsList[this.parentIndexValue]._id, data._id).subscribe(result => {
        let mapped_user_index = this.allAccountsList[this.parentIndexValue].practice_users.findIndex(user => { return user.user_id == data.user_id });
        if (mapped_user_index == -1) {
          this.allAccountsList[this.parentIndexValue].practice_users.push(data);
        } else {
          this.allAccountsList[this.parentIndexValue].practice_users[mapped_user_index].enabled = true;
        }
        if (result.status == "success") {
          this.sharedService.pushToaster(
            "success",
            "Success",
            "Users mapped to practice successfully"
          );
        }
      });
    } else {
      this.practicesService.deleteUser(this.allAccountsList[this.parentIndexValue]._id, data._id).subscribe(result => {
        if (result.status == "success") {
          let index = this.allAccountsList[this.parentIndexValue].practice_users.findIndex((user) => {
            return user.user_id == data.user_id
          })
          this.allAccountsList[this.parentIndexValue].practice_users[index].enabled = false;
          let practice_map_index = this.practiceMappedUsers.findIndex(result => result.user_id === data.user_id);
          this.practiceMappedUsers.splice(practice_map_index, 1);
          this.sharedService.pushToaster(
            "success",
            "Success",
            "User removed from practice successfully"
          );
        }
      });
    }
  }
  removeMappedUser(userAccount, indexs) {
    if (userAccount) {
      this.practicesService.deleteUser(this.allAccountsList[this.parentIndexValue]._id, userAccount.user_id).subscribe(result => {
        if (result.status == "success") {
          let index = this.allAccountsList[this.parentIndexValue].practice_users.findIndex((user) => {
            return user.user_id == userAccount.user_id
          })
          this.allAccountsList[this.parentIndexValue].practice_users[index].enabled = false;
          this.practiceMappedUsers.splice(indexs, 1);
          this.sharedService.pushToaster(
            "success",
            "Success",
            "Users mapped removed successfully"
          );
        }
      });
      let practice_index = this.practiceUsersData.findIndex(result => result.user_id === userAccount.user_id); // this.practiceUsersData
      if (practice_index >= 0) {
        this.practiceUsersData[practice_index].enabled = false;
      }
    }
  }

  mapNewUserToPractice() {
    let loopCount = 0;
    let allUsersAccounts = this.tmpMappingData;
    let updatedDataIds = [];
    this.practiceMappedUsers.forEach(element => {
      updatedDataIds.push(element.user_id);
    });
    let mappedData = JSON.parse(JSON.stringify(this.practiceMappedUsers));
    allUsersAccounts.forEach(element => {
      let index = this.practiceMappedUsers.findIndex(result => result.user_id === element.user_id);
      if (index != -1) {
        this.practiceMappedUsers.splice(index, 1);
      }

      if (updatedDataIds.indexOf(element.user_id) == -1) {
        mappedData.push(element);
      }
    });
    let finelmappedData = [];
    let i = 0;
    mappedData.forEach(element => {
      let selectedAccount = {
        'practice_id': this.allAccountsList[this.parentIndexValue]._id,
        'user_title': element.user_title ? element.user_title : 'default',
        'user_id': element.user_id,
        'practice_visibility': element.practice_visibility ? element.practice_visibility : false,
        'enabled': element.enabled,
        'last_modified_date': new Date(),
        'last_modified_user_id': this.DataHelper.getItem('userid'),
      };
      finelmappedData.push(selectedAccount);
      i++;
    });
    if (i == mappedData.length) {
      this.practicesService.bulkUserInsert(this.allAccountsList[this.parentIndexValue]._id, finelmappedData).subscribe(_data => {
        this.allAccountsList[this.parentIndexValue].practice_users = mappedData;
        this.allAccountsList[this.parentIndexValue].practice_users_limit = mappedData.length;
        this.modalClose();
        this.timeout = setTimeout(_x => {
          this.sharedService.pushToaster('success', 'Success', 'Users mapped to practice successfully');
        }, 400);
      }, _err => {
        this.modalClose();
        this.sharedService.pushToaster('error', 'Failure', 'Bulk user mapping failed');
      });
    }
  }

  searchUsersModelUpdated(value) {
    let tempBool = false;
    this.currentPage = 1;
    let testPattern = /^[a-zA-Z0-9 ]*$/;
    let regExVal = new RegExp(testPattern);
    tempBool = regExVal.test(value);
    if (tempBool) {
      this.page = 0;
      this.searchUsers(value, true);
    }
  }

  searchUsers(searchValue: String, showActive: any, pagesize: number = this.defaultLimit, page: number = 0, isInternal = true) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.usersService.searchUsers(searchValue, showActive, pagesize, page, isInternal).subscribe(result => {
        //console.log(result);
        if (result.status == 'success') {
          // this.practiceUserEmptyResult = result;
          this.practiceUsersData = result.results;
          this.totalCount = result.totalcount;
          this.defaultSelection();
        }
      });
    }, 400);
  }

  defaultSelection() {
    this.practiceUsersData.forEach(element => {
      let flag = false;
      this.practiceMappedUsers.forEach(tmp => {
        if (element.user_id == tmp.user_id) {
          flag = true;
        }
      });
      if (flag == false) {
        element.enabled = false;
      } else {
        element.enabled = true;
      }
    });
  }

  /*  Start - Add & Edit Providers */
  // tslint:disable-next-line: no-shadowed-variable
  openProviderModal(template: TemplateRef<any>, from, infoData, parentIndex, childIndex) {
    this.popUpfrom = from;
    this.parentIndexValue = parentIndex;
    this.childIndexValue = childIndex;
    this.popUpinfo = infoData;
    this.providerDetails = infoData.practice_providers[childIndex];

    if (from == 'addProvider') {
      this.stateSuccessResult = 'success';
      this.popupTitle = 'New Provider for Practice';
      this.taxonomySearch('', '', 'initial');
      this.providerObject = {
        providerId: '',
        practiceId: '',
        practiceName: '',
        userId: '',
        userName: '',
        npi: '',
        ssn: '',
        ein: '',  // This is tax_id field in API
        type: 'Individual',
        entity: false,
        organizationName: '',
        firstName: '',
        lastName: '',
        middleName: '',
        shortName: '',
        prefixName: '',
        streetLine1: '',
        streetLine2: '',
        city: '',
        state: '',
        zipcode: '',
        enabled: true,
        taxonomyCode: '',
        alternativeTaxonomy: '',
        extCode: '',
        extId: '',
        extName: '',
        listTypes: this.listTypes
      };

    } else if (from == 'editProvider') {
      this.popupTitle = 'Edit Provider Details';
      if (this.providerDetails.address) {
        this.getState(null, this.providerDetails.address.state);
      }
      if (this.userSelectedText && (this.userSelectedText.length > 0)) {
        this.getUser(this.userSelectedText);
      } else {
        this.getUser(this.providerDetails.username);
      }
      // this.taxonomySearch(this.providerDetails.taxonomy_code);
      // this.taxonomySearch(this.providerDetails.alt_taxonomy);
      let openEin;
      if (this.providerDetails.tax_id) {
        openEin = conformToMask(
          this.providerDetails.tax_id,
          this.einMask,
          { guide: false }
        ).conformedValue;
      }
      this.providerObject = {
        providerId: this.providerDetails._id,
        practiceId: this.providerDetails.practice_id,
        practiceName: '', // Need to fetch this for title purpose
        userId: this.providerDetails.user_id,
        userName: this.providerDetails.username,
        npi: this.providerDetails.npi,
        ssn: this.providerDetails.ssn,
        ein: this.providerDetails.tax_id ? openEin : this.providerDetails.tax_id,  // This is tax_id field in API
        type: this.providerDetails.type,
        entity: this.providerDetails.entity,
        organizationName: this.providerDetails.organization_name,
        firstName: this.providerDetails.first_name,
        lastName: this.providerDetails.last_name,
        middleName: this.providerDetails.middle_name,
        shortName: this.providerDetails.short_name,
        prefixName: this.providerDetails.prefix_name ? this.providerDetails.prefix_name : this.providerDetails.title,
        streetLine1: this.providerDetails.address.street_line_1,
        streetLine2: this.providerDetails.address.street_line_2,
        city: this.providerDetails.address.city,
        state: this.providerDetails.address.state,
        zipcode: this.providerDetails.address.zip,
        enabled: this.providerDetails.enabled,
        taxonomyCode: this.providerDetails.taxonomy_code,
        alternativeTaxonomy: this.providerDetails.alt_taxonomy,
        listTypes: this.listTypes,
        extCode: this.providerDetails.external_ids.ext_code,
        extId: this.providerDetails.external_ids.ext_id,
        extName: this.providerDetails.external_ids.ext_name
      };
      if (this.providerObject.zipcode) {
        this.zipCodeMask();
      }
      this.taxonomySearch(this.providerObject.taxonomyCode, 'taxonomy', 'initial');
      this.taxonomySearch(this.providerObject.alternativeTaxonomy, 'alt_taxonomy', 'initial');
    }
    this.SharedPopupService.show(template, {
      animated: true,
      // backdrop: true
      backdrop: 'static'
      // backdrop: false,
    });
  }

  getUser(searchKey) {
    this.practicesService.listPracticeUsers(this.popUpinfo._id, searchKey, 'any', 'true', 10, 0).subscribe(res => {
      if (res.status == 'success') {
        // this.practiceUserEmptyResult = res;
        this.user_array_item = [];
        for (let i in res.results) {
          // if(res.results[i].isInternal != undefined && res.results[i].isInternal == true){
          this.user_array_item[i] = {
            id: res.results[i].user_id,
            text: res.results[i].username
          };
          // }
        }
      }
    });
  }

  searchUser(searchKey) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(_x => {
      this.getUser(searchKey);
    }, 500);
  }

  clearUserArrayItem() {
    this.user_array_item = [];
  }
  removeDuplicatesBy(keyFn, array) {
    let mySet = new Set();
    return array.filter(function (x) {
      let key = keyFn(x), isNew = !mySet.has(key);
      if (isNew) { mySet.add(key); }
      return isNew;
    });
  }
  userSelectedValue(userId) {
    this.userSelectedId = userId;
    this.userSelectedText = this.user_array_item.find(x => x.id == userId).text;
  }

  taxonomySearch(searchKey, searchfrom, flag) {
    if (this.popUpfrom == 'editProvider') {
      this.taxonomy_array_item = (searchfrom == 'taxonomy') ? [] : this.taxonomy_array_item;
      this.alt_taxonomy_array_item = (searchfrom == 'alt_taxonomy') ? [] : this.alt_taxonomy_array_item;

      if (flag == '') {
        clearTimeout(this.taxonomy_timer);
        this.taxonomy_timer = setTimeout(() => {
          this.taxonomyAPICall(searchKey, searchfrom);
        }, 1000);
      } else {
        this.taxonomyAPICall(searchKey, searchfrom);
      }

    } else {
      clearTimeout(this.taxonomy_timer);
      this.taxonomy_timer = setTimeout(() => {
        this.practicesService.searchTaxonomy(searchKey).subscribe(result => {
          this.alt_taxonomy_array_item = [];
          this.taxonomy_array_item = [];
          if (result.status == 'success') {
            for (let i in result.results) {
              if (this.taxonomySelectedCode != result.results[i].Code) {
                this.alt_taxonomy_array_item[i] = {
                  id: result.results[i].Code,
                  text: result.results[i].Code
                };
              }
              if (this.altTaxonomySelectedCode != result.results[i].Code) {
                this.taxonomy_array_item[i] = {
                  id: result.results[i].Code,
                  text: result.results[i].Code
                };
              }
            }
          }
        });
      }, 1000);
    }
  }

  taxonomyAPICall(searchKey, searchfrom) {
    this.practicesService.searchTaxonomy(searchKey).subscribe(result => {
      if (result.status == 'success') {
        for (let i in result.results) {
          if (searchfrom == 'taxonomy') {
            if (this.altTaxonomySelectedCode != result.results[i].Code) {
              this.taxonomy_array_item[i] = {
                id: result.results[i].Code,
                text: result.results[i].Code
              };
            }
          } else if (searchfrom == 'alt_taxonomy') {
            if (this.taxonomySelectedCode != result.results[i].Code) {
              this.alt_taxonomy_array_item[i] = {
                id: result.results[i].Code,
                text: result.results[i].Code
              };
            }
          }
        }
      }
    });
  }

  taxonomySelectedValue(taxonomyCode) {
    let existing_alttaxomomy_index = this.alt_taxonomy_array_item.map(function (o) { return o.id; }).indexOf(taxonomyCode);
    if (existing_alttaxomomy_index > -1) {
      delete this.alt_taxonomy_array_item[existing_alttaxomomy_index];
    }
    this.taxonomySelectedCode = taxonomyCode;
    this.providerObject.taxonomyCode = taxonomyCode;
  }

  altTaxonomySelectedValue(taxonomyCode) {
    let existing_taxomomy_index = this.taxonomy_array_item.map(function (o) { return o.id; }).indexOf(taxonomyCode);
    if (existing_taxomomy_index > -1) {
      delete this.taxonomy_array_item[existing_taxomomy_index];
    }
    this.altTaxonomySelectedCode = taxonomyCode;
    this.providerObject.alternativeTaxonomy = taxonomyCode;
  }

  addEditProvider(model: ProviderObject, isValid: boolean, mynform: NgForm) {
    if (model.zipcode) {
      let tempZip = model.zipcode.split('-');
      model.zipcode = tempZip.join('');
    }

    this.providerData = {
      practice_id: this.allAccountsList[this.parentIndexValue]._id,
      entity: model.entity,
      npi: model.npi,
      type: model.type,
      ssn: model.ssn,
      first_name: model.firstName,
      middle_name: model.middleName,
      last_name: model.lastName,
      short_name: model.shortName,
      prefix_name: model.prefixName,
      'address': {
        'city': model.city || '',
        'state': model.state || '',
        'zip': model.zipcode || '',
        'street_line_1': model.streetLine1 || '',
        'street_line_2': model.streetLine2 || ''
      },
      enabled: model.enabled,
      taxonomy_code: model.taxonomyCode,
      alt_taxonomy: model.alternativeTaxonomy,
      tax_id: model.ein,
      organization_name: model.organizationName,
      user_id: (model.userId) ? model.userId : '',
      provider_type: 'Normal Provider',
      external_ids: {
        ext_code: model.extCode,
        ext_id: model.extId,
        ext_name: model.extName
      }
    };
    if (isValid == true) {
      if (this.popUpfrom == 'addProvider') {
        this.practicesService.addProvider(this.providerData).subscribe(data => {
          if(data.status=='success'){
            this.contacts = data;
            this.ValidatingNewProvider(this.contacts.status);
            this.validationMessage = this.contacts.message;
            this.validationStatus = this.contacts.status;
            if (this.validationStatus == 'success') {
              this.allAccountsList[this.parentIndexValue].practice_providers.unshift(this.contacts.doc);
              mynform.resetForm();
              this.modalClose();
              this.sharedService.pushToaster('success', 'Success', 'New provider created successfully.');
            } else {
              this.sharedService.pushToaster('error', 'Error', this.contacts.response);
            }
          }else{
            this.sharedService.pushToaster('error', 'Error', data.status);
          }
          
        },
          err => this.logError(err),
        );
      }

      if (this.popUpfrom == 'editProvider') {
        // tslint:disable-next-line: no-unused-expression
        this.sameAsPractice == false;
        if (this.providerData.ssn) {
          this.providerData.ssn = (this.providerData.ssn).replace(/-/gi, '');
        }
        this.providerData.providerId = this.allAccountsList[this.parentIndexValue].practice_providers[this.childIndexValue]._id;
        this.practicesService.updateProvider(this.providerData).subscribe(data => {
          data = this.removeNullEntries(data);
          this.contacts = data;
          this.ValidatingNewProvider(this.contacts.status);
          this.practiceAccountEditStatus = this.contacts.status;
          this.validationMessage = this.contacts.message;
          if (this.practiceAccountEditStatus == 'success') {
            let obj = this.allAccountsList[this.parentIndexValue].practice_providers[this.childIndexValue];
            obj.practice_id = model.practiceId;
            obj.providerId = model.providerId;
            obj.entity = model.entity;
            obj.npi = model.npi;
            obj.type = model.type;
            obj.ssn = model.ssn;
            obj.first_name = model.firstName;
            obj.middle_name = model.middleName;
            obj.last_name = model.lastName;
            obj.short_name = model.shortName;
            obj.prefix_name = model.prefixName;
            obj.address.street_line_1 = model.streetLine1;
            obj.address.street_line_2 = model.streetLine2;
            obj.address.city = model.city;
            obj.address.state = model.state;
            obj.address.zip = model.zipcode.toString();
            obj.taxonomy_code = model.taxonomyCode;
            obj.alt_taxonomy = model.alternativeTaxonomy;
            obj.tax_id = model.ein;
            obj.organization_name = model.organizationName;
            obj.user_id = model.userId;
            obj.external_ids.ext_code = model.extCode;
            obj.external_ids.ext_id = model.extId;
            obj.external_ids.ext_name = model.extName;
            mynform.resetForm();
            this.modalClose();
            this.sharedService.pushToaster('success', 'Success', 'Provider details updated successfully.');
          } else {
            this.sharedService.pushToaster('error', 'Error', this.contacts.response);
          }
        },
          err => this.logError(err)
        );
      }
    }
  }

  ValidatingNewProvider(response) {
    if (response == 'Provider Already Exists') {
      this.successflag = false;
      this.alreadyExistFlag = true;
    } else if (response == 'success') {
      this.alreadyExistFlag = false;
      this.successflag = true;
      this.timer = setInterval(() => {
        this.successflag = false;
        clearInterval(this.timer);
      }, 1000);
    }
  }

  /* End - Add & Edit Providers */

  /* Start - Provider-Facility mapping */

  // tslint:disable-next-line: no-shadowed-variable
  openProviderFacilityMappingModal(template: TemplateRef<any>, from, infoData, parentIndex, childIndex) {
    this.popUpfrom = from;
    this.parentIndexValue = parentIndex;
    this.childIndexValue = childIndex;
    this.popUpinfo = infoData;
    this.groupedFacilities = [];
    if (from == 'mapFacilitiesToProvider') {
      this.existingMappedFacilities = [];
      this.practiceFacilitiesData = [];

      /*
      this.practicesService.fetchEnabledFacilitesForProvider(this.popUpinfo.practice_id, this.popUpinfo._id, '', false).subscribe(result => {
        if (result.status == 'success') {
          console.log('vv')
          this.existingMappedFacilities = result.results;
        }
      },
        _err => 'error',
        () => {
          this.practicesService.listSearchFacilities(this.popUpinfo.practice_id, '').subscribe(result => {
            if (result.status == 'success') {
              console.log('vcv')
              this.practiceFacilitiesData = result.results;
              this.groupFacilities();
              this.defaultSelectionProviderFacilities();
              this.existRecordFound('mapFacilitiesToProvider');
            }
          });
        });
        */
      this.mapFacilitiesToProviderV2();
    }

    if (from == 'mapProviderToFacilities') {
      this.existingMappedFacilities = [];
      this.practiceFacilitiesData = [];

      // this.practicesService.fetchEnabledProvidersForFacility(this.popUpinfo.practice_id, this.popUpinfo._id, '', false).subscribe(result => {
      //   if (result.status == 'success') {
      //     this.existingMappedFacilities = result.results;
      //   }
      // },
      //   _err => 'error',
      //   () => {
      //     this.practicesService.searchProvider(this.popUpinfo.practice_id, '').subscribe(result => {
      //       if (result.status == 'success') {
      //         this.practiceFacilitiesData = result.results;
      //         this.groupProviders();
      //         this.defaultSelectionFacilitieProviders();
      //         this.existRecordFound('mapProviderToFacilities');
      //       }
      //     });
      //   });
      this.mapProviderToFacilitiesv2();
    }
    this.searchKeyWordFacility = '';
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }

  mapFacilitiesToProviderV2() {
    this.loaderIndicatorprovider = true;
    this.flagIndicatorprovider = false;
    this.existingMappedFacilities=[];
    this.practiceFacilitiesData=[];
    this.groupedFacilities = [];
    
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
    this.practicesService.fetchEnabledFacilitesForProviderV2(this.popUpinfo.practice_id, this.popUpinfo._id, this.defaultLimit, this.page,this.searchKeyWordFacility).subscribe(result => {
      if (result.status == 'success') {
        
        if(result.results.length > 0 ){
          this.existingMappedFacilities = result.results;
          this.practiceFacilitiesData = result.results;
          this.loaderIndicatorprovider = false;
          this.practiceFacilitiesData = result.results;
          this.totalCount = result['totalCount'];
          this.groupFacilities();
          this.defaultSelectionProviderFacilities();

        }else{
          this.groupedFacilities=[];
          this.flagIndicatorprovider = true;
          this.loaderIndicatorprovider = false;
        }
        //this.existRecordFound('mapFacilitiesToProvider');
      }
    });},500);
  }

  mapProviderToFacilitiesv2() {
    this.existingMappedFacilities=[];
    this.practiceFacilitiesData=[];
    this.groupedFacilities = [];
    this.loaderIndicatorprovider = true;
    this.flagIndicatorprovider = false;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
    this.practicesService.fetchEnabledProviderForFacilitesV2(this.popUpinfo.practice_id,this.popUpinfo._id, this.defaultLimit, this.page).subscribe(result => {
      if (result.status == 'success') {
        if(result.results.length > 0 ){
          this.loaderIndicatorprovider = false;
          this.flagIndicatorprovider = false;
          this.existingMappedFacilities = result.results;
          this.practiceFacilitiesData = result.results;
          this.totalCount = result['totalCount'];
          this.groupProviders();
          this.defaultSelectionFacilitieProviders();
        }else{
          this.groupedFacilities=[];
          this.flagIndicatorprovider = true;
          this.loaderIndicatorprovider = false;
        }
        
        //this.existRecordFound('mapProviderToFacilities');
      }
    })},500);
  }

  existRecordFound(check: string) {
    //if (this.searchKeyWordFacility != '') {
      if (check == 'mapProviderToFacilities') {
        this.existingMappedFacilities = [];
        this.practiceFacilitiesData = [];
        this.groupedFacilities = [];
        this.flagIndicatorprovider = false;
        this.loaderIndicatorprovider = true;
        this.currentPage = 1;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(x => {
        this.practicesService.fetchEnabledProviderForFacilitesV2(this.popUpinfo.practice_id, this.popUpinfo._id, this.defaultLimit, this.page,this.searchKeyWordFacility).subscribe(result => {
          if (result.status == 'success') {
            if(result.results.length > 0 ){
              this.loaderIndicatorprovider = false;
              this.flagIndicatorprovider = false;
              this.existingMappedFacilities = result.results;
              this.practiceFacilitiesData = result.results;
              this.totalCount = result['totalCount'];
              this.groupProviders();
              this.defaultSelectionProviderFacilities();
            }else{
              this.groupedFacilities=[];
              this.loaderIndicatorprovider = false;
              this.flagIndicatorprovider = true;             
            }
            
            //this.existRecordFound('mapFacilitiesToProvider');
          }
        })},500);
        // let find = this.practiceFacilitiesData.filter(provider => {
        //   if (provider.type == 'Individual' && provider.first_name == '' && provider.middle_name == '' && provider.last_name == '' && (provider.short_name && provider.short_name.toLowerCase().includes(this.searchKeyWordFacility.toLowerCase()))) {
        //     return provider;
        //   } else if (provider.type == 'Individual' && (
        //     (provider.middle_name && provider.middle_name.toLowerCase().includes(this.searchKeyWordFacility.toLowerCase())) ||
        //     (provider.first_name && provider.first_name.toLowerCase().includes(this.searchKeyWordFacility.toLowerCase())) ||
        //     (provider.last_name && provider.last_name.toLowerCase().includes(this.searchKeyWordFacility.toLowerCase())))) {
        //     return provider;
        //   } else if (provider.type == 'Organization' && (provider.organization_name && provider.organization_name.toLowerCase().includes(this.searchKeyWordFacility.toLowerCase()))) {
        //     return provider;
        //   } else if (provider.npi && provider.npi.toString().toLowerCase().includes(this.searchKeyWordFacility.toLowerCase())) {
        //     return provider;
        //   }
        // });
        // this.flagIndicator = (find.length > 0) ? false : true;
      }
      if (check == 'mapFacilitiesToProvider') {
        this.existingMappedFacilities = [];
        this.practiceFacilitiesData = [];
        this.groupedFacilities = [];
        this.flagIndicatorprovider = false;
        this.loaderIndicatorprovider = true;
        this.currentPage = 1;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(x => {
        this.practicesService.fetchEnabledFacilitesForProviderV2(this.popUpinfo.practice_id, this.popUpinfo._id, this.defaultLimit, this.page,this.searchKeyWordFacility).subscribe(result => {
          if (result.status == 'success') {     
            if(result.results.length > 0 ){
              this.loaderIndicatorprovider = false;
              this.existingMappedFacilities = result.results;
              this.practiceFacilitiesData = result.results;
              this.totalCount = result['totalCount'];
              this.groupFacilities();
              this.defaultSelectionProviderFacilities();
            }else{
              this.groupedFacilities=[];
              this.flagIndicatorprovider = true;
              this.loaderIndicatorprovider = false;             
            }
            
            //this.existRecordFound('mapFacilitiesToProvider');
          }
        })},500);
        // let find = this.practiceFacilitiesData.filter(facility => {
        //   if ((facility.short_name && facility.short_name.toLowerCase().includes(this.searchKeyWordFacility.toLowerCase())) ||
        //     (facility.name && facility.name.toLowerCase().includes(this.searchKeyWordFacility.toLowerCase())) ||
        //     (facility.npi && facility.npi.toString().toLowerCase().includes(this.searchKeyWordFacility.toLowerCase()))) {
        //     return facility;
        //   }
        // });
        // this.flagIndicator = (find.length > 0) ? false : true;
      }
    //}
  }

  searchTaskKeyFacilities(facilities = []) {
    //console.log("test",this.searchKeyWordFacility)
    // let find = facilities.filter(facility => {
    //   if ((facility.short_name && facility.short_name.toLowerCase().includes(this.searchKeyWordFacility.toLowerCase())) ||
    //     (facility.name && facility.name.toLowerCase().includes(this.searchKeyWordFacility.toLowerCase())) ||
    //     (facility.npi && facility.npi.toString().toLowerCase().includes(this.searchKeyWordFacility.toLowerCase()))) {
    //     return facility;
    //   }
    // });
    // return (find.length > 0) ? true : false;
  }

  groupFacilities() {
    this.groupedFacilities = [];
    let groupedFacilities: any = {};
    this.practiceFacilitiesData.forEach(element => {
      let c = this.existingMappedFacilities.find(x => x._id == element._id);
      if (!groupedFacilities[element.place_of_service]) {
        groupedFacilities[element.place_of_service] = {
          place_of_service_name: element.place_of_service_name,
          facilities: []
        };
      }
      if (c == undefined || c.enabled == true) {
        element.enabled = true;
      } else {
        element.enabled = false;
      }
      groupedFacilities[element.place_of_service].facilities.push(element);
    });
    let temp = [];
    for (let tmp in groupedFacilities) {

      temp.push(groupedFacilities[tmp]);

    }
    this.groupedFacilities = temp;
  }
  defaultSelectionProviderFacilities() {
    this.practiceFacilitiesData.forEach(element => {
      let flagResult = this.existingMappedFacilities.find(tmp => element._id == tmp._id);
      if (flagResult == undefined) {
        element.enabled = false;
      } else if (flagResult.enabled == true) {
        element.enabled = true;
      } else if (flagResult.enabled == false) {
        element.enabled = false;
      }
    });
  }


  groupProviders() {
    let groupedFacilities: any = {};
    this.practiceFacilitiesData.forEach(element => {
      let c = this.existingMappedFacilities.find(x => x._id == element._id);
      if (!groupedFacilities[element.last_name + element.middle_name + element.first_name]) {
        groupedFacilities[element.last_name + element.middle_name + element.first_name] = {
          place_of_service_name: element.last_name + element.middle_name + element.first_name,
          facilities: []
        };
      }

      if (c == undefined || c.enabled == true) {
        element.enabled = true;
      } else {
        element.enabled = false;
      }
      groupedFacilities[element.last_name + element.middle_name + element.first_name].facilities.push(element);
    });
    let temp = [];
    for (let tmp in groupedFacilities) {
      temp.push(groupedFacilities[tmp]);
    }
    this.groupedFacilities = temp;
  }

  defaultSelectionFacilitieProviders() {
    this.practiceFacilitiesData.forEach(element => {
      let flagResult = this.existingMappedFacilities.find(tmp => element._id == tmp._id);
      if (flagResult == undefined || flagResult.enabled == true) {
        element.enabled = true;
      } else if (flagResult.enabled == false) {
        element.enabled = false;
      }
    });
  }

  mapNewFacilityToProvider(x: String) {
    if (x == 'mapFacilitiesToProvider') {
      let mappedData = JSON.parse(JSON.stringify(this.groupedFacilities));
      let finalmappedData = [];
      mappedData.forEach(element => {
        element.facilities.forEach(elem => {
          let selectedFacility = {
            'practice_id': this.popUpinfo.practice_id,
            'provider_id': this.popUpinfo._id,
            'facility_id': elem._id,
            'enabled': elem.enabled
          };
          finalmappedData.push(selectedFacility);
        });
      });
      this.practicesService.bulkFacilitiesMapToProvider(this.popUpinfo.practice_id, this.popUpinfo._id, finalmappedData).subscribe(_data => {
        this.modalClose();
        this.timeout = setTimeout(_x => {
          this.sharedService.pushToaster('success', 'Success', 'Facilities mapped to provider successfully.');
        }, 1000);
      }, _err => {
        this.modalClose();
        this.sharedService.pushToaster('error', 'Failure', 'Bulk facility mapping failed.');
      });
    }
    if (x == 'mapProviderToFacilities') {
      let mappedData = JSON.parse(JSON.stringify(this.groupedFacilities));
      let finalmappedData = [];
      mappedData.forEach(element => {
        element.facilities.forEach(elem => {
          let selectedFacility = {
            'practice_id': this.popUpinfo.practice_id,
            'facility_id': this.popUpinfo._id,
            'provider_id': elem._id,
            'enabled': elem.enabled
          };
          finalmappedData.push(selectedFacility);
        });
      });
      this.practicesService.bulkProvidersMapToFacility(this.popUpinfo.practice_id, this.popUpinfo._id, finalmappedData).subscribe(_data => {
        this.modalClose();
        this.timeout = setTimeout(_x => {
          this.sharedService.pushToaster('success', 'Success', 'Providers mapped to Facility successfully.');
        }, 1000);
      }, _err => {
        this.modalClose();
        this.sharedService.pushToaster('error', 'Failure', 'Bulk Providers mapping failed.');
      });
    }
  }
  /* End - Provider-Facility mapping */
  mapFacilityToProvider(selectedFacility) {
    if (selectedFacility.enabled) {
      this.practicesService.mapFacilityToProvider(this.popUpinfo.practice_id, this.popUpinfo._id, selectedFacility._id).subscribe(result => {
        if (result.status == 'success') {
          this.sharedService.pushToaster('success', 'Success', 'Facility Mapped Successfully')
        }
        ;
      });
    } else {
      this.practicesService.deleteFacilityToProvider(this.popUpinfo.practice_id, this.popUpinfo._id, selectedFacility._id).subscribe(result => {
        if (result.status == 'success') {
          this.sharedService.pushToaster('success', 'Success', 'Facility Removed Successfully');
        }
      });
    }
  }
  mapProvidersToFacility(selectedProviderz) {
    if (selectedProviderz.enabled) {
      this.practicesService.mapProvidersToFacility(this.popUpinfo.practice_id, this.popUpinfo._id, selectedProviderz._id).subscribe(result => {
        if (result.status == 'success') {
          this.sharedService.pushToaster('success', 'Success', 'Provider Mapped Successfully');
        }
      });
    } else {
      this.practicesService.deleteProvidersToFacility(this.popUpinfo.practice_id, this.popUpinfo._id, selectedProviderz._id).subscribe(result => {
        if (result.status == 'success') {
          this.sharedService.pushToaster('success', 'Success', 'Provider Removed Successfully');
        }
      });
    }
  }
  updateUserTitle() {
    let userData = {
      enabled:  this.popUpinfo.enabled,
      user_title: this.userTitle,
      practice_visibility: this.popUpinfo.practice_visibility,
    };
    if (this.userTitle) {
      this.usersService
      .deleteAccount( this.popUpinfo.user_id,this.popUpinfo.practice_id,  userData).subscribe(result => {
        if (result.status == 'success') {
          this.modalClose();
          this.allAccountsList[this.parentIndexValue].practice_users[this.childIndexValue].user_title = this.userTitle;
          this.timeout = setTimeout(_x => {
            this.sharedService.pushToaster('success', 'Success', 'User title updated successfully');
          }, 1000);
        } else {
          this.sharedService.pushToaster('error', 'Failure', result.response);
        }
      });
    } else {
      this.sharedService.pushToaster('error', 'Failure', 'User title should not be empty');
    }
  }

  practiceConfig(configFrom, booleanValue, practice_id) {
    let options = {
      groupcensus: 'Groupcensus',
      ocr: 'OCR',
      compensation: 'Compensation',
      ps_integration: 'PS integration',
      coding_service: 'Coding Service',
      medical_coding: 'Medical Coding',
    }
    if (options[configFrom]) {
      let param = {
        [configFrom]: booleanValue
      }
      this.practicesService.updatePracticeOptions(practice_id, param).subscribe(_result => {
        if (_result.status == 'success') {
          this.sharedService.pushToaster('success', 'Success', options[configFrom] + ' status updated successfully');

        } else {
          this.sharedService.pushToaster('error', 'Error', _result.message);

        }
      });
    }
  }

  patient360IntegrationPracties(item){   
     let data={
      practice_id:item._id,
      user_name:item.patient_360.userName,
      password:item.patient_360.passcode,
      status:true
    }
    this.practicesService.patient360IntegrationPracties(data).subscribe(result =>{
      if (result.status == 'success') {
        // this.error360Pass=false;
        // this.error360 = false;
        this.errorFlag360 = false;
        this.sharedService.pushToaster('success', 'Success', ' status updated successfully');
      } else {
        this.sharedService.pushToaster('error', 'Error', result.message);
      }
      //this.userName='';
      this.passcode='';
    });
  //   if(this.userName.trim()){
  //     if(this.passcode.trim()){
        
   
  //     }else{
  //       this.error360Pass=true;
  //       this.errorMessage360Pass = 'Password should not be empty';
  //     }
  // } else{
  //   this.error360=true;
  //   this.errorMessage360 = 'Username should not be empty';
  // }
  }

  getPatient360(practice_id,index){
    this.practicesService.getPatient360Details(practice_id).subscribe(result =>{
      if (result.status == 'success') {
        this.allAccountsList[index].patient_360.userName = (result.results) ? result.results.user_name : '';
        this.allAccountsList[index].patient_360.passcode = (result.results) ? result.results.password : '';
      }
    });
  }
  getSigmaCareIds(practice_id,index){
    this.practicesService.getSigmaCareIds(practice_id).subscribe(result =>{
      if(result.status == 'success'){
        this.allAccountsList[index].sigma.partnerid = (result.results) ? result.results.partner_id : '';
        this.allAccountsList[index].sigma.accountid = (result.results) ? result.results.account_id : '';
        this.allAccountsList[index].sigma.vendor_interfaceid = (result.results) ? result.results.vendor_interface_id : '';
        this.allAccountsList[index].sigma.document_type = (result.results) ? result.results.document_type : '';
        this.allAccountsList[index].sigma.document_sub_type = (result.results) ? result.results.document_sub_type : '';
        this.allAccountsList[index].sigma.notes_vender_interface_id = (result.results) ? result.results.notes_vender_interface_id : '';
        this.allAccountsList[index].sigma.enabled = (result.results) ? result.results.enabled : '';
      }
    })
  }
  updateexternalid(status, practice_id) {
    let updateObject = { patient_360_integration: status }
    this.practicesService.updateexternalid(updateObject, practice_id).subscribe(result => {
      if (result.status == 'success') {
        this.sharedService.pushToaster('success', 'Success', ' status updated successfully');
      } else {
        this.sharedService.pushToaster('error', 'Error', result.message);
      }
    })
  }
  patient_360( parentIndex) {
    let mips_regex = '^[0-9]{4}_[0-9]{1,3}$';
    if(this.mips_360 && this.mips_360.match(mips_regex)){
      let index = this.patient360List[parentIndex].findIndex(x => x === this.mips_360.trim().replace(/\s\s+/g, ' '));
      if (index == -1) {
        this.patient360List[parentIndex].push(this.mips_360.trim().replace(/\s\s+/g, ' '));
        this.errorFlag360 = false;
        this.errorMessage360 = '';
      } else {
        this.errorFlag360 = true;
        this.errorMessage360 = ' Patient360 Mips data already exist';
      }
    } else {
      this.errorFlag360 = true;
      this.errorMessage360 = 'Invalid Patient360 Mips Measures';
    }
    this.mips_360 = '';
  }

  savePatient360(parentIndex, practice_id) {
    if(this.patient360List[parentIndex].length >0){
    let dataObject = {
     // patient_360_integration: this.Patient_360_Integration,
      patient360_mips: this.patient360List[parentIndex]
    };
    this.practicesService.updateexternalid(dataObject, practice_id).subscribe(result => {
      if (result.status == 'success') {
        this.errorFlag360=false;
        this.sharedService.pushToaster('success', 'Success', 'Mips Data Updated Successfully');
      } else {
        this.sharedService.pushToaster('error', 'Error', result.message);
      }
    });
  } else{
    this.errorFlag360=true;
    this.errorMessage360 = 'Please add atleast one data to save';

  }
  }

  removePatient360(parentIndex, currentIndex) {
    if (this.patient360List[parentIndex] && this.patient360List[parentIndex].length == 1) {
      this.sharedService.pushToaster('warning', 'Info', 'Mips should have atleast one value');
    } else {
      this.patient360List[parentIndex].splice(currentIndex, 1);
    }
  }
  
  mipsData(practiceIndex){
    let mips_regex = '^[0-9]{4}_[0-9]{1,3}$';
    if(this.mips_measures && this.mips_measures.match(mips_regex)){
      let index = this.mipsList[practiceIndex].findIndex(x => x === this.mips_measures);
      if(index == -1){   
        this.mipsList[practiceIndex].push(this.mips_measures);
        this.errorFlag = false;
        this.errorMessage = '';
      }else{
        this.errorFlag = true;
        this.errorMessage = 'Mips data already exist';
        this.sharedService.pushToaster('warning', 'Info', 'Mips should have atleast one value');
      }
    }else{
      this.errorFlag = true;
      this.errorMessage = 'Invalid Mips Measures';
    }
    this.mips_measures = '';
  }

  saveMips(parentIndex, practice_id){
    if( this.mipsList[parentIndex].length >0){
    let dataObject = {
      mips_measures : this.mipsList[parentIndex]
    };
    this.practicesService.updateexternalid(dataObject, practice_id).subscribe(result => {
      if (result.status == 'success') {
        this.errorFlag=false;
        this.sharedService.pushToaster('success', 'Success', 'Mips Data Updated Successfully');
      } else {
        this.sharedService.pushToaster('error', 'Error', result.message);
      }
    })
  }else{
    this.errorFlag=true;
    this.errorMessage = 'Please add atleast one data to save';
  }
  }
  
  removeMips(parentIndex, currentIndex){
    if(this.mipsList[parentIndex] && this.mipsList[parentIndex].length == 1){
      this.sharedService.pushToaster('warning', 'Info',  'Mips should have atleast one value');
    }else{
      this.mipsList[parentIndex].splice(currentIndex, 1);
    }
  }

  matrixcareid(data){
    let updateextId={
      "external_id":data.exernalID,
      "OID":data.oid
    }
    this.practicesService.updateExternalIds(data.facility_id,updateextId).subscribe(result => {
      if(result.status == 'success'){
        this.sharedService.pushToaster('success', 'Success', 'MatrixCare updated successfully');
            }  
    });
  }
  progressNotes( status,practice_id){
    let updateObject = { mc_progressnotes: status }
    this.practicesService.mcProgressNote(updateObject,practice_id).subscribe(result => {      
      if(result.status == 'success'){
        this.sharedService.pushToaster('success', 'Success', status ?  'MatrixCare ProgressNotes Activated successfully' : ' MatrixCare ProgressNotes Deactivated successfully');
            }else {
              this.sharedService.pushToaster('error', 'Error', result.message);
            }
    });
  }

  // Sigmacare Notes update
  sigmaProgressNote(status, practice_id) {
    let updatesigmaObj = {sc_progressnotes: status}
    this.practicesService.sigmaProgressNote(updatesigmaObj, practice_id).subscribe(result => {
      if(result.status == 'success') {
        this.sharedService.pushToaster('success', 'Success', status ?  'SigmaCare ProgressNotes Activated successfully' : 'SigmaCare ProgressNotes Deactivated successfully');
      } else { 
        this.sharedService.pushToaster('error', 'Error', result.message);
      }
    });
  }
 
  // save the Sigmacare details
  updateSigmaCare(data) { 
        let updateextId={
      "external_id":data.exernalIDs,
    }
    this.practicesService.updateSigmaExternalid(data.facility_id,updateextId).subscribe(result => { 
      if(result.status == 'success') {
        this.sharedService.pushToaster('success', 'Success', 'SigmaCare updated successfully');
         }  
    });
  }
  updateSigmaCareid(data){
    let updatesigmaids ={
      "facility_id":data.sigma.facility_id,
      "practice_id":data._id,
      "partner_id" :data.sigma.partnerid ,
      "account_id" : data.sigma.accountid ,
      "vendor_interface_id" :data.sigma.vendor_interfaceid ,
      "document_type" : data.sigma.document_type ,
      "document_sub_type" :data.sigma.document_sub_type ,
      "notes_vender_interface_id":data.sigma.notes_vender_interface_id,
      'enabled': (data.sigma.enabled == null || data.sigma.enabled == '') ? false : data.sigma.enabled,
    }
    this.practicesService.updateSigmaCareid(data._id,updatesigmaids).subscribe(result=> {
      if(result.status == 'success'){
        this.sharedService.pushToaster('success', 'Success', 'SigmaCare updated successfully');
      }
    });

  }

  customProcedures(customTemplate: TemplateRef<any>, data, index) {
    this.hasErrorInJson = false;
    this.isValidJson = true;
    this.popupTitle = 'Edit Custom Procedures for';
    this.SharedPopupService.show(customTemplate, {
      animated: true,
      backdrop: 'static'
    });
    this.selectedPracticeId = data._id;
    this.parentIndexValue = index;
    this.selectedPracticeName = data.accountname;
    this.customProcedureJson = JSON.stringify(data.custom_procedures, undefined, 4);
  }

  customProcedureModelClose() {
    this.SharedPopupService.dismiss();
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  saveCustomProcedures() {
    this.hasErrorInJson = false;
    this.isValidJson = true;
    if (this.customProcedureJson != undefined) {
      if (this.customProcedureJson.charAt(0) != '[') {
        this.customProcedureJson = '[' + this.customProcedureJson + ']';
      }
      let customProcedureKeys = ['code', 'place_of_service_code', 'custom_code', 'custom_description'];
      if (this.customProcedureJson.charAt(0) != '"') {
        if (this.isJson(this.customProcedureJson) == true) {
          if (JSON.parse(this.customProcedureJson).length != undefined) {
            JSON.parse(this.customProcedureJson).forEach((element) => {
              let hasAllKeys = customProcedureKeys.every((item) => {
                return element.hasOwnProperty(item);
              });
              if (hasAllKeys == false) {
                this.hasErrorInJson = true;
              }
            });
          } else {
            this.isValidJson = false;
          }

          if (this.hasErrorInJson == false && this.isValidJson == true) {
            let custom_procedures = {
              'custom_procedures': JSON.parse(this.customProcedureJson)
            };
            this.practicesService.updatePracticeOptions(this.selectedPracticeId, custom_procedures).subscribe(_result => {
              this.allAccountsList[this.parentIndexValue].custom_procedures = JSON.parse(this.customProcedureJson);
              this.sharedService.pushToaster('success', 'Success', 'Custom procedures updated successfully');
              this.customProcedureModelClose();
            });
          }

        } else {
          this.isValidJson = false;
        }
      } else {
        this.isValidJson = false;
      }
    } else {
      this.isValidJson = false;
    }
  }

  npiProviderData(npi, sameAsPracticeToggle) {
    if(npi.length==10){
    clearTimeout(this.timeout);
    this.timeout = setTimeout(_x => {
      this.practicesService.npiProviderDataAPI(npi).subscribe(data => {
        if (data.results && data.results.length > 0) {
          if (data.results && data.results.length > 0) {
            let npi_data = data.results[0];
            this.providerObject.firstName = npi_data.basic.first_name;
            this.providerObject.lastName = npi_data.basic.last_name;
            this.providerObject.prefixName = npi_data.basic.name_prefix;

            this.taxonomy_array_item = [];
            this.alt_taxonomy_array_item = [];
            this.providerObject.taxonomyCode = '';
            this.providerObject.alternativeTaxonomy = '';

            npi_data.taxonomies.forEach(element => {
              if (element.primary == true) {
                this.taxonomy_array_item[0] = {
                  id: element.code,
                  text: element.code
                };
                this.providerObject.taxonomyCode = element.code;
              } else if (element.primary == false) {
                this.alt_taxonomy_array_item[0] = {
                  id: element.code,
                  text: element.code
                };
                this.providerObject.alternativeTaxonomy = element.code;
              }
            });

            let npi_address = npi_data.addresses[0];
            if (npi_address) {
              this.getState(null, npi_address.state);
              this.providerObject.streetLine1 = npi_address.address_1;
              this.providerObject.streetLine2 = npi_address.address_2;
              this.providerObject.city = npi_address.city;
              this.providerObject.state = npi_address.state;
              this.providerObject.zipcode = npi_address.postal_code;
            }
          }
        } else {
          if(sameAsPracticeToggle=='')this.sharedService.pushToaster('warning', 'Info', 'Provided NPI doesnt exist in the NPI registry !');
          this.providerObject.firstName = '';
          this.providerObject.lastName = '';
          this.providerObject.prefixName = '';
          this.taxonomySearch('', '', 'initial');
          this.providerObject.streetLine1 = '';
          this.providerObject.streetLine2 = '';
          this.providerObject.city = '';
          this.providerObject.state = '';
          this.providerObject.zipcode = '';
        }
      });
    }, 1100);
  }
  }

  compensationPopup(policyTemplate: TemplateRef<any>, from, i, policy) {
    this.fromPopup = from;
    if (this.fromPopup == 'createpolicy') {
      this.selected_policy = {
        policy_info: []
      };
    } else {
      this.selected_policy = policy;
    }
    this.policy = this.selected_policy;
    let provider_data = this.selected_policy.policy_info;
    let selected_providers = [];
    this.policy_obj = {};
    this.policy.policy_info.forEach(element => {

      let mapped_provider;
      if (element.policy_mapped_provider) {
        // element.effective_provider = element.policy_mapped_provider[0];
        mapped_provider = element.policy_mapped_provider[0];

      } else {
        // element.effective_provider = element.effective_for;
        mapped_provider = element.effective_for;
      }
      this.policy_obj[mapped_provider] = element;
      // if (mapped_provider && !selected_providers.includes(mapped_provider)) {
      //   selected_providers.push(mapped_provider);

      // }
    });
    // console.log('from agr', from, i,  provider_data);
    if (provider_data == '') {
      provider_data = [];
    }
    this.childIndexValue = i;


    // this.editPolicyIndex = j;
    this.providerFullData = provider_data;
    this.editPolicyIndex = 0;
    if (this.fromPopup == 'editpolicy') {
      if (policy) {
        this.policyinfo = JSON.parse(JSON.stringify(policy));
        // console.log(data,this.allAccountsList[this.parentIndexValue].policy_information[0]);
        this.policyData.effective_from = policy.effective_from;
        this.policyData.effective_to = policy.effective_to;
      }


      // loading first policy info
      if (policy.policy_info[0]) {
        this.policyinfo = policy.policy_info[0];
        if (policy.policy_info[0].effective_for) {
          this.policyData.effective_for = policy.policy_info[0].effective_for;

        } else {
          this.policyData.effective_for = policy.policy_info[0].policy_mapped_provider[0];
        }

        // console.log(policy.policy_info[0].policy_mapped_provider[0]);
      }



    } else {
      this.policyData.effective_from = '';
      this.policyData.effective_to = '';
      this.policyinfo = {
        allProviders: false,
        base_compansation: 0,
        base_compensation_enable: false,
        base_duration: 'per month',
        collecttions_enable: false,
        comprate: 1
      };
      this.policyinfo.rvu = [{
        'from': '0',
        'to': '',
        'rate': ''
      }];
      this.resetpolicy();
    }
    // this.selectedproviderList = [];
    // this.unselectedproviderList = [];
    let providerList = this.allAccountsList[this.parentIndexValue].practice_providers;
    providerList.forEach(element => {
      element.provider_name = element.organization_name ? element.organization_name : element.last_name + ', ' + element.first_name;
    });
    this.providerList = providerList;
    // this.allAccountsList[this.parentIndexValue].practice_providers.filter(element => {
    //   if (selected_providers.includes(element._id)) {
    //     this.selectedproviderList.push(element);
    //   } else {
    //     this.unselectedproviderList.push(element);
    //   }
    // });
    this.providerFullData = provider_data;
    // console.log('this.policy', this.policy);
    this.editPolicyFilterProviderList();

    this.SharedPopupService.show(policyTemplate, {
      animated: true,
      backdrop: 'static',
      class: 'modal-xl'
    });
  }


  editPolicyFilterProviderList() {
    // console.log('..........', this.policyinfo);
    let tempFilterData = [];


    if (this.policy && this.policy.estimation_metadata && this.fromPopup == 'editpolicy') {

      // re filling value from meta data
      let grouped_estimation = jutil.group(this.policy.estimation_metadata, '_id');
      const default_meta = {
        rvu: 0,
        rvuPercent: 0,
        revenue: 0,
        revenuePercent: 0

      };

      this.providerList.forEach(tempProvider => {
        let metadata = grouped_estimation[tempProvider._id] ? grouped_estimation[tempProvider._id][0] : default_meta;

        tempFilterData.push({
          '_id': tempProvider._id,
          'provider_name': tempProvider.organization_name ? tempProvider.organization_name : tempProvider.last_name + ', ' + tempProvider.first_name,
          'revenue': metadata.revenue ? metadata.revenue : 0,
          'revenuePercent': metadata.revenuePercent ? metadata.revenuePercent : 0,
          'rvu': metadata.rvu ? metadata.rvu : 0,
          'rvuPercent': metadata.rvuPercent ? metadata.rvuPercent : 0,
          'base_compensation': 0,
          'total_compensation': 0,
          'collection_amount': 0,
          'temp_rvu': 0
        });
      });
    } else {
      tempFilterData = this.providerList.map(x => {
        return {
          '_id': x._id,
          'provider_name': x.organization_name ? x.organization_name : x.last_name + ', ' + x.first_name,
          'revenue': 0,
          'revenuePercent': 0.0,
          'rvu': 0,
          'rvuPercent': 0.0,
          'base_compensation': 0,
          'total_compensation': 0,
          'collection_amount': 0,
          'temp_rvu': 0,
          'tempIndex': 0
        };
      });
    }
    this.comRevenue = tempFilterData;

    // console.log('comRevenue',  this.comRevenue);
    this.onChangeData();
  }

  onChangeData() {
    let allProvIndex;

    let tempArr = [];
    // console.log(this.policy);
    tempArr = this.policy.policy_info.filter(item => item.effective_for != 'All providers');

    let allprovider;
    let allprovider_index;

    this.policy.policy_info.forEach((element, ind) => {
      this.comRevenue.forEach(item => {
        // console.log((element.policy_mapped_provider && element.policy_mapped_provider[0]), element, item._id);

        if (!element.allProviders && element.policy_mapped_provider && element.policy_mapped_provider[0]) {
          if (item._id == element.policy_mapped_provider[0]) {
            item['tempIndex'] = ind;
            item.base_compensation = element.base_compensation_enable ? element.base_compansation : 0;
            let comprate = element.comprate ? element.comprate : 100;
            item['collection_amount'] = (comprate * item['revenue']) / 100;
            item.total_compensation = Number(item.base_compensation) + item['temp_rvu'] + item['collection_amount'];
            item.rvus = element.rvu;
            tempArr.push(element);
            // console.log("inside foreach comrevenue ", item);
          }
        }
        if (element.effective_for == 'All providers') {

          allprovider = element;
          allprovider_index = ind;
        }
      });
    });
    if (allprovider) {
      this.comRevenue.forEach((element1, i) => {
        tempArr.forEach((temp) => {
          if (temp.policy_mapped_provider[0] != element1._id) {

            // console.log(element.rvu)
            if (!element1.rvus) {
              element1.base_compensation = allprovider.base_compensation_enable ? allprovider.base_compansation : 0;
              element1.rvus = allprovider.rvu;
              element1['tempIndex'] = allprovider_index;

            }
          }
        });
      });
    }

    if (this.fromPopup == 'editpolicy') {
      allProvIndex = this.providerList.findIndex(obj => obj.effective_for == this.policyinfo.effective_for);
      allProvIndex = this.comRevenue.findIndex(item => item._id == this.policyinfo.effective_for);
      // this.selectedproviderList[allProvIndex] = this.policyinfo;
    } else {
      allProvIndex = this.comRevenue.findIndex(item => item._id == this.policyinfo.effective_for);
    }
    // console.log(allProvIndex);

    if (this.comRevenue.length > 0 && this.policyinfo.effective_for && this.policyinfo.base_compansation) {

      this.comRevenue.forEach((element, i) => {
        if (element.tempIndex == allProvIndex) {
          element.base_compensation = this.policyinfo.base_compensation_enable ? this.policyinfo.base_compansation : 0;
        }
        if (this.fromPopup != 'editpolicy' && i == allProvIndex) {
          element.base_compensation = this.policyinfo.base_compensation_enable ? this.policyinfo.base_compansation : 0;
        }
      });




      // this.calRvuRevenue('revenue', 'totalRevenue', 'revenuePercent', 'revenuePercentRate');
      // this.calRvuRevenue('rvu', 'totalRvu', 'rvuPercent', 'rvuPercentRate');
      this.calRvu();
    }
  }

  savePolicy() {
    let data = this.policyData;


    this.policy.effective_from = this.policyData.effective_from;
    this.policy.effective_to = this.policyData.effective_to;


    // load current view into policy

    let current_info = JSON.parse(JSON.stringify(this.policyinfo));

    // console.log(current_info);
    // replace existing data from current form
    for (let element_i in this.policy.policy_info) {
      let element = this.policy.policy_info[element_i];
      if (element.policy_mapped_provider && element.policy_mapped_provider[0] == this.policyData.effective_for) {
        //  console.log('replaced by provider');
        this.policy.policy_info[element_i] = current_info;
        //  console.log(element);
        break;
      } else if (element.allProviders == true && this.policyData.effective_for == 'All providers') {
        this.policy.policy_info[element_i] = current_info;
        //  console.log('replaced by all provider');
        break;
      } else {
        //  console.log('no match at ');
      }
    }

    let policy_data = this.policy;
    let is_form_invalid = false;
    policy_data.policy_info.forEach(element => {
      if ((!element.base_compensation_enable || element.base_compensation_enable == false) && (!element.rvu_enable || element.rvu_enable == false)) {
        is_form_invalid = true;
      }
    });

    if (is_form_invalid) {
      this.sharedService.pushToaster('error', '', 'Please enable at least one Base compensation or RVU');
      return;
    }

    policy_data.practice_id = this.allAccountsList[this.parentIndexValue]._id;
    policy_data.enabled = true;
    if (this.policyData.effective_from > this.policyData.effective_to) {
      this.sharedService.pushToaster('error', '', 'Starting date is greater than End Date');
      return;
    }

    let tempeffective_for = this.policyinfo.effective_for;

    let is_date_invalid = false;
    let ranges = [];

    let from = moment(this.policy.effective_from).startOf('month').format('YYYY-MM-DD'); // .format('MM/DD/YYYY');
    let to = moment(this.policy.effective_to).endOf('month').format('YYYY-MM-DD'); // .format('MM/DD/YYYY');

    let months = moment(to).diff(from, 'months', false);
    ranges.push(from);
    for (let i = 1; i <= months; i++) {
      ranges.push(moment(from).add(i, 'months').format('YYYY-MM-DD'));
    }

    for (let tmp of ranges) {
      this.policies.forEach(element => {
        //console.log(element);
        let start = moment(element.effective_from).startOf('month').format('YYYY-MM-DD');
        let end = moment(element.effective_to).endOf('month').format('YYYY-MM-DD');
        //console.log(tmp, start, end);
        if (element._id && element._id == this.policy._id) {
          //console.log('skipping');
        } else {
          if (moment(tmp).isBetween(start, end, 'days', '[]')) {
            is_date_invalid = true;
          }
        }


      });
    }
    console.log(is_date_invalid);
    if (is_date_invalid) {
      this.sharedService.pushToaster('error', '', 'Date overlapping. please choose different date');
      return;
    }

    // this.policyinfo.provider_name = this.mapped_provider_name;
    // if (this.allAccountsList[this.parentIndexValue].policy_information.length) {
    //   var d_record = this.allAccountsList[this.parentIndexValue].policy_information.filter(data => {
    //     return data.enabled == true;
    //   });
    //   if (d_record.length) {
    //     if (this.fromPopup != 'editpolicy') {
    //       this.policyData.policy_info.push(this.policyinfo);
    //     } else {

    //  }
    //     if (d_record[0].effective_from != this.policyData.effective_from) {
    //     }
    //   }
    // } else {
    //   this.policyData.policy_info = [this.policyinfo];
    // }
    // if(this.fromPopup !=  "editpolicy" && this.p_replace != true){

    let tempFormatData = this.comRevenue.map(x => {
      return {
        '_id': x._id,
        'revenue': Number(x.revenue),
        'revenuePercent': Number(x.revenuePercent),
        'rvu': Number(x.rvu),
        'rvuPercent': Number(x.rvuPercent)
      };
    });
    policy_data['estimation_metadata'] = tempFormatData;
    // this.policyData.policy_info = [this.policyinfo];
    // console.log("///////", this.policyData);
    // console.log(policy_data);
    // return;
    this.practicesService.policy_info_create(policy_data).subscribe(resdata => {
      if (resdata.status == 'success') {
        this.policyinfo.effective_for = tempeffective_for;
        // data.effective_from_display = tempeffective_from_display;

        // data.effective_to_display = tempeffective_to_display;
        if (this.allAccountsList[this.parentIndexValue] && this.allAccountsList[this.parentIndexValue].policy_information) {
          if (this.allAccountsList[this.parentIndexValue].policy_information[this.childIndexValue]) {
            this.allAccountsList[this.parentIndexValue].policy_information[this.childIndexValue] = policy_data;
          } else {
            this.allAccountsList[this.parentIndexValue].policy_information.push(policy_data);
          }
        }
        this.onCancelpolicypopup();
        if (this.fromPopup != 'editpolicy') {

          this.sharedService.pushToaster('success', 'creation', 'Policy added successfully');
        } else {

          let policy_index = this.policies.findIndex(ele => ele._id == policy_data._id);
          this.policies[policy_index] = policy_data;


          this.sharedService.pushToaster('success', 'updation', 'Policy updated successfully');
        }
      } else {
        //  this.policyData.policy_info = d_record[0].policy_info;
        if (this.fromPopup != 'editpolicy') {
          this.sharedService.pushToaster('error', 'creation', 'Faild to add policy');
        } else {
          this.sharedService.pushToaster('error', 'Update', 'Faild to update policy');
        }
      }
    }, _err => {
      // this.policyData.policy_info = d_record[0].policy_info;
      if (this.fromPopup != 'editpolicy') {
        this.sharedService.pushToaster('error', 'creation', 'Faild to add policy');
      } else {
        this.sharedService.pushToaster('error', 'update', 'Faild to update policy');
      }

    });




  }
  deactivatePolicy(policy) {
    this.practicesService.deactivate_policy(policy._id).subscribe(resdata => {
      if (resdata.status == 'success') {

        this.sharedService.pushToaster('success', '', 'Policy Deactivated successfully');


        let policy_index = this.policies.findIndex(ele => ele._id == policy._id);
        this.policies.splice(policy_index, 1);

      } else {
        this.sharedService.pushToaster('error', '', 'Policy Deactivation failed');
      }

    });
  }

  changeProviderCompensation(provider) {

    let provider_info;
    let current_info = JSON.parse(JSON.stringify(this.policyinfo));


    // replace existing data from current form

    for (let element_i in this.policy.policy_info) {
      let element = this.policy.policy_info[element_i];
      if (element.policy_mapped_provider && element.policy_mapped_provider[0] == current_info.effective_for) {
        this.policy.policy_info[element_i] = current_info;
        //console.log(element);
        break;

      } else if (element.allProviders == true && current_info.effective_for == 'All providers') {
        this.policy.policy_info[element_i] = current_info;
        break;

      } else {
        // console.log('no match at ', index);
      }
    }

    // fetch data from policy to form

    for (let element of this.policy.policy_info) {

      // this.policy.policy_info.forEach((element, index) => {
      if (element.policy_mapped_provider && element.policy_mapped_provider[0] == provider) {
        provider_info = JSON.parse(JSON.stringify(element));
        provider_info.allProviders = false;
        break;
      } else if (element.allProviders == true && provider == 'All providers') {
        provider_info = JSON.parse(JSON.stringify(element));
        delete provider_info.policy_mapped_provider;
        break;

      } else {
        // console.log('no match at ');
      }
    }

    let existing_info = JSON.parse(JSON.stringify(this.policy.policy_info));
    if (provider_info) {

      this.policyinfo = provider_info;
      if (provider_info.allProviders == true) {
        //console.log('switching to exiting all provider');
      } else {
        //console.log('switching to  provider');

      }

    } else if (provider == 'All providers') {

      //console.log('pushing all provider');

      // console.log('adding all provider');

      // this.policyinfo.effective_provider = 'All providers';
      this.policyinfo.effective_for = 'All providers';
      this.policyinfo.allProviders = true;
      delete this.policyinfo.provider_name;
      delete this.policyinfo.policy_mapped_provider;
      let policyinfo = JSON.parse(JSON.stringify(this.policyinfo));
      existing_info.push(policyinfo);
      // console.log(policy_infos, existing_info);
      this.policy.policy_info = JSON.parse(JSON.stringify(existing_info));
    } else {
      //console.log('new entry for ', provider);
      this.policyinfo.effective_for = provider;
      this.policyinfo.allProviders = false;
      this.policyinfo.policy_mapped_provider = [provider];

      let provider_obj = jutil.group(this.providerList, '_id');

      if (provider_obj[provider]) {
        this.policyinfo.provider_name = provider_obj[provider][0].provider_name;
      }

      let policyinfo = JSON.parse(JSON.stringify(this.policyinfo));
      existing_info.push(policyinfo);
      // console.log(policy_infos, existing_info);
      this.policy.policy_info = JSON.parse(JSON.stringify(existing_info));
    }
  }

  checkExist(data) {
    if (this.preSelectedList.includes(data)) {
      setTimeout(() => {
        this.policyinfo.effective_for = this.revert_effective_for;
        this.sharedService.pushToaster('error', 'create', 'Policy exist with selected provider name');
      }, 500);

    } else {
      if (data == 'All providers') {
        this.mapped_provider_name = 'All providers';
      } else {
        let provierList = this.allAccountsList[this.parentIndexValue].practice_providers;
        let pro_list = provierList.filter(pdata => {
          return pdata._id == data;
        });
        if (pro_list.length) {
          this.mapped_provider_name = pro_list[0].first_name + ' ' + pro_list[0].last_name;
        }
      }
    }
  }
  update_status(p_id, policy_id) {
    this.practicesService.policy_info_update(p_id, policy_id, { enabled: false }).subscribe(resdata => {
      //console.log(resdata, '34');
    });
  }
  insertnextrvurow(i) {
    if ((this.policyinfo.rvu[i + 1] == undefined) && (this.policyinfo.rvu[i].to) && (this.policyinfo.rvu[i].to != '')) {
      if (this.policyinfo.rvu[i].to != '-') {
        this.policyinfo.rvu.push({
          'from': this.policyinfo.rvu[i].to,
          'to': '',
          'rate': ''
        });
      }
    } else {
      if (this.policyinfo.rvu[i + 1]) {
        this.policyinfo.rvu[i + 1].from = this.policyinfo.rvu[i].to;

      }
    }
  }
  onCancelpolicypopup() {
    this.SharedPopupService.dismiss();
  }
  effectivedatechanges(replacecompensation: TemplateRef<any>, data) {
    // this.replacecompensationModelPopup = this.modalService.show(replacecompensation, {
    //   animated: true,
    //   backdrop: 'static'
    // });
    // return;
    var date = moment(data, 'YYYY-MM');
    if (date.isValid()) {
      // this.policyinfo.effective_to = moment(date).endOf('month').format("YYYY-MM");
      //console.log(this.allAccountsList[this.parentIndexValue].policy_information, 'demodemodemodemo');
      if (this.allAccountsList[this.parentIndexValue].policy_information && this.allAccountsList[this.parentIndexValue].policy_information.length > 0) {
        this.allAccountsList[this.parentIndexValue].policy_information.forEach(element => {
          let compareDate = date;
          let startDate = element.effective_from;
          let endDate = element.effective_to;
          if (moment(compareDate).isSameOrBefore(endDate) && moment(compareDate).isSameOrAfter(startDate)) {
            this.SharedPopupService.show(replacecompensation, {
              animated: true,
              backdrop: 'static'
            });
            return;
          }
        });
      }
    }
  }
  get_policy_mapped(p_id) {
    this.practicesService.policy_info_get(p_id).subscribe(data => {
      this.preSelectedList = [];
      if (data.status == 'success') {
        if (data.result.length > 0) {
          let provierList = this.allAccountsList[this.parentIndexValue].practice_providers;
          if (provierList == undefined) {
            provierList = [];
          }
          // get provider id and All providers in array  and setting providername
          // data.result[0].policy_info.forEach(element => {
          //   if (element.policy_mapped_provider) {
          //     this.preSelectedList.push(element.policy_mapped_provider[0]);
          //     let pro_list = provierList.filter(pdata => {
          //       return pdata._id == element.policy_mapped_provider[0];
          //     });
          //     // if (pro_list.length) {
          //     //   element.provider_name = pro_list[0].first_name + ' ' + pro_list[0].last_name;
          //     // }
          //   } else if (element.allProviders) {
          //     element.provider_name = 'All providers';
          //     this.preSelectedList.push('All providers');
          //   }
          // });
          this.policies = data.result;
          this.allAccountsList[this.parentIndexValue].policy_information = data.result;
        }
      } else {
        this.allAccountsList[this.parentIndexValue].policy_information = [];
      }
    }, _err => {
      this.sharedService.pushToaster('error', 'Get', 'Faild to Get mapped policy');
    });


  }
  rePolicy() {
    this.resetpolicy();
    this.SharedPopupService.dismiss();
  }
  resetpolicy() {
    this.p_replace = true;
    let provierList = this.allAccountsList[this.parentIndexValue].practice_providers;
    this.unselectedproviderList = provierList;
    this.isaddnewProvider = true;
    this.selectedproviderList = [];
    this.selected_policy = {};
    this.selected_policy.policy_info = [];
    this.policy = this.selected_policy;
  }
  cancelrePolicy() {
    this.p_replace = false;
    this.SharedPopupService.dismiss();
    this.SharedPopupService.dismiss();
  }
  tabselect(param) {
    clearTimeout(this.timeout);
    setTimeout(() => {
      if ((!param.practice_providers) || (param.practice_providers && param.practice_providers.length == 0)) {
        // this.sharedService.pushToaster('error', 'Error message', 'Provider list not available');
      }
    }, 1000);
  }

  practiceinvoice(items, i) {
    this.allAccountsList[i].tabloaderIndicator = true;
    this.parentIndexValue = i;
    this.allAccountsList[i]['type'] = 'invoice';
    this.practice_id = items._id;
    this.practicesService.getPracticeInvoice(items._id).subscribe(data => {
      if (data.data && data.data.length > 0) {
        this.invoicelist = data.data;
        this.allAccountsList[i].invoicelist = this.invoicelist;
      } else {
        this.allAccountsList[i].invoicelist = [];
      }
      this.allAccountsList[i].tabloaderIndicator = false;
    });
  }

  practieInvoiceModel(invoiceTemplate: TemplateRef<any>, from, i, data, j) {
    this.fromPopup = from;
    if (this.fromPopup == 'editinvoice') {
      this.childIndexValue = j;
      this.practiceInvoiceID = data._id;
      this.practice_id = data.practice_id;
      this.effective_month_from = data.effective_month_from;
      this.effective_month_to = data.effective_month_to;
      this.billing_service_fee = data.billing_service_fee;
    } else {
      this.effective_month_from = '';
      this.effective_month_to = '';
      this.billing_service_fee = '';
    }
    this.SharedPopupService.show(invoiceTemplate, {
      animated: true,
      backdrop: 'static'
    });
  }

  checkInvoiceDateRangeIsValid(inputdate, type) {
    this.allAccountsList[this.parentIndexValue].invoicelist.forEach((element, i) => {
      if (this.fromPopup == 'editinvoice') {
        if (i != this.childIndexValue) {
          var date = moment(inputdate, 'YYYY-MM');
          let compareDate = date;
          let startDate = element.effective_month_from;
          let endDate = element.effective_month_to;
          if (moment(compareDate).isSameOrBefore(endDate) && moment(compareDate).isSameOrAfter(startDate)) {
            this.sharedService.pushToaster('error', 'Error', 'Date overlapping. please choose different date');
            setTimeout(() => {
              if (type == 'from') { this.effective_month_from = ''; }
              if (type == 'to') { this.effective_month_to = ''; }
            }, 200);
          }
        }
      } else {
        var date = moment(inputdate, 'YYYY-MM');
        let compareDate = date;
        let startDate = element.effective_month_from;
        let endDate = element.effective_month_to;
        if (moment(compareDate).isSameOrBefore(endDate) && moment(compareDate).isSameOrAfter(startDate)) {
          this.sharedService.pushToaster('error', 'Error', 'Date overlapping. please choose different date');
          setTimeout(() => {
            if (type == 'from') { this.effective_month_from = ''; }
            if (type == 'to') { this.effective_month_to = ''; }
          }, 200);
        }
      }
    });
  }
  saveInvoice() {
    if (this.effective_month_from && this.effective_month_to && this.billing_service_fee) {
      if (this.fromPopup == 'createinvoice') {
        if (this.effective_month_from > this.effective_month_to) {
          this.sharedService.pushToaster('error', 'Error', 'Effective Month TO Date is lesser than the From date');
        } else {
          let insertObj = {
            'effective_month_from': this.effective_month_from,
            'effective_month_to': this.effective_month_to,
            'billing_service_fee': this.billing_service_fee,
            'practice_id': this.practice_id
          };
          this.practicesService.addPracticeInvoice(insertObj).subscribe(resdata => {
            //console.log(resdata);
            if (resdata.data) {
              this.allAccountsList[this.parentIndexValue].invoicelist.push(resdata.data);
            }
            this.onCancelpolicypopup();
            this.sharedService.pushToaster('success', 'success', 'Invoice inserted successfully');
          });
        }
      } else if (this.fromPopup == 'editinvoice') {
        if (this.effective_month_from > this.effective_month_to) {
          this.sharedService.pushToaster('error', 'Error', 'Effective Month TO Date is lesser than the From date');
        } else {
          let updateObj = {
            'effective_month_from': this.effective_month_from,
            'effective_month_to': this.effective_month_to,
            'billing_service_fee': this.billing_service_fee,
            'practice_id': this.practice_id,
            '_id': this.practiceInvoiceID
          };
          this.practicesService.updatePracticeInvoice(updateObj).subscribe(resdata => {
            if (resdata.status == 'success') {
              this.allAccountsList[this.parentIndexValue].invoicelist[this.childIndexValue] = updateObj; // resdata.data;
            }
            this.onCancelpolicypopup();
            this.sharedService.pushToaster('success', 'success', 'Invoice updated successfully');
          });
        }
      }
    } else {
      this.sharedService.pushToaster('error', 'error', 'Invoice details invalid. Please enter a valid input');
    }
  }

  checkIndex(i) {
    if (i == undefined && this.fromPopup == 'editpolicy') {
      this.revenueOptions['disabled'] = true; // Object.assign({}, this.revenueOptions, {disabled: true});
      this.rvuOptions['disabled'] = true; // = Object.assign({}, this.rvuOptions, {disabled: true});
      return true;
    } else {
      this.revenueOptions['disabled'] = false; // Object.assign({}, this.revenueOptions, {disabled: true});
      this.rvuOptions['disabled'] = false; // = Object.assign({}, this.rvuOptions, {disabled: false});
      return false;
    }
  }
  //  this.calRvuRevenue('revenue', 'totalRevenue', '', '', 'revenuePercent', 'revenuePercentRate');
  //  this.calRvuRevenue('rvu', 'totalRvu', '', 'rvuChange', 'rvuPercent', 'rvuPercentRate');
  // calRvuRevenue(objname, total,  objname1, total1) {
  //   console.log(objname, total,  objname1, total1);
  //   let tempData = this.comRevenue;
  //   const convertNum = tempData.map(function (v) {
  //     let tempDec = parseFloat(v[objname]);
  //     return tempDec ? tempDec : 0.0;
  //   });
  //   this[total] = convertNum.reduce((a, b) => a + b, 0);
  //   let grouped_providers = jutil.group(this.policy.policy_info, 'effective_for');
  //   this.comRevenue.forEach(element => {
  //     let changeRevenue = (element[objname] * 100) / this[total];
  //     element[objname] = (element[objname] == NaN || element[objname] == '') ? 0 : element[objname];
  //     element[objname1] = (Number(element[objname]) == 0 || Number(element[objname]) == NaN) ? 0 : changeRevenue.toFixed(2);
  //     console.log(objname1, element[objname1]);
  //     if (grouped_providers[element._id]) {
  //       let comprate = grouped_providers[element._id][0].comprate ? grouped_providers[element._id][0].comprate : 100;
  //       element['collection_amount'] = element['revenue'] ? (comprate * element['revenue']) / 100 : 0;
  //     } else {
  //       element['collection_amount'] = element['revenue'];
  //     }
  //     // if (this.selectedproviderList[element.tempIndex].base_compensation_enable && element['rvu'] != 0) {
  //     //   let comprate = this.selectedproviderList[element.tempIndex].comprate ? this.selectedproviderList[element.tempIndex].comprate : 100;
  //     //   element['collection_amount'] = (comprate * element['revenue']) / 100;
  //     // } else {
  //     //   element['collection_amount'] = 0;
  //     // }
  //     let rvu_cutoffs = Number(element['rvu']);
  //     element['temp_rvu'] = 0;
  //     if (element.rvus && element.rvus.length > 0) {
  //       for (let temp_rvu of element.rvus) {
  //         if (rvu_cutoffs > (Number(temp_rvu.to) - Number(temp_rvu.from)) && temp_rvu.to != '-') {
  //           rvu_cutoffs = rvu_cutoffs - (Number(temp_rvu.to) - Number(temp_rvu.from));
  //           element['temp_rvu'] = element['temp_rvu'] + (Number(temp_rvu.to) - Number(temp_rvu.from)) * Number(temp_rvu.rate);
  //         } else {
  //           element['temp_rvu'] = element['temp_rvu'] + Number(temp_rvu.rate) * rvu_cutoffs;
  //           rvu_cutoffs = 0;
  //           break;
  //         }
  //       }
  //     }


  //     //   if(rvu_cutoffs > (tmp_rvu.to-tmp_rvu.from) && tmp_rvu.to != ""){
  //     //     rvu_cutoffs = rvu_cutoffs-(tmp_rvu.to-tmp_rvu.from);
  //     //     rvu_tier = {
  //     //         "compensation" : (tmp_rvu.to-tmp_rvu.from) * tmp_rvu.rate
  //     //     };
  //     // }else{
  //     //     rvu_tier = {
  //     //         "compensation" : rvu_cutoffs * tmp_rvu.rate
  //     //     };
  //     //     rvu_cutoffs = 0;
  //     // }
  //     element.total_compensation = Number(element.base_compensation) + Number(element['temp_rvu']) + Number(element['collection_amount']);
  //   });
  //   let tempData1 = this.comRevenue;
  //   const convertNum1 = tempData1.map(function (v) {
  //     return v[objname1] ? parseFloat(v[objname1]) : 0.0;
  //   });
  //   this[total1] = convertNum1.reduce((a, b) => a + b, 0);
  //   console.log(this.comRevenue,this[total]);

  // }
  calRvu() {
    let tempData = this.comRevenue;
    this.totalRvu = 0;
    this.totalRevenue = 0;

    tempData.forEach(v => {
      let temprvu = parseFloat(v.rvu + '');
      let temprrevenue = parseFloat(v.revenue + '');
      // return tempDec ? tempDec : 0.0;
      this.totalRvu = this.totalRvu + (temprvu ? temprvu : 0.0);
      this.totalRevenue = this.totalRevenue + (temprrevenue ? temprrevenue : 0.0);
    });

    let grouped_providers = jutil.group(this.policy.policy_info, 'effective_for');
    this.comRevenue.forEach(element => {
      let changeRvu = (element.rvu * 100) / this.totalRvu;
      let changeRevenue = (element.revenue * 100) / this.totalRevenue;
      element.rvu = (element.rvu == NaN) ? 0 : element.rvu;
      element.rvuPercent = (Number(element.rvu) == 0 || Number(element.rvu) == NaN) ? 0 : this.parse_float(changeRvu);
      element.revenue = (element.revenue == NaN) ? 0 : element.revenue;
      element.revenuePercent = (Number(element.revenue) == 0 || Number(element.revenue) == NaN) ? 0 : this.parse_float(changeRevenue);

      if (grouped_providers[element._id]) {
        let comprate = grouped_providers[element._id][0].comprate ? grouped_providers[element._id][0].comprate : 100;
        element['collection_amount'] = element['revenue'] ? (comprate * element['revenue']) / 100 : 0;
      } else {
        element['collection_amount'] = element['revenue'];
      }

      let rvu_cutoffs = Number(element['rvu']);
      element['temp_rvu'] = 0;
      if (element.rvus && element.rvus.length > 0) {
        for (let temp_rvu of element.rvus) {
          if (rvu_cutoffs > (Number(temp_rvu.to) - Number(temp_rvu.from)) && temp_rvu.to != '-') {
            rvu_cutoffs = rvu_cutoffs - (Number(temp_rvu.to) - Number(temp_rvu.from));
            element['temp_rvu'] = element['temp_rvu'] + (Number(temp_rvu.to) - Number(temp_rvu.from)) * Number(temp_rvu.rate);
          } else {
            element['temp_rvu'] = element['temp_rvu'] + Number(temp_rvu.rate) * rvu_cutoffs;
            rvu_cutoffs = 0;
            break;
          }
        }
      }
      element.total_compensation = Number(element.base_compensation) + Number(element['temp_rvu']) + Number(element['collection_amount']);
    });
    let tempData1 = this.comRevenue;

    this.revenuePercentRate = 0;
    this.rvuPercentRate = 0;
    tempData1.forEach(v => {
      let temprvu = parseFloat(v.rvuPercent + '');
      let temprrevenue = parseFloat(v.revenuePercent + '');
      this.rvuPercentRate = this.rvuPercentRate + temprvu ? temprvu : 0.0;
      this.revenuePercentRate = this.revenuePercentRate + temprrevenue ? temprrevenue : 0.0;
    });

  }
  checkRate(objname, totalRate, objname1, totalRevenue, i) {
    // console.log(objname, totalRate, objname1, totalRevenue, i);
    let tempData = this.comRevenue;
    const convertNum = tempData.map(function (v) {
      return v[objname] ? parseInt(v[objname], 10) : 0;
    });
    // console.log(convertNum);
    this[totalRate] = convertNum.reduce((a, b) => a + b, 0);
    this.comRevenue.forEach(element => {
      let changeRevenue = (element[objname] * this[totalRevenue]) / 100;
      element[objname1] = (Number(element[objname1]) == 0 || Number(element[objname1]) == NaN) ? 0 : Math.round(changeRevenue);
    });
  }

  toggleCollapse(target): void {
    if (this.isCollapse[target] != undefined) {
      this.isCollapse[target] = !this.isCollapse[target];
    } else {
      this.isCollapse[target] = true;
    }
  }
  collapsed(event: any): void {
  }
  isCollapsed(target) {
    if (this.isCollapse[target] != undefined) {
      return this.isCollapse[target];
    } else {
      this.isCollapse[target] = true;
      return this.isCollapse[target];
    }
  }
  addnewProviderToggle() {
    this.isaddnewProvider = !this.isaddnewProvider;
  }
  savePolicyPreview() {
    this.practicesService.update_policy_estimation(this.policy._id, this.comRevenue).subscribe(data => {
      if (data.status == 'success') {
        this.sharedService.pushToaster('success', 'creation', 'Estimation saved successfully');
      } else {
        this.sharedService.pushToaster('error', 'Update', 'failed to save');
      }
    });
  }
  parse_float(number) {
    number = parseFloat(number);
    if (!isNaN(number)) {
      return Math.round(number * 100) / 100;
    } else {
      return 0;
    }
  }

  selecteFacility(facility_list,f_id,index){
    this.selected_facility_for_integration = facility_list.filter(facility => facility._id == f_id);
   // console.log(this.selected_facility_for_integration[0],"s_facility");
    if(this.selected_facility_for_integration.length > 0){
      if(this.selected_facility_for_integration[0].external_ids && this.selected_facility_for_integration[0].external_ids.matrix){
        this.allAccountsList[index].matrix.oid=this.selected_facility_for_integration[0].external_ids.matrix.OID
        this.allAccountsList[index].matrix.exernalID=this.selected_facility_for_integration[0].external_ids.matrix.external_id
      }else{
        this.allAccountsList[index].matrix.oid=''
        this.allAccountsList[index].matrix.exernalID=''
      }
    }
    
  }

  selectedFacilities(facility_list,f_id,index){
    this.selected_facility_for_integration = facility_list.filter(facility => facility._id === f_id);
    if(this.selected_facility_for_integration.length > 0){
      if (this.selected_facility_for_integration[0].external_ids && this.selected_facility_for_integration[0].external_ids.sigma) {
        this.allAccountsList[index].sigma.exernalIDs=this.selected_facility_for_integration[0].external_ids.sigma.external_id;
      } else {
        this.allAccountsList[index].sigma.exernalIDs = '';
      }
    }
  }
  openNpiModal(template: TemplateRef<any>) {
    this.SharedPopupService.showNPI(template, {
      animated: true,
      backdrop: 'static',
      class: 'modal-md'
    });
  }
  npiIdProviderData(npi,model: PracticeAccount,flag?) {
    clearTimeout(this.timeout);
    this.practiceFormValue=model;
    if(npi != ''){
      this.timeout = setTimeout(x => {
        this.practicesService.npiProviderDataAPI(npi).subscribe(data => {
          if (data.results && data.results.length > 0) {
            if (data.results && data.results.length > 0) {
              this.npiError = false;
              if(flag!=1){
                this.openNpiModal(this.npiPopup);
              }
                
              let npi_data = data.results[0];
              let npi_address=npi_data.addresses[0];
              this.npi_address = npi_data.addresses[0];
              npi_address=this.npi_address;
              npi_data.taxonomies.forEach(element => {
                if (element.primary == true) {
                  this.taxonomy_array_item[0] = {
                    id: element.code,
                    text: element.code
                  };
                  this.npi_address.taxonomy_code = element.code;
                } else if (element.primary == false) {
                  this.alt_taxonomy_array_item[0] = {
                    id: element.code,
                    text: element.code
                  };
                  this.npi_address.alt_taxonomy = element.code;
                }
              });
            }
          } else {
            this.npiError = true;
          }
        });
      }, 1100);
    }
  }
  validatephoneno(value,flag) {
    try {
      if (value && value.length > 4 && value.length < 18 && value.search(/[a-z]/i) == -1) {
        const phoneNumber = value;
        const regionCode = 'US';
        const number = phoneUtil.parseAndKeepRawInput(phoneNumber, regionCode);
          if(flag==1)
          { 
        this.phoneError = phoneUtil.isValidNumber(number) == false ? true : false;
        if (phoneUtil.getRegionCodeForNumber(number) == 'US') {
       
            this.practiceFormValue.phoneno = '+1' + number.values_[2];
            this.form.patchValue({phoneno:this.practiceFormValue.phoneno})
          }
          else
          {
            this.phoneError = (value.length == 0) ? false : true;
          }
        }
          else if(flag==2)
          {
            this.callbackphoneError = phoneUtil.isValidNumber(number) == false ? true : false;
            if (phoneUtil.getRegionCodeForNumber(number) == 'US') {
           
              this.practiceFormValue.callbackphoneno = '+1' + number.values_[2];
              this.form.patchValue({callbackphoneno:this.practiceFormValue.callbackphoneno})
              }
              else
              {
                this.callbackphoneError = (value.length == 0) ? false : true;
              }
          }
        }
    } catch (e) {
        if(flag==1)
        {
          this.phoneError = true;
        }
        else if(flag==2)
        {
         this.callbackphoneError =true; 
        }
    }
  }
  async onUpdateDetails() {

    await this.getState(null,(this.npi_address) ? this.npi_address.state : '');
    this.practiceFormValue.street1 = this.npi_address.address_1;
    this.practiceFormValue.street2 = this.npi_address.address_2;
    this.practiceFormValue.city = this.npi_address.city;
    setTimeout(() => {
      this.practiceFormValue.state = this.npi_address.state;
      this.form.patchValue({state:this.npi_address.state})
    }, 1)
    
    this.form.patchValue({
      state:this.npi_address.state,
      taxonomy_code:this.npi_address.taxonomy_code,
      alt_taxonomy:this.npi_address.alt_taxonomy||'',
      streetLine1:this.npi_address.address_1, // Assigning a new value to the control
      streetLine2 : this.npi_address.address_2,
      city:this.npi_address.city,
      zipcode:this.npi_address.postal_code
    });
    this.practiceFormValue.zip = this.npi_address.postal_code;
    this.practiceFormValue.taxonomy_code = this.npi_address.taxonomy_code;
    this.practiceFormValue.alt_taxonomy = this.npi_address.alt_taxonomy;
    this.SharedPopupService.dismissNPI();
  }
  onChangeReport(val)
    {
    let formVal=this.form.get(val).value
    if(val=='openAR')
    {
      if(formVal==true)
         this.openARflag=true;
      else {
        this.openARflag=false;
        this.form.get('openArtFormat').setValue('')
         }
     }
    else if(val=='patientInfo') {
      if(formVal==true)
      this.patientInfoflag=true;
      else  {
        this.patientInfoflag=false;   
        this.form.get('paitentFileFormat').setValue('')
       }
    }
    else if(val=='payerInfo') {
      if(formVal==true)
      this.payerInfoflag=true;
       else  {
      this.payerInfoflag=false;
      this.form.get('payerFormat').setValue('')
         }
    }
    else{
      if(formVal==true)
      this.providerInfoflag=true;
   else
   {
     this.providerInfoflag=false;
    this.form.get('providerFormat').setValue('')
   }
    }
  }

  ngOnDestroy(): void {
    this.SharedPopupService.dismiss();
  }
}


