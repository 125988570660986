import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PatientsService } from '../../_services/patients/patients.service';
import constants from '../../constants';

@Component({
  selector: 'app-patient-coverages',
  templateUrl: './patient-coverages.component.html',
  styleUrls: ['./patient-coverages.component.css']
})
export class PatientCoveragesComponent implements OnInit{
  @Input('points_click_care_patient') points_click_care_patient: any;
  @Input('charge_id') charge_id: any;
  @Input('selected_case') selected_case: any;
  @Input('patient') patient: any;
  @Input('mobileView') mobileView: boolean;
  @Input('fromModule') fromModule: any;
  insurances: any[];
  effective_from_date: string;
  insurance_detail: boolean;
  selected_insurance: any;
  coverage_data: any;
  pcc_patient: any;
  pcc_patient_text: any;
  constants: any;
  integration_type: any[];
  selectedint: any;
  constructor(private patientsService: PatientsService) {
    this.constants=constants;
    this.selectedint='POINT CLICKERS'
    this.integration_type = [
      {title:'DR NOW'},
      {title:'POINT CLICKERS'}
    ];
  }

  ngOnInit() {
    //console.log("on init");
  }
  ngOnChanges( changes: SimpleChanges) {
    //console.log("on changes patient coverages");
    this.insurance_detail = false;
    this.selected_insurance = {};
    this.coverage_data = {};
    // this.pcc_patient_text = "";
    this.getCoverages();
  }
  getCoverages() {
    this.pcc_patient = null;
    this.pcc_patient_text = undefined;
    if (this.points_click_care_patient) {
      this.patientsService.getPatientCoverages(this.points_click_care_patient).subscribe(resp => {
        if (resp.status === 'success' && resp.data) {
          this.pcc_patient = resp.data;
          // this.pcc_patient_text = JSON.stringify(this.pcc_patient, null, 2);
          this.coverage_data = resp.data.coverages;
          this.insurances = resp.data.coverages ? resp.data.coverages.payers : [];
          this.effective_from_date = resp.data.coverages ? resp.data.coverages.effectiveFromDateTime : '';
        }
      });
    }
  }
  insuranceDetail(insurance) {
    this.selected_insurance = insurance;
    this.insurance_detail = true;
  }
  backToResults(){
    this.insurance_detail = false;
  }
  showInsurance(){
    if(this.fromModule=='task'){
      this.patientsService.getPccInsurance(this.charge_id).subscribe(data =>{   
        let parseData = JSON.parse(data.data);
        this.insurances = parseData.payers;
             
       })
    }
  }
  selectedintegration(type){ 
    if(type=="POINT CLICKERS"){
      this.getCoverages()
    }else{
      if(type=="DR NOW"){
        let case_info = this.patient.cases.filter(data => data.case_name == this.selected_case);
        console.log(case_info[0].insurances,"case_info.insurances")
        this.insurances = case_info[0].insurances
      }
    }
  }
  showPatientText() {
    this.pcc_patient_text = JSON.stringify(this.pcc_patient, null, 2);
  }
}
