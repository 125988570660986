import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../_services/auth/authentication.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SharedService } from '../../../_services/shared.service';
class ChangePassword {
  newPassword: string;
  confirmNewPassword: string;
}

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  router: Router;
  urlId: any;
  urlToken: any;
  currentUrl: any;
  public changePassword: ChangePassword;
  public tokenResponse: any;
  loginresponse_error: any;
  isValidToken: boolean;
  username_reset:string;
  resetpass1: any;
  brandLogo: string;

  constructor(private authenticationService: AuthenticationService,private routeParams: ActivatedRoute,private sharedService: SharedService,private _router: Router) {
    this.router = _router;
    this.changePassword = {
      newPassword: '',
      confirmNewPassword: ''
    };
    let claimocity = 'claimocity';
    // let Global = 'globalhealthcareresource';
    if(window.location.href.includes(claimocity) == true) {
      this.brandLogo = 'claimocity'
    } else {
      this.brandLogo = 'Global'
    }
   }

  ngOnInit() {
    // console.log(this.router," this.router this.router")
    this.routeParams.params.subscribe(params => {
      //console.log(params,"paramsparamsparams")
      this.urlId = params['id'];
      this.urlToken = params['token'];
      this.currentUrl = this.router.url;
      this.validToken(this.urlId, this.urlToken);
    });
  }
  validToken(uid: string, utoken: string) {
    uid = this.urlId;
    utoken = this.urlToken;
    this.authenticationService.validateToken(uid, utoken).subscribe(data => {
      this.tokenResponse = data;
      if (this.tokenResponse.status == 'success') {
        this.isValidToken = true;
      } else {
        // this.toasterService.pop('success', 'Success', 'User status updated successfully');
        this.isValidToken = false;
      }
    }, error => {
      alert('Something went wrong');
    });
  }
  setPasswordViaEmail(model: ChangePassword, isValid: boolean){
    if (isValid) { 
      let strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');
      let password = model.newPassword;
      if (model.newPassword == model.confirmNewPassword) {
        if (password.match(strongRegex)) { 
          let userData = {
            'id': this.urlId,
            'password': btoa(model.newPassword),
            'skipAuthorization': true
          };
          this.authenticationService.validateToken(this.urlId, this.urlToken).subscribe(data => {
            this.tokenResponse = data;
            if (this.tokenResponse.status == 'success') {
              this.authenticationService.setPassword(userData).subscribe(resdata => { 
                if (resdata.status == 'success') { 
                  this.sharedService.pushToaster('success', 'Success', 'Password changed successfully');
                  setTimeout(() => {
                    this.router.navigate(['/login']);
                  }, 2000);
                }else {
                  this.loginresponse_error = resdata.message;
                }
              });
            }else {
              this.loginresponse_error = 'Token Expired.';
            }
          });
        }
        else {
          this.loginresponse_error = 'Password should be 8 characters with 1 lowercase, 1 uppercase, 1 special character, 1 numeric';
        }
       }else {
        this.loginresponse_error = 'Password does not match. please try again';
      }
    }
  }
  resend_mail(){
    if (this.username_reset) {
      this.authenticationService.resetpassword(this.username_reset).subscribe(data => this.resetpass1 = data,
        err => "error",
        () => this.authenticationService.generateToken(this.resetpass1.dataList).subscribe()
      );
    }
  }
  onCancel() {
    this.router.navigate(['/']);
  }
}
