
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import 'rxjs/Rx';
import * as myGlobals from '../../globals';

const headers = new Headers();
interface resopnce {
  "status": string;
  result: Object[];
}
@Injectable({
  providedIn: 'root'
})

export class TaskService {
  public total_rows = 0;
  public pagelimit = 20;
  public currentTaskData: any;
  public currentTaskChargeInfo: any;
  public currentTaskPatientInfo: any;
  public viewHistory: boolean;
  public accountHistory: boolean;
  public stateList: any = [];
  public viewSessionHistory: boolean;
  imageInfo: any;
  public task_notes: any[];
  public task_cretaed_date: any;
  public task_dos: any;
  public auth_from_date: any;
  public auht_to_date: any;
  public policy_from_date: any;
  public policy_to_date: any;
  public ar_original_bill_date: any;
  public incident_to_providers: any;
  constructor(private http: HttpClient) {
    this.viewHistory = false;
    this.task_notes = [];
    this.incident_to_providers={};
  }

  getFilterResult(statusids, assignedids, accountids, flagVal, tasktypes, providerids,insuranceids, facilityids, dateOfServicerange, dateCreatedRange, dateModifiedRange, page, pagesize, searchKey, extraFilter, type = '', taskids = undefined, chargeAmountFilter, balanceAmountFilter, ageFilter,userRoles,priorityFlag) {
    if (taskids == undefined) {
      taskids = [];
    }
    if (statusids == undefined) {
      statusids = '';
    }
    if (assignedids == undefined) {
      assignedids = '';
    }
    if (accountids == undefined) {
      accountids = '';
    }
    if (tasktypes == undefined) {
      tasktypes = '';
    }
    if (providerids == undefined) {
      providerids = '';
    }
    if (facilityids == undefined) {
      facilityids = '';
    }
    if(insuranceids == undefined) {
      insuranceids = '';
    }
    if (dateOfServicerange == undefined) {
      dateOfServicerange = '';
    }
    if (dateCreatedRange == undefined) {
      dateCreatedRange = '';
    }
    if (dateModifiedRange == undefined) {
      dateModifiedRange = '';
    }
    if (ageFilter == undefined) { ageFilter = ''}
    if (searchKey == undefined) {
      searchKey = '';
    }
    if(userRoles==undefined)
    {
      userRoles="";
    }
    if(priorityFlag==undefined)
    {
      priorityFlag="";
    }
    const req = {
      'status_id': statusids,
      'account_id': accountids,
      'assigned_user_id': assignedids,
      'tasktype_id': tasktypes,
      'flagVal': flagVal,
      'provider_id': providerids,
      'facility_id': facilityids,
      'insurance_id':insuranceids,
      'dateOfServicerange': dateOfServicerange,
      'dateCreatedRange': dateCreatedRange,
      'dateModifiedRange': dateModifiedRange,
      'page': page,
      'pagesize': pagesize,
      'searchKey': searchKey,
      'extrafilter': extraFilter,
      'taskids': taskids,
      "chargeAmountFilter": chargeAmountFilter,
      "balanceAmountFilter": balanceAmountFilter,
      'ageFilter': ageFilter,
      "userRoles":userRoles,
      "priorityFlag":priorityFlag
    };
    if (type === '' || type === undefined) {
      return this.http.post<any>(myGlobals.TaskBase_URL + '/filter', req).pipe(tap(res => {
        const res_data = res;
        // console.log('search resultv is', this.total_rows);
        return res;
      }));
    }
    if (type === 'select_all') {
      return this.http.post<any>(myGlobals.TaskBase_URL + '/filter/totalid', req).pipe(tap(res => {
        const res_data = res;
        // console.log('search resultv is', this.total_rows);
        return res;
      }));
    }
  }

  viewtask(id) {
    return this.http.get<any>(myGlobals.TaskBase_URL + '/' + id);
  }

  incidentToProviders(practice_id){
    return this.http.get<any>(myGlobals.TaskBase_URL + '/practice/' + practice_id);
  }

  listStatus() {
    return this.http.get<any>(myGlobals.Settings_URL + '/taskstatus/statuses');
  }
  listTask() {
    return this.http.get<any>(myGlobals.Settings_URL + '/taskstatus' + '/task_types');
  }
  task_statuses_list(str): any {
    const url = myGlobals.Settings_URL + '/taskstatus?task_status_name=' + str;
    return this.http.get(url);
  }
  task_statuses_all_list(str): any {
    const url = myGlobals.Settings_URL + '/taskstatus';
    return this.http.get(url);
  }
  viewTask() {
    // console.log('task.services for charge data...');
    const url = myGlobals.TaskBase_URL + '/view/5c77a5283fe5ff18dcefc345';
    return this.http.get<any>(url);
  }
  searchDiagnosisCode(searchKey) {
    // console.log('searchcode dia----------------', searchKey);
    return this.http.get<any>(myGlobals.Base_URL + '/diagnosiscodes?' + searchKey);
  }
  searchProcedureCode(searchKey) {
    // console.log('search procedure----------------', searchKey);
    return this.http.get<any>(myGlobals.Base_URL + '/procedurecodes?' + searchKey + '&status=true');
  }

  listStateNames() {
    const url = myGlobals.Base_URL + '/search/states?q=';
    return this.http.get<any>(url);
  }

  listpayers(searchkey, practice_id) {
    const url = myGlobals.Base_URL + '/payers/searchpayers?q=' + searchkey + '&practice_id=' + practice_id;
    return this.http.get<any>(url);
  }

  listpayersGrid(searchkey: string, status: string, loadPage?: any) {
    const url = myGlobals.Base_URL + '/currentpractice/practiceinsurances?q=' + searchkey + '&status=' + status + '&pagesize=' + loadPage.pagesize + '&offset=' + loadPage.offset;
    return this.http.get<any>(url);
  }

  listExternalType() {
    const url = myGlobals.Base_URL + '/currentpractice/practiceinsurances/externaltype';
    return this.http.get<any>(url);
  }

  getConversations(taskid) {
    const order = 'dec';
    const url = myGlobals.TaskBase_URL + '/' + taskid + '/conversation?' + order;
    return this.http.get<any>(url);

  }

  newPatientDemography(taskid, formdata) {
    return this.http.post<any>(myGlobals.TaskBase_URL + '/' + taskid + '/patients/patientdetails', formdata).pipe(tap(res => {
      return res;
    }));
  }
  processPatient(taskid, formdata) {
    return this.http.post<any>(myGlobals.TaskBase_URL + '/' + taskid + '/patients', formdata).pipe(tap(res => {
      return res;
    }));
  }
  processCharge(taskid, formdata) {
    if(typeof formdata.charge.rendering_provider_npi !== "string"){
      formdata.charge.rendering_provider_npi = formdata.charge.rendering_provider_npi.toString();
    }
    return this.http.post<any>(myGlobals.TaskBase_URL + '/' + taskid + '/charge', formdata).pipe(tap(res => {
      return res;
    }));
  }
  addMessage(data, task_id) {
    const new_message = [data];
    const url = myGlobals.TaskBase_URL + '/' + task_id + '/conversation';
    return this.http.post<any>(url, new_message);
  }
  updateStatus(newStatus, taskid, statusName, parentStatus, assigned_user_id, chargeId) {
    const req = { 'newStatus': newStatus, 'taskid': taskid, 'status': statusName, 'parentStatus': parentStatus, 'parent_assigned_userid': assigned_user_id, 'charge_id': chargeId };
    return this.http.post<any>(myGlobals.TaskBase_URL + '/' + taskid + '/setstatus', req);
  }
  searchuser(name,data) {
    return this.http.get(myGlobals.User_URL + '/useropentickets?q=' + name +'&pagesize='+data.pagesize+'&page='+data.page);
  }
  searchuserbyPractice(name,data) {
    return this.http.get(myGlobals.User_URL + '/userinPractice?q=' + name +'&pagesize='+data.pagesize+'&page='+data.page+'&practiceid='+data.practice_id);
  }
  updateUser(active_user, taskid) {
    const req = { 'active_user': active_user };
    return this.http.post<any>(myGlobals.TaskBase_URL + '/' + taskid + '/assign', req);
  }

  taskStatus(taskstatus: any): any {
    return this.http.get(myGlobals.Settings_URL + '/taskstatus/' + taskstatus + '/reason');
  }
  addConversation(data): any {
    const add_conversatoin_taskId = data[0].task_id;
    return this.http.post(myGlobals.TaskBase_URL + '/' + add_conversatoin_taskId + '/conversation', data);
  }
  addReasonCodes(command, isproviderhold): any {
    return this.http.post(myGlobals.TaskBase_URL + '/' + command.task_id + '/comments?isproviderhold=' + isproviderhold, command);
  }
  getCommand(taskid): any {
    const order = 'asc';
    const url = myGlobals.TaskBase_URL + '/' + taskid + '/comments?' + order;
    return this.http.get(url);
  }
  getConver(taskid): any {
    const order = 'asc';
    const url = myGlobals.TaskBase_URL + '/' + taskid + '/conversation?' + order;
    return this.http.get(url);
  }
  /* list attachment */
  attachments(charge_id, task_id, patient_id) {
    // var url = myGlobals.TaskAttachmentList_URL+'?charge_id='+charge_id+'&task_id='+task_id;
    let url = myGlobals.Get_ServiceLayer_Url + '/files/attachments?task_id=' + task_id;
    if (charge_id) {
      url += '&charge_id=' + charge_id;
    }
    if (patient_id) {
      url += '&patient_id=' + patient_id;
    }
    return this.http.get<any>(url);
  }
  deleteattachment(attachment_file_id, taskid) {
    let req = {};
    req = {
      'attachment': attachment_file_id,
      'taskid': taskid
    };
    return this.http.delete<any>(myGlobals.TaskBase_URL + '/' + taskid + '/attachments/' + attachment_file_id);
  }
  fileDataUpdate(file_id, data) {
    const url = myGlobals.TaskBase_URL + '/fileupdate';
    const req = {
      file_id: file_id,
      is_internal: data
    };
    return this.http.put<any>(url, req);
  }
  downloadattachment(attachment_file_id, task_id) {
    return this.http.get(myGlobals.TaskBase_URL + '/' + task_id + '/attachments/' + attachment_file_id, { responseType: 'blob' });
  }
  history_list(chargeId) {
    return this.http.get<any>(myGlobals.charges_URL + '/' + chargeId + '/series');
  }
  patient_history_list(patientId) {
    return this.http.get<any>(myGlobals.patients_URL + '/' + patientId + '/series');
  }
  downloadsingleattachment(file_id) {
    return this.http.get(myGlobals.Get_ServiceLayer_Url + '/files/download/' + file_id, { responseType: 'blob' });
  }
  searchChargeStatuses(type) {
    return this.http.get<any>(myGlobals.globaldata_URL + '/' + type);
  }
  saveChargeStatus(charge_id, status) {
    const url = myGlobals.charges_URL + '/' + charge_id + '/status';
    const body = {
      visit_status: status
    };
    return this.http.put<any>(url, body);
  }

bulkChargeClaimstatus(checklist: any[], newStatus : string) {
  let checklistid = { 'charge_ids': checklist, newStatus : newStatus };
  return this.http.post<any>(myGlobals.charges_URL + '/chargeclaimstatus', checklistid);
}

  filterByValue(array, string) {
    string = (string==null) ? '' : string;
    if (array) {
      return array.filter(o =>
        Object.keys(o).some(k => o[k].toLowerCase().includes(string.toLowerCase())));
    }
  }
  initialStateList() {
    return new Promise<void>((resolve, reject) => {
      if (!this.stateList || this.stateList.length == 0) {
        this.listStateNames().subscribe(result => {
          if (result.status == 'success') {
            this.stateList = [];
            result.results.forEach(element => {
              this.stateList.push({ 'state_code': element.state_code });
            });
            resolve();
          }
        });
      } else {
        resolve();
      }
    });
  }
  viewthumbnail(file_id): any {
    let url = myGlobals.images_URL + '/' + file_id + '?thumbnail=true';
    return this.http.get(url);
  }
  getpdfDocument(file_names): any {
    let url = myGlobals.TaskBase_URL + '/convertPdf/document';
    return this.http.post(url, file_names, { responseType: 'blob' });
  }
  updateFileInfo(data): any {
    let url = myGlobals.TaskBase_URL + '/fileupdate';
    return this.http.put(url, data);
  }

  getSavedPersonalViews() {
    let url = myGlobals.Base_URL + '/currentuser/personalviews';
    return this.http.get(url);
  }

  createPersonalView(data: any): any {
    let url = myGlobals.Base_URL + '/currentuser/personalviews';
    return this.http.post(url, data);
  }

  updatePersonalView(data: any): any {
    let url = myGlobals.Base_URL + '/currentuser/personalviews';
    return this.http.put(url, data);
  }
  user_task_notification(data) {
    let url = myGlobals.Base_URL + '/currentuser/user_notification_option';
    return this.http.put<any>(url, data);
  }
  current_user_info() {
    let url = myGlobals.Base_URL + '/currentuser';
    return this.http.get<any>(url);
  }
  validateChargeInsurance(charge_id: any, instance_id: any) {
    let req = { charge_id: charge_id, id: instance_id };
    return this.http.post<any>(myGlobals.charges_URL + '/validatechargeinstance', req).pipe(tap(res => {
      return res;
    }));
  }
  priorityUpdate(payload: any) {
    return this.http.post<any>(myGlobals.TaskBase_URL + '/' + payload.task_id + '/setpriority', { priority: payload.priority }).pipe(tap(res => {
      return res;
    }));
  }
  setImageViewerInfo(imgData) {
    this.imageInfo = imgData;
  }
  getImageViewerInfo() {
    return this.imageInfo;
  }

  downloadTaskData(id: any, optional: any) {
    let tempData = {
      _id: id,
      optional: optional
    };
    return this.http.post(myGlobals.TaskBase_URL + '/export', tempData, {
      responseType: 'blob'
    });
  }
  updateBulkUserAssign(active_user, taskid) {
    const req = { 'active_user': active_user, 'taskid': taskid };
    return this.http.post<any>(myGlobals.TaskBase_URL + '/assign', req);
  }
  updateBulkStatusAssign(statusname, reassignedstatus, taskid, reasoncodesbulk = [], converData = []) {
    const req = { 'statusname': statusname, 'reassignedstatus': reassignedstatus, 'bulk_taskid': taskid, 'reasoncodesbulk': reasoncodesbulk, 'converData': converData };
    return this.http.post<any>(myGlobals.TaskBase_URL + '/bulksetstatus', req);
  }

  // updateconversationData(conversationid, messagetype) {
  //   const url = myGlobals.Socket_Url + '/conversations/' + conversationid + '/messagetype/' + messagetype;
  //   return this.http.put<any>(url, '');
  // }

  getARTQuestions(statusCode) {
    const url = myGlobals.Get_ServiceLayer_Url + '/tasks/notes?statusCode=' + statusCode;
    return this.http.get<any>(url);
  }

  updateFinalizeNotes(data) {
    const url = myGlobals.Get_ServiceLayer_Url + '/tasks/notes';
    return this.http.post<any>(url, data);
  }

  updateNotes(notesId,data){
    const url = myGlobals.Get_ServiceLayer_Url + '/tasks/notes/'+notesId;
    return this.http.put<resopnce>(url,{notes:data.notes,taskId:data.taskId});
  }

  searchpractice(search) {
    const url = myGlobals.Get_ServiceLayer_Url + '/taskfilter/practice?searchkey=' + search
    return this.http.get<resopnce>(url);
  }
  searchProviders(search) {
    const url = myGlobals.Get_ServiceLayer_Url + '/taskfilter/Providers?searchkey=' + search
    return this.http.get<resopnce>(url);
  }
  searchfacilities(search) {
    const url = myGlobals.Get_ServiceLayer_Url + '/taskfilter/facilities?searchkey=' + search
    return this.http.get<resopnce>(url);
  }
  searchInsuranceNames(search){
    const url = myGlobals.Get_ServiceLayer_Url + '/taskfilter/Insurances?searchkey='+ search
    return this.http.get<resopnce>(url);
  }
  searchtaskstatus(search) {
    const url = myGlobals.Get_ServiceLayer_Url + '/taskfilter/taskstatus?searchkey=' + search
    return this.http.get<resopnce>(url);
  }
  searchTasktypes(search) {
    const url = myGlobals.Get_ServiceLayer_Url + '/taskfilter/Tasktypes?searchkey=' + search
    return this.http.get<Array<Object>>(url);
  }
  getAssignedUserFiler(search) {
    const url = myGlobals.Get_ServiceLayer_Url + '/taskfilter/assigneduser?searchkey=' + search
    return this.http.get<Array<Object>>(url);
  }
  initialtasksearchfilter() {
    const url = myGlobals.Get_ServiceLayer_Url + '/taskfilter/initial'
    return this.http.get<Array<Object>>(url);
  }
}
