import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as myGlobals from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class MueService {

  constructor(private http:HttpClient) { }

  //List of MUECode
  fetchMueCodeList(search_query, status, page=0, limit = 10) {
    return this.http.get<any>(myGlobals.Base_URL + '/muemodifier?q=' + search_query + '&status=' + status + '&page=' + page + '&pagesize=' + limit );
  }

  //
  addMueCode(code) {
    return this.http.post<any>(myGlobals.Base_URL +'/muemodifier' +'/addcode', code)
  }
  //
  addMueModifer(modifier) {
    return this.http.post<any>(myGlobals.Base_URL +'/muemodifier' +'/addmodifier', modifier)
  }

  activateCode(code_id,data) {
    return this.http.put<any>(myGlobals.Base_URL + '/muemodifier/'+ code_id + '/code' , data);
  }

  activateModifiers(code_id, modifier_id, modifier) {
    return this.http.put<any>(myGlobals.Base_URL + '/muemodifier/'+ code_id + '/modifiers/'+ modifier_id, modifier)
  }

 activeandDeactivateCode(code_id, enabled) {
    return this.http.put<any>(myGlobals.Base_URL + '/muemodifier/' + code_id , enabled)
  }

  activeandDeactivateModifiers(code_id,modifier_id, status) {
    return this.http.put<any>(myGlobals.Base_URL +'/muemodifier/'+ code_id + '/modifier/' + modifier_id, status)
  }
}
