import { AfterViewInit, Component, OnInit, ViewEncapsulation, Output, EventEmitter, ChangeDetectorRef, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { HeaderService } from '../../_services/header/header.service';
import { DataHelperService } from './../../_services/auth/data-helper.service';
import { UsersService } from './../../_services/settings/users.service';
import { RouteAuthorizationService } from './../../_services/auth/route-authorization.service';
import { Router, NavigationEnd } from '@angular/router';
import { PracticesService } from './../../_services/settings/practices.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-select-practice',
  templateUrl: './select-practice.component.html',
  styleUrls: ['./select-practice.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class SelectPracticeComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @Output() selectionEmit = new EventEmitter<string>();
  @ViewChild('openmodal', { static: false }) openmodal: ElementRef;
  // practiceList : any[];
  selectedPractice: string;
  practiceData: any;
  defaultValue: any;
  inputValue: any;
  recentPracticeData: any;
  timeout = null;
  // public practiceList: any;
  public filteredPracticeData: any;
  public recentFilteredPractice: any;
  public allMappedPractices: any;
  local_Practice_id: string;
  local_Practice_name: string;
  paginationCounts: number;
  currentPage: number;
  pagelimit: number;
  page: number;
  currentUrl: string;
  loadPagination: boolean;
  userNotAllowed:string;
  constructor(private modalService: NgbModal, public headerService: HeaderService, private routers: Router,
    private dataHelperService: DataHelperService, 
    private usersService: UsersService,
    private practicesService: PracticesService,
    private routeAuthorizationService: RouteAuthorizationService,
    private cdr: ChangeDetectorRef) {
    this.paginationCounts = 0;
    this.currentPage = 1;
    this.pagelimit = 1;
    this.page = 0;
    this.loadPagination = true;
    // this.practiceList = [];
    this.filteredPracticeData = [];
    this.recentFilteredPractice = [];
    this.local_Practice_id = this.dataHelperService.getItem('practice_id');
    this.local_Practice_name = this.dataHelperService.getItem('practice_name');
    // this.routers.events.subscribe(e => {
    //        if (e instanceof NavigationEnd) {
    //          this.currentUrl = e.url;
    //         }
    //       });

    if (this.local_Practice_id) {
      this.inputValue = this.local_Practice_id;
    } else {
      this.getPracticesAccount('', 'fromInitial');
    }
    this.headerService.practiceList = [];
  }
  ngOnInit() {

  }
  ngAfterViewChecked() {

    // your code to update the model
    this.cdr.detectChanges();
 }
  onpageChanged(ev) {
    if (this.loadPagination == true) {
      this.page = ev.page * this.pagelimit - this.pagelimit;
      let tempPractice = this.headerService.practiceList[this.page];
      this.currentPage = this.page + 1;
      this.inputValue = tempPractice.practice_id;
      this.onSelected(tempPractice.practice_id, 'pagination');
    }
  }
  ngAfterViewInit() {
    this.recentPractices('');
    if (this.local_Practice_id) {
      this.defaultValue = this.local_Practice_id;
      if (this.routers.url.indexOf('/task') == -1) {
        // skipping for task module
        this.onSelected(this.local_Practice_id, 0);
      }
    }
  }
  getPracticesAccount(searchkey, from) {
    this.headerService.userPracticeAccount(searchkey, 500).subscribe(data => {
      this.practiceData = data.results;
      if (data.status == 'success') {
        this.headerService.practiceList = [];
        this.headerService.practiceList = data.results.filter(practice =>{
          return practice.enabled;
        });
       // this.headerService.practiceList = data.results;
      }
    }, err => this.logError(err));
  }
  logError(arg0: any): any {
    throw new Error('Error, Method not implementd.');
  }
  expandDropDown(event: any) {
    this.recentPractices('expand');
  }
  searchVal(searchkey) {
    if (searchkey == '') {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.headerService.practiceList = this.recentFilteredPractice;
      }, 2000);
    } else {
      this.getPracticesAccount(searchkey, 'fromSearch');
    }
  }
  onSelected(selectPractice, pageVal) {
    if(this.dataHelperService.getItem('temPrac') == null || this.dataHelperService.getItem('temPrac') && this.dataHelperService.getItem('temPrac') != selectPractice) {
      this.dataHelperService.setItem('temPrac', selectPractice)
    this.loadPagination = pageVal;
    this.selectedPractice = selectPractice;
    let reqData = {
      'practice_id': selectPractice
    };
    let tempcurr = (this.headerService.practiceList.findIndex(obj => obj.practice_id == this.inputValue)) + 1;
    this.currentPage = tempcurr;
    this.headerService.updatePracticetoken(reqData).subscribe(data => {
      if(data.message){
        this.userNotAllowed = data.message;
        let el: HTMLElement = this.openmodal.nativeElement as HTMLElement;
        el.click();

        this.selectedPractice = '';
        selectPractice='';

        setTimeout(() => {
          this.closeModel();
        }, 3000);
      }
      this.usersService.getFeatures().subscribe(features => {
        this.dataHelperService.setItem('features', features);
        this.routeAuthorizationService.filterRoutes();
        localStorage.setItem('authToken', data.result);
        this.headerService.showSelectedPractice(selectPractice);

        this.practicesService.searchAccountById().subscribe(practice => {
          //console.log('x', practice);
        })

        this.dataHelperService.setItem('practice_id', selectPractice);
        
        let practice_name, integrationFlag,source_report_type;

        this.headerService.practiceList.forEach(ele => {
          if (ele.practice_id == this.selectedPractice) {
            practice_name = ele.text;
            if(ele.sourceReportType!=undefined&&ele.sourceReportType!=''&&ele.sourceReportType!=null)
            source_report_type=ele.sourceReportType;
          else{
            source_report_type=null
          }
            integrationFlag = ele.hasOwnProperty('ps_integration') ? ele.ps_integration : false;
          }
        });
        this.dataHelperService.setItem('practice_name', practice_name);
        this.dataHelperService.setItem('source_report_type', source_report_type);
        this.selectionEmit.emit('selection');
        this.loadPagination = true;
        this.headerService.showSelectedPracticeIntegration(integrationFlag);
      });
    });
    this.dataHelperService.setItem('temPrac', selectPractice)
  }
  }

  recentPractices(option) {
  //   if (this.routers.url !== '/dashboard') {
  //   this.headerService.getRecentPractices().subscribe(data => {
  //     this.recentPracticeData = data.results;
  //     if (data.status == 'success') {
  //       this.headerService.practiceList = [];
  //       this.recentPracticeData = _.uniqBy(this.recentPracticeData, 'practice_id');
  //       this.headerService.practiceList = this.recentPracticeData;
  //       if ((option != 'expand' && this.inputValue) || (option == 'expand' && !this.inputValue)) {
  //         this.loadPagination = false;
  //         let tempcurr = (this.headerService.practiceList.findIndex(obj => obj.practice_id == this.inputValue)) + 1;
  //         this.currentPage  = tempcurr;
  //         this.paginationCounts =  this.headerService.practiceList.length;
  //         this.loadPagination = true;
  //       }
  //     }
  //   }, err => this.logError(err));
  // } else {
    this.headerService.userPracticeAccount('', 500).subscribe(data => {
      this.practiceData = data.results;
      if (data.status == 'success') {
        this.headerService.practiceList = [];
        this.headerService.practiceList = data.results.filter(practice =>{
          return practice.enabled;
        });
        if ((option != 'expand' && this.inputValue) || (option == 'expand' && !this.inputValue)) {
          this.loadPagination = false;
          let tempcurr = (this.headerService.practiceList.findIndex(obj => obj.practice_id == this.inputValue)) + 1;
          this.currentPage  = tempcurr;
          this.paginationCounts =  this.headerService.practiceList.length;
          this.loadPagination = true;
        }
      }
    }, err => this.logError(err));
  // }
  }

  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  closeModel() {
    this.modalService.dismissAll();
  }
}
