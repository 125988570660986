import { Component } from '@angular/core';

@Component({
  templateUrl: '400.component.html'
})
export class P400Component {

  constructor() { }

}
