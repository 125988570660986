import { Directive, Input, ElementRef } from '@angular/core';
import { DataHelperService } from '../_services/auth/data-helper.service';

@Directive({
  selector: '[attributeVisibility]'
})
export class AttributeVisibilityDirective {
  @Input('attributeVisibility') permission: any;
  constructor(private el: ElementRef, private dataHelperService: DataHelperService) {
  }
  
  ngOnInit() {
    let userPermissions = this.dataHelperService.getItem('userPermissions');
    if (userPermissions.indexOf(this.permission) == -1) {
      this.el.nativeElement.style.display = 'none';
    }
  }

}
