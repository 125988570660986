import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavparamsService {
  params: any;
  constructor() {
    this.params = {};
  }
  setParams(data) {
    this.params = data;
  }
  setParam(key, data) {
    this.params[key] = data;
  }
  getParams() {
    return this.params;
  }
  getParam(key) {
    return this.params[key];
  }
}
