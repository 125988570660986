import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { TaskService } from '../../_services/task/task.service';

@Component({
  selector: 'app-task-activity',
  templateUrl: './task-activity.component.html',
  styleUrls: ['./task-activity.component.scss']
})
export class TaskActivityComponent implements OnInit {
  selectedFilterValue: string;
  filterAttachmentOptions: string[];
  activities: any[];
  filteredData: any[];

  @Input('activityData') activityData: any;
  @Input('userList') userList: any[];
  @Input('activityLabel') activityLabel: any;
  @Input('filterOption') filterOption: any[];

  constructor(public taskService: TaskService) {
    this.filterAttachmentOptions = ['All events', 'Task activity', 'Charge activity', 'Patient activity'];
    this.selectedFilterValue = 'All events';
    this.activities = [];
    this.filteredData = [];
    this.onChange();
  }

  ngOnInit() {
    this.filteredData = [];
    this.onChange();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.activities = this.activityData.activities;
    this.selectedFilterValue = (this.filterOption && this.filterOption.length > 0) ? this.filterOption[0] : 'All events';
    this.filterAttachmentOptions = (this.filterOption) ? this.filterOption : this.filterAttachmentOptions;
    this.onChange();
  }
  onChange() {
    this.filteredData = [];
    if (this.selectedFilterValue == 'All events') {
      this.filteredData = this.activities;
    } else {
      this.activities.forEach(element => {
        if (element.type == this.selectedFilterValue) {
          this.filteredData.push(element);
        }
      });
    }
    function predicateBy(prop) {
      return function (a, b) {
        if (a[prop] > b[prop]) {
          return 1;
        } else if (a[prop] < b[prop]) {
          return -1;
        }
        return 0;
      };
    }
    if (this.filteredData && this.filteredData.length > 0) {
      this.filteredData.sort(predicateBy('activity_time'));
      this.filteredData.reverse();
    }
  }
}
