import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../../../../_services/shared.service';
import * as _ from 'lodash';
import { FeaturesService } from '../../../../_services/settings/features.service';
import { PracticesService } from '../../../../_services/settings/practices.service';
import { element } from 'protractor';
import { SharedPopupService } from '../../../../_services/popup.service';
import constants from '../../../../constants';

class NewFeature {
  'name': string;
  'description': string;
}

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit, OnDestroy {
  featuresList: any;
  showInActive: boolean;
  searchKey: string;
  timeout = null;
  popupTitle: string;
  selectedFeatureId: string;
  public newFeature: NewFeature;

  selectedFeaturesObject: any;
  parentIndexValue: any;
  childIndexValue: any;
  confirmPopupFrom: any;
  messageInfo: any;
  statusChangeTo: any;
  tmpMappingAccountgroupsData: any[];
  listAllAccountgroups: any;
  allAccountgrouups: any;
  mappedAccountgroups: any;
  seletedTab: any;
  selectedAccountgroupId: any;
  updateAccountgroupStatus: boolean;
  tmpMappingPracticesData: any[];
  mappedPractices: any;
  listAllPractices: any;
  selectedPracticeId: any;
  updatePracticeStatus: boolean;
  searchMappedAccounts: string;
  searchMappedPractice: string;
  defaultLimit: number;
  searchValue: string;
  loaderIndicator:boolean;
  tabloaderIndicator:boolean;
  constants:any;
  constructor(
    public featuresService: FeaturesService,
    public modalService: BsModalService,
    public SharedPopupService: SharedPopupService,
    private sharedService: SharedService,
    private practiceService: PracticesService
  ) {
    this.featuresList = [];
    this.showInActive = false;
    this.searchKey = '';
    this.tmpMappingAccountgroupsData = [];
    this.tmpMappingPracticesData = [];
    this.searchMappedAccounts = '';
    this.searchMappedPractice = '';
    this.defaultLimit = 5;
    this.searchValue = '';
    this.loaderIndicator=true;
    this.tabloaderIndicator=true;
    this.constants=constants;
    this.searchFeatures(this.searchKey, this.showInActive);
  }

  ngOnInit(): void {
  }
  searchFeatures(searchKey, showInActive) {
    this.loaderIndicator=true;
    this.featuresList=[];
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.featuresService.seachFeatures(searchKey, showInActive).subscribe(result => {
        this.featuresList = (result.featuresList && result.featuresList.length > 0) ? result.featuresList : [];
        this.loaderIndicator=false;
        this.featuresList.map(item => {
          item['buttontype'] = '';
        });
      });
    }, 1000);
  }
  showActiveOrInactiveFeatures() {
    this.searchFeatures(this.searchKey, this.showInActive);
  }
  addNewFeatureOpenModel(template: TemplateRef<any>, from) {

    if (from == 'addFeature') {
      this.popupTitle = 'New Feature';
      this.newFeature = {
        'name': '',
        'description': ''
      };
      this.selectedFeatureId = '';
    }
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static'
    });
  }
  editFeatureOpenModel(template: TemplateRef<any>, from, featuresData) {

    if (from == 'editFeatures') {
      this.popupTitle = 'Edit Feature';
      this.newFeature = {
        'name': featuresData.name,
        'description': featuresData.description
      };
      this.selectedFeatureId = featuresData._id;
    }
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static'
    });
  }
  modalClose(closeAction = '') {
    if ((this.confirmPopupFrom == 'accountgroupStatusChange' || this.confirmPopupFrom == 'practiceStatusChange') && closeAction == 'no') {
      if (this.confirmPopupFrom == 'accountgroupStatusChange') {
        this.featuresList[this.parentIndexValue].accountgroups[this.childIndexValue].accgroup.enable = !this.updateAccountgroupStatus;
        this.updateAccountgroupStatus = !this.updateAccountgroupStatus;
      } else if (this.confirmPopupFrom == 'practiceStatusChange') {
        this.featuresList[this.parentIndexValue].allPractices[this.childIndexValue].practiceData.enable = !this.updatePracticeStatus;
        this.updatePracticeStatus = !this.updatePracticeStatus;
      }
    }
    this.SharedPopupService.dismiss();
  }
  saveFeature(model, isValid) {
    if (model.editFeatureData == '') { // Add Feature
      if (isValid == true) {
        this.featuresService.addNewFeature(model).subscribe(result => {
          if (result.status == 'success') {
            this.sharedService.pushToaster('success', 'Success', 'New Feature added successfully');
            this.modalClose();
            this.featuresList.unshift(result.result);
          } else {
            this.sharedService.pushToaster('error', 'Failure', result.message);
          }
        });
      }
    } else {  // Edit Feature
      if (isValid == true) {
        this.featuresService.editFeature(model, model.editFeatureData).subscribe(result => {
          if (result.status == 'success') {
            this.sharedService.pushToaster('success', 'Success', 'Feature updated successfully');
            this.modalClose();
            this.searchFeatures(this.searchKey, this.showInActive);
          } else {
            this.sharedService.pushToaster('error', 'Failure', result.message);
          }
        });
      }
    }
  }
  confirmPopup(confirmPopupName: TemplateRef<any>, status, featuresSelectedData, type, index, cindex) {
    this.selectedFeaturesObject = featuresSelectedData;
    this.parentIndexValue = index;
    this.childIndexValue = cindex;
    this.confirmPopupFrom = type;
    if (type == 'featuresStatusChange') {
      this.messageInfo = (status == true) ? 'Are you sure to deactivate feature ?' : 'Are you sure to activate feature ?';
      this.selectedFeatureId = this.selectedFeaturesObject._id;
      this.statusChangeTo = (status == true) ? false : true;
    } else if (type == 'accountgroupStatusChange') {
      this.messageInfo = (status == true) ? 'Are you sure to deactivate account group ?' : 'Are you sure to activate account group ?';
      this.selectedFeatureId = this.featuresList[index]._id;
      this.selectedAccountgroupId = this.selectedFeaturesObject.accgroup.accgroup_id;
      this.updateAccountgroupStatus = (status == true) ? false : true;
    } else if (type == 'practiceStatusChange') {
      this.messageInfo = (status == true) ? 'Are you sure to deactivate practice ?' : 'Are you sure to activate practice ?';
      this.selectedFeatureId = this.featuresList[index]._id;
      this.selectedPracticeId = this.selectedFeaturesObject.practiceData.practice_id;
      this.updatePracticeStatus = (status == true) ? false : true;
    } else if (type == 'removePractice') {
      this.messageInfo = 'Are you sure to remove practice ?';
      this.selectedFeatureId = this.featuresList[index]._id;
      this.selectedPracticeId = this.selectedFeaturesObject.practiceData.practice_id;
    }
    // else if (type == 'deactivateAllPermissions') {
    //   this.messageInfo = 'Are you sure to Deactivate All Permissions ?';
    //   this.selectedRoleId = this.rolesList[index]._id;
    // } else if (type == 'deactivateAllUsers') {
    //   this.messageInfo = 'Are you sure to Deactivate All Users ?';
    //   this.selectedRoleId = this.rolesList[index]._id;
    // }

    this.SharedPopupService.show(confirmPopupName, {
      animated: true,
      backdrop: 'static'
    });
  }
  onConfirm() {
    if (this.confirmPopupFrom == 'featuresStatusChange') {
      this.featuresStatusChange();
    } else if (this.confirmPopupFrom == 'accountgroupStatusChange') {
      this.accountgroupStatusChange();
    } else if (this.confirmPopupFrom == 'practiceStatusChange') {
      this.practicesStatusChange();
    } else if (this.confirmPopupFrom == 'removePractice') {
      this.removePractice();
    }
    // else if (this.confirmPopupFrom == 'deactivateAllPermissions') {
    //   this.rolesPermissionsStatusChange('all');
    // } else if (this.confirmPopupFrom == 'deactivateAllUsers') {
    //   this.rolesUsersStatusChange('all');
    // }
  }
  featuresStatusChange() {
    this.featuresService.updateFeaturesStatus(this.selectedFeatureId, this.statusChangeTo).subscribe(() => {
      let status = this.statusChangeTo === true ? 'activated' : 'deactivated';
      this.sharedService.pushToaster('success', 'Success', 'Feature status ' + status + ' successfully');
      this.modalClose();
      this.featuresList.splice(this.parentIndexValue, 1);
    });
  }
  actionButton(item) {
    if (item.buttontype == 'Account Group') {
      return 'Setup a Accountgroup';
    } else if (item.buttontype == 'Practice') {
      return 'Setup a Practice';
    }
  }
  mapAccountgroupsModal(template: TemplateRef<any>, from, featuresData, index) {
    this.searchValue = '';
    this.selectedFeatureId = featuresData._id;
    this.selectedFeaturesObject = featuresData;
    this.parentIndexValue = index;

    this.tmpMappingAccountgroupsData = [];
    this.mappedAccountgroups = [];
    let tmpexistingData = _.cloneDeep(featuresData.allAccounts);
    if (tmpexistingData) {
      tmpexistingData.forEach(element => {
        if (element.accgroup.enable == true) {
          this.mappedAccountgroups.push(element);
        }
        this.tmpMappingAccountgroupsData.push(element);
      });
    }

    this.practiceService.fetchAccountGroupList().subscribe(data => {
      if (data.status == 'success') {
        this.listAllAccountgroups = (data.results.length > 0) ? data.results : [];
        this.allAccountgrouups = (data.results.length > 0) ? data.results : [];
        this.defaultSelection();
      }
    });

    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }
  mapPracticesModal(template: TemplateRef<any>, from, featuresData, index) {
    this.searchValue = '';
    this.selectedFeatureId = featuresData._id;
    this.selectedFeaturesObject = featuresData;
    this.parentIndexValue = index;

    this.tmpMappingPracticesData = [];
    this.mappedPractices = [];
    let tmpexistingData = _.cloneDeep(featuresData.allPractices);
    if (tmpexistingData) {
      tmpexistingData.forEach(element => {
        if (element.practiceData.enable == true) {
          this.mappedPractices.push(element);
        }
        this.tmpMappingPracticesData.push(element);
      });
    }

    this.featuresService.fetchPracticeList('').subscribe(data => {
      if (data.status == 'success') {
        this.listAllPractices = (data.results.length > 0) ? data.results : [];
        this.defaultSelectionPractices();
      }
    });

    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }
  defaultSelection() {
    this.listAllAccountgroups.forEach(element => {
      let flag = false;
      this.mappedAccountgroups.forEach(tmp => {
        if (element._id == tmp.accgroup.accgroup_id && tmp.accgroup.enable == true) {
          flag = true;
        }
      });
      if (flag == false) {
        element.enable = false;
      } else {
        element.enable = true;
      }
    });
  }
  defaultSelectionPractices() {
    this.listAllPractices.forEach(element => {
      let flag = false;
      this.mappedPractices.forEach(tmp => {
        if (element._id == tmp.practiceData.practice_id && tmp.practiceData.enable == true) {
          flag = true;
        }
      });
      if (flag == false) {
        element.enable = false;
      } else {
        element.enable = true;
      }
    });
  }
  tabChange(selectedTab, item, index) {
    if (selectedTab.target.innerText == 'Account Group' || selectedTab.target.innerText == 'Practice') {
      this.seletedTab = selectedTab.target.innerText;
      item['buttontype'] = selectedTab.target.innerText;
      if (selectedTab.target.innerText == 'Account Group') {
        if (!this.featuresList[index]['accountgroups']) {
          this.getAccountgroupsAndPractices(item._id, index);
        }
      } else if (selectedTab.target.innerText == 'Practice') {
        if (!this.featuresList[index]['practices']) {
          this.getAccountgroupsAndPractices(item._id, index);
        }
      }
    } else { } 
}
  getAccountgroupsAndPractices(featureId, index) {
    this.featuresList[index].tabloaderIndicator=true;
    this.featuresService.getAccountgroupsAndPractices(featureId).subscribe(data => {
      this.featuresList[index].tabloaderIndicator=false;
      if (data.status === 'success') {
        let tempgroup = [];
        if (data.result.accountGroups) {
          (data.result.accountGroups).forEach(element => {
            let temp = {};
            temp['accgroup'] = element;
            tempgroup.push(temp);
          });
        }
        this.featuresList[index]['accountgroups'] = tempgroup;
        this.searchMappedAccountgroups(index);
        let tempPract = [];
        if (data.result.practices) {
          (data.result.practices).forEach(element => {
            let temp = {};
            temp['practiceData'] = element;
            tempPract.push(temp);
          });
        }
        this.featuresList[index]['practices'] = tempPract;
        this.searchMappedPractices(index);
      }
    });
  }
  searchAccountGroupKey(searchKey) {
    this.listAllAccountgroups = this.allAccountgrouups.filter(item => (item.accountgroup).search(new RegExp(searchKey, 'i')) > -1);
    this.defaultSelection();
  }
  searchPracticesKey(searchKey) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
      this.featuresService.fetchPracticeList(searchKey).subscribe(data => {
        if (data.status == 'success') {
          this.listAllPractices = (data.results.length > 0) ? data.results : [];
          this.defaultSelectionPractices();
        }
      });
    }, 1000);
  }
  selectAccountgroups(data, i) {
    if (data.enable) {
      if (this.mappedAccountgroups == undefined) {
        this.mappedAccountgroups = [];
      }
      data['accgroup_id'] = data['_id'];
      data['enable'] = true;
      this.mappedAccountgroups.push({ 'accgroup': data });
      let index = this.featuresList[this.parentIndexValue].allAccounts.findIndex(result => result.accgroup.accgroup_id === data._id)
      if(index !== -1)
      {
        this.featuresList[this.parentIndexValue].allAccounts[index].accgroup.enable = true;
      } else {
        this.featuresList[this.parentIndexValue].allAccounts.push({ 'accgroup': data });
      }
      this.tmpMappingAccountgroupsData.map(elem => {
        if(elem.accgroup.accgroup_id === data._id) {
          elem.accgroup.enable = true;
        }
        return elem;
      });
      this.featuresService.mapAccgroupsToFeature(this.selectedFeatureId,data._id).subscribe(result => {
        let checkedfeature = this.tmpMappingAccountgroupsData.findIndex(elem =>{
          if(elem.accgroup.accgroup_id === data._id) {
            return true;
          }
          return false;
        });
       
        if(checkedfeature === -1) {
          this.tmpMappingAccountgroupsData.push({ _id:this.selectedFeatureId,'accgroup': data })
        }
        this.sharedService.pushToaster('success', 'Success', 'Accountgroups to Feature Mapped Successfully');
        this.featuresList[this.parentIndexValue].accountgroups = this.tmpMappingAccountgroupsData;
      });

    } else {
      let index = this.mappedAccountgroups.findIndex(result => result.accgroup.accgroup_id === data._id);
      this.tmpMappingAccountgroupsData.map(elem => {
        if (elem.accgroup.accgroup_id === data._id) {
          elem.accgroup.enable = false;
        }
        return elem;
      });
       this.mappedAccountgroups.splice(index, 1); 
       this.featuresService.removeAccgroupsToFeature(this.selectedFeatureId,data._id).subscribe(result => {
        this.sharedService.pushToaster('success', 'Success', 'Accountgroups to Feature Removed Successfully');
       this.featuresList[this.parentIndexValue].accountgroups = this.tmpMappingAccountgroupsData;
       });
    }
  }
  
  selectPractices(data, i) {
    if (data.enable) {
      if (this.mappedPractices == undefined) {
        this.mappedPractices = [];
      }
      data['practice_id'] = data['_id'];
      data['enable'] = true;
      this.mappedPractices.push({ 'practiceData': data });
      let index = this.featuresList[this.parentIndexValue].allPractices.findIndex(result => result.practiceData.practice_id === data._id)

      if(index !== -1)
      {
        this.featuresList[this.parentIndexValue].allPractices[index].practiceData.enable = true;
      }
      else
      {
        this.featuresList[this.parentIndexValue].allPractices.push({ 'practiceData': data })
        if(this.featuresList[this.parentIndexValue].practices.length<5)
        {
          this.featuresList[this.parentIndexValue].practices.push({ 'practiceData': data })
        }
        this.featuresList[this.parentIndexValue].totalCountForPractice = this.featuresList[this.parentIndexValue].totalCountForPractice + 1;
      }
      
      
      this.featuresService.addPractices(this.selectedFeatureId, data['_id']).subscribe(result => {
        if (result.status == 'success') {
          this.sharedService.pushToaster('success', 'Success', 'Practice added successfully');
        } else {
          this.sharedService.pushToaster('error', 'Failure', 'Practice adding Failed. Please try again');
        }
      });

    } else {
      let index = this.mappedPractices.findIndex(result => result.practiceData.practice_id === data._id);
      this.tmpMappingPracticesData.map(elem => {
        if (elem.practiceData.practice_id === data._id) {
          elem.practiceData.enable = false;
        }
        return elem;
      });
      let index2 = this.featuresList[this.parentIndexValue].allPractices.findIndex(result => result.practiceData.practice_id === data._id)
      if(index2 !== -1)
      {
        this.featuresList[this.parentIndexValue].allPractices[index2].practiceData.enable = false;
      }
      this.mappedPractices.splice(index, 1);

      this.featuresService.deletePractices(this.selectedFeatureId, data['_id']).subscribe(result => {
        if (result.status == 'success') {
          this.sharedService.pushToaster('success', 'Success', 'Practice removed successfully');
        } else {
          this.sharedService.pushToaster('error', 'Failure', 'Practice removing Failed. Please try again');
        }
      });
    }
  }
  removeMappedAccountgroup(mappedGroup, indexs) {
    if (mappedGroup) {
      let index = this.listAllAccountgroups.findIndex(result => (result.accgroup_id === mappedGroup.accgroup.accgroup_id || result._id === mappedGroup.accgroup.accgroup_id));
      if (index >= 0) {
        this.listAllAccountgroups[index].enable = false;
      }
      this.tmpMappingAccountgroupsData.map(elem => {
        if (elem.accgroup.accgroup_id === mappedGroup.accgroup.accgroup_id) {
          elem.accgroup.enable = false;
        }
        return elem;
      });
      this.mappedAccountgroups.splice(indexs, 1);
      this.featuresService.removeAccgroupsToFeature(this.selectedFeatureId,mappedGroup.accgroup.accgroup_id).subscribe(result => {
      this.sharedService.pushToaster('success', 'Success', 'Accountgroups to Feature Removed Sucessfully'); 
      this.featuresList[this.parentIndexValue].accountgroups = this.tmpMappingAccountgroupsData;
       });
    }
  }
  removeMappedPractice(mappedPractice, indexs) {
    if (mappedPractice) {
      let index = this.listAllPractices.findIndex(result => (result.practice_id === mappedPractice.practiceData.practice_id || result._id === mappedPractice.practiceData.practice_id));
      if (index >= 0) {
        this.listAllPractices[index].enable = false;
      }
      let index2 = this.featuresList[this.parentIndexValue].practices.findIndex(result => result.practiceData.practice_id === mappedPractice.practiceData.practice_id)
      if(index2 !== -1)
      {
        this.featuresList[this.parentIndexValue].practices[index2].practiceData.enable = false;
      }
      let index3 = this.mappedPractices.findIndex(result => result.practiceData.practice_id === mappedPractice.practiceData.practice_id)
      if(index3 !== -1){
        this.mappedPractices.splice(index3, 1);
      }

      this.tmpMappingPracticesData.map(elem => {
        if (elem.practiceData.practice_id === mappedPractice.practiceData.practice_id) {
          elem.practiceData.enable = false;
        }
        return elem;
      });
      this.featuresService.deletePractices(this.selectedFeatureId, mappedPractice.practiceData.practice_id).subscribe(result => {
        if (result.status == 'success') {
          this.sharedService.pushToaster('success', 'Success', 'Practice removed successfully');
        } else {
          this.sharedService.pushToaster('error', 'Failure', 'Practice removing Failed. Please try again');
        }
      });

    }
  }
  mapNewAccountgroup() {
    let allAccountgroups = this.tmpMappingAccountgroupsData;
    let updatedDataIds = [];
    this.mappedAccountgroups.forEach(element => {
      updatedDataIds.push(element.accgroup.accgroup_id);
    });
    let mappedData = JSON.parse(JSON.stringify(this.mappedAccountgroups));
    allAccountgroups.forEach(element => {
      let index = this.mappedAccountgroups.findIndex(result => result.accgroup.accgroup_id === element.accgroup.accgroup_id);
      if (index != -1) {
        this.mappedAccountgroups.splice(index, 1);
      }
      if (updatedDataIds.indexOf(element.accgroup.accgroup_id) == -1) {
        mappedData.push(element);
      }
    });
    let finelmappedData = [];
    let i = 0;
    let selectedAccount;
    mappedData.forEach(element => {
      element.accgroup.enable = (element.accgroup.enable == undefined) ? true : element.accgroup.enable;
      selectedAccount = {
        'accgroup_id': element.accgroup.accgroup_id,
        'isCheck': element.accgroup.enable
      };
      finelmappedData.push(selectedAccount);
      i++;
    });
    let accountgroupsObject = {
      'accountgroups': finelmappedData
    };
    if (i == mappedData.length) {
      this.featuresService.mapAccountgroups(this.featuresList[this.parentIndexValue]._id, accountgroupsObject).subscribe(result => {
        if (result.status == 'success') {
          this.featuresList[this.parentIndexValue].accountgroups = mappedData;
          this.featuresList[this.parentIndexValue].allAccounts = undefined;
          this.searchMappedAccountgroups(this.parentIndexValue);
          this.sharedService.pushToaster('success', 'Success', 'Account groups mapped successfully');
          this.modalClose();
        } else {
          this.sharedService.pushToaster('error', 'Failure', 'Mapping Failed. Please try again');
        }
      });
    }
  }
  mapNewPractice() {
    let allPractices = this.tmpMappingPracticesData;
    let updatedDataIds = [];
    this.mappedPractices.forEach(element => {
      updatedDataIds.push(element.practiceData.practice_id);
    });
    let mappedData = JSON.parse(JSON.stringify(this.mappedPractices));
    allPractices.forEach(element => {
      let index = this.mappedPractices.findIndex(result => result.practiceData.practice_id === element.practiceData.practice_id);
      if (index != -1) {
        this.mappedPractices.splice(index, 1);
      }
      if (updatedDataIds.indexOf(element.practiceData.practice_id) == -1) {
        mappedData.push(element);
      }
    });
    let finelmappedData = [];
    let i = 0;
    let selectedPractice;
    mappedData.forEach(element => {
      element.practiceData.enable = (element.practiceData.enable == undefined) ? true : element.practiceData.enable;
      selectedPractice = {
        'practice_id': element.practiceData.practice_id,
        'isCheck': element.practiceData.enable
      };
      finelmappedData.push(selectedPractice);
      i++;
    });
    let practicesObject = {
      'practices': finelmappedData
    };
    if (i == mappedData.length) {
      this.featuresService.mapPractices(this.featuresList[this.parentIndexValue]._id, practicesObject).subscribe(result => {
        if (result.status == 'success') {
          this.featuresList[this.parentIndexValue].practices = mappedData;
          this.featuresList[this.parentIndexValue].allPractices = undefined;
          this.searchMappedPractices(this.parentIndexValue);
          this.sharedService.pushToaster('success', 'Success', 'Practices mapped successfully');
          this.modalClose();
        } else {
          this.sharedService.pushToaster('error', 'Failure', 'Mapping Failed. Please try again');
        }
      });
    }
  }
  accountgroupStatusChange() {
    if(this.updateAccountgroupStatus){
      this.featuresService.mapAccgroupsToFeature(this.selectedFeatureId,this.selectedAccountgroupId).subscribe(result => {
        this.sharedService.pushToaster('success', 'Success', 'Account Group Mapped Successfully');
      });
    } else {
      this.featuresService.removeAccgroupsToFeature(this.selectedFeatureId,this.selectedAccountgroupId).subscribe(result => {
        this.sharedService.pushToaster('success', 'Success', 'Account Group Removed Successfully');
       });
    }
    this.modalClose();
    // let arr = [{
    //   'accgroup_id': this.selectedAccountgroupId,
    //   'isCheck': this.updateAccountgroupStatus
    // }];
    // let updateStatusObj = {
    //   'accountgroups': arr
    // };
    // this.featuresService.mapAccountgroups(this.selectedFeatureId, updateStatusObj).subscribe(result => {
    //   if (result.status == 'success') {
    //     let status = this.updateAccountgroupStatus === true ? 'activated' : 'deactivated';
    //     this.sharedService.pushToaster('success', 'Success', 'Account group status ' + status + ' successfully');
    //     this.featuresList[this.parentIndexValue].accountgroups[this.childIndexValue].accgroup.enable = this.updateAccountgroupStatus;
    //     this.modalClose();
    //   } else {
    //     this.sharedService.pushToaster('error', 'Failure', 'Failed to update. Please try again');
    //   }
    // });
  }
  practicesStatusChange() {
    if(this.updatePracticeStatus)
    {
      this.featuresService.addPractices(this.selectedFeatureId, this.selectedPracticeId).subscribe(result => {
        if (result.status == 'success') {
          let status = this.updatePracticeStatus === true ? 'activated' : 'deactivated';
          this.sharedService.pushToaster('success', 'Success', 'Practice status ' + status + ' successfully');
          this.featuresList[this.parentIndexValue].practices[this.childIndexValue].practiceData.enable = this.updatePracticeStatus;
          this.modalClose();
        } else {
          this.sharedService.pushToaster('error', 'Failure', 'Failed to update. Please try again');
        }
      });
    }else{
      this.featuresService.deletePractices(this.selectedFeatureId, this.selectedPracticeId).subscribe(result => {
        if (result.status == 'success') {
          let status = this.updatePracticeStatus === true ? 'activated' : 'deactivated';
          this.sharedService.pushToaster('success', 'Success', 'Practice status ' + status + ' successfully');
          this.featuresList[this.parentIndexValue].practices[this.childIndexValue].practiceData.enable = this.updatePracticeStatus;
          this.modalClose();
        } else {
          this.sharedService.pushToaster('error', 'Failure', 'Failed to update. Please try again');
        }
      });
    }   
  }
  searchMappedAccountgroups(i) {
    if (!this.featuresList[i].allAccounts) {
      this.featuresList[i].allAccounts = JSON.parse(JSON.stringify(this.featuresList[i].accountgroups));
    }
    // this.featuresList[i].accountgroups = [];
    this.resetPaginationVal(i);
    this.dataMapping(i);
  }
  resetPaginationVal(i) {
    this.featuresList[i].page = 0;
    this.featuresList[i].pageskip = 0;
    this.featuresList[i].currentPage = 1;
  }
  dataMapping(i) {
    let tempData = (this.featuresList[i].allAccounts).filter(item => (item.accgroup.accountgroup).search(new RegExp(this.searchMappedAccounts, 'i')) > -1);
    let endLimit = (tempData.length <= 2) ? tempData.length : this.defaultLimit;
    this.featuresList[i].accountgroups = tempData.slice(this.featuresList[i].page, this.featuresList[i].page + endLimit);
    this.featuresList[i].totalCount = tempData.length;
  }
  onpageChanged(ev, i) {
    this.featuresList[i].currentPage = ev.page;
    this.featuresList[i].page = ev.page * this.defaultLimit - this.defaultLimit;
    this.featuresList[i].pageskip = this.featuresList[i].page;
    this.dataMapping(i);
  }
  searchMappedPractices(i) {
    if (!this.featuresList[i].allPractices) {
      this.featuresList[i].allPractices = this.featuresList[i].practices;
    }
    // this.featuresList[i].accountgroups = [];
    this.resetPaginationForPractice(i);
    this.dataMappingForPractice(i);
  }
  resetPaginationForPractice(i) {
    this.featuresList[i].pageForPractice = 0;
    this.featuresList[i].pageskipForPractice = 0;
    this.featuresList[i].currentPageForPractice = 1;
  }
  dataMappingForPractice(i) {
    let tempData = (this.featuresList[i].allPractices).filter(item => ((item.practiceData.organization_name).search(new RegExp(this.searchMappedPractice, 'i')) > -1) || ((item.practiceData.accountno).search(new RegExp(this.searchMappedPractice, 'i')) > -1));
    let endLimit = (tempData.length <= 2) ? tempData.length : this.defaultLimit;
    this.featuresList[i].practices = tempData.slice(this.featuresList[i].pageForPractice, this.featuresList[i].pageForPractice + endLimit);
    this.featuresList[i].totalCountForPractice = tempData.length;
  }
  onpageChangedForPractice(ev, i) {
    this.featuresList[i].currentPageForPractice = ev.page;
    this.featuresList[i].pageForPractice = ev.page * this.defaultLimit - this.defaultLimit;
    this.featuresList[i].pageskipForPractice = this.featuresList[i].pageForPractice;
    this.dataMappingForPractice(i);
  }
  removePractice() {
    this.featuresService.deletePractice(this.selectedFeatureId, this.selectedPracticeId).subscribe(response => {
      if (response['status'] == 'success') {
        let indexs = this.featuresList[this.parentIndexValue].practices.findIndex(result => result.practiceData.practice_id === this.selectedPracticeId)
      let indexs2 = this.featuresList[this.parentIndexValue].allPractices.findIndex(result => result.practiceData.practice_id === this.selectedPracticeId)
      let indexs3;
      let cp = this.featuresList[this.parentIndexValue].currentPageForPractice;
      if(this.featuresList[this.parentIndexValue].allPractices.length>cp*5){
        indexs3 =  this.featuresList[this.parentIndexValue].allPractices.findIndex(result => result.practiceData.practice_id === this.featuresList[this.parentIndexValue].allPractices[this.featuresList[this.parentIndexValue].practices.length].practiceData.practice_id)
      }

      if(indexs !== -1)
      {
        this.featuresList[this.parentIndexValue].practices.splice(indexs,1);
        if(this.featuresList[this.parentIndexValue].practices.length ==0 && this.featuresList[this.parentIndexValue].currentPageForPractice > 0){
          this.featuresList[this.parentIndexValue].currentPageForPractice=this.featuresList[this.parentIndexValue].currentPageForPractice-1;
          let ev = {
            itemsPerPage: 5,
            page: this.featuresList[this.parentIndexValue].currentPageForPractice
          }
          this.onpageChangedForPractice(ev,this.parentIndexValue);
        }else{
            if(this.featuresList[this.parentIndexValue].allPractices.length>cp*5){
              this.featuresList[this.parentIndexValue].practices.push(this.featuresList[this.parentIndexValue].allPractices[indexs3])
              }
        }

      }
      if(indexs2 !== -1)
      {
        this.featuresList[this.parentIndexValue].allPractices.splice(indexs2,1);
      }
      this.featuresList[this.parentIndexValue].totalCountForPractice = this.featuresList[this.parentIndexValue].totalCountForPractice - 1;
        
        if (this.featuresList[this.parentIndexValue].totalCountForPractice != 0 && (this.featuresList[this.parentIndexValue].practices).length == 0) {
          if (this.featuresList[this.parentIndexValue].pageForPractice != 0) {
            this.featuresList[this.parentIndexValue].pageForPractice = this.featuresList[this.parentIndexValue].pageForPractice - this.defaultLimit;
          }
        }
        //this.dataMappingForPractice(this.parentIndexValue);
        this.modalClose();
        this.sharedService.pushToaster('success', 'Success', 'Feature mapped practice removed successfully');
      } else {
        this.modalClose();
      }
    });
  }
  ngOnDestroy(): void {
    this.SharedPopupService.dismiss();
  }
}
