import { Component, OnDestroy, Inject, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit, OnInit, TemplateRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navItems, NavData } from '../../_nav';
import { AuthenticationService } from '../../_services/auth/authentication.service';
import { UserIdleService } from 'angular-user-idle';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { SharedPopupService } from '../../_services/popup.service';
import { SharedService } from "../../_services/shared.service";
import { UsersService } from "../../_services/settings/users.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataHelperService } from '../../_services/auth/data-helper.service';
import { CapitalizePipe } from '../../pipes/capitalize.pipe';
import { SelectPracticeComponent } from '../../components/select-practice/select-practice.component';
import { NavparamsService } from '../..//_services/navparams/navparams.service';
import { RouteAuthorizationService } from '../../_services/auth/route-authorization.service';
import { HeaderService } from '../../_services/header/header.service';
import { Subscription } from 'rxjs/Rx';
import { ProcedurecodesService } from '../../_services/settings/procedurecodes.service';
//import { Spinkit } from 'ng-http-loader';
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  providers: [CapitalizePipe]
})
export class DefaultLayoutComponent implements AfterViewInit, OnDestroy, OnInit {
 // public spinner= Spinkit;
  public navLists: NavData[];
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  public displayname: string;
  public initialDisplayIcon: string;
  closeResult: string;
  count: any;
  currentUrl: string;
  praciceClicked: boolean;
  followupcount: number;
  followupIds: any;
  popUpfrom:any;
  parentIndexValue:any;
  popupTitle:any;
  popupUserInfo:any;
  resetPasswordType:any;
  tempPassword:any;
  tempConfirmPassword:any;
  country:any;
  uae_VersionList:any;
  selectedversion:any;
  userInfo:any=[];
  item:any=[];
  isStatus:boolean=false;
  years:any=[];
  months:any=[];
  selectedScope:any=[];
  tempPMS:any=[];
  tempSpec:any=[];
  proficiencyLevel=["No Experience","Beginner","Moderate","Advanced"];
  experience=["true","false"];
  subWorkscope:any=["AR Analysis","Denials","Rejections","Quality Audit"];
  specialityInfo:any=[  {
    "Name": "Allergy and Immunology",
    "ProficiencyLevels": ""
  },
  {
    "Name": " Anesthesiology",
    "ProficiencyLevels": ""
  }, {
    "Name": "Cardiology",
    "ProficiencyLevels": ""
  },
  {
    "Name": "Dermatology",
    "ProficiencyLevels": ""
  }, {
    "Name": " Emergency Medicine",
    "ProficiencyLevels": ""
  },
  {
    "Name": "Endocrinology",
    "ProficiencyLevels": ""
  }, {
    "Name": "Family Medicine",
    "ProficiencyLevels": ""
  },
  {
    "Name": "Gastroenterology",
    "ProficiencyLevels": ""
  }, {
    "Name": "Geriatrics",
    "ProficiencyLevels": ""
  },
  {
    "Name": "Hematology",
    "ProficiencyLevels": ""

  }, {
    "Name": "Infectious Disease",
    "ProficiencyLevels": ""
  },
  {
    "Name": "Internal Medicine",
    "ProficiencyLevels": ""
  }, {
    "Name": "Nephrology",
    "ProficiencyLevels": ""
  },
  {
    "Name": "Neurology",
    "ProficiencyLevels": ""
  }, {
    "Name": "Obstetrics and Gynecology",
    "ProficiencyLevels": ""
  },
  {
    "Name": "Ophthalmology",
    "ProficiencyLevels": ""
  }, {
    "Name": "Otolaryngology (ENT)",
    "ProficiencyLevels": ""
  },
  {
    "Name": "Pathology",
    "ProficiencyLevels": ""
  },
  {
    "Name": "Pediatrics",
    "ProficiencyLevels": ""
  }, {
    "Name": "Psychiatry",
    "ProficiencyLevels": ""
  },
  {
    "Name": "Pulmonology",
    "ProficiencyLevels": ""
  }, {
    "Name": "Radiology",
    "ProficiencyLevels": ""
  },
  {
    "Name": "Rheumatology",
    "ProficiencyLevels": ""
  },
  {
    "Name": "Surgery",
    "ProficiencyLevels": ""
  }, {
    "Name": "Urology",
    "ProficiencyLevels": ""
  },]
  PMSinfo:any=[{ "Name": "ADVANCEDMD", "ProficiencyLevels": "" },
  { "Name": "ALLEGIANCEMD", "ProficiencyLevels": "" },
  { "Name": "ALLSCRIPTS", "ProficiencyLevels": "" },
  { "Name": "ATHENAHEALTH", "ProficiencyLevels": "" },
  { "Name": "AZALEA HEALTH", "ProficiencyLevels": "" },
  { "Name": "BRIGHTREE", "ProficiencyLevels": "" },
  { "Name": "CARECLOUD", "ProficiencyLevels": "" },
  { "Name": "CENTRALREACH", "ProficiencyLevels": "" },
  { "Name": "CENTRICITY EMR", "ProficiencyLevels": "" },
  { "Name": "CERNER", "ProficiencyLevels": "" },
  { "Name": "CHARMHEALTH", "ProficiencyLevels": "" },
  { "Name": "CHARTLOGIC", "ProficiencyLevels": "" },
  { "Name": "CUREMD", "ProficiencyLevels": "" },
  { "Name": "DRCHRONO", "ProficiencyLevels": "" },
  { "Name": "ECLINICALWORKS", "ProficiencyLevels": "" },
  { "Name": "EPIC", "ProficiencyLevels": "" },
  { "Name": "EPIC SYSTEMS", "ProficiencyLevels": "" },
  { "Name": "GE HEALTHCARE CENTRICITY PRACTICE SOLUTION", "ProficiencyLevels": "" },
  { "Name": "GREENWAY HEALTH", "ProficiencyLevels": "" },
  { "Name": "HEALTHFUSION MEDITOUCH", "ProficiencyLevels": "" },
  { "Name": "INTERGY EHR", "ProficiencyLevels": "" },
  { "Name": "IPATIENTCARE", "ProficiencyLevels": "" },
  { "Name": "KAREO", "ProficiencyLevels": "" },
  { "Name": "MCKESSON PRACTICE CHOICE", "ProficiencyLevels": "" },
  { "Name": "MEDITECH", "ProficiencyLevels": "" },
  { "Name": "MODMED", "ProficiencyLevels": "" },
  { "Name": "NEXTGEN", "ProficiencyLevels": "" },
  { "Name": "NEXTGEN HEALTHCARE", "ProficiencyLevels": "" },
  { "Name": "NUEMD", "ProficiencyLevels": "" },
  { "Name": "OFFICE ALLY", "ProficiencyLevels": "" },
  { "Name": "PABAU", "ProficiencyLevels": "" },
  { "Name": "PCC EHR", "ProficiencyLevels": "" },
  { "Name": "PRACTICE EHR", "ProficiencyLevels": "" },
  { "Name": "PRACTICE FUSION", "ProficiencyLevels": "" },
  { "Name": "PRACTICESUITE", "ProficiencyLevels": "" },
  { "Name": "PROGNOCIS BY BIZMATICS", "ProficiencyLevels": "" },
  { "Name": "RXNT", "ProficiencyLevels": "" },
  { "Name": "SIMPLEPRACTICE", "ProficiencyLevels": "" },
  { "Name": "SRS HEALTH", "ProficiencyLevels": "" },
  { "Name": "THERABILL", "ProficiencyLevels": "" },
  { "Name": "TIGERCONNECT", "ProficiencyLevels": "" },
  { "Name": "TOUCHWORKS EHR BY ALLSCRIPTS", "ProficiencyLevels": "" },
  { "Name": "VIRENCE HEALTH CENTRICITY PRACTICE SOLUTION", "ProficiencyLevels": "" },
  { "Name": "WEBPT", "ProficiencyLevels": "" },
  { "Name": "WRS HEALTH", "ProficiencyLevels": "" },
  { "Name": "ZOCDOC", "ProficiencyLevels": "" }]
  public brand: Brand = {
    'full': '',
    'logo': '',
    'title': '',
    'alt': ''
  };
  globalSkillset;
  isLoading:boolean=false;
  practiceInfo:any=[];
  tempPractice:any=[];
  @ViewChild('openmodal', { static: false }) openmodal: ElementRef;
  @ViewChild('openUsermodal', { static: false }) openUsermodal: ElementRef;
  public practiceList: any;
  subscription: Subscription;
  constructor(private capitalize: CapitalizePipe,
    private modalService: NgbModal,
    private userIdle: UserIdleService,
    private routers: Router,
    private dataHelperService: DataHelperService,
    private authenticationService: AuthenticationService,
    private routeAuthorizationService: RouteAuthorizationService,
    public SharedPopupService: SharedPopupService,
    public sharedService:SharedService,
    public userService:UsersService,
    private headerService: HeaderService,
    private route: ActivatedRoute,
    private navparams: NavparamsService,
    private procedurecodesService: ProcedurecodesService,
    @Inject(DOCUMENT) _document?: any
  ) {

    this.country = localStorage.getItem('Region');
    if(this.country=="UAE"){
      this.sharedService.getGlobalData('uae_codeset').subscribe(result => {      
        this.uae_VersionList = this.sharedService.globalData['uae_codeset'];      
        this.selectedversion=this.uae_VersionList[0];
        this.selectedVersion(this.selectedversion, 'initial'); 
      })
    }
    // this.routers.events.subscribe(event => { console.log(event); });
    this.subscription = this.routers.events.filter(event => event instanceof NavigationEnd).subscribe(event => {
      this.changeTrigger();
    });
    this.routeAuthorizationService.addPersionalViewList();
    this.routeAuthorizationService.purePersonalNavLists.subscribe(data => {
      this.navLists = data;
    });

    if (this.dataHelperService.getItem('userBrand') != '' && this.dataHelperService.getItem('userBrand') && this.dataHelperService.getItem('userBrand') != undefined) {
      this.brand = {
        'full': this.dataHelperService.getItem('userBrand') + '-logoz-full.png',
        'logo': this.dataHelperService.getItem('userBrand') + '-logoz.png',
        'title': this.dataHelperService.getItem('userBrand'),
        'alt': this.dataHelperService.getItem('userBrand') + ' Logo',
      };
    } else {
      this.brand = {
        'full': 'Default-logoz-full.png',
        'logo': 'Default-logoz.png',
        'title': 'Default',
        'alt': 'Default Logo',
      };
    }
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
      this.dataHelperService.toggleChecker = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });
    this.displayname = this.dataHelperService.getItem('currentUserDisplayname');
    if(this.displayname){
      let dName = this.displayname.split('_');
      this.initialDisplayIcon = dName[1];
    }
    /* Session out during idle */
    // Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.count = 10;
    this.userIdle.onTimerStart().subscribe(count => {
      if (this.count == 10) {
        let userid = this.dataHelperService.getItem('userid');
        let refreshToken = localStorage.getItem('authRefreshToken');
        let authToken = localStorage.getItem('authToken');
        this.authenticationService.checkRefreshToken(userid, refreshToken, authToken).subscribe(data => {
          if (data.status == 'success') {
            localStorage.setItem('authToken', data.authToken);
            this.resetsession();
          } else {
            let el: HTMLElement = this.openmodal.nativeElement as HTMLElement;
            el.click();
          }
        });
      }
      this.count = --this.count;
    });

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      this.modalService.dismissAll();
      this.authenticationService.stopWatching();
      // this.authenticationService.logout();
      this.logout();
    });
    /* Session out during idle */

    // route authorization start
    this.routeAuthorizationService.filterRoutes().then(data => {
      this.navLists = this.routeAuthorizationService.navLists;
    });
    this.routeAuthorizationService.changeEmitted$.subscribe(data => {
      this.navLists = this.routeAuthorizationService.navLists;
    });
    this.headerService.userPracticeAccount('', 500).subscribe(data => {
      if(data.status=='success'){
        this.practiceInfo=data.results
      }
          });
    this.headerService.followupEmitted$.subscribe(data => {
      this.triggerFollowupService();
    });
    //  route authorization ends
  }
   openUserModal(template: TemplateRef<any>, from, infoData, parentIndex) {


    this.popUpfrom = from;
    this.parentIndexValue = parentIndex;
    this.item=[];
    let responseArray:any=[];
    this.selectedScope=[];
    this.years=[];
    this.months=[];
    for(let i=0;i<=35;i++)
      {
        this.years.push(i)
      }
      for(let i=0;i<=12;i++)
        {
          this.months.push(i)
        }
    this.globalSkillset=[{
      "SkillSet" : { 
        "Experience" : {
          "Years" : "",
          "Months" : ""
      },     
          "SubworkScope" : "",
          "SkillLevels" : {
              "CallingSkills" : [
                  {
                      "Type" : "AR Calling",
                      "ProficiencyLevels" : "No Experience"
                  },
                  {
                      "Type" : "Patient Calling",
                      "ProficiencyLevels" : "No Experience"
                  }
              ]
          },
          "HighPriorityClaimsHandling" : {
              "Experienced" : "false"
          },
          "CallingTypeExpertise" : {
              "Types" : [
                  {
                      "Name" : "AVR/IVR",
                      "ProficiencyLevels" : "No Experience"
                  },
                  {
                      "Name" : "Federal Insurance Claims",
                      "ProficiencyLevels" : "No Experience"
                  },
                  {
                      "Name" : "Blue Cross Blue Shield (BCBS) Claims",
                      "ProficiencyLevels" : "No Experience"
                  },
                  {
                      "Name" : "Workers' Compensation (WC) Claims",
                      "ProficiencyLevels" : "No Experience"
                  },
                  {
                      "Name" : "Patient Direct Claims",
                      "ProficiencyLevels" : "No Experience"
                  },
                  {
                      "Name" : "Miscellaneous Claims",
                      "ProficiencyLevels" : "No Experience"
                  }
              ]
          },
          "PracticeManagementSystems" : {
              "Systems" : [
  
              ]
          },
          "ClientProtocolProficiency" : {
              "Levels" : [
               
              ]
          }
      },
      "Specialties": {
        "SpecialtiesList": [
        
        ]
      },
   
    }]
    this.userService.getUserSkillSetInfo('').subscribe(response=>{
      
      if(response.length)
        {
          responseArray=response[0];
          // if (responseArray.Specialties && responseArray.Specialties.SpecialtiesList && responseArray.Specialties.SpecialtiesList.length!=0){
          //   this.globalSkillset.Specialties.SpecialtiesList=responseArray.Specialties.SpecialtiesList
          // }
          // if (responseArray.SkillSet && responseArray.SkillSet.PracticeManagementSystems&&responseArray.SkillSet.PracticeManagementSystems.Systems){
          //   this.globalSkillset.SkillSet.PracticeManagementSystems.Systems[0]=responseArray.SkillSet.PracticeManagementSystems.Systems
          // }
          this.globalSkillset.forEach(skillset => {
            if (responseArray.commentsPosted){
                skillset.commentsPosted=responseArray.commentsPosted
              }else{
                skillset.commentsPosted=null
              }
            if (responseArray.SkillSet.Experience && responseArray.SkillSet.Experience.Years) {
              skillset.SkillSet.Experience.Years = responseArray.SkillSet.Experience.Years;
            }
            if (responseArray.SkillSet.Experience && responseArray.SkillSet.Experience.Months) {
              skillset.SkillSet.Experience.Months = responseArray.SkillSet.Experience.Months;
            }
         
            if (responseArray.SkillSet.SubworkScope) {
              skillset.SkillSet.SubworkScope = responseArray.SkillSet.SubworkScope;
          //    this.selectedScope=responseArray.SkillSet.SubworkScope
            }
            else
            {
              skillset.SkillSet.SubworkScope = "";
          //    this.selectedScope=[];
            }
            if (skillset.SkillSet && skillset.SkillSet.SkillLevels.CallingSkills && skillset.SkillSet.SkillLevels.CallingSkills ) {
              skillset.SkillSet.SkillLevels.CallingSkills.forEach(callingType => {
                  const correspondingCallingType = responseArray.SkillSet.SkillLevels.CallingSkills.find(responseCallingType => responseCallingType.Type === callingType.Type);
                  if (correspondingCallingType) {
                      callingType.ProficiencyLevels = correspondingCallingType.ProficiencyLevels;
                  }
              });
            }
            if(responseArray.SkillSet.HighPriorityClaimsHandling&&responseArray.SkillSet.HighPriorityClaimsHandling.Experienced){
              skillset.SkillSet.HighPriorityClaimsHandling.Experienced=responseArray.SkillSet.HighPriorityClaimsHandling.Experienced
            }
            if(responseArray.SkillSet.ClientProtocolProficiency&&responseArray.SkillSet.ClientProtocolProficiency.Levels
              &&responseArray.SkillSet.ClientProtocolProficiency.Levels.length
            ){
              skillset.SkillSet.ClientProtocolProficiency.Levels=responseArray.SkillSet.ClientProtocolProficiency.Levels
            }
              if (skillset.SkillSet && skillset.SkillSet.CallingTypeExpertise && skillset.SkillSet.CallingTypeExpertise.Types) {
                skillset.SkillSet.CallingTypeExpertise.Types.forEach(callingType => {
                    const correspondingCallingType = responseArray.SkillSet.CallingTypeExpertise.Types.find(responseCallingType => responseCallingType.Name === callingType.Name);
                    if (correspondingCallingType) {
                        callingType.ProficiencyLevels = correspondingCallingType.ProficiencyLevels;
                    }
                });
              }
              if (responseArray.SkillSet && responseArray.SkillSet.PracticeManagementSystems&&responseArray.SkillSet.PracticeManagementSystems.Systems) {
                skillset.SkillSet.PracticeManagementSystems.Systems=responseArray.SkillSet.PracticeManagementSystems.Systems;
                // skillset.SkillSet.PracticeManagementSystems.Systems.forEach(callingType => {
                //     const correspondingCallingType = responseArray.SkillSet.PracticeManagementSystems.Systems.find(responseCallingType => responseCallingType.Name === callingType.Name);
                //     if (correspondingCallingType) {
                //         callingType.ProficiencyLevels = correspondingCallingType.ProficiencyLevels;
                //     }
                // });
               }
                 if (responseArray.Specialties && responseArray.Specialties.SpecialtiesList && responseArray.Specialties.SpecialtiesList.length!=0) {
                  skillset.Specialties.SpecialtiesList=responseArray.Specialties.SpecialtiesList
              //     skillset.Specialties.SpecialtiesList.forEach(callingType => {
              //         const correspondingCallingType = responseArray.Specialties.SpecialtiesList.find(responseCallingType => responseCallingType.Name === callingType.Name);
              //         if (correspondingCallingType) {
              //             callingType.ProficiencyLevels = correspondingCallingType.ProficiencyLevels;
              //         }
              //     });
               }    
        });
        if(responseArray.Status){
          this.globalSkillset[0].Status=responseArray.Status;
        }else {
          this.globalSkillset[0].Status='Not Updated';
        }
          this.item= this.globalSkillset[0];
          this.SharedPopupService.show(template, {
            animated: true,
            backdrop: 'static',
            class: 'modal-medium'
          }); 
        }
        else{
          this.globalSkillset[0].Status='Not Updated';
          this.item=this.globalSkillset[0];
          this.SharedPopupService.show(template, {
          animated: true,
          backdrop: 'static',
          class: 'modal-medium'
        }); 
        }
    })
      this.popupTitle = 'Update SkillSet';
      this.popupUserInfo = {
        user_id: this.dataHelperService.getItem('userid'),
        username: this.dataHelperService.getItem('currentUser'),
        email: this.dataHelperService.getItem('uem'),
        mobile: this.dataHelperService.getItem('umob')
      }; 
  }
  updateProfile()
  {
    if(this.item.SkillSet.Experience.Years=="" &&this.item.SkillSet.Experience.Months==""){
        this.sharedService.pushToaster('warning', 'Experience not updated', 'Please select experience');
        return;
      }else if(this.item.SkillSet.SubworkScope==''){
        this.sharedService.pushToaster('warning', 'Subwork scope not updated', 'Please select Subwork scope');
        return;
      } else if(this.item.SkillSet.PracticeManagementSystems.Systems.length==0){
        this.sharedService.pushToaster('warning', 'Practice Management System not updated', 'Please select PMS');
        return;
        }else if(this.item.Specialties.SpecialtiesList.length==0){
          this.sharedService.pushToaster('warning', 'Specialties not updated', 'Please select Specialties');
          return;
          }
          else if(this.item.SkillSet.ClientProtocolProficiency.Levels.length==0)
            {
              this.sharedService.pushToaster('warning', 'Client Protocol Proficiency not updated', 'Please select Practice Name');
              return;
            }
    this.isLoading=true;
    this.item.Specialties.SpecialtiesList =  this.item.Specialties.SpecialtiesList.filter(system => system.ProficiencyLevels !== "");
    this.item.SkillSet.CallingTypeExpertise.Types =  this.item.SkillSet.CallingTypeExpertise.Types.filter(type => type.ProficiencyLevels !== "");
    this.item.SkillSet.PracticeManagementSystems.Systems =  this.item.SkillSet.PracticeManagementSystems.Systems.filter(system => system.ProficiencyLevels !== "");
    this.item.SkillSet.SkillLevels.CallingSkills =  this.item.SkillSet.SkillLevels.CallingSkills.filter(system => system.ProficiencyLevels !== "");
    //this.item.PMS.Types =  this.item.PMS.Types.filter(system => system.ProficiencyLevels !== "");
    let updateInfo={    
      'Specialties':this.item.Specialties,
      'SkillSet':this.item.SkillSet,
     // 'PMS': this.item.PMS,
      'Status':'Pending',
    }
    this.userService.updateUserSkill(updateInfo).subscribe(response=>{
      this.isLoading=false;
      if(response.status=='success')
        {
         this.closeModal();
         this.sharedService.pushToaster('success', 'SkillSet Updated', 'Skillset Submitted for Approval');
       }
       else{
        console.log(response);
       }
    })
  }
  openModal(template: TemplateRef<any>, from, infoData, parentIndex) {
    this.popUpfrom = from;
    this.parentIndexValue = parentIndex;
    if (from == 'passwordReset') {
      this.popupTitle = 'Reset password';
      this.popupUserInfo = {
        user_id: this.dataHelperService.getItem('userid'),
        username: this.dataHelperService.getItem('currentUser'),
        email: this.dataHelperService.getItem('uem'),
        mobile: this.dataHelperService.getItem('umob')
      };
      this.resetPasswordType = 0;
      this.tempConfirmPassword = "";
      this.tempPassword = "";
    }
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }

  showModal(templateRef) {
    this.SharedPopupService.show(templateRef, {
      animated: true,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }

  closeModal() {
    this.SharedPopupService.dismiss();
  }
  modalClose() {
    this.SharedPopupService.dismiss();
  }
  passwordresetting() {
    if (this.resetPasswordType == 1) {
      if (this.popupUserInfo.username) {
        this.userService
          .resetPasswordWithOTP(this.popupUserInfo.username)
          .subscribe((data) => {
            if (data.status == "success") {
              this.sharedService.pushToaster(
                "success",
                "Success",
                "OTP Sent to your mobile number."
              ); // Please enter the OTP to reset your password
              this.SharedPopupService.dismiss();
            } else {
              this.sharedService.pushToaster("error", "Failure", data.message);
            }
          });
      }
    } else if (this.resetPasswordType == 2) {
      if (this.popupUserInfo.user_id) {
        this.userService
          .resetPasswordWithEmail(this.popupUserInfo.user_id)
          .subscribe((data) => {
            if (data.status == "success") {
              this.sharedService.pushToaster(
                "success",
                "Success",
                "Check your registered email to reset your password."
              ); // Please enter the OTP to reset your password
              this.SharedPopupService.dismiss();
            } else {
              this.sharedService.pushToaster("error", "Failure", data.message);
            }
          });
      }
    } else if (this.resetPasswordType == 3) {
      let strongRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
      );
      let password = this.tempPassword;

      if (this.tempPassword == this.tempConfirmPassword) {
        if (password.match(strongRegex)) {
          let data = {
            userId: this.popupUserInfo._id,
            newpassword: this.tempPassword,
          };

          this.userService.setTemporaryPassword(data).subscribe((data) => {
            if (data.status == "success") {
              this.sharedService.pushToaster(
                "success",
                "Success",
                "Temporary password sent to your registered email."
              );
              this.SharedPopupService.dismiss();
            } else {
              this.sharedService.pushToaster("error", "Failure", data.message);
            }
          });
        } else {
          this.sharedService.pushToaster(
            "error",
            "Failure",
            "Password should be 8 characters with 1 lowercase, 1 uppercase, 1 special character, 1 numeric"
          );
        }
      } else {
        this.sharedService.pushToaster(
          "error",
          "Failure",
          "Password does not match. please try again"
        );
      }
    } else if (this.resetPasswordType == 0) {
      this.sharedService.pushToaster(
        "error",
        "Alert",
        "Please select any option to reset your password"
      );
    }
  }

  ngOnInit() {

    this.route.params.subscribe((val: any) => {
      if (val instanceof NavigationStart) {
      }
    });
    this.triggerFollowupService();
    this.country = localStorage.getItem('Region');
  }

  triggerFollowupService(){
    this.headerService.getFollowupTask().subscribe(res => {
      if (res.status == 'success') {
      this.followupcount = res.count;
      this.followupIds = res.result;
      } else {
        this.followupIds = [];
        this.followupcount = 0;
      }
    });
  }
  
  changeTrigger() {
    this.currentUrl = this.routers.url;
    if (this.routers.url.indexOf('/dashboard') > -1 || this.routers.url.indexOf('/task') > -1) {
      this.headerService.userPracticeAccount('', 500).subscribe(data => {
        if (data.status == 'success') {
          this.practiceList = [];
          this.practiceList = data.results;
          if (localStorage.getItem('practice_id') && localStorage.getItem('practice_name') && localStorage.getItem('enterprise_mode') == 'false') {
            this.praciceClicked = true;
          } else {
            this.praciceClicked = false;
          }
          if (this.practiceList.length == 1) {
            this.dataHelperService.setItem('practice_id', this.practiceList['0'].practice_id);
            this.dataHelperService.setItem('practice_name', this.practiceList['0'].organization_name);
            this.viewSelection('selected');
          } else if (this.practiceList.length > 1 && localStorage.getItem('practice_id') && localStorage.getItem('practice_name') && localStorage.getItem('enterprise_mode') == 'false') {
            this.viewSelection('selected');
          } else {
            this.viewSelection('full');
          }
        }
      }, err => console.log(err));
    } else {
      this.headerService.userPracticeAccount('', 500).subscribe(data => {
        if (data.status == 'success') {
          this.practiceList = [];
          this.practiceList = data.results;
          if (this.practiceList.length == 1) {
            this.dataHelperService.setItem('practice_id', this.practiceList['0'].practice_id);
            this.dataHelperService.setItem('practice_name', this.practiceList['0'].organization_name);
            this.viewSelection('selected');
          } else if (this.practiceList.length > 1 && localStorage.getItem('practice_id') && localStorage.getItem('practice_name')) {
            this.viewSelection('selected');
          }
        }
      }, err => console.log(err));
    }
  }
  ngAfterViewInit() {

  }
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  resetsession() {
    this.authenticationService.restart();
    this.count = 10;
    this.modalService.dismissAll();
  }

  logout() {
    if (localStorage.getItem('authToken')) {
      this.authenticationService.clearToken().subscribe(data => {
        this.authenticationService.logout();
      });
    } else {
      this.authenticationService.logout();
    }
  }

  async viewSelection(reason: string) {
    if (['selected', 'practicescreen'].includes(reason)) {
      if(localStorage.getItem('practice_id')){
      localStorage.setItem('enterprise_mode', 'false');
      let reqData = {
        'practice_id': await this.dataHelperService.getItem('practice_id'),
      };
      if(localStorage.getItem('UaeVersion')){
        reqData["version"] = localStorage.getItem('UaeVersion');
      }
      this.routeAuthorizationService.sendMessage(reason);
      // this.headerService.updatePracticetoken(reqData).subscribe(data => {
      //   localStorage.setItem('authToken', data.result);
      //   this.routeAuthorizationService.sendMessage(reason);
      // });
    }else{
      localStorage.setItem('enterprise_mode', 'false');
      // this.routeAuthorizationService.sendMessage(reason);
    }
    } else {
      localStorage.setItem('enterprise_mode', 'true');
      this.routeAuthorizationService.sendMessage(reason);
    }
  }

  onSelected() {
    let reqData = {
      'practice_id': ''
    };
    if(localStorage.getItem('UaeVersion')){
      reqData["version"] = localStorage.getItem('UaeVersion');
    }
    this.headerService.updatePracticetoken(reqData).subscribe(data => {
      localStorage.setItem('authToken', data.result);
      this.viewSelection('full');
    });
  }

  selectedVersion(selectedversion, type){
    let versionData = {
      version : selectedversion
    };
    this.headerService.updateUaetoken(versionData).subscribe(data => {
      localStorage.setItem('UaeVersion',selectedversion);
      this.headerService.uaeVersiontrigger('success');
      if(type!='initial'){
        this.sharedService.pushToaster('success', 'success', 'CPT version updated');
      }
    });
  }

  followupTask() {
    this.dataHelperService.setItem('practice_id', '');
    localStorage.setItem('practice_id', '');
    this.praciceClicked=false;
    this.onSelected();
    this.navparams.setParams({
      task_ids: this.followupIds,
      from : 'followup'
    });
    this.routers.navigate(['task/patient-task-viewid']);
  }
  selectSubworkScope(value:any){
   this.selectedScope.push(value)
   this.selectedScope=Array.from(new Set(this.selectedScope))
  }

  addInfoToTable(value:any,flag)
  {
    let valueExists;
    if(flag==1){
     valueExists =  this.item.SkillSet.PracticeManagementSystems.Systems.some(item => item.Name === value);
    if (valueExists) {
      this.tempPMS=''
      this.sharedService.pushToaster('warning', 'PMS Name already selected', 'Please select any other PMS Name');
    } 
  }else if(flag==2){
    let valueExists =  this.item.Specialties.SpecialtiesList.some(item => item.Name === value);
    if (valueExists) {
      this.tempSpec=''
      this.sharedService.pushToaster('warning', 'Specialties already selected', 'Please select any other Specialties');
    } 
  }else{
    
    let valueExists =  this.item.SkillSet.ClientProtocolProficiency.Levels.some(item => item.Name === value);
    if (valueExists) {
      this.tempPractice=''
      this.sharedService.pushToaster('warning', 'Practice already selected', 'Please select any other Practice');
    } 
  }
  }
  addPMSandSpecValue(flag){
    if(flag==1){
   if(this.tempPMS!=''){
    this.item.SkillSet.PracticeManagementSystems.Systems.push({
       Name:this.tempPMS,
       ProficiencyLevels:"No Experience"
     })
       this.tempPMS=''
    }else{
       this.sharedService.pushToaster('warning', 'No PMS selected', 'Please select the PMS');
    }
  }else if(flag==2){
    if(this.tempSpec!=''){
      this.item.Specialties.SpecialtiesList.push({
         Name:this.tempSpec,
         ProficiencyLevels:"No Experience"
       })
       this.tempSpec=''
      }else{
         this.sharedService.pushToaster('warning', 'No Specialties selected', 'Please select the Specialties');
      }
  }else{
    if(this.tempPractice!=''){
    this.item.SkillSet.ClientProtocolProficiency.Levels.push({
      Name:this.tempPractice,
      ProficiencyLevels:"No Experience"
    })
    this.tempPractice=''
   }else{
      this.sharedService.pushToaster('warning', 'No Practice selected', 'Please select the Practice');
   }
  }
  }
  removeValue(value,flag){
    if(flag==1) {
      this.item.SkillSet.PracticeManagementSystems.Systems = this.item.SkillSet.PracticeManagementSystems.Systems.filter(item => item.Name !== value);
    } else if(flag==2){
      this.item.Specialties.SpecialtiesList = this.item.Specialties.SpecialtiesList.filter(item => item.Name !== value);
      }else{
        this.item.SkillSet.ClientProtocolProficiency.Levels = this.item.SkillSet.ClientProtocolProficiency.Levels.filter(item => item.Name !== value);
      }
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
    this.routeAuthorizationService.clearMessages();
    this.subscription.unsubscribe();
  }
}

interface Brand {
  'full': string;
  'logo': string;
  'title': string;
  'alt': string;
}
