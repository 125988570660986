import { Component, OnDestroy, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { conformToMask } from 'angular2-text-mask';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { SharedPopupService } from '../../../_services/popup.service';
import { DataHelperService } from '../../../_services/auth/data-helper.service';
import { HeaderService } from '../../../_services/header/header.service';
import { PracticesService } from '../../../_services/settings/practices.service';
import { SharedService } from '../../../_services/shared.service';
import { TaskService } from '../../../_services/task/task.service';
import constants from '../../../constants';




class FacilityData {
  npi: any;
  ein: any;
  exId: any;
  facilityName: string;
  nickName: string;
  pos: any;
  cliaNumber: any;
  street1: string;
  street2: string;
  city: any;
  state: any;
  zipcode: any;
  phone: any;
  fax: any;
  extCode: string;
  extId: string;
  extName: string;
  orgUuid: string;
  facId: string;
  noteType: any;
  documentCategory: any;
}

@Component({
  selector: 'app-facility-details',
  templateUrl: './facility-details.component.html',
  styleUrls: ['./facility-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FacilityDetailsComponent implements OnInit, OnDestroy {
  constants:any;
  loaderIndicator: boolean;
  constructor(
    private practicesService: PracticesService,
    private DataHelper: DataHelperService,
    public bsModalService: BsModalService,
    public SharedPopupService: SharedPopupService,
    public taskService: TaskService,
    private sharedService: SharedService,
    private headerService: HeaderService) {
    this.practiceFacilities = [];
    this.flagIndicator = false;
    this.stateSuccessResult = '';
    this.state_array_item = [];
    this.successResult = '';
    this.pos_array_item = [];
    this.pccNoteType = [];
    this.pccDocumentCategory = [];
    this.sameAsPractice = false;
    this.sameAsPracticeFacility = false;
    this.searchValue = '';
    this.showInactive = false;
    this.pageskip = 0;
    this.paginationCounts = 0;
    this.paginationCountV2 = 0;
    this.existingMappedFacilities = [];
    this.enabledFacilitesForProvider = [];
    this.groupedFacilities = [];
    this.searchKeyWordFacility = '';
    this.practiceIdFlag = true;
    this.isValidFacilityForm = false;
    this.pagelimit = 10;
    this.page = 0;
    this.inactivePageBool = false;
    this.constants=constants;
    this.loaderIndicator=true;
    this.modelOpen = false;
    this.currentPracticeInfo;


    if (localStorage.getItem('practice_id')) {
      this.selectedPracticeVal = this.DataHelper.getItem('practice_id');
      this.searchListFacilities('');
      this.practiceIdFlag = false;
      this.searchValue = '';
      this.popUpinfo = '';
      // this.userSelectedText = '';

    }
    this.subscription1$ = this.headerService.practiceEmitted$.subscribe(newPractice => {
      this.selectedPracticeVal = newPractice;
      this.practiceIdFlag = false;
      this.pageskip = 0;
      this.currentPage = 1;
      this.currentPageV2 = 1;
      this.showInactive = false;
      this.searchValue = '';
      this.searchListFacilities('');
    });
  }
  subscription1$: Subscription;
  selectedPracticeVal: string;
  practiceFacilities: any;
  practiceIdFlag: boolean;
  popUpfrom: any;
  popUpinfo: any;
  selectedFacilityIndex: number;
  stateSuccessResult: string;
  facilityPopupTitle: string;
  public facilityData: FacilityData;

  public state_array_item: Array<{ id: string, text: string }>;
  public pos_array_item: Array<{ id: string, text: string }>;
  public pccNoteType: Array<{ id: string, text: string }>;
  public pccDocumentCategory: Array<{ id: string, text: string }>;
  viewFacilityData: any;
  successResult: string;
  selectedFacilityId: any;
  public zipCodeFive = [/\d/, /\d/, /\d/, /\d/, /\d/];
  public zipCodeNine = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  sameAsPractice: boolean;
  sameAsPracticeFacility: boolean;
  maskValidation: boolean;
  facilityDataObj: any;
  facilityErrMessage: string;
  selectedposCode: any;
  searchValue: any;
  showInactive: boolean;
  pagelimit: number;
  pageskip: any;
  currentPracticeAddressFacilities: any;
  paginationCounts: number;
  paginationCountV2:number;
  pageCount: any;
  page: number;
  selectedPracticeUserObject: any;
  facilityIdex: any;
  messageInfo: String;
  confirmPopupFrom: any;
  existingMappedFacilities: any[];
  practiceFacilitiesData: any;
  enabledFacilitesForProvider: any[];
  currentPracticeInfo : any;
  groupedFacilities: any;
  timeout = null;
  searchKeyWordFacility: String;
  isValidFacilityForm: boolean;
  currentPage: number;
  currentPageV2: number;
  inactivePageBool: boolean;
  flagIndicator: boolean;
  noteTypeEdit: boolean;
  documentTypeEdit: boolean;
  npiFailureMessage: string;
  public einMask = [/[1-9]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  modelOpen: boolean;
  ngOnInit() {
    this.pageskip = 0;
    this.taskService.initialStateList();
    this.npiFailureMessage='';
  }
  ngOnDestroy() {
    this.subscription1$.unsubscribe();
    this.SharedPopupService.dismiss();
  }
  /*initialStateList(){
    this.taskService.listStateNames().subscribe(result => {
      if (result.status == 'success') {
        this.taskService.stateList=[];
        result.results.forEach(element => {
          this.taskService.stateList.push({"state_code" : element.state_code});
        });
      }
    });
  }*/

  checkDigit(event: any) {
    let pattern = /\d/g;
    let checkIsNumericKey = event.key.match(pattern);
    if (checkIsNumericKey != null) {
      let conformedNumber = conformToMask(
        event.key,
        this.einMask,
        { guide: false }
      );
      this.maskValidation = conformedNumber.meta.someCharsRejected;
    }
  }
  zipCodeMask(): any {
    return {
      mask: (value) => {
        if (value.length <= 5) {
          return this.zipCodeFive;
        } else {
          return this.zipCodeNine;
        }
      },
      guide: false
    };
  }

  onpageChanged(ev) {
    if(this.modelOpen == false){
      this.page = ev.page * this.pagelimit - this.pagelimit;
      this.searchListFacilities('pagination');
      this.inactivePageBool = false;
    } else {
      this.page = ev == 0 ? 0 : (ev.page * this.pagelimit - this.pagelimit) ;
      this.mapProviderToFacilitiesv2();
    }
  }

  searchListFacilities(from: string, actionType = '') {
    this.loaderIndicator=true;
    let newactive = (this.showInactive == true) ? 'false' : 'true';
    if (from != 'pagination' || this.inactivePageBool == true) {
      this.page = 0;
      this.currentPage = 1;
    }
    let loadPage = {
      pagesize: this.pagelimit,
      offset: this.page
    };
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
      this.practicesService.listSearchFacilities(this.selectedPracticeVal, this.searchValue.trim(), newactive, loadPage).subscribe(data => {
       this.loaderIndicator=false;
        if (data.status == 'success') {
          this.currentPracticeInfo = data.practiceInfo;
          this.practiceFacilities = data.results;
          if (this.practiceFacilities.length > 0) {
            this.practiceIdFlag = false;
          }
          if (this.searchValue && this.practiceFacilities.length == 0) {
            this.practiceIdFlag = false;
          }
          if (data.current_practice && data.current_practice.practice_address && data.current_practice.practice_address[0]) {
            this.currentPracticeAddressFacilities = data.current_practice.practice_address[0];
          } else {

          }
          this.paginationCounts = data.totalCount;
          this.pageskip = this.page;

          if (data.results.length == 0 && actionType == 'deactivated') {
            // this.pagelimit=this.pagelimit;
            if (this.page != 0) {
              this.page = this.page - this.pagelimit;
              this.searchListFacilities('pagination');
            }
          }
        } else {
        // this.loaderIndicator=false;
          this.practiceIdFlag = false;
        }
      });
    }, 700);

  }
  openFacilityModal(facilityTemplate: TemplateRef<any>, from, index, infoData) {
    this.npiFailureMessage='';
    this.popUpfrom = from;
    this.selectedFacilityIndex = index;
    this.popUpinfo = infoData;
    if (from == 'addFacility') {
      this.stateSuccessResult = 'success';
      this.facilityPopupTitle = 'Add new Facility';
      if (this.practiceFacilities.length == 0) {
        this.practicesService.getCurrentPracticeDetails(this.selectedPracticeVal).subscribe(practice => {
          if (practice.length > 0) {
            this.currentPracticeAddressFacilities = practice[0].address;
          } else {
            this.currentPracticeAddressFacilities = [];
          }
        });
      }
      this.facilityData = {
        'npi': '',
        'ein': '',
        'exId': '',
        'facilityName': '',
        'nickName': '',
        'street1': '',
        'street2': '',
        'city': '',
        'state': '',
        'zipcode': '',
        'phone': '',
        'fax': '',
        'cliaNumber': '',
        'pos': '',
        extCode: '',
        extId: '',
        extName: '',
        orgUuid: '',
        facId: '',
        'noteType': '',
        'documentCategory': ''
      };
      this.SharedPopupService.show(facilityTemplate, {
        animated: true,
        backdrop: 'static'
      });
    } else if (from == 'editFacility') {
      if (infoData.address) {
        this.getState(null, infoData.address.state);
      }
      this.practicesService.viewFacility(this.selectedPracticeVal, infoData._id, 'fromFacilitityDetails').subscribe(data => {
        if (data.status == 'success') {
          this.viewFacilityData = data.results[0];
          this.successResult = data.status;
          // tslint:disable-next-line: no-shadowed-variable
          if(this.viewFacilityData.place_of_service != null){
            this.getPlaceOfService(this.viewFacilityData.place_of_service).then(data => {
              this.selectedFacilityId = infoData._id;
              this.facilityPopupTitle = `Edit Facility for ${this.viewFacilityData.name}`;
              this.facilityData = {
                npi: this.viewFacilityData.npi,
                ein: conformToMask(
                  this.viewFacilityData.ein,
                  this.einMask,
                  { guide: false }
                ).conformedValue,
                exId: this.viewFacilityData.external_ids.ext_locationid,
                facilityName: this.viewFacilityData.name,
                nickName: this.viewFacilityData.short_name,
                street1: this.viewFacilityData.address.street_line_1,
                street2: this.viewFacilityData.address.street_line_2,
                city: this.viewFacilityData.address.city,
                state: this.viewFacilityData.address.state,
                zipcode: this.viewFacilityData.address.zip,
                phone: this.viewFacilityData.phone,
                fax: this.viewFacilityData.fax,
                cliaNumber: this.viewFacilityData.clia_number,
                pos: this.viewFacilityData.place_of_service,
                extCode: this.viewFacilityData.external_ids.ext_code,
                extId: this.viewFacilityData.external_ids.ext_id,
                extName: this.viewFacilityData.external_ids.ext_name,
                orgUuid: this.viewFacilityData.external_ids.pointclickcare_orgUuid,
                facId: this.viewFacilityData.external_ids.pointclickcare_id,
                noteType: this.viewFacilityData.external_ids.noteType,
                documentCategory: this.viewFacilityData.external_ids.documentCategory
              };
              if (this.facilityData.zipcode) {
                this.zipCodeMask();
              }
              this.SharedPopupService.show(facilityTemplate, {
                animated: true,
                backdrop: 'static'
              });
            });
          }
          

          if(this.viewFacilityData && this.viewFacilityData.external_ids && this.viewFacilityData.external_ids.pointclickcare_orgUuid && this.viewFacilityData.external_ids.pointclickcare_id){
            this.practicesService.getPCCDetails(this.viewFacilityData.external_ids.pointclickcare_orgUuid, this.viewFacilityData.external_ids.pointclickcare_id).subscribe(data => {
              if (data.status == 'success') {
                this.noteTypeEdit = true;
                this.documentTypeEdit = true;
                for (let i in data.results[0].noteTypes) {
                  this.pccNoteType[i] = {
                    id: data.results[0].noteTypes[i].noteType,
                    text: data.results[0].noteTypes[i].noteType
                  };
                }
                if(data.results[0].pickList && data.results[0].pickList.documentCategories){
                  for (let i in data.results[0].pickList.documentCategories) {
                    this.pccDocumentCategory[i] = {
                      id: data.results[0].pickList.documentCategories[i].id,
                      text: data.results[0].pickList.documentCategories[i].id
                    };
                  }
                }
              } else {
                this.noteTypeEdit = false;
                this.documentTypeEdit = false;
                this.pccNoteType = [];
                this.pccDocumentCategory = [];
              }
            });
          }else{
            this.noteTypeEdit = false;
            this.documentTypeEdit = false;
            this.pccNoteType = [];
            this.pccDocumentCategory = [];
          }
        }
      });
    }
  }
  
  facilityModalClose() {
    this.sameAsPractice = false;
    this.sameAsPracticeFacility = false;
    this.maskValidation = false;
    this.SharedPopupService.dismiss();
    // this.alreadyExistFlag = false
  }

  /*getState(practiceId, searchKey) {
    return new Promise((resolve, reject) => {
      this.taskService.listStateNames().subscribe(result => {
        if (result.status == 'success') {
          this.stateSuccessResult = result.status;
          for (let i in result.results) {

            this.state_array_item[i] = {
              id: result.results[i].state_code,
              text: result.results[i].state_code
            };
          }
          resolve();
        }
      });
    });
  }*/

  getState(practiceId, searchKey) {
    let filtered_data = [];
    let stateval = this.taskService.stateList;
    filtered_data = this.taskService.filterByValue(stateval, searchKey);

    for (let i in filtered_data) {
      if (parseInt(i) < 5) {
        this.stateSuccessResult = 'success';
        this.state_array_item[i] = {
          id: filtered_data[i].state_code,
          text: filtered_data[i].state_code
        };
      }
    }
  }

  getPlaceOfService(searchKey) {
    return new Promise<void>((resolve, reject) => {
      this.practicesService.searchPlaceOfService(searchKey).subscribe(data => {
        if (data.status == 'success') {
          for (let i in data.results) {
            this.pos_array_item[i] = {
              id: data.results[i].code,
              text: `${data.results[i].code} - ${data.results[i].place_of_service}`
            };
          }
          resolve();
        }
      });
    });

  }
  posSelectedValue(selectedPos, facilityDataForm) {
    this.selectedposCode = selectedPos;
  }
  addEditFacility(model: FacilityData, isValid: boolean, mynform: NgForm) {
    this.isValidFacilityForm = isValid;
    if (isValid == true) {
      let temp = model.ein.replace(/-/g, '');
      model.ein = temp;
      if (model.zipcode) {
        let tempZip = model.zipcode.split('-');
        model.zipcode = tempZip.join('');
      }
      if (this.popUpfrom == 'addFacility') {
        this.facilityDataObj = {
          'newfacility': {
            'npi': model.npi,
            'ein': model.ein,
            'extid': model.exId,
            'name': model.facilityName,
            'nickname': model.nickName,
            'street1': model.street1,
            'street2': model.street2,
            'city': model.city,
            'state': model.state,
            'zip': model.zipcode,
            'phone': '',
            'fax': '',
            'clia': model.cliaNumber,
            'pos': model.pos,
            extCode: model.extCode,
            extId: model.extId,
            extName: model.extName,
            orgUuid: model.orgUuid,
            facId: model.facId
          }
        };

        this.practicesService.addFacilityService(this.selectedPracticeVal, this.facilityDataObj, 'fromFacilityDetails').subscribe(data => {
          if (data.status == 'success') {
            let addresult = data.results;
            let newdata = {
              '_id': addresult._id,
              'npi': addresult.npi,
              'ein': addresult.ein,
              'extid': model.exId || '',
              'fax': addresult.fax || '',
              'name': addresult.name,
              'nickname': model.nickName || '',
              'street1': addresult.address.street_line_1,
              'street2': addresult.address.street_line_2 ? addresult.address.street_line_2 : '',
              'city': addresult.address.city,
              'state': addresult.address.state,
              'zip': addresult.address.zipcode,
              'phone': addresult.phone ? addresult.phone : '',
              'enabled': addresult.enabled,
              'clia': model.cliaNumber ? model.cliaNumber : '',
              'pos': model.pos,
              'address': {
                'street_line_1': addresult.address.street_line_1,
                'street_line_2': addresult.address.street_line_2,
                'city': addresult.address.city,
                'state': addresult.address.state,
                'zip': addresult.address.zip
              },
              extCode: addresult.external_ids.ext_code,
              extId: addresult.external_ids.ext_id,
              extName: addresult.external_ids.ext_name,
              orgUuid: addresult.external_ids.pointclickcare_orgUuid,
              facId: addresult.external_ids.pointclickcare_id
            };
            this.practiceFacilities.unshift(newdata);
            this.paginationCounts = this.paginationCounts + 1;
            if (this.practiceFacilities.length == (this.pagelimit + 1)) {
              this.practiceFacilities.pop();
            }
            // mynform.resetForm();
            // this.searchListFacilities('');
            this.sharedService.pushToaster('success', 'Success', 'New facility account created successfully.');
            this.facilityModalClose();
          } else {
            this.facilityErrMessage = data.message;
            this.sharedService.pushToaster('error', 'Error', this.facilityErrMessage);
            // mynform.resetForm();
            // this.facilityModalClose();
          }
        });
      } else if (this.popUpfrom == 'editFacility') {
        this.facilityDataObj = {
          'editfacility': {
            'npi': model.npi,
            'ein': model.ein,
            'extid': model.exId,
            'name': model.facilityName,
            'nickname': model.nickName,
            'street1': model.street1,
            'street2': model.street2,
            'city': model.city,
            'state': model.state,
            'zip': model.zipcode,
            'phone': '',
            'fax': '',
            'clia': model.cliaNumber,
            'pos': model.pos,
            extCode: model.extCode,
            extId: model.extId,
            extName: model.extName,
            orgUuid: model.orgUuid,
            facId: model.facId,
            noteType: model.noteType,
            documentCategory: model.documentCategory
          }
        };
        // tslint:disable-next-line: no-unused-expression
        this.sameAsPractice == false;
        this.practicesService.editFacility(this.selectedPracticeVal, this.selectedFacilityId, this.facilityDataObj, 'fromFacilityDetails').subscribe(data => {
          if (data.status == 'success') {

            this.searchListFacilities('');
            // mynform.resetForm();
            this.facilityModalClose();
            this.sharedService.pushToaster('success', 'Updated', 'Facility account updated successfully.');
          } else {
            this.facilityErrMessage = data.message;
            this.sharedService.pushToaster('error', 'Error', this.facilityErrMessage);
            // mynform.resetForm();
            // this.facilityModalClose();
          }
        });

      } else {
        // console.log('not met the conditions')
      }
    }

  }

  inactive() {
    this.showInactive = this.showInactive ? false : true;
    this.inactivePageBool = true;
    this.page = 0;
    this.currentPage = 1;
    this.searchListFacilities('');
    this.searchValue = '';
  }
  confirmPopup(accountConfirmPopup: TemplateRef<any>, status: Boolean, practiceSelectedData, type, index) {
    this.selectedPracticeUserObject = practiceSelectedData;
    // this.parentIndexValue = index;
    this.facilityIdex = index;
    if (type == 'deactivatefacilities') {
      this.messageInfo = (status == true) ? 'Are you sure to deactivate Facility ?' : 'Are you sure to Activate Facility ?';
    }
    if (type == 'removePccIntegration') {
      this.messageInfo = 'Are you sure to Remove PCC Integration with this facility ?';
      this.practiceFacilities[index].external_ids.pointclickcare_orgUuid = '';
      this.practiceFacilities[index].external_ids.pointclickcare_id = '';
    }
    this.confirmPopupFrom = type;
    this.SharedPopupService.show(accountConfirmPopup, {
      animated: true,
      backdrop: 'static'
    });
  }

  onConfirm() {
    if (this.confirmPopupFrom == 'deactivatefacilities') {
      this.facilityStatus(this.selectedPracticeUserObject);
    }
    if (this.confirmPopupFrom == 'removePccIntegration') {
      this.removeIntegration(this.selectedPracticeUserObject);
    }

    this.SharedPopupService.dismiss();
  }

  removeIntegration(data) {
    let facilityid = data._id;
    // tslint:disable-next-line: no-shadowed-variable
    this.practicesService.removePccIntegration(facilityid).subscribe(data => {
      if (data.status == 'success') {
        this.sharedService.pushToaster('success', 'Success', 'Facility status updated successfully');
      }
    });
  }
  facilityStatus(data) {
    let facilityid = data._id;
    let facilityStatusData = {
      'status': (data.enabled == true) ? false : true
    };
    // tslint:disable-next-line: no-shadowed-variable
    this.practicesService.facilitiesStatusChange(facilityid, this.selectedPracticeVal, facilityStatusData, 'fromFacilityDetails').subscribe(data => {
      if (data.status == 'success') {
        this.searchListFacilities('pagination', 'deactivated');
        this.practiceFacilities[this.facilityIdex].enabled = facilityStatusData.status;
        this.sharedService.pushToaster('success', 'Success', 'Facility status updated successfully');
      }
    });
  }
  openProviderFacilityMappingModal(template: TemplateRef<any>, from, infoData, index) {
    this.loaderIndicator=true;
    this.popUpfrom = from;
    this.modelOpen = true;
    this.popUpinfo = infoData;

    if (from == 'mapProviderToFacilities') {
      this.existingMappedFacilities = [];
      this.practiceFacilitiesData = [];

      // this.practicesService.fetchEnabledProvidersForFacility(this.popUpinfo.practice_id, this.popUpinfo._id, '', false, 'fromFacilityDetails').subscribe(result => {
      //   if (result.status == 'success') {
      //     this.existingMappedFacilities = result.results;
      //   }
      // },
      //   err => 'error',
      //   () => {
      //     this.practicesService.searchProvider(this.popUpinfo.practice_id, '').subscribe(result => {
      //       this.loaderIndicator=false;
      //       if (result.status == 'success') {
      //         this.practiceFacilitiesData = result.results;
      //         this.groupProviders();
      //         this.defaultSelectionFacilitieProviders();
      //       }
      //     });
      //   });
      this.mapProviderToFacilitiesv2();
    }
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }

  mapProviderToFacilitiesv2(){
    this.loaderIndicator = true;
    this.practicesService.mapProvidersV2(this.popUpinfo._id, this.searchKeyWordFacility,  this.pagelimit , this.page).subscribe(result => {
      if (result.status == 'success') {
        this.loaderIndicator=false;
        this.existingMappedFacilities = result.results;
        this.practiceFacilitiesData = result.results;
        this.paginationCountV2 = result['totalCount'];
        this.groupProviders();
        this.defaultSelectionFacilitieProviders();
        this.existRecordFound('mapProviderToFacilities');
      }
    });
  }
  groupProviders() {
    let groupedFacilities: any = {};
    this.practiceFacilitiesData.forEach(element => {
      let c = this.existingMappedFacilities.find(x => x._id == element._id);
      if (!groupedFacilities[element.last_name + element.middle_name + element.first_name]) {
        groupedFacilities[element.last_name + element.middle_name + element.first_name] = {
          place_of_service_name: element.last_name + element.middle_name + element.first_name,
          facilities: []
        };
      }

      if (c == undefined || c.enabled == true) {
        element.enabled = true;
      } else {
        element.enabled = false;
      }
      groupedFacilities[element.last_name + element.middle_name + element.first_name].facilities.push(element);
    });
    let temp = [];
    for (let tmp in groupedFacilities) {
      temp.push(groupedFacilities[tmp]);
    }
    this.groupedFacilities = temp;
  }

  defaultSelectionFacilitieProviders() {
    this.practiceFacilitiesData.forEach(element => {
      let flagResult = this.existingMappedFacilities.find(tmp => element._id == tmp._id);
      if (flagResult == undefined || flagResult.enabled == true) {
        element.enabled = true;
      } else if (flagResult.enabled == false) {
        element.enabled = false;
      }
    });
  }

  mapNewFacilityToProvider(x: String) {

    if (x == 'mapProviderToFacilities') {
      let mappedData = JSON.parse(JSON.stringify(this.groupedFacilities));
      let finalmappedData = [];
      mappedData.forEach(element => {
        element.facilities.forEach(elem => {
          let selectedFacility = {
            'practice_id': this.popUpinfo.practice_id,
            'facility_id': this.popUpinfo._id,
            'provider_id': elem._id,
            'enabled': elem.enabled
          };
          finalmappedData.push(selectedFacility);
        });
      });
      this.practicesService.bulkProvidersMapToFacility(this.popUpinfo.practice_id, this.popUpinfo._id, finalmappedData).subscribe(_data => {
        this.modalClose();
        this.timeout = setTimeout(_x => {
          this.sharedService.pushToaster('success', 'Success', 'Providers mapped to Facility successfully.');
        }, 1000);
      }, _err => {
        this.modalClose();
        this.sharedService.pushToaster('error', 'Failure', 'Bulk Providers mapping failed.');
      });
    }
  }

  mapProvider(providerData) {
    if(providerData.enabled) {
    this.practicesService.addProvidersMapToFacility(this.popUpinfo._id, providerData._id).subscribe(data => {
    if(data.status == "success") {
    this.sharedService.pushToaster('success', 'Success', 'Providers mapped to Facility successfully.');
  } else {
    this.sharedService.pushToaster('error','Error', 'Providers mapping failed');
  }
  });
  }
  else {
    this.practicesService.removeProviderMapToFacility(this.popUpinfo._id,providerData._id).subscribe(data => {
        if(data.status == "success") {
        this.sharedService.pushToaster('success', 'Success', 'Providers removed successfully.')
        }
      });
    }
  }

  modalClose() {
    this.searchKeyWordFacility = '';
    this.sameAsPractice = false;
    this.sameAsPracticeFacility = false;
    this.SharedPopupService.dismiss();
    this.currentPageV2 = 1;
    this.modelOpen =  false;
    this.npiFailureMessage='';
  }
  onCancel() {
    this.SharedPopupService.dismiss();
  }
  npiProviderData(npi) {
    this.npiFailureMessage='';
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
      this.practicesService.npiProviderDataAPI(npi).subscribe(data => {
        if (data.results && data.results.length > 0) {
          if (data.results && data.results.length > 0) {
            let npi_data = data.results[0];
            this.facilityData.facilityName = npi_data.basic.organization_name;
            let npi_address = npi_data.addresses[0];
            if (npi_address) {
              this.getState(null, npi_address.state);
              this.facilityData.street1 = npi_address.address_1;
              this.facilityData.street2 = npi_address.address_2;
              this.facilityData.city = npi_address.city;
              this.facilityData.state = npi_address.state;
              this.facilityData.zipcode = npi_address.postal_code;
            }
          }
        } else {
          // this.facilityData.firstName = '';
          // this.facilityData.lastName = '';
          this.facilityData.facilityName = '';
          // this.taxonomySearch('','','initial');
          this.facilityData.street1 = '';
          this.facilityData.street2 = '';
          this.facilityData.city = '';
          this.facilityData.state = '';
          this.facilityData.zipcode = '';
          this.npiFailureMessage = data.message;
        }
      });
    }, 1100);
  }
  sameAsPracticeAddress(addfacility, editfacility, val) {
    this.stateSuccessResult = '';
    this.sameAsPracticeFacility = val;
    if ((addfacility == 'addfacility' && this.sameAsPractice == true) || (editfacility == 'editfacility' && this.sameAsPracticeFacility == true)) {
      if (this.currentPracticeInfo.address) {
        this.getState(null, this.currentPracticeInfo.address.state);
        this.facilityData.street1 = this.currentPracticeInfo.address.street_line_1;
        this.facilityData.street2 = this.currentPracticeInfo.address.street_line_2;
        this.facilityData.city = this.currentPracticeInfo.address.city;
        this.facilityData.zipcode = this.currentPracticeInfo.address.zip;
        this.facilityData.state = this.currentPracticeInfo.address.state;
      }
    } else if (addfacility == 'addfacility' && this.sameAsPractice == false) {
      this.stateSuccessResult = 'success';
      this.facilityData.street1 = '';
      this.facilityData.street2 = '';
      this.facilityData.city = '';
      this.facilityData.state = '';
      this.facilityData.zipcode = '';
    } else if (editfacility == 'editfacility' && this.sameAsPracticeFacility == false) {
      if (this.viewFacilityData.address) {
        this.getState(null, this.viewFacilityData.address.state);
        this.facilityData.street1 = this.viewFacilityData.address.street_line_1;
        this.facilityData.street2 = this.viewFacilityData.address.street_line_2;
        this.facilityData.city = this.viewFacilityData.address.city;
        this.facilityData.state = this.viewFacilityData.address.state;
        this.facilityData.zipcode = this.viewFacilityData.address.zip;
      }
    }
  }

  existRecordFound(check: string) {
    if (this.searchKeyWordFacility != '') {
      if (check == 'mapProviderToFacilities') {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(x => {
        this.practicesService.mapProvidersV2(this.popUpinfo._id, this.searchKeyWordFacility,  this.pagelimit , this.page).subscribe(result => {
          if (result.status == 'success') {
                this.existingMappedFacilities = result.results;
                this.practiceFacilitiesData = result.results;
                this.paginationCountV2 = result['totalCount'];
                this.groupProviders();
                this.defaultSelectionFacilitieProviders();
                // this.existRecordFound('mapProviderToFacilities');
          }
        });
      },
      500);
        // let find = this.practiceFacilitiesData.filter(provider => {
        //   if (provider.type == 'Individual' && provider.first_name == '' && provider.middle_name == '' && provider.last_name == '' && (provider.short_name && provider.short_name.toLowerCase().includes(this.searchKeyWordFacility.toLowerCase()))) {
        //     return provider;
        //   } else if (provider.type == 'Individual' && (
        //     (provider.middle_name && provider.middle_name.toLowerCase().includes(this.searchKeyWordFacility.toLowerCase())) ||
        //     (provider.first_name && provider.first_name.toLowerCase().includes(this.searchKeyWordFacility.toLowerCase())) ||
        //     (provider.last_name && provider.last_name.toLowerCase().includes(this.searchKeyWordFacility.toLowerCase())))) {
        //     return provider;
        //   } else if (provider.type == 'Organization' && (provider.organization_name && provider.organization_name.toLowerCase().includes(this.searchKeyWordFacility.toLowerCase()))) {
        //     return provider;
        //   } else if (provider.npi && provider.npi.toString().toLowerCase().includes(this.searchKeyWordFacility.toLowerCase())) {
        //     return provider;
        //   }
        // });
        // this.flagIndicator = (find.length > 0) ? false : true;
      }
    }
  }

}

