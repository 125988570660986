import { Directive, OnInit, Input, ElementRef } from '@angular/core';
import { DataHelperService } from '../_services/auth/data-helper.service';

@Directive({
  selector: '[attributeDisable]'
})
export class AttributeDisableDirective implements OnInit {
  @Input('attributeDisable') permission: any;
  constructor(private el: ElementRef, private dataHelperService: DataHelperService) {
    // console.log(this.permission)
  }
  ngOnInit() {
    // console.log(this.dataHelperService.getItem('userPermissions'),this.permission);
    let userPermissions = this.dataHelperService.getItem('userPermissions');
    if (userPermissions.indexOf(this.permission) == -1) {
      //console.log("disabled")
      this.el.nativeElement.disabled = true;
    }
  }

}
