import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../_services/auth/authentication.service';
import { DataHelperService } from '../../../_services/auth/data-helper.service';
import { PlatformLocation} from '@angular/common';
import { SharedService } from '../../../_services/shared.service';
import { LoginService } from '../../../_services/login.service';

class MFA_login {
  mfa_otp: any;
}
@Component({
  selector: 'app-mfa-login',
  templateUrl: './mfa-login.component.html',
  styleUrls: ['./mfa-login.component.scss']
})
export class MfaLoginComponent implements OnInit {
  public mfa_Login: MFA_login;
  brand: string;
  logoUrl: string;
  isTemporaryFlag: boolean;
  user_data: any;
  constructor(private routers: Router,
    private authenticationService: AuthenticationService,
    private dataHelperService: DataHelperService,
    private platformLocation:PlatformLocation,
    private sharedService: SharedService,
    public loginService:LoginService

    ) {
      this.loginService.isPasswordExpired = false;
      this.loginService.isPasswordOtp = false;
      this.loginService.loginresponse = '';
      this.brand = this.dataHelperService.getItem('userBrand');
      if(this.platformLocation.hostname.includes('globalhealthcareresource.io') || this.brand=="Global" || this.platformLocation.hostname=='localhost'){
        this.logoUrl='assets/img/ghrfull_logo_no_shadow.webp';
        this.brand = 'Global';
      }
      if(this.platformLocation.hostname.includes('claimocity.io') || this.platformLocation.hostname=='localhost'){
        this.logoUrl='assets/img/Claimocity-logo-dark.png';
        this.brand = 'Claimocity';
      }
    this.mfa_Login = {
      mfa_otp: ''
    };
    this.isTemporaryFlag = false;
    this.user_data = {
      user_id: atob(localStorage.getItem('user_id')),
    }

  }
  ngOnInit(): void {
  }

  mfaLogin(code) {
    // console.log(code)
      let req = {
        "code": code,
        "mfa-token":localStorage.getItem("mfa-token")
      }
      this.authenticationService.verifyOTp(req).subscribe(data => {
        if(data.status == "success") {
          this.loginService.userBasiclogin(data);
          this.sharedService.pushToaster('success', 'Success', 'OTP verified successfully');
          localStorage.removeItem("otp-type");
          localStorage.removeItem("mfa-token");
        } else if(data.message == "invalid otp") {
          this.sharedService.pushToaster('error', 'Failure', 'Invalid OTP');
        } else if(data.message == "otp expired"){
          this.sharedService.pushToaster('error', 'Failure', 'OTP expired, Please try to Resend OTP');
        } else {
          this.sharedService.pushToaster('error', 'Failure', 'Something went wrong');
        }
      });
  }
  onCancel() {
    localStorage.removeItem("otp-type");
    if ( localStorage.getItem('authToken')) {
      this.authenticationService.clearToken().subscribe(data => {
        this.authenticationService.logout();
      });
    } else {
      this.authenticationService.logout();
    }
  }

  mfaResendOtp() {
    let userdata = {
      "mfa-token":localStorage.getItem("mfa-token"),
      "otp_type":localStorage.getItem("otp-type")
    };
    if(userdata.otp_type == "mail") {
      this.authenticationService.generateMilOtp(userdata).subscribe(result =>{
        if(result.status == "success") {
              this.sharedService.pushToaster('success', 'Success', 'Resend OTP successfully');
            } else if(result.message) {
              this.sharedService.pushToaster('error', 'Failure', result.message);
            } else {
              this.sharedService.pushToaster('error', 'Failure', 'Something went wrong');
            }
      });
    } else if(userdata.otp_type == "mobile") {
      localStorage.setItem("otp-type", "mobile");
      this.authenticationService.generateOtp(userdata).subscribe(result =>{
        if(result.status == "success") {
          this.sharedService.pushToaster('success', 'Success', 'Resend OTP successfully');
        } else if(result.message) {
          this.sharedService.pushToaster('error', 'Failure', result.message);
        } else {
          this.sharedService.pushToaster('error', 'Failure', 'Something went wrong');
        }
    });
    }
  }
}