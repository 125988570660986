import { Component, OnInit, SimpleChanges, Input, Output, EventEmitter, ChangeDetectorRef, ViewEncapsulation, TemplateRef, SimpleChange, OnDestroy } from '@angular/core';
import { TaskService } from '../../_services/task/task.service';
import * as _ from 'lodash';
import { DragulaService } from 'ng2-dragula';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { SharedService } from '../../_services/shared.service';
import { PracticesService } from '../../_services/settings/practices.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DiagnosisCodesService } from '../../_services/settings/diagnosis-codes.service';
import { Observable } from 'rxjs/Rx';
import { SharedPopupService } from '../../_services/popup.service';

@Component({
  selector: 'app-charge-data',
  templateUrl: './charge-data.component.html',
  styleUrls: [
    './charge-data.component.scss',
    '../../../scss/vendors/bs-datepicker/bs-datepicker.scss'
  ],
  encapsulation: ViewEncapsulation.None,
  providers: [DragulaService]
})



export class ChargeDataComponent implements OnInit, OnDestroy {
  @Input('Show') show: boolean;
  @Input('taskData') data: any;
  @Input('patient') patient: any;
  @Input('forchild') forchild: any;
  @Input('chargesOnly') chargesOnly: any;
  @Input('voidServiceLine') voidServiceLine: any
  @Input('practiceList') allPractices: any;

  @Output() submit = new EventEmitter();
  @Output() chargeevent = new EventEmitter();

  public dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  // isCollapsed: boolean = true;
  iconCollapse = 'icon-arrow-down';
  showChargeData: any;
  chargeData: any;
  serviceLinesData: any;
  chargeDetails: [];
  diagnosisCodes;
  searchKey: any;
  diagnoCodeArray = [];
  procedureCodeArray = [];
  diaString: any;
  storeDcode = [];
  proString: any;
  storeprocode = [];
  public dCode: string;
  createPatientDemography: any;
  EditPatientCharge: {};
  rendering_provider_fullName: string;
  isCollapse: any;
  togglecollapseVar: any;
  selectedCountries: any;
  diagnosisData: any = [];
  patientDetails: any;
  selectCase: any;
  indexOfclose: any;
  public diagnosiscode_array_item: any = [];
  items: any[];
  msg = '';
  enablesection: boolean;
  initialstoreDecodeClone: any[];
  closemodelval: any;
  diagnosiscode_array: any = [];
  allprocedureCode: any;
  procedureCode_array_item: any = [];
  diagnosiscode_array_item_last: any[];
  procedureCode_array_item_init: any = [];
  diagnosisCodesTmp: any;
  subs = new Subscription();
  reset_last_code: boolean;
  serviceDate: any;
  isDatePicker: boolean;
  diag_prev: string;
  isCodeExists: boolean;
  taskData: any;
  dataUpdates: any;
  facilities: any;
  valideerrordosto = [];
  valideerrordosfrom = [];
  datevalidateerror: boolean;
  tempFlag: boolean;
  diagnosis_pointer_hasError: boolean;
  procedure_modifier_hasError: boolean;
  allIncidentProviders: any;
  chargeStatusTransition: any;
  enableSaveButton: boolean;
  selectStatus: any;
  global_data: any;
  status_arr: any;
  isclicked: boolean;
  selected_incident_provider: any;
  incident_to_billing: any;
  timeout = null;
  void_array: any = [];
  providerList: any[];
  facilitiesList: any[];
  defaultProvider: any;
  defaultFacility: any;
  selectedPractice: any;
  dynamicValue:any;
  country:any;
  // serviceFromDate:any;
  // serviceToDate:any;
  // public editChargeData: EditChargeData

  constructor(public taskService: TaskService,
    private dragula: DragulaService,
    private daterangepickerConfig: DaterangepickerConfig,
    private sharedService: SharedService,
    private cdRef: ChangeDetectorRef,
    private practicesService: PracticesService,
    public modalService: BsModalService,
    public SharedPopupService: SharedPopupService,
    private diagnosisCodesService: DiagnosisCodesService
  ) {
    this.isclicked = false;
    this.isCollapse = {};
    this.togglecollapseVar = '';
    // this.valideerrordosto[0]=true
    // this.valideerrordosto[1]=true
    // this.valideerrordosto[2]=true
    // this.valideerrordosto[3]=true
    // this.valideerrordosto[4]=true
    // this.valideerrordosto[5]=true
    // this.dragulaDragging();
    // this.subs.add(this.dragula.drag("diag-items").subscribe(({ name, el, source }) => {
    //   // ...
    //  })
    // );
    // dragula.createGroup("diag-items", {
    //   revertOnSpill: false,
    //   moves: function (el, source, handle, sibling) {
    //     return true; // elements are always draggable by default
    //   },
    //   accepts: function (el, target, source, sibling) {
    //     return true; // elements can be dropped in any of the `containers` by default
    //   },
    //   invalid: function (el, handle) {
    //     return false; // don't prevent any drags from initiating by default
    //   },
    // });
    this.selectedPractice = '';
    this.providerList = [];
    this.facilitiesList = [];
    this.defaultFacility = '';
    this.defaultProvider = '';
    this.diag_prev = '';
    this.isCodeExists = false;
    this.diagnosiscode_array_item_last = [];
    this.diagnosisCodesTmp = '';
    this.reset_last_code = true;
    this.dragulaDragging();
    this.isDatePicker = false;
    this.dataUpdates = {};
    this.facilities = [];
    this.tempFlag = false;
    this.allIncidentProviders = [];
    this.selected_incident_provider = '';
    this.incident_to_billing = '';
  }
  loadProvider(practice_id) {
    let totalProviderList = [];
    if (this.chargesOnly != "chargesOnly") {
      this.practicesService.searchProvider(practice_id, '', 'true').subscribe((data: any) => {
        let activeProvider = '';
        if (data.status = 'success') {
          let res = data.results;
          if (res) {
            res.forEach((val, i) => {
              if (this.taskData.charge.rendering_provider_id == val._id) {
                activeProvider = val._id;
              }
              totalProviderList[i] = { value: val._id, label: `${val.last_name},${val.middle_name} ${val.first_name}` };
            });
          }
          this.taskData.charge.rendering_provider_id = activeProvider;
          this.taskData.charge['totalProviderList'] = totalProviderList;
          this.taskData.charge_info[0]['totalProviderList'] = totalProviderList;
        }
      });
    } else {
      if (this.allPractices.length > 0) {
        // let initialval = {
        //   practice_id: 'initial',
        //   organization_name: '---select practice--'
        // }
        // this.allPractices.unshift(initialval)
        this.selectedPractice = this.allPractices[0].practice_id
      }
      if (this.taskData.charge.rendering_provider) {
        this.defaultProvider = this.taskData.charge.rendering_provider;
        let tempName = this.taskData.charge.rendering_provider.organization_name ? this.taskData.charge.rendering_provider.organization_name : `${this.taskData.charge.rendering_provider.last_name},${this.taskData.charge.rendering_provider.middle_name} ${this.taskData.charge.rendering_provider.first_name}`;
        totalProviderList[0] = {
          value: tempName,
          label: tempName,
        }
        this.taskData.charge.rendering_provider_id = tempName;
        this.taskData.charge['totalProviderList'] = totalProviderList;

      }
    }

  }
  loadFacilities(practice_id) {
    let totalLocationList = [];
    if (this.chargesOnly != "chargesOnly") {
      this.practicesService.searchPracticeFacilities(practice_id).subscribe((data: any) => {
        let activeLocation = '';
        if (data.status = 'success') {
          let res = data.results;
          if (res) {
            this.facilities = res;
            res.forEach((val, i) => {
              if (this.taskData.charge.facility_id == val._id) {
                activeLocation = val._id;
              }
              totalLocationList[i] = { value: val._id, label: val.name };
            });
          }
          this.taskData.charge.facility_id = activeLocation;
          this.taskData.charge['totalLocationList'] = totalLocationList;
          this.taskData.charge_info[0]['totalLocationList'] = totalLocationList;
        }
      });
    } else {
      if (this.taskData.charge.service_facility) {
        this.defaultFacility = this.taskData.charge.service_facility;
        let tempName = this.taskData.charge.service_facility.name
        totalLocationList[0] = {
          value: tempName,
          label: tempName,
        }
        this.taskData.charge.facility_id = tempName;
        this.taskData.charge['totalLocationList'] = totalLocationList;
      }
    }

  }

  public ngDoCheck() {
    this.cdRef.detectChanges();
  }
  dragulaDragging() {
    let bag: any = this.dragula.find('bag-items');
    if (bag !== undefined) {
      this.dragula.destroy('bag-items');
    }

    //this.dragula.setOptions
    this.dragula.createGroup('diag-items', {
      ignoreInputTextSelection: true,
      //   accepts: function (el, target, source, sibling) {
      //     if (el.classList.contains('no-drag')) {
      //       return false;
      //     }else{
      //       return true;
      //     }

      //     return target == source; // elements can be dropped in any of the `containers` by default

      //   }

    });
  }

  initialProcess() {
    if (this.data) {
      this.taskData = JSON.parse(JSON.stringify(this.data));
      this.selectCase = this.taskData.charge.selected_case;
      let allIncidentProviders = this.taskData.incident_provider_info;
      let selected_incident_provider = [];
      let enabled_incident_provider_data = [];
      this.incident_to_billing = this.taskData.incident_to_billing;
      enabled_incident_provider_data = this.taskData.incident_to_billing_providers;
      selected_incident_provider = this.taskData.selected_incident_provider;
      this.isCollapse = {};
      this.togglecollapseVar = '';
      this.selectCase = this.taskData.charge.selected_case;

      this.processServiceLines();

      this.processDiagnosis();
      this.loadIncidentProvider(selected_incident_provider, allIncidentProviders);
    }

    // this.loadDiagnosisCode();
  }
  loadIncidentProvider(selected_incident_provider, allIncidentProviders) {
    let active_incident_provider = '';
    let enabled_incident_provider_list = [];
    if (allIncidentProviders && allIncidentProviders.length > 0) {
      allIncidentProviders.forEach(provider => {
        if (provider.incident_to_billing_providers) {

          let isincident = provider.incident_to_billing_providers.some(elem => {
            return (elem._id == this.taskData.provider_id && elem.enabled == true && provider.incident_to_billing == true);
          });
          if (isincident) {
            // {value: element._id, label: `${element.first_name} ${element.last_name} ${element.middle_name} ${element.title}`}
            enabled_incident_provider_list.push({ value: provider._id, label: `${provider.first_name} ${provider.last_name} ${provider.middle_name} ${provider.title}` });
          }
        }
      });
    }



    // if(enabled_incident_provider_data && enabled_incident_provider_data.length > 0) {
    //   allIncidentProviders.forEach((element, i) => {
    //     enabled_incident_provider_data.forEach((incident, j) => {
    //       if(element._id == incident._id && incident.enabled == true) {
    //         incident_provider_list.push({ value: element._id, label: `${element.first_name} ${element.last_name} ${element.middle_name} ${element.title}` });
    //       }
    //       if(element._id == selected_incident_provider && element._id == incident._id && incident.enabled == false) {
    //         incident_provider_list.push({value: element._id, label: `${element.first_name} ${element.last_name} ${element.middle_name} ${element.title}`})
    //       }
    //     });
    //     if(element._id == selected_incident_provider) {
    //       active_incident_provider = element._id;
    //     }
    //     // incident_provider_list[i] = { value: element._id, label: `${element.first_name} ${element.last_name} ${element.middle_name} ${element.title}` };
    //   });
    // }
    // let enabled_incident_provider_list = incident_provider_list.filter(data => {
    //   return data.value != this.taskData.provider_id;
    // });
    enabled_incident_provider_list.unshift({ value: null, label: 'Not set' });
    this.taskData.charge.incident_to_provider_id = selected_incident_provider;
    this.taskData.charge['allIncidentProviders'] = enabled_incident_provider_list;
    if (this.chargesOnly != "chargesOnly") {
      this.taskData.charge_info[0]['allIncidentProviders'] = enabled_incident_provider_list ? enabled_incident_provider_list : [];
    }

  }

  ngOnInit() {
    // Drag & Drop - STEP 4
    //    this.dragula
    //    .drag
    //    .subscribe(value => {
    //      this.msg = `Dragging the ${value[1].innerText}!`;
    //    });

    //  this.dragula
    //    .drop
    //    .subscribe(value => {
    //      this.msg = `Dropped the ${value[1].innerText}!`;

    //      setTimeout(() => {
    //        this.msg = '';
    //      }, 1000);
    //    });
    // Drag & Drop - STEP 4
    this.country = localStorage.getItem('Region');

  }
  ngOnChanges(changes: SimpleChanges) {
    this['valideerrordatefrom'] = true;
    this['valideerrordateto'] = true;

    if (this.data && this.data.charge) {
      // if(this.data.charge.service_lines.length !=0){
      //   let service_ine_temp  = this.data.charge.service_lines.filter(line =>{

      //     if(line.status && line.status.toLowerCase() != 'void'){
      //       return line
      //     }else{
      //       this.void_array.push(line);
      //     }
      //   })
      //   this.data.charge.service_lines = service_ine_temp;
      // }
      this.initialProcess();
      this.loadProvider(this.taskData.charge.practice_id);
      this.loadFacilities(this.taskData.charge.practice_id);
      if (this.patient) {
        this.taskData.patient = this.patient;
        this.taskData.patient.age = this.patient.dob ? moment().diff(moment(this.taskData.patient.dob), 'years') : null;
      }


      this.procedureCode_array_item = [];
      this.diagnosisCodesTmp = '';
      this.allProcedureCode();
      let tmp = [];
      this.taskData.charge.diagnosis_codes_info.forEach(element => {
        element.value = element.code;
        element.label = element.description;
        tmp.push(element);
      });
      this.taskData.charge.diagnosis_codes_info = tmp;
      this.serviceDate = moment(this.taskData.charge.service_date_from).format('YYYY-MM-DD') + ' - ' + moment(this.taskData.charge.service_date_to).format('YYYY-MM-DD');
      this.daterangepickerConfig.settings = {
        locale: { format: 'YYYY-MM-DD' },
        alwaysShowCalendars: false
      };

      this.serviceDate = {
        'startDate': moment(this.taskData.charge.service_date_from).format('YYYY-MM-DD'),
        'endDate': moment(this.taskData.charge.service_date_to).format('YYYY-MM-DD')
      };
      this.isDatePicker = false;
      setTimeout(() => {
        this.isDatePicker = true;
      }, 1);
    }
  }
  selectPractice(practice_id) {
    if (practice_id != '') {
      if (this.allPractices[0].practice_id == 'initial') {
        this.allPractices.splice(0, 1)
      }
      Observable.forkJoin([this.practicesService.searchProvider(practice_id, '', status = 'true'), this.practicesService.listSearchFacilities(practice_id, '', status = 'true')]).subscribe(data => {
        if (data[0].status == 'success' && data[1].status == 'success') {
          if (data[0].results && data[0].results.length > 0) {
            let tempProviderData = data[0].results;
            this.providerList = tempProviderData;
            let totalProviderList = [];
            tempProviderData.forEach((provider, i) => {
              let providerName;
              if (provider.type == 'Individual' && provider.first_name == '' && provider.middle_name == '' && provider.last_name == '') {
                providerName = provider.short_name;
              } else if (provider.type == 'Individual') {
                providerName = `${provider.last_name}, ${provider.middle_name} ${provider.first_name}`
              }
              if (provider.type == 'Organization') {
                providerName = provider.organization_name;
              }
              totalProviderList[i] = { value: provider._id, label: providerName };

            });
            // this.taskData.charge.rendering_provider_id = this.taskData.charge.rendering_provider_id;
            // totalProviderList.unshift({value: this.taskData.charge.rendering_provider_id, label: this.taskData.charge.rendering_provider_id});
            this.taskData.charge['totalProviderList'] = totalProviderList;
          } else if (data[0].results.length == 0) {
            this.taskData.charge['totalProviderList'] = [];
          }
          if (data[1].results && data[1].results.length > 0) {
            let totalLocationList = [];
            let tempLocationdata = data[1].results;
            this.facilitiesList = tempLocationdata;
            tempLocationdata.forEach((facility, i) => {
              totalLocationList[i] = { value: facility._id, label: facility.name };
            });
            // this.taskData.charge.facility_id = this.taskData.charge.facility_id;
            // totalLocationList.unshift({value: this.taskData.charge.facility_id, label: this.taskData.charge.facility_id})
            this.taskData.charge['totalLocationList'] = totalLocationList;
          } else if (data[1].results.length == 0) {
            this.taskData.charge['totalLocationList'] = [];
          }
        }
      });
    }
  }
  ngAfterViewInit() {

  }
  collapsed(event: any): void {
  }
  serviceDateSelected(value: any) {
    this.taskData.charge.service_date_from = value.startDate;
    this.taskData.charge.service_date_to = value.endDate;
  }
  /* Common method to set provider && facilities(location) */
  setItem(evt, ele) {
    switch (ele) {
      case 'provider':
        if (this.chargesOnly != 'chargesOnly') {
          this.taskData.charge.rendering_provider_name = evt.label;
        } else {
          let provider_obj = this.providerList.filter(item => item._id == evt.value);
          if (provider_obj.length == 0) {
            provider_obj['0'] = this.defaultProvider.organization_name == evt.label ? this.defaultProvider : [];
          }
          this.taskData.charge.rendering_provider = {
            entity: provider_obj[0].entity,
            first_name: provider_obj[0].first_name ? provider_obj[0].first_name : "",
            last_name: provider_obj[0].last_name ? provider_obj[0].last_name : "",
            middle_name: provider_obj[0].middle_name ? provider_obj[0].middle_name : "",
            npi: provider_obj[0].npi ? provider_obj[0].npi : "",
            organization_name: provider_obj[0].organization_name ? provider_obj[0].organization_name : "",
            secondary_id: provider_obj[0].secondary_id ? provider_obj[0].secondary_id : "",
            secondary_id_type: provider_obj[0].secondary_id_type ? provider_obj[0].secondary_id_type : "",
            taxonomy_code: provider_obj[0].taxonomy_code ? provider_obj[0].taxonomy_code : ""
          }

        }
        break;
      case 'location':
        if (this.chargesOnly == 'chargesOnly') {
          let location_obj = this.facilitiesList.filter(item => item._id == evt.value);
          if (location_obj.length == 0) {
            location_obj['0'] = this.defaultFacility.name == evt.label ? this.defaultFacility : [];
          }
          this.taskData.charge.service_facility = {
            name: location_obj[0].name,
            npi: location_obj[0].npi ? location_obj[0].npi : "",
            phone_number: location_obj[0].phone ? location_obj[0].phone : "",
            secondary_id: location_obj[0].secondary_id ? location_obj[0].secondary_id : "",
            secondary_id_type: location_obj[0].secondary_id_type ? location_obj[0].secondary_id_type : "",
            address: location_obj[0].address ? Object.assign(location_obj[0].address) : ""
          }
        }
        break;
    }
  }
  expanded(event: any): void {
  }
  isCollapsed(target) {
    this.togglecollapseVar = target;
    if (this.isCollapse[target] != undefined) {
      return this.isCollapse[target];
    } else {
      this.isCollapse[target] = true;
      return this.isCollapse[target];
    }
  }
  toggleCollapse(target): void {
    this.togglecollapseVar = target;
    if (this.isCollapse[target] != undefined) {
      this.isCollapse[target] = !this.isCollapse[target];
    } else {
      this.isCollapse[target] = true;
    }
  }

  diagnosisCodeWithDescription(codeData) {
    codeData.forEach((val, index) => {
      this.diagnosiscode_array_item[index] = [];
      for (let i in codeData) {
        this.diagnosiscode_array_item[index].push({
          value: codeData[i].code,
          label: codeData[i].code + ' - ' + codeData[i].description
        });
      }
    });
  }

  processDiagnosis() {
    if (!this.taskData.charge.diagnosis_codes_info) {
      this.taskData.charge.diagnosis_codes_info = [];
    }

    if (this.taskData.charge.diagnosis_codes.length > 0) {

      this.diagnoCodeArray = this.taskData.charge.diagnosis_codes_info;
      if (this.chargesOnly != "chargesOnly") {
        this.diagnosisCodeWithDescription(this.taskData.charge.diagnosis_codes_info)
      } else {
        this.diagnosisCodesService.findManyDaignosisCodes(this.taskData.charge.diagnosis_codes).subscribe(data => {
          if (data.status == "success") {
            this.diagnosisCodeWithDescription(data.results)
          }
        });
      }

    } else {
      // this.taskData.charge.diagnosis_codes=[];
      // this.diagnosisData=['']
      // this.diagnoCodeArray= [[]];
      // this.diagnosiscode_array_item=[[]];

    }

  }
  processServiceLines() { 
   let searchKey = '';
    if(this.country == 'US') {
      this.taskData.charge.service_date_from = moment(this.taskData.charge.service_date_from).format('MM/DD/YYYY');
      this.taskData.charge.service_date_to = moment(this.taskData.charge.service_date_to).format('MM/DD/YYYY');
    }else{
      this.taskData.charge.service_date_from = moment(this.taskData.charge.service_date_from).format('DD/MM/YYYY');
      this.taskData.charge.service_date_to = moment(this.taskData.charge.service_date_to).format('DD/MM/YYYY');
    }
    if (this.taskData.charge.service_lines && this.taskData.charge.service_lines.length > 0) {
      let protemp = [];
      this.taskData.charge.service_lines.forEach((val, i) => {
        if(typeof val.procedure_code == 'number'){
          val.procedure_code = val.procedure_code.toString();
        }
        protemp.push(val.procedure_code);
      });

      // this.taskData.charge.service_date_from = value.startDate;
      this.taskData.charge.service_lines.forEach((element, i) => {
        this.valideerrordosto[i] = true;
        this.valideerrordosfrom[i] = true;
        this.taskData.charge.service_lines[i].service_date_from = moment(element.service_date_from).format('MM/DD/YYYY');
        this.taskData.charge.service_lines[i].service_date_to = moment(element.service_date_to).format('MM/DD/YYYY');
      });

      protemp.length > 0 ? searchKey = 'cpt=' + protemp.join(',') : searchKey = 'q=' + protemp;
      this.taskService.searchProcedureCode(searchKey).subscribe(data => {

        if (data.results.length > 0) {
          this.procedureCodeArray = data.results;
          if (this.procedureCodeArray) {
            this.procedureCodeArray.forEach((val, i) => {
              this.proString = `${this.procedureCodeArray[i].code} - ${this.procedureCodeArray[i].description}`;
              this.procedureCode_array_item_init.push({
                value: this.procedureCodeArray[i].code,
                label: this.proString
              });
              this.storeprocode.push(this.proString);
            });
          }
        }
      });
    }
  }

  allProcedureCode() {
    this.taskService.searchProcedureCode('').subscribe(data => {
      if (data.status == 'success') {
        let loopcount = 1;
        data.results.forEach((val, i) => {
          this.procedureCode_array_item_init.push({
            value: val.code,
            label: val.code + ' - ' + val.description
          });
          if (loopcount == data.results.length) {
            // tslint:disable-next-line: no-shadowed-variable
            this.taskData.charge.service_lines.forEach((val, i) => {
              this.procedureCode_array_item[i] = _.cloneDeep(this.procedureCode_array_item_init);
            });
          }
          loopcount++;
        });
      }
    });
  }

  searchProcedureCodeData(i, searchKey) {
    if (searchKey.indexOf(',') > -1) {
      searchKey = 'diag=' + searchKey;
    } else {
      searchKey = 'q=' + searchKey;
    }
    this.taskService.searchProcedureCode(searchKey).subscribe(data => {
      if (data.status == 'success') {
        this.procedureCode_array_item[i] = [];
        data.results.forEach((val) => {
          this.procedureCode_array_item[i].push({
            value: val.code,
            label: val.code + ' - ' + val.description
          });
        });
      }
    });

  }
  removeDragAndDropElement_DiagnosisCode(arrayindex) {
    let itemToRemove = this.taskData.charge.diagnosis_codes[arrayindex];
    let index_codeInfo = this.taskData.charge.diagnosis_codes.findIndex(result => result.value === itemToRemove);
    if (this.taskData.charge.diagnosis_codes.length > 1) {
      this.taskData.charge.diagnosis_codes.splice(arrayindex, 1);
      this.diagnosiscode_array_item.splice(arrayindex, 1);
      if (index_codeInfo > -1) {
        this.taskData.charge.diagnosis_codes_info.splice(index_codeInfo, 1);
      }
      this.taskData.charge.diagnosis_codes = this.taskData.charge.diagnosis_codes;
    }
    for (let i in this.diagnosiscode_array_item) {
      let tmp = this.diagnosiscode_array_item[i];
      for (let j in tmp) {
        if (tmp[j].value == itemToRemove) {
          tmp.splice(j, 1);
        }

      }
    }
  }
  removeDragAndDropElement_ProcedureCode(arrayindex) {
    this.taskData.charge.service_lines.splice(arrayindex, 1);
    this.taskData.charge.service_lines = this.taskData.charge.service_lines;
  }
  addServiceLine() {
    this.isCollapse = {};
    this.togglecollapseVar = '';
    let servicelineText = {
      diagnosis_code_pointers: [],
      procedure_modifiers: []
    };
    this.taskData.charge.service_lines.push(servicelineText);
    this.valideerrordosto[this.taskData.charge.service_lines.length - 1] = true;
    this.valideerrordosto[this.taskData.charge.service_lines.length - 1] = true;
  }
  onChange(val) {

  }


  patientDemography() {
    let diagnosis_codes = [];

    this.taskData.charge.diagnosis_codes.forEach((val, i) => {
      if (val != '' && val != null) {
        diagnosis_codes.push(val);
      }

    });

    /*
    if(this.taskData.charge.service_lines){
      if(this.taskData.charge.service_lines[0] && this.taskData.charge.service_lines[0].service_date_from){
        this.taskData.charge.service_date_from=this.taskData.charge.service_lines[0].service_date_from;
      }
      if(this.taskData.charge.service_lines[0] && this.taskData.charge.service_lines[0].service_date_to){
        this.taskData.charge.service_date_to=this.taskData.charge.service_lines[0].service_date_to;
      }
    }
    */
    // this.taskData.charge.service_lines.forEach((element, i) => {
    //   this.taskData.charge.service_lines[i].service_date_from = moment(element.service_date_from).format('YYYY-MM-DD');
    //   this.taskData.charge.service_lines[i].service_date_to = moment(element.service_date_to).format('YYYY-MM-DD');
    // });

    this.taskData.charge.diagnosis_codes = diagnosis_codes;
    let currentTaskChargeInfo = _.cloneDeep(this.taskData.charge);
    delete currentTaskChargeInfo.diagnosis_codes_info;
    this.submit.emit();

  }

  getDiagnosisCodeData(textval) {
    if (this.taskData.charge.diagnosis_codes.indexOf(textval) == -1 && textval != undefined) {
      this.taskData.charge.diagnosis_codes.push(textval);
    }
  }

  dropdoenSelect(i) {
    if (i == 13) {
    } else {
      if (this.diagnosiscode_array_item[i] && this.diagnosiscode_array_item[i].length > 0) {

      } else {
        this.diagnosiscode_array_item[i] = [];
        this.searchDiagnosisCode(i, '');
      }
    }


  }
  searchDiagnosisCode(index, searchKey) {
    if (searchKey.indexOf(',') > -1) {
      searchKey = 'diag=' + searchKey;
    } else {
      searchKey = 'q=' + searchKey;
    }
    let code;
    if (this.taskData.charge.diagnosis_codes[index]) {
      code = this.taskData.charge.diagnosis_codes[index];
    }
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
      this.taskService.searchDiagnosisCode(searchKey).subscribe(result => {
        if (result.status == 'success') {
          if (index <= 12) {
            if (this.diagnosiscode_array_item[index]) {
              // tslint:disable-next-line: no-shadowed-variable
              let code_index = this.diagnosiscode_array_item[index].findIndex(result => result.value === code);
              if (this.diagnosiscode_array_item[index][code_index]) {
                this.diagnosiscode_array_item[index] = [this.diagnosiscode_array_item[index][code_index]];
              } else {
                this.diagnosiscode_array_item[index] = [];
              }
            } else {
              this.diagnosiscode_array_item[index] = [];
            }


          } else {
            this.diagnosiscode_array_item_last = [];
          }


          for (let i in result.results) {
            let n = this.taskData.charge.diagnosis_codes.includes(result.results[i].code);
            if (!n) {
              if (index <= 12) {

                this.diagnosiscode_array_item[index].push({
                  code: result.results[i].code,
                  description: result.results[i].description,
                  value: result.results[i].code,
                  label: result.results[i].code + ' - ' + result.results[i].description
                });
              } else {
                this.diagnosiscode_array_item_last.push({
                  code: result.results[i].code,
                  description: result.results[i].description,
                  value: result.results[i].code,
                  label: result.results[i].code + ' - ' + result.results[i].description
                });

              }

            } else {
              //console.log(result.results[i].code);
            }
          }
        } else {
          // this.accountList = accountList;
        }
      });
    }, 400);

  }
  closemodel(i) {

    if ((this.diagnosisData[this.taskData.charge.diagnosis_codes.length - 1] == undefined || this.diagnosisData[this.taskData.charge.diagnosis_codes.length - 1] == '') && this.diagnosisData[this.taskData.charge.diagnosis_codes.length - 2] == '') {
      this.taskData.charge.diagnosis_codes.splice(this.taskData.charge.diagnosis_codes.length - 1, 1);
    }

    this.closemodelval = this.taskData.charge.diagnosis_codes;
    this.indexOfclose = i;
    // this.diagnosisData[i] = this.initialstoreDecodeClone[i]
  }
  loadDiagnosisCode() {
    let searchKey = '';

    if (this.taskData.charge && this.taskData.charge.diagnosis_codes) {
      let tempp1 = this.taskData.charge.diagnosis_codes;
      //   searchKey=tempp1;
      for (let tmp_service of tempp1) {
        if (searchKey != '') {
          searchKey = searchKey + ',' + tmp_service;
        } else {
          searchKey = tmp_service;
        }
      }
    }

    if (searchKey.indexOf(',') > -1) {
      searchKey = 'diag=' + searchKey;
    } else {
      searchKey = 'q=' + searchKey;
    }
    this.taskService.searchDiagnosisCode(searchKey).subscribe(result => {
      if (result.status == 'success') {
        this.diagnosiscode_array_item = [];

        // edit
        if (this.showChargeData && this.taskData.charge) {
          // tslint:disable-next-line: no-var-keyword
          var tempp = this.taskData.charge.diagnosis_codes;
          // tslint:disable-next-line: no-var-keyword
          var temp = [];
          this.items = [];
        }

        for (let i in result.results) {
          this.diagnosiscode_array_item.push({
            value: result.results[i].code,
            label: result.results[i].code + ' - ' + result.results[i].description
          });
          if (this.showChargeData && this.taskData.charge) {
            for (let j in tempp) {
              if ((this.diagnosiscode_array_item[i].label).includes(tempp[j])) {
                temp.push(this.diagnosiscode_array_item[i].label);
              }
            }
          }
        }



        if (this.showChargeData && this.taskData.charge) {
          for (let k in tempp) {
            for (let l in temp) {
              if (tempp[k] == temp[l].split(' -')[0].trim()) {
                this.items.push(temp[l]);
              }
            }
          }
        }
        let newCourse = _.cloneDeep(this.diagnosiscode_array_item);
        let index = 0;
        this.diagnosiscode_array_item_last = newCourse;
        this.taskData.charge.diagnosis_codes.forEach(element => {

          this.diagnosiscode_array_item[index] = newCourse;
          this.diagnosisData[index] = element;

          index++;
          if (index == this.taskData.charge.diagnosis_codes.length) {
            this.enablesection = true;
          }
        });
      } else {
        // this.accountList = accountList;
      }


    });
  }

  optiondLoad() {
    let newCourse = _.cloneDeep(this.diagnosiscode_array_item);
    let index = 0;
    this.taskData.charge.diagnosis_codes.forEach(element => {

      this.diagnosiscode_array_item[index] = newCourse;
      this.diagnosisData[index] = element;

      index++;
      if (index == this.taskData.charge.diagnosis_codes.length) {
        this.enablesection = true;
      }
    });
  }

  insuranceChange(event: any) {

    let selectedLaw: any = event.target.value;
    // this.taskService.currentTaskData.selected_current_case_type = selectedLaw;
    this.taskData.charge.selected_case = selectedLaw;
    let selected_case = this.patient.cases.find(c => c.case_name == this.taskData.charge.selected_case);
    if (selected_case && selected_case.insurances) { // && selected_case.insurances.length>0) {
      this.taskData.charge.insurance = selected_case.insurances.map(policy => {
        return {
          "sequence": policy.sequence.toString(),
          "payer_id": policy.id,
          "payer_name": policy.name,
          "plan_name": "",
          "subscriber_id": policy.subscriber.id, //"3PD3U64FE94",
          "group_name": policy.subscriber.group_name, // "NONE",
          // "insurance_paid_amount": 0,
          // "insurance_balance_amount": 0,
          // "insurance_payment_date": ""
        }
      });
    } else {
      this.taskData.charge.insurance = [];
    }
  }

  selectedDiagnosis(code_obj, i) {
    if (!this.isCodeExists) {
      this.taskData.charge.diagnosis_codes[i] = code_obj.value;
      this.taskData.charge.diagnosis_codes_info[i] = code_obj;
      if (this.diagnosiscode_array_item[i - 1]) {
        this.diagnosiscode_array_item[i] = JSON.parse(JSON.stringify(this.diagnosiscode_array_item[i - 1]));
      }
      let flag = true;
      for (let tmp of this.diagnosiscode_array_item) {
        if (tmp.value == code_obj.value) {
          flag = false;
        }
      }
      if (flag) {
        // tslint:disable-next-line: no-shadowed-variable
        for (let i in this.diagnosiscode_array_item) {
          this.diagnosiscode_array_item[i].push(code_obj);
        }
      }
    } else {
      this.sharedService.pushToaster('error', 'Validation', 'Diagnosis code has to be unique.');
      this.reset_last_code = false;
      setTimeout(() => {
        this.reset_last_code = true;
      }, 100);


    }



  }
  selectedDiagnosisFromEmpty(code_obj) {
    let i = this.taskData.charge.diagnosis_codes.length;
    this.taskData.charge.diagnosis_codes[i] = code_obj.value;
    this.taskData.charge.diagnosis_codes_info[i] = code_obj;
    let newArrayItem = [];
    if (i > 0) {
      newArrayItem = JSON.parse(JSON.stringify(this.diagnosiscode_array_item[i - 1]));
    }

    this.diagnosiscode_array_item[i] = newArrayItem;
    this.reset_last_code = false;
    setTimeout(() => {
      this.reset_last_code = true;
    }, 1);


    // tslint:disable-next-line: no-shadowed-variable
    for (let i in this.diagnosiscode_array_item) {
      this.diagnosiscode_array_item[i].push(code_obj);
    }
  }
  diag_old_code(event, i) {
    this.isCodeExists = false;
    this.diag_prev = this.taskData.charge.diagnosis_codes[i];
    if (this.taskData.charge.diagnosis_codes.includes(event)) {
      this.taskData.charge.diagnosis_codes[i] = this.diag_prev;
      this.isCodeExists = true;
    }
  }
  undoChanges() {
    //    this.taskData.charge = JSON.parse(JSON.stringify(this.taskService.currentTaskData.charge_info[0]));
    this.taskData = JSON.parse(JSON.stringify(this.data));

    this.ngOnChanges(this.taskData);
  }

  latestProcedureModifier(i: number) {
    this.taskData.charge.service_lines[i].procedure_modifiers.forEach((elem, k) => {
      if (elem == "") {
        this.taskData.charge.service_lines[i].procedure_modifiers.splice(k, 1)
      }
    });
  }

  latestDiagnosisPointer(i: number) {
    this.taskData.charge.service_lines[i].diagnosis_code_pointers.forEach((ele, j) => {
      if (ele == "") {
        this.taskData.charge.service_lines[i].diagnosis_code_pointers.splice(j, 1)
      }
    });
  }

  processCharge() {
    //this.taskData.charge_info[0].charge_status_color = (this.global_data[this.taskData.charge_info[0].status].task_color) ? this.global_data[this.taskData.charge_info[0].status].task_color : 'light';
    this.tempFlag = false;
    this.diagnosis_pointer_hasError = false;
    this.procedure_modifier_hasError = false;
    let diagnosis_codes = [];

    if (!(this.taskData.patient) && this.chargesOnly == "chargesOnly") {
      this.sharedService.pushToaster('error', 'Patient information is missing', 'Patient information is required in order to submit charge.');
      return true;
    }
    if (!(this.taskData.patient._id) && this.chargesOnly != "chargesOnly") {
      this.sharedService.pushToaster('error', 'Patient information is missing', 'Patient information is required in order to submit charge.');
      return true;
    } else {
      this.taskData.charge.diagnosis_codes.forEach((val, i) => {
        if (val != '' && val != null) {
          diagnosis_codes.push(val);
        }

      });
      /*
      if(this.taskData.charge.service_lines){
        if(this.taskData.charge.service_lines[0] && this.taskData.charge.service_lines[0].service_date_from){
          this.taskData.charge.service_date_from=this.taskData.charge.service_lines[0].service_date_from;
        }
        if(this.taskData.charge.service_lines[0] && this.taskData.charge.service_lines[0].service_date_to){
          this.taskData.charge.service_date_to=this.taskData.charge.service_lines[0].service_date_to;
        }
      }
      */

      this.datevalidateerror = false;

      this.taskData.charge.service_lines.forEach((element, i) => {
        if (this.valideerrordosfrom[i] == false) {
          this.datevalidateerror = true;
        }
        if (this.valideerrordosto[i] == false) {
          this.datevalidateerror = true;
        }
        // if(this.chargesOnly != "chargesOnly") {
        // if(element.diagnosis_code_pointers.length > this.taskData.charge.diagnosis_codes.length) {
        // this.diagnosis_pointer_hasError = true;
        // }
        // }
        let tempFilter = element.diagnosis_code_pointers.some(data => data > this.taskData.charge.diagnosis_codes.length);
        if (tempFilter) {
          this.diagnosis_pointer_hasError = true;
        }
        if (element.procedure_modifiers.length > 4) {
          this.procedure_modifier_hasError = true;
        }
        if (element.procedure_code == undefined) {
          this.tempFlag = true;
        }
        if (element.service_date_from == undefined || element.service_date_to == undefined) {
          this.datevalidateerror = true;
        }
      });

      if (this.tempFlag) {
        this.sharedService.pushToaster('error', "Error", "Service lines must have a procedure code");
        return;
      }
      if (this.procedure_modifier_hasError) {
        this.sharedService.pushToaster('error', "Error", "Service lines can only have up to four modifiers");
        return;
      }
      if (this.diagnosis_pointer_hasError) {
        this.sharedService.pushToaster('error', "Error", "Diagnosis pointer should not exceed diagnosis code");
        return;
      }
      this.taskData.charge.diagnosis_codes = diagnosis_codes;
      let currentTaskChargeInfo = _.cloneDeep(this.taskData.charge);
      delete currentTaskChargeInfo.diagnosis_codes_info;
      this.dataUpdates = {};
      let isValid = true;
      let validationMessage = '';
      // valideerrordosfrom
      if (this.datevalidateerror) {
        isValid = false;
        validationMessage = 'Please enter valid date ';
      }

      if (this['valideerrordatefrom'] == false || this['valideerrordateto'] == false) {
        isValid = false;
        validationMessage = 'Please enter valid date ';
      }
      // let currentTaskChargeInfo = _.cloneDeep(this.taskData.charge);
      if (currentTaskChargeInfo.service_lines.length == 0) {
        isValid = false;
        validationMessage = 'Service Line can not be empty.';
      } else {
        let tmp_servicelines = [];
        currentTaskChargeInfo.service_lines.forEach(element => {
          if (tmp_servicelines.indexOf(element.procedure_code) == -1) {
            tmp_servicelines.push(element.procedure_code);
          } else {
            isValid = false;
            validationMessage = 'Procedure code has to be unique.';
          }

        });
      }
      if (!currentTaskChargeInfo.selected_case) {
        if (currentTaskChargeInfo.cases && currentTaskChargeInfo.cases.length == 0) {

        }
      }
      if (!isValid) {
        this.sharedService.pushToaster('error', 'Validation', validationMessage);
        // this.sharedService.emitChange()
      } else {
        this.taskData.charge.patient_firstname = this.taskData.patient.first_name;
        this.taskData.charge.patient_lastname = this.taskData.patient.last_name;
        this.taskData.charge.patient_middlename = this.taskData.patient.middle_name;
        this.taskData.charge.patient_dob = this.taskData.patient.dob;
        this.taskData.charge.gender = this.taskData.patient.gender;

        delete currentTaskChargeInfo.diagnosis_codes_info;

        // find what are the changes made to patinet and charge data
        if (this.chargesOnly != "chargesOnly") {
          this.findChanges(this.taskData, this.taskData.charge);
        }
        let data = {
          patient: this.taskData.patient,
          charge: currentTaskChargeInfo,
          dataUpdates: this.dataUpdates
        };
        this.voidServiceLine.forEach(element => {
          data.charge.service_lines.push(element)
        });
        data.charge.service_lines.forEach((element, i) => {
          data.charge.service_lines[i].service_date_from = moment(element.service_date_from).format('YYYY-MM-DD');
          data.charge.service_lines[i].service_date_to = moment(element.service_date_to).format('YYYY-MM-DD');
        });
        data.charge.service_date_from = moment(this.taskData.charge.service_date_from).format('YYYY-MM-DD');
        data.charge.service_date_to = moment(this.taskData.charge.service_date_to).format('YYYY-MM-DD');

        if (this.chargesOnly != "chargesOnly") {
          this.taskService.processCharge(this.taskData._id, data).subscribe(res => {
            if (res.status == 'success') {
              this.taskService.validateChargeInsurance(res.data.instanceData.charge_id, res.data.instanceData._id).subscribe(validateResponse => {
                if (validateResponse.result && validateResponse.result[0]) {
                  this.sharedService.pushToaster('success', res.status, 'Claim submitted successfully');
                  data.charge.validations = validateResponse.result[0].validations;
                }
                this.submit.emit(data);
              });
              //this.taskData.charge_info[0].charge_status_color='';
              this.sharedService.pushToaster('warning', res.status, 'Claim initiated. Please wait');
              this.taskData.charge_info[0] = JSON.parse(JSON.stringify(this.taskData.charge));
              this.taskData.charge_info[0].patient = JSON.parse(JSON.stringify(this.taskData.patient));
              this.taskData.charge_info[0].patient.patient_name = JSON.parse(JSON.stringify(this.taskData.patient.first_name + ',' + this.taskData.patient.last_name));
              let dob = JSON.parse(JSON.stringify(this.taskData.charge.patient_dob));
              this.taskData.age = moment().diff(dob, 'years', false) + '';
              this.taskData.charge_info[0] =  this.data.charge_info[0];
              this.taskData.charge_info[0].charge_status_color = (this.global_data[this.taskData.charge_info[0].status].task_color) ? this.global_data[this.taskData.charge_info[0].status].task_color : 'info';

              // this.submit.emit('hi');
              //this.submit.emit(data);

            } else {
              this.sharedService.pushToaster('error', res.status, res.message);
            }
          });
        } else {
          this.submit.emit(data);
          this.sharedService.pushToaster('success', 'success', 'Claim submitted in local successfully');
        }
      }
    }
    // this.submit.emit();
  }

  findChanges(taskinfo, chargeinfo) {
    let taskData = JSON.parse(JSON.stringify(taskinfo)); // _.cloneDeep(taskinfo);
    let chargeData = JSON.parse(JSON.stringify(chargeinfo)); // _.cloneDeep(chargeinfo);
    // let patientData = JSON.parse(JSON.stringify(patientInfo)); // _.cloneDeep(patientInfo);

    // check whether diagnosis codes updated or not
    let ini_diagnosis_codes = JSON.stringify(taskData.charge_info[0].diagnosis_codes);
    let modified_diagnosis_codes = JSON.stringify(chargeData.diagnosis_codes);
    if (ini_diagnosis_codes === modified_diagnosis_codes) {
      this.dataUpdates['diagnosis_codes_updated'] = false;
    } else {
      this.dataUpdates['diagnosis_codes_updated'] = true;
    }

    // check whether procedure codes updated or not
    let ini_proc_codes = [];
    if (taskData.charge_info[0] && taskData.charge_info[0].service_lines != undefined) {
      taskData.charge_info[0].service_lines.forEach(element => {
        ini_proc_codes.push(element.procedure_code);
      });
    }

    let modified_proc_codes = [];
    chargeData.service_lines.forEach(element => {
      modified_proc_codes.push(element.procedure_code);
    });
if( taskData &&  taskData.charge_info &&   taskData.charge_info.length>0 && taskData.charge_info[0].service_lines?.length>0 ){
  taskData.charge_info[0].service_lines.forEach((element, i) => {
    taskData.charge_info[0].service_lines[i].service_date_from = moment(taskData.charge_info[0].service_lines[i].service_date_from).format('MM/DD/YYYY')
    taskData.charge_info[0].service_lines[i].service_date_to = moment(taskData.charge_info[0].service_lines[i].service_date_to).format('MM/DD/YYYY')
  });
}
    if ((chargeData.claim_number != taskData.charge_info[0].claim_number) ||
      (chargeData.rendering_provider_id != taskData.charge_info[0].rendering_provider_id) ||
      (chargeData.facility_id != taskData.charge_info[0].facility_id) ||
      (chargeData.service_date_from != moment(taskData.charge_info[0].service_date_from).format('MM/DD/YYYY')) ||
      (chargeData.service_date_to != moment(taskData.charge_info[0].service_date_to).format('MM/DD/YYYY'))) {
      this.dataUpdates['claim_line_updated'] = true;
    } else {
      this.dataUpdates['claim_line_updated'] = false;
    }
    if (!_.isEqual(chargeData.service_lines, taskData.charge_info[0].service_lines)) {
      this.dataUpdates['service_line_updated'] = true;
    } else {
      this.dataUpdates['service_line_updated'] = false;
    }

    if (ini_proc_codes.length !== modified_proc_codes.length) {
      this.dataUpdates['procedure_codes_updated'] = true;
    } else {
      for (let i = ini_proc_codes.length; i--;) {
        if (ini_proc_codes[i] !== modified_proc_codes[i]) {
          this.dataUpdates['procedure_codes_updated'] = true;
        } else {
          this.dataUpdates['procedure_codes_updated'] = false;
        }
      }
    }

    // check whether selected case updated
    if (taskData.charge_info[0].selected_case == chargeData.selected_case) {
      this.dataUpdates['selected_case_updated'] = false;
    } else {
      this.dataUpdates['selected_case_updated'] = true;
    }
  }

  chagedetected() {
    this.cdRef.detectChanges();
  }

  datechangedata(value, from, index) {
    let dateSplit = value.split('/');
    let datePattern = "^[0-9]{2}/[0-9]{2}/[0-9]{4}$";
    let regExVal = new RegExp(datePattern);
    let patternMatchBool = regExVal.test(value);
    let serviceFromDate;
    let serviceToDate;
    if (value != undefined ) {
      let date
      let currentDate
      if( this.country=="US"){
         date = moment(value, 'MM/DD/YYYY');
         currentDate = moment(); //.format("MM/DD/YYYY");
      }else{
        date = moment(value, 'DD/MM/YYYY');
         currentDate = moment(); //.format("MM/DD/YYYY");
      }
      // if (from == 'dosfrom') {
      //   this.serviceFromDate = value;
      // } else if (from == 'dosto') {
      //   this.serviceToDate = value;
      // }
      if(from == 'dosfrom' || from == 'datefrom'){
        serviceFromDate = date;
        if(this.country =='US'){
          serviceToDate = moment(index!=undefined ? this.taskData.charge.service_lines[index].service_date_to : this.taskData.charge.service_date_to,'MM/DD/YYYY');
        }else{
          serviceToDate = moment(index!=undefined ? this.taskData.charge.service_lines[index].service_date_to : this.taskData.charge.service_date_to,'DD/MM/YYYY');
        }
      }else if(from == 'dosto' || from == 'dateto'){
        serviceToDate = date;
        if(this.country =='US'){
          serviceFromDate = moment(index!=undefined ? this.taskData.charge.service_lines[index].service_date_from : this.taskData.charge.service_date_from,'MM/DD/YYYY');
        }else{
          serviceFromDate = moment(index!=undefined ? this.taskData.charge.service_lines[index].service_date_from : this.taskData.charge.service_date_from,'DD/MM/YYYY');
        }
      }
      let dynamicvar = 'valideerror' + from;
      if (index >= 0) {
        this['valideerrordosfrom'][index] = serviceFromDate.isValid();
        this['valideerrordosto'][index] = serviceToDate.isValid();
        // this[dynamicvar][index] = date.isValid();
        if (dateSplit[2] == '0000' || patternMatchBool == false || dateSplit[2] < '1991' || serviceFromDate.isAfter(serviceToDate) || serviceFromDate.isAfter(currentDate) || serviceToDate.isAfter(currentDate)) {
          this[dynamicvar][index] = false;
          // this['valideerrordosfrom'][index] = false;
          // this['valideerrordosto'][index] = false;
        }
      } else {
        this['valideerrordatefrom'] = serviceFromDate.isValid();
        this['valideerrordateto'] = serviceToDate.isValid();
        // this[dynamicvar] = date.isValid();
        if (dateSplit[2] == '0000' || patternMatchBool == false || dateSplit[2] < '1991' || serviceFromDate.isAfter(serviceToDate) || serviceFromDate.isAfter(currentDate) || serviceToDate.isAfter(currentDate)) {
          this[dynamicvar] = false;
          // this['valideerrordatefrom'] = false;
          // this['valideerrordateto'] = false;
        }
      }
    }
  }

  changeStatusPopup(template: TemplateRef<any>) {
    this.enableSaveButton = false;
    this.selectStatus = this.taskData.charge_info[0].status;
    this.taskService.searchChargeStatuses('visit_statuses').subscribe(data => {
      if (data.status == 'success') {
        this.status_arr = [];
        this.chargeStatusTransition = (data.results[0].values) ? data.results[0].values : null;
        for (const key in this.chargeStatusTransition) {
          if (key != 'Last') {
            this.status_arr.push(key.replace(/"/g, ""))
          }
        }

        if (this.chargeStatusTransition != null) {
          this.global_data = data.results[0].values;
          this.SharedPopupService.show(template, {
            animated: true,
            backdrop: 'static'
          });
        } else {
          this.sharedService.pushToaster('error', 'Error', 'No Transition for this status');
        }
      }
    }, function (err) {
      // this.modalRef = this.SharedPopupService.show(template, {
      //     animated: true,
      //     backdrop: 'static'
      // });
    });
  }

  modalClose() {
    this.SharedPopupService.dismiss();
    this.isclicked = false;
  }

  selectedStatus(value) {
    if (value != '') {
      this.enableSaveButton = true;
      this.selectStatus = value;
    }
  }

  savechanges() {
    this.taskService.saveChargeStatus(this.taskData.charge_id, this.selectStatus).subscribe(data => {
      this.SharedPopupService.dismiss();
      this.isclicked = false;
      if (data.status == 'success') {
        this.taskData.charge_info[0].status = this.selectStatus;
        this.taskData.charge_info[0].charge_status_color = (this.global_data[this.taskData.charge_info[0].status].task_color) ? this.global_data[this.taskData.charge_info[0].status].task_color : 'light';
        this.sharedService.pushToaster('success', 'Success', 'Charge status updated successfully');
        let obj = {
          chargeNewStatus: this.selectStatus,
          chargeNewStatusColor: this.global_data[this.taskData.charge_info[0].status].task_color,
          activities: data.activities
        };
        this.chargeevent.emit(obj);
      }
    });
  }
  ngOnDestroy(): void {
    this.SharedPopupService.dismiss();
  }
}

