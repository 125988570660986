import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';


import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { JwtModule } from '@auth0/angular-jwt';
import * as myGlobals from './globals';

import { NgHttpLoaderModule } from 'ng-http-loader';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { UserIdleModule } from 'angular-user-idle';

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';
const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { FormsModule } from '@angular/forms';
import { AlertModule } from 'ngx-bootstrap/alert';
import { from } from 'rxjs';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ToasterModule } from 'angular2-toaster/angular2-toaster';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

// components
import { LoginComponent } from './views/login/login.component';
import { P404Component } from './views/error/404.component';
import { P400Component } from './views/error/400.component';

// services
import { TokenInterceptor } from './_services/auth/token-interceptor';

// Pipes
// import { CapitalizePipe } from './pipes/capitalize.pipe';
import { SharedModule } from './pipes/shared.module';
import { DataHelperService } from './_services/auth/data-helper.service';
import { ResetPasswordComponent } from './views/login/reset-password/reset-password.component';
import { SetPasswordComponent } from './views/login/set-password/set-password.component';
import { SettingsModule } from './views/settings/settings.module';
import { TaskNotificationOptionsComponent , RelatedValueFilterPipe} from './components/task-notification-options/task-notification-options.component';
import { MfaLoginComponent } from './views/login/mfa-login/mfa-login.component';
import { AttachmentsComponent } from './components/attachments/attachments.component';
import { SkillsetApprovalComponent } from './views/skillset-approval/skillset-approval.component';





// const config: SocketIoConfig = { url: myGlobals.Socket_Url, options: {} };


export function getToken() {
  return localStorage.getItem('authToken');
}
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    FormsModule,
    AlertModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
      }
    }),
    UserIdleModule.forRoot({ idle: 1800, timeout: 10, ping: 60 }),
    NgbModalModule,
    SharedModule,
    //SocketIoModule, // .forRoot(config),
    ToasterModule.forRoot(),
    SettingsModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    LoginComponent,
    P404Component,
    P400Component,
    ResetPasswordComponent,
    SetPasswordComponent,
    RelatedValueFilterPipe,
    TaskNotificationOptionsComponent,
    MfaLoginComponent,
    AttachmentsComponent,
    SkillsetApprovalComponent
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy,

  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
    DataHelperService,
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
