import { Component, OnInit,Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SharedService } from './_services/shared.service';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { DataHelperService } from './_services/auth/data-helper.service';
import { Title } from '@angular/platform-browser';
import { PlatformLocation} from '@angular/common';
import { DOCUMENT } from '@angular/common';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: `<router-outlet></router-outlet>
            <toaster-container  [toasterconfig]="toasterconfig"></toaster-container>`,
  providers: [ToasterService]
})
export class AppComponent implements OnInit {
  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      'tapToDismiss': true,
      'preventDuplicates': true,
      'timeout': 5000
    });
  constructor(private router: Router,
    private sharedService: SharedService,
    private toasterService: ToasterService,
    private dataHelperService: DataHelperService,
    private titleService: Title,
    private platformLocation: PlatformLocation,
    @Inject(DOCUMENT) private _document: HTMLDocument) {
    this.sharedService.changeEmitted$.subscribe(data => {
      this.toasterService.pop(data.type ? data.type : 'success', data.title ? data.title : '', data.message ? data.message : ''); // "success","sss","sssh");

    });
    this.dataHelperService.setSalt();
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    if (this.platformLocation.hostname.includes('globalhealthcareresource.io') || this.platformLocation.hostname=='localhost'){
      this._document.getElementById('appFavicon').setAttribute('href','assets/global_favicon.ico');
      this.titleService.setTitle('GLOBART');
    }
    if (this.platformLocation.hostname.includes('claimocity.io') || this.platformLocation.hostname=='localhost'){
      //this._document.getElementById('appFavicon').setAttribute('href','assets/claimocity_favicon.ico');
      this._document.getElementById('appFavicon').setAttribute('href','assets/global_favicon.ico');
      this.titleService.setTitle('GLOBART');
    }
  }
}
