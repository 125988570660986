import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '../../globals';
import { version } from 'os';

@Injectable({
  providedIn: 'root'
})
export class ProcedurecodesService {
  constructor(private http: HttpClient) {}

  addProcedureCode(req: any) {
    let country = localStorage.getItem('Region');
    if(country=="UAE"){
      req.version=localStorage.getItem('UaeVersion')
    }
    return this.http.post(myGlobals.ProcedureCodes_URL, req);
  }
  editProcedureCode(req: any, tmpcode: string) {
    //console.log('////////////', tmpcode, req);
    req['tmpcode'] = tmpcode;
    let country = localStorage.getItem('Region');
    if(country=="UAE"){
      req.version=localStorage.getItem('UaeVersion')
    }    return this.http.put(myGlobals.ProcedureCodes_URL + '/' + req.code, req);
  }

  searchProcedureCodes(searchKey, status, page= 0, limit= 20) {
    if (searchKey == undefined) {
      searchKey = '';
    }
    let version=localStorage.getItem('UaeVersion')
    let url=myGlobals.Base_URL + '/procedurecodes?q=' + searchKey + '&status=' + status + '&page=' + page + '&pagesize=' + limit 
    if(version && version.length>0){
      url=url+'&version='+ version
    }
    return this.http.get(url);
  }

  changeStatus(procedure_code, enabled) {
    let req = { 'enabled': enabled };
    return this.http.put(myGlobals.Base_URL + '/procedurecodes/' + procedure_code + '/status', req);
  }
}
