import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as myGlobals from '../../globals';
import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class LoginHistoryService {
  public test = 'test';
  public user_id;
  public user_name;
  public accountHistory;
  public viewHistory;
  public practice_id;
  public organization_name;
  constructor(private http: HttpClient) {
    this.practice_id = '';
    this.organization_name = '';
    this.user_id = '';
    this.user_name = '';
    this.viewHistory = false;
    this.accountHistory = false;
    //console.log('from comp', this.test, this.user_id, this.user_name);

  }
  getUserLoginHistory(val, historytype) {
    historytype = historytype == 'userloginhistory' ? '/' + val.user_id + '/' + historytype : '/' + historytype;
    return this.http.post<any>(myGlobals.User_URL  + historytype, val);
  }
  downloadAsCsv(val, historytype) {
    historytype = historytype == 'userloginhistory' ? '/' + val.user_id + '/' + historytype : '/' + historytype;
    return this.http.post(myGlobals.User_URL + historytype + '/download', val, { responseType: 'blob' });

  }

}
