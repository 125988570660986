import { Component, OnDestroy, TemplateRef, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SharedPopupService } from '../../../../_services/popup.service';
import { DataHelperService } from '../../../../_services/auth/data-helper.service';
import { PermissionsService } from '../../../../_services/settings/permissions.service';
import { SharedService } from '../../../../_services/shared.service';
import constants from '../../../../constants';
class NewPermission {
  id: string;
  permission_name: string;
  permission_description: string;
}

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: []
})
export class PermissionsComponent implements OnDestroy {

  permissionSearchData: any;
  permissionList: any;
  seletedTab: any;
  dynamicBtn: any;
  timeout = null;
  inactiveFlag: boolean;
  showInactive: boolean;
  searchtext: String;

  ConfirmPopupfrom: any;
  permissionId: String;
  permissionStatus: any;
  permissionIndex: any;
  messageStatus: any;
  messageInfo: any;
  Permission_Status_Update_Data_list: any;
  resourcesId: String;
  resourcesIndex: any;
  searchModel: String;
  selectedResIndex: number;
  existmappedResources: any;
  tmpMappingData: any;
  allPracticesAccountandrole: any;

  linkedResources: any[];
  filterresource: any;
  checked: boolean;
  resouceData: any[];
  selectedItem: string;
  permissionIndexValue: number;
  popUpfrom: String;
  popupTitle: String;

  addNewPermission: any;
  contacts: any;
  PermissionValidationMsg: String;
  PermissionValidationStatus: any;
  AlreadyExistFlag: boolean;
  popUpinfo: any;
  deactivateResources: any = [];
  refreshFlag: boolean;
  successflag: any;
  addpermission_timer: any;
  loaderIndicator:boolean;

  public newpermission: NewPermission;
  constants: any;
  constructor(private sharedService: SharedService, public SharedPopupService: SharedPopupService, public permissionsService: PermissionsService, public modalService: BsModalService, private DataHelper: DataHelperService) {

    this.inactiveFlag = true;
    this.searchtext = '';
    this.selectedItem = '';
    this.refreshFlag = true;
    this.onSearchChange(this.searchtext);
    this.permissionList = [];
    this.loaderIndicator=true;
    this.constants=constants;
  }

  ngOnInit() {
    this.linkedResources = [];
    this.filterresource = '';
    this.resouceData = [];
    this.newpermission = {
      id: '',
      permission_name: '',
      permission_description: ''
    };
  }

  logError(arg0: any): any {
    throw new Error('Method not implemented.');
  }

  // search permission
  onSearchChange(searchValue) {
   this.loaderIndicator=true;
  //  this.permissionList =[];
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
      // console.log('searchValue/*/*/*/*', searchValue);
      this.permissionsService.searchpermission(searchValue, this.inactiveFlag).subscribe(data => {
        this.permissionSearchData = data;
        if (this.permissionSearchData.status == 'success') {
          this.permissionList = this.permissionSearchData.results;
          this.permissionList.map(item => {
            item['type'] = '';
          });
        }
        this.loaderIndicator=false;
      });
    }, 1000);
  }

  activeStatus() {
    this.loaderIndicator=true;
    this.permissionList=[];
    this.inactiveFlag = (this.showInactive == true) ? true : false;
    this.permissionsService.searchpermission(this.searchtext, this.inactiveFlag).subscribe((data: any) => {
      this.permissionSearchData = data;
      this.loaderIndicator=false;
      if (this.permissionSearchData.status == 'success') {
        this.permissionList = this.permissionSearchData.results;
        this.permissionList.map(item => {
          item['type'] = '';
        });
      
  //console.log('this.inactiveFlag ', this.permissionList);
      }
    });
  }

  statusPermission(permissionid, status) {
    status = !status;
    this.Permission_Status_Update_Data_list = {
      '_id': permissionid,
      'status': status
    };
    this.permissionsService.updatePermissionStatus(this.Permission_Status_Update_Data_list).subscribe(data => {
      if (data) {
        this.onSearchChange(this.searchtext);
        this.sharedService.pushToaster('success', 'Success', 'Permission ' + this.messageStatus + ' successfully');
      }
    },
      err => console.log(this.logError(err)));
  }

  tabChange(selectedTab: any, permission, index) {
   // this.permissionList[index]=[];
    this.permissionList[index].tabloaderIndicator=true;
    if (selectedTab.target.innerText == 'Resources' || selectedTab.target.innerText == 'Roles') {
      // this.existmappedaccountandrole=[]
      this.seletedTab = selectedTab.target.innerText;
      permission['type'] = selectedTab.target.innerText;
      this.dynamicBtn = '';
      let size = 10;
      'Resources' == this.seletedTab ? this.dynamicBtn = 'Map resources' : 'Roles' == this.seletedTab ? this.dynamicBtn = 'Map roles' : '';
      if (this.seletedTab == 'Resources') {
        this.dynamicBtn = 'Map resources';
        if (!this.permissionList[index]['permission_resources']) {
          this.permissionsService.get_mapped_resources(permission._id).subscribe(data => {
            this.permissionList[index].tabloaderIndicator=false;
            if (data.status == 'success') {
              this.permissionList[index]['permission_resources'] = data.results;
              this.permissionList[index]['permission_resources_limit'] = 10;
              permission['type'] = selectedTab.target.innerText;
            }
          });
        } else {
          this.permissionList[index].tabloaderIndicator=false;
          permission['type'] = selectedTab.target.innerText;
        }
      } else if (this.seletedTab == 'Roles') {
        this.dynamicBtn = 'Map roles';
        if (!this.permissionList[index]['permission_roles']) {
          this.permissionsService.get_mapped_roles(permission._id).subscribe(data => {
            this.permissionList[index].tabloaderIndicator=false;
            if (data.status == 'success') {
              let roles = [];
              data.results.forEach(element => {
                if (element.type) {
                  roles.push(element);
                }
              });

              this.permissionList[index]['permission_roles'] = roles;
              this.permissionList[index]['permission_roles_limit'] = 10;
              permission['type'] = selectedTab.target.innerText;
            }
          });
        } else {
          permission['type'] = selectedTab.target.innerText;
        }
      }
    } else {
    }
  }
  toggleLimit(item, limit, type) {
    item[type] = limit;
  }

  openModal(template: TemplateRef<any>, from, permissionData, permissionIndex) {
    this.popUpfrom = from;
    this.permissionIndexValue = permissionIndex;
    if (this.popUpfrom == 'newPermission') {
      this.popupTitle = 'New Permission';
      this.newpermission = {
        id: '',
        permission_name: '',
        permission_description: ''
      };
    } else if (this.popUpfrom == 'editPermission') {
      this.popupTitle = 'Edit Permission';
      this.popUpinfo = permissionData;
      this.newpermission = {
        id: this.popUpinfo._id,
        permission_name: this.popUpinfo.permission_name,
        permission_description: this.popUpinfo.permission_description
      };
    }
    // this.toasterService.pop('success', 'Success', 'Procedure Code Successfully');
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static'
    });
  }
  addPermission(model: NewPermission, isValid: boolean, mynform: NgForm) {
    model = this.removeNullEntries(model);
    if (isValid == true) {
      if (this.popUpfrom == 'newPermission') {
        this.addNewPermission = {
          id: model.id,
          permission_name: model.permission_name,
          permission_description: model.permission_description,
          created_by: this.DataHelper.getItem('userid')
        };
        this.permissionsService.addPermission(this.addNewPermission).subscribe(data => {
          this.contacts = data;
          this.ValidatingNewPermission(this.contacts.status);
          this.PermissionValidationMsg = this.contacts.message;
          this.PermissionValidationStatus = this.contacts.status;
          if (this.PermissionValidationStatus == 'success') {
            this.permissionList.unshift(data.data);
            mynform.resetForm();
            this.modalClose();
            this.sharedService.pushToaster('success', 'Success', 'New Permission added successfully');
          }
        },
          err => this.logError(err),
        );
      } else if (this.popUpfrom == 'editPermission') {
        this.addNewPermission = {
          id: model.id,
          name: model.permission_name,
          desc: model.permission_description,
          updated_by: this.DataHelper.getItem('userid')
        };
        this.permissionsService.editPermission(this.addNewPermission).subscribe(data => {
          this.contacts = data;
          this.ValidatingNewPermission(this.contacts.status);
          this.PermissionValidationStatus = this.contacts.status;
          this.PermissionValidationMsg = this.contacts.message;
          if (this.PermissionValidationStatus == 'success') {
            this.permissionList[this.permissionIndexValue].permission_name = this.contacts.data.value.permission_name;
            this.permissionList[this.permissionIndexValue].permission_description = this.contacts.data.value.permission_description;
            this.permissionList[this.permissionIndexValue].permission_updated_by = this.contacts.data.value.permission_updated_by;
            this.permissionList[this.permissionIndexValue].permission_updated_date = this.contacts.data.value.permission_updated_date;
            mynform.resetForm();
            this.modalClose();
            this.sharedService.pushToaster('success', 'Success', 'Permission Update Successfully');
          }
        },
          err => this.logError(err)
        );
      }
    }
  }

  ValidatingNewPermission(permissionresponse) {
    if (permissionresponse == 'Permission Name Already Exist') {
      this.successflag = false;
      this.AlreadyExistFlag = true;
    } else if (permissionresponse == 'success') {
      this.AlreadyExistFlag = false;
      this.successflag = true;
      this.addpermission_timer = setInterval(() => {
        this.successflag = false;
        // closing popup start
        // let el: HTMLElement = this.adduserpopup.nativeElement as HTMLElement;
        // el.click();
        // closing popup end
        clearInterval(this.addpermission_timer);
      }, 1000);
    }
  }

  clearform(mynform: NgForm) {
    mynform.resetForm();
    this.AlreadyExistFlag = false;
  }

  modalClose() {
    this.SharedPopupService.dismiss();
    this.AlreadyExistFlag = false;
  }

  removeNullEntries(obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        obj[key] = this.removeNullEntries(obj[key]);
      } else if (obj[key] === undefined || obj[key] === null) {
        obj[key] = '';
      } else {
        obj[key] = (typeof obj[key] === 'string') ? obj[key].trim() : obj[key];
      }
    });
    return obj;
  }

  ConfirmPopup(Confirmtemplate: TemplateRef<any>, from, id, status, i, resourcesid, resourceIndex) {
    this.ConfirmPopupfrom = from;
    this.permissionId = id;
    this.permissionStatus = status;
    this.permissionIndex = i;
    this.resourcesId = resourcesid;
    this.resourcesIndex = resourceIndex;
    this.messageStatus = (this.permissionStatus == true) ? ' Deactivate ' : ' Activate ';
    if (this.ConfirmPopupfrom == 'deActivatePermission') {
      this.messageInfo = 'Are you sure want to' + this.messageStatus + 'Permisson ?';
    } else if (this.ConfirmPopupfrom == 'statusResources') {
      this.messageInfo = 'Are you sure want to' + this.messageStatus + 'Resources ?';
    } else if (this.ConfirmPopupfrom == 'deactivaterole') {
      this.messageInfo = 'Are you sure want to' + this.messageStatus + 'Role ?';
    }
    this.SharedPopupService.show(Confirmtemplate, {
      animated: true,
      backdrop: 'static',
    });
  }

  onConfirm() {
    if (this.ConfirmPopupfrom == 'deActivatePermission') {
      this.statusPermission(this.permissionId, this.permissionStatus);
      // this.messageInfo = "Are you sure want to Deactivate Account group ?"
    } else if (this.ConfirmPopupfrom == 'statusResources') {
      this.statusResources(this.permissionId, this.permissionStatus, this.resourcesId);
    } else if (this.ConfirmPopupfrom == 'deactivaterole') {
      this.deactivaterole(this.permissionId, this.permissionStatus, this.resourcesId);
    }
    this.SharedPopupService.dismiss();
  }

  deactivaterole(permissionId, roleStatus, roleId) {
    roleStatus = !roleStatus;
    this.permissionsService.role_deactivate(permissionId, roleStatus, roleId).subscribe(
      data => {
        if (data.status == 'success') {
    //console.log('this.permissionList', this.permissionList);
          this.permissionList[this.permissionIndex].permission_roles[this.resourcesIndex].enable = roleStatus;
          this.sharedService.pushToaster('success', 'Success', 'Role' + this.messageStatus + 'Successfully');
        }
      }
    );
  }
  statusResources(permissionId, resourceStatus, resourcesId) {
    let resources = [];
    resourceStatus = !resourceStatus;
    resources = [
      {
        perm_id: resourcesId,
        isCheck: resourceStatus
      }
    ];

    this.permissionsService.resource_link(permissionId, resources).subscribe(
      data => {
        if (data.status == 'success') {
          this.permissionList[this.permissionIndex].permission_resources[this.resourcesIndex].resource_data.status = resourceStatus;
          this.sharedService.pushToaster('success', 'Success', 'Resources' + this.messageStatus + 'Successfully');
        }
      }
    );
  }
  onCancel() {
    this.SharedPopupService.dismiss();
  }
  changeButtonText(permission) {
    if (permission.type == 'Resources') {
      return 'Map resources';
    } else if (permission.type == 'Roles') {
      return 'Map roles';
    }
  }
  createnewresourcesorrole(createnewtemplate: TemplateRef<any>, selectedResources, index) {
    this.searchModel = '';
    'Resources' == selectedResources.type ? this.dynamicBtn = 'Map resources' : 'Roles' == selectedResources.type ? this.dynamicBtn = 'Map roles' : '';
    // this.displayuserName = selectedResources.username;
    this.selectedResIndex = index;
    this.existmappedResources = [];
    this.deactivateResources = [];
    this.tmpMappingData = [];

    this.selectedItem = selectedResources._id;
    if (selectedResources.permission_resources || selectedResources.permission_roles) {
      let tmpexistingData = _.cloneDeep(selectedResources.type == 'Resources' ? selectedResources.permission_resources : selectedResources.permission_roles);
//console.log('tmpexistingDatatmpexistingDatatmpexistingData', tmpexistingData);
      if (tmpexistingData && tmpexistingData != undefined) {
        if (selectedResources.type != 'Resources') {
          // this.existmappedResources=tmpexistingData;
          tmpexistingData.forEach(element => {
            if (element.enable == true) {
              this.existmappedResources.push(element);
            } else {
              //   this.deactivateResources.push(element)
            }
          });
          this.tmpMappingData = JSON.parse(JSON.stringify(tmpexistingData));
        } else {
          tmpexistingData.forEach(element => {
            if (element.resource_data.status == true) {
              this.existmappedResources.push(element);
            }
            this.tmpMappingData.push(element);
          });
        }
      }

    }

    if (selectedResources.type == 'Resources') {
      this.permissionsService.searchBulkResources('', selectedResources._id).subscribe(data => {
        if (data.status == 'success') {
          this.allPracticesAccountandrole = data.resouceList.length > 0 ? data.resouceList : [];
          for (let tmp_i in this.allPracticesAccountandrole) {
            let tmp = this.allPracticesAccountandrole[tmp_i];
            if ((this.linkedResources).indexOf(tmp._id) > -1) {
              tmp['status'] = true;
            } else {
              tmp['status'] = false;
            }
          }
          this.defaultSelection();
          this.enablemodel(createnewtemplate);
        }
      }, err => {
        this.sharedService.pushToaster('error', 'Error', 'Manage resources failed.');
        this.allPracticesAccountandrole = [];
      });
    } else if (selectedResources.type == 'Roles') {
      this.permissionsService.viewRoles(this.filterresource).subscribe(data => {
        if (data.status == 'success') {
          this.allPracticesAccountandrole = data.results.length > 0 ? data.results : [];
          this.defaultSelection();
          this.enablemodel(createnewtemplate);
        }
      }, err => {
        this.sharedService.pushToaster('error', 'Error', 'Manage roles failed.');
        this.allPracticesAccountandrole = [];
      });
    }
  }

  enablemodel(createnewtemplate) {
    this.SharedPopupService.show(createnewtemplate, {
      animated: true,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }
  cancelmapModal() {
    this.filterresource = '';
    this.SharedPopupService.dismiss();
  }
  selectResources(data, i) {
    if (data.status) {
      if (this.existmappedResources == undefined) {
        this.existmappedResources = [];
      }
      let tmpData = data;
      if (this.dynamicBtn == 'Map resources') {
        data['status'] = true;
        tmpData = {
          _id: this.selectedItem,
          resource_data: data
        };

      let filteredlistIndex = this.permissionList[this.selectedResIndex].permission_resources.findIndex(result => result.resource_data._id === data._id);
      if(filteredlistIndex === -1)
      {
        this.permissionList[this.selectedResIndex].permission_resources.push(tmpData);
      }
      else
      {
        this.permissionList[this.selectedResIndex].permission_resources[filteredlistIndex].resource_data.status = true;
      }
      
      this.permissionsService.addPermissions(data._id, this.selectedItem).subscribe(data => {
        this.sharedService.pushToaster('success', 'Success', 'Resources added successfully');
      }, err => {
        this.SharedPopupService.dismiss();
        this.sharedService.pushToaster('error', 'Error', 'Resources mapping failed.');
      });

      } else {
        data['status'] = true;

      let filteredlistIndex = this.permissionList[this.selectedResIndex].permission_roles.findIndex(result => result._id === data._id);
      if(filteredlistIndex === -1)
      {
        this.permissionList[this.selectedResIndex].permission_roles.push({
          enable: true,
          groupname: data.groupname,
          type: data.type,
          _id: data._id
        });
      }
      else
      {
        this.permissionList[this.selectedResIndex].permission_roles[filteredlistIndex].enable = true;
      }

        this.permissionsService.addPermissionrole(this.selectedItem,data._id).subscribe(data => {
          this.sharedService.pushToaster('success', 'Success', 'Role added successfully');
        }, err => {
          this.SharedPopupService.dismiss();
          this.sharedService.pushToaster('error', 'Error', 'Role mapping failed.');
        });

      }
      this.existmappedResources.push(tmpData);
      




    } else {
      if (this.dynamicBtn == 'Map resources') {
        var index = this.existmappedResources.findIndex(result => result.resource_data._id === data._id);
        this.tmpMappingData.map(elem => {
          if (elem.resource_data._id === data._id) {
            elem.resource_data.status = false;
          }
          return elem;
        });
        this.permissionList[this.selectedResIndex].permission_resources.filter((ele: any) => {
          if(ele.resource_data._id === data._id){
            ele.resource_data.status = false;
          }
        });
        
  //console.log(this.permissionList[this.selectedResIndex].permission_resources)

        this.permissionsService.deletePermissions(data._id, this.selectedItem).subscribe(data => {
          this.sharedService.pushToaster('success', 'Success', 'Resources removed successfully');
        }, err => {
          this.SharedPopupService.dismiss();
          this.sharedService.pushToaster('error', 'Error', 'Resources mapping failed.');
        });
      } else {
        var index = this.existmappedResources.findIndex(result => result._id === data._id);
        this.tmpMappingData.map(elem => {
          if (elem._id === data._id) {
            elem.enable = false;
          }
          return elem;
        });
      }
      this.existmappedResources.splice(index, 1);

      this.permissionList[this.selectedResIndex].permission_roles.filter((ele: any) => {
        if(ele._id === data._id){
          ele.enable = false;
        }
      });

      this.permissionsService.deletePermissionrole(this.selectedItem,data._id).subscribe(data => {
        this.sharedService.pushToaster('success', 'Success', 'Role removed successfully');
      }, err => {
        this.SharedPopupService.dismiss();
        this.sharedService.pushToaster('error', 'Error', 'Role mapping failed.');
      });
    }
  }

  removeMappedResources(resourcesData, indexs) {
    if (resourcesData) {
      if (this.dynamicBtn == 'Map resources') {
        let index = this.allPracticesAccountandrole.findIndex(result => result._id === resourcesData.resource_data._id);
        if (index >= 0) {
          this.allPracticesAccountandrole[index].status = false;
          this.tmpMappingData.map(elem => {
            if (elem.resource_data._id === resourcesData.resource_data._id) {
              elem.resource_data.status = false;

            }
            return elem;
          });
        }
        this.permissionList[this.selectedResIndex].permission_resources.filter((ele: any) => {
          if(ele.resource_data._id === resourcesData.resource_data._id){
            ele.resource_data.status = false;
          }
        });
        this.permissionsService.deletePermissions(resourcesData.resource_data._id, this.selectedItem).subscribe(data => {
          this.sharedService.pushToaster('success', 'Success', 'Resources removed successfully');
        }, err => {
          this.SharedPopupService.dismiss();
          this.sharedService.pushToaster('error', 'Error', 'Resources mapping failed.');
        });
      } else {
        let index = this.allPracticesAccountandrole.findIndex(result => result._id === resourcesData._id);
        if (index >= 0) {
          this.allPracticesAccountandrole[index].status = false;
          this.tmpMappingData.map(elem => {
            if (elem._id === resourcesData._id) {
              elem.enable = false;
            }
            return elem;
          });
    //console.log('this.tmpMappingDatathis.tmpMappingData', this.tmpMappingData);

          this.permissionList[this.selectedResIndex].permission_roles.filter((ele: any) => {
            if(ele._id === resourcesData._id){
              ele.enable = false;
            }
          });

          this.permissionsService.deletePermissionrole(this.selectedItem,resourcesData._id).subscribe(data => {
            this.sharedService.pushToaster('success', 'Success', 'Role removed successfully');
          }, err => {
            this.SharedPopupService.dismiss();
            this.sharedService.pushToaster('error', 'Error', 'Role mapping failed.');
          });
        }
      }
      // console.log(indexs,index);
      this.existmappedResources.splice(indexs, 1);
    }
  }
  defaultSelection() {
    if (this.dynamicBtn == 'Map resources') {
      this.allPracticesAccountandrole.forEach(element => {
        let flag = false;
        this.existmappedResources.forEach(tmp => {
          if (element._id == tmp.resource_data._id) {
            flag = true;
          }
        });
        if (flag == false) {
          element.status = false;
        } else {
          element.status = true;
        }
      });
    } else {
      this.allPracticesAccountandrole.forEach(element => {
        let flag = false;
        this.existmappedResources.forEach(tmp => {
          if (element._id == tmp._id) {
            flag = true;
          }
        });
        if (flag == false) {
          element.status = false;
        } else {
          element.status = true;
        }
      });
    }
  }

  // Filter Resources
  onSearchResources() {
    if (this.filterresource == '') {
      this.checked = false;
    }
    var tmp_prem = [];
    clearTimeout(this.timeout);
    if (this.dynamicBtn == 'Map resources') {
      this.timeout = setTimeout(x => {
        this.permissionsService.resource_list(this.filterresource).subscribe(
          data => {
            if (data.status == 'success') {
              this.allPracticesAccountandrole = data.resouceList.length > 0 ? data.resouceList : [];
              this.defaultSelection();
            } else {
              this.allPracticesAccountandrole = [];
            }
          }
        );
      }, 1000);
    } else {
      this.timeout = setTimeout(x => {
        this.permissionsService.viewRoles(this.filterresource).subscribe(data => {
          if (data.status == 'success') {
            this.allPracticesAccountandrole = data.results.length > 0 ? data.results : [];
            this.defaultSelection();
          } else {
            this.allPracticesAccountandrole = [];
          }
        });
      }, 1000);
    }
  }

  mapNewResources() {
    // console.log(this.tmpMappingData)
    let loopCount = 0;
    let allUsersAccounts = this.tmpMappingData;
    let updatedDataIds = [];
    this.existmappedResources.forEach(element => {
      updatedDataIds.push(element.resource_data._id);
    });
    let mappedData = JSON.parse(JSON.stringify(this.existmappedResources));
    allUsersAccounts.forEach(element => {
      let index = this.existmappedResources.findIndex(result => result.resource_data._id === element.resource_data._id);
      if (index != -1) {
        this.existmappedResources.splice(index, 1);
      }

      if (updatedDataIds.indexOf(element.resource_data._id) == -1) {
        mappedData.push(element);
      }
    });
    let finalmappedData = [];
    let i = 0;
    mappedData.forEach(element => {
      let selectedResourcesData = {
        perm_id: element.resource_data._id,
        isCheck: element.resource_data.status
      };
      finalmappedData.push(selectedResourcesData);
      i++;
    });
    if (i == mappedData.length) {
      this.permissionsService.bulkResourcesInsert(this.permissionList[this.selectedResIndex]._id, finalmappedData).subscribe(data => {
        if (this.permissionList[this.selectedResIndex]['permission_resources_limit'] == undefined) {
          this.permissionList[this.selectedResIndex]['permission_resources_limit'] = 10;
        }
        this.permissionList[this.selectedResIndex].permission_resources = mappedData;
        this.SharedPopupService.dismiss();
        this.filterresource = '';
        this.sharedService.pushToaster('success', 'Success', 'Resources mapped successfully');
      }, err => {
        this.SharedPopupService.dismiss();
        this.sharedService.pushToaster('error', 'Error', 'Bulk resources mapping failed.');
      });
    }
  }
  mapNewRole() {
    let rolesData = [];
    let roleList = [];
    let roleIds = [];
    this.existmappedResources.forEach(element => {
      roleIds.push(element._id);
      roleList.push(element);
      rolesData.push({
        role_id: element._id,
        isCheck: true
      });
    });
    this.tmpMappingData.forEach(element => {
      if (roleIds.indexOf(element._id) == -1) {
        rolesData.push({
          role_id: element._id,
          isCheck: false
        });
        roleList.push(element);
      }
    });
    let rolesDatas = {
      'roles': rolesData
    };
    this.permissionsService.bulkRolesLink(this.permissionList[this.selectedResIndex]._id, rolesDatas).subscribe(data => {
      if (data.status == 'success') {
        if (this.permissionList[this.selectedResIndex]['permission_roles_limit'] == undefined) {
          this.permissionList[this.selectedResIndex]['permission_roles_limit'] = 10;
        }

        let ids = [];
        rolesData.forEach(element => {
          this.tmpMappingData.forEach(ele => {
            if (element.role_id == ele._id) {
              ele.enable = element.isCheck;
            }
          });
        });
        let list_permission_roles = [];

        this.existmappedResources.forEach(elem => {
          if (ids.indexOf(elem._id) == -1) {
            ids.push(elem._id);
            list_permission_roles.push(elem);
          }
        });

        this.deactivateResources.forEach(elem => {
          if (ids.indexOf(elem._id) == -1) {
            list_permission_roles.push(elem);
          }
        });
        this.permissionList[this.selectedResIndex].permission_roles = roleList; // this.existmappedResources.concat(this.deactivateResources)
        this.filterresource = '';
        this.sharedService.pushToaster('success', 'Success', 'Roles mapped successfully');
        this.SharedPopupService.dismiss();
      }
    }, err => {
      this.SharedPopupService.dismiss();
    });
  }
  ngOnDestroy(): void {
    this.SharedPopupService.dismiss();
  }
}
