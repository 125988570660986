import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import * as myGlobals from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class PatientsService {

  constructor(private http: HttpClient) { }

  allPatients(data) {
    let searchValue = data.searchKey;
    let page = data.page;
    let limit = data.limit;
    let patient_type = data.patient_type ? data.patient_type : '';
    let created_date = data.created_date ? data.created_date : '';
    let last_modified_date = data.last_modified_date ? data.last_modified_date : '';
    let practice_id = '';
    if (data.practice_id) {
      practice_id = '&practice_id=' + data.practice_id;
    }
    //console.log(practice_id);
    return this.http.get<any>(myGlobals.patients_URL + '?searchKey=' + searchValue + '&skip=' + page + '&patient_type=' + patient_type + '&created_date=' + created_date + '&last_modified_date=' + last_modified_date + '&limit=' + limit + practice_id);
  }
  exportPatient(data) {
    let searchValue = data.searchKey;
    let page = data.page;
    let limit = data.limit;
    let patient_type = data.patient_type ? data.patient_type : '';
    let created_date = data.created_date ? data.created_date : '';
    let last_modified_date = data.last_modified_date ? data.last_modified_date : '';
    let practice_id = '';
    if (data.practice_id) {
      practice_id = '&practice_id=' + data.practice_id;
    }
    //console.log(practice_id);
    return this.http.get(myGlobals.patients_URL + '/export/' + '?searchKey=' + searchValue + '&skip=' + page + '&patient_type=' + patient_type + '&created_date=' + created_date + '&last_modified_date=' + last_modified_date + '&limit=' + limit + practice_id, { responseType: 'blob' });

  }
  singlePatient(patient_id) {
    return this.http.get<any>(myGlobals.patients_URL + '/' + patient_id);
  }
  patient_history_list(patientId) {
    return this.http.get<any>(myGlobals.patients_URL + '/' + patientId + '/series');
  }
  savePatientInfo(patientData) {
    return this.http.post<any>(myGlobals.patients_URL, patientData).pipe(tap(res => {
      return res;
    }));
  }
  getPatientDocuments(ref_type, ref_id) {
    return this.http.get<any>(myGlobals.Get_ServiceLayer_Url + '/files/' + ref_type + '/' + ref_id);
  }
  getChargeHistotyPatients(patient_id) {
    return this.http.get<any>((myGlobals.patients_URL + '/' + patient_id + '/visits'));
  }
  getPatientCoverages(id) {
    return this.http.get<any>(myGlobals.patients_URL + '/' + id + '/coverages');
  }
  getPccInsurance(charge_id){
    return this.http.get<any>(myGlobals.patients_URL+'/'+ charge_id + '/insurance')
  }
}
