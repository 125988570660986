import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'underscore';
import { PracticesService } from '../../../_services/settings/practices.service';
import { CustomListService } from '../../../_services/settings/custom-list.service';
import { Observable } from 'rxjs/Rx';
import { NgForm } from '@angular/forms';
import { SharedService } from '../../../_services/shared.service';
import { SharedPopupService } from '../../../_services/popup.service';
import constants from '../../../constants';
import { Constants } from '@ag-grid-community/all-modules';

@Component({
  selector: 'app-custom-list',
  templateUrl: './custom-list.component.html',
  styleUrls: ['./custom-list.component.css']
})
export class CustomListComponent implements OnInit, OnDestroy {
  customListData: any;


  ConfirmPopupfrom: String;
  messageInfo: String;
  popupMode: String;
  customListType: string;
  listApplyFor: any[];
  listName: string;
  displayCodes: any;
  popupTitle: string;
  accountListData: any;
  applyOptions: any[];
  selectedItem: any;
  selected_list_name: string;
  localCodeTypeName: any[];
  togglecollapseVar: any;
  isCollapse: any;
  searchtext: string;
  tempAllCustomListData: any;
  loaderIndicator:boolean;
  constants:Constants;
  constructor(public bsModalService: BsModalService,
    public modalService: BsModalService,
    public SharedPopupService: SharedPopupService,
    private practiceService: PracticesService,
    private sharedService: SharedService,
    private customListService: CustomListService,) {
    this.customListData = [];
    this.processCustomList();
    this.customListType = '';
    this.listApplyFor = [];
    this.listName = '';
    this.displayCodes = '';
    this.popupTitle = '';
    this.accountListData = [];
    this.applyOptions = [];
    this.selectedItem = '';
    this.selected_list_name = '';
    this.togglecollapseVar = '';
    this.isCollapse = {};
    this.searchtext = '';
    this.tempAllCustomListData = [];
    this.loaderIndicator=true;
    this.constants=constants;
    this.localCodeTypeName = [
      {
        key: 'procedure_code',
        value: 'Procedure codes'
      },
      {
        key: 'diagnosis_code',
        value: 'Diagnosis codes'
      },
      {
        key: 'service_location',
        value: 'Service location'
      }
    ]
  }
  ngOnInit() {

  }

  processCustomList() {
    this.loaderIndicator=true;
    this.customListData=[];
    Observable.forkJoin([this.practiceService.fetchAccountGroupList(), this.customListService.getAllCustomListData()]).subscribe(data => {
      this.loaderIndicator=false;
      let allAccountData = data[0].results;
      let allCustomListData = data[1].result;
      this.tempAllCustomListData = data[1].result
      if (data[0].status == 'success' && data[1].status == 'success') {
        this.loadAllData(allAccountData, allCustomListData)
      }
    });
  }

  loadAllData(allAccountData, allCustomListData) {
    if (allAccountData.length > 0) {
      for (let tmp of allAccountData) {
        let temp = {};
        temp['key'] = tmp._id;
        temp['value'] = tmp.accountgroup;
        this.applyOptions.push(temp);
      }
      this.applyOptions.push({
        key: 'global',
        value: 'All account groups'
      });
    }
    this.accountListData = allAccountData;
    if (allCustomListData.length > 0) {
      allCustomListData.forEach((element, i) => {
        element.type = this.localCodeTypeName.filter(item => item.key == element.type);
        element.type = element.type[0].value;
      });
      this.customListData = allCustomListData;
    }
  }
  openPopup(template: TemplateRef<any>, popupMode: String, selectedItem, i) {
    this.popupMode = popupMode;
    this.selectedItem = selectedItem;
    this.displayCodes = '';
    if (this.popupMode == 'addcustomList') {
      this.popupTitle = 'Add new custom list';
      this.customListType = '';
      this.listApplyFor = ['global'];
      this.listName = '';
    } else if (this.popupMode == 'editCustomList') {
      this.popupTitle = 'Edit custom list';
      this.customListType = selectedItem.type;
      this.listApplyFor = selectedItem.apply_to;
      selectedItem.data.forEach(element => {
        this.displayCodes = this.displayCodes + element.Code + ',';
      });
      this.listName = selectedItem.name;
    }
    this.SharedPopupService.show(template, { animated: true, backdrop: 'static', keyboard: false });
  }
  closePopup() {
    this.SharedPopupService.dismiss();
  }

  validateCustomList(model, isValid: boolean, myform: NgForm) {
    let tempCodeData = [];
    let displayCodeArray = model.display_codes.split(',').map(function (item) {
      let temp = item.trim();
      if (temp != '') {
        tempCodeData.push(temp);
      }
    });
    let findDuplicate = tempCodeData.some(x => tempCodeData.indexOf(x) !== tempCodeData.lastIndexOf(x));
    if (!findDuplicate && isValid) {
      if (this.popupMode == 'addcustomList') {
        let reqFormat = {
          apply_to: this.listApplyFor,
          type: model.type,
          name: model.list_name,
          update_code: tempCodeData
        };
        this.customListService.addNewCustomList(reqFormat).subscribe(newData => {
          if (newData.status == 'success') {
            this.sharedService.pushToaster('success', 'Success', 'Custom list added successfully');
            if (reqFormat.update_code) {
              reqFormat['data'] = [];
              (reqFormat.update_code).forEach(code => {
                code = code.trim();
                if (code.indexOf('-') > -1) {
                  let tmprange = code.split('-');
                  for (let i = tmprange[0]; i <= tmprange[1]; i++) {
                    reqFormat['data'].push({
                      Code: i
                    });
                  }
                } else {
                  reqFormat['data'].push({
                    Code: code
                  });
                }
              });
            }
            reqFormat['_id'] = newData.result._id;
            delete reqFormat.update_code;
            this.customListData.push(reqFormat);
            this.closePopup();
          }
          else if (newData.status == 'failure') {
            this.sharedService.pushToaster('error', 'error', newData.message);
          }
        });
      }
      if (this.popupMode == 'editCustomList') {
        let reqFormat = {
          update_code: tempCodeData,
          apply_to: this.listApplyFor
        };
        this.customListService.editCustomList(reqFormat, this.selectedItem._id).subscribe(newData => {
          if (newData.status == 'success') {
            this.sharedService.pushToaster('success', 'Success', 'Custom list updated successfully');
            if (tempCodeData) {
              this.selectedItem.data = [];
              tempCodeData.forEach(code => {
                code = code.trim();
                if (code.indexOf('-') > -1) {
                  let tmprange = code.split('-');
                  for (let i = tmprange[0]; i <= tmprange[1]; i++) {
                    this.selectedItem.data.push({
                      Code: i
                    });
                  }
                } else {
                  this.selectedItem.data.push({
                    Code: code
                  });
                }
              });
            }
            this.selectedItem.apply_to = this.listApplyFor;
            this.closePopup();
          }
        });

      }
    } else {
      if (!isValid) {
        this.sharedService.pushToaster('error', 'Validation', 'Form validation error');
      }
      if (findDuplicate) {
        this.sharedService.pushToaster('error', 'Validation', 'List code has to be unique');
      }
    }
  }
  ConfirmPopup(Confirmtemplate: TemplateRef<any>, from, selected_data) {
    this.ConfirmPopupfrom = from;
    this.selected_list_name = selected_data.name;
    this.selectedItem = selected_data;
    if (this.ConfirmPopupfrom == 'customListStatus') {
      this.messageInfo = 'Are you sure want to delete custom list';
    }
    this.SharedPopupService.show(Confirmtemplate, {
      animated: true,
      backdrop: 'static',
    });
  }
  onConfirm() {
    this.customListService.deleteCustomList(this.selectedItem._id).subscribe(newData => {
      if (newData.status == 'success') {
        this.sharedService.pushToaster('success', 'Success', 'Custom list updated successfully');
        let index = this.customListData.findIndex(list => list._id == this.selectedItem._id);
        this.customListData.splice(index, 1);
        this.SharedPopupService.dismiss();
      }
    });
  }
  onCancel() {
    this.SharedPopupService.dismiss();
  }
  onSearchChange(text) {
    this.loaderIndicator=true;
    let result = [];
    text = text.trim();
    if (text != '') {
      this.tempAllCustomListData.forEach((element, i) => {
        if ((element.name).search(new RegExp(text, 'i')) > -1) {
          result.push(element);
        }
        return result;
      });
      this.customListData = result;
    } else {
      this.customListData = this.tempAllCustomListData;
    }
    this.loaderIndicator=false;
  }
  getGroupName(key) {
    for (let tmp of this.applyOptions) {
      if (tmp.key == key) {
        return tmp.value;
      }
    }
  }

  ngOnDestroy(): void {
    this.SharedPopupService.dismiss();
  }

}
