import { Component, TemplateRef, OnInit, ViewEncapsulation, HostListener, OnDestroy } from '@angular/core';

import { NgForm } from '@angular/forms';
import { DiagnosisCodesService } from '../../../../_services/settings/diagnosis-codes.service';
import { SharedService } from '../../../../_services/shared.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subject } from 'rxjs/Rx';
import { SharedPopupService } from '../../../../_services/popup.service';
import constants from '../../../../constants';
import regiondateformat from '../../../../regiondateformat';
import { HeaderService } from '../../../../_services/header/header.service';
class Diagnosis {
  type: String;
  code: String;
  description: String;
  custom_description: String;
  enabled: Boolean;
  from_date: string;
  to_date: string;
}

@Component({
  selector: 'app-diagnosis-codes',
  templateUrl: './diagnosis-codes.component.html',
  styleUrls: ['./diagnosis-codes.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DiagnosisCodesComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  timeout: any;
  popupMode: String;
  addStatusMessage: String;
  popupTitle: String;
  public diagnosis: Diagnosis;

  searchtext: String = '';
  diagnosisData: any;
  ConfirmPopupfrom: String;
  diagnosisCode: any;
  diagnosisStatus: boolean;
  mobileviewTaskcontent: boolean;
  diagnosisCodeAddTotalCount: number;
  diagnosisCodeAddPage: number;
  diagnosisCodeAddCurrentPage: number;
  diagnosisCodeAddPageskip: number;
  allPaginationDefaultLimit: number;
  userIndex: number;
  messageStatus: String;
  messageInfo: String;

  inactiveFlag = true;
  showInactive: boolean;
  diagnosisCodeId: string;
  diagnosisArrayIndex: number;
  typeList: any[];
  public dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  from_date: any;
  to_date: any;
  date_validation: any;
  enableSaveButton: boolean;
  innerWidth: number;
  loaderIndicator:boolean;
  constants:any;
  country:any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    this.screenValues();
  }
  constructor(private sharedService: SharedService, public SharedPopupService: SharedPopupService, public diagnosiscodesService: DiagnosisCodesService,    private headerService: HeaderService
    ) {
    this.onSearchChange(this.searchtext);
    // this.inactiveFlag = true;
    this.diagnosisData = [];
    this.enableSaveButton = false;
    this.diagnosisCodeAddTotalCount = 0;
    this.diagnosisCodeAddPage = 0;
    this.diagnosisCodeAddCurrentPage = 1;
    this.diagnosisCodeAddPageskip = 0;
    this.allPaginationDefaultLimit = 20;
    this.innerWidth = window.innerWidth;
    this.loaderIndicator=true;
    this.constants=constants;
    this.screenValues();
    this.country = localStorage.getItem('Region');
    if(this.country=="UAE"){
      this.headerService.versionEmitted$.subscribe(data => {
        this.onSearchChange(this.searchtext, 0, 20);
      });
    }
   
  }

  ngOnInit() {
    this.diagnosis = {
      type: '',
      code: '',
      description: '',
      custom_description: '',
      enabled: true,
      from_date: '',
      to_date: ''
    };
    this.typeList = ['ICD10', 'ICD9'];
  }

  openPopup(template: TemplateRef<any>, poupMode: String, listItem: any, diagnosisarrayIndex) {

    this.popupMode = poupMode;
    this.addStatusMessage = '';
    this.date_validation = '';
    this.from_date = '';
    this.to_date = '';
    this['valideerrordatefrom'] = true;
    this['valideerrordateto'] = true;

    if ('addDiagnosis' == this.popupMode) {
//console.log('inside add diagnosis');
      this.popupTitle = 'Add new diagnosis code';
      this.diagnosis = {
        type: 'ICD10',
        code: '',
        description: '',
        custom_description: '',
        enabled: true,
        from_date: '',
        to_date: ''
      };
    } else if ('editDiagnosis' == this.popupMode) {
      if (listItem.from_date && listItem.from_date != null && listItem.from_date != 'Invalid date') {
        listItem.from_date =listItem.from_date
      } else {
        listItem.from_date = '';
      }
      if (listItem.to_date && listItem.to_date != null && listItem.to_date != 'Invalid date') {
        listItem.to_date =listItem.to_date
      } else {
        listItem.to_date = '';
      }
      this.diagnosisCodeId = listItem._id;
      this.diagnosisArrayIndex = diagnosisarrayIndex;
      this.popupTitle = 'Edit diagnosis code';
      this.diagnosis = {
        type: listItem.type,
        code: listItem.code,
        description: listItem.description,
        custom_description: listItem.custom_description,
        enabled: listItem.enabled,
        from_date: listItem.from_date,
        to_date: listItem.to_date
      };
      this.datechangedata(this.diagnosis.to_date, 'dateto', 0);
    }
    this.SharedPopupService.show(template, { animated: true, backdrop: 'static', keyboard: false });
  }
  closePopup() {
    this.SharedPopupService.dismiss();
  }
  /* validate and called add/edit method */
  validateDiagnosisCode(model: Diagnosis, isValid: boolean, myform: NgForm) {
    if (isValid == true && (this.date_validation == false || this.date_validation == '')) {
      this.enableSaveButton = true;
      this.diagnosis = {
        type: model.type,
        code: model.code.toUpperCase(),
        description: model.description,
        custom_description: model.custom_description,
        enabled: true,
        from_date: model.from_date ? model.from_date : null,
        to_date: model.to_date ? model.to_date : null,

      };
      let diagnosis = JSON.parse(JSON.stringify(this.diagnosis));
      if (diagnosis.from_date && diagnosis.from_date.length > 0 && diagnosis.from_date.length != null) {
        diagnosis.from_date = diagnosis.from_date
      }
      if (diagnosis.to_date && diagnosis.to_date.length > 0 && diagnosis.to_date.length != null) {
        diagnosis.to_date = diagnosis.to_date
      }
      if (this.popupMode == 'addDiagnosis') {
        this.diagnosiscodesService.addDiagnosisCode(diagnosis).subscribe((response: any) => {
          this.enableSaveButton = false;
          if (response.status == 'success') {
            this.diagnosisData.unshift(response.results);
            this.sharedService.pushToaster('success', 'Added', 'New diagnosis code added successfully.');
            myform.resetForm();
            this.closePopup();
          } else {
            this.sharedService.pushToaster('error', 'Error', response.Message);
            myform.resetForm();
            this.closePopup();
          }
        });
      } else if (this.popupMode == 'editDiagnosis') {
        this.diagnosiscodesService.editDiagnosisCode(diagnosis, this.diagnosisCodeId).subscribe((response: any) => {
          this.enableSaveButton = false;
          if (response.status == 'success') {
            if (response.from_date || response.to_date) {
              response.from_date = response.from_date
              response.to_date = response.to_date
            }
            this.diagnosisData[this.diagnosisArrayIndex] = _.assign({}, this.diagnosisData[this.diagnosisArrayIndex], response.results);
            this.sharedService.pushToaster('success', 'Updated', 'Diagnosis code updated successfully.');
            this.closePopup();
          } else {
            this.sharedService.pushToaster('error', 'Error', response.Message);
            myform.resetForm();
            this.closePopup();
          }
        }, (err) => {
          if (err.error.error.includes('duplicate key')) {
            this.sharedService.pushToaster('error', 'Error', 'Diagnosis code Already Exists');
          } else {
            this.sharedService.pushToaster('error', 'Error', err.error.error);
          }
          myform.resetForm();
          this.closePopup();
        });
      }
    } else {
      this.sharedService.pushToaster('error', 'Error', 'form validation error');
    }
    //  isValid&&("addDiagnosis"==this.popupMode?this.addDiagnosisCode(this.diagnosis):"editDiagnosis"==this.popupMode&&this.editDiagnosisCode(this.diagnosisCodeId));
  }


  onSearchChange(searchValue, page = 0, limit = 20, type = '') {
    this.loaderIndicator=true;
    this.diagnosisData = [];
    this.ngUnsubscribe.next();
    if (type === '') {
      this.resetAllPagination();
    }
    let tempResult = [];
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
    this.diagnosiscodesService.searchdiagnosiscode(searchValue, this.inactiveFlag, page, limit).debounceTime(400).takeUntil(this.ngUnsubscribe).subscribe(data => {      
      this.loaderIndicator=false;
      if (data) {
        tempResult = data.results;
        
        tempResult.forEach(element => {
          if (element.from_date || element.to_date) {
            element.from_date = element.from_date
            element.to_date = element.to_date
          }
        });
        this.diagnosisData = data.results;
        this.diagnosisCodeAddTotalCount = data['totalCount'];
      } else {
        this.diagnosisData = [];
      }
    }, err => console.log(err));
  },500);
  }

  ConfirmPopup(Confirmtemplate: TemplateRef<any>, from, code, diagnosisStatus, i) {
    this.ConfirmPopupfrom = from;
    this.diagnosisCode = code;
    this.diagnosisStatus = diagnosisStatus;
    this.userIndex = i;
    this.messageStatus = (this.diagnosisStatus == true) ? ' Deactivate ' : ' Activate ';
    if (this.ConfirmPopupfrom == 'diagnosisStatus') {
      this.messageInfo = 'Are you sure want to' + this.messageStatus + 'Diagnosis Code ?';
    }
    this.SharedPopupService.show(Confirmtemplate, {
      animated: true,
      backdrop: 'static',
    });
  }

  onConfirm() {
    if (this.ConfirmPopupfrom == 'diagnosisStatus') {
      this.changeStatus(this.diagnosisCode, this.diagnosisStatus);
      // this.messageInfo = "Are you sure want to Deactivate Account group ?"
    }
    this.SharedPopupService.dismiss();

  }

  changeStatus(code, status) {
    let enabled = !status;
    this.diagnosiscodesService.activeDiagnosis(code, enabled).subscribe(data => {
      if (data) {
        this.onSearchChange(this.searchtext);
        this.sharedService.pushToaster('success', 'Success', 'Diagnosis Code' + this.messageStatus + 'Successfully');
      }
    });

  }

  onCancel() {
    this.SharedPopupService.dismiss();
  }

  activeStatus() {
    this.loaderIndicator=true;
    this.diagnosisData =[];
    this.resetAllPagination();
    this.inactiveFlag = (this.showInactive == true) ? true : false;
    // console.log('this.inactiveFlag ', this.inactiveFlag);
    this.diagnosiscodesService.searchdiagnosiscode(this.searchtext, this.inactiveFlag).subscribe((userList: any) => {
      this.diagnosisData = userList.results;
      this.loaderIndicator=false;
      this.diagnosisCodeAddTotalCount = userList.count;      
    });
  }
  datechangedata(value, from, index) {
    let dateSplit = value.split('/');
    let datePattern = '^[0-9]{2}/[0-9]{2}/[0-9]{4}$';
    let regExVal = new RegExp(datePattern);
    let patternMatchBool = regExVal.test(value);
    if (from == 'datefrom') {
      this.from_date = regiondateformat(value);
    }
    if (from == 'dateto') {
      this.to_date =  regiondateformat(value);
    }
    if (this.popupMode == 'editDiagnosis') {
      if (this.diagnosis.from_date != null) {
        this.from_date = regiondateformat(this.diagnosis.from_date)
      }
      if (this.diagnosis.to_date != null) {
        this.to_date = regiondateformat(this.diagnosis.to_date)
      }
      this.date_validation = moment(this.to_date).diff(this.from_date);
      this.date_validation = (this.date_validation < 0) ? true : false;
    } else {
      if (this.from_date != 'Invalid date' && this.from_date != undefined && this.from_date != '' && this.to_date != 'Invalid date' && this.to_date != undefined && this.to_date != '') {
        this.date_validation = moment(this.to_date).diff(this.from_date);
        this.date_validation = (this.date_validation < 0) ? true : false;
      }
    }
    if (value != undefined) {
      if(this.country=="US"){
        let date = moment(value, 'MM/DD/YYYY');
        let dynamicvar = 'valideerror' + from;
        this[dynamicvar] = date.isValid();
        if (dateSplit[2] == '0000' || patternMatchBool == false) {
          this[dynamicvar] = false;
        }
      }else if(this.country=="UAE"){
        let date = moment(value, 'DD/MM/YYYY');
        let dynamicvar = 'valideerror' + from;
        this[dynamicvar] = date.isValid();
        if (dateSplit[2] == '0000' || patternMatchBool == false) {
          this[dynamicvar] = false;
        }
        
      }
     
    }
  }

  diagnosisCodeAddPageChanged(ev) {
    this.diagnosisCodeAddPage = ev.page * this.allPaginationDefaultLimit - this.allPaginationDefaultLimit;
    this.onSearchChange(this.searchtext, this.diagnosisCodeAddPage, this.allPaginationDefaultLimit, 'Pagination');
  }

  resetAllPagination() {
    this.diagnosisCodeAddPage = 0;
    this.diagnosisCodeAddCurrentPage = 1;
    this.diagnosisCodeAddPageskip = 0;
    this.diagnosisCodeAddTotalCount = 0;
  }


  screenValues() {
    if (this.innerWidth < 768) {
      // mobile
      this.mobileviewTaskcontent = false;
    } else if (this.innerWidth < 1200) {
      // big tablet screen or laptop
      this.mobileviewTaskcontent = true;
    } else {
      // desktop
      this.mobileviewTaskcontent = true;
    }
  }

  ngOnDestroy() {
    this.resetAllPagination();
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();
    this.SharedPopupService.dismiss();
  }
}
