import { Injectable } from '@angular/core';
import { Subject, of } from 'rxjs';
import * as myGlobals from '../globals';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private emitChangeSource = new Subject<any>();
  globalData = {};
  constructor(private http: HttpClient) {
  }
  // Observable string sources

  // Observable string streams
  changeEmitted$ = this.emitChangeSource.asObservable();
  // Service message commands
  pushToaster(type, title, message) {
    let change = {
      type: type ? type : 'info',
      title: title,
      message: message
    };
    this.emitChangeSource.next(change);
  }
  getGlobalData(type) {
    //console.log('Hello getGlobalData Provider');
    let url = myGlobals.globaldata_URL + '/' + type;
    if (this.globalData[type] && Object.keys(this.globalData[type]).length > 0) {
      return of(false);
    } else {
      if (!this.globalData[type]) {
        this.globalData[type] = {};
      }
      return this.http.get<any>(url).pipe(tap<any>(result => {
        if (result.status == 'success' && result.results.length > 0) {
          this.globalData[type] = result.results[0].values;
        }
      }));

    }

  }
}
