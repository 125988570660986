import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as myGlobals from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class BulkCodeService {

  constructor(private http: HttpClient) { }

  searchBulkCode(searchKey: string, page: number, limit: number) {
    let url =myGlobals.bulkCode_URL + '?searchKey=' + searchKey + '&page=' + page + '&limit=' + limit
    let version=localStorage.getItem('UaeVersion')
    if(version && version.length>0){
      url=url+'&version='+ version
    }
    return this.http.get<any>(url);
  }

  selectedBulkCode(bulkCode_id, viewCodeType, page, limit, action) {
    return this.http.get<any>(myGlobals.bulkCode_URL + '/' + bulkCode_id + '?codeType=' + viewCodeType + '&page=' + page + '&limit=' + limit + '&action=' + action);
  }
  approveCode(bulkCode_id) {
    return this.http.put<any>(myGlobals.bulkCode_URL + '/' + bulkCode_id + '/approve', '');
  }
  downloadTemplate() {
    return this.http.get(myGlobals.bulkCode_URL +'/download/template',{ responseType: 'blob' });
  }
  declineFile(id){
    return this.http.delete<any>(myGlobals.bulkCode_URL+'/'+id);
  }
}
