import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  constructor(private http: HttpClient) {

  }

  getResources(searchKey: string, showactive: boolean) {
    return this.http.get(`${myGlobals.Resource_URL}?q=${searchKey}&showactive=${showactive ? false : true}&pagesize=10`);
  }

  getPermissionList(searchKey: string) {
    return this.http.get(`${myGlobals.Base_URL}/permissions?q=${searchKey}&status=true&page=1&size=30`);
  }

  viewpermission(id: string) {
    return this.http.get(`${myGlobals.Resource_URL}${id}/permissions`);
  }

  addResource(newresource: any) {
    return this.http.post(myGlobals.Resource_URL, newresource);
  }

  editResource(editresource: any, id: string) {
    return this.http.put(`${myGlobals.Resource_URL}${id}`, editresource);
  }

  activeResource(id: string, enable: boolean) {
    return this.http.put(`${myGlobals.Resource_URL}${id}/status`, { enable: enable });
  }

  addPermissionList(resourceid: string, updatedPermissions: any) {
    return this.http.put(`${myGlobals.Resource_URL}${resourceid}/permissions`, { updatedPermissions: updatedPermissions });
  }

  addPermission(resourceid: string, permissionsid: any) {
    return this.http.post(`${myGlobals.Resource_URL}${resourceid}/permissions/${permissionsid}`,{});
  }

  deletePermission(resourceid: string, permissionsid: any) {
    return this.http.delete(`${myGlobals.Resource_URL}${resourceid}/permissions/${permissionsid}`);
  }

  deleteAnPermission(resourceid: string, permissionsid: any) {
    return this.http.delete(`${myGlobals.Resource_URL}${resourceid}/permissions/${permissionsid}`);
  }

  deleteAllPermission(resourceid: string) {
    return this.http.delete(`${myGlobals.Resource_URL}${resourceid}/permissions`);
  }
}
