import { Directive, HostListener, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { TaskService } from '../_services/task/task.service';

@Directive({
  selector: '[appArtFormValidation]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ArtFormValidationDirective, multi: true }]

})
export class ArtFormValidationDirective {

  constructor( public taskService: TaskService) { }
  @Input() appArtFormValidation: string;
  patternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      let validationBool;
      let regExPattern;
      switch(this.appArtFormValidation) {
        case 'numericOnly':
        regExPattern = "^[0-9]{0,30}$";
        break;
        case 'noFutureDate':
        let present_date_1 = moment().format("YYYY-MM-DD");
        let denied_date  = moment(control.value).format("YYYY-MM-DD");
        let task_dos_date_1 = moment(this.taskService.task_dos).format("YYYY-MM-DD");
         let aftertask_dosDate_1 = moment(denied_date).isAfter(task_dos_date_1);
        let presentdays = moment(denied_date).isSameOrBefore(present_date_1);
        if(aftertask_dosDate_1 && presentdays) {
          validationBool = false;
        } else {
          validationBool = true;
        }
        break;
        case 'checkDate':
        let current_date = moment();
        current_date = moment(current_date, 'YYYY-MM-DD').add('days', 10);
        let input_date  = moment(control.value, "YYYY-MM-DD");
        let original_bill_date = moment(this.taskService.ar_original_bill_date, "YYYY-MM-DD");
        let afterOriginalDate = moment(input_date).isAfter(original_bill_date);
        let presentAnd10Days = moment(input_date).isSameOrBefore(current_date);
      
        if(afterOriginalDate && presentAnd10Days) {
          validationBool = false;
        } else {
          validationBool = true;
        }
        break;
        case 'npiNum':
        regExPattern = "^[1-9]{1}[0-9]{9}$"
        break;
        case 'taxId':
        regExPattern = "^[0-9]{2}-[0-9]{7}$" //"^[1-9]{1}[0-9]{8}$" 
        break;
        case 'phoneFax':
        regExPattern = "^[0-9]{3}-[0-9]{3}-[0-9]{4}$"
        break;
        case '60DFromCurrent':
        let current_date_check1 = moment( );
        let current_date_check = moment(current_date_check1, 'YYYY-MM-DD').format('YYYY-MM-DD');
        let input_date_check  = moment(control.value, "YYYY-MM-DD").format('YYYY-MM-DD');
        let current_date_60d = moment(this.taskService.task_cretaed_date);
        current_date_60d = moment(current_date_60d, 'YYYY-MM-DD');
        let allowable60Date=moment(current_date_60d, 'YYYY-MM-DD').add('days', 60);
        let input_date_60d  = moment(control.value, "YYYY-MM-DD");
       // let diffDays_60d = moment.duration(input_date_60d.diff(current_date_60d)).asDays();
        // if( diffDays_60d >= 0 && diffDays_60d <= 60 ) {
        if( (moment(input_date_check).isSameOrAfter(current_date_check) && moment(input_date_check).isSameOrBefore(allowable60Date))) {
            validationBool = false;
        } else {
          // let temp = diffDays_60d.toString().split('.');
          // let temp1 = temp[0];
          // if(temp1 == '-0'){
            // validationBool = false;
          // }else{
            validationBool = true;
          // }
        }
        break;
        case 'receivedDate':
        let present_date = moment().format("YYYY-MM-DD");
        let receivedDate  = moment(control.value).format("YYYY-MM-DD");
        let temp_receive = moment(receivedDate).isAfter(this.taskService.task_dos);
        let temp_current = moment(present_date).isSameOrAfter(this.taskService.task_dos);
        let future_received = moment(receivedDate).isSameOrBefore(present_date);

        if(temp_receive && temp_current && future_received) {
          validationBool = false;
        } else {
          validationBool = true;
        }
        
        break;
        default:
          //code block
      }
      if( this.appArtFormValidation == 'npiNum' || this.appArtFormValidation =='taxId' || this.appArtFormValidation == 'phoneFax' || this.appArtFormValidation == 'numericOnly') {

        const regex = new RegExp(regExPattern);
        const valid = regex.test(control.value);
        return valid ? (undefined || null) : { invalidValue: true };

      } else if(this.appArtFormValidation == 'noFutureDate' || this.appArtFormValidation == 'checkDate' || this.appArtFormValidation == '60DFromCurrent' || this.appArtFormValidation == 'receivedDate') {
        return validationBool ? { invalidValue: true } : (undefined || null) ;

      } else if (this.appArtFormValidation == 'fromDate' || this.appArtFormValidation == 'todate') {
        let from_tmp;
        let to_temp
        if (this.appArtFormValidation == 'fromDate') {
          from_tmp = moment(control.value, "YYYY-MM-DD");
          this.taskService.policy_from_date = from_tmp;
        }
        if (this.appArtFormValidation == 'todate') {
          to_temp = moment(control.value, "YYYY-MM-DD");
          this.taskService.policy_to_date = to_temp;
        }
        let auth_days;
        auth_days = moment(this.taskService.policy_to_date).diff(this.taskService.policy_from_date);
        if (auth_days) {
          return auth_days < 0 ? { invalidValue: true } : (undefined || null);
        } else {
          return { invalidValue: true };
        }
      } else if (this.appArtFormValidation == 'authFromDate' || this.appArtFormValidation == 'authTodate') {
        let auth_from_tmp;
        let auth_to_temp
        if (this.appArtFormValidation == 'authFromDate') {
          auth_from_tmp = moment(control.value, "YYYY-MM-DD");
          this.taskService.auth_from_date = auth_from_tmp;
        }
        if (this.appArtFormValidation == 'authTodate') {
          auth_to_temp = moment(control.value, "YYYY-MM-DD");
          this.taskService.auht_to_date = auth_to_temp;
        }
        let auth_days;
        if (this.taskService.auth_from_date && this.taskService.auht_to_date) {
          auth_days = moment.duration(this.taskService.auht_to_date.diff(this.taskService.auth_from_date)).asDays();
          if (auth_days > 0 && auth_days <= 180) {
            let temp_btween_date = moment(this.taskService.task_dos).format('YYYY-MM-DD').toString();
            let auth_from_tmp1 = moment(this.taskService.auth_from_date).format("YYYY-MM-DD").toString();
            let auth_to_temp1 = moment(this.taskService.auht_to_date).format("YYYY-MM-DD").toString();
            let is_btwn;
            let is_btwn_1;
            if(auth_from_tmp1 != "" && auth_to_temp1 != '') {
            // is_btwn =  moment(temp_btween_date).isBetween(auth_from_tmp1, auth_to_temp1);
            is_btwn = moment(temp_btween_date).isSameOrAfter(auth_from_tmp1);
            is_btwn_1 = moment(temp_btween_date).isSameOrBefore(auth_to_temp1);
            }
            if(is_btwn && is_btwn_1) {
              validationBool = false;
            } else {
              validationBool = true;
            }
          } else {
            validationBool = true;
            }
          }
          return validationBool ? { invalidValue: true } :(undefined || null) ;
        }
    };
  }
  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.patternValidator()(control);
  }
}
