import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, Input, SimpleChanges, HostListener } from '@angular/core';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import * as moment from 'moment';
import { LoginHistoryService } from '../../../_services/login-history/login-history.service';
import { UsersService } from '../../../_services/settings/users.service';
@Component({
  selector: 'app-login-history',
  templateUrl: './login-history.component.html',
  styleUrls: [
    './login-history.component.scss',
    '../../../../scss/vendors/bs-datepicker/bs-datepicker.scss'
  ]
})
export class LoginHistoryComponent implements OnInit {
  tableData: any;
  loginHistoryToday_date: string;
  allHistoryData: any;
  totalCount: any;
  currentPage: any;
  page: any;
  // countLength: any;
  userName: string;
  defaultLimit = 20;
  historytype: string;
  printUserTitle: string;
  public innerWidth: any;
  mobileview: boolean;
  mobileviewTaskcontent: boolean;
  pageCountNum: number;
  usersList: any;
  userSearch: any;
  selectedUser: any;
  historySearchval: any;
  @ViewChild('downurl', { static: false }) public downurl: ElementRef;
  matchResult: any;
  testBoolVal: boolean;
  timeout = null;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 800) {
      this.mobileview = true;
      this.mobileviewTaskcontent = true;
    } else {
      this.mobileview = false;
      this.mobileviewTaskcontent = false;
    }
  }
  constructor(
    private loginHistoryedaterangepickerOptions: DaterangepickerConfig,
    private loginHistoryService: LoginHistoryService,
    private usersService: UsersService
  ) {
    this.currentPage = 1;
    this.selectedUser = '';
    this.testBoolVal = false;
    this.matchResult = '';
    this.historySearchval = '';
    this.usersList = [];
    this.historytype = 'userloginhistory';
    this.loginHistoryToday_date = moment().format('YYYY-MM-DD') + ' - ' + moment().format('YYYY-MM-DD');
    this.loginHistoryedaterangepickerOptions.settings = {
      locale: { format: 'YYYY-MM-DD' },
      alwaysShowCalendars: false
    };
    this.page = 0;
    this.printUserTitle = '';
  }

  ngOnInit() {
     this.historytype = "userloginhistory";
  }
  getAllUser(userSearch) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
      this.usersService.searchUsers(userSearch,'all',40).subscribe(userVal => {
        if(userVal.status == "success") {
          let Usersdata = userVal.results;
          this.usersList = [];
          if(userVal.results.length == 0) {
            //this.allHistoryData = [];
          }
          Usersdata.forEach((element, i) => {
            if(element._id) {
              let userObj = {
                text: element.username,
                id: {
                  text: element.username,
                  id: element._id
                }
              }
              this.usersList.push(userObj);

            }
          });
        }
      });
    }, 400);
  }

  sendCurrentSearch(val) {
  }
  searchVal(val) {
    this.usersList = [];
    let userSearch = val;
    let pattern = '^([0-9]{0,3}[.]{0,1})*$';
    let regex = new RegExp(pattern);
    this.matchResult = val.match(regex);
    this.testBoolVal = regex.test(val);
    if(this.testBoolVal == true) {
      let ipAddressObj = {
        text: this.matchResult[0],
        id: this.matchResult[0]
      }
      this.usersList.push(ipAddressObj);
      this.historySearchval = this.matchResult[0];
      this.getUserHistoryData();
      this.printUserTitle = `${this.matchResult[0]} Login History`;
    } else {
      this.historySearchval = userSearch;
      this.getAllUser(val);
    }
  }
  onSelected(selectedVal) {
    if(this.matchResult) {

    } else {
      this.selectedUser = selectedVal;
      this.printUserTitle = `User ${this.selectedUser.text} Login History`;
      this.pageChanged(0);
      this.currentPage = 1;
    }
  }
  ngOnChanges(change: SimpleChanges) {
    this.getUserHistoryData();
  }
  getUserHistoryData() {
    let reqFormate = {};
    reqFormate = {
      dateRange: this.loginHistoryToday_date,
      skip_pages: this.page ? this.page : 0
    };
    if((this.historySearchval != '') && !(this.matchResult && this.matchResult.length == 0)) {
      reqFormate['user_id'] = this.selectedUser.id;
    }
    if(this.matchResult && this.matchResult[0] != (null || '') && (this.historySearchval != '')) {
      reqFormate['ipAddress'] = this.matchResult[0];
    }
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
      this.loginHistoryService.getUserLoginHistory(reqFormate, "userloginhistory").subscribe(data => {
        if (data.status == 'success' && (data.resData && data.resData[0].totalData)) {
          this.pageCountNum = this.page;
          this.allHistoryData = data.resData[0].totalData;
          this.totalCount = data.resData[0].totalCount;
        } else {
          this.allHistoryData = [];
        }
      });
    }, 100);
  }
  userLogin_datetriggered(date) {
    this.loginHistoryToday_date = date;
    this.currentPage = '';
    this.getUserHistoryData();
  }
  pageChanged(val) {
    this.page = val == 0 ? 0 : (val.page * this.defaultLimit - this.defaultLimit) ;
    this.getUserHistoryData();
  }
  downloadCsvFormate(val) {
    let downloadReq = {
      dateRange: this.loginHistoryToday_date,
    };
    if((this.historySearchval != '') && !(this.matchResult && this.matchResult.length == 0)) {
      downloadReq['user_id'] = this.selectedUser.id;
    }
    if(this.matchResult && this.matchResult[0] != (null || '') && (this.historySearchval != '')) {
      downloadReq['ipAddress'] = this.matchResult[0];
    }
    this.loginHistoryService.downloadAsCsv(downloadReq, this.historytype).subscribe(data => {
      let blob = data;
      let url = window.URL.createObjectURL(blob);
      let el: HTMLElement = this.downurl.nativeElement as HTMLElement;
      el.setAttribute('href', url);
      el.setAttribute('download', 'history.xlsx');
      el.click();
    });
  }

}
