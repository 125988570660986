import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'underscore';

@Component({
  selector: 'app-patient-history-list',
  templateUrl: './patient-history-list.component.html',
  styleUrls: ['./patient-history-list.component.css']
})
export class PatientHistoryListComponent implements OnInit {
  @Input('historylist') historylist: any;
  @Output('viewTask') viewTask = new EventEmitter();
  historylistData: any;
  country:any;
  constructor() {

  }

  ngOnInit() {
    this.country = localStorage.getItem('Region');
  }
  ngOnChanges() {
    if (this.historylist) {
      // console.log(this.historylist);
      this.historylistData = this.historylist;
    } else {
      this.historylistData = [];
    }
  }
  viewTaskById(task_id: string) {
    this.viewTask.emit(task_id);
  }
  groupHistoryData() {
    // tslint:disable-next-line: no-unused-expression
    this.historylistData;
  }
  dnconstruct(name) {
    let splitdta = name.split(/[ ,]+/);
    if (splitdta[1]) {
      return splitdta[0][0] + splitdta[1][0];
    } else {
      return '';
    }
  }

}
