import { Component, OnInit, TemplateRef } from '@angular/core';
import {MueService} from '../../../_services/settings/mue.service';
import { SharedService } from '../../../_services/shared.service';
import { SharedPopupService } from '../../../_services/popup.service';
import constants from '../../../constants';
@Component({
  selector: 'app-mue',
  templateUrl: './mue.component.html',
  styleUrls: ['./mue.component.css']
})
export class MUEComponent implements OnInit {

  showInactive: boolean;
  searchcode: string;
  timeout = null;
  loaderIndicator : boolean;
  popinfo: any;
  popUpForm : any;
  codePopTitle1: string;
  modifierPopTitle1: string;
  messageInfo: string;
  errorFlag: boolean;
  constants: any;
  contacts: any;
  UserValidationMessage: any;
  UserValidationMsg: any;
  mueCategoryData: any;
  selected_Code: any;
  selected_Code_index: any;
  selectedCodeStatus: boolean;
  mue_codes : any;
  mue_modifier : any;
  selected_applicable_modifier: any;
  selected_applicable_modifier_i: any;
  code_list: { categoryname: any; tasktype: any; enabled: boolean; };


  constructor(private mueService:MueService, private sharedPopupService: SharedPopupService, private sharedService:SharedService ) {
    this.showInactive = false;
    this.searchcode = '';
    this.loaderIndicator = true;
    this.mueCategoryData = [];
    this.messageInfo = '';
    this.errorFlag = false;
    this.constants = constants;
    this.selectedCodeStatus = true;

   }

  ngOnInit(): void {
    let status = this.showInactive ? false : true;
    this.getMueCodeList(status);
  }

  // search the MUEcode list
  searchCodeList(searchValue : string) {
    let tempBool = false;
    let testPattern = /^[a-zA-Z0-9 ]*$/;
    let regExVal = new RegExp(testPattern);
    tempBool = regExVal.test(searchValue);
    if (tempBool) {
      clearTimeout(this.timeout);
      this.timeout = 
      setTimeout(x => {
        this.triggerSearch(searchValue);
      }, 1000);
    }

  }

  triggerSearch(search : string) { 
    let status = this.showInactive ? false : true;
        this.getMueCodeList(status);
        this.loaderIndicator=false;
  }
// get the List of MueCodes
  getMueCodeList(status) {
    this.mueService.fetchMueCodeList(this.searchcode, status).subscribe((data : any) => {
      this.loaderIndicator = false;
      data.status == 'success' ? this.mueCategoryData = data.results : this.mueCategoryData = [];
    });
     
  }

  // Add and Edit MUECode OpenModalpopup window
  openMueCodeModal(template: TemplateRef<any>,from, item,i) {
    this.popinfo = item;
    this.popUpForm = from;
    this.selected_Code = item;
    this.selected_Code_index = i;
    if(this.popUpForm =='newMueCode') {
      this.codePopTitle1 = 'Add new code';
      this.mue_codes = ''
    } 
    else if(this.popUpForm == 'editCode') {
      this.codePopTitle1 = 'Edit code';
      this.mue_codes = item.code
    }
        this.sharedPopupService.show(template, {
        animated: true,
        backdrop: 'static'
      });
  }

  // Add and Edit MUEModifer OpenModalpopup window
  openMueModifierModal(template: TemplateRef<any>,modifierfrom,item,i, items= null, c_i = null) {
    this.popinfo = item;
    this.popUpForm = modifierfrom;
    this.selected_Code = item;
    this.selected_Code_index = i;
    this.selected_applicable_modifier= items;
    this.selected_applicable_modifier_i=c_i

    if(this.popUpForm == 'addMueModifier') {
    this.modifierPopTitle1 = 'Add new modifiers';
    this.mue_modifier = '';
    } 
    else if(this.popUpForm == 'editMueModifier') {
    this.modifierPopTitle1 = 'Edit modifier';
    this.mue_modifier = items.code
    }
    this.sharedPopupService.show(template, {
      animated: true,
      backdrop: 'static'
    });
  }


 
  // New MUE code added or already exists means Edit the Code addCategory()
  addAndEditMueCode() {
    if(this.popUpForm == 'newMueCode') {
      let codeData = /^[a-zA-Z]+$/.test(this.mue_codes);
      if(codeData==false) {
        let code_list = {
          "code": this.mue_codes
        };
        this.mueService.addMueCode(code_list).subscribe(data => {
          this.contacts = data;          
          this.UserValidationMessage = this.contacts.Message;
          if(data.status=="success") {
            this.mueCategoryData.unshift(data.results);   
            this.sharedService.pushToaster('success', 'Success', 'New Code added successfully');
            this.modalClose();
          } else {
            this.sharedService.pushToaster('error', 'Error', this.UserValidationMessage);
          }
        });
        this.errorFlag = false;
    } else {
      this.errorFlag = true;
    }
  }
    if (this.popUpForm == 'editCode') {
      let codeData = /^[a-zA-Z]+$/.test(this.mue_codes);
      if(codeData==false) {
        let code_list = {
          "code": this.mue_codes
        };
        this.mueService.activateCode(this.selected_Code._id,code_list).subscribe(data => {
          this.contacts = data;          
          this.UserValidationMessage = this.contacts.Message;
          if(data.status=="success") {
            this.mueCategoryData[this.selected_Code_index].code = this.mue_codes;
            this.modalClose();
            this.sharedService.pushToaster('success', 'Success', 'MUE Code updated successfully');
          }
           else{
            this.sharedService.pushToaster('error', 'Error', this.UserValidationMessage);
          }
        });
        this.errorFlag=false;
      }else{
        this.errorFlag=true;
      } 
}
}

addMueModifiers() {
    if(this.popUpForm == 'addMueModifier') {
    let code_list = {
      "code_id":this.selected_Code._id,
      "modifier": this.mue_modifier
    };
    this.mueService.addMueModifer(code_list).subscribe(data => {
      this.contacts = data;      
      this.UserValidationMsg = this.contacts.message;
      if(data.status=="success"){
        this.mueCategoryData[this.selected_Code_index].applicableModifier.unshift({code: this.mue_modifier, status: 1});
        this.modalClose();
        this.sharedService.pushToaster('success', 'Success', 'New Modifiers added successfully');
      }else{
        this.sharedService.pushToaster('error', 'Error', this.UserValidationMsg);
      }
    });
  }
  if(this.popUpForm == 'editMueModifier') {
    let code_list = {
      "modifier": this.mue_modifier
    };
    this.mueService.activateModifiers(this.selected_Code._id,this.selected_applicable_modifier.code,code_list).subscribe(data=>{
      this.constants = data;
      this.UserValidationMessage = this.constants.message;
      if (data.status == 'success') {
        this.mueCategoryData[this.selected_Code_index].applicableModifier[this.selected_applicable_modifier_i].code = this.mue_modifier;
        this.modalClose();
        this.sharedService.pushToaster('success', 'Success', 'Update Modifiers Successfully');
      } else {
        this.sharedService.pushToaster('error', 'Error', this.UserValidationMessage);
      }
    });
  }
}
  

  activeStatus() {
    this.getMueCodeList(this.showInactive);
    this.loaderIndicator=false;
  }

  ConformPopup(Conformtemplate: TemplateRef<any>,i, code){
    this.selected_Code = code
    this.selected_Code_index = i;
    this.selectedCodeStatus = code.enabled;
      if (this.selectedCodeStatus == true) {
        this.messageInfo = 'Are you sure want to Deactivate the Code ?';
      } else {
        this.messageInfo = 'Are you sure want to Activate the Code ?';
      }
    
    this.sharedPopupService.show(Conformtemplate, {
      animated: true,
      backdrop: 'static',
    });
  }

  onConfirm() {
    if (this.selected_Code.enabled== false) {
      this.mueService.activeandDeactivateCode(this.selected_Code._id,{enabled:true}).subscribe(result => {
        this.contacts = result;
         this.UserValidationMsg = this.contacts.message;
        if (result.status == 'success') {
          this.mueCategoryData.splice(this.selected_Code_index,1);
          this.sharedService.pushToaster('success', 'Success',  this.UserValidationMsg);
          this.modalClose();

        }
      });
    } else {
      this.mueService.activeandDeactivateCode(this.selected_Code._id,{enabled:false}).subscribe((result: any) => {
          this.contacts = result;
          this.UserValidationMsg = this.contacts.message;
        if (result.status == 'success') {
          this.mueCategoryData.splice(this.selected_Code_index,1);
          this.sharedService.pushToaster('success', 'Success', this.UserValidationMsg);
          this.modalClose();
        }
      });
    }
  }
  conformpopupcodelist(Conformtemplate: TemplateRef<any>, item,items, i,c_i) {
  this.selected_Code=item
  this.selected_Code_index = i;
  this.selected_applicable_modifier= items;
  this.selected_applicable_modifier_i=c_i
  let enabled= items.status;
    if (enabled == 1) {
      this.messageInfo = 'Are you sure want to Deactivate the Modifier ?';
    } else {
      this.messageInfo = 'Are you sure want to Activate the Modifier ?';
    }
  
  this.sharedPopupService.show(Conformtemplate, {
    animated: true,
    backdrop: 'static',
  });
  }

  updateSatus() {
    if (this.selected_applicable_modifier.status== 0) {
      this.mueService.activeandDeactivateModifiers(this.selected_Code._id,this.selected_applicable_modifier.code,{status :1}).subscribe(result => {
        this.contacts = result;
         this.UserValidationMsg = this.contacts.message;
        if (result.status == 'success') {
          this.mueCategoryData[this.selected_Code_index].applicableModifier[this.selected_applicable_modifier_i].status = 1;
          this.sharedService.pushToaster('success', 'Success',  this.UserValidationMsg);
          this.modalClose();
        }
      });
    } 
    else {
      this.mueService.activeandDeactivateModifiers(this.selected_Code._id,this.selected_applicable_modifier.code,{status :0}).subscribe((result: any) => {
        this.contacts = result;
        this.UserValidationMsg = this.contacts.message;
        if (result.status == 'success') {
          this.mueCategoryData[this.selected_Code_index].applicableModifier[this.selected_applicable_modifier_i].status = 0;
          this.sharedService.pushToaster('success', 'Success',  this.UserValidationMsg);
          this.modalClose();
        }
      });
    }
  }

  modalClose() {
    this.errorFlag=false;
    this.sharedPopupService.dismiss();
  }

}
