import { Component, OnInit, TemplateRef, HostListener, OnDestroy } from '@angular/core';
import { StatuscodesService } from '../../../_services/settings/statuscodes.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../../../_services/shared.service';
import { DataHelperService } from '../../../_services/auth/data-helper.service';
import * as _ from 'lodash';
import { Subject } from 'rxjs/Rx';
import { SharedPopupService } from '../../../_services/popup.service';
import constants from '../../../constants';
@Component({
  selector: 'app-status-codes',
  templateUrl: './status-codes.component.html',
  styleUrls: ['./status-codes.component.css']
})
export class StatusCodesComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  statusCodeList: any;
  searchString: any;
  showInactive: any;
  inactiveFlag: boolean;

  popupTitle: String;
  popUpfrom: String;
  statusCodeTitle: String;
  mobileviewTaskcontent: boolean;
  statuscode: String;
  FollowUpRequired: boolean;
  selectedStatusCode_id: any;

  selected_p_index: any;
  currentstatus: any;
  question_edit_permission: any;
  dynamicBtn: string;
  seletedTab: any;
  actionCodeData: any;
  displayuserName: any;
  selectedStatusCodeIndex: any;
  existmappedActionCodes: any[];
  searchModel: string;
  tmpMappingData: any[];

  allActionCodes: any;
  timeout: NodeJS.Timeout;
  messageInfo: string;
  ConfirmPopupfrom: any;
  selectedStatusCodeData: any;
  selectedActionCodeData: any;
  statusCodeIndex: any;
  actionCodeIndex: any;
  confirmTitle: string;
  innerWidth: number;
  actionCodeTotalCount: number;
  actionCodePage: number;
  actionCodeCurrentPage: number;
  actionCodePageskip: number;
  allPaginationDefaultLimit: number;
  loaderIndicator:boolean;
  tabloaderIndicator:boolean;
  constants:any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    this.screenValues();
  }
  constructor(public statuscodesService: StatuscodesService,
    private modalService: BsModalService,
    public SharedPopupService: SharedPopupService,
    private sharedService: SharedService,
    private DataHelper: DataHelperService,
  ) {
    this.actionCodeTotalCount = 0;
    this.actionCodePage = 0;
    this.actionCodeCurrentPage = 1;
    this.actionCodePageskip = 0;
    this.allPaginationDefaultLimit = 10;
    this.actionCodeData = [];
    this.loaderIndicator=false;
    this.tabloaderIndicator=false;
    this.constants=constants;
  }

  ngOnInit(): void {
    this.searchString = "";
    this.statuscode = "";
    //this.FollowUpRequired = false;
    this.statusCodeTitle = "";
    //this.actionCodePage = 0;
    this.inactiveFlag = true;
    this.showInactive = false;
    this.statusCodeList = [];
    this.statuscodelist();
    let roleList = this.DataHelper.getItem('userPermissions');
    this.question_edit_permission = roleList.indexOf("Status Code Question")

  }

  statuscodelist() {
   
    this.loaderIndicator=true;
    this.statusCodeList=[];
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
    this.statuscodesService.statusCodeList(this.searchString, this.inactiveFlag, this.actionCodePage, this.allPaginationDefaultLimit).subscribe(data => {
      this.loaderIndicator=false;
      if (data.status == "success") {
        this.statusCodeList = data.results;
        this.actionCodeTotalCount = data.totalCount;
        
       
        this.statusCodeList.forEach(element => {
          element['mapped_action_codes'] = [];
          element.questions = JSON.stringify(element.questions);
        });
      }
    })
  }, 1000);
  }
  actioncodePageChanged(ev) {
    this.actionCodePage = ev.page * this.allPaginationDefaultLimit - this.allPaginationDefaultLimit;
    this.statuscodelist();
  }

  resetAllPagination() {
    this.actionCodePage = 0;
    this.actionCodeCurrentPage = 1;
    this.actionCodePageskip = 0;
    this.actionCodeTotalCount = 0;
  }
  activeStatus() {
    this.resetAllPagination();
    this.inactiveFlag = (this.showInactive == true) ? true : false;
    this.statuscodelist();
  }
  searchTitle() {
    this.actionCodePage = 0;
    this.statuscodelist();

  }
  openModal(template: TemplateRef<any>, from, selectedStatusCode, i) {
    this.popUpfrom = from;
    
    this.selected_p_index = i
    if (from == 'newStatusCode') {
      this.FollowUpRequired = false;
      this.popupTitle = "Add status code";
      this.statusCodeTitle = '';
      this.statuscode = '';
    } else {
      this.selectedStatusCode_id = selectedStatusCode._id;
      this.popupTitle = "Edit status code";
      this.statusCodeTitle = selectedStatusCode.title;
      this.statuscode = selectedStatusCode.statusCode;
      this.FollowUpRequired = selectedStatusCode.FollowUpRequired;
    }
    this.SharedPopupService.show(template, {
      animated: true,
      backdrop: 'static'
    });
  }
  addStatusCode() {
    let postdata = {
      title: this.statusCodeTitle.trim(),
      statusCode: this.statuscode.trim(),
      questions: "[]",
      FollowUpRequired:this.FollowUpRequired
    }
 
    this.statuscodesService.createNewStatusCode(postdata).subscribe(data => {
      if (data.status == "success") {
        postdata["_id"] = data._id;
        postdata["enabled"] = true;
        postdata['mapped_action_codes'] = [];
        this.sharedService.pushToaster('success', 'Success', 'Status code created successfully');
        this.statusCodeList.unshift(postdata);
        this.SharedPopupService.dismiss();
      }
      else {
        this.sharedService.pushToaster('error', 'Error', data.Message);
        // myform.resetForm();
      }
    })
  }
  modalClose() {
    this.SharedPopupService.dismiss();  
  }
  editStatusCode() {
    let updatedata = {
      title: this.statusCodeTitle,
      statusCode: this.statuscode,
      FollowUpRequired:this.FollowUpRequired
    }
    this.update_status_code(updatedata, 'edit');

  }

  update_status_code(updatedata, property) {
    this.statuscodesService.updateStatusCode(this.selectedStatusCode_id, updatedata).subscribe(data => {
      if (data.status == "success") {
        if (property == "edit") {
          this.statusCodeList[this.selected_p_index].title = updatedata.title;
          this.statusCodeList[this.selected_p_index].statusCode = updatedata.statusCode;
          this.statusCodeList[this.selected_p_index].FollowUpRequired = updatedata.FollowUpRequired;
          this.sharedService.pushToaster('success', 'Success', 'Status code updated successfully');
          this.SharedPopupService.dismiss();
        }
        if (property == "remove") {
          if (this.currentstatus == "activate") {
            this.sharedService.pushToaster('success', 'Success', 'Status code Activated successfully');
          } else {
            this.sharedService.pushToaster('success', 'Success', 'Status code Deactivated successfully');
          }
          this.statusCodeList.splice(this.selected_p_index, 1);
          this.SharedPopupService.dismiss();
        }
        if (property == "question") {
          this.sharedService.pushToaster('success', 'Success', 'Status code updated successfully');
        }
      }
    })
  }
  onCancel() {
    this.SharedPopupService.dismiss();
  }

  onCancelConfirm() {
    this.SharedPopupService.dismiss();
  }

  statusCodeTemplateConfirm(templateConfirmpopup: TemplateRef<any>, status, scode_id, i) {
    if (status == "deActivate") {
      this.confirmTitle = "Are you sure want to Deactivate status code ?"
    } else {
      this.confirmTitle = "Are you sure want to Activate status code ?"
    }
    this.selectedStatusCode_id = scode_id;
    this.selected_p_index = i;
    this.currentstatus = status
    this.SharedPopupService.show(templateConfirmpopup, {
      animated: true,
      backdrop: 'static'
    });
  }
  confirmStatus() {
    var updatedata = {}
    if (this.currentstatus == "activate") {
      updatedata['enabled'] = true;
    } else {
      updatedata['enabled'] = false;
    }
    this.update_status_code(updatedata, "remove");
  }
  updateQuestion(question, id, i) {
      this.selectedStatusCode_id = id;
      this.selected_p_index = i;
      this.update_status_code({ questions: question }, "question");
  }
  validateJson(question, i) {
    try {
      let Question = JSON.parse(question)
      if(question!= "[]"){
        this.statusCodeList[i].validjson = true;
        Question.forEach(element => {
          if(!(element.hasOwnProperty('show_header') && (element['show_header'] ==false || (element.hasOwnProperty('type') && (element['type'] == 'Rep' || element['type'] == 'Caller'))) && (element.hasOwnProperty('details') && element['details'].length>0))){
            throw Error('Invalid Json')
          }
        });
      }
      else{
        this.statusCodeList[i].validjson = true;
      }
    } catch (e) {
      this.statusCodeList[i].validjson = false;
    }
  }
  tabChange(from, code, index) {
    this.statusCodeList[index].tabloaderIndicator=true;
    this.seletedTab = from;
    if (this.seletedTab == 'ActionCodes') {
      if (this.statusCodeList[index]['mapped_action_codes'].length == 0) {
        this.statuscodesService.get_mapped_action_codes(code._id).subscribe(data => {
          this.statusCodeList[index].tabloaderIndicator=false;
          if (data.status == 'success') {
            this.statusCodeList[index]['mapped_action_codes'] = data.result;
            this.statusCodeList[index]['mapped_action_codes_limit'] = 10;
            code['type'] = from;
          }
        });
      } else {
        this.statusCodeList[index].tabloaderIndicator=false;
        code['type'] = from;
      }
    } else {
      this.validateJson(code.questions, index);
      code['type'] = from;
    }
  }

  createnewmapping(createnewtemplate: TemplateRef<any>, selectedUser, index) {
    this.searchModel = '';
    this.displayuserName = selectedUser.statusCode;
    this.selectedStatusCodeIndex = index;
    this.existmappedActionCodes = [];
    this.tmpMappingData = [];
    this.selectedStatusCodeData = selectedUser;
    if (selectedUser.mapped_action_codes) {
      let tmpexistingData = _.cloneDeep(selectedUser.mapped_action_codes);
      tmpexistingData.forEach(element => {
        if (element.enabled == true) {
          this.existmappedActionCodes.push(element);
        }
        this.tmpMappingData.push(element);
      });
    }

    if (selectedUser.type == 'ActionCodes') {
      this.statuscodesService.searchBulkActionCodes('').subscribe(data => {
        if (data.status == 'success') {
          this.allActionCodes = data.result.length > 0 ? data.result : [];
          this.defaultSelection();
          this.enablemodel(createnewtemplate);
        }
      }, err => {
        alert('err');
        this.allActionCodes = [];
      });
    }
  }

  enablemodel(createnewtemplate) {
    this.SharedPopupService.show(createnewtemplate, {
      animated: true,
      backdrop: 'static',
      class: 'modal-lg'
    });
  }

  selectActionCode(data, i) {
    if (data.enabled) {
      if (this.existmappedActionCodes == undefined) {
        this.existmappedActionCodes = [];
      }
      data['enabled'] = true;
      this.existmappedActionCodes.push(data);
    } else {
      var index = this.existmappedActionCodes.findIndex(result => result._id === data._id);
      this.tmpMappingData.map(elem => {
        if (elem._id === data._id) {
          elem.enabled = false;
        }
        return elem;
      });
      this.existmappedActionCodes.splice(index, 1);
    }
    this.mapNewActiontoStatus(data);
  }

  removeMappedActionCode(userAccount, indexs) {
    if (userAccount) {
      let index = this.allActionCodes.findIndex(result => result._id === userAccount._id);
      if (index >= 0) {
        this.allActionCodes[index].enabled = false;
        this.tmpMappingData.map(elem => {
          if (elem._id === userAccount._id) {
            elem.enabled = false;
          }
          return elem;
        });
      } else {
        this.tmpMappingData.map(elem => {
          if (elem._id === userAccount._id) {
            elem.enabled = false;
          }
          return elem;
        });
      }
      this.existmappedActionCodes.splice(indexs, 1);
    }
  }

  removeMappedActionCodes(userAccount, selectedActionCodeIndex) {
    let actionCodeIndex;
    if (userAccount) {
      let index = this.allActionCodes.findIndex(result => result._id === userAccount._id);
      actionCodeIndex = this.selectedStatusCodeData.mapped_action_codes.findIndex(res => res.actionCode === userAccount.actionCode);
      if (index >= 0) {
        this.allActionCodes[index].enabled = false;
        this.tmpMappingData.map(elem => {
          if (elem._id === userAccount._id) {
            elem.enabled = false;
          }
          return elem;
        });
      } 
      this.existmappedActionCodes.splice(selectedActionCodeIndex, 1);
    }

    let updateinfo = [{
      status: false,
      action_code_id: userAccount._id
    }];
    this.statuscodesService.ActioncodesDelete(this.selectedStatusCodeData._id, userAccount._id).subscribe(data => {
      if (data.status == 'success') {
        this.statusCodeList[this.selectedStatusCodeIndex].mapped_action_codes[actionCodeIndex].enabled = false;
      }
      //this.SharedPopupService.dismiss();
      let msg = 'Action code deactivated successfully';
      this.sharedService.pushToaster('success', 'Success', msg);
    }, err => {
      this.SharedPopupService.dismiss();
    }
    );

  }

  defaultSelection() {
    this.allActionCodes.forEach(element => {
      let flag = false;
      this.existmappedActionCodes.forEach(tmp => {
        if (element._id == tmp._id) {
          flag = true;
        }
      });
      if (flag == false) {
        element.enabled = false;
      } else {
        element.enabled = true;
      }
    });
  }

  cancelmapNewActioncode() {
    this.SharedPopupService.dismiss();
  }

  searcTaskKey(value) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(x => {
      this.statuscodesService.searchBulkActionCodes(value).subscribe(data => {
        this.allActionCodes = data.result.length > 0 ? data.result : [];
        this.defaultSelection();
      });
    }, 400);
  }

  toggleLimit(item, limit, type) {
    item[type] = limit;
  }

  mapNewActiontoStatus(selectedActionCodeData) {
    let allUsersAccounts = this.tmpMappingData;
    let updatedDataIds = [];
    this.existmappedActionCodes.forEach(element => {
      updatedDataIds.push(element._id);
    });
    let mappedData = JSON.parse(JSON.stringify(this.existmappedActionCodes));
    allUsersAccounts.forEach(element => {
      if (updatedDataIds.indexOf(element._id) == -1) {
        mappedData.push(element);
      }
    });
    let finalmappedData = [];
    let i = 0;
    mappedData.forEach(element => {
      let selectedAccount = {
        'action_code_id': element._id,
        'status': element.enabled,
        'updatedTime': new Date(),
        'lastUpdatedBy': this.DataHelper.getItem('userid'),
      };
      finalmappedData.push(selectedAccount);
      i++;
    });
    if(selectedActionCodeData.enabled){
      //if (i == mappedData.length) {
        this.statuscodesService.ActioncodesInsert(this.statusCodeList[this.selectedStatusCodeIndex]._id, selectedActionCodeData._id).subscribe(data => {
          this.statusCodeList[this.selectedStatusCodeIndex].mapped_action_codes = mappedData;
          //this.SharedPopupService.dismiss();
          this.sharedService.pushToaster('success', 'Success', 'Action Codes Mapped Successfully');
        }, err => {
          this.SharedPopupService.dismiss();
          alert('bulk action code mapping failed ');
        });
      //}
    }else{
      this.statuscodesService.ActioncodesDelete(this.statusCodeList[this.selectedStatusCodeIndex]._id, selectedActionCodeData._id).subscribe(data => {
        this.statusCodeList[this.selectedStatusCodeIndex].mapped_action_codes = mappedData;
        this.sharedService.pushToaster('success', 'Success', 'Action Codes Unmapped Successfully');
      });
    }
    
  }

  onSearchChange(searchValue, page = 0, limit = 20, type = '') {
    let tempBool = false;
    let testPattern = /^[a-zA-Z0-9 ]*$/;
    let regExVal = new RegExp(testPattern);
    tempBool = regExVal.test(searchValue);
    if (tempBool) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(x => {
        this.ngUnsubscribe.next();
        if (type === '') {
          this.resetAllPagination();
        }
        let tempResult = [];
        this.statuscodesService.statusCodeList(searchValue, this.inactiveFlag, page, limit).debounceTime(400).takeUntil(this.ngUnsubscribe).subscribe(data => {
          if (data) {
            tempResult = data.results;
            this.statuscodesService = data.results;
            this.actionCodeTotalCount = data['totalCount'];
          } else {
            this.actionCodeData = [];
          }
        }, err => console.log(err));
      }, 400);
    }
  }

  ConfirmPopup(Confirmtemplate: TemplateRef<any>, from, statusCodeData, actionCodeData, statusCodeindex, actionCodeindex) {
    this.ConfirmPopupfrom = from;
    this.selectedStatusCodeData = statusCodeData;
    this.selectedActionCodeData = actionCodeData;
    this.statusCodeIndex = statusCodeindex;
    this.actionCodeIndex = actionCodeindex;
    if (from == 'deactivateActionCode') {
      this.messageInfo = 'Are you sure want to Deactivate Action Code?';
    } else if (from == 'activateActionCode') {
      this.messageInfo = 'Are you sure want to Activate Action Code?';
    }

    this.SharedPopupService.show(Confirmtemplate, {
      animated: true,
      backdrop: 'static',
    });
  }

  onConfirm() {
    if (this.ConfirmPopupfrom == 'deactivateActionCode') {
      this.deactivatSingleActionCode('deactivate');
    } else if (this.ConfirmPopupfrom == 'activateActionCode') {
      this.deactivatSingleActionCode('activate');
    }
  }

  deactivatSingleActionCode(actiontype) {
    if (this.selectedStatusCodeData && this.selectedActionCodeData) {
      let updateinfo = [{
        status: actiontype == 'deactivate' ? false : true,
        action_code_id: this.selectedActionCodeData._id
      }];
      this.statuscodesService.ActioncodesDelete(this.selectedStatusCodeData._id, this.selectedActionCodeData._id).subscribe(data => {
        if (data.status == 'success') {
          this.statusCodeList[this.statusCodeIndex].mapped_action_codes[this.actionCodeIndex].enabled = actiontype == 'deactivate' ? false : true;
        }
        this.SharedPopupService.dismiss();
        let msg = 'Action code deactivated successfully';
        if (actiontype != 'deactivate') {
          msg = 'Action code activated successfully';
        }
        this.sharedService.pushToaster('success', 'Success', msg);
      }, err => {
        this.SharedPopupService.dismiss();
      }
      );
    }
  }

  screenValues() {
    if (this.innerWidth < 768) {
      // mobile
      this.mobileviewTaskcontent = false;
    } else if (this.innerWidth < 1200) {
      // big tablet screen or laptop
      this.mobileviewTaskcontent = true;
    } else {
      // desktop
      this.mobileviewTaskcontent = true;
    }
  }
  ngOnDestroy(): void {
    this.SharedPopupService.dismiss();
  }

}