import { Component, OnInit } from '@angular/core';
import { UsersService } from "../../_services/settings/users.service";
import { SharedService } from "../../_services/shared.service";
import { SharedPopupService } from "../../_services/popup.service";
import * as _ from 'lodash';
@Component({
  selector: 'app-skillset-approval',
  templateUrl: './skillset-approval.component.html',
  styleUrls: ['./skillset-approval.component.css']
})
export class SkillsetApprovalComponent implements OnInit {
  reportedInfo:any=[];
  commentsPosted:any='';
  globalreportedInfo:any=[];
  constructor(private userService: UsersService,    public sharedService: SharedService) { }

  ngOnInit(): void {
    this.getReportedInfo();
  }

getReportedInfo(){
  this.reportedInfo=[];
  this.globalreportedInfo=[];
  this.userService.getReportedInfo().subscribe(res=>{
  if(res.length){
    this.reportedInfo=res;
    this.globalreportedInfo=_.cloneDeep(this.reportedInfo)
  }else
  {
    this.sharedService.pushToaster('warning', 'No Records', 'No reports found');
  }
})
}
filterReportedInfo(value){
  if(value=='Approved'){
    this.reportedInfo=[];
    this.reportedInfo=this.globalreportedInfo.filter(item => item.Status === 'Approved');
  } else if(value=='Rejected') {
    this.reportedInfo=[];
    this.reportedInfo=this.globalreportedInfo.filter(item => item.Status === 'Rejected');
  } else if(value=='Pending'){
      this.reportedInfo=[];
      this.reportedInfo=this.globalreportedInfo.filter(item => item.Status === 'Pending');
  } else if(value=='All'){
    this.reportedInfo=this.globalreportedInfo;
  }
}
updateReportedInfoStatus(reportedInfo,Status)
{
  if(Status=='Rejected')
    {
    if(!reportedInfo.commentsPosted||reportedInfo.commentsPosted.trim()=='' )
      {
        this.sharedService.pushToaster('error', 'Comments not available' , 'Please enter the Comments for Rejection');
        return;
      }
    }
  let updateInfo={
    user_id:reportedInfo.user_id,
    Status:Status,
    commentsPosted:reportedInfo.commentsPosted
  }
  this.userService.skillsetapprove(updateInfo).subscribe(res=>{
    if(res.status=='success'){
      this.sharedService.pushToaster('success', 'Skillset status Updated' , 'Submitted Report has been '+Status);
      this.getReportedInfo();
      }
      else
      {
        this.sharedService.pushToaster('error', 'Skillset status not updated' , 'Please contact Admin');
      }
    })
}



}
