import { Component, OnInit,  Pipe, PipeTransform } from '@angular/core';
import { TaskService } from '../../_services/task/task.service';
import { SharedService } from '../../_services/shared.service';
import { assign } from 'lodash';
import constants from '../../constants';
@Pipe({
  name: 'relatedValueFilter',
})


export class RelatedValueFilterPipe implements PipeTransform {
  transform(items: any[], task_type:any[],task_status:any[]): any {
    // console.log(items,"1")
    // console.log(task_type,"2")
    // console.log(task_status,"3")
    if(items){
      let filterdata =[]
      items.forEach((element,i) => {
        let t_type_index = task_type.findIndex(v1 => v1._id == element.task_type_id);
        let t_status_index = task_status.findIndex(v2 => v2._id == element.task_status_id);
        filterdata.push({
          index:i,
          task_type_name: t_type_index!= -1 ? task_type[t_type_index].tasktype :"",
          task_status_name:t_status_index !=-1 ? task_status[t_status_index].task_status_name:""
        });     
      });   
    return filterdata;

    }

  }
}
@Component({
  selector: 'app-task-notification-options',
  templateUrl: './task-notification-options.component.html',
  styleUrls: ['./task-notification-options.component.css']
})

export class TaskNotificationOptionsComponent implements OnInit {
  addalert: boolean;
  task_type: any;
  task_status: any;
  task_notification_update_info:any;
  new_alert:any;
  cloneafterRemove: string;
  constants:any;

  constructor(private taskService:TaskService,private sharedService:SharedService) { 
    this.addalert = false;
    this.task_notification_update_info={};
    this.new_alert={};
    this.constants=constants;
    var updatedDta
  } 

  ngOnInit(): void {
    this.taskService.listTask().subscribe(data =>{
      // console.log(data,"data");
      if(data.length){
        this.task_type = data;
      }

    });
    this.taskService.task_statuses_list("").subscribe(data =>{
      // console.log(data,"data data data")
      this.task_status = data.filter(d =>d.task_active_status == true);
    })
    this.taskService.current_user_info().subscribe(data =>{
      // console.log(data,"1222 3e2e ww");
      if(data && data.task_notification_setting_option){
        this.task_notification_update_info = data.task_notification_setting_option
      }
    })
  }
  addnewalert(){
    this.addalert = true;
  }
  cancel(){
    this.addalert = false;
  }
  saveconfig(){
    // console.log(this.task_notification_update_info,"2345");
    if(!this.task_notification_update_info.status_alert){
      this.task_notification_update_info.status_alert=[];
    }
    // console.log(this.new_alert.task_type_id,"232");
    // console.log(this.new_alert.task_status_id,"45");
    if(!this.new_alert.task_type_id ||  !this.new_alert.task_status_id){
      this.sharedService.pushToaster('error', 'Error', 'Task type and Task status should not empty');
      return;
    }
    let index = this.task_notification_update_info.status_alert.findIndex(data => data.task_type_id == this.new_alert.task_type_id && data.task_status_id == this.new_alert.task_status_id)
    if(index != -1){
      this.sharedService.pushToaster('error', 'Error', 'Configuration Exist');
      return;
   }
    this.task_notification_update_info.status_alert.push({
      "task_type_id":this.new_alert.task_type_id,
      "task_status_id":this.new_alert.task_status_id
    })
    this.update_info("add");
  }
  update_info(from){
    this.taskService.user_task_notification(this.task_notification_update_info).subscribe(res =>{
      // console.log(this.task_notification_update_info,"867")
      if(from == "add"){
        if(res.status != "success"){
          this.task_notification_update_info.status_alert.pop();
        }
        this.addalert = false;
      }else if(from != "basic"){
        this.task_notification_update_info.status_alert=[];
        this.task_notification_update_info.status_alert =  JSON.parse(this.cloneafterRemove); 
      }
      if(res.status == "success"){
        this.sharedService.pushToaster('success', 'Success', 'Notification configured successfully');
      }else{
        this.sharedService.pushToaster('error', 'Error', 'Notification configuration failed');
      }
    })
  }
  removealert(index){
    // console.log(index,"123456")
    //delete this.task_notification_update_info.status_alert[index];
    this.task_notification_update_info.status_alert.splice(index,1)
    this.cloneafterRemove = JSON.stringify(this.task_notification_update_info.status_alert);
    // console.log(this.task_notification_update_info.status_alert,"123")
    this.update_info("remove");
  }
  updateConfigInfo(){

  }
}
