import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as myGlobals from '../../globals';
import { Subject, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private emitSelectPractice = new Subject<any>();
  private emitIntegrationSelectPractice = new Subject<any>();
  public followupCountEmitter = new Subject<any>();
  public uaeVersionEmitter = new Subject<any>();
  public practiceList = [];

  constructor(private http: HttpClient) { }
  practiceEmitted$ = this.emitSelectPractice.asObservable();
  integrationPracticeEmitted$ = this.emitIntegrationSelectPractice.asObservable();
  followupEmitted$ = this.followupCountEmitter.asObservable();
  versionEmitted$ = this.uaeVersionEmitter.asObservable();
  showSelectedPracticeIntegration(integrationVal) {
    this.emitIntegrationSelectPractice.next(integrationVal);
  }
  showFollowupCount(data) {
    this.followupCountEmitter.next(data);
  }
  uaeVersiontrigger(data) {
    this.uaeVersionEmitter.next(data);
  }
  showSelectedPractice(practiceVal) {
    this.emitSelectPractice.next(practiceVal);
  }
  userPracticeAccount(searchKey, pagesize?) {
    if (searchKey == undefined) {
      searchKey = '';
    }
    let pageSize = pagesize ? pagesize : 5;
    let url = myGlobals.Base_URL + '/currentuser/practices' + '?q=' + searchKey + '&pagesize=' + pageSize;
    return this.http.get<any>(url);
  }
  updatePracticetoken(practiceIdData) {
    return this.http.put<any>(myGlobals.update_token_URL, practiceIdData);
  }
  updateUaetoken(versionData) {
    return this.http.put<any>(myGlobals.update_uae_version, versionData);
  }
  getRecentPractices() {
    return this.http.get<any>(myGlobals.Base_URL + '/currentuser/recentpractice');
  }
  getFollowupTask() {
    return this.http.get<any>(myGlobals.Report + '/tasks/followup');
  }
}
