import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../_services/auth/authentication.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SharedService } from '../../../_services/shared.service';
class tokenResponses {
  status: string;
}

class ChangePassword {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: []
})
// '../../../../../src/scss/vendors/toastr/toastr.scss'
export class ResetPasswordComponent implements OnInit {
  router: Router;
  urlId: any;
  urlToken: any;
  currentUrl: any;
  public tokenResponse: tokenResponses;
  public changePassword: ChangePassword;
  loginresponse_error: any;
  loginresponse_success: any;
  isValidToken: boolean;
  brandLogo: any;


  constructor(private authenticationService: AuthenticationService, private routeParams: ActivatedRoute, private _router: Router, private sharedService: SharedService) {
    this.router = _router;

    this.changePassword = {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    };
    ///console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>", window.location.href);
    let claimocity = 'claimocity';
    // let Global = 'globalhealthcareresource';
    if(window.location.href.includes(claimocity) == true) {
      this.brandLogo = 'claimocity'
    } else {
      this.brandLogo = 'Global'
    }
    

  }

  ngOnInit() {
    this.routeParams.params.subscribe(params => {
      //console.log(params,  this.currentUrl, "paramsparamsparams")
      this.urlId = params['id'];
      this.urlToken = params['token'];
      this.currentUrl = this.router.url;
      this.validToken(this.urlId, this.urlToken);
    });
  }

  validToken(uid: string, utoken: string) {
    uid = this.urlId;
    utoken = this.urlToken;
    this.authenticationService.validateToken(uid, utoken).subscribe(data => {
      this.tokenResponse = data;
      if (this.tokenResponse.status == 'success') {
        this.isValidToken = true;
      } else {
        // this.toasterService.pop('success', 'Success', 'User status updated successfully');
        this.isValidToken = false;
      }
    }, error => {
      alert('Something went wrong');
    });
  }

  changePasswordViaEmail(model: ChangePassword, isValid: boolean) {
    if (isValid) {
      let strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');
      let password = model.newPassword;

      if (model.newPassword == model.confirmNewPassword) {
        if (password.match(strongRegex)) {
          let userData = {
            'userId': this.urlId,
            'password': model.currentPassword,
            'newpassword': model.newPassword,
            'skipAuthorization': true
          };

          this.authenticationService.validateToken(this.urlId, this.urlToken).subscribe(data => {
            this.tokenResponse = data;
            if (this.tokenResponse.status == 'success') {
              this.authenticationService.changePasswordViaLink(userData).subscribe(resdata => {
                if (resdata.status == 'success') {
                  // this.loginresponse_success = 'Password changed successfully';
                  this.sharedService.pushToaster('success', 'Success', 'Password changed successfully');
                  setTimeout(() => {
                    this.router.navigate(['/']);
                  }, 2000);
                } else {
                  this.loginresponse_error = resdata.message;
                }
              });
            } else {
              this.loginresponse_error = 'Token Expired.';
            }
          });
        } else {
          this.loginresponse_error = 'Password should be 8 characters with 1 lowercase, 1 uppercase, 1 special character, 1 numeric';
        }
      } else {
        this.loginresponse_error = 'Password does not match. please try again';
      }
    }
  }

  onCancel() {
    this.router.navigate(['/']);
  }
}
