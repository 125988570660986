import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { LoginComponent } from './views/login/login.component';
import { AuthRouteGuard } from './auth-route.guard';
import { P404Component } from './views/error/404.component';
import { ResetPasswordComponent } from './views/login/reset-password/reset-password.component';
import { SetPasswordComponent } from './views/login/set-password/set-password.component';
import { SessionHistoryComponent } from './views/settings/session-history/session-history.component';
import { ImageViewerComponent } from './views/image-viewer/image-viewer.component';
import { MfaLoginComponent } from './views/login/mfa-login/mfa-login.component';
import { SkillsetApprovalComponent } from './views/skillset-approval/skillset-approval.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  }, {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'mfa-login',
    component: MfaLoginComponent,
    data: {
      title: 'MFA Login Page'
    }
  },
  {
    path: 'reset-password/:id/:token',
    component: ResetPasswordComponent,
    data: {
      title: 'Reset password Page'
    }
  },
  {
    path: 'set-password/:id/:token',
    component: SetPasswordComponent,
    data: {
      title: 'Set password Page'
    }
  },
  {
    path: 'image-viewer',
    component: ImageViewerComponent,
    data: {
      title: 'Image Viewer',
      'permissions': ['Portal Batches Read', 'Portal Batches Admin']
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthRouteGuard],
    canActivateChild: [AuthRouteGuard],
    data: {
      title: ''
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'settings',
        loadChildren: './views/settings/settings.module#SettingsModule',
        data: {
          title: 'administration',
          // "roles":['portalroleadmin'],
          permissions: ['Portal Settings Access', 'Portal Settings Admin'],
        }
      },
      {
        path: 'task',
        loadChildren: './views/task/task.module#TaskModule',
        data: {
          title: 'task',
          permissions: ['Portal Task Read', 'Portal Task Admin']
        }
      },
      {
        path: 'invoicing',
        loadChildren: './views/invoicing/invoicing.module#InvoicingModule',
        data: {
          title: 'invoicing',
          permissions: ['Portal Batches Read', 'Portal Batches Admin']
        }
      },
      {
        path: 'batches',
        loadChildren: './views/batches/batches.module#BatchesModule',
        data: {
          title: 'batches',
          permissions: ['Portal Batches Read', 'Portal Batches Admin']
        }
      },
      {
        path:'skillsetapproval',
        component : SkillsetApprovalComponent,
        data:{
          title:'Skill Set Approval',
          Permissions:['Portal Skillset Approval']
        }
      },
      {
        path: 'charges',
        loadChildren: './views/charges/charges.module#ChargesModule',
        data: {
          title: 'charges',
          permissions: ['Portal Charges Read', 'Portal Charges Admin']
        }
      },
      {
        path: 'task-assign',
        loadChildren: './views/task-assign/task-assign.module#TaskAssignModule',
        data: {
          title: 'task-assign',
          permissions: ['Portal TaskAssign Read', 'Portal TaskAssign Admin']
        }
      },
      {
        path: 'doctor-now',
        loadChildren: './views/doctor-now/doctor-now.module#DoctorNowModule',
        data: {
          title: 'doctor-now',
          permissions: ['Portal Patient Read', 'Portal Patient Admin']
        }
      },
      {
        path: 'patients',
        loadChildren: './views/patients/patients.module#PatientsModule',
        data: {   
          title: 'patients',
          permissions: ['Portal Patient Read', 'Portal Patient Admin']
        }
      },
      {
        path: 'practice-setting',
        loadChildren: './views/practice-setting/practice-setting.module#PracticeSettingModule',
        data: {
          title: 'practice-setting',
          'permissions': ['Practice Setting Admin']
        }
      },
      {
        path: 'taskdashboard',
        loadChildren: './views/taskdashboard/taskdashboard.module#TaskDashboardModule',
        data: {
          title: 'taskdashboard',
          permissions: ['Portal Task Report View']
        }
      },
      {
        path: 'reports',
        loadChildren: './views/reports/reports.module#ReportsModule',
        data: {
          title: 'Reports',
          permissions: ['Portal Report View', 'Portal Report Admin'],
        }
      },
      {
        path: 'rules',
        loadChildren: './views/rules/rules.module#RulesModule',
        data: {
          title: 'rules',
          'permissions': ['Portal Rule Users']
        }
      },
      {
        path: 'timesheet',
        loadChildren: './views/timesheet/timesheet.module#TimesheetModule',
        data: {
          title: 'timesheet',
          'permissions': ['Portal Timesheet Users']
        }
      },
      {
        path: 'session-history',
        component: SessionHistoryComponent,
        data: {
          title: 'Session history',
          'permissions': ['Portal Batches Read', 'Portal Batches Admin']
        }
      },
      {
        path: 'image-viewer',
        component: ImageViewerComponent,
        data: {
          title: 'Image Viewer',
          'permissions': ['Portal Batches Read', 'Portal Batches Admin']
        }
      },
      {
        path: 'bulk-uploads-view',
        loadChildren: './views/bulk-uploads-view/bulk-uploads-view.module#BulkUploadsViewModule',
        data: {
          title: 'Bulk uploads view',
          // 'permissions' : ['Portal Rule Users']
          permissions : ['Portal Bulk Uploads']
        }
      },
    ]
  },

  {
    path: '**',
    component: P404Component,
    data: {
      title: '404 Page'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
